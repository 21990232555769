import { Editor, Range } from 'domains/reporter/RichTextEditor/core';
import { equals } from 'ramda';
import { Element } from 'slate';

/**
 * This checks whether the startNode and endNode for a given selection
 * shares the same node for a given nodeType.
 *
 *  E.g. returns true if in a given selection, two 'paragraphs' do not have equal shape
 *
 *  This can in future be improved if we use Node Ids to check for the true equality.
 *  At the moment we can return false positives if two nodes being compared happen to have
 *  The exact same shape.
 */
export const hasMultipleInSelection = (editor: Editor, nodeType: string): boolean => {
  if (editor.selection != null && Range.isCollapsed(editor.selection)) {
    return false;
  }
  const [startNodeEntry] = Array.from(
    Editor.nodes(editor, {
      at: editor.selection?.anchor,
      match: (n) => Element.isElement(n) && n.type === nodeType,
    })
  );

  const [endNodeEntry] = Array.from(
    Editor.nodes(editor, {
      at: editor.selection?.focus,
      match: (n) => Element.isElement(n) && n.type === nodeType,
    })
  );

  if (startNodeEntry == null || endNodeEntry == null) {
    return false;
  }

  return !equals(startNodeEntry[0], endNodeEntry[0]);
};

import { Stack } from '../ui/Layout';
import Text from 'common/ui/Text';
import React, { useEffect, useState } from 'react';

import qrcode from 'qrcode';
import { logger } from 'modules/logger';
import { useCurrentUser } from 'hooks/useCurrentUser';
import Input from 'common/ui/Input';
import { Button } from 'common/ui/Button';
import { Colors } from 'styles';
import useLinkAuthenticator from './useLinkAuthenticator';
import CircularProgress from '@material-ui/core/CircularProgress';

const QRcode = ({
  authenticatorToken,
  email,
}: {
  authenticatorToken: string;
  email: string;
}): React.ReactElement => {
  const [qrCodeURL, setQRCodeURL] = useState(null);

  useEffect(() => {
    if (authenticatorToken != null) {
      const data = `otpauth://totp/${email}?secret=${authenticatorToken}&issuer=SironaMedical`;

      // @ts-expect-error [EN-7967] - TS2693 - 'qrcode' only refers to a type, but is being used as a value here.
      qrcode.toDataURL(data, (err, url) => {
        if (err) {
          logger.warn(err);
        } else {
          setQRCodeURL(url);
        }
      });
    }
  }, [authenticatorToken, email]);

  return (
    <div>
      <p>Scan the QR code with the Google Authenticator app:</p>
      <img src={qrCodeURL} alt="QR Code" data-testid="auth-qr-code" />
    </div>
  );
};

const DownloadAuthenticator = ({
  onSuccess,
  authenticatorToken,
}: {
  onSuccess: (arg1: string) => void;
  authenticatorToken: string | null | undefined;
}): React.ReactElement => {
  const { data: user } = useCurrentUser();
  const [authenticatorCode, setAuthenticatorCode] = useState('');
  const [password, setPassword] = useState('');
  const { linkAuthenticator, error, loading } = useLinkAuthenticator();

  const onSubmit = async (e: Event): Promise<void> => {
    e.preventDefault();
    try {
      await linkAuthenticator(authenticatorCode, password);
      onSuccess('Authenticator linked');
    } catch (e: any) {
      logger.debug(e);
    }
  };

  return (
    <Stack vertical css="margin-left: 16px">
      <Stack>
        <Text>Follow these steps to set up two-factor authentication for your account:</Text>
      </Stack>
      <Stack vertical css="margin-top: 12px">
        <ol css="padding-inline-start: 16px">
          <li>
            Download and install the Google Authenticator app on your mobile device from the{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              target="_blank"
              rel="noopener noreferrer"
              css={`
                color: ${Colors.white};
              `}
            >
              Google Play Store
            </a>{' '}
            (for Android) or{' '}
            <a
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              target="_blank"
              rel="noopener noreferrer"
              css={`
                color: ${Colors.white};
              `}
            >
              App Store
            </a>{' '}
            (for iOS).
          </li>
          <li>Open the Google Authenticator app on your mobile device.</li>
          <li>Click the '+' button to add a new account manually.</li>
          <li>Choose "Scan a barcode" or "Enter a setup key," depending on your preference.</li>
          <li>
            If you chose "Scan a barcode," use your device's camera to scan the QR code below:
          </li>
        </ol>
      </Stack>
      <Stack>
        {authenticatorToken != null && user?.me?.email ? (
          <QRcode authenticatorToken={authenticatorToken} email={user?.me?.email} />
        ) : (
          <Text>Cannot generate QR Code, please try again later</Text>
        )}
      </Stack>
      <Stack vertical>
        <Text css="margin-top: 12px" variant="body2">
          If you chose "Enter a setup key," manually enter the following key:{' '}
          <Text variant="display1" data-testid="authenticator-code">
            {authenticatorToken}
          </Text>
        </Text>
        <Text css="margin-top: 12px" variant="body2">
          After scanning the QR code or entering the setup key in Google Authenticator a new code
          will be generated. Enter the generated code into the field below to verify and complete
          your two-factor authentication setup.
        </Text>
      </Stack>
      <Stack vertical>
        <Text css="margin: 12px 0 0px 12px">Password</Text>
        {/* @ts-expect-error [EN-7967] - TS2322 - Type '(e: Event) => Promise<void>' is not assignable to type 'FormEventHandler<HTMLFormElement>'. */}
        <form onSubmit={onSubmit}>
          <Stack css="margin-top: 5px">
            <Input
              as="input"
              autoComplete="password"
              value={password}
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              name="Password"
              css="
                width: 248px;
                height: 40px;
                margin-right: 12px;
              "
            />
          </Stack>
          <Text css="margin: 12px 0 0px 12px" display="block">
            Authentication Code
          </Text>
          <Stack css="margin-top: 5px">
            <Input
              as="input"
              id="totp"
              autoComplete="one-time-code"
              value={authenticatorCode}
              onChange={(e) => setAuthenticatorCode(e.target.value)}
              type="text"
              name="Authenticator Code"
              css="
                width: 248px;
                height: 40px;
                margin-right: 12px;
              "
            />
            {loading ? (
              <CircularProgress css="margin-left: 50px; height: 40px;" />
            ) : (
              <Button css="margin-left: 12px" type="submit" variant="primary">
                Submit
              </Button>
            )}
          </Stack>
          {error != null && (
            <Text
              css={`
                margin-top: 5px;
                margin-left: 10px;
                color: ${Colors.red6};
              `}
            >
              Unable to link authenticator app, please check if code is correct
            </Text>
          )}
        </form>
      </Stack>
    </Stack>
  );
};

export default DownloadAuthenticator;

import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { ApolloProvider } from '@apollo/client';
import { client } from 'modules/Apollo/client';
import App from './App';
import { APP_VERSION, APP_BUILD } from 'config/constants';
import { env } from 'config/env';
import localforage from 'localforage';
import 'index.css';

window.onerror = () => {
  // If an unhandled exception occurs, let's clear the persistent
  // Apollo cache so that we can start fresh next time.
  localforage.clear();
};

const bold = 'font-weight: bold';
const monospace = 'font-family: monospace';
// eslint-disable-next-line no-console
console.info(
  `🚀 %cSirona Medical Frontend - Version %c%s %cBuild %c%s, %cDeploy Env %c%s, %cE2E Mode %c%s`,
  bold,
  monospace,
  APP_VERSION || 'N/A',
  bold,
  monospace,
  APP_BUILD || 'N/A',
  bold,
  monospace,
  env.DEPLOY_ENV ?? 'N/A',
  bold,
  monospace,
  env.E2E_TEST ?? 'N/A'
);

function RootApp() {
  return (
    <StrictMode>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </StrictMode>
  );
}

const rootElement = document.getElementById('root');

if (rootElement == null) {
  throw new Error('Could not find root element');
}

const root = createRoot(rootElement);
root.render(<RootApp />);

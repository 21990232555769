import { Editor, Point, Node, Transforms, Range } from '../../core';
import { LIST_WRAPPER } from './constants';
import {
  createListItemNode,
  findClosestBlockPathAtSelection,
  getCurrentActiveListVariant,
  isInsideFirstListItemOfListAtPath,
  isInsideListAtPath,
  triggerListInsertion,
} from './utils';
import { demoteListItems, exitAllLists } from './functions';
import { PARAGRAPH_LIST_ITEM_VARIANT } from '../paragraph/types';
import { BRACKET_TYPES } from '../../constants';

export const withListShortcuts = (editor: Editor): Editor => {
  const { deleteBackward, insertText } = editor;

  editor.insertText = (text: string) => {
    const { selection } = editor;
    if (text === ' ' && selection != null && Range.isCollapsed(selection)) {
      const block = Editor.above(editor, {
        match: (n: Node) => Editor.isBlock(editor, n),
      });

      if (block != null) {
        const path = block[1];
        const start = Editor.start(editor, path);

        const range = { anchor: selection.anchor, focus: start } as const;
        const beforeText = Editor.string(editor, range);
        const variant = LIST_WRAPPER[beforeText];

        if (variant) {
          if (isInsideFirstListItemOfListAtPath(editor, path)) {
            // We are in the first list node. Don't trigger the insertion.
            insertText(text);
            return;
          }

          const blockText = Node.string(block[0]);

          // if a valid shortcut representing a variant is used, strip the variant text
          // e.g. if beforeText is '1. ' and blockText is '1. my first list item'
          // differenceText is 'my first list item'
          const differenceText = blockText.replace(beforeText, '');

          const listNode = createListItemNode(differenceText);

          Transforms.select(editor, Editor.range(editor, range));
          Transforms.removeNodes(editor);
          triggerListInsertion({
            editor,
            variant,
            at: path,
            listItemNodes: [listNode],
            edge: 'start',
          });
          return;
        }
      }
    }

    insertText(text);
  };

  editor.deleteBackward = (...args) => {
    const { selection } = editor;
    const variant = getCurrentActiveListVariant(editor);
    const path = findClosestBlockPathAtSelection(editor);

    if (selection && Range.isCollapsed(selection) && isInsideListAtPath(editor, path)) {
      const listItem = Editor.above(editor, {
        // @ts-expect-error [EN-7967] - TS2353 - Object literal may only specify known properties, and 'listItem' does not exist in type 'EditorAboveOptions<any>'.
        listItem: (n: Node) => n.variant === PARAGRAPH_LIST_ITEM_VARIANT,
      });

      // check for BRACKET_TYPES before demoting
      if (listItem != null && !BRACKET_TYPES.includes(listItem[0].type)) {
        const start = Editor.start(editor, listItem[1]);

        // if backspace at start of line within a list
        if (Point.equals(selection.anchor, start)) {
          const paragraphEntry = Editor.above(editor, {
            match: (n: Node) => n.type === 'paragraph',
          });
          if (paragraphEntry != null) {
            // if at the start of a list item
            if (paragraphEntry[0].variant === PARAGRAPH_LIST_ITEM_VARIANT) {
              demoteListItems(editor);
              return;
            }
            // or on a new line, merge the line with the previous line
            const mergeTarget = Editor.before(editor, selection);
            if (mergeTarget == null) {
              // we are at the top of the editor
              Transforms.moveNodes(editor, { at: selection, to: [0] });
              return;
            }

            const target = Editor.node(editor, mergeTarget);
            const targetParent = Editor.parent(editor, target[1]);
            Transforms.mergeNodes(editor);
            Transforms.setNodes(
              editor,
              { variant: targetParent[0].variant },
              { at: targetParent[1] }
            );
            return;
          }

          exitAllLists(editor, variant);
          return;
        }
      }
    }
    deleteBackward(...args);
  };

  return editor;
};

import { useState } from 'react';

type Box = {
  left: number;
  top: number;
  right: number;
  bottom: number;
  height: number;
  width: number;
};

export function getElementBox(el: Element): Box {
  const { left, top, right, bottom, height, width } = el.getBoundingClientRect();
  return { left, top, right, bottom, height, width };
}

export function useElementBox(el?: Element | null): Box | null | undefined {
  const [r, setRect] = useState(el != null ? getElementBox(el) : el);

  if (el != null) {
    const n = getElementBox(el);

    if (
      r == null ||
      // @ts-expect-error [EN-7967] - TS2339 - Property 'left' does not exist on type 'Element | Box'.
      r.left !== n.left ||
      // @ts-expect-error [EN-7967] - TS2339 - Property 'top' does not exist on type 'Element | Box'.
      r.top !== n.top ||
      // @ts-expect-error [EN-7967] - TS2339 - Property 'right' does not exist on type 'Element | Box'.
      r.right !== n.right ||
      // @ts-expect-error [EN-7967] - TS2339 - Property 'bottom' does not exist on type 'Element | Box'.
      r.bottom !== n.bottom ||
      // @ts-expect-error [EN-7967] - TS2339 - Property 'width' does not exist on type 'Element | Box'.
      r.width !== n.width ||
      // @ts-expect-error [EN-7967] - TS2339 - Property 'height' does not exist on type 'Element | Box'.
      r.height !== n.height
    ) {
      setRect(n);
    }
  }

  // @ts-expect-error [EN-7967] - TS2322 - Type 'Element | Box' is not assignable to type 'Box'.
  return r;
}

import { custom, tuple, number } from '@recoiljs/refine';
import type { Checker } from '@recoiljs/refine';
import type { Vector3 as vec3 } from '@kitware/vtk.js/types';

// Same as stringLiterals but ignores the key values and only checks the values
// useful to test *Values objects generated by Codegen
export function stringLiteralsCodegen<
  T extends {
    readonly [key: string]: string;
  },
>(enumValues: T): Checker<T[keyof T]> {
  // @ts-expect-error [EN-7967] - TS2322 - Type 'Checker<string>' is not assignable to type 'Checker<T[keyof T]>'.
  return custom(
    (value) => {
      if (typeof value !== 'string') {
        return null;
      }

      const out = Object.values(enumValues).includes(value);
      if (out == null) {
        return null;
      }
      return value;
    },
    `value is not one of ${Object.keys(enumValues).join(', ')}`
  );
}

// @ts-expect-error [EN-7967] - TS2322 - Type 'Checker<readonly [number, number, number]>' is not assignable to type 'Checker<Vector3>'.
export const vec3Checker = (): Checker<vec3> => tuple(number(), number(), number());

import { Path, Editor } from 'domains/reporter/RichTextEditor/core';
// NOTE:
// There's a nasty bug in slate-react that makes it to where inserting objects
// that share a reference borks Slate React. The only workaround I know of without redoing
// Slate React is to deep clone all of the nodes before inserting them into the editor.
// This is a super horrible hack and hits performance, but these functions aren't called a lot.
//
// Issue: https://github.com/ianstormtaylor/slate/issues/4309

import { MACRO_PLACEHOLDER_PLUGIN_ID } from './types';
import type { MacroPlaceholderPluginElement, Macro, MacroPlaceholderType } from './types';
import { Node } from '../../core';
import { PARAGRAPH_PLUGIN_ID } from '../paragraph';
import { unreachableCaseLog } from 'types';
import { replaceNodeAtPath } from '../../utils';
import { isTextFragment, isInlineFragment } from '../../stitching';

type Nodes = any;

export const insertMacroPlaceholder = ({
  referenceID,
  displayName,
}: {
  referenceID: string;
  displayName: string;
}): MacroPlaceholderPluginElement => ({
  // Unique id used to associate the macro element with its metadata
  referenceID,
  type: MACRO_PLACEHOLDER_PLUGIN_ID,
  children: [{ text: displayName }],
});

export function isMacro(maybeMacro?: Macro | string): boolean {
  return Array.isArray(maybeMacro) && maybeMacro.every(Node.isNode);
}

export const normalizeMacro = (macro: Macro): Macro => {
  // PRD: If the first node in a macro is a paragraph, unwrap it so that those nodes will appear inline.
  if (macro[0].type === PARAGRAPH_PLUGIN_ID) {
    const newMacro = [...macro];
    newMacro.splice(0, 1);

    return [...macro[0].children, ...newMacro];
  }

  return macro;
};

export const getMacroEntry = (
  editor: Editor,
  maybeMacro: Macro | string
): [MacroPlaceholderType, Nodes] => {
  if (isMacro(maybeMacro)) {
    const normalizedMacro = normalizeMacro(maybeMacro as Macro);

    if (isTextFragment(editor, normalizedMacro)) {
      return ['macroText', normalizedMacro];
    } else {
      if (isInlineFragment(editor, normalizedMacro)) {
        return ['macroInline', normalizedMacro];
      } else {
        return ['macroBlock', normalizedMacro];
      }
    }
  } else {
    return ['plainText', maybeMacro];
  }
};

export const insertMacroAtPath = (editor: Editor, path: Path, maybeMacro: Macro | string): void => {
  const [macroType, normalizedMacro] = getMacroEntry(editor, maybeMacro);

  switch (macroType) {
    case 'plainText':
      replaceNodeAtPath(editor, [{ text: normalizedMacro }], { at: path });
      break;
    case 'macroText':
    case 'macroInline':
      replaceNodeAtPath(editor, normalizedMacro, { at: path });
      break;

    case 'macroBlock':
      replaceNodeAtPath(editor, normalizedMacro, { at: path });
      break;

    default:
      unreachableCaseLog(macroType);
      return;
  }
};

import { object, string } from '@recoiljs/refine';
import type { Checker } from '@recoiljs/refine';
import { stringLiteralsCodegen } from 'utils/refine';
import { ToolInteractionRequestMethod } from 'generated/graphql';
import type { ToolInteractionRequest } from 'generated/graphql';

export const interactionHttpRequestChecker: Checker<ToolInteractionRequest> = object({
  url: string(),
  method: stringLiteralsCodegen(ToolInteractionRequestMethod),
});

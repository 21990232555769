import { useCurrentCaseId } from './useCurrentCase';

import { useMemo } from 'react';
import { GET_WORKLIST_ITEM_ANALYTICS } from 'modules/Apollo/queries';
import type {
  GetWorklistItemAnalyticsQuery,
  GetWorklistItemAnalyticsQueryVariables,
} from 'generated/graphql';
import { useQuery } from '@apollo/client';

type WorklistItemAnalytics = {
  worklistItemId: string;
  modality: string;
  bodyPart: string;
  studyDescription: string;
};

export function useWorklistItemAnalytics(): WorklistItemAnalytics | undefined {
  const currentCaseId = useCurrentCaseId();
  const { data: caseData } = useQuery<
    GetWorklistItemAnalyticsQuery,
    GetWorklistItemAnalyticsQueryVariables
  >(GET_WORKLIST_ITEM_ANALYTICS, {
    variables: currentCaseId == null ? undefined : { smid: currentCaseId },
    skip: currentCaseId == null,
  });

  const analyticsData = useMemo(() => {
    if (caseData?.worklistItem == null) return undefined;
    return {
      worklistItemId: caseData.worklistItem.smid,
      modality: caseData.worklistItem.modality ?? 'N/A',
      bodyPart: caseData.worklistItem.bodyPart ?? 'N/A',
      studyDescription: caseData.worklistItem.studyDescription ?? 'N/A',
    };
  }, [caseData?.worklistItem]);

  return analyticsData;
}

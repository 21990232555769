import { Renderer } from '../../RichTextEditor';
import { useFields } from './Fields.Context';
import { useCurrentUser } from 'hooks/useCurrentUser';
import type { Styles } from 'generated/graphql';
import { useReporterStyles } from 'hooks/useReporterStyles';
import { UnifiedEditor } from '../../RichTextEditor/ui/UnifiedEditor';

export const FieldsAddendumRenderer = (): React.ReactElement | null => {
  const [{ finalizedAddendums }] = useFields();
  const { data } = useCurrentUser();
  const reporterSettings = data?.me?.reporterSettings;

  const style: Styles = useReporterStyles(reporterSettings);

  // https://sironamedical.atlassian.net/browse/EN-2417
  if (!finalizedAddendums || finalizedAddendums.some((a) => a == null)) return null;

  // @ts-expect-error [EN-7967] - TS2739 - Type 'Element[]' is missing the following properties from type 'ReactElement<any, string | JSXElementConstructor<any>>': type, props, key
  return finalizedAddendums
    .map((addendum, index) => {
      return (
        <UnifiedEditor
          key={index}
          isAddendumEditor={true}
          isDisabled={true}
          variant="view"
          label={addendum.name}
        >
          <Renderer
            content={Array.isArray(addendum.content) ? addendum.content : [addendum.content]}
            style={style}
          />
        </UnifiedEditor>
      );
    })
    .flat();
};

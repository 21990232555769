import type { ViewportsConfigurations } from '../ViewportsConfigurations/types';
import type { TStackProviders } from './ViewerContext';

import { useEffect } from 'react';

import { usePrevious } from 'react-use';
import { PixelDataSharedWorkerError } from 'modules/viewer/workers/PixelWorkerConnection';
import { useViewerMemoryConfig } from './useViewerMemoryConfig';
import { useViewerId } from 'hooks/useViewerId';
import { viewportIdToConfig } from './viewerUtils';

export type LoadType = 'none' | 'full' | 'initial';

export type ViewerMemoryConfig = Map<string, LoadType>;

export type ViewerMemoryManagerProps = {
  stackProviders: TStackProviders;
  viewportsConfigurations: ViewportsConfigurations | null | undefined;
  areViewportsReady: boolean;
};

// a high-level hook to manage the side effects of loading and unloading
// based on a memory configuration calculated without side effects
function useViewerMemoryManagement({
  stackProviders,
  viewportsConfigurations,
  areViewportsReady,
}: ViewerMemoryManagerProps): ViewerMemoryConfig {
  const memoryConfig = useViewerMemoryConfig({
    stackProviders,
    viewportsConfigurations,
    areViewportsReady,
  });
  const previousMemoryConfig = usePrevious(memoryConfig);

  const viewerId = useViewerId();

  // inject memory config into providers so they know what to do with new images
  useEffect(() => {
    memoryConfig.forEach((loadType, stackSmid) => {
      const provider = stackProviders.get(stackSmid);
      if (provider != null) provider.loadType = loadType;
    });
  }, [stackProviders, memoryConfig]);

  // throw imaging providers into error states that we don't have the space for
  // but are trying to render in a viewport
  useEffect(() => {
    memoryConfig.forEach((loadType, stackSmid) => {
      const provider = stackProviders.get(stackSmid);
      if (
        provider != null &&
        viewportsConfigurations != null &&
        loadType !== 'full' &&
        Object.entries(viewportsConfigurations).some(
          ([viewportId, vc]: [any, any]) =>
            vc?.stack?.smid === provider.stack.smid &&
            viewportIdToConfig(viewportId).windowId === viewerId
        )
      ) {
        provider.failLoading(PixelDataSharedWorkerError.NotEnoughMemory, true);
      }
    });
  }, [stackProviders, memoryConfig, viewportsConfigurations, viewerId]);

  // dynamically unload and load stacks' frames depending on what is on screen
  // and fits into the memory limits according to the memory configuration
  useEffect(() => {
    if (previousMemoryConfig == null) {
      return;
    }

    memoryConfig.forEach((loadType, stackSmid) => {
      const previousConfig = previousMemoryConfig.get(stackSmid);
      if (previousConfig == null) return;

      if (loadType === 'full' && previousConfig !== 'full') {
        const provider = stackProviders.get(stackSmid);
        if (provider != null) {
          provider.reloadAllFramesIntoMemory();
        }
      }

      if (loadType === 'initial' && previousConfig !== 'initial') {
        const provider = stackProviders.get(stackSmid);
        if (provider != null) {
          provider.loadFrameIntoMemory(provider.getActiveSlice('TWO_D_DRE'), {
            unloadOthers: true,
          });
        }
      }

      if (loadType === 'none' && previousConfig !== 'none') {
        const provider = stackProviders.get(stackSmid);
        if (provider != null) {
          provider.unloadAllFrames();
        }
      }
    });
  }, [stackProviders, memoryConfig, previousMemoryConfig]);

  return memoryConfig;
}

function ViewerMemoryManager({
  stackProviders,
  viewportsConfigurations,
  areViewportsReady,
}: ViewerMemoryManagerProps): React.ReactElement | null {
  useViewerMemoryManagement({
    stackProviders,
    viewportsConfigurations,
    areViewportsReady,
  });
  return null;
}

export { ViewerMemoryManager, useViewerMemoryManagement as __test_only__useViewerMemoryManagement };

import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { reportVersionState } from 'domains/reporter/Reporter/state';
import { useCurrentCaseReport } from './useCurrentCaseReport';
import { logger } from 'modules/logger';

export type UseReportVersionReturn = {
  reportVersion: number | null | undefined;
  incrementReportVersion: () => Promise<number>;
};

export const useReportVersion = (): UseReportVersionReturn => {
  const { currentCaseReport } = useCurrentCaseReport();
  const [reportVersion, setReportVersion] = useRecoilState(reportVersionState);

  const initializeVersion = useCallback(() => {
    if (
      currentCaseReport?.report != null &&
      typeof currentCaseReport.report.version === 'number' &&
      currentCaseReport.report.version !== reportVersion
    ) {
      setReportVersion(currentCaseReport.report?.version ?? 0);
    }
  }, [currentCaseReport?.report, reportVersion, setReportVersion]);

  /**
   * Since we are optimistically updating the report version, we need to wait
   * for the version state to be updated properly before we can proceed with
   * other actions; e.g. sending an update to the report with the correct version.
   * Technically, the callback (resolve) will be called before the state is updated,
   * but at least the `setReportVersion` callback will be invoked right before
   * the state updates.
   */
  const incrementReportVersion = useCallback((): Promise<number> => {
    return new Promise((resolve: (result: Promise<number> | number) => void) => {
      setReportVersion((prevVersion) => {
        const updatedVersion = (prevVersion ?? 0) + 1;
        resolve(updatedVersion);
        logger.info(`[useReportVersion]: incrementing report version to ${updatedVersion}`);
        return updatedVersion;
      });
    });
  }, [setReportVersion]);

  useEffect(() => {
    initializeVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { reportVersion, incrementReportVersion };
};

import { Path } from 'domains/reporter/RichTextEditor/core';
import type { CreateEnhanceEditorState } from '../../types';
import type { HeadingPluginPropertyOptions } from './types';
import { Element, Transforms, Editor, Node, Range, Point } from '../../core';
import { reportHasDuplicateHeadings } from '../headingError/utils';
import { PARAGRAPH_PLUGIN_ID } from '../paragraph';
import { HeadingLevel } from './constants';
import {
  normalizeText,
  getHeadingKeywordIndex,
  toTitleCase,
  upgradeHeadingKeywordToHeading,
  isCursorAfterHeadingColon,
  maybeConvertTextParagraphsToHeadings,
} from './utils/normalization';
import {
  createInlineParagraph,
  isInlineParagraph,
  getOrInsertNextInlineParagraph,
} from '../paragraph/utils';
import { HEADING_PLUGIN_ID } from './types';
import { partialEditor } from '../../utils';
import { logger } from 'modules/logger';
import { clone } from 'ramda';
import type { SlateContent } from '../../../Reporter/types';
// @ts-expect-error [EN-7967] - TS2305 - Module '"slate"' has no exported member 'TextUnit'.
import type { NodeEntry, TextUnit } from 'slate';

/*
 * This is a bit tricky. We want to replace the text of the heading with the capitalized text, but we also want to preserve the selection.
 *
 * There's two scenarios where heading text needs to be updated: typing and dictation.
 *
 * In the normalizeNode call, we check if the heading node's children have already been normalized into a single text node. This makes it significantly easier to replace the incorrectly formatted text node with the correctly formatted one.
 *
 * Instead of trying to delete a range of text nodes and replace them with a single text node, we can just replace the text of the single text node.
 *
 * Finally, we select the original selection, so that the selection is preserved instead of being moved to the end of the heading.
 */
const replaceHeadingText = (
  editor: Editor,
  formattedHeading: string,
  path: Path,
  selection: Range
) => {
  // Our schema and the earlier check that the length of the heading's children array is 0 allows us to assume that the first child is the text node to replace.
  Transforms.insertText(editor, formattedHeading, { at: [...path, 0] });
  Transforms.select(editor, selection);
};

const isMissingInlineParagraph = (fragmentNode: Node) => {
  return fragmentNode.type === HEADING_PLUGIN_ID;
};

export const enhanceEditorStateHeading: CreateEnhanceEditorState<HeadingPluginPropertyOptions> =
  ({ pluginID, headingKeywords, isInsertingMacro }) =>
  (editor: Editor) => {
    const {
      normalizeNode,
      deleteFragment,
      insertText,
      deleteBackward,
      deleteForward,
      insertFragment,
    } = editor;

    editor.insertText = (text: string) => {
      const { selection: originalSelection } = editor;
      if (originalSelection == null) {
        insertText(text);
        return;
      }

      const selectionStartPoint = Range.start(originalSelection);
      let nodeEntry = Editor.node(editor, selectionStartPoint, { depth: 1 });
      const [node, path] = nodeEntry;

      if (
        Element.isElement(node) &&
        node.type === pluginID &&
        node.level === HeadingLevel.H1 &&
        reportHasDuplicateHeadings(editor, Node.string(node))
      ) {
        insertText(text);
        Editor.normalize(editor, { force: true });
        return;
      }

      // If we are inserting a macro, then we don't want to upgrade a heading keyword to a heading
      if (isInsertingMacro === true) {
        insertText(text);
        Editor.normalize(editor, { force: true });
        return;
      }

      if (
        text === ':' &&
        Element.isElement(node) &&
        node.type === PARAGRAPH_PLUGIN_ID &&
        headingKeywords != null
      ) {
        const [headingKeyword, textArrayIndex] = getHeadingKeywordIndex(
          editor,
          headingKeywords,
          nodeEntry
        );

        if (headingKeyword != null && textArrayIndex !== -1) {
          const wordIndex = Array.from(Node.texts(node))
            [textArrayIndex][0].text.toLowerCase()
            .indexOf(headingKeyword.toLowerCase());
          const endOfWordPath = {
            path: [path[0], textArrayIndex],
            offset: wordIndex + headingKeyword.length,
          } as const;

          // @ts-expect-error [EN-7967] - TS2345 - Argument of type '{ readonly path: readonly [number, number]; readonly offset: number; }' is not assignable to parameter of type 'BasePoint'.
          if (Point.equals(selectionStartPoint, endOfWordPath)) {
            upgradeHeadingKeywordToHeading(editor, nodeEntry, headingKeyword, textArrayIndex, true);
            return;
          }
        }
      } else if (
        text.includes(':') &&
        Element.isElement(node) &&
        node.type === PARAGRAPH_PLUGIN_ID &&
        headingKeywords != null
      ) {
        let headingKeyword = null;
        let textArrayIndex = -1;

        Editor.withoutNormalizing(editor, () => {
          Transforms.insertText(editor, text, { at: originalSelection });

          nodeEntry = Editor.node(editor, originalSelection, { depth: 1 });
          [headingKeyword, textArrayIndex] = getHeadingKeywordIndex(
            editor,
            headingKeywords,
            nodeEntry
          );

          if (headingKeyword != null && textArrayIndex !== -1) {
            upgradeHeadingKeywordToHeading(
              editor,
              nodeEntry,
              headingKeyword,
              textArrayIndex,
              Range.includes(
                {
                  anchor: Editor.start(editor, nodeEntry[1]),
                  focus: Editor.end(editor, nodeEntry[1]),
                },
                selectionStartPoint
              )
            );
          }
        });
        return;
      }

      // If the cursor is currently in a heading but after the colon, we want to insert the text into
      // the adjacent inline paragraph instead
      if (
        Range.isCollapsed(originalSelection) &&
        isCursorAfterHeadingColon(editor, originalSelection)
      ) {
        const nextInlineParagraphEntry = getOrInsertNextInlineParagraph(editor, originalSelection);
        // @ts-expect-error [EN-7967] - TS2488 - Type 'ParagraphPluginElement' must have a '[Symbol.iterator]()' method that returns an iterator.
        const [nextInlineParagraphNode, nextInlineParagraphPath] = nextInlineParagraphEntry;
        const startPosition = Editor.start(editor, nextInlineParagraphPath);

        Transforms.select(editor, Editor.start(editor, startPosition));

        const nodeText = Node.string(nextInlineParagraphNode);
        const hasNoPrecedingSpace = !nodeText.startsWith(' ') && !text.startsWith(' ');

        // If neither the next inline paragraph nor the text to insert starts with a space, then we need to insert
        // a space
        if (hasNoPrecedingSpace) {
          Transforms.insertText(editor, ' ', {
            at: startPosition,
          });
          // If the text to insert doesn't start with a space but the inline paragraph does, then we move the selection
          // after the space
        } else if (nodeText.startsWith(' ') && !text.startsWith(' ')) {
          Transforms.move(editor, { distance: 1 });
        }

        Transforms.insertText(editor, text);

        // If the text in the inline paragraph is not empty and our text to insert
        // doesn't end with a space, then append a space so that the text doesn't merge
        if (nodeText.length > 0 && !nodeText.startsWith(' ') && !text.endsWith(' ')) {
          Transforms.insertText(editor, ' ');
        }
        return;
      }

      insertText(text);
    };

    editor.deleteBackward = (unit: TextUnit) => {
      const { selection } = editor;
      if (!selection) return;

      const headingEntry = Editor.node(editor, selection, {
        depth: 1,
      });

      if (
        headingEntry != null &&
        Element.isElement(headingEntry[0]) &&
        headingEntry[0].type === pluginID
      ) {
        const [headingNode, headingPath] = headingEntry;
        if (Range.isCollapsed(selection) && Editor.isStart(editor, selection.anchor, headingPath)) {
          const previousNode = Editor.previous(editor, { at: headingPath });
          if (
            previousNode != null &&
            Element.isElement(previousNode[0]) &&
            previousNode[0].type === PARAGRAPH_PLUGIN_ID &&
            previousNode[0].shouldForceInline === true
          ) {
            Transforms.removeNodes(editor, { at: headingPath });
            const point = Editor.end(editor, previousNode[1]);
            Transforms.insertText(editor, Node.string(headingNode), {
              at: point,
            });
            Transforms.select(editor, point);
            Editor.normalize(editor, { force: true });
            return;
          }
        }

        if (
          Editor.isEnd(editor, Range.end(selection), headingPath) &&
          normalizeText(Node.string(headingNode)).length <= 1 &&
          unit === 'character'
        ) {
          Transforms.delete(editor, { at: headingPath });
          Editor.normalize(editor, { force: true });
          return;
        }

        // Normalize entire editor if we are deleting something in an H1, to make sure
        // we are removing possible heading errors
        if (headingEntry[0].level === HeadingLevel.H1) {
          deleteBackward(unit);
          Editor.normalize(editor, { force: true });
          return;
        }
      }

      deleteBackward(unit);
    };

    editor.deleteFragment = () => {
      const { selection } = editor;
      if (!selection) return;

      const headingEntries = Array.from(
        Editor.nodes(editor, {
          at: selection,
          // @ts-expect-error [EN-7967] - TS2353 - Object literal may only specify known properties, and 'depth' does not exist in type 'EditorNodesOptions<any>'.
          depth: 1,
          match: (n: Node) => n.type === pluginID && n.level === HeadingLevel.H1,
        })
      );

      // If any of the nodes in the selection is an H1, normalize the entire editor
      // to make sure that we remove heading errors
      if (headingEntries.length > 0) {
        deleteFragment();
        Editor.normalize(editor, { force: true });
        return;
      }
      deleteFragment();
    };

    // While we are no longer enforcing a paragraph between headings throughout the report, we are enforcing it
    // when a heading is first inserted into the report. This can happen via typing, dictating, or copy and pasting. In
    // particular, the latter two scenarios will trigger the logic in this function
    editor.insertFragment = (fragment: Array<Node>) => {
      const selection = editor.selection;
      if (selection == null) {
        const selectionErrorMessage =
          '[enhanceEditorStateHeading] Cannot insert fragment into editor as there is no selection.';
        logger.error(selectionErrorMessage, {
          editor: editor != null ? partialEditor(editor) : 'null',
          fragment: JSON.stringify(fragment),
        });
        return;
      }

      let fragmentToInsert: Array<Node> = clone(fragment);

      // If the fragment doesn't contain any headings, then we should check to see if we need to upgrade
      // any of these nodes to headings
      if (!fragment.some((node) => node.type === pluginID)) {
        if (headingKeywords != null) {
          fragmentToInsert = maybeConvertTextParagraphsToHeadings(
            editor,
            fragment,
            headingKeywords
          );

          if (!fragmentToInsert.some((node) => node.type === pluginID)) {
            insertFragment(fragmentToInsert);
            return;
          }

          logger.info(
            `[enhanceEditorStateHeading] Converted text paragraphs to headings when inserting fragment`,
            {
              fragment,
              fragmentToInsert,
              selection: editor?.selection ?? '',
            }
          );
        } else {
          insertFragment(fragmentToInsert);
          return;
        }
      }

      // Broadly, what we are going to do is ensure that each heading in the fragment is followed by
      // an inline paragraph, and that if the heading is preceded by either another heading or an inline paragraph, that
      // we add a paragraph between them.
      const formattedFragment: SlateContent = [];

      // If the current node is an inline paragraph, then we need to make sure to start our
      // fragment with an inline paragraph. This is because the inline paragraph will be
      // overridden by this fragment, and we want to make sure there is still an inline paragraph
      // at this point, as it means that the previous node to our selection is a heading.
      const currentNodeEntry = Editor.node(editor, selection, {
        depth: 1,
      });
      if (currentNodeEntry != null && isInlineParagraph(currentNodeEntry[0])) {
        formattedFragment.push(createInlineParagraph(''));
      }

      formattedFragment.push(fragmentToInsert[0]);

      // If the first node of the fragment is a heading, we add an inline paragraph afterwards. This ensures that we can
      // type next to the heading in the future. If the next node in fragment is an inline paragraph, which may be the case
      // when copy and pasting, these two inline paragraphs will be merged during normalization.
      if (isMissingInlineParagraph(fragmentToInsert[0])) {
        formattedFragment.push(createInlineParagraph(''));
      }

      for (let i = 1; i < fragmentToInsert.length; i++) {
        formattedFragment.push(fragmentToInsert[i]);
        if (isMissingInlineParagraph(fragmentToInsert[i])) {
          formattedFragment.push(createInlineParagraph(' '));
        }
      }

      insertFragment(formattedFragment);
    };

    editor.deleteForward = (unit: TextUnit) => {
      const { selection } = editor;
      if (!selection) return;
      const currentNodeEntry = Editor.node(editor, selection, {
        depth: 1,
      });

      if (
        currentNodeEntry != null &&
        Element.isElement(currentNodeEntry[0]) &&
        currentNodeEntry[0].type === pluginID
      ) {
        const [headingNode, headingPath] = currentNodeEntry;

        if (
          Editor.isStart(editor, Range.start(selection), headingPath) &&
          normalizeText(Node.string(headingNode)).length === 1 &&
          unit === 'character'
        ) {
          Transforms.delete(editor, { at: headingPath });
          return;
        }

        // Normalize entire editor if we are deleting something in an H1, to make sure
        // we are removing possible heading errors
        if (currentNodeEntry[0].level === HeadingLevel.H1) {
          deleteForward(unit);
          Editor.normalize(editor, { force: true });
          return;
        }
      } else if (
        currentNodeEntry != null &&
        Element.isElement(currentNodeEntry[0]) &&
        currentNodeEntry[0].type === PARAGRAPH_PLUGIN_ID &&
        currentNodeEntry[0].shouldForceInline === true &&
        Range.isCollapsed(selection) &&
        Editor.isEnd(editor, selection.anchor, currentNodeEntry[1])
      ) {
        const nextNode = Editor.next(editor, { at: currentNodeEntry[1] });
        if (nextNode != null && Element.isElement(nextNode[0]) && nextNode[0].type === pluginID) {
          const point = Editor.end(editor, currentNodeEntry[1]);
          Transforms.insertText(editor, Node.string(nextNode[0]), {
            at: point,
          });
          Transforms.removeNodes(editor, { at: nextNode[1] });

          Transforms.select(editor, point);
          Editor.normalize(editor, { force: true });
          return;
        }
      }

      deleteForward(unit);
    };

    editor.normalizeNode = (entry: NodeEntry) => {
      const [node, path] = entry;
      const { selection } = editor;

      if (Element.isElement(node) && node.type === pluginID && selection) {
        if (
          node.level === HeadingLevel.H1 &&
          Array.isArray(node.children) &&
          node.children.length === 1 &&
          Node.string(node) !== Node.string(node).toUpperCase()
        ) {
          replaceHeadingText(editor, Node.string(node).toUpperCase(), path, selection);
          return;
        }

        if (
          node.level === HeadingLevel.H2 &&
          Array.isArray(node.children) &&
          node.children.length === 1 &&
          Node.string(node) !== toTitleCase(Node.string(node))
        ) {
          replaceHeadingText(editor, toTitleCase(Node.string(node)), path, selection);
          return;
        }

        /**
         * If there is an empty heading but our cursor isn't currently in it,
         * delete it. This happens when something like a space is split off from
         * a previous heading. If a heading is empty because our cursor is in it, we don't
         * want to delete it since it means someone just began it.
         *
         * For example, with the following slate state:
         *  [
         *    {
         *      type: "heading",
         *      level: 1,
         *      children: [
         *        {text: "exam"}
         *      ]
         *    },
         *    {
         *      type: "heading",
         *      level: 1,
         *      children: [
         *        {text: ""}
         *      ]
         *    },
         *    {
         *      type: "heading",
         *      level: 1,
         *      children: [
         *        {text: ""}
         *      ]
         *    },
         *  ]
         *
         * And the cursor at [2,0]
         *
         * we WANT the end result to be:
         *
         *  [
         *    {
         *      type: "heading",
         *      level: 1,
         *      children: [
         *        {text: "exam"}
         *      ]
         *    },
         *    {
         *      type: "heading",
         *      level: 1,
         *      children: [
         *        {text: ""}
         *      ]
         *    },
         *  ]
         *
         * And cursor at [1,0]
         *
         * In the editor, it would look like:
         *
         *  EXAM
         *  |
         */
        if (
          normalizeText(Node.string(node)).length === 0 &&
          selection != null &&
          !Range.includes(
            { anchor: Editor.start(editor, path), focus: Editor.end(editor, path) },
            selection
          )
        ) {
          Transforms.removeNodes(editor, { at: path });
          return;
        }
      }

      normalizeNode(entry);
    };

    return editor;
  };

import type { DateTimeLocal } from 'generated/graphql';
import memoize from 'lodash.memoize';
import { env } from 'config/env';
import { pluralize } from './string';
import { isNumber } from './isNumber';

export const toLocaleString = (
  date?: Date | null,
  options?: Intl.DateTimeFormatOptions
): string | null | undefined =>
  date != null
    ? new Date(date).toLocaleString([], {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        ...options,
      })
    : null;

export const toLocaleTime = (
  date?: Date | null,
  options?: Intl.DateTimeFormatOptions
): string | null | undefined =>
  date != null
    ? new Date(date).toLocaleString([], {
        hour: '2-digit',
        minute: '2-digit',
        ...options,
      })
    : null;

export const toUTCLocaleString = (
  date?: Date | null,
  options?: Intl.DateTimeFormatOptions
): string | null | undefined =>
  toLocaleString(date, {
    timeZone: 'UTC',
    ...options,
  });

export const toUTCLocaleDateTimeString = (
  date?: Date | null,
  options?: Intl.DateTimeFormatOptions
): string | null | undefined =>
  date != null
    ? new Date(date).toLocaleTimeString([], {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'UTC',
        ...options,
      })
    : null;

export const toLocaleDateTimeStringMemoized: (
  dateTimeLocal: DateTimeLocal | null | undefined | Date,
  options?: Intl.DateTimeFormatOptions
) => string | null | undefined = memoize(
  (
    dateTimeLocal?: DateTimeLocal | null,
    options?: Intl.DateTimeFormatOptions
  ): string | null | undefined => {
    return toLocaleDateTimeString(dateTimeLocal, options);
  },
  (dateTimeLocal) =>
    dateTimeLocal != null
      ? `${dateTimeLocal.year}${dateTimeLocal.month}${dateTimeLocal.date}${dateTimeLocal.hours}${dateTimeLocal.minutes}${dateTimeLocal.seconds}`
      : null
);

export const toLocaleDateTimeString = (
  dateTimeLocal: DateTimeLocal | null | undefined | Date,
  options?: Intl.DateTimeFormatOptions
): string | null | undefined => {
  if (dateTimeLocal == null) {
    return null;
  }

  const date =
    dateTimeLocal instanceof Date
      ? dateTimeLocal
      : new Date(
          dateTimeLocal.year,
          dateTimeLocal.month,
          dateTimeLocal.date,
          dateTimeLocal.hours,
          dateTimeLocal.minutes,
          dateTimeLocal.seconds
        );
  return date.toLocaleTimeString(env.NODE_ENV === 'test' ? 'en-US' : [], {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    ...options,
  });
};

export const toLocaleTimeString = (
  date?: Date | null,
  options?: Intl.DateTimeFormatOptions
): string | null | undefined =>
  date != null
    ? new Date(date).toLocaleTimeString(env.NODE_ENV === 'test' ? 'en-US' : [], {
        hour: '2-digit',
        minute: '2-digit',
        ...options,
      })
    : null;

export const formatDicomDate = (dicomDate: string): string => {
  const date = new Date(`${dicomDate.slice(0, 4)},${dicomDate.slice(4, 6)},${dicomDate.slice(6)}`);
  return toLocaleString(date) ?? '';
};

export const formatDicomTime = (dicomTime: string): string => {
  const time = new Date(
    1,
    1,
    1,
    Number(dicomTime.slice(0, 2)),
    Number(dicomTime.slice(2, 4)),
    Number(dicomTime.slice(4))
  );
  return toLocaleTime(time) ?? '';
};

/**
 * @param age A string of characters with one of the following formats -- nnnD, nnnW, nnnM, nnnY;
 * where nnn shall contain the number of days for D, weeks for W, months for M, or years for Y.
 * Example: "018M" would represent an age of 18 months.
 */
export const formatAgeString = (age: string): string => {
  if (age.length !== 4 || !isNumber(age.slice(0, 3)) || !(age[3] in AGE_PERIODS)) {
    return age; // invalid format, return so we show something
  }
  const num = Number(age.slice(0, 3));
  const period = age[3];
  return `${num} ${pluralize(num, AGE_PERIODS[period])}`;
};

const AGE_PERIODS = {
  D: 'day',
  W: 'week',
  M: 'month',
  Y: 'year',
} as const;

import { useEffect, useCallback, useRef } from 'react';

import { useLocalStorage } from 'hooks/useStorage';
import { getPageType } from 'utils/pageTypes';
import type { PageTypes } from 'utils/pageTypes';
import { useLocation } from 'react-router-dom';
import { getWindowId } from 'hooks/useOpenTabs';
import type { Dispatch, SetStateAction } from 'types';

type WindowRect = {
  top: number;
  left: number;
  width: number;
  height: number;
};
export type WindowsState = Partial<
  Record<
    PageTypes,
    {
      [key: string]: WindowRect;
    }
  >
>;

export const useWindowsRects = (): [WindowsState, Dispatch<SetStateAction<WindowsState>>] => {
  // @ts-expect-error [EN-7967] - TS2322 - Type 'unknown[]' is not assignable to type '[Partial<Record<PageTypes, { [key: string]: WindowRect; }>>, Dispatch<SetStateAction<Partial<Record<PageTypes, { [key: string]: WindowRect; }>>>>]'.
  return useLocalStorage('windows-state', {});
};

const getWindowIdWithDefault = (url: string) => getWindowId(url) ?? 'default';

export const useWindowState = (): [WindowRect, Dispatch<WindowRect>] => {
  const location = useLocation();
  const url = `${window.origin}${location.pathname}`;
  const pageType = getPageType(location.pathname);
  const windowId = getWindowIdWithDefault(url);

  const [windowsState, setWindowsState] = useWindowsRects();

  const setWindowState = useCallback(
    ({ top, left, width, height }: WindowRect) => {
      setWindowsState((windowsState) => ({
        ...windowsState,
        [String(pageType)]: {
          ...windowsState[pageType],
          [String(windowId)]: { top, left, width, height },
        },
      }));
    },
    [pageType, setWindowsState, windowId]
  );

  return [windowsState?.[pageType]?.[windowId], setWindowState];
};

// Tracks the window state for the current page type and window id
export const useTrackWindowRect = (): void => {
  const [, setWindowState] = useWindowState();
  const screenPosition = useRef<{
    screenY: number | null | undefined;
    screenX: number | null | undefined;
  }>({
    screenY: null,
    screenX: null,
  });

  useEffect(() => {
    // Used to track the window size and position when user is resizing it
    const resizeHandler = () => {
      const { innerWidth, innerHeight, screenX, screenY } = window;
      setWindowState({ width: innerWidth, height: innerHeight, top: screenY, left: screenX });
    };
    window.addEventListener('resize', resizeHandler);

    // The following code is necessary to track the position of the browser window
    const intervalId = setInterval(() => {
      if (
        screenPosition.current.screenY !== window.screenY ||
        screenPosition.current.screenX !== window.screenX
      ) {
        screenPosition.current.screenY = window.screenY as number;
        screenPosition.current.screenX = window.screenX as number;
        resizeHandler();
      }
    }, 5000);

    return () => {
      window.removeEventListener('resize', resizeHandler);
      clearInterval(intervalId);
    };
  }, [setWindowState]);
};

export type PopupOptionsGetter = (arg1: string) => WindowRect;

export const usePopupOptionsGetter = (): PopupOptionsGetter => {
  const [windowsState] = useWindowsRects();
  const getPopupOptions = useCallback(
    (url: string) => windowsState?.[getPageType(url)]?.[getWindowIdWithDefault(url)],
    [windowsState]
  );

  return getPopupOptions;
};

import { Icon } from 'common/ui/Icon';
import type { PickerEvent } from '../modules/usePicker';
import { useHTMLActionFromPickerClick } from '../modules/useHTMLActionFromPickerClick';

type TriangleProps = {
  id: string;
  expanded?: boolean;
  onClick?: (event: PickerEvent) => void;
};

export const Expander = ({
  id,
  onClick,
  // @ts-expect-error [EN-7967] - TS2339 - Property 'visible' does not exist on type 'TriangleProps'.
  visible = true,
  expanded = false,
}: TriangleProps): React.ReactElement => {
  const ref = useHTMLActionFromPickerClick({ id, onClick });

  return (
    // @ts-expect-error [EN-7967] - TS2322 - Type 'Ref<ElementType<any, keyof IntrinsicElements>>' is not assignable to type 'LegacyRef<HTMLSpanElement>'.
    <span ref={ref}>
      <Icon name={expanded ? 'expand_vertical_chevron' : 'collapse_vertical_chevron'}></Icon>
    </span>
  );
};

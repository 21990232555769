import { SECTION_HEADER_PLUGIN_ID } from './types';
import type { SectionHeaderPluginElement } from './types';
import { find } from '../../utils/find';
import { Transforms } from 'domains/reporter/RichTextEditor/core';
import type { Editor, NodeEntry, Path, Point, Range } from 'domains/reporter/RichTextEditor/core';

/**
 * Insert a section header whereever the current selection is in the editor.
 * @param {Editor} editor The editor to insert into.
 * @param {string} conceptID The concept ID to use for the section header
 */
export const insertSectionHeader = ({
  editor,
  conceptID,
  viewerRefID,
  at,
}: {
  editor: Editor;
  conceptID: string;
  viewerRefID: string;
  at?: Path | Point | Range | undefined;
}) => {
  Transforms.insertNodes(
    editor,
    {
      type: SECTION_HEADER_PLUGIN_ID,
      conceptID,
      viewerRefID,
      children: [
        {
          text: conceptID,
        },
      ],
    },
    {
      at,
    }
  );
};

/**
 * Find a section header node in the editor by it's concept ID, returns void if nothing found
 * @param {Editor} editor The editor to search in
 * @param {string} conceptID The concept ID of the section header to match
 */
export const findNodeByIDs = ({
  editor,
  conceptID,
  viewerRefID,
}: {
  editor: Editor;
  conceptID: string;
  viewerRefID: string;
}): NodeEntry<SectionHeaderPluginElement> | null | undefined =>
  find<SectionHeaderPluginElement>(
    editor,
    (n) =>
      n.type === SECTION_HEADER_PLUGIN_ID &&
      n.conceptID === conceptID &&
      n.viewerRefID === viewerRefID,
    { at: [] }
  );

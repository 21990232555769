import type { EditorPlugin } from '../../types';
import { UnderlineEditable } from './components';
import { onKeyDownUnderline } from './onKeyDownUnderline';
import { getRenderLeaf } from '../../utils';
import { UNDERLINE_PLUGIN_ID } from './types';
import type { UnderlinePluginOptions, UnderlinePluginPropertyOptions } from './types';
import { MarkButton } from '../../components';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';

export const DEFAULT_UNDERLINE_HOTKEY = 'mod+u';

export const UnderlinePlugin = ({
  component = UnderlineEditable,
  pluginName = 'Underline',
  hotkey = DEFAULT_UNDERLINE_HOTKEY,
  icon = FormatUnderlinedIcon,
  ...rest
}: UnderlinePluginOptions = {}): EditorPlugin => {
  const options: UnderlinePluginPropertyOptions = {
    pluginID: UNDERLINE_PLUGIN_ID,
    pluginName,
    hotkey,
    icon,
    component,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    renderLeaf: getRenderLeaf(options),
    onKeyDown: onKeyDownUnderline(options),
    hoveringToolbarConfig: () => [
      {
        pluginID: options.pluginID,
        toolbarButton: (
          <MarkButton
            icon={options.icon}
            pluginID={options.pluginID}
            pluginName={options.pluginName}
          />
        ),
      },
    ],
  };
};

import { useMemo } from 'react';
import { useSplitFlag } from './useSplitFlag';
import type { FeatureFlag } from './featureFlags.generated';

type Enabled = boolean;
type Loading = boolean;

/**
 * Hook that covers the common use case of checking if a feature flag is enabled.
 * If the flag is not defined or the treatment is not recognized, it will default
 * to the flag being disabled.
 *
 * NOTE: This hook ONLY WORKS with feature flags treatments of 'on' and 'off'.
 * See useSplitFlag hook for feature flags with more complex treatments.
 */
export const useFeatureFlagEnabled = (flag: FeatureFlag): [Enabled, Loading] => {
  const [enabled, loading] = useSplitFlag(flag, 'off', (v) => v === 'on');
  return useMemo(() => [enabled, loading], [enabled, loading]);
};

import styled from 'styled-components';
import { Colors } from 'styles';
import Input from 'common/ui/Input';

export const SearchBar = styled(Input)`
  padding: 1px 6px;
  height: unset;
  background-color: ${Colors.gray1};
  color: ${Colors.gray10};
  border-color: ${Colors.gray8};
  display: flex;

  svg {
    height: 15px;
    width: 15px;
    color: ${Colors.gray10};
  }
`;

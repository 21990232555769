import type { Preference } from '.';

const keyboardShortcuts = {
  reporterPrevious: {
    id: 'REPORTER_PREVIOUS',
    description: 'Focus Previous',
    iconName: 'skip_previous',
    category: 'REPORTER',
  },
  reporterNext: {
    id: 'REPORTER_NEXT',
    description: 'Focus Next',
    iconName: 'skip_next',
    category: 'REPORTER',
  },
  reporterToggleDictation: {
    id: 'REPORTER_TOGGLE_DICTATION',
    description: 'Toggle Dictation',
    iconName: 'fiber_manual_record',
    category: 'REPORTER',
  },
  reporterSignReport: {
    id: 'REPORTER_SUBMIT_REPORT',
    description: 'Sign Report',
    iconName: 'submitReport',
    category: 'REPORTER',
  },
  reporterGenerateImpression: {
    id: 'REPORTER_GENERATE_IMPRESSION',
    description: 'Generate Impression',
    iconName: 'generateImpression',
    category: 'REPORTER',
  },
  reporterMedCheck: {
    id: 'REPORTER_MED_CHECK',
    description: 'Med Check',
    iconName: 'medCheck',
    category: 'REPORTER',
  },
  reporterDiscardReport: {
    id: 'REPORTER_DISCARD_REPORT',
    description: 'Discard Report',
    iconName: 'delete',
    category: 'REPORTER',
  },
  reporterDraftReport: {
    id: 'REPORTER_DRAFT_REPORT',
    description: 'Draft Report',
    iconName: 'save',
    category: 'REPORTER',
  },
} as const;

export const getKeyboardShortcutById = (
  id: string,
  interactionId?: string | null
): Preference | null | undefined => // $FlowFixMe[incompatible-use]
  // $FlowFixMe[incompatible-type]
  // @ts-expect-error [EN-7967] - TS2322 - Type '{ readonly id: "REPORTER_PREVIOUS"; readonly description: "Focus Previous"; readonly iconName: "skip_previous"; readonly category: "REPORTER"; } | { readonly id: "REPORTER_NEXT"; readonly description: "Focus Next"; readonly iconName: "skip_next"; readonly category: "REPORTER"; } | ... 5 more ... | { ...; }' is not assignable to type 'Preference'.
  Object.values(keyboardShortcuts).find((ks) => ks.id === id);

export default keyboardShortcuts;

import { Range } from 'domains/reporter/RichTextEditor/core';
import { Editor } from '../core';
import type { NodeEntry } from '../core';
import { PARAGRAPH_PLUGIN_ID } from '../plugins/paragraph/types';
import { HEADING_PLUGIN_ID } from '../plugins/heading/types';
import { Element } from 'slate';

export const getBlocksInRange = (editor: Editor, range?: Range): Array<NodeEntry> => {
  const selection = range ?? editor.selection;
  if (selection == null) {
    return [];
  }

  return Array.from(
    Editor.nodes(editor, {
      at: selection,
      match: (n) =>
        Element.isElement(n) && (n.type === PARAGRAPH_PLUGIN_ID || n.type === HEADING_PLUGIN_ID),
    })
  );
};

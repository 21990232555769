import { PathRef, ReactEditor } from 'domains/reporter/RichTextEditor/core';
import WarningIcon from '@material-ui/icons/Warning';
import { omit } from 'ramda';
import { useLayoutEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { css } from 'styled-components';
import {
  useSlateSingletonContext,
  useSlateSelectionSingletonContext,
} from '../../Reporter/SlateSingletonContext';
import { Editor } from 'slate';
import { Colors } from 'styles';
import { useRecoilState } from 'recoil';
import { touchedRequiredFieldsState } from '../../Reporter/state';
import { logger } from 'modules/logger';
import { getPathRefSafe } from '../utils/refHelpers';
import { env } from 'config/env';
import Tooltip from 'common/ui/Tooltip';

const Indicator = ({
  pathRef,
  unifiedEditorRef,
}: {
  pathRef: PathRef;
  unifiedEditorRef?: {
    current: HTMLElement | null | undefined;
  };
}): React.ReactElement | null => {
  const [popperElement, setPopperElement] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, state } = usePopper(referenceElement, popperElement, {
    placement: 'right',
    modifiers: [
      { name: 'offset', options: { offset: [0, 10] } },
      { name: 'arrow', options: { element: arrowElement } },
    ],
  });
  const [{ editor }] = useSlateSingletonContext();
  const [{ selection }] = useSlateSelectionSingletonContext();

  useLayoutEffect(() => {
    const updateReferenceElement = () => {
      if (editor == null || pathRef.current == null) {
        setReferenceElement(null);
        return;
      }
      try {
        const ref = getPathRefSafe(pathRef);
        const domRange = ReactEditor.toDOMRange(
          // @ts-ignore [prop-missing]
          editor,
          Editor.range(editor, ref)
        );
        setReferenceElement(domRange);
      } catch (e: any) {
        logger.error('Failed to get dom range for required field indicator', e);

        setReferenceElement(null);
      }
    };
    updateReferenceElement();
    const interval = setInterval(updateReferenceElement, 16); // 16ms is ~60fps
    return () => clearInterval(interval);
  }, [editor, pathRef, selection]);

  if (referenceElement == null) {
    return null;
  }

  const popperStyles = omit(['transform'], styles.popper || {});
  const arrowStyles = omit(['transform'], styles.arrowStyles || {});
  const popperOffsetY = state?.modifiersData?.popperOffsets?.y
    ? Math.round(state?.modifiersData?.popperOffsets?.y + 2)
    : null;
  const outOfView =
    referenceElement?.getBoundingClientRect().bottom >
      (unifiedEditorRef?.current?.getBoundingClientRect()?.bottom ?? 0) - 32 ||
    referenceElement?.getBoundingClientRect().top <
      (unifiedEditorRef?.current?.getBoundingClientRect()?.top ?? 0);

  return (
    <Tooltip content="Required field" backgroundColor={Colors.gray7} color={Colors.gray1}>
      <div
        ref={setPopperElement}
        {...(state?.attributes?.popper != null ? state.attributes.popper : {})}
        style={{
          margin: 0,
          ...(popperOffsetY != null ? { transform: `translate(0px, ${popperOffsetY}px)` } : {}),
        }}
        css={css`
          ${css(popperStyles)};
          background-color: transparent;
          display: flex;
          align-content: center;
          z-index: 0;
          height: 35px;
        `}
      >
        <div
          css={css`
            ${css(arrowStyles)};
            display: flex;
            align-content: center;
          `}
          ref={setArrowElement}
        >
          <WarningIcon
            data-testid="required-field-indicator"
            css={css`
              color: ${Colors.red5};
              font-size: 1.6rem;
              position: relative;
              left: 10px;
              top: 7px;
              display: ${(popperOffsetY != null && !outOfView) ||
              env.STORYBOOK_STORYSHOTS === 'true'
                ? 'block'
                : 'none'};
            `}
          />
        </div>
      </div>
    </Tooltip>
  );
};

export const RequiredFieldIndicators = ({
  unifiedEditorRef,
}: {
  unifiedEditorRef?: {
    current: HTMLElement | null | undefined;
  };
}): React.ReactElement => {
  const [touchedRequiredFields] = useRecoilState(touchedRequiredFieldsState);

  // @ts-expect-error [EN-7967] - TS2739 - Type 'Element[]' is missing the following properties from type 'ReactElement<any, string | JSXElementConstructor<any>>': type, props, key
  return Array.from(touchedRequiredFields).map(([reactKey, pathRef]: [any, any]) => (
    <Indicator key={reactKey.id} pathRef={pathRef} unifiedEditorRef={unifiedEditorRef} />
  ));
};

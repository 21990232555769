import type { EditorPlugin } from '../../types';
import { enhanceProvider } from './enhanceProvider';
import type {
  AnatomicNavigatorPluginOptions,
  AnatomicNavigatorPluginPropertyOptions,
} from './types';
import { ANATOMIC_NAVIGATOR_PLUGIN_ID } from './types';

/**
 * A plugin that will decorate the Reporter with links to various
 * anatomic locations. These locations will be supplied over the
 * broadcast channel from the viewer. They will be added in batch
 * or as a user is dictating.
 *
 * @param {AnatomicNavigatorPluginOptions} options - Plugin options
 */
export const AnatomicNavigatorPlugin = ({
  pluginName = 'Anatomic Navigator',
  ...rest
}: AnatomicNavigatorPluginOptions = {}): EditorPlugin => {
  const options: AnatomicNavigatorPluginPropertyOptions = {
    pluginID: ANATOMIC_NAVIGATOR_PLUGIN_ID,
    pluginName,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    enhanceProvider: enhanceProvider(options),
  };
};

import type {
  DehydratedViewportsConfigurations,
  StudyGroup,
} from '../ViewportsConfigurations/types';
import { useEffect, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import { useStudies, useBaseViewerData } from 'domains/viewer/Viewer/StudyLoader/useStudies';
import { usePreviewHangingProtocolConfiguration } from './usePreviewHangingProtocol';
import type { GetHangingProtocolsQuery } from 'generated/graphql';
import { dehydratedHangingProtocol } from 'domains/viewer/ViewportsConfigurations';
import type { ViewportsConfigurations } from 'domains/viewer/ViewportsConfigurations';
import type { Layouts } from '../Viewer/StudyLoader/viewerLoaderState';
import { hangingProtocolLayoutsState } from '../ViewportsConfigurations/state';
import { ViewType } from 'generated/graphql';
import { logger } from 'modules/logger';
import { hydrateViewportsConfigurations } from '../ViewportsConfigurations/manipulators';

export type HangingProtocol = GetHangingProtocolsQuery['hangingProtocols']['items'][0];

type UseHangingProtocol = {
  loadingHangingProtocol: boolean;
  hangingProtocol: HangingProtocol | null | undefined;
  hangingProtocolViewportsConfigurations: ViewportsConfigurations | null | undefined;
  hangingProtocolLayouts: Layouts | null | undefined;
};

const useMatchedHangingProtocol = ({
  groupedStudies,
  loading,
  studyDescriptions,
  cases,
}: {
  cases: Array<Array<string>>;
  groupedStudies: Array<StudyGroup>;
  loading: boolean;
  studyDescriptions: Array<string>;
}) => {
  const { hangingProtocol, loading: loadingHangingProtocol } = useBaseViewerData();
  return [hangingProtocol, loadingHangingProtocol];
};

export const useHangingProtocol = (): UseHangingProtocol => {
  const previewedHangingProtocol = usePreviewHangingProtocolConfiguration();
  const { loading, groupedStudies } = useStudies();

  const studyDescriptions: string[] = useMemo(
    () =>
      groupedStudies.flatMap((studies) =>
        studies.reduce<string[]>(
          (studies, { description }) => (description != null ? [...studies, description] : studies),
          []
        )
      ),
    [groupedStudies]
  );
  const cases = useMemo(() => {
    return groupedStudies.map((group) => group.map((study) => study.smid));
  }, [groupedStudies]);

  const [matchedHangingProtocol, matchedHangingProtocolLoading] = useMatchedHangingProtocol({
    groupedStudies,
    loading,
    studyDescriptions,
    cases,
  });

  const hangingProtocol = useMemo(
    () => previewedHangingProtocol || matchedHangingProtocol,
    [matchedHangingProtocol, previewedHangingProtocol]
  );

  const validateViewType = (viewType?: ViewType | null): ViewType => {
    if (!Object.values(ViewType).includes(viewType)) {
      logger.debug(`Invalid ViewType from Hanging Protocol viewType: ${viewType ?? 'null'}`);
    }
    return viewType ?? ViewType.TwoDDre;
  };

  const hangingProtocolViewportsConfigurations:
    | DehydratedViewportsConfigurations
    | null
    | undefined = useMemo(
    () =>
      // @ts-expect-error [EN-7967] - TS2339 - Property 'viewportsConfigurations' does not exist on type 'boolean | { readonly __typename?: "HangingProtocol"; readonly smid: string; readonly name: string; readonly numberOfPriors: number; readonly created: Date; readonly updated: Date; readonly studyDescriptions: readonly string[]; readonly studyCriteria?: JSON; readonly data: { ...; }; readonly viewportsConfigurations?:...'.
      hangingProtocol?.viewportsConfigurations?.reduce((acc: any, { id, configuration }) => {
        return {
          ...acc,
          [id]: {
            ...configuration,
            viewType: validateViewType(configuration.viewType),
          },
        };
      }, {}),
    // @ts-expect-error [EN-7967] - TS2339 - Property 'viewportsConfigurations' does not exist on type 'boolean | { readonly __typename?: "HangingProtocol"; readonly smid: string; readonly name: string; readonly numberOfPriors: number; readonly created: Date; readonly updated: Date; readonly studyDescriptions: readonly string[]; readonly studyCriteria?: JSON; readonly data: { ...; }; readonly viewportsConfigurations?:...'.
    [hangingProtocol?.viewportsConfigurations]
  );

  const setHangingProtocol = useSetRecoilState(dehydratedHangingProtocol);
  useEffect(() => {
    setHangingProtocol(hangingProtocolViewportsConfigurations);
  }, [setHangingProtocol, hangingProtocolViewportsConfigurations]);

  const hangingProtocolLayouts: Layouts | null | undefined = useMemo(() => {
    if (hangingProtocol == null) return;

    const layouts = Object.fromEntries(
      // @ts-expect-error [EN-7967] - TS2339 - Property 'data' does not exist on type 'boolean | { readonly __typename?: "HangingProtocol"; readonly smid: string; readonly name: string; readonly numberOfPriors: number; readonly created: Date; readonly updated: Date; readonly studyDescriptions: readonly string[]; readonly studyCriteria?: JSON; readonly data: { ...; }; readonly viewportsConfigurations?:...'.
      hangingProtocol.data.layouts.map(({ id, layout }) => [
        id,
        [layout[0], layout[1]], // converting the GraphQL array to a tuple
      ])
    );

    return layouts;
  }, [hangingProtocol]);

  const setHangingProtocolLayouts = useSetRecoilState(hangingProtocolLayoutsState);
  useEffect(() => {
    setHangingProtocolLayouts(hangingProtocolLayouts);
  }, [setHangingProtocolLayouts, hangingProtocolLayouts]);

  return {
    // @ts-expect-error [EN-7967] - TS2322 - Type 'boolean | { readonly __typename?: "HangingProtocol"; readonly smid: string; readonly name: string; readonly numberOfPriors: number; readonly created: Date; readonly updated: Date; readonly studyDescriptions: readonly string[]; readonly studyCriteria?: JSON; readonly data: { ...; }; readonly viewportsConfigurations?:...' is not assignable to type '{ readonly __typename?: "HangingProtocol"; readonly smid: string; readonly name: string; readonly numberOfPriors: number; readonly created: Date; readonly updated: Date; readonly studyDescriptions: readonly string[]; readonly studyCriteria?: JSON; readonly data: { ...; }; readonly viewportsConfigurations?: readonly ...'.
    hangingProtocol,
    // @ts-expect-error [EN-7967] - TS2322 - Type 'boolean | { readonly __typename?: "HangingProtocol"; readonly smid: string; readonly name: string; readonly numberOfPriors: number; readonly created: Date; readonly updated: Date; readonly studyDescriptions: readonly string[]; readonly studyCriteria?: JSON; readonly data: { ...; }; readonly viewportsConfigurations?:...' is not assignable to type 'boolean'.
    loadingHangingProtocol: matchedHangingProtocolLoading,
    hangingProtocolViewportsConfigurations:
      hangingProtocolViewportsConfigurations != null
        ? hydrateViewportsConfigurations(hangingProtocolViewportsConfigurations, groupedStudies)
        : undefined,
    hangingProtocolLayouts,
  };
};

import { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { useSendFocusEvent } from 'modules/focusManager';
import Tooltip from 'common/ui/Tooltip';
import { usePopupOptionsGetter } from 'hooks/useWindowsRects';
import { getPageType, PAGE_TYPES } from 'utils/pageTypes';
import type { PageTypes } from 'utils/pageTypes';
import { displayMessage } from './displayMessage';
import { Colors } from 'styles';
import type { GenericSnackBarProps } from 'common/GenericSnackBar/GenericSnackBar';
import { openWindow } from './openViewer';
import { MENU_WIDTH } from './constants';
import { useRef, useState } from 'react';
import type { SendFocusEvent } from 'modules/focusManager';
import type { PopupOptionsGetter } from 'hooks/useWindowsRects';
import {
  draftReportWorklistSmid,
  discardReportState,
  discardReportStateDefault,
} from 'domains/reporter/Reporter/state';
import { useSetRecoilState } from 'recoil';

/** Time to wait until the popup loads before enabling a subsequent window.open call. Prevents
 * multiple windows from opening during the period a popup's about:blank transitions to a route. */
export const WAIT_UNTIL_POPUP_LOADS_MS = 1000;

export const logEvents = ({
  pageType,
  sendEvent,
  viewerIndex,
}: {
  pageType: PageTypes;
  sendEvent: SendFocusEvent;
  viewerIndex: string;
}): void => {
  // Focus the other viewer if it is open. This way, both are easily accessible
  // to the viewer
  if (pageType === PAGE_TYPES.VIEWER) {
    sendEvent({ pageType, viewerId: viewerIndex });
  }

  // Timeout is necessary so that both events will fire
  setTimeout(() => {
    sendEvent({ pageType, viewerId: viewerIndex });
  }, 50);
};

type OnClickArguments = {
  to: string;
  openedViewerIds: Set<string>;
  isReporterOpen: boolean;
  isURTOpen: boolean;
  isWorklistOpen: boolean;
  isJacketOpen: boolean;
  setSnackbarMessage: (arg1: {
    message: React.ReactNode;
    severity: GenericSnackBarProps['severity'];
  }) => void;
  sendEvent: SendFocusEvent;
  viewerIndex: string;
  getPopupOptions: PopupOptionsGetter;
};

export const onNavMenuItemClick = ({
  to,
  openedViewerIds,
  isReporterOpen,
  isURTOpen,
  isWorklistOpen,
  isJacketOpen,
  setSnackbarMessage,
  sendEvent,
  viewerIndex,
  getPopupOptions,
}: OnClickArguments) => {
  const pageType = getPageType(to);

  displayMessage({
    openedViewerIds,
    isReporterOpen,
    setSnackbarMessage,
    viewerIndex,
    pageType,
  });
  openWindow({
    to,
    openedViewerIds,
    isReporterOpen,
    isURTOpen,
    isWorklistOpen,
    isJacketOpen,
    viewerIndex,
    getPopupOptions,
    pageType,
  });

  logEvents({ pageType, sendEvent, viewerIndex });
};

type LinkStyleProps = {
  $selected: boolean;
  $isRelatedWindowOpen: boolean;
};

export const MenuItem = ({
  selected,
  isRelatedWindowOpen,
  to,
  viewerIndex,
  children,
  label,
  setSnackbarMessage,
  openedViewerIds,
  isReporterOpen,
  isURTOpen,
  isWorklistOpen,
  isJacketOpen,
  isExternal = false,
  onClick,
  'data-testid': dataTestid,
  'aria-label': ariaLabel,
}: {
  selected: boolean;
  isRelatedWindowOpen: boolean;
  to: string;
  children: React.ReactNode;
  label: string;
  setSnackbarMessage: (arg1: {
    message: React.ReactNode;
    severity: GenericSnackBarProps['severity'];
  }) => void;
  viewerIndex: string;
  openedViewerIds: Set<string>;
  isReporterOpen: boolean;
  isURTOpen: boolean;
  isWorklistOpen: boolean;
  isJacketOpen: boolean;
  isExternal?: boolean;
  onClick?: (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  ['data-value']?: string;
  ['data-testid']?: string;
  ['aria-label']?: string;
}): React.ReactElement => {
  const sendEvent = useSendFocusEvent();
  const getPopupOptions = usePopupOptionsGetter();
  const [isLoadingReporter, setIsLoadingReporter] = useState<boolean>(false);
  const loadingTimeout = useRef<number | null | undefined>(null);
  const setDraftReportWorklistSmid = useSetRecoilState(draftReportWorklistSmid);
  const setDiscardReportOptions = useSetRecoilState(discardReportState);

  // Sets a 1s timer after the initial click to prevent the reporter from opening multiple windows.
  // The `isLoadingReporter` value controls whether `window.open` will be triggered.
  const queueReporterLoadingReset = () => {
    setIsLoadingReporter(true);
    loadingTimeout.current = window.setTimeout(() => {
      setIsLoadingReporter(false);
    }, WAIT_UNTIL_POPUP_LOADS_MS);
  };
  const clearLoadingTimeout = () => {
    if (loadingTimeout.current) {
      clearTimeout(loadingTimeout.current);
    }
  };

  const props = {
    'data-testid': dataTestid,
    'data-analytics-name': `navbar-link-${dataTestid ?? label}`,
    'data-page-open': isRelatedWindowOpen,
    'aria-label': ariaLabel,
    $selected: selected,
    $isRelatedWindowOpen: isRelatedWindowOpen,
  } as const;
  const styles = css`
    app-region: no-drag;
    color: ${(props: LinkStyleProps) =>
      props.$isRelatedWindowOpen && !props.$selected ? Colors.mainBlue : Colors.white};
    width: ${MENU_WIDTH};
    height: 5.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props: LinkStyleProps) =>
      props.$selected ? Colors.mainBlue36Percent : 'transparent'};
    transition: 0.2s ease-in-out;
    flex-shrink: 0;
    position: relative;
    &:hover {
      background-color: ${Colors.mainBlue6Percent};
      color: ${(props: LinkStyleProps) =>
        props.$isRelatedWindowOpen && !props.$selected ? Colors.blue6 : Colors.white};
    }
    &::after {
      content: '';
      height: ${(props: LinkStyleProps) =>
        props.$isRelatedWindowOpen && !props.$selected ? '20%' : '0%'};
      opacity: ${(props: LinkStyleProps) =>
        props.$isRelatedWindowOpen && !props.$selected ? 1 : 0};
      transition:
        height 0.5s ease-in-out,
        opacity 0.5s ease-in-out;
      position: absolute;
      right: 2px;
      border-radius: 2.5px;
      width: 3px;
      background-color: currentColor;
    }
  `;

  return (
    <Tooltip content={label} placement="right" backgroundColor={Colors.gray8} color={Colors.gray1}>
      {isExternal ? (
        <a href={to} target="_blank" rel="noopener noreferrer" {...props} css={styles}>
          {children}
        </a>
      ) : (
        <Link
          to={to}
          onClick={(evt) => {
            evt.preventDefault();
            if (onClick) {
              onClick(evt);
              return;
            }

            if (isLoadingReporter || isReporterOpen) {
              clearLoadingTimeout();
              queueReporterLoadingReset();
            }
            queueReporterLoadingReset();

            if (
              getPageType(to) === PAGE_TYPES.REPORTER &&
              !window.location.pathname.includes(PAGE_TYPES.REPORTER)
            ) {
              setDraftReportWorklistSmid(null);
              setDiscardReportOptions(discardReportStateDefault);
            }

            onNavMenuItemClick({
              to,
              openedViewerIds,
              isReporterOpen: isLoadingReporter || isReporterOpen,
              isURTOpen,
              isWorklistOpen,
              isJacketOpen,
              setSnackbarMessage,
              sendEvent,
              viewerIndex,
              getPopupOptions,
            });
          }}
          {...props}
          css={styles}
        >
          {children}
        </Link>
      )}
    </Tooltip>
  );
};

import { useState } from 'react';
import { useEventAction } from '../../modules/usePicker';
import type { PickerEvent } from '../../modules/usePicker';

function sameWithTolerance(a: number, b: number, tolerance: number) {
  return Math.abs(a - b) <= tolerance;
}

// Same as `useEventAction('click', callback)` but will abort in case the user
// moved the mouse between the `mouseDown` and `click` events.
export function useStillClickEventHandler(callback: (event: PickerEvent) => unknown) {
  const [mouseDownPosition, setMouseDownPosition] = useState(null);

  useEventAction('click', (event) => {
    const displayPosition = event.displayPosition;

    // If we don't have the mouse down position, we can't compare it to the
    // click position, so we bail out.
    if (mouseDownPosition == null || displayPosition == null) return;

    // If the mouse down and click positions are close enough, we consider
    // the click to be a "still" click.
    if (
      sameWithTolerance(mouseDownPosition.x, displayPosition.x, 5) &&
      sameWithTolerance(mouseDownPosition.y, displayPosition.y, 5)
    ) {
      callback(event);
    }
  });

  useEventAction('mouseDown', ({ displayPosition }) => setMouseDownPosition(displayPosition));
}

import { useState, useCallback } from 'react';
export const useAsyncError = (): ((arg1: Error) => void) => {
  const [, setError] = useState();
  return useCallback(
    (e) => {
      setError(() => {
        throw e;
      });
    },
    [setError]
  );
};

import type { CreateEnhanceEditorState } from '../../types';
import type { ParagraphPluginPropertyOptions } from './types';
import { Element, Range, Transforms, Editor, Node } from '../../core';
import { HEADING_PLUGIN_ID } from '../heading/types';
import { createInlineParagraph, isInlineParagraph } from './utils';

export const enhanceEditorStateParagraph: CreateEnhanceEditorState<
  ParagraphPluginPropertyOptions
> =
  ({ pluginID }) =>
  (editor: Editor) => {
    const { normalizeNode, deleteBackward } = editor;

    editor.deleteBackward = (unit) => {
      const { selection } = editor;
      if (!selection) return;

      if (Range.isCollapsed(selection)) {
        const paragraphEntry = Editor.node(editor, selection, {
          depth: 1,
        });

        if (paragraphEntry != null && isInlineParagraph(paragraphEntry[0])) {
          const [, paragraphPath] = paragraphEntry;
          if (Editor.isStart(editor, Range.end(selection), paragraphPath) && unit === 'character') {
            Transforms.move(editor, { distance: 1, reverse: true });
          }
        }
      }

      deleteBackward(unit);
    };

    editor.normalizeNode = (entry) => {
      const [node, path] = entry;
      const { selection } = editor;

      if (Element.isElement(node) && node.type === pluginID) {
        Editor.withoutNormalizing(editor, () => {
          const isCurrentNodeInline = entry != null && isInlineParagraph(entry[0]);
          const prevNodeEntry = Editor.previous<Node>(editor, { at: path });
          const nodeText = Node.string(node);
          const isCursorInNode =
            selection != null &&
            Range.includes(
              { anchor: Editor.start(editor, path), focus: Editor.end(editor, path) },
              selection
            );

          if (!isCurrentNodeInline && prevNodeEntry != null) {
            if (
              prevNodeEntry != null &&
              prevNodeEntry[0].type === HEADING_PLUGIN_ID &&
              Node.string(node) !== ''
            ) {
              /**
               * If I'm currently in a paragraph that follows a heading, and I'm currently not an inline
               * paragraph, this probably means something is wrong with our slate state. We expect that
               * when typing on the next line after a heading, we do not want it to jump back next to the
               * the heading above it. As a result, we add an extra inline paragraph between us and the heading
               * before us.
               *
               * For example, if we type "there is pain" at the cursor:
               *
               *   EXAM:
               *   |
               *
               * we WANT the end result to be:
               *
               *   EXAM:
               *   there is pain
               *
               * and NOT to be:
               *
               *   EXAM: there is pain
               *
               */
              if (isCursorInNode) {
                Transforms.insertNodes(editor, createInlineParagraph(), {
                  at: Editor.before(editor, path),
                });
                return;
              } else {
                /**
                 * If the node is a paragraph following a heading and is not currently inline,
                 *
                 * For example, if we set 'exam' as an H1 in the following text:
                 *
                 *   exam there is pain
                 *
                 * we WANT the end result to be:
                 *
                 *   EXAM: there is pain
                 *
                 * and NOT to be:
                 *
                 *   EXAM:
                 *   there is pain
                 *
                 */
                Transforms.setNodes(editor, { shouldForceInline: true }, { at: path });
                return;
              }
            }
          }

          const nextNodeEntry = Editor.next<Node>(editor, { at: path });
          if (nextNodeEntry != null) {
            const currChildren = node.children;

            /**
             * If two inline-styled paragraphs are next to each other,
             * merge them to be a single paragraph. This should only occur when you have
             * just selected a heading and set it back to a paragraph.
             *
             * For example, with the following slate state:
             *  [
             *    {
             *      type: "paragraph",
             *      children: [
             *        {text: "there"}
             *      ]
             *    },
             *    {
             *      type: "paragraph",
             *      shouldForceInline: true,
             *      children: [
             *        {text: "is"}
             *      ]
             *    },
             *    {
             *      type: "paragraph",
             *       shouldForceInline: true,
             *      children: [
             *        {text: "pain"}
             *      ]
             *    },
             *  ]
             *
             * we WANT the end result to be:
             *
             *  [
             *    {
             *      type: "paragraph",
             *      children: [
             *        {text: "there"}
             *      ]
             *    },
             *    {
             *      type: "paragraph",
             *      children: [
             *        {text: "is pain"},
             *      ]
             *    },
             *  ]
             *
             * In the editor, it would look like:
             *
             *  there
             *  is pain
             *
             **/
            if (
              isCurrentNodeInline &&
              isInlineParagraph(nextNodeEntry[0]) &&
              Array.isArray(currChildren)
            ) {
              Transforms.insertNodes(editor, nextNodeEntry[0].children, {
                at: [...path, currChildren.length],
              });
              Transforms.unsetNodes(editor, 'shouldForceInline', { at: path });
              Transforms.removeNodes(editor, { at: nextNodeEntry[1] });
              return;
            }
          }

          /**
           * If the node is an inline paragraph, and the previous entry is a paragraph
           * we should merge the two paragraphs.
           * */
          if (
            isCurrentNodeInline &&
            prevNodeEntry != null &&
            prevNodeEntry[0].type === 'paragraph' &&
            Array.isArray(node.children)
          ) {
            Transforms.insertNodes(editor, node.children, {
              at: [...prevNodeEntry[1], node.children.length],
            });
            Transforms.removeNodes(editor, { at: path });
            return;
          }

          // If I'm in an inline paragraph and there is no preceding node
          if (isCurrentNodeInline && prevNodeEntry == null) {
            if (nodeText.trim() === '') {
              Transforms.removeNodes(editor, { at: path });
            } else {
              Transforms.unsetNodes(editor, 'shouldForceInline', { at: path });
            }

            return;
          }
        });
      }
      normalizeNode(entry);
    };

    return editor;
  };

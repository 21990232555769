import { Range, Path } from 'domains/reporter/RichTextEditor/core';
import { Editor, Point } from '../core';

export function isEntireFieldSelected(
  editor: Editor,
  path?: Path | null,
  selection?: Range | null
): boolean {
  if (path == null || selection == null) {
    return false;
  }

  const beforeStart = Editor.before(editor, path);
  const afterEnd = Editor.after(editor, path);
  const selectionEdges = Editor.edges(editor, selection);

  if (beforeStart == null || afterEnd == null) {
    return false;
  }

  return (
    (Point.isBefore(selectionEdges[0], beforeStart) ||
      Point.equals(selectionEdges[0], beforeStart)) &&
    (Point.isAfter(selectionEdges[1], afterEnd) || Point.equals(selectionEdges[1], afterEnd))
  );
}

import { createContext, useContext, useEffect, useState } from 'react';

// View mode is handled by the renderer so we don't have to handle bootstrapping slate
// and styling within a contenteditable
export type EditableReporterVariant = 'report' | 'template' | 'fragment';
export type AllReporterVariant = EditableReporterVariant | 'view';

export type ReporterState = Readonly<{
  variant: AllReporterVariant;
  headlessMode: boolean;
  navMountPoint: HTMLElement | null | undefined;
}>;
export type ReporterDispatch = (
  arg1: ((arg1: ReporterState) => ReporterState) | ReporterState
) => void;

const ReporterStateContext = createContext<ReporterState | null | undefined>(undefined);
const ReporterDispatchContext = createContext<ReporterDispatch | null | undefined>(undefined);

export const ReporterProvider = ({
  variant,
  headlessMode,
  navMountPoint,
  children,
}: {
  variant: AllReporterVariant;
  children: React.ReactNode;
  headlessMode: boolean;
  navMountPoint?: HTMLElement | null | undefined;
}): React.ReactElement => {
  const [reporterState, setReporterState] = useState(() => ({
    variant,
    headlessMode,
    navMountPoint,
  }));

  useEffect(() => {
    setReporterState((s) => ({ ...s, variant }));
  }, [variant, setReporterState]);

  useEffect(() => {
    setReporterState((s) => ({ ...s, headlessMode }));
  }, [variant, headlessMode]);

  useEffect(() => {
    setReporterState((s) => ({ ...s, navMountPoint }));
  }, [variant, navMountPoint]);

  return (
    <ReporterStateContext.Provider value={reporterState}>
      <ReporterDispatchContext.Provider value={setReporterState}>
        {children}
      </ReporterDispatchContext.Provider>
    </ReporterStateContext.Provider>
  );
};

export const useReporterState = (): ReporterState => {
  const reporterState = useContext(ReporterStateContext);

  if (!reporterState) {
    throw new Error('useReporterState must be used within a ReporterProvider.');
  }

  return reporterState;
};

export const useReporterDispatch = (): ReporterDispatch => {
  const reporterDispatch = useContext(ReporterDispatchContext);

  if (!reporterDispatch) {
    throw new Error('useReporterDispatch must be used within a ReporterProvider.');
  }

  return reporterDispatch;
};

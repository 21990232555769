import { format } from 'date-fns';
import { pipe, values, mapObjIndexed } from 'ramda';
import type { WorklistItem, GetWorklistItemQuery } from 'generated/graphql';
import type { PlaceholderFieldID, PlaceholderFieldItem } from './types';
import { toLocaleString } from 'utils/date';
import type { GroupedWorklistItem } from 'hooks/useCurrentWorklistItems';

// TODO: RP-3212 - Fix WorklistItem types
type LooseWorklistItem =
  | WorklistItem
  | NonNullable<GetWorklistItemQuery['worklistItem']>
  | GroupedWorklistItem;

export const PLACEHOLDER_DELIMITER_LEFT = '<';
export const PLACEHOLDER_DELIMITER_RIGHT = '>';

export const PLACEHOLDER_FIELDS_DICT = {
  age: {
    name: 'Age',
    resolve(worklistItem?: LooseWorklistItem | null): string | null | undefined {
      return worklistItem?.patientAge;
    },
  },
  currentDate: {
    name: 'Current Date',
    resolve(worklistItem?: LooseWorklistItem | null): string | null | undefined {
      return toLocaleString(new Date());
    },
  },
  currentTime: {
    name: 'Current Time',
    resolve(worklistItem?: LooseWorklistItem | null): string | null | undefined {
      return format(new Date(), 'h:mm:ss a');
    },
  },
  dose: {
    name: 'Dose',
    resolve(worklistItem?: LooseWorklistItem | null): string | null | undefined {
      return worklistItem?.dosage;
    },
  },
  gender: {
    name: 'Gender',
    resolve(worklistItem?: LooseWorklistItem | null): string | null | undefined {
      return worklistItem?.patientSex;
    },
  },
  laterality: {
    name: 'Laterality',
    resolve(worklistItem?: LooseWorklistItem | null): string | null | undefined {
      return worklistItem?.studies?.[0]?.laterality;
    },
  },
  reasonForStudy: {
    name: 'Reason for Study',
    resolve(worklistItem?: LooseWorklistItem | null): string | null | undefined {
      return worklistItem?.studyReason;
    },
  },
  referringProvider: {
    name: 'Referring Provider',
    resolve(worklistItem?: LooseWorklistItem | null): string | null | undefined {
      return worklistItem?.referringPhysician;
    },
  },
  studyDate: {
    name: 'Study Date',
    resolve(worklistItem?: LooseWorklistItem | null): string | null | undefined {
      if (worklistItem?.studyDate != null) {
        return toLocaleString(new Date(worklistItem?.studyDate));
      }
      // fallback option
      if (worklistItem?.studies?.[0]?.studyDate) {
        return toLocaleString(new Date(worklistItem.studies[0].studyDate));
      }
      return null;
    },
  },
  studyDescription: {
    name: 'Study Description',
    resolve(worklistItem?: LooseWorklistItem | null): string | null | undefined {
      return worklistItem?.studyDescription;
    },
  },
  studyTime: {
    name: 'Study Time',
    resolve(worklistItem?: LooseWorklistItem | null): string | null | undefined {
      if (worklistItem?.studyDate != null) {
        return format(new Date(worklistItem?.studyDate), 'h:mm:ss a');
      }
      // fallback option
      if (worklistItem?.studies?.[0]?.studyDate) {
        return format(new Date(worklistItem.studies[0].studyDate), 'h:mm:ss a');
      }
      return null;
    },
  },
  techNotes: {
    name: 'Tech Notes',
    resolve(worklistItem?: LooseWorklistItem | null): string | null | undefined {
      return worklistItem?.techNotes;
    },
  },
} as const;

/**
 * This is the list of placeholders which should display multiple values if there are
 * multiple worklist items. Check GET_GROUPED_WORKLIST_ITEMS in queries.js for which
 * fields are queried for and can populate placeholders.
 * This is an object instead of an array to account for the fact that some worklist item
 * properties do not match their corresponding placeholderID.
 * */
export const PLACEHOLDER_FIELDS_TO_APPLY_TO_LINK_CASES = {
  studyDescription: 'studyDescription',
  reasonForStudy: 'studyReason',
  laterality: 'laterality',
} as const;
export type PlaceholderIDToApplyToLinkCases =
  keyof typeof PLACEHOLDER_FIELDS_TO_APPLY_TO_LINK_CASES;
export type PlaceholderFieldToApplyToLinkCases =
  (typeof PLACEHOLDER_FIELDS_TO_APPLY_TO_LINK_CASES)[keyof typeof PLACEHOLDER_FIELDS_TO_APPLY_TO_LINK_CASES];
export const PLACEHOLDER_FIELDS: PlaceholderFieldItem[] = pipe(
  mapObjIndexed(
    (
      value: {
        name: string;
        resolve(worklistItem?: LooseWorklistItem | null | undefined): string | null | undefined;
      },
      key: PlaceholderFieldID
    ) => ({
      ...value,
      key,
    })
  ),
  values
)(PLACEHOLDER_FIELDS_DICT);

import { memo, useMemo } from 'react';
import { PolyData } from 'react-vtk-js';
import { equals } from 'ramda';
import { stripConsecutiveEqualPoints } from '../../utils/points';

const pointsToLines = (points: Array<number>) => {
  return [points.length / 3, ...Array.from({ length: points.length / 3 }).map((_, i) => i)];
};

type LinearPolyDataProps = {
  points: Array<number>;
  fill?: 'lines' | 'polys' | 'verts' | 'strips';
};

/**
 * This function is memoized since it takes an array of points that
 * changes identity on each render, this causes VTK to attempt to
 * re-render the polydata.
 */
export const LinearPolyData: React.ComponentType<LinearPolyDataProps> = memo(
  ({ points, fill = 'lines' }: LinearPolyDataProps): React.ReactElement => {
    const uniquePoints = useMemo(() => {
      return stripConsecutiveEqualPoints(points);
    }, [points]);

    const props = useMemo(() => {
      return {
        [String(fill)]: pointsToLines(uniquePoints),
      };
    }, [uniquePoints, fill]);

    return <PolyData points={uniquePoints} {...props} />;
  },
  (next, prev) => {
    return equals(next.points, prev.points) && next.fill === prev.fill;
  }
);
LinearPolyData.displayName = 'LinearPolyData';

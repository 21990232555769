import { GenericSnackbarContent } from 'common/GenericSnackBar/GenericSnackBar';
import type { GenericSnackBarContentProps } from 'common/GenericSnackBar/GenericSnackBar';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import type { SnackBarIcon } from 'common/GenericSnackBar';

export type ToastKey = string;

export type ToastPosition =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'center-right'
  | 'bottom-right'
  | 'bottom-center'
  | 'bottom-left'
  | 'center-left';

export type ToastProps = Readonly<
  GenericSnackBarContentProps & {
    /**
     * Unique identifier to reference a snackbar.
     * @default random unique string
     */
    toastKey: ToastKey;
    /**
     * Called when the timeout is fulfilled or the user clicks the close button.
     */
    onClose?: (toastKey: string) => void;
    /**
     * The number of milliseconds to wait before automatically calling the
     * `onClose` function. `onClose` should then set the state of the `open`
     * prop to hide the Snackbar. This behavior is disabled by default with
     * the `null` value.
     *
     * @default 4000
     */
    autoHideDuration?: number | null;
    /**
     * Where to position the toast on the screen.
     */
    position?: ToastPosition;
    /**
     * Offset from normal vertical position of the toast on the screen
     *
     * @default 0
     */
    yOffset?: number;
    /**
     * Should the Toast render only once. Defaults `false`
     */
    isUnique?: boolean;
    /**
     * Icon to display at the left of the SnackBar, before the message
     */
    icon?: SnackBarIcon;
  }
>;

export const Toast = ({
  onClose,
  toastKey,
  autoHideDuration = 4000,
  message,
  position = 'top-right',
  isUnique = false,
  yOffset = 0,
  ...rest
}: ToastProps): React.ReactElement => {
  useEffect(() => {
    if (autoHideDuration == null) return;

    const timeoutId = setTimeout(() => {
      onClose?.(toastKey);
    }, autoHideDuration);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [toastKey, autoHideDuration, onClose]);

  return (
    <motion.div
      layout={true}
      initial={{ opacity: 0, y: 50 + yOffset, scale: 0.3 }}
      animate={{ opacity: 1, y: yOffset, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
      data-testid="toast"
    >
      <GenericSnackbarContent message={message} onClose={() => onClose?.(toastKey)} {...rest} />
    </motion.div>
  );
};

import { Range, Node } from 'domains/reporter/RichTextEditor/core';
import type { NodeEntry, RenderElementProps, RenderLeafProps } from '../core';
import { compose, prop, uniqBy } from 'ramda';
import { createContext, useMemo, useCallback, useContext, useEffect, useRef } from 'react';
import { createEditorWarning } from '../utils';
import type { AllPluginID } from '../plugins';
import type { GamepadActionId } from 'generated/graphql';
import type {
  Decorate,
  DeserializeHtml,
  EnhanceEditable,
  EnhanceEditorState,
  EnhanceProvider,
  EnhanceRenderElement,
  EditorPlugin,
  OnDOMBeforeInput,
  OnKeyDown,
  OnDictaphoneButtonPress,
  RenderElement,
  RenderLeaf,
  SerializeHtml,
  HoveringToolbarConfig,
  ToolbarConfig,
  EditorBag,
} from '../types';
import { ParagraphPlugin } from '../plugins/paragraph/paragraphPlugin';
import { HistoryPlugin } from '../plugins/history/historyPlugin';
import { logger } from 'modules/logger';
import { LineBreakPlugin } from '../plugins/lineBreak/lineBreakPlugin';

/**
 * Default plugins are loaded by default into a rich text editor instance.
 * If a user passes a plugin of the same type, they will be overridden.
 */
const DEFAULT_PLUGINS: EditorPlugin[] = [ParagraphPlugin(), HistoryPlugin(), LineBreakPlugin()];

export type PluginsBag = Readonly<{
  activatedPlugins: AllPluginID[];
  getEditableEnhancers: () => EnhanceEditable[];
  getProviderEnhancers: () => EnhanceProvider[];
  getEditorStateEnhancers: () => EnhanceEditorState[];
  getHoveringToolbarConfigs: () => HoveringToolbarConfig[];
  getToolbarConfigs: () => ToolbarConfig[];
  decoratePlugins: (editorBag: EditorBag) => (entry: NodeEntry<Node>) => Range[];
  renderLeafPlugins: (leafProps: RenderLeafProps) => React.ReactElement;
  renderElementPlugins: (elementProps: RenderElementProps) => React.ReactElement;
  onKeyDownPlugins: (editorBag: EditorBag) => (event: KeyboardEvent) => void;
  onDOMBeforeInputPlugins: (editorBag: EditorBag) => (event: Event) => void;
  onDictaphoneButtonPresses: (editorBag: EditorBag) => (action: GamepadActionId) => void;
}>;

type ParsedPlugins = Readonly<{
  pluginIDs: AllPluginID[];
  enhanceProviders: EnhanceProvider[];
  enhanceEditables: EnhanceEditable[];
  enhanceEditorStates: EnhanceEditorState[];
  enhanceRenderElements: EnhanceRenderElement[];
  renderElements: RenderElement[];
  onDOMBeforeInputs: OnDOMBeforeInput[];
  onDictaphoneButtonPresses: OnDictaphoneButtonPress[];
  onKeyDowns: OnKeyDown[];
  renderLeafs: RenderLeaf[];
  decorates: Decorate[];
  deserializes: DeserializeHtml[];
  serializes: SerializeHtml[];
  hoveringToolbarConfigs: HoveringToolbarConfig[];
  toolbarConfigs: ToolbarConfig[];
}>;

const PluginsContext = createContext<PluginsBag | null | undefined>(undefined);

export type PluginsProviderProps = Readonly<{
  plugins: EditorPlugin[];
  children: React.ReactNode;
}>;

export const parsePlugins = (plugins: EditorPlugin[]): ParsedPlugins => {
  // uniqBy puts precedence on the first argument - so duplicate plugins in the first
  // argument will overwrite default plugins
  const pluginsToParse: EditorPlugin[] = uniqBy(prop('pluginID'), [...plugins, ...DEFAULT_PLUGINS]);

  return pluginsToParse.reduce(
    (accu, item: EditorPlugin) => {
      if (item.pluginID) {
        accu.pluginIDs.push(item.pluginID);
      }
      if (item.enhanceProvider) {
        accu.enhanceProviders.push(item.enhanceProvider);
      }
      if (item.enhanceEditable) {
        accu.enhanceEditables.push(item.enhanceEditable);
      }
      if (item.enhanceEditorState) {
        accu.enhanceEditorStates.push(item.enhanceEditorState);
      }
      if (item.enhanceRenderElement) {
        accu.enhanceRenderElements.push(item.enhanceRenderElement);
      }
      if (item.onDOMBeforeInput) {
        accu.onDOMBeforeInputs.push(item.onDOMBeforeInput);
      }
      if (item.onDictaphoneButtonPress) {
        accu.onDictaphoneButtonPresses.push(item.onDictaphoneButtonPress);
      }
      if (item.onKeyDown) {
        accu.onKeyDowns.push(item.onKeyDown);
      }
      if (item.renderLeaf) {
        accu.renderLeafs.push(item.renderLeaf);
      }
      if (item.renderElement) {
        accu.renderElements.push(item.renderElement);
      }
      if (item.decorate) {
        accu.decorates.push(item.decorate);
      }
      if (item.deserialize) {
        accu.deserializes.push(item.deserialize);
      }
      if (item.serialize) {
        accu.serializes.push(item.serialize);
      }
      if (item.hoveringToolbarConfig) {
        accu.hoveringToolbarConfigs.push(item.hoveringToolbarConfig);
      }
      if (item.toolbarConfig) {
        accu.toolbarConfigs.push(item.toolbarConfig);
      }

      return accu;
    },
    {
      pluginIDs: [],
      enhanceProviders: [],
      enhanceEditables: [],
      enhanceEditorStates: [],
      enhanceRenderElements: [],
      renderElements: [],
      onDOMBeforeInputs: [],
      onDictaphoneButtonPresses: [],
      onKeyDowns: [],
      renderLeafs: [],
      decorates: [],
      deserializes: [],
      serializes: [],
      hoveringToolbarConfigs: [],
      toolbarConfigs: [],
    }
  );
};

type RenderElementComponentProps = Readonly<{
  enhanceRenderElements: EnhanceRenderElement[];
  renderElement: RenderElement;
  elementProps: RenderElementProps;
}>;

const RenderElementComponent = ({
  enhanceRenderElements,
  renderElement,
  elementProps,
}: RenderElementComponentProps) => {
  // @ts-expect-error [EN-7967] - TS2322 - Type '(props: any, deprecatedLegacyContext?: any) => (props: unknown) => ReactElement<any, string | JSXElementConstructor<any>>' is not assignable to type 'ComponentType<any>'.
  const ComposedComponent: React.ComponentType<any> = useMemo(
    // @ts-expect-error [EN-7967] - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
    () => compose(...enhanceRenderElements, renderElement),
    [enhanceRenderElements, renderElement]
  );

  return <ComposedComponent {...elementProps} />;
};

export type UsePluginsStaticProps = Readonly<{
  plugins: EditorPlugin[];
}>;

/**
 * For testing purposes only!
 *
 * This parses and returns the plugin bag to be consumed by the app. This operates as a pure
 * function and does not place the plugins into context. You do not want to use this most likely!
 * Instead, use usePlugins and wrap PluginsProvider around the editor.
 */
export const usePluginsStatic = ({ plugins }: UsePluginsStaticProps): PluginsBag => {
  /**
   * We're in contenteditable world, so everything is weird. What's happening here is that we
   * are parsing all of the plugins and setting the return value to a mutable ref. Then, we manually
   * keep the ref in sync if any props passed into the plugins change. This method used to be one big memoized
   * function that returned a bag of props, but that causes a weird side effect.
   *
   * Slate expects a function for renderElement. When we create it here and new plugin props come,
   * it runs again and creates a new function in memory. That new, memory allocated function is then passed
   * to Slate, causing every component inside the contenteditable to unmount from the DOM. This is bad!
   * This causes the user's selection to be reset, flickers the component, and can cause cannot resolve
   * DOM node errors. I suspect this is due to a WeakMap inside of Slate clearing out when the unmount happens
   * since a bunch of objects likely get garbage collected. Then, it has a stale object that was held due to it being a
   * singleton (likely editor.selection) and throws.
   *
   * I don't like this code and open to better ideas. The right way to fix this would be:
   *
   * - Don't let plugins have props that change often. This should be the case, but since we're integrating into what we
   *   have, it's a ton of refactoring to get it there.
   * - Use context instead of prop drilling through plugin options
   * - Slate accepts an invoked component instead of a function so the React Reconciler can better know not to unmount. I doubt
   *   we can slide this in without forking.
   */
  const pluginsBag = useRef(parsePlugins(plugins));

  useEffect(() => {
    pluginsBag.current = parsePlugins(plugins);
  }, [plugins]);

  const getEditableEnhancers = useCallback(() => pluginsBag.current.enhanceEditables, []);
  const getProviderEnhancers = useCallback(() => pluginsBag.current.enhanceProviders, []);
  const getEditorStateEnhancers = useCallback(() => pluginsBag.current.enhanceEditorStates, []);

  const getHoveringToolbarConfigs = useCallback(
    () => pluginsBag.current.hoveringToolbarConfigs,
    []
  );
  const getToolbarConfigs = useCallback(() => pluginsBag.current.toolbarConfigs, []);

  const decoratePlugins = useCallback(
    (editorBag: EditorBag) => (entry: NodeEntry) => {
      return pluginsBag.current.decorates
        .map((decorate) => (decorate ? decorate(entry, editorBag) : []))
        .flat(1);
    },
    []
  );

  const renderElementPlugins = useCallback((elementProps: RenderElementProps) => {
    /**
     * This code is bit misleading as to what's happening. Plugins can write HOCs that wrap
     * elements that are rendered into Slate. What we're doing here is calling each element with the
     * props to see if it renders something. If it does, that's what we want to serve up to Slate
     * to render for a given node.
     *
     * Once that's decided, we call the RenderElementComponent component that calls any HOCs to wrap
     * the selected element. It does lead us to invoking an element twice, but I haven't seen any performance
     * problems using this pattern. Open to better ideas!
     */
    const renderElement = pluginsBag.current.renderElements.find((renderElement) =>
      renderElement(elementProps)
    );

    if (renderElement != null) {
      return (
        <RenderElementComponent
          elementProps={elementProps}
          enhanceRenderElements={pluginsBag.current.enhanceRenderElements}
          renderElement={renderElement}
        />
      );
    }

    const missingPluginWarning = `No element found for node type '${String(
      elementProps.element?.type
    )}'! Using a fallback component instead. This is likely not what you want and will cause issues when using nested nodes. Did you forget to inject a plugin?`;

    createEditorWarning(missingPluginWarning);
    logger.warn(missingPluginWarning, {
      pluginIDs: pluginsBag.current.pluginIDs,
    });
    return <div {...elementProps.attributes}>{elementProps.children}</div>;
  }, []);

  const renderLeafPlugins = useCallback((leafProps: RenderLeafProps) => {
    pluginsBag.current.renderLeafs.forEach((renderLeaf) => {
      leafProps.children = renderLeaf(leafProps);
    });

    return <span {...leafProps.attributes}>{leafProps.children}</span>;
  }, []);

  const onKeyDownPlugins = useCallback(
    (editorBag: EditorBag) => (event: Event) => {
      pluginsBag.current.onKeyDowns.some((onKeyDown) =>
        // @ts-ignore [incompatible-cast] we are passing onKeyDown events
        onKeyDown(event as KeyboardEvent, editorBag)
      );
    },
    []
  );

  const onDOMBeforeInputPlugins = useCallback(
    (editorBag: EditorBag) => (event: Event) => {
      pluginsBag.current.onDOMBeforeInputs.some((onDOMBeforeInput) =>
        onDOMBeforeInput(event, editorBag)
      );
    },
    []
  );

  const onDictaphoneButtonPresses = useCallback(
    (editorBag: EditorBag) => (action: GamepadActionId) => {
      pluginsBag.current.onDictaphoneButtonPresses.some((onDictaphoneButtonPress) =>
        onDictaphoneButtonPress(action, editorBag)
      );
    },
    []
  );

  return {
    activatedPlugins: pluginsBag.current.pluginIDs,
    getEditableEnhancers,
    getProviderEnhancers,
    getHoveringToolbarConfigs,
    getToolbarConfigs,
    getEditorStateEnhancers,
    decoratePlugins,
    renderLeafPlugins,
    renderElementPlugins,
    onKeyDownPlugins,
    onDOMBeforeInputPlugins,
    onDictaphoneButtonPresses,
  };
};

export const PluginsProvider = ({
  children,
  plugins,
}: PluginsProviderProps): React.ReactElement => {
  const pluginsBag = usePluginsStatic({ plugins });
  return <PluginsContext.Provider value={pluginsBag}>{children}</PluginsContext.Provider>;
};

export const usePlugins = (): PluginsBag => {
  const pluginContext = useContext(PluginsContext);

  if (!pluginContext) {
    throw new Error('usePlugins must be used within a PluginsProvider.');
  }

  return pluginContext;
};

import type { Vector3 as vec3 } from '@kitware/vtk.js/types';
import { assertion } from '@recoiljs/refine';
import { Suspense, useContext, useEffect, useMemo } from 'react';
import { Handles } from '../helpers/Handles';
import { useHover } from '../helpers/useHover';
import { useMoveAnnotation } from '../helpers/useMoveAnnotation';
import type { Segment } from '../types';
import { CURSOR_NODE } from '../helpers/cursors';
import { encodeId } from '../../utils/representationId';
import { Contexts } from 'react-vtk-js';
import { primitivesConfig } from './primitives';
import type { Primitive } from './primitives';
import type {
  AnnotationStatus,
  BaseAnnotationProps,
} from '../../../AnnotationsManager/annotationCreators';
import { useAnnotationReactiveRender } from '../../modules/useAnnotationReactiveRender';
import { executeToolAnnotationLogic, useQuickJsContext } from './logic';
import { useViewCamera } from '../../modules/useViewCamera';
import equals from 'lodash/fp/equals';
import { useStableValue } from 'hooks/useEqualValue';
import type { IView } from 'react-vtk-js';

export type Configuration = Readonly<{
  primitives: ReadonlyArray<Primitive>;
  maxPoints: number;
  completeOnDoubleClick: boolean;
}>;

type CustomAnnotationProps = BaseAnnotationProps & {
  annotationLogic: string;
  onConfigurationUpdate?: (arg1: Configuration) => unknown;
  mousePosition?: vec3 | null | undefined;
  segments: ReadonlyArray<Segment>;
  status?: AnnotationStatus;
  // This is only used for backwards compatibility with the old
  // annotation logic. It will be removed once the old logic is
  // removed and we start using points instead of segments.
  // All the new annotations will always use a list of segments
  // that directly map to the points of the annotation.
  handlesMapper?: JSX.LibraryManagedAttributes<
    typeof Handles,
    React.ComponentProps<typeof Handles>
  >['mapper'];
};

export const defaultConfiguration: Configuration = Object.freeze({
  primitives: [],
  maxPoints: Infinity,
  completeOnDoubleClick: false,
});

const defaultHandlersMapper = (segmentIndex: number, pointIndex: number, mousePosition: vec3) => [
  [segmentIndex, pointIndex, mousePosition],
];

function LazyCustomAnnotation({
  id,
  visible,
  onConfigurationUpdate,
  annotationLogic,
  mousePosition,
  segments,
  allowedTransformations = { move: false, transform: false },
  status = 'idle',
  // @ts-expect-error [EN-7967] - TS2322 - Type '(segmentIndex: number, pointIndex: number, mousePosition: vec3) => (number | vec3)[][]' is not assignable to type '(segmentIndex: number, pointIndex: number, mousePosition: Vector3) => SegmentPointPositionTuple[]'.
  handlesMapper = defaultHandlersMapper,

  // TODO: We should support multiple labels for multiple annotations
  label,
}: CustomAnnotationProps): React.ReactElement | null {
  const view = useContext<IView | null | undefined>(Contexts.ViewContext);
  const camera = useViewCamera(view);

  const quickJsContext = useQuickJsContext(annotationLogic);

  const unstableConfiguration = useMemo(() => {
    let configuration: Configuration | null | undefined = defaultConfiguration;
    if (segments.length === 0) return configuration;

    const points = segments.flatMap((segment) => segment);

    configuration = executeToolAnnotationLogic({
      context: quickJsContext,
      annotationLogic,
      points,
      mousePosition,
      camera,
      status,
      label,
    });

    return configuration;
  }, [segments, quickJsContext, annotationLogic, mousePosition, camera, status, label]);
  const configuration = useStableValue(unstableConfiguration, equals);

  useEffect(() => {
    if (onConfigurationUpdate != null && configuration != null) {
      onConfigurationUpdate(configuration);
    }
  }, [configuration, onConfigurationUpdate]);

  const { hovered, ...hoverProps } = useHover({ targetId: id, allowedTransformations });
  const dragHandlers = useMoveAnnotation({ annotationId: id, allowedTransformations });

  useAnnotationReactiveRender(configuration?.primitives.length);

  if (configuration == null || configuration.primitives.length === 0) {
    return null;
  }

  return (
    <>
      {configuration.primitives.map((item, index) => {
        const primitive = primitivesConfig.find(({ type }) => type === item.type);

        if (!primitive) {
          throw new Error(`Unknown primitive type: ${item.type}`);
        }
        const { renderer: Primitive, checker } = primitive;

        // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'Checker<Readonly<CommonPrimitiveProps & { type: "line"; start: Vector3; end: Vector3; }>> | Checker<Readonly<CommonPrimitiveProps & { ...; }>> | Checker<...>' is not assignable to parameter of type 'Checker<Readonly<CommonPrimitiveProps & { type: "line"; start: Vector3; end: Vector3; }>>'.
        const { type, ...primitiveConfiguration } = assertion(checker)(item);

        // NOTE(fzivolo): I can't make Flow work, here, it generates an union of all the props types
        // and an union of all the Primitive types but they don't end up being compatible
        let props: any = { ...primitiveConfiguration };
        if (status === 'idle') {
          if (allowedTransformations.move === true) {
            props = { ...props, ...dragHandlers, ...hoverProps };
          }
        }

        return (
          <Primitive
            {...props}
            visible={visible}
            cursor={CURSOR_NODE}
            key={index}
            id={encodeId({ id, name: `${type}-${index}` })}
          />
        );
      })}

      {allowedTransformations.transform === true && status === 'idle' && (
        <Handles
          visible={visible === true && status === 'idle'}
          opacity={hovered ? 1 : 0}
          annotationId={id}
          segments={segments}
          mapper={handlesMapper}
          {...hoverProps}
        />
      )}
    </>
  );
}

export const CustomAnnotation = (props: CustomAnnotationProps): React.ReactElement => (
  <Suspense fallback={null}>
    <LazyCustomAnnotation {...props} />
  </Suspense>
);

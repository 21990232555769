import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import type { Vector3 as vec3 } from '@kitware/vtk.js/types';
import { splitEvery } from 'ramda';
import { ellipse } from 'shape-points';
import * as config from '../config';
import { hexToVTKColor } from '../utils/colors';
import { LinearPolyData } from './helpers/LinearPolyData';
import { useScale } from '../modules/useScale';
import type { MouseHandlers } from '../types';
import { withCommonPrimitiveHelpers, transformCoordinate } from './helpers/common';
import { DisplayLocation } from '@kitware/vtk.js/Rendering/Core/Property2D/Constants';
import { slicingModeState, useViewportId } from '../modules/state';
import BatchedGeometry2DRepresentation from './helpers/BatchedGeometry2DRepresentation';
import type { BasePrimitiveProps } from './PrimitiveTypes';
import { useImagingContext } from '../modules/imaging/ImagingContext';
import { useAnnotationColors } from '../Annotations/helpers/useAnnotationColors';

type CircleProps = BasePrimitiveProps & {
  point: vec3;
  radius: number;
  pointsInArc?: number;
  fill?: boolean;
  forceScale?: boolean;
};

export const Circle: React.ComponentType<CircleProps & MouseHandlers> = withCommonPrimitiveHelpers(
  ({
    id,
    point,
    radius,
    color,
    pointsInArc = 10,
    fill = false,
    thickness = config.thickness,
    visible = true,
    forceScale = false,
  }: CircleProps) => {
    const { imagingProvider } = useImagingContext();
    const indexPoint = imagingProvider?.worldToIndex(point) ?? [0, 0, 0];
    const viewportId = useViewportId();
    const slicingModeIndex = useRecoilValue(slicingModeState(viewportId));
    const scale = useScale(true);
    const radiusWithScale = scale.map((s) => (fill || forceScale ? radius * s : radius));
    const ijScale = radiusWithScale.filter((v, i) => i !== slicingModeIndex);
    const kSlice = indexPoint[slicingModeIndex];
    const ijSlices = indexPoint.filter((v, i) => i !== slicingModeIndex);

    const circleSegments = useMemo(
      () =>
        // 'radius' is used in index space here, so compensate for voxel-spacing.
        // Use of ellipse is required because the scaling can be anisotropic.
        splitEvery(2, ellipse(ijSlices[0], ijSlices[1], ijScale[0], ijScale[1], pointsInArc))
          .map((xyPoint) => {
            xyPoint.splice(slicingModeIndex, 0, kSlice);
            return xyPoint;
          })
          .map((imageSpaceEllipsePoint: vec3) => {
            const point = imagingProvider?.indexToWorld(imageSpaceEllipsePoint);
            if (!point) {
              console.error('Unable to localize point');
              return imageSpaceEllipsePoint;
            }
            return point;
          })
          .flat(1),
      [pointsInArc, imagingProvider, ijSlices, kSlice, ijScale, slicingModeIndex]
    );
    const { defaultColor } = useAnnotationColors();
    const property = useMemo(
      () => ({
        opacity: 1,
        color: hexToVTKColor(color ?? defaultColor),
        displayLocation: DisplayLocation.FOREGROUND,
        lineWidth: thickness,
      }),
      [color, defaultColor, thickness]
    );

    const points = useMemo(
      () => [...circleSegments, ...circleSegments.slice(0, 3)],
      [circleSegments]
    );

    const actorProperties = useMemo(
      () => ({
        visibility: visible,
      }),
      [visible]
    );

    return (
      <BatchedGeometry2DRepresentation
        id={id}
        // @ts-expect-error [EN-7967] - TS2559 - Type '{ visibility: boolean; }' has no properties in common with type 'Partial<IActor2DInitialValues>'.
        actor={actorProperties}
        property={property}
        transformCoordinate={transformCoordinate}
      >
        <LinearPolyData fill={!fill ? 'lines' : 'polys'} points={points} />
      </BatchedGeometry2DRepresentation>
    );
  }
);

import type {
  GetWorklistFilterDropdownItemsQuery,
  GetWorklistFilterDropdownItemsQueryVariables,
} from 'generated/graphql';
import { useMemo, useEffect, useState } from 'react';

import { GET_WORKLIST_FILTER_DROPDOWN_ITEMS } from 'modules/Apollo/queries';
import { useQuery } from '@apollo/client';
import { useDebouncedState } from 'hooks/useDebouncedState';
import type { MultiSelectFilterItemType } from '../types';

const DEFAULT_CLIENT_SIDE_LIMIT = 50;

export type WorklistFilterDropdown = {
  isLoading: boolean;
  items: Array<MultiSelectFilterItemType>;
  shouldUseClientSideSearch?: boolean;
  search: string;
  setSearch: (arg1: string) => void;
};

export const useWorklistFilterDropdown = (
  filterName: string,
  limit: number = DEFAULT_CLIENT_SIDE_LIMIT
): WorklistFilterDropdown => {
  const [search, debouncedSearch, setSearch] = useDebouncedState('', 500);
  const [initialTotalCount, setInitialTotalCount] = useState(0);

  const { data, loading } = useQuery<
    GetWorklistFilterDropdownItemsQuery,
    GetWorklistFilterDropdownItemsQueryVariables
  >(GET_WORKLIST_FILTER_DROPDOWN_ITEMS, {
    fetchPolicy: 'cache-and-network',
    variables: { searchValue: debouncedSearch, limit, fieldName: filterName },
  });

  // We only want this to run once
  useEffect(() => {
    setInitialTotalCount(data?.worklistFilterDropdownItems?.totalCount ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items = useMemo(
    () =>
      (data?.worklistFilterDropdownItems?.items ?? [])
        .map((item) =>
          item.value !== '' && item.value != null
            ? {
                value: item.value,
                label: item.label,
                count: item.count,
              }
            : null
        )
        .filter((item) => item != null),
    [data]
  );

  return {
    // $FlowFixMe[incompatible-return] We filter out the nulls
    items,
    shouldUseClientSideSearch:
      initialTotalCount <= DEFAULT_CLIENT_SIDE_LIMIT || items.length === initialTotalCount,
    search,
    setSearch,
    isLoading: loading,
  };
};

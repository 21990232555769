import styled from 'styled-components';

const TableOverlayDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  cursor: not-allowed;
`;

export const TableOverlay = (props: { message: string }): React.ReactElement => {
  return <TableOverlayDiv>{props.message}</TableOverlayDiv>;
};

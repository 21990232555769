import type { CreateOnKeyDown } from '../../types';
import isHotkey from 'is-hotkey';
import { Transforms, Editor } from '../../core';
import type { ParagraphPluginPropertyOptions } from './types';
import { BRACKET_TYPES } from '../../constants';
import { logger } from 'modules/logger';
import { createParagraph, isInlineParagraph } from './utils';
import { Element } from 'slate';

export const onKeyDownParagraph: CreateOnKeyDown<ParagraphPluginPropertyOptions> =
  ({ pluginID, allowNewLines = true }) =>
  (e: KeyboardEvent, { editor }) => {
    const selection = editor.selection;
    if (isHotkey('enter', e) && selection != null) {
      if (allowNewLines === false) {
        e.preventDefault();
        return true;
      }

      try {
        const nodeEntry = Editor.node(editor, selection, { depth: 1 });
        if (
          nodeEntry != null &&
          Element.isElement(nodeEntry[0]) &&
          nodeEntry[0].type === pluginID
        ) {
          const [bracketSelected] =
            Editor.above(editor, {
              at: selection,
              match: (n) => Element.isElement(n) && BRACKET_TYPES.includes(n.type),
              mode: 'lowest',
            }) ?? [];

          // If this is a regular text node inside a paragraph, we want to add
          // a paragraph. If not, we want to handle it in onKeyDownInlineTemplate
          if (bracketSelected == null) {
            e.preventDefault();
            if (nodeEntry != null && isInlineParagraph(nodeEntry[0])) {
              Transforms.insertNodes(editor, createParagraph());
            } else {
              Transforms.splitNodes(editor, { always: true, mode: 'highest' });
            }
            return true;
          }
        }
      } catch (e: any) {
        logger.error('[onKeyDownHeading] Error occurred when pressing "enter" in a paragraph', e);
      }
    }

    if (isHotkey('shift+enter', e) && selection != null) {
      if (allowNewLines === false) {
        e.preventDefault();
        return true;
      }
    }
  };

import { Contexts } from 'react-vtk-js';
import type { Vector3 as vec3 } from '@kitware/vtk.js/types';
import { add } from '@kitware/vtk.js/Common/Core/Math';
import { thickness as defaultThickness } from '../config';

import { useRef, useContext, useState, useEffect, useMemo, useLayoutEffect } from 'react';
import { useLatest, useMountedState } from 'react-use';
import { rafThrottle } from 'utils/rafThrottle';
import { useViewportId } from '../modules/state';
import { encodeId } from '../utils/representationId';
import type { MouseHandlers } from '../types';
import { quickVec3Comparison, worldToDisplayOffset } from '../utils/math';
import { Polygon } from './Polygon';
import { withCommonPrimitiveHelpers } from './helpers/common';
import { getElementBox, useElementBox } from 'utils/useElementBox';
import { Circle } from '.';
import { CURSOR_NODE } from '../Annotations/helpers/cursors';
import { useOpenGLRenderWindow } from '../modules/useOpenGLRenderWindow';
import { useRenderer } from '../modules/useRenderer';
import { useAnnotationColors } from '../Annotations/helpers/useAnnotationColors';
import { Expander } from '../Primitives';
import type { IView } from 'react-vtk-js';
import { Editable } from './Editable';

const HORIZONTAL_ALIGNMENT = {
  top: '0%',
  left: '-100%',
  center: '-50%',
  bottom: '100%',
  right: '0%',
} as const;
const VERTICAL_ALIGNMENT = {
  top: '0%',
  left: '-100%',
  center: '50%',
  bottom: '100%',
  right: '0%',
} as const;

const FLEX_ALIGNMENT = {
  top: 'flex-start',
  left: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
  right: 'flex-end',
} as const;

// line-height (default of 20) / 2, action is a small square;
const ACTION_SIZE = 20;
const ACTION_PADDING = 2;

type DisplayRectProps = {
  cursor: string;
  id: string;
  rect: null | {
    bottom: number;
    left: number;
    right: number;
    top: number;
  };
  z: number;
  visible?: boolean;
  opacity?: number;
  fill?: boolean;
  thickness?: number;
  color?: string;
};

const getWorldPoint = (
  x?: any | number,
  y?: any | number,
  z?: any | number,
  view?: IView | null
) => {
  const renderer = view?.getRenderer()?.get();
  const result =
    x != null && y != null && renderer != null
      ? view
          ?.getOpenGLRenderWindow()
          ?.get()
          .displayToWorld(x * window.devicePixelRatio, y * window.devicePixelRatio, z, renderer)
      : null;

  if (result == null) return null;

  return [result[0], result[1], result[2]];
};

const DisplayRect = ({
  id,
  rect,
  z,
  cursor,
  visible,
  opacity = 0,
  fill = true,
  thickness = defaultThickness,
  color,
}: DisplayRectProps) => {
  const view = useContext<IView | null | undefined>(Contexts.ViewContext);

  const topLeft = getWorldPoint(rect?.left, rect?.top, z, view);
  const bottomLeft = getWorldPoint(rect?.left, rect?.bottom, z, view);
  const topRight = getWorldPoint(rect?.right, rect?.top, z, view);
  const bottomRight = getWorldPoint(rect?.right, rect?.bottom, z, view);
  if (topLeft == null || bottomLeft == null || topRight == null || bottomRight == null) return null;

  return (
    <Polygon
      id={id}
      fill={fill ? 'polys' : 'lines'}
      opacity={opacity}
      cursor={cursor}
      thickness={thickness}
      color={color}
      segments={[
        // @ts-expect-error [EN-7967] - TS2322 - Type 'any[]' is not assignable to type 'Vector3'. | TS2322 - Type 'any[]' is not assignable to type 'Vector3'.
        [topLeft, topRight],
        // @ts-expect-error [EN-7967] - TS2322 - Type 'any[]' is not assignable to type 'Vector3'. | TS2322 - Type 'any[]' is not assignable to type 'Vector3'.
        [topRight, bottomRight],
        // @ts-expect-error [EN-7967] - TS2322 - Type 'any[]' is not assignable to type 'Vector3'. | TS2322 - Type 'any[]' is not assignable to type 'Vector3'.
        [bottomRight, bottomLeft],
        // @ts-expect-error [EN-7967] - TS2322 - Type 'any[]' is not assignable to type 'Vector3'. | TS2322 - Type 'any[]' is not assignable to type 'Vector3'.
        [bottomLeft, topLeft],
      ]}
    />
  );
};

type LabelElementProps = {
  alignHorizontal: string;
  alignVertical: string;
  bottom?: number;
  children: React.ReactNode;
  color: string;
  opacity: number;
  cursor: string;
  id: string;
  left: number;
  right?: number;
  top: number;
  viewportId: string;
  z: number;
  visible?: boolean;
  orientWithinBoundingBox?: boolean;
  borderPadding?: number;
  showBorder?: boolean;
  ['data-testid']?: string;
  expandable: boolean;
  expanded: boolean;
  onExpandChanged?: (expanded: boolean) => void;
  editable?: boolean;
  onEdit?: () => void;
};

const LabelElement = ({
  children,
  id,
  viewportId,
  color,
  opacity,
  left,
  right,
  top,
  bottom,
  z,
  alignHorizontal,
  alignVertical,
  cursor,
  visible,
  orientWithinBoundingBox,
  borderPadding = 3,
  // @ts-expect-error [EN-7967] - TS2339 - Property 'actionSize' does not exist on type 'LabelElementProps'.
  actionSize = ACTION_SIZE,
  // @ts-expect-error [EN-7967] - TS2339 - Property 'actionPadding' does not exist on type 'LabelElementProps'.
  actionPadding = ACTION_PADDING,
  showBorder = false,
  'data-testid': dataTestId,
  expandable,
  expanded: defaultExpanded,
  onExpandChanged,
  onEdit,
  editable = false,
}: LabelElementProps) => {
  const view = useContext<IView | null | undefined>(Contexts.ViewContext);
  const [expanded, setExpanded] = useState(defaultExpanded);
  const canvasRect = useElementBox(view?.getOpenGLRenderWindow()?.get().getCanvas());
  const labelRef = useRef(null);
  const [rect, setRect] = useState(null);
  // Align the label off enough with border to not overlap annotation
  const computedLeft = left - (showBorder ? borderPadding + 1 : 0);

  useLayoutEffect(() => {
    if (labelRef.current == null || canvasRect == null) return;

    const labelRect = getElementBox(labelRef.current);

    const r = {
      left: labelRect.left - canvasRect.left,
      top: canvasRect.height - (labelRect.top - canvasRect.top),
      right: labelRect.left - canvasRect.left + labelRect.width,
      bottom: canvasRect.height - (labelRect.top - canvasRect.top + labelRect.height),
    } as const;

    setRect((rect) => {
      if (
        rect == null ||
        r.left !== rect.left ||
        r.top !== rect.top ||
        r.right !== rect.right ||
        r.bottom !== rect.bottom
      ) {
        return r;
      }

      return rect;
    });
  }, [canvasRect, left, bottom, children]);

  // orientWithinBoundingBox decides how our styling will look
  // we have the ability to orient the label within the bounding box
  // or relative to an anchor point, using vertical and horizontal alignment
  // if orientWithinBoundingBox is true, we use flexbox to align the label within the bounding box
  // if orientWithinBoundingBox is false, we use transform to align the label relative to the anchor point

  const flexProps = `
    display: flex;
    align-items: ${FLEX_ALIGNMENT[alignVertical]};
    justify-content: ${FLEX_ALIGNMENT[alignHorizontal]};
    width: ${(right ?? 0) - left}px;
    height: ${(bottom ?? 0) - top}px;
    transform: translatey(100%);
  `;

  const justifyProps = `
    transform: translate(${HORIZONTAL_ALIGNMENT[alignHorizontal]}, ${VERTICAL_ALIGNMENT[alignVertical]});
  `;

  const expandedRect =
    rect != null
      ? {
          left: rect.left - borderPadding,
          right: rect.right + borderPadding,
          top: rect.top + borderPadding,
          bottom: rect.bottom - borderPadding,
        }
      : null;

  const topPoint = rect != null ? rect.top - actionSize / 2 : 0; // inline with center of text

  let leftPoint = rect != null ? rect.right - borderPadding - actionPadding : 0;

  const expanderVisible = showBorder && expandable && onExpandChanged != null;
  const editableVisible = showBorder && editable && onEdit != null;

  const expandedPoint = expanderVisible ? getWorldPoint(leftPoint, topPoint, z, view) : null;

  if (expanderVisible) {
    leftPoint -= actionSize;
  }

  const editablePoint = editableVisible ? getWorldPoint(leftPoint, topPoint, z, view) : null;

  let padding = 0;

  if (expanderVisible) {
    padding += actionSize + actionPadding;
  }

  if (editableVisible) {
    padding += actionSize;
  }

  return (
    <div
      id={encodeId({ viewportId }, id)}
      data-testid={dataTestId}
      role="note"
      css={`
        pointer-events: none;
        user-select: none;
        position: absolute;
        color: ${color};
        opacity: ${opacity};
        /* The following helps with contrast between the text and the background */
        text-shadow: 0px 0px 2px rgb(0 0 0 / 60%);
        white-space: nowrap;
        ${orientWithinBoundingBox === true ? flexProps : justifyProps}
      `}
      style={{
        left: computedLeft / window.devicePixelRatio,
        bottom: bottom / window.devicePixelRatio,
      }}
    >
      <div
        ref={labelRef}
        css={`
          > [data-element='label-header'] {
            padding-right: ${padding}px;
          }

          > [data-element='label-header']:has(+ [data-element='label-header']) {
            display: none;
          }
        `}
      >
        {(expandable || editable) && <LabelHeader>&nbsp;</LabelHeader>}
        {children}
        {(editableVisible || expanderVisible) && (
          <div style={{ position: 'absolute', top: 0, right: 0, pointerEvents: 'auto' }}>
            {editableVisible && editablePoint && onEdit && (
              <Editable
                id={id}
                onClick={() => {
                  onEdit();
                }}
              />
            )}
            {expanderVisible && expandedPoint && onExpandChanged && (
              <Expander
                id={id}
                expanded={expanded}
                onClick={() => {
                  setExpanded(!expanded);
                  onExpandChanged(!expanded);
                }}
              />
            )}
          </div>
        )}
      </div>

      <DisplayRect id={id} rect={rect} z={z} cursor={cursor} />
      {showBorder && (
        <DisplayRect
          id={id}
          rect={expandedRect}
          z={z}
          cursor={cursor}
          fill={false}
          opacity={1}
          thickness={1}
          color={color}
        />
      )}
    </div>
  );
};

type LabelProps = {
  id: string;
  children: React.ReactNode;
  /** Label origin position */
  anchorPoint: vec3;
  /** Text bounding box top left position */
  topLeftBoundingBoxPoint?: vec3;
  /** Text bounding box bottom right position */
  bottomRightBoundingBoxPoint?: vec3;
  /** Provide a vector to define the direction in which to offset the label */
  offsetDirection?: vec3;
  /** Offset distance in pixels */
  offsetDistance?: number;
  color?: string;
  opacity?: number;
  alignVertical?: 'center' | 'top' | 'bottom';
  alignHorizontal?: 'left' | 'right' | 'center';
  cursor?: string;
  visible?: boolean;
  orientWithinBoundingBox?: boolean;
  ['data-testid']?: string;
  showAnchorPoint?: boolean;
  showBorder?: boolean;
  hoverProps?: Partial<MouseHandlers> & {
    color: string;
  };
  expandable?: boolean;
  expanded?: boolean;
  onExpandChanged?: (expanded: boolean) => void;
  onEdit?: () => void;
  editable?: boolean;
} & MouseHandlers;

export const LabelHeader: React.ComponentType<{
  children: React.ReactNode;
}> = ({ children }) => {
  return <div data-element="label-header">{children}</div>;
};

export const Label: React.ComponentType<LabelProps & MouseHandlers> = withCommonPrimitiveHelpers(
  ({
    id,
    children,
    anchorPoint,
    topLeftBoundingBoxPoint,
    bottomRightBoundingBoxPoint,
    offsetDirection = [0, 0, 0],
    offsetDistance = 0,
    color,
    opacity = 1,
    alignVertical = 'center',
    alignHorizontal = 'center',
    cursor = 'default',
    visible = true,
    orientWithinBoundingBox = false,
    showAnchorPoint = false,
    showBorder = false,
    hoverProps,
    expandable = false,
    expanded = false,
    onExpandChanged,
    onEdit,
    editable = false,
    ...rest
  }: LabelProps): React.ReactElement | null => {
    const view = useContext<IView | null | undefined>(Contexts.ViewContext);
    const openglRenderWindow = useOpenGLRenderWindow();
    const renderer = useRenderer();
    const interactor = view?.getRenderWindow()?.getInteractor();
    const viewportId = useViewportId();

    const [displayCoordinates, setDisplayCoordinates] = useState(
      openglRenderWindow && renderer
        ? add(
            openglRenderWindow.worldToDisplay(
              anchorPoint[0],
              anchorPoint[1],
              anchorPoint[2],
              renderer
            ),
            worldToDisplayOffset(offsetDirection, offsetDistance, openglRenderWindow, renderer),
            [0, 0, 0]
          )
        : null
    );

    // We destructure the array so that we can easily memoize the throttled
    // event callback below.
    // the bounding box is optional, we may just get a point to orient around
    const [pointX, pointY, pointZ] = topLeftBoundingBoxPoint ?? anchorPoint;
    const [bottomX, bottomY, bottomZ] = bottomRightBoundingBoxPoint ?? [0, 0, 0];

    const [boundingBoxCoordinates, setBoundingBoxCoordinates] = useState(
      openglRenderWindow && renderer
        ? add(
            openglRenderWindow.worldToDisplay(bottomX, bottomY, bottomZ, renderer),
            worldToDisplayOffset(offsetDirection, offsetDistance, openglRenderWindow, renderer),
            [0, 0, 0]
          )
        : null
    );
    const latestDisplayCoordinates = useLatest(displayCoordinates);
    const latestBoundingBoxCoordinates = useLatest(boundingBoxCoordinates);
    const isMounted = useMountedState();

    // This is throttled because the `onRenderEvent` callback is called once for each
    // rendered element, but we only want to update the display coordinates once per
    // render.
    // TODO(fzivolo): Replace this with a better solution once provided by kitware.
    // https://github.com/Kitware/react-vtk-js/issues/23#issuecomment-915103030
    const throttledRenderEventCallback = useMemo(
      () =>
        rafThrottle(() => {
          if (
            renderer == null ||
            openglRenderWindow == null ||
            !isMounted() ||
            !visible ||
            renderer.isDeleted()
          )
            return;

          const newDisplayCoordinates = add(
            openglRenderWindow.worldToDisplay(pointX, pointY, pointZ, renderer),
            worldToDisplayOffset(offsetDirection, offsetDistance, openglRenderWindow, renderer),
            [0, 0, 0]
          );

          const newBoundingBoxCoordinates = add(
            openglRenderWindow.worldToDisplay(bottomX, bottomY, bottomZ, renderer),
            worldToDisplayOffset(offsetDirection, offsetDistance, openglRenderWindow, renderer),
            [0, 0, 0]
          );

          if (
            latestDisplayCoordinates.current == null ||
            !quickVec3Comparison(newDisplayCoordinates, latestDisplayCoordinates.current)
          ) {
            setDisplayCoordinates(newDisplayCoordinates);
            setBoundingBoxCoordinates(newBoundingBoxCoordinates);
          }
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pointX, pointY, pointZ, offsetDirection, offsetDistance, renderer, openglRenderWindow]
    );

    // We use the useLatest hook to improve the overall performance
    // avoiding to update the `onRenderEvent` callback on each render
    const latestThrottledRenderEventCallback = useLatest(throttledRenderEventCallback);
    useEffect(
      () => {
        if (interactor == null) return;

        const subscription = interactor.onRenderEvent(() =>
          // @ts-expect-error [EN-7967] - TS2554 - Expected 1 arguments, but got 0.
          latestThrottledRenderEventCallback.current?.()
        );

        return () => {
          subscription.unsubscribe();
        };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [interactor]
    );

    const latestDisplayCoordinatesValue = latestDisplayCoordinates.current;
    const latestBoundingBoxCoordinatesValue = latestBoundingBoxCoordinates.current;

    const { defaultColor } = useAnnotationColors();

    if (
      displayCoordinates == null ||
      latestDisplayCoordinatesValue == null ||
      !visible ||
      latestBoundingBoxCoordinatesValue == null
    )
      return null;

    // displayCoordinates is currently (left, bottom) relative to the canvas, so
    // adjust to be relative to renderer.
    const size = openglRenderWindow?.getSize() ?? [0, 0];
    // @ts-expect-error [EN-7967] - TS2488 - Type 'number[] | vtkViewport' must have a '[Symbol.iterator]()' method that returns an iterator.
    const [xmin, ymin] = renderer?.getViewport() ?? [0, 0];
    let [left, bottom, z] = latestDisplayCoordinatesValue;
    let [right, top] = latestBoundingBoxCoordinatesValue;
    left -= size[0] * xmin;
    bottom -= size[1] * ymin;
    top -= size[1] * ymin;
    right -= size[0] * xmin;

    return (
      <>
        {showAnchorPoint && (
          <Circle
            id={encodeId({ name: 'dot' }, id)}
            point={anchorPoint}
            radius={7}
            fill
            cursor={CURSOR_NODE}
            visible={visible}
            {...hoverProps}
          />
        )}
        <LabelElement
          id={id}
          data-testid={rest['data-testid']}
          viewportId={viewportId}
          color={color ?? defaultColor}
          opacity={opacity}
          alignHorizontal={alignHorizontal}
          alignVertical={alignVertical}
          orientWithinBoundingBox={orientWithinBoundingBox}
          left={left}
          bottom={bottom}
          right={right}
          top={top}
          z={z}
          cursor={cursor}
          showBorder={showBorder}
          expandable={expandable}
          expanded={expanded}
          editable={editable}
          onExpandChanged={onExpandChanged}
          onEdit={onEdit}
        >
          {children}
        </LabelElement>
      </>
    );
  }
);

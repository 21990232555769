import { useMemo } from 'react';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useCurrentCase } from 'hooks/useCurrentCase';

export function useCurrentCaseClaimedStatus(): {
  isClaimedBySomeoneElse: boolean;
  isClaimedByMe: boolean;
  isClaimed: boolean;
  isArchived: boolean;
} {
  const { currentCase } = useCurrentCase();
  const { data } = useCurrentUser();
  const me = data?.me;

  const status = useMemo(() => {
    const isClaimedBySomeoneElse =
      currentCase != null &&
      me != null &&
      currentCase.claimedBy?.id != null &&
      currentCase.claimedBy?.id !== me.id;
    const isClaimedByMe =
      currentCase != null &&
      me != null &&
      currentCase.claimedBy?.id != null &&
      currentCase.claimedBy?.id === me.id;
    const isClaimed = currentCase != null && currentCase.claimedBy?.id != null;
    const isArchived = currentCase?.status === 'ARCHIVE';

    return {
      isClaimed,
      isClaimedByMe,
      isClaimedBySomeoneElse,
      isArchived,
    };
  }, [currentCase, me]);

  return status;
}

import { Flow } from 'flow-to-typescript-codemod';
import type {
  WorkspacePresetsFieldsFragment,
  GetWorkspacesPreferencesQuery,
  GetWorkspacesPreferencesQueryVariables,
  CreateWorkspacePresetMutation,
  CreateWorkspacePresetMutationVariables,
  UpdateWorkspacePresetMutation,
  UpdateWorkspacePresetMutationVariables,
  SetWorkspaceAutoApplyMutation,
  SetWorkspaceAutoApplyMutationVariables,
  DeleteWorkspacePresetMutation,
  DeleteWorkspacePresetMutationVariables,
  WindowType,
  WindowState,
  ScreenName,
} from 'generated/graphql';
import { memo, useEffect, useState, useCallback, useRef } from 'react';
import {
  makeStyles,
  Dialog,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Switch,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import DoneIcon from '@material-ui/icons/Done';
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { css, keyframes } from 'styled-components';
import type { Keyframes } from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { equals, splitEvery } from 'ramda';
import { BroadcastChannel } from 'broadcast-channel';
import useBroadcastChannel from 'hooks/useBroadcastChannel';
import {
  GET_WORKSPACES_PREFERENCES,
  CREATE_WINDOW_STATE,
  UPDATE_WINDOW_STATE,
  SET_WORKSPACE_AUTO_APPLY,
  DELETE_WORKSPACE_PRESET,
} from 'modules/Apollo/queries';
import Input from 'common/ui/Input';
import { EMPTY } from 'config/constants';
import { useCurrentCaseId } from 'hooks/useCurrentCase';
import { getScreenName } from '../utils/screenNames';
import {
  addExtensionListener,
  applyWorkspacePresetEvent,
  initEvent,
  refreshEvent,
  removeExtensionListener,
  dispatchExtensionEvent,
} from 'domains/extension/extensionEventCreators';
import { RESPONSE_EVENTS as EXTENSION_RESPONSE_EVENTS } from 'domains/extension/constants';

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    paddingLeft: 45,
  },
});

const flashAnimation = ({
  rect: { top = 0, left = 0, bottom = window.innerHeight, right = window.innerWidth },
}: {
  rect: DOMRect;
}): Keyframes => keyframes`
0% {
  border-radius: 0;
}
95% {
  opacity: 1;
  border-radius: 10px;
  top: ${Math.round(top)}px;
  left: ${Math.round(left)}px;
  bottom: ${Math.round(window.innerHeight - bottom)}px;
  right: ${Math.round(window.innerWidth - right)}px;
}

100% {
  opacity: 0;
  border-radius: 10px;
  top: ${Math.round(top)}px;
  left: ${Math.round(left)}px;
  bottom: ${Math.round(window.innerHeight - bottom)}px;
  right: ${Math.round(window.innerWidth - right)}px;
}
`;

const CameraFlash = ({ rect }: { rect: DOMRect }) => (
  <div
    css={css`
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: white;
      z-index: 1;
      animation-name: ${flashAnimation({ rect })};
      animation-duration: 0.5s;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      pointer-events: none;
    `}
  />
);

const KEY: 'workspace-manager' = 'workspace-manager';

type WorkspaceManagerBroadcastChannelMessage = {
  type: 'close' | 'open' | 'flash';
};

export const useWorkspaceManagerChannel =
  (): BroadcastChannel<WorkspaceManagerBroadcastChannelMessage> =>
    useBroadcastChannel<WorkspaceManagerBroadcastChannelMessage>(KEY);

const useFlash = ({ refetch }: { refetch: () => Promise<unknown> }) => {
  const [capturing, setCapturing] = useState(false);
  const didUnmount = useRef(false);
  useEffect(
    () => () => {
      didUnmount.current = true;
    },
    []
  );
  const flash = useCallback(() => {
    setCapturing(true);
    setTimeout(() => {
      if (didUnmount.current === false) {
        refetch();
        setCapturing(false);
      }
    });
  }, [refetch]);

  return [capturing, flash, setCapturing];
};

export type BrowserWindow = {
  id: string;
  width: number;
  height: number;
  top: number;
  left: number;
  type: WindowType;
  state: WindowState;
  tabs: Array<{
    url: string;
  }>;
  screen: ScreenName;
};

type Detail = {
  instanceId: string;
  windows: Array<
    Flow.Diff<
      BrowserWindow,
      {
        screen: ScreenName;
      }
    >
  >;
};

type SystemInfo = {
  instanceId: string;
  windows: Array<
    Flow.Diff<
      BrowserWindow,
      {
        tabs: Array<{
          url: string;
        }>;
      }
    >
  >;
};

export const mapSystemInfo = (detail: Detail): SystemInfo => ({
  instanceId: detail.instanceId,
  windows: Array.from(
    new Map(
      detail.windows
        .map((window) => ({
          id: window.id,
          width: window.width,
          height: window.height,
          top: window.top,
          left: window.left,
          type: window.type,
          state: window.state,
          screen: getScreenName(window.tabs[0].url),
        }))
        .map((item) => [item.screen, item])
    ).values()
  ),
});

const getSystemInfo = () =>
  new Promise((resolve) => {
    // @ts-expect-error [EN-7967] - TS2322 - Type '({ detail }: { detail: any; }) => void' is not assignable to type 'EventListener'.
    const handler: EventListener = ({ detail }) => {
      removeExtensionListener(EXTENSION_RESPONSE_EVENTS.SYSTEM_INFO_UPDATED, handler);
      const systemInfo = mapSystemInfo({
        ...detail,
        windows: detail.windows.filter(
          (w) => w.tabs.length > 0 && w.tabs[0].url.includes(window.location.hostname)
        ),
      });

      resolve({
        ...systemInfo,
        windows: Array.from(
          new Map(systemInfo.windows.map((item) => [item.screen, item])).values()
        ),
      });
    };
    dispatchExtensionEvent(refreshEvent());
    addExtensionListener(EXTENSION_RESPONSE_EVENTS.SYSTEM_INFO_UPDATED, handler);
  });

export const computeSingleDisplayGrid = (
  windows: Array<
    Flow.Diff<
      BrowserWindow,
      {
        tabs: unknown;
      }
    >
  >
): Array<
  Flow.Diff<
    BrowserWindow,
    {
      tabs: unknown;
    }
  >
> => {
  const root = Math.ceil(Math.sqrt(windows.length));

  const width = Math.floor(window.screen.availWidth / root);
  const height = Math.floor(
    window.screen.availHeight / Math.ceil(Math.min(root, windows.length / root))
  );

  return splitEvery(root, windows)
    .map((chunk, row) => {
      return chunk.map((win, col) => ({
        ...win,
        height,
        width,
        top: row * height,
        left: col * width,
      }));
    })
    .flat(1);
};

const WorkspaceManager = memo(() => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [, setSystemInfo] = useState(null);
  const [renaming, setRenaming] = useState<string | null | undefined>(null);
  const captureButtonRef = useRef(null);
  const [rect, setRect] = useState<DOMRect | null | undefined>(null);
  // const [autoApplied, setAutoApplied] = useState(false);
  // NOTE(fzivolo): we never skip this query because the "autoload" feature must run
  // even if the Workspace Manager is not visible
  const {
    data: toolPreferencesResults,
    refetch,
    loading,
  } = useQuery<GetWorkspacesPreferencesQuery, GetWorkspacesPreferencesQueryVariables>(
    GET_WORKSPACES_PREFERENCES
  );
  const toolPreferences = toolPreferencesResults?.toolPreferences;
  const workspacePresets = toolPreferences?.workspaces.presets || EMPTY.ARRAY;
  const autoApply = toolPreferences?.workspaces.autoApply ?? false;
  const [capturing, flash, setCapturing] = useFlash({ refetch });
  const [editingSmid, setEditingSmid] = useState<string | null | undefined>(null);

  const channel = useWorkspaceManagerChannel();

  useEffect(() => {
    const handler = (msg: WorkspaceManagerBroadcastChannelMessage) => {
      switch (msg.type) {
        case 'flash':
          // @ts-expect-error [EN-7967] - TS2349 - This expression is not callable.
          flash();
          break;
        case 'open':
          setShowModal(true);
          break;
        case 'close':
          // @ts-expect-error [EN-7967] - TS2349 - This expression is not callable.
          setCapturing(false);
          setShowModal(false);
          break;
        default:
      }
    };
    channel.addEventListener('message', handler);

    return () => {
      channel.removeEventListener('message', handler);
    };
  }, [channel, flash, setCapturing]);

  const [createWorkspacePreset] = useMutation<
    CreateWorkspacePresetMutation,
    CreateWorkspacePresetMutationVariables
  >(CREATE_WINDOW_STATE, {
    refetchQueries: [{ query: GET_WORKSPACES_PREFERENCES }],
  });
  const [updateWorkspacePreset] = useMutation<
    UpdateWorkspacePresetMutation,
    UpdateWorkspacePresetMutationVariables
  >(UPDATE_WINDOW_STATE, {
    refetchQueries: [{ query: GET_WORKSPACES_PREFERENCES }],
  });
  const [setWorkspaceAutoApply] = useMutation<
    SetWorkspaceAutoApplyMutation,
    SetWorkspaceAutoApplyMutationVariables
  >(SET_WORKSPACE_AUTO_APPLY, {
    refetchQueries: [{ query: GET_WORKSPACES_PREFERENCES }],
  });
  const [deleteWorkspacePreset] = useMutation<
    DeleteWorkspacePresetMutation,
    DeleteWorkspacePresetMutationVariables
  >(DELETE_WORKSPACE_PRESET, {
    refetchQueries: [{ query: GET_WORKSPACES_PREFERENCES }],
  });

  const handleUpsertWorkspacePreset = useCallback(async () => {
    const systemInfo = await getSystemInfo();
    setEditingSmid(null);
    setRect(captureButtonRef.current?.getBoundingClientRect());
    channel.postMessage({ type: 'flash' });
    // @ts-expect-error [EN-7967] - TS2349 - This expression is not callable.
    flash();

    const toUpdate = workspacePresets.find((x) => x.smid === editingSmid);

    const payload = {
      // @ts-expect-error [EN-7967] - TS2339 - Property 'instanceId' does not exist on type 'unknown'.
      instanceId: systemInfo.instanceId,
      // @ts-expect-error [EN-7967] - TS2339 - Property 'windows' does not exist on type 'unknown'.
      name: toUpdate?.name || `${systemInfo.windows.length} windows`,
      // @ts-expect-error [EN-7967] - TS2339 - Property 'windows' does not exist on type 'unknown'.
      windows: systemInfo.windows,
    } as const;

    let smid;
    if (editingSmid == null) {
      const response = await createWorkspacePreset({
        variables: {
          payload,
        },
      });
      smid = response?.data?.createWorkspacePreset?.smid;
    } else {
      const response = await updateWorkspacePreset({
        variables: {
          smid: editingSmid,
          payload,
        },
      });
      smid = response?.data?.updateWorkspacePreset?.smid;
    }
    setRenaming(smid);
  }, [channel, flash, workspacePresets, editingSmid, createWorkspacePreset, updateWorkspacePreset]);

  const handleShowModal = useCallback(() => {
    if (showModal) {
      // @ts-expect-error [EN-7967] - TS2349 - This expression is not callable.
      setCapturing(false);
    }
    channel.postMessage({ type: showModal ? 'close' : 'open' });
    setShowModal((show) => !show);
  }, [channel, setCapturing, showModal]);

  useEffect(() => {
    if (!showModal) {
      return;
    }

    getSystemInfo().then((systemInfo) => {
      setSystemInfo((prevSystemInfo) =>
        !equals(systemInfo, prevSystemInfo) ? systemInfo : prevSystemInfo
      );
    });
  }, [showModal]);

  useEffect(() => {
    dispatchExtensionEvent(initEvent());
  }, []);

  useEffect(() => {
    addExtensionListener(EXTENSION_RESPONSE_EVENTS.ICON_CLICKED, handleShowModal);

    return () => {
      removeExtensionListener(EXTENSION_RESPONSE_EVENTS.ICON_CLICKED, handleShowModal);
    };
  }, [handleShowModal]);

  const handleRename = useCallback(
    async (evt: React.SyntheticEvent<HTMLFormElement>) => {
      evt.preventDefault();

      if (!(evt.target instanceof HTMLFormElement)) {
        return;
      }

      const name = new FormData(evt.target).get('name')?.toString();

      if (renaming == null || name == null) {
        return;
      }

      const toUpdate = workspacePresets.find(
        (workspacePreset) => workspacePreset.smid === renaming
      );

      if (toUpdate) {
        const { instanceId, windows } = toUpdate;
        await updateWorkspacePreset({
          variables: {
            smid: renaming,
            payload: {
              instanceId,
              windows: windows.map(({ __typename, ...win }) => win),
              name,
            },
          },
        });

        setRenaming(null);
      }
    },
    [renaming, updateWorkspacePreset, workspacePresets]
  );

  const currentCaseId = useCurrentCaseId();
  const applyWorkspacePreset = useCallback(
    (
      workspacePreset:
        | any
        | {
            instanceId: string;
            windows: Array<
              Flow.Diff<
                BrowserWindow,
                {
                  tabs: unknown;
                }
              >
            >;
          }
        | ({
            readonly __typename?: 'WorkspacePreset';
          } & WorkspacePresetsFieldsFragment)
    ) => {
      if (currentCaseId == null) return null;
      dispatchExtensionEvent(
        applyWorkspacePresetEvent({ preset: workspacePreset, caseId: currentCaseId })
      );
    },
    [currentCaseId]
  );

  const toggleAutoApply = useCallback(() => {
    if (toolPreferences == null) {
      return;
    }

    setWorkspaceAutoApply({
      variables: {
        value: !autoApply,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        setWorkspaceAutoApply: {
          __typename: 'WorkspacePreferences',
          autoApply: !autoApply,
        },
      },
      update: (proxy) => {
        proxy.writeQuery({
          query: GET_WORKSPACES_PREFERENCES,
          data: {
            toolPreferences: {
              ...toolPreferences,
              workspaces: {
                ...toolPreferences.workspaces,
                autoApply: !autoApply,
              },
            },
          },
        });
      },
    });
  }, [autoApply, toolPreferences, setWorkspaceAutoApply]);

  const [dropdownButton, setDropdownButton] = useState(null);
  const handleOpenDropdown = ({ target, smid }: { target: string; smid: string }) => {
    setDropdownButton(target);
    setEditingSmid(smid);
  };
  const handleCloseDropdown = () => {
    setDropdownButton(null);
    setEditingSmid(null);
  };
  const handleDeletePreset = useCallback(() => {
    if (editingSmid == null) {
      return;
    }

    deleteWorkspacePreset({
      variables: { smid: editingSmid },
      optimisticResponse: {
        __typename: 'Mutation',
        deleteWorkspacePreset: {
          __typename: 'ConfirmDeleteWorkspacePreset',
          smid: editingSmid,
        },
      },
      update: (proxy) => {
        proxy.writeQuery({
          query: GET_WORKSPACES_PREFERENCES,
          data: {
            toolPreferences: {
              ...toolPreferences,
              workspaces: {
                ...toolPreferences?.workspaces,
                presets: toolPreferences?.workspaces.presets.filter(
                  (x: any) => x.smid !== editingSmid
                ),
              },
            },
          },
        });
      },
    });
    setEditingSmid(null);
  }, [deleteWorkspacePreset, editingSmid, toolPreferences]);

  // This is a read-only preset used to move all the open Sirona windows
  // into a single window, they will be arranged on a grid layout
  const singleDisplayPreset = useCallback(async () => {
    const systemInfo = await getSystemInfo();

    // @ts-expect-error [EN-7967] - TS2339 - Property 'windows' does not exist on type 'unknown'.
    const windows = systemInfo.windows;

    applyWorkspacePreset({
      // @ts-expect-error [EN-7967] - TS2339 - Property 'instanceId' does not exist on type 'unknown'.
      instanceId: systemInfo.instanceId,
      windows: computeSingleDisplayGrid(windows),
    });
  }, [applyWorkspacePreset]);

  return (
    <>
      {!showModal && capturing && rect && <CameraFlash rect={rect} />}
      <Dialog
        open={!loading && showModal}
        classes={{ scrollPaper: classes.scrollPaper }}
        onClose={handleShowModal}
      >
        {capturing && rect && <CameraFlash rect={rect} />}
        {workspacePresets.length > 0 && (
          <>
            <List>
              <ListItem>
                <ListItemText primary="Automatically apply" />
                <ListItemSecondaryAction>
                  <Switch edge="end" onClick={toggleAutoApply} checked={autoApply} />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
            <Divider />
            <List>
              {workspacePresets.map((workspacePreset) => {
                const isRenaming = renaming === workspacePreset.smid;
                return (
                  <form onSubmit={handleRename} css="display: contents;" key={workspacePreset.smid}>
                    <ListItem
                      // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
                      button={!isRenaming}
                      onClick={() => !isRenaming && applyWorkspacePreset(workspacePreset)}
                    >
                      {isRenaming ? (
                        <Input name="name" defaultValue={workspacePreset.name} />
                      ) : (
                        <ListItemText primary={workspacePreset.name} />
                      )}
                      <ListItemSecondaryAction>
                        <IconButton
                          type={isRenaming ? 'submit' : 'button'}
                          edge="end"
                          aria-label="settings"
                          onClick={(evt) =>
                            isRenaming === false &&
                            handleOpenDropdown({
                              // @ts-expect-error [EN-7967] - TS2322 - Type 'EventTarget & HTMLButtonElement' is not assignable to type 'string'.
                              target: evt.currentTarget,
                              smid: workspacePreset.smid,
                            })
                          }
                        >
                          {isRenaming ? <DoneIcon /> : <MoreHorizIcon />}
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </form>
                );
              })}
              <ListItem button onClick={singleDisplayPreset}>
                <ListItemText primary="All in a single display" />
              </ListItem>
            </List>
            <Divider />
          </>
        )}
        <List>
          <ListItem button onClick={handleUpsertWorkspacePreset} ref={captureButtonRef}>
            <ListItemText primary="Capture window state" />
            <ListItemSecondaryAction css="pointer-events: none;">
              <DesktopMacIcon />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Menu
          anchorEl={dropdownButton}
          keepMounted
          open={editingSmid != null}
          onClose={handleCloseDropdown}
          // @ts-expect-error [EN-7967] - TS2339 - Property 'paper' does not exist on type 'ClassNameMap<"scrollPaper">'.
          classes={{ paper: classes.paper }}
        >
          <MenuItem onClick={handleUpsertWorkspacePreset}>
            <ListItemText primary="Update" />
            <ListItemSecondaryAction>
              <RefreshIcon />
            </ListItemSecondaryAction>
          </MenuItem>
          <MenuItem onClick={handleDeletePreset}>
            <ListItemText primary="Delete" />
            <ListItemSecondaryAction>
              <DeleteForeverIcon />
            </ListItemSecondaryAction>
          </MenuItem>
        </Menu>
      </Dialog>
    </>
  );
});
WorkspaceManager.displayName = 'WorkspaceManager';

export const toggleModal = (): unknown =>
  document.dispatchEvent(new CustomEvent('srna.icon.clicked'));

export default WorkspaceManager;

import type { PickerEvent } from './usePicker';

import { useStillClickEventHandler } from '../Annotations/helpers/useStillClickEventHandler';
import { decodeId } from '../utils/representationId';

import { useRef } from 'react';

/**
 * Provides a mechanism for annotations to have HTML actions outside
 * of the picker action. Given the annotation id, it can find other
 * elements within the annotation that it can filter on by ref.
 */
export const useHTMLActionFromPickerClick = ({
  id,
  onClick,
}: {
  id: string;
  onClick?: (event: PickerEvent) => void;
}): React.Ref<React.ElementType> => {
  const ref = useRef();
  const { id: decodedId } = decodeId(id);

  const handleClick = (event: PickerEvent) => {
    const { id: clickedId, displayPosition, originalEvent } = event;
    if (
      !(clickedId === id || clickedId === decodedId) ||
      ref.current == null ||
      displayPosition == null ||
      onClick == null
    )
      return;

    originalEvent.stopPropagation();

    /*
     * Due to the layering of events between the Canvas and HTML elements we grab
     * the picker events and make sure its within the annotation from canvas and then
     * check the html element stack at that point to find the element we want to action
     * on
     */
    const elements = document.elementsFromPoint(displayPosition.x, displayPosition.y);

    if (elements.find((elem) => elem === ref.current) != null) {
      onClick(event);
    }
  };

  useStillClickEventHandler(handleClick);

  return ref;
};

import type { ToolInteractionUpdateToolActiveStateResponse } from 'generated/graphql';

import { useSelectedTool } from '../../../../Viewer/withSelectedTool';

import { useCallback } from 'react';

export const useHandleUpdateToolActiveStateResponse = (): ((
  response: Readonly<ToolInteractionUpdateToolActiveStateResponse>,
  id: string
) => void) => {
  const [selectedTool, setSelectedTool] = useSelectedTool();
  const updateActiveState = useCallback(
    (response: Readonly<ToolInteractionUpdateToolActiveStateResponse>, id: string) => {
      const targetToolId = response.toolId ?? id;
      if (response.active && targetToolId != null && selectedTool !== targetToolId) {
        setSelectedTool(targetToolId);
      }
    },
    [selectedTool, setSelectedTool]
  );
  return updateActiveState;
};

import { useSuspenseQuery } from '@apollo/client';
import type { QueryHookOptions } from '@apollo/client';
import type { GetMeQuery, GetMeQueryVariables } from 'generated/graphql';
import { GET_ME } from 'modules/Apollo/queries';

/**
 * NOTE: uses Suspense, make sure to handle it accordingly
 */
export function useCurrentUser(
  options?: QueryHookOptions<GetMeQuery, GetMeQueryVariables>
): GetMeQuery {
  // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
  const { data } = useSuspenseQuery(GET_ME, options);
  // @ts-expect-error [EN-7967] - TS2741 - Property 'me' is missing in type '{}' but required in type 'GetMeQuery'.
  return data;
}

import { env } from 'config/env';

export default function getGreetingTime(): string {
  var g = null;
  const splitAfternoon = 12; // 24hr time to split the afternoon
  const splitEvening = 17; // 24hr time to split the evening
  // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
  const currentHour = parseFloat(new Date().getHours());

  if (env.STORYBOOK_STORYSHOTS === 'true') {
    return 'test';
  }

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    g = 'afternoon';
  } else if (currentHour >= splitEvening) {
    g = 'evening';
  } else {
    g = 'morning';
  }

  return g;
}

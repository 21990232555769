import { useEffect, useContext, useCallback } from 'react';
import { Contexts } from 'react-vtk-js';
import { useSetRecoilState, useRecoilValue, selectorFamily } from 'recoil';
import type { RecoilValueReadOnly } from 'recoil';
import { useViewportId, primitiveCursorsState, selectedPrimitiveState, useViewType } from './state';
import { useActiveSlice, useImagingContext } from '../modules/imaging/ImagingContext';

import { TOOL_CURSORS, CURSOR_DISABLED } from 'config/constants';
import { setCursor } from '../utils/dom';
import type { ViewportTypeKeys } from 'config/constants';
import { isToolDisabled } from '../utils/isToolDisabled';

// Special ID used to identify the default cursor between the other primitive IDs
export const DEFAULT_CURSOR_ID: '__DEFAULT_CURSOR_ID__' = '__DEFAULT_CURSOR_ID__';

// The tools listed here are the ones that will override any other cursor while active
export const CURSORS_WITH_PRECEDENCE = [
  'ERASER',
  'ERASE_ALL',
  'ZOOM',
  'ZOOM_IN',
  'ZOOM_OUT',
] as const;

type CursorArguments = {
  id: string;
  cursor: string | null | undefined;
};

export type SetCursor = (arg1: CursorArguments) => void;

export const useSetCursor = (): SetCursor => {
  const viewportId = useViewportId();
  const setPrimitiveCursors = useSetRecoilState(primitiveCursorsState(viewportId));

  return useCallback(
    ({ id, cursor }) => setPrimitiveCursors((state) => ({ ...state, [id]: cursor })),
    [setPrimitiveCursors]
  );
};

export const useRegisterCursor = ({ id, cursor }: CursorArguments): void => {
  const viewportId = useViewportId();
  const setPrimitiveCursors = useSetRecoilState(primitiveCursorsState(viewportId));
  useEffect(() => {
    setPrimitiveCursors((state) => ({ ...state, [id]: cursor }));
  }, [id, cursor, setPrimitiveCursors]);
};

export const withCursor = <T extends unknown>(
  Component: React.ComponentType<
    T & {
      cursor?: string;
      id: string;
    }
  >
): React.ComponentType<
  T & {
    cursor?: string;
    id: string;
  }
> =>
  function WithCursor({ id, cursor, ...props }) {
    useRegisterCursor({ id, cursor });
    // @ts-expect-error [EN-7967] - TS2322 - Type 'Omit<T & { cursor?: string; id: string; }, "cursor" | "id"> & { cursor: string; id: string; }' is not assignable to type 'IntrinsicAttributes & T & { cursor?: string; id: string; }'.
    return <Component {...props} cursor={cursor} id={id} />;
  };

export const defaultCursorSelector: (
  arg1: string
) => RecoilValueReadOnly<string | null | undefined> = selectorFamily({
  key: 'viewer.dre.defaultCursorSelector',
  get:
    (viewportId: string) =>
    ({ get }) => {
      return get(primitiveCursorsState(viewportId))[DEFAULT_CURSOR_ID];
    },
});
export const selectedCursorSelector: (
  arg1: string
) => RecoilValueReadOnly<string | null | undefined> = selectorFamily({
  key: 'viewer.dre.selectedCursorSelector',
  get:
    (viewportId: string) =>
    ({ get }) => {
      const selectedPrimitive = get(selectedPrimitiveState(viewportId));
      return selectedPrimitive != null
        ? get(primitiveCursorsState(viewportId))[selectedPrimitive]
        : null;
    },
});

export const useCursorHandler = () => {
  const view = useContext(Contexts.ViewContext);
  const viewportId = useViewportId();
  const defaultCursor = useRecoilValue(defaultCursorSelector(viewportId));
  const cursor = useRecoilValue(selectedCursorSelector(viewportId));
  const viewType = useViewType();
  const activeSlice = useActiveSlice();
  const { imagingProvider } = useImagingContext();
  const activeSliceTags = imagingProvider?.getFrameTagsForViewIndex(viewType, activeSlice);

  useEffect(() => {
    // @ts-expect-error [EN-7967] - TS2339 - Property 'getViewContainer' does not exist on type 'unknown'.
    const element = view?.getViewContainer();
    if (element == null) return;
    if (activeSliceTags?.sopClassUID == null) return;

    const targetCursor = pickCursor(
      cursor,
      defaultCursor,
      viewType,
      activeSliceTags?.sopClassUID,
      activeSliceTags?.validSpacing
    );

    setCursor(element, targetCursor);
  }, [
    cursor,
    defaultCursor,
    view,
    viewType,
    activeSliceTags?.sopClassUID,
    activeSliceTags?.validSpacing,
  ]);
};

export const CursorProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  useCursorHandler();
  // @ts-expect-error [EN-7967] - TS2322 - Type 'ReactNode' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
  return children;
};

export const toCssCursor = (cursorConfig: { value: string; x?: number; y?: number }): string =>
  `${cursorConfig.value} ${cursorConfig?.x ?? 0}  ${cursorConfig?.y ?? 0}, auto`;

const toolCursorEntries: ReadonlyArray<[string, (typeof TOOL_CURSORS)[keyof typeof TOOL_CURSORS]]> =
  Object.entries(TOOL_CURSORS);

// FIXME(fzivolo): ideally we shouldn't store the resolved CSS cursor string in the state
const cursorReverseLookup = (cssCursor: string): string | null | undefined =>
  toolCursorEntries.find(
    ([key, config]: [any, any]) => config.value === cssCursor.split(' ')[0]
  )?.[0];

const getCursorForToolAndViewType = (
  cursor: string | null | undefined,
  viewType: ViewportTypeKeys,
  sopClassUID?: string | null,
  validSpacing?: boolean | null
) => {
  if (cursor == null) return CURSOR_DISABLED;
  const tool = cursorReverseLookup(cursor);

  if (tool != null && isToolDisabled(tool, viewType, sopClassUID, validSpacing)) {
    return CURSOR_DISABLED;
  }

  return cursor;
};

export const pickCursor = (
  cursor: string | null | undefined,
  defaultCursor: string | null | undefined,
  viewType: ViewportTypeKeys,
  sopClassUID?: string | null,
  validSpacing?: boolean | null
): string | null | undefined => {
  let targetCursor = cursor ?? defaultCursor;
  if (
    defaultCursor != null &&
    (defaultCursor.startsWith('data:image/svg+xml;base64') ||
      // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'string' is not assignable to parameter of type '"ZOOM" | "ERASER" | "ERASE_ALL" | "ZOOM_OUT" | "ZOOM_IN"'.
      CURSORS_WITH_PRECEDENCE.includes(cursorReverseLookup(defaultCursor)))
  ) {
    targetCursor = defaultCursor;
  }

  targetCursor = getCursorForToolAndViewType(targetCursor, viewType, sopClassUID, validSpacing);

  return targetCursor;
};

import { useRecoilCallback, useRecoilValue } from 'recoil';
import type { HangingProtocol } from './useHangingProtocol';
import {
  isPreviewingHangingProtocolState,
  previewHangingProtocolSelector,
  previousHangingProtocolState,
} from './state';
import { useViewportsConfigurationsSelector } from '../ViewportsConfigurations/state';
import { useCurrentCaseId, useCurrentCase } from 'hooks/useCurrentCase';
import { client } from 'modules/Apollo/client';
import { GET_HANGING_PROTOCOL } from 'modules/Apollo/queries';
import { useMemo, useState } from 'react';
import useStudyIds from 'hooks/useStudyIds';
import { layoutsForAllCasesState } from '../Viewer/StudyLoader/viewerLoaderState';
import { usePreviousHangingProtocol } from './usePreviousHangingProtocol';

export type PreviewHangingProtocol = (id: string) => Promise<void>;

export type UsePreviewHangingProtocol = {
  previewHangingProtocol: PreviewHangingProtocol;
  acceptAndKeepPreview: () => void;
  rejectAndRemovePreview: () => void;
  loadingPreview: boolean;
};

export const usePreviewHangingProtocolConfiguration = (): HangingProtocol | null | undefined => {
  const currentCaseSmid = useCurrentCaseId();
  return useRecoilValue(previewHangingProtocolSelector(currentCaseSmid));
};

export const usePreviewHangingProtocol = (): UsePreviewHangingProtocol => {
  const { currentCase } = useCurrentCase();
  const studyIds = useStudyIds();
  const viewportsConfigurationsSelector = useViewportsConfigurationsSelector();
  const { previousHangingProtocol, savePreviousHangingProtocol } = usePreviousHangingProtocol();

  const [loadingPreview, setLoadingPreview] = useState(false);

  const currentCaseSmid = useCurrentCaseId();
  const cases = useMemo(
    () =>
      currentCase?.studies
        ? [currentCase?.studies?.map((c: any) => c.smid)].concat(studyIds.map((smid) => [smid]))
        : [],
    [currentCase?.studies, studyIds]
  );

  const previewHangingProtocol = useRecoilCallback(
    ({ set }) => {
      return async (id: string) => {
        if (currentCaseSmid == null || cases.length === 0) return;
        setLoadingPreview(true);
        savePreviousHangingProtocol();

        try {
          const { data } = await client.query({
            query: GET_HANGING_PROTOCOL,
            variables: { id, cases },
          });
          const hangingProtocol = data?.hangingProtocol;

          set(previewHangingProtocolSelector(currentCaseSmid), hangingProtocol);
          set(isPreviewingHangingProtocolState, true);
          setLoadingPreview(false);
        } catch (error: any) {
          console.error('Failed to fetch hanging protocol', error);
          setLoadingPreview(false);
        }
      };
    },
    [cases, currentCaseSmid, savePreviousHangingProtocol]
  );

  const acceptAndKeepPreview = useRecoilCallback(
    ({ set, reset }) =>
      () => {
        // The HangingProtocolWidget handles the mutation update
        set(isPreviewingHangingProtocolState, false);
        reset(viewportsConfigurationsSelector);
        reset(layoutsForAllCasesState);
        reset(previousHangingProtocolState);
      },
    [viewportsConfigurationsSelector]
  );

  const rejectAndRemovePreview = useRecoilCallback(
    ({ set, reset }) =>
      () => {
        if (currentCaseSmid == null) return;
        set(isPreviewingHangingProtocolState, false);

        if (previousHangingProtocol) {
          set(previewHangingProtocolSelector(currentCaseSmid), previousHangingProtocol);
          return;
        }

        reset(previewHangingProtocolSelector(currentCaseSmid));
      },
    [currentCaseSmid, previousHangingProtocol]
  );

  return {
    previewHangingProtocol,
    acceptAndKeepPreview,
    rejectAndRemovePreview,
    loadingPreview,
  };
};

import { useCurrentUser } from 'hooks/useCurrentUser';
import { UPDATE_WORKLIST_SETTINGS, GET_ME } from 'modules/Apollo/queries';
import { useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import type { GetMeQuery, GetMeQueryVariables } from 'generated/graphql';
import { WorklistSurface } from '../types';
import { useFeatureFlagEnabled, FF } from 'modules/feature-flags';

type UseSavedSortingPreferencesReturn = {
  savedSortingPreferences: {
    [surface: string]: string;
  };
  saveSortingPreferences: (
    surface: string,
    column: string,
    order: string,
    worklistViewID?: string
  ) => void;
};

export const useSavedSortingPreferences = (): UseSavedSortingPreferencesReturn => {
  const meQuery = useCurrentUser();
  const worklistSettings = meQuery?.data?.me.worklistSettings;
  const [updateWorklistSettings] = useMutation(UPDATE_WORKLIST_SETTINGS);
  const [isCustomWorklistMilestoneOneEnabled] = useFeatureFlagEnabled(
    FF.WORKLIST_CUSTOM_WORKLISTS_MILESTONE_1
  );
  const savedSortingPreferences = useMemo(() => {
    const preferences = worklistSettings?.savedSortingPreferences ?? [];

    return preferences.reduce<{
      [key: string]: string;
    }>(
      (
        acc: {
          [key: string]: string;
        },
        { surface, searchParams, worklistViewID }
      ) => {
        if (isCustomWorklistMilestoneOneEnabled && surface === WorklistSurface.WORKLIST_VIEW) {
          acc[`${surface}_${worklistViewID}`] = searchParams;
        } else {
          acc[surface] = searchParams;
        }
        return acc;
      },
      {}
    );
  }, [isCustomWorklistMilestoneOneEnabled, worklistSettings?.savedSortingPreferences]);

  const saveSortingPreferences = useCallback(
    (surface, column, order, worklistViewID) => {
      const updatedSortingPreferences: Array<
        | any
        | {
            searchParams: string;
            surface: string;
          }
        | {
            searchParams: string;
            surface: any;
            worklistViewID: string;
          }
      > = [];
      const params = `sortColumn=${column}&sortOrder=${order}&tab=${surface}`;

      const exists = worklistSettings?.savedSortingPreferences?.find((preference: any) => {
        if (isCustomWorklistMilestoneOneEnabled && surface === WorklistSurface.WORKLIST_VIEW) {
          return (
            preference.surface === `${surface}_${worklistViewID}` &&
            preference.worklistViewID === worklistViewID
          );
        } else {
          return preference.surface === surface;
        }
      });

      if (exists && exists.searchParams === params) {
        return;
      }

      const shouldSupplyWorklistView =
        isCustomWorklistMilestoneOneEnabled && surface === WorklistSurface.WORKLIST_VIEW;

      if (!exists) {
        updatedSortingPreferences.push({
          surface: shouldSupplyWorklistView ? `${surface}_${worklistViewID}` : surface,
          searchParams: params,
          worklistViewID: shouldSupplyWorklistView ? worklistViewID : null,
        });
      }

      worklistSettings?.savedSortingPreferences?.forEach((preference: any) => {
        if (preference.surface !== surface) {
          updatedSortingPreferences.push({
            ...preference,
            worklistViewID: preference.worklistViewID ?? null,
          });
        } else {
          updatedSortingPreferences.push({
            surface: shouldSupplyWorklistView
              ? `${preference.surface}_${worklistViewID}`
              : preference.surface,
            searchParams: params,
            worklistViewID: shouldSupplyWorklistView ? worklistViewID : null,
          });
        }
      });

      const updatedResponse = {
        ...worklistSettings,
        savedSortingPreferences: updatedSortingPreferences,
      } as const;

      updateWorklistSettings({
        variables: { savedSortingPreferences: updatedSortingPreferences },
        optimisticResponse: {
          __typename: 'Mutation',
          updateWorklistSettings: {
            __typename: 'WorklistSettings',
            ...updatedResponse,
          },
        },
        update: (proxy) => {
          const { me } = proxy.readQuery<GetMeQuery, GetMeQueryVariables>({ query: GET_ME }) ?? {};

          proxy.writeQuery({
            query: GET_ME,
            data: {
              me: {
                ...me,
                worklistSettings: {
                  ...updatedResponse,
                },
              },
            },
          });
        },
      });
    },
    [isCustomWorklistMilestoneOneEnabled, updateWorklistSettings, worklistSettings]
  );

  return { savedSortingPreferences, saveSortingPreferences };
};

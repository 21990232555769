import type { ElementPlugin } from '../plugins';
import type { RendererRenderElement, RendererRenderText } from './types';
import { Children } from './Children';

export type ElementComponentProps = Readonly<{
  node: ElementPlugin;
  renderElement: RendererRenderElement;
  renderText: RendererRenderText;
}>;

export const ElementComponent = ({
  node,
  renderElement,
  renderText,
}: ElementComponentProps): React.ReactElement => {
  const children = <Children node={node} renderElement={renderElement} renderText={renderText} />;
  return renderElement({ children, node });
};

import getGreetingTime from 'utils/greeting';
import styled from 'styled-components';
import Text from 'common/ui/Text';
import { Colors } from 'styles';
import { transparentize } from 'color2k';
import VersionAndBuild from '../../VersionAndBuild';
import {
  Main,
  Footer,
  Wrapper,
  MainContentWrapper,
  MainContentWrapperTitle,
  MainContent,
} from './styles';
import { env } from 'config/env';
import { PWATitleBar } from '../../ui/PWATitleBar';

type AuthLayoutProps = {
  title: string;
  children: React.ReactNode;
};

const ShadowText = styled(Text)`
  text-shadow: 0 2px 4px ${transparentize(Colors.gray1, 0.3)};
`;

const AuthLayout = ({ title, children, ...props }: AuthLayoutProps): React.ReactElement => {
  return (
    <Wrapper>
      <PWATitleBar />
      <Main
        // NOTE: we disable the transition during storyshots to make them more stable
        initial={{ opacity: env.STORYBOOK_STORYSHOTS !== 'true' ? 0 : 1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: env.STORYBOOK_STORYSHOTS !== 'true' ? 0 : 1 }}
      >
        <ShadowText
          variant="display4"
          css={`
            margin-bottom: 25px;
          `}
        >
          Good {getGreetingTime()}.
        </ShadowText>
        <MainContentWrapper>
          <MainContentWrapperTitle>
            <Text variant="display1">{title}</Text>
          </MainContentWrapperTitle>
          <MainContent>{children}</MainContent>
        </MainContentWrapper>
      </Main>
      <Footer>
        <div
          css={`
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px;
          `}
        >
          <div css="flex: 1; display: flex; justify-content: flex-start;">
            <VersionAndBuild />
          </div>
          <div css="flex: 2; display: flex; justify-content: center;">
            <ShadowText css="text-align: center">
              Copyright &copy; 2018-{new Date().getFullYear()} Sirona Medical, Inc.
            </ShadowText>
          </div>
          <div css="flex: 1; display: flex; justify-content: flex-end;">
            <ShadowText
              as="a"
              href="mailto:contact@sironamedical.com"
              css={`
                text-decoration: none;
              `}
            >
              Contact Us
            </ShadowText>
          </div>
        </div>
      </Footer>
    </Wrapper>
  );
};

export default AuthLayout;

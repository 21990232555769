import { withListShortcuts } from './shortcuts';
import type { CreateEnhanceEditorState } from '../../types';
import type { ListPluginPropertyOptions } from './types';
import { isNodeTypeIn } from '../../utils/isNodeTypeIn';
import { Editor, Element, Transforms, Node } from '../../core';
import { BRACKET_TYPES } from '../../constants';
import { isEmptyParagraph } from '../paragraph/utils';
import { isListChildValid, removeEmptyLists } from './utils';
import { PARAGRAPH_PLUGIN_ID } from '../paragraph/types';
import { LIST_PLUGIN_ID } from './types';
import { LIST_VARIANTS } from './constants';
import type { NodeEntry } from 'slate';

export const enhanceEditorStateList: CreateEnhanceEditorState<ListPluginPropertyOptions> =
  (options) => (editor: Editor) => {
    const { insertFragment, normalizeNode } = editor;

    const insertNodesIntoList = (nodes: Array<Node>) => {
      nodes &&
        nodes.forEach((node, index) => {
          if (Array.isArray(node.children) && node.type === 'list') {
            const listChildren: Array<Node> = node.children;
            listChildren.forEach((listChild: Node, childIndex: number) => {
              insertNodesIntoList([listChild]);
              if (childIndex !== listChildren.length - 1) {
                // Insert a line break after each list item except for the last one
                Editor.insertBreak(editor);
              }
            });
          } else if (BRACKET_TYPES.includes(node.type)) {
            Transforms.insertNodes(editor, node);
          } else if (isEmptyParagraph(node)) {
            Editor.insertBreak(editor);
          } else if (Array.isArray(node.children) && node.children != null) {
            const nodeChildren: Array<Node> = node.children;

            // Insert each child of the node
            nodeChildren.forEach((child, childIndex) => {
              if (child.text != null && child.text !== '' && typeof child.text === 'string') {
                if (index !== 0 || childIndex !== 0) {
                  Editor.insertBreak(editor);
                }
                Transforms.insertText(editor, child.text);
              } else if (Array.isArray(child.children) && child.children) {
                insertNodesIntoList([child]);
              }
            });
          }
        });
    };

    editor.insertFragment = (fragment: Array<Node>) => {
      if (isNodeTypeIn(editor, options.pluginID)) {
        return insertNodesIntoList(fragment);
      }

      return insertFragment(fragment);
    };

    editor.normalizeNode = (entry: NodeEntry) => {
      const [node, path] = entry;

      if (
        Element.isElement(node) &&
        node.type === options.pluginID &&
        Array.isArray(node.children)
      ) {
        // If the list node doesn't have a variant property, then set it to be an ordered list
        if (node.variant == null) {
          Transforms.setNodes(editor, { variant: LIST_VARIANTS.ol }, { at: path });
        }

        const listChildren: Array<Node> = node.children;
        if (listChildren.length === 0) {
          removeEmptyLists(editor);
          return;
        } else if (
          // Any of its children are invalid - either they aren't paragraph/list nodes or are
          // missing a variant property
          listChildren.some((child) => !isListChildValid(child))
        ) {
          for (const [child, childPath] of Array.from(
            Node.children(editor, path, { reverse: true })
          )) {
            if (Element.isElement(child) || Node.isNode(child)) {
              // If a child is neither a paragraph nor a list node, then it is removed if empty or
              // wrapped in a paragraph list item if not empty
              // @ts-expect-error [EN-7967] - TS2339 - Property 'type' does not exist on type 'Descendant'. | TS2339 - Property 'type' does not exist on type 'Descendant'.
              if (child.type !== PARAGRAPH_PLUGIN_ID && child.type !== LIST_PLUGIN_ID) {
                if (Node.string(child).trim() === '') {
                  Transforms.removeNodes(editor, { at: childPath });
                } else {
                  Transforms.wrapNodes(
                    editor,
                    { type: PARAGRAPH_PLUGIN_ID, variant: LIST_VARIANTS.li, children: [] },
                    { at: childPath }
                  );
                }
                // If its a list node, then it is invalid because it doesn't have a variant property.
                // Arbitrarily set it to be an ordered list
                // @ts-expect-error [EN-7967] - TS2339 - Property 'type' does not exist on type 'Descendant'.
              } else if (child.type === LIST_PLUGIN_ID) {
                Transforms.setNodes(editor, { variant: LIST_VARIANTS.ol }, { at: childPath });
              }
            }
          }
          return;
        }
      }

      normalizeNode(entry);
    };

    return withListShortcuts(editor);
  };

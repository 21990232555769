import type { SortOrder } from 'generated/graphql';

import { PENDING, SORT_ORDER } from 'config/constants';
import { SortColumn } from 'generated/graphql';
import { DEFAULT_SORT_COLUMNS, DEFAULT_SORT_ORDERS } from './constants';
import type {
  FilterDefinitionType,
  FilterStateType,
  NumberRangeTuple,
  NumberRangeTupleString,
  ProcessedFilterStateType,
  WorklistFiltersType,
} from './types';
import { FilterVariant } from './types';
import { WorklistSurface } from '../Worklist/types';
import type { WorklistSurfaceType } from '../Worklist/types';

const VALID_COLUMN_SET = new Set(Object.values(SortColumn));
const VALID_ORDER_SET = new Set([SORT_ORDER.ASCENDING, SORT_ORDER.DESCENDING]);

export const validateSurface = (
  surface: string,
  defaultSurface: WorklistSurfaceType = PENDING
): WorklistSurfaceType => {
  return (
    // @ts-ignore [incompatible-type]
    // @ts-ignore [incompatible-return]
    Object.values(WorklistSurface).find((s: WorklistSurfaceType) => s === surface) ??
    defaultSurface ??
    WorklistSurface.PENDING
  );
};

export const validateSortColumns = (
  columns: string[],
  defaultColumn: Array<SortColumn> = DEFAULT_SORT_COLUMNS
): Array<SortColumn> => {
  // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'string' is not assignable to parameter of type 'SortColumn'.
  const validColumns = columns.filter((column) => VALID_COLUMN_SET.has(column));
  // @ts-expect-error [EN-7967] - TS2322 - Type 'string[]' is not assignable to type 'SortColumn[]'.
  return validColumns.length > 0 ? validColumns : defaultColumn;
};

export const validateSortOrders = (
  orders: string[],
  defaultOrders: Array<SortOrder> = DEFAULT_SORT_ORDERS
): Array<SortOrder> => {
  // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'string' is not assignable to parameter of type '"ASC" | "DESC"'.
  const validOrders = orders.filter((order) => VALID_ORDER_SET.has(order));
  // @ts-expect-error [EN-7967] - TS2322 - Type 'string[]' is not assignable to type 'SortOrder[]'.
  return validOrders.length > 0 ? validOrders : defaultOrders;
};

type WorklistURLParams = {
  surface: null | WorklistSurfaceType;
  sortColumn: Array<SortColumn>;
  sortOrder: Array<SortOrder>;
  filters: Partial<Record<WorklistFiltersType, string>> | null | undefined;
};

export const getWorklistURLParams = (searchParams: URLSearchParams): WorklistURLParams => {
  const params: WorklistURLParams = {
    sortColumn: DEFAULT_SORT_COLUMNS,
    sortOrder: DEFAULT_SORT_ORDERS,
    surface: null,
    filters: null,
  };

  for (const [key, value] of searchParams) {
    switch (key) {
      // If sortColumn and sortOrder are not the same length, the values will be
      // corrected by tanstack table
      case 'sortColumn':
        params.sortColumn = validateSortColumns(value.split(','));
        break;
      case 'sortOrder':
        params.sortOrder = validateSortOrders(value.split(','));
        break;
      case 'tab':
        params.surface = validateSurface(value);
        break;
      default:
        if (key.startsWith('filters')) {
          params.filters = params.filters ?? {};
          const filterKey = key.match(/filters\[([^\]]*)]/)?.[1];
          if (params.filters != null && filterKey != null) {
            params.filters[filterKey] = value;
          }
        }
        break;
    }
  }

  return params;
};

// TODO: @kcwijaya figure out how to type this function well. If we make the
// return type not as strict, it messes up things for the future mutation
export const processFiltersForQuery = (
  filters: FilterStateType,
  definitions: FilterDefinitionType
): ProcessedFilterStateType => {
  const processedFilters: ProcessedFilterStateType = {};

  for (const [key, value] of Object.entries(filters)) {
    const filter = definitions[key];
    if (filter == null) {
      continue;
    }

    switch (filter.type) {
      case FilterVariant.MultiSelect:
        if (filter.isBoolean === true) {
          processedFilters[key] = processBooleanFilter(value);
        } else {
          processedFilters[key] = value;
        }
        break;
      case FilterVariant.DateRange:
        // $FlowFixMe[incompatible-type]
        // $FlowFixMe[incompatible-call]
        processedFilters[key] = processDateFilter(value);
        break;
      case FilterVariant.Toggle:
        // $FlowFixMe[incompatible-type]
        processedFilters[key] = typeof value === 'boolean' ? value : value[0] === 'true';
        break;
      case FilterVariant.NumberRange:
        // $FlowFixMe[invalid-tuple-arity]
        // $FlowFixMe[incompatible-type]
        // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'FilterValueType' is not assignable to parameter of type 'NumberRangeTupleString'.
        processedFilters[key] = processNumberRangeFilter(value);
        break;
      case FilterVariant.Text:
        // $FlowFixMe[incompatible-type]
        processedFilters[key] = value[0] ?? '';
        break;
      default:
        break;
    }
  }

  return processedFilters;
};

export const processBooleanFilter = (values: Array<string>): boolean | null | undefined => {
  if (values.length === 1) {
    return values[0] === 'true';
  }

  return null;
};

export const processDateFilter = (
  values: Array<string | null | undefined>
): [Date | null | undefined, Date | null | undefined] => {
  if (values.length === 0) {
    return [null, null];
  }

  let processedDates: Array<never> | Array<null | Date> = [];

  if (values.length === 1) {
    processedDates = [values[0] != null ? new Date(values[0]) : null, new Date()];
  } else {
    processedDates = [
      values[0] != null ? new Date(values[0]) : null,
      values[1] != null ? new Date(values[1]) : null,
    ];
  }

  return [
    isNaN(processedDates[0]?.getTime()) ? null : processedDates[0],
    isNaN(processedDates[1]?.getTime()) ? null : processedDates[1],
  ];
};

export const processNumberRangeFilter = (values: NumberRangeTupleString): NumberRangeTuple => {
  // @ts-expect-error [EN-7967] - TS2367 - This comparison appears to be unintentional because the types '2' and '0' have no overlap.
  if (values.length === 0) {
    return [null, null];
  }

  const processValue = (value?: string | null): number | null | undefined => {
    if (value == null || value === '') {
      return null;
    }
    const parsed = parseInt(value);
    return isNaN(parsed) ? null : parsed;
  };

  // @ts-expect-error [EN-7967] - TS2367 - This comparison appears to be unintentional because the types '2' and '1' have no overlap.
  if (values.length === 1) {
    return [processValue(values[0]), null];
  }

  return [processValue(values[0]), processValue(values[1])];
};

export const ageRangeToDOBRange = (
  ageRange: [number | null | undefined, number | null | undefined]
): [Date | null | undefined, Date | null | undefined] => {
  const [minAge, maxAge] = ageRange;

  const today = new Date();

  const earliestDOB =
    maxAge != null
      ? new Date(today.getFullYear() - maxAge, today.getMonth(), today.getDate())
      : null;
  const latestDOB =
    minAge != null
      ? new Date(
          today.getFullYear() - minAge + (minAge === maxAge ? 1 : 0),
          today.getMonth(),
          today.getDate() + 1
        )
      : null;

  return [earliestDOB, latestDOB];
};

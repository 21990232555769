import { Editor } from 'domains/reporter/RichTextEditor/core';
/* eslint-disable import/extensions */
import { FieldsProvider } from './Fields.Context';
import type { FieldsProviderProps } from './Fields.Context';
import { EditorWrapper } from '../Editor';
import { FieldsSectionEditor } from './Fields.SectionEditor';
import { FieldsSubmit } from './Fields.Submit';
import { FieldsAddendumEditor } from './Fields.AddendumEditor';
import { FieldsAddendumRenderer } from './Fields.AddendumRenderer';
import { useRecoilValue } from 'recoil';
import { ReportStatuses, reportStatusState, SECTION_EDITOR_EDITABLE_STATUSES } from '../state';
import { FieldsSectionRenderer } from './Fields.SectionRenderer';
import PriorReport from '../PriorReport';
import { useCurrentCaseReport } from 'hooks/useCurrentCaseReport';
import Text from 'common/ui/Text';
import type { OnStableText } from '../../RichTextEditor/plugins/dictation/types';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';
import { ReportOutline } from '../Outline/ReportOutline';
import { Stack } from 'common/ui/Layout';
import { useIsCaseGroupSubmitting } from '../hooks/useIsCaseGroupSubmitting';

export type FieldsProps = FieldsProviderProps & {
  isLoading?: boolean;
  onStableText?: OnStableText;
  aiMode?: boolean;
  editor?: Editor | null | undefined;
  children?: React.ReactNode | undefined;
};

export const Fields = ({
  aiMode = false,
  onStableText,
  editor,
  isLoading,
  ...props
}: FieldsProps): React.ReactElement => {
  const status = useRecoilValue(reportStatusState);
  const { isCaseGroupSubmitting } = useIsCaseGroupSubmitting();
  const isReportEditable =
    (SECTION_EDITOR_EDITABLE_STATUSES as ReportStatuses[]).includes(status) &&
    !isCaseGroupSubmitting;
  const { currentCaseReport } = useCurrentCaseReport();
  const [isReportOutlineEnabled] = useFeatureFlagEnabled(FF.REPORT_OUTLINE);

  return (
    <FieldsProvider aiMode={aiMode} {...props}>
      <EditorWrapper variant="report" aiMode={aiMode} space="xsmall">
        {currentCaseReport != null && <PriorReport />}
        {aiMode && (
          <div
            css={`
              padding-left: 16px;
            `}
          >
            <Text
              variant="body1"
              css={`
                font-size: 13px;
              `}
            >
              Report
            </Text>
          </div>
        )}
        <Stack
          data-stack-stretch
          space="medium"
          stretchX
          stretchY
          css="overflow: auto; height: 100%; min-height: 55%;"
        >
          {isReportOutlineEnabled && props.isClaimedByMe && (
            <ReportOutline isDisabled={props.isDisabled || !isReportEditable} />
          )}
          {isReportEditable ? (
            <FieldsSectionEditor
              isDisabled={props.isDisabled}
              onInit={props.onInit}
              onDestroy={props.onDestroy}
              autoloadTemplateState={props.autoloadTemplateState}
              aiMode={aiMode}
              onStableText={onStableText}
              editor={editor}
              isLoading={isLoading}
            />
          ) : (
            <FieldsSectionRenderer />
          )}
        </Stack>
        <Stack vertical space="small">
          <FieldsAddendumRenderer />
          <FieldsAddendumEditor isDisabled={props.isDisabled} onInit={props.onInit} />
          <FieldsSubmit
            isDisabled={props.isDisabled}
            isClaimedByMe={props.isClaimedByMe}
            smid={currentCaseReport?.smid}
            initialExamCount={currentCaseReport?.examCount ?? 1}
          />
        </Stack>
      </EditorWrapper>
    </FieldsProvider>
  );
};

import Text from 'common/ui/Text';
import { css } from 'styled-components';
import TextWithTooltip from 'common/TextWithTooltip';
import { Stack } from '../ui/Layout';
import { Colors } from 'styles';
import { toLocaleTimeString, toLocaleString } from 'utils/date';
import { StudyLabelChip } from 'domains/worklist/Worklist/PatientJacketV2/common/StudyLabelChip';
import Skeleton from '@material-ui/lab/Skeleton';
import type { FlattenSimpleInterpolation } from 'styled-components';

export const formatStudyInformation = (
  studyDescription?: string | null,
  accessionNumber?: string | null,
  studyDate?: Date | null
): string => {
  const studyDescriptionText =
    studyDescription == null || studyDescription === '' ? 'Study Desc: N/A' : studyDescription;
  const accessionNumberText =
    accessionNumber == null || accessionNumber === '' ? 'N/A' : accessionNumber;
  const formattedTime = toLocaleTimeString(studyDate, { hour: 'numeric' });
  const formattedDate = toLocaleString(studyDate, { year: '2-digit' });
  const studyDateText =
    studyDate != null && formattedTime != null && formattedDate != null
      ? `${formattedDate} ${formattedTime}`
      : 'Time: N/A';

  return `${studyDescriptionText} (ACC: ${accessionNumberText}), ${studyDateText}`;
};

export const formatStudyInformationWithoutDate = (
  studyDescription?: string | null,
  accessionNumber?: string | null
): string =>
  `${
    studyDescription == null || studyDescription === '' ? 'Study Desc: N/A' : studyDescription
  } (ACC: ${accessionNumber == null || accessionNumber === '' ? 'N/A' : accessionNumber})`;

export const VARIANT = Object.freeze({
  TOOLBAR: 'toolbar',
  PANEL: 'panel',
});
type VariantType = (typeof VARIANT)[keyof typeof VARIANT];

type StudyInformationCardProps = {
  studyColor: string | null | undefined;
  studyDescription: string | null | undefined;
  accessionNumber: string | null | undefined;
  studyDate: Date | null | undefined;
  variant?: VariantType;
  textCss?: FlattenSimpleInterpolation;
  studyLabel?: string | null | undefined;
  isLoading?: boolean;
};

export const StudyInformationCard = ({
  studyColor,
  studyDescription,
  accessionNumber,
  studyDate,
  variant = VARIANT.TOOLBAR,
  textCss,
  studyLabel,
  isLoading = false,
}: StudyInformationCardProps): React.ReactElement => {
  if (isLoading) {
    return <Skeleton height={32} width="100%" />;
  }

  return (
    <Stack
      alignX={variant === VARIANT.TOOLBAR ? 'center' : 'start'}
      alignY="center"
      css={css`
        user-select: none;
        min-width: 16px;
        padding-right: 24px;
      `}
      data-testid="study-information-card"
      space="xsmall"
    >
      {variant === VARIANT.TOOLBAR && (
        <div
          css={css`
            min-height: 12px;
            min-width: 12px;
            border-radius: 12px;
            background-color: ${studyColor ?? Colors.gray5};
          `}
        />
      )}
      {variant === VARIANT.PANEL && studyLabel != null && (
        <StudyLabelChip studyColor={studyColor} studyLabel={studyLabel} />
      )}
      <Text
        variant="button"
        css={css`
          display: flex;
          width: 100%;
          ${textCss || ''}
        `}
      >
        <TextWithTooltip
          conditionalTooltip={true}
          name={formatStudyInformation(studyDescription, accessionNumber, studyDate)}
          variant="noWrap"
          data-testid="study-information"
        />
      </Text>
    </Stack>
  );
};

import { useAnnotationsRenderBatcher } from '../Annotations/AnnotationsRenderBatcher';

import { useEffect } from 'react';
// this hook will subscribe to a primitive value and
// request an annotation rerender whenever it changes
// using with non primitive values will cause runaway render calls
// dont touch that type signature!
export const useAnnotationReactiveRender = (...args: Array<unknown>): void => {
  const requestRender = useAnnotationsRenderBatcher();

  useEffect(() => {
    requestRender();
    /* eslint react-hooks/exhaustive-deps: 0*/
  }, [...args, requestRender]);
};

import { useOpenTabs } from 'hooks/useOpenTabs';

export const PATIENT_JACKET_VIEWS = {
  DOCKED: 'Docked',
  UNDOCKED: 'Undocked',
  NONE: 'None',
} as const;

export type PATIENT_JACKET_VIEWS_TYPE =
  (typeof PATIENT_JACKET_VIEWS)[keyof typeof PATIENT_JACKET_VIEWS];

/**
 * Returns the current view of the patient jacket.
 * Expected URLS:
 *  /worklist/jacket/:smid for combined worklist + jacket view
 *  /jacket/:smid for standalone jacket view
 * @returns {Object} - view: $Values<typeof PATIENT_JACKET_VIEWS>, isDocked: boolean, isUndocked: boolean
 */
const usePatientJacketView = (): {
  view: PATIENT_JACKET_VIEWS_TYPE;
  isDocked: boolean;
  isUndocked: boolean;
} => {
  const openTabs = useOpenTabs();
  const patientJacketTab = openTabs.find((tab) => tab.path.includes('/jacket'));

  let view: PATIENT_JACKET_VIEWS_TYPE;
  if (!patientJacketTab) {
    view = PATIENT_JACKET_VIEWS.NONE;
  } else if (patientJacketTab.path.startsWith('/worklist/jacket')) {
    view = PATIENT_JACKET_VIEWS.DOCKED;
  } else {
    view = PATIENT_JACKET_VIEWS.UNDOCKED;
  }

  return {
    view,
    isDocked: view === PATIENT_JACKET_VIEWS.DOCKED,
    isUndocked: view === PATIENT_JACKET_VIEWS.UNDOCKED,
  };
};

export default usePatientJacketView;

import { Node } from 'domains/reporter/RichTextEditor/core';
import { Editor, Range, Path } from '../../core';

import { PARAGRAPH_PLUGIN_ID } from '../paragraph/types';
import { LIST_PLUGIN_ID } from './types';
import type { ListVariant } from './constants';
import {
  findClosestBlockPathAtSelection,
  getCurrentActiveListVariant,
  getListDepth,
  getListVariantsInRange,
  isInsideListAtPath,
} from './utils';
import { LIST_VARIANTS } from './constants';
import { getPathRefSafe } from '../../utils/refHelpers';
import { demoteListItems, extendPreviousList, insertList, switchListVariant } from './functions';
import { HEADING_PLUGIN_ID } from '../heading/types';
import { logger } from 'modules/logger';
import { Element } from 'slate';

export const toggleList = (
  editor: Editor | null | undefined,
  currentActiveListVariant: ListVariant | null | undefined,
  listVariant: ListVariant
): void => {
  if (editor == null) {
    return;
  }

  const { selection } = editor;

  if (selection == null) {
    logger.error('[useListInteraction] Cannot toggle list without a selection');
    return;
  }

  const listVariantsInRange = getListVariantsInRange(editor, selection);

  // If the selection is collapsed or there is only a single list variant in the selection, then
  // the logic is simpler, since you'll only ever need to do one type of action.
  if (Range.isCollapsed(selection) || listVariantsInRange.length === 1) {
    toggleListLine(editor, currentActiveListVariant, listVariant, listVariantsInRange);
  } else {
    // If there is more than one list variant in the selection, then we need to handle each
    // block node separately. We keep pathRefs since we need to keep track of how paths
    // shift when we modify a previous node.
    const pathRefs = Array.from(
      Editor.nodes(editor, {
        at: selection,
        mode: 'highest',
        match: (node: Node) =>
          Element.isElement(node) &&
          ((node.type === PARAGRAPH_PLUGIN_ID && node.variant == null) ||
            node.type === HEADING_PLUGIN_ID ||
            node.type === LIST_PLUGIN_ID),
      })
    ).map((entry) => Editor.pathRef(editor, entry[1]));

    for (const pathRef of pathRefs) {
      toggleListChunk(
        editor,
        listVariant,
        Editor.range(editor, getPathRefSafe(pathRef)),
        listVariantsInRange
      );
    }
  }
};

export const toggleListLine = (
  editor: Editor,
  currentActiveListVariant: ListVariant | null | undefined,
  listVariant: ListVariant,
  listVariantsInRange: Array<string>
): void => {
  if (
    (listVariant === LIST_VARIANTS.ol || listVariant === LIST_VARIANTS.ul) &&
    currentActiveListVariant == null &&
    listVariantsInRange.length === 1 &&
    listVariantsInRange[0] === 'none'
  ) {
    return insertList(editor, listVariant);
  }
  const selectedListVariant =
    currentActiveListVariant ?? (listVariantsInRange.length === 1 ? listVariantsInRange[0] : null);

  if (selectedListVariant === listVariant) {
    return demoteListItems(editor);
  } else if (selectedListVariant != null && selectedListVariant !== 'none') {
    return switchListVariant(editor);
  }
};

export const toggleListChunk = (
  editor: Editor,
  listVariant: ListVariant,
  range: Range,
  listVariantsInRange: Array<string>
): void => {
  const currentListVariant = getCurrentActiveListVariant(editor, range);
  let previousBlockIsList = false;
  let previousBlockPath = null;

  // If the selection is expanded, then we check to make sure if the previous block within
  // the selection to this range is a list.
  if (editor.selection != null && !Range.isCollapsed(editor.selection)) {
    const closestBlock = findClosestBlockPathAtSelection(editor, range);
    const beforeClosestBlock = Editor.before(editor, closestBlock, { unit: 'line' });
    const listDepth = getListDepth(editor, Editor.path(editor, range));

    const previousBlock =
      beforeClosestBlock != null &&
      editor.selection != null &&
      Range.includes(editor.selection, beforeClosestBlock)
        ? Editor.node(editor, beforeClosestBlock, { depth: listDepth === 0 ? 1 : listDepth })
        : null;
    previousBlockPath = previousBlock != null ? Editor.path(editor, previousBlock[1]) : null;
    previousBlockIsList =
      previousBlockPath != null &&
      previousBlock != null &&
      Element.isElement(previousBlock['0']) &&
      (previousBlock[0].type === LIST_PLUGIN_ID || isInsideListAtPath(editor, previousBlockPath));
  }

  // At this point, currentListVariant points to the list variant of the current range, not
  // the entire selection. So, if there is  no currentListVariant, that means our current range
  // is a regular block of text.
  if (
    (listVariant === LIST_VARIANTS.ol || listVariant === LIST_VARIANTS.ul) &&
    currentListVariant == null
  ) {
    // If the previous block in the selection is a list, then we should be extending that list
    // instead of creating a new list
    if (!previousBlockIsList) {
      return insertList(editor, listVariant, range);
    } else if (previousBlockPath != null && previousBlockIsList) {
      return extendPreviousList(editor, listVariant, range, previousBlockPath);
    }
  }

  if (currentListVariant === listVariant) {
    // If the currentListVariant is equal to the listVariant we want to switch to and there is only
    // a single type of variant within the entire selection, then we should demote the list items.
    if (listVariantsInRange.length === 1) {
      return demoteListItems(editor, range);
      // If there is more than one type of list variant within the selection, then we should do nothing, since
      // we want to keep the list within this range of our selection the same. However, if the previous block
      // in our a selection is a list that is not the same as our parent list, then we want to extend the previous
      // list instead of keeping this range as a separate list.
    } else if (previousBlockPath != null && previousBlockIsList) {
      const hasSameParentList = Path.isParent(previousBlockPath, range.anchor.path);
      if (!hasSameParentList) {
        return extendPreviousList(editor, listVariant, range, previousBlockPath);
      }
    }
  } else if (currentListVariant !== listVariant) {
    // If the previous block is not a list and this current range is a list of a different variant, then all we need to do is switch the variant
    if (!previousBlockIsList) {
      return switchListVariant(editor, range);
      // If the previous block in our selection is a list, then that means that we should be extending the previous list with this range rather
      // than creating a new list
    } else if (previousBlockPath != null) {
      return extendPreviousList(editor, listVariant, range, previousBlockPath);
    }
  }
};

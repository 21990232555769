import { Node } from 'domains/reporter/RichTextEditor/core';
import { Editor, Element } from '../core';
import { BRACKET_TYPES } from '../constants';
import { DEPLOY_ENV } from 'config';

export const areMultipleBracketsSelected = (editor: Editor): boolean => {
  try {
    const brackets = Editor.nodes<Node>(editor, {
      match: (n: Node) => Element.isElement(n) && BRACKET_TYPES.includes(n.type),
    });
    return [...brackets].length > 1;
  } catch (e: any) {
    // The editor's selection might be out of sync with the latest transform which
    // will cause this call to fail and throw. This function is called during the
    // render of a React component, meaning if we do not catch it we will crash
    // the editor. Being out of sync does not mean there is a bug, once the selection
    // syncs up the component that calls this will re-render and should work.
    if (DEPLOY_ENV !== 'production') {
      console.warn(e);
    }
    return false;
  }
};

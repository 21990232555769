import { Node } from 'domains/reporter/RichTextEditor/core';
import { useEffect, useRef } from 'react';
import { useSlateStatic } from 'slate-react';
import { useCurrentCaseReport } from 'hooks/useCurrentCaseReport';
import { API_GATEWAY_URL } from 'config';
import analytics from 'modules/analytics';
import { useSessionContext } from 'hooks/useSessionContext';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';
import { useReportLockedState } from '../../Reporter/hooks/useReportLockedState';

/**
 * Opens a WebSocket connection to the API and logs editor operations for a report.
 * Reports are stored on the backend in an s3 bucket with the key format:
 *
 * <clinic_name>/<report_smid>/<user_email>/<DD-MM-YYYY>/<hhmmss>-<counter>
 *
 * Hook will not open a WebSocket connection if the SLATE_OPS_LOG feature flag is off.
 */
export function useOpsLogger(initialState: Node[]) {
  const [isSlateOpsLogEnabled] = useFeatureFlagEnabled(FF.SLATE_OPS_LOG);
  const editor = useSlateStatic();
  const { currentCaseReport } = useCurrentCaseReport();
  const sessionContext = useSessionContext();
  const initialStateRef = useRef(initialState);
  const { locked } = useReportLockedState();

  const reportSmid = currentCaseReport?.report?.smid;

  useEffect(() => {
    if (reportSmid == null || isSlateOpsLogEnabled === false || locked) return;

    const ws = new WebSocket(
      `${API_GATEWAY_URL.replace('http', 'ws')}/ws/report/${reportSmid}/logs/`
    );

    let intervalId;
    let opsIndex = 0;

    const log = () => {
      if (!editor.log) return;

      const { ops } = editor.log;

      const endIndex = ops.length - 1;

      // opt out if there are no changes
      if (opsIndex === endIndex) return;

      ws.send(
        JSON.stringify({
          ops: ops.slice(opsIndex, endIndex),
        })
      );

      opsIndex = endIndex;
    };

    ws.onopen = () => {
      // send initial context
      ws.send(JSON.stringify({ sessionContext, ops: [], initialState: initialStateRef.current }));

      // send the latest logs from the last index every second
      intervalId = window.setInterval(log, 1000);
    };

    ws.onerror = (err: any) => {
      window.clearInterval(intervalId);
      analytics.error(err, {
        reportSmid,
      });
    };

    ws.onclose = () => {
      window.clearInterval(intervalId);
    };

    window.addEventListener('beforeunload', log);

    return () => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.close();
      } else {
        // If it isnt open, close it when it finally opens
        ws.onopen = () => {
          // @ts-expect-error [EN-7967] - TS2367 - This comparison appears to be unintentional because the types '2' and '3' have no overlap.
          if (ws.readyState !== WebSocket.CLOSING || ws.readyState !== WebSocket.CLOSED) {
            ws.close();
          }
        };
      }

      window.removeEventListener('beforeunload', log);
    };
  }, [locked, editor, reportSmid, sessionContext, isSlateOpsLogEnabled]);
}

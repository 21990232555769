import Text from 'common/ui/Text';
import { Stack } from 'common/ui/Layout';
import { useRecoilValue } from 'recoil';
import { emptyRequiredFieldsDialogRecoilState } from 'domains/reporter/Reporter/state';

export const EmptyRequiredFieldsDialogContent = (): React.ReactElement => {
  const emptyRequiredFieldDialogState = useRecoilValue(emptyRequiredFieldsDialogRecoilState);

  const emptyRequiredFields = emptyRequiredFieldDialogState?.emptyRequiredFields ?? [];

  return (
    <Stack vertical space="small">
      {emptyRequiredFields.length === 1 && (
        <Text color="black">There is [1] empty required field in your report.</Text>
      )}
      {emptyRequiredFields.length > 1 && (
        <Text color="black">
          There are [{emptyRequiredFields.length}] empty required fields in your report.
        </Text>
      )}
    </Stack>
  );
};

import styled from 'styled-components';
import type { ThemedStyledProps } from 'styled-components';
import { Colors, TextCss } from 'styles';
import { transparentize } from 'color2k';

type ToolbarButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  height?: string;
  hasLabels?: boolean;
  toolbarEditOn?: boolean;
  ['data-testid']?: string;
  ['data-analytics-name']?: string;
  css?: string;
  selected?: boolean;
  disabled?: boolean;
};

export const ToolbarButton = styled.button`
  app-region: no-drag;
  height: ${(props: ThemedStyledProps<ToolbarButtonProps, never>) =>
    props.height != null ? props.height : '6rem'};
  width: 48px;
  border: inherit;
  background: ${(props: ThemedStyledProps<ToolbarButtonProps, never>) =>
    props.selected === true ? Colors.blue3 : 'inherit'};
  color: ${(props: ThemedStyledProps<ToolbarButtonProps, never>) =>
    props.disabled === true ? Colors.gray8 : Colors.gray10};
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${TextCss.small}
  text-align: center;
  margin: ${(props: ThemedStyledProps<ToolbarButtonProps, never>) =>
    props.hasLabels === true ? '0.2rem 0.5rem' : '0'};
  &:hover {
    background-color: ${(props: ThemedStyledProps<ToolbarButtonProps, never>) =>
      props.toolbarEditOn === true ? 'inherit' : Colors.gray3};
    border-color: ${(props: ThemedStyledProps<ToolbarButtonProps, never>) =>
      props.toolbarEditOn === true ? 'inherit' : transparentize(Colors.gray10, 0.63)};
  }
`;

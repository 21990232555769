import { useSelected } from '../../../core';
import { PicklistElement } from './PicklistElement';
import type { PicklistPluginElement, ReportPicklistBeforeSubmissionOptions } from '../types';

type PicklistBuilderProps = {
  attributes: {
    ['data-slate-node']: 'element';
    ['data-slate-inline']?: true;
    ['data-slate-void']?: true;
    dir?: 'rtl';
    // @ts-expect-error [EN-7967] - TS2724 - 'React' has no exported member named 'Element'. Did you mean 'CElement'?
    ref: React.Ref<React.Element>;
  };
  options: ReadonlyArray<ReportPicklistBeforeSubmissionOptions>;
  children: React.ReactNode | Array<React.ReactNode>;
  element: PicklistPluginElement;
  ['data-editor-element']?: string;
  variant?: string;
};

export const PicklistBuilder = ({
  attributes,
  options = [],
  children,
  element,
  variant,
  ...rest
}: PicklistBuilderProps): React.ReactElement => {
  const selected = useSelected();

  return (
    <>
      <PicklistElement
        {...attributes}
        element={element}
        selected={selected}
        data-editor-element={rest['data-editor-element']}
        variant={variant}
      >
        {children}
      </PicklistElement>
    </>
  );
};

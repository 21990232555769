import { Stack } from 'common/ui/Layout';

import { css } from 'styled-components';
import { Fonts } from 'styles';

export const ReporterSettingsTabContainer = ({
  children,
  footer,
}: {
  children: React.ReactNode;
  footer?: React.ReactNode;
}): React.ReactElement => (
  <Stack
    vertical
    css={`
      font-family: ${Fonts.mainFont};
      border-radius: 10;
      width: 583px;
      max-height: 70vh;
    `}
    space="small"
  >
    <Stack
      vertical
      space="small"
      css={css`
        max-height: 70vh;
        overflow-y: auto;
      `}
    >
      {children}
    </Stack>
    {footer}
  </Stack>
);

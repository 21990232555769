import type { ToastKey } from 'common/ui/Toaster/Toast';
import type { Toast } from 'common/ui/Toaster/Toaster';
import type { AllReporterVariant } from '../../hooks/useReporter';
import type { EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';
import type { SetterOrUpdater } from 'recoil';
import { Key } from 'react';
import { Editor, Range } from 'slate';

export const REQUIRED_FIELDS_PLUGIN_ID: 'requiredFields' = 'requiredFields';
export type RequiredFieldsPluginID = typeof REQUIRED_FIELDS_PLUGIN_ID;

export type RequiredFieldsPluginOptions = Readonly<
  EditorPluginOptions & {
    setConfirmDeletionDialogState?: SetterOrUpdater<{
      deleteCallback: () => void;
      open: boolean;
      isMultiple: boolean;
    }>;
    isDialogOpenRef?: {
      current: boolean;
    };
    variant?: AllReporterVariant;
    enqueueToast?: (msg: React.ReactNode, options?: Readonly<Partial<Toast>>) => ToastKey;
    onInsertTextRequiredField?: (path: Key) => void;
    syncRequiredFields?: (selection: Range, editor: Editor) => void;
  }
>;

export type RequiredFieldsPluginPropertyOptions = Readonly<
  EditorPluginPropertyOptions &
    RequiredFieldsPluginOptions & {
      pluginID: RequiredFieldsPluginID;
      pluginName: string;
    }
>;

import type { CreateOnKeyDown } from '../../types';
import isHotkey from 'is-hotkey';
import type { ClearFormattingPluginPropertyOptions } from './types';
import { clearMarksForSelection } from '../../utils';

export const onKeyDownClearFormatting: CreateOnKeyDown<ClearFormattingPluginPropertyOptions> =
  ({ hotkey, pluginID }) =>
  (e: KeyboardEvent, { editor }) => {
    if (isHotkey(hotkey, e)) {
      e.preventDefault();
      clearMarksForSelection(editor);
      return true;
    }
  };

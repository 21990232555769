import { Flow } from 'flow-to-typescript-codemod';

import { Colors } from 'styles';
import type { RenderElementProps } from '../../../core';
import { Node } from '../../../core';
import type { PlaceholderPluginElement } from '../types';
import { NotEditable } from '../../../components';
import { EMPTY } from 'config/constants';
import { PLACEHOLDER_DELIMITER_LEFT, PLACEHOLDER_DELIMITER_RIGHT } from '../constants';
import { Transforms, ReactEditor } from 'domains/reporter/RichTextEditor/core';
import { useSlateSingletonContext } from '../../../../Reporter/SlateSingletonContext';

export type PlaceholderReportElementProps = Readonly<
  RenderElementProps<PlaceholderPluginElement> & {
    selected?: boolean;
    leftDelimiter?: React.ReactNode;
    rightDelimiter?: React.ReactNode;
    emptyText?: string;
    testID?: string;
    ['data-editor-element']?: string;
    attributes?: Readonly<{
      ['data-slate-inline']?: true;
      ['data-slate-node']: 'element';
      ['data-slate-void']?: true;
      dir?: 'rtl';
      // @ts-expect-error [EN-7967] - TS2724 - 'React' has no exported member named 'Element'. Did you mean 'CElement'?
      ref: React.Ref<React.Element>;
    }>;
  }
>;

export const PlaceholderReportElement: Flow.AbstractComponent<
  PlaceholderReportElementProps, // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  any
> = ({
  attributes = EMPTY.OBJECT,
  children,
  selected = false,
  element,
  leftDelimiter = PLACEHOLDER_DELIMITER_LEFT,
  rightDelimiter = PLACEHOLDER_DELIMITER_RIGHT,
  emptyText,
  testID,
  ...props
}) => {
  const isEmpty = Node.string(element) === '';
  const [{ editor }] = useSlateSingletonContext();

  return (
    <span
      {...attributes}
      onMouseUp={() => {
        if (isEmpty) {
          const path = ReactEditor.findPath(editor, element);
          Transforms.select(editor, path);
          return;
        }
      }}
      data-testid={testID}
      data-dd-privacy="mask"
      css={`
        padding: 2px 0px;
        vertical-align: baseline;
        border-radius: 4px;
        color: ${selected ? Colors.blue5 : Colors.pink6};

        &:focus {
          outline: 0;
        }
      `}
      {...props}
    >
      <NotEditable>{leftDelimiter}</NotEditable>
      {children}
      {emptyText != null && (
        <NotEditable>
          <span css="opacity: 0.5">{emptyText}</span>
        </NotEditable>
      )}
      <NotEditable>{rightDelimiter}</NotEditable>
    </span>
  );
};

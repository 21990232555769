import type { EditorPlugin } from '../../types';
import { InlineBookmarkEditable } from './components';
import { getRenderElement } from '../../utils/getRenderElement';
import { INLINE_BOOKMARK_PLUGIN_ID } from './types';
import type {
  InlineBookmarkPluginOptions,
  InlineBookmarkPluginPropertyOptions,
  InlineBookmarkPluginElement,
} from './types';
import { withInlineTemplate } from '../common/inlineTemplate';
import { createInlineBookmark } from './utils';
import { INLINE_BOOKMARK_DELIMITER_LEFT } from './constants';
import { onKeyDownInlineBookmark } from './onKeyDownInlineBookmark';

export const InlineBookmarkPlugin = ({
  component = InlineBookmarkEditable,
  pluginName = 'Inline Bookmark',
  hotkey = INLINE_BOOKMARK_DELIMITER_LEFT,
  ...rest
}: InlineBookmarkPluginOptions = {}): EditorPlugin => {
  const options: InlineBookmarkPluginPropertyOptions = {
    pluginID: INLINE_BOOKMARK_PLUGIN_ID,
    pluginName,
    hotkey,
    component,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    renderElement: getRenderElement<InlineBookmarkPluginPropertyOptions>(options, {
      spreadAttributes: false,
    }),
    enhanceEditorState: withInlineTemplate<InlineBookmarkPluginElement>({
      pluginID: options.pluginID,
      triggerText: options.hotkey,
      createNode: createInlineBookmark,
      onNodeCreate: options.onInlineBookmarkInsert,
      onNodeRemove: options.onInlineBookmarkRemove,
    }),
    onKeyDown: onKeyDownInlineBookmark(options),
  };
};

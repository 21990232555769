import { useSelected } from '../../../core';
import type { PlaceholderPluginElement, PlaceholderPluginPropertyOptions } from '../types';
import type { GetRenderElementComponentType } from '../../../utils/getRenderElement';
import { unreachableCaseError } from 'types';
import { useReporterState } from '../../../hooks';
import styled from 'styled-components';
import { PlaceholderTemplateElement } from './PlaceholderTemplate';
import { PlaceholderReportElement } from './PlaceholderReport';
import { Node } from 'slate';
import { getPlaceholderName } from '../utils';
import { PLACEHOLDER_DELIMITER_LEFT, PLACEHOLDER_DELIMITER_RIGHT } from '../constants';
import { useCurrentWorklistItems } from 'hooks/useCurrentWorklistItems';
import { formatStudyInformationWithoutDate } from 'common/StudyInformationCard';

export type PlaceholderEditableProps = Readonly<{
  children: React.ReactNode;
  selected?: boolean;
  handleClick?: () => void;
  testID?: string;
  ['data-editor-element']?: string;
  ['data-slate-node']?: 'element';
  ['data-slate-inline']?: true;
  ['data-slate-void']?: true;
  ['data-selected']?: boolean;
  dir?: 'rtl';
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  element: any;
  leftDelimiter?: string;
  rightDelimiter?: string;
}>;

// @ts-expect-error [EN-7967] - TS2322 - Type '({ pluginOptions: { onPlaceholderRemove, pluginID }, element, leftDelimiter, rightDelimiter, ...rest }: { [x: string]: any; pluginOptions: { onPlaceholderRemove: any; pluginID: any; }; element: any; leftDelimiter?: string; rightDelimiter?: string; }, ref: any) => Element' is not assignable to type 'GetRenderElementComponentType<Readonly<Readonly<{ pluginID: AllPluginID; pluginName?: string; hotkey?: string; icon?: ComponentType<Record<any, any>>; }> & Readonly<Readonly<{ pluginName?: string; hotkey?: string; icon?: ComponentType<...>; }> & { ...; }> & { ...; }>, Readonly<...>>'.
export const PlaceholderEditable: GetRenderElementComponentType<
  PlaceholderPluginPropertyOptions,
  PlaceholderPluginElement
> = (
  {
    pluginOptions: { onPlaceholderRemove, pluginID },
    element,
    leftDelimiter = PLACEHOLDER_DELIMITER_LEFT,
    rightDelimiter = PLACEHOLDER_DELIMITER_RIGHT,
    ...rest
  },
  ref
) => {
  const { variant } = useReporterState();
  const selected = useSelected();
  const { currentWorklistItems } = useCurrentWorklistItems();
  const { placeholderID, workListItemSmid } = element;
  let emptyText;
  const isEmpty = Node.string(element) === '';
  const placeholderName = getPlaceholderName(placeholderID);
  const worklistItem = currentWorklistItems.find((item) => item.smid === workListItemSmid);

  if (isEmpty === true) {
    emptyText = `missing value for ${placeholderName}${
      currentWorklistItems.length > 1
        ? ' for ' +
          formatStudyInformationWithoutDate(
            worklistItem?.studyDescription,
            worklistItem?.accessionNumber
          )
        : ''
    }`;
  }

  switch (variant) {
    case 'report':
      return (
        // @ts-expect-error [EN-7967] - TS2739 - Type '{ leftDelimiter: string; rightDelimiter: string; selected: boolean; element: any; emptyText: any; testID: string; }' is missing the following properties from type 'Readonly<RenderElementProps<Readonly<Readonly<{ children: any[]; }> & { type: "placeholder"; placeholderID: "age" | "studyDate" | "studyDescription" | "laterality" | "techNotes" | "currentDate" | "currentTime" | ... 4 more ... | "studyTime"; workListItemSmid: string; }>> & { ...; }>': children, attributes
        <PlaceholderReportElement
          {...rest}
          leftDelimiter={leftDelimiter}
          rightDelimiter={rightDelimiter}
          selected={selected}
          element={element}
          emptyText={emptyText}
          testID={`placeholder-${placeholderName}`}
        />
      );
    case 'fragment':
    case 'template':
      return (
        // @ts-expect-error [EN-7967] - TS2739 - Type '{ leftDelimiter: string; rightDelimiter: string; selected: boolean; element: any; emptyText: any; testID: string; }' is missing the following properties from type 'Readonly<RenderElementProps<Readonly<Readonly<{ children: any[]; }> & { type: "placeholder"; placeholderID: "age" | "studyDate" | "studyDescription" | "laterality" | "techNotes" | "currentDate" | "currentTime" | ... 4 more ... | "studyTime"; workListItemSmid: string; }>> & { ...; }>': children, attributes, "data-editor-element"
        <PlaceholderTemplateElement
          {...rest}
          leftDelimiter={leftDelimiter}
          rightDelimiter={rightDelimiter}
          selected={selected}
          element={element}
          emptyText={emptyText}
          testID={`placeholder-${placeholderName}`}
        />
      );
    case 'view':
      return null;

    default:
      unreachableCaseError(variant);
  }
};

export const PlaceholderRenderer = styled(PlaceholderReportElement)`
  white-space: pre-wrap;
`;

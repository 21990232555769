import type { EditorElement, EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';

export const SECTION_HEADER_PLUGIN_ID: 'sectionHeader' = 'sectionHeader';
export type SectionHeaderPluginID = typeof SECTION_HEADER_PLUGIN_ID;

export type SectionHeaderPluginElement = Readonly<
  EditorElement & {
    type: SectionHeaderPluginID;
    conceptID: string;
    viewerRefID: string;
  }
>;

export type SectionHeaderPluginOptions = Readonly<
  EditorPluginOptions & {
    // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
    component?: any;
  }
>;

export type SectionHeaderPluginPropertyOptions = Readonly<
  EditorPluginPropertyOptions &
    SectionHeaderPluginOptions & {
      pluginID: SectionHeaderPluginID;
      pluginName: string;
      // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
      component: any;
    }
>;

import type { ViewerMemoryManagerProps, ViewerMemoryConfig } from './ViewerMemoryManager';
import { useViewerId } from 'hooks/useViewerId';
import { viewportIdToConfig } from './viewerUtils';
import { useMemo } from 'react';
import type { BaseImagingProvider } from '../ViewportDre/modules/imaging/BaseImagingProvider';
import type { Stack } from '../ViewportsConfigurations/types';

// Chrome has a limit around 13.3 GB for Typed Arrays in a single tab
const CHROME_MEMORY_LIMIT = 13 * 1024 * 1024 * 1024;

// future-proofing more complicated logic for determining a memory limit;
const useMemoryLimit = () => CHROME_MEMORY_LIMIT;

// side-effect-free hook to determine what should be loaded at any given time
export function useViewerMemoryConfig({
  stackProviders,
  viewportsConfigurations,
  areViewportsReady,
}: ViewerMemoryManagerProps): ViewerMemoryConfig {
  const memoryLimit = useMemoryLimit();

  const viewerId = useViewerId();

  return useMemo(() => {
    // 1. Initialize
    const memoryConfigMap: ViewerMemoryConfig = new Map();
    // the second check is just for type safety and is one component of `areViewportsReady`
    if (!areViewportsReady || viewportsConfigurations == null) {
      return memoryConfigMap;
    }

    // 2. separate out providers not in this window's viewport configuration
    const stackProvidersInViewports: BaseImagingProvider<Stack>[] = [];
    const stackProvidersOutsideViewports: BaseImagingProvider<Stack>[] = [];

    stackProviders.forEach((provider) => {
      const matchingViewport = Object.entries(viewportsConfigurations).find(
        ([viewportId, viewportConfiguration]: [any, any]) =>
          viewportIdToConfig(viewportId).windowId === viewerId &&
          viewportConfiguration?.stack?.smid === provider.stack.smid
      );
      if (matchingViewport != null) {
        stackProvidersInViewports.push(provider);
      } else {
        stackProvidersOutsideViewports.push(provider);
      }
    });

    let remainingMemoryToUse = memoryLimit;

    // 3. Estimate hanged stack providers memory usage
    let idealMemoryForFullLoad = 0;
    const loadedStacksMemoryMap = new Map<BaseImagingProvider<Stack>, number>();
    stackProvidersInViewports.forEach((provider) => {
      const estimate = provider.estimateMemory();
      idealMemoryForFullLoad += estimate;
      loadedStacksMemoryMap.set(provider, estimate);
    });

    if (idealMemoryForFullLoad > remainingMemoryToUse) {
      stackProvidersInViewports.forEach((provider) => {
        const estimate = provider.estimateMemory();
        if (estimate <= remainingMemoryToUse) {
          loadedStacksMemoryMap.set(provider, estimate);
          remainingMemoryToUse -= estimate;
          memoryConfigMap.set(provider.stack.smid, 'full');
        } else {
          loadedStacksMemoryMap.set(provider, 0);
          memoryConfigMap.set(provider.stack.smid, 'none');
        }
      });

      stackProvidersOutsideViewports.forEach((provider) => {
        loadedStacksMemoryMap.set(provider, 0);
        memoryConfigMap.set(provider.stack.smid, 'none');
      });

      return memoryConfigMap;
    }

    // 4. Allocate Hanged Stacks
    loadedStacksMemoryMap.forEach((stackMemory, provider) => {
      remainingMemoryToUse -= stackMemory;
      memoryConfigMap.set(provider.stack.smid, 'full');
    });

    const memoryForUnhangedStacksFullLoad = stackProvidersOutsideViewports.reduce(
      (totalNeedForFullLoad, provider) => totalNeedForFullLoad + provider.estimateMemory(),
      0
    );
    idealMemoryForFullLoad += memoryForUnhangedStacksFullLoad;

    if (remainingMemoryToUse > memoryForUnhangedStacksFullLoad) {
      // 5. Allocate full memory for unhanged stacks
      stackProvidersOutsideViewports.forEach((provider) => {
        const estimate = provider.estimateMemory();
        loadedStacksMemoryMap.set(provider, estimate);
        memoryConfigMap.set(provider.stack.smid, 'full');
      });
      remainingMemoryToUse -= memoryForUnhangedStacksFullLoad;
    } else {
      // 5. Allocate minimal memory for unhanged non3dable stacks
      stackProvidersOutsideViewports.forEach((provider) => {
        const estimatePerFrame = provider.estimatePerFrameMemory();

        if (remainingMemoryToUse >= estimatePerFrame && !provider.is3Dable()) {
          loadedStacksMemoryMap.set(provider, estimatePerFrame);
          memoryConfigMap.set(provider.stack.smid, 'initial');
          remainingMemoryToUse -= estimatePerFrame;
        } else {
          loadedStacksMemoryMap.set(provider, 0);
          memoryConfigMap.set(provider.stack.smid, 'none');
        }
      });
    }

    return memoryConfigMap;
  }, [stackProviders, viewportsConfigurations, viewerId, areViewportsReady, memoryLimit]);
}

import { BroadcastChannel } from 'broadcast-channel';

export type BroadcastMessage = {
  type: string;
};

const channelsMap = new Map<string, BroadcastChannel<any>>();
const getBroadcastChannel = <T>(key: string): BroadcastChannel<T> => {
  const bc = channelsMap.get(key);
  if (bc != null) {
    return bc;
  } else {
    const newBc = new BroadcastChannel<T>(key);
    channelsMap.set(key, newBc);
    return newBc;
  }
};

export default function useBroadcastChannel<T = unknown>(key: string): BroadcastChannel<T> {
  return getBroadcastChannel<T>(key);
}

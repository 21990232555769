import type { GetRenderElementComponentType } from '../../../utils/getRenderElement';
import type { LineBreakPluginElement, LineBreakPluginPropertyOptions } from '../types';
import styled from 'styled-components';

export const LineBreakEditable: GetRenderElementComponentType<
  LineBreakPluginPropertyOptions,
  LineBreakPluginElement
> = ({ element, children, ...rest }): React.ReactElement => {
  return (
    <span contentEditable={false}>
      {children}
      <br />
    </span>
  );
};

export const LineBreakRenderer = styled(LineBreakEditable)`
  white-space: pre-wrap;
`;

import type { EditorElement } from '../../types';
import type { ComponentType } from 'react';
import type { ListToolbarMode, ListVariant } from './constants';
import type { CurrentActiveListVariantState } from './hooks/useCurrentList';

export const LIST_PLUGIN_ID: 'list' = 'list';
export type ListPluginID = typeof LIST_PLUGIN_ID;

export type ListPluginOptions = Readonly<{
  // $FlowFixMe[unclear-type]
  component?: ComponentType<any>;
  toolbarMode: ListToolbarMode;
  setCurrentActiveListVariant: (arg1: CurrentActiveListVariantState) => void;
}>;

export type ListPluginElement = Readonly<
  EditorElement & {
    type: ListPluginID;
    variant: ListVariant;
  }
>;

export type ListPluginPropertyOptions = Readonly<{
  pluginID: ListPluginID;
  // $FlowFixMe[unclear-type]
  component?: ComponentType<any>;
  setCurrentActiveListVariant: (arg1: CurrentActiveListVariantState) => void;
}>;

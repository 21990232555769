import { Editor } from 'domains/reporter/RichTextEditor/core';
import type { RecorderTarget } from 'common/Recorder/useRecorder';
import type { CreateEnhanceProvider } from '../../types';
import type { ErrorResponse } from './ASRPlex/ASRPlexProtocol';
import type { NvoqError } from './NvoqQueue/createNvoqWebsocketFactory';
import type {
  DictationPluginPropertyOptions,
  OnStableText,
  RecorderVoiceCommandProps,
} from './types';

import { ASRPlexDictation } from './ASRPlex/ASRPlexDictation';
import { DictationProvider } from './DictationProvider';
import { NvoqQueueProvider } from './NvoqQueue/useNvoqQueue';

import { useRecorder } from 'common/Recorder/useRecorder';
import { useToasterDispatch } from 'common/ui/Toaster';
import { NVOQ_WS_MAXIMUM_CONNECTIONS } from 'config';
import { NAMESPACES, sendEvent } from 'modules/EventsManager';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';
import { logger } from 'modules/logger';
import { useCallback } from 'react';
import type { SlateProps } from 'slate-react';

type ProviderComponentProps = Readonly<{
  editor: Editor;
  nvoqID: string;
  nvoqAuthorization: string;
  /**
   * Important! Must be unique per editor instantiated in the app.
   */
  target: RecorderTarget;
  children: React.ReactNode;
  enablePicklistDictation: boolean;
  // asr-plex
  onStableText?: OnStableText;
  activeTemplateId?: string;
  aiMode?: boolean;
}>;

const ProviderComponent = ({
  children,
  editor,
  nvoqAuthorization,
  nvoqID,
  target,
  onStableText,
  enablePicklistDictation,
  activeTemplateId,
  aiMode = false,
  ...rest
}: ProviderComponentProps): React.ReactElement => {
  const { isRecording, stopRecording, recorderTarget } = useRecorder();
  const { enqueueToast } = useToasterDispatch();

  const onExternalSubstitution = useCallback((props: RecorderVoiceCommandProps) => {
    sendEvent(NAMESPACES.RECORDER_VOICE_COMMAND, props);
  }, []);

  type ErrorMessage =
    | ErrorResponse
    | {
        msg: NvoqError;
      }
    | undefined;
  type MessageExtractor = (arg1: ErrorMessage) => string | null | undefined;
  function errorHandlerFactory(messageExtractor: MessageExtractor) {
    return (e: ErrorMessage) => {
      const message = `Dictation Service Error: ${messageExtractor(e) ?? 'Unknown error'}`;
      enqueueToast(message, {
        severity: 'error',
      });
      logger.error(message);

      stopRecording();
    };
  }

  const baseProps = {
    editor,
    isEnabled: isRecording && target === recorderTarget,
    enablePicklistDictation,
    onExternalSubstitution,
    onWebsocketError: () => {
      const message = 'Dictation Service is down right now and should be back in a few minutes.';
      enqueueToast(message, {
        severity: 'error',
      });
      logger.error(message);

      stopRecording();
    },
  } as const;
  const [accurateTextPlacementEnabled] = useFeatureFlagEnabled(FF.ACCURATE_TEXT_PLACEMENT);
  const [asrPlexFeatureEnabled] = useFeatureFlagEnabled(FF.REPORTER_ASR_PLEX);
  if (accurateTextPlacementEnabled) {
    return (
      <DictationProvider
        editor={editor}
        enablePicklistDictation={enablePicklistDictation}
        onExternalSubstitution={onExternalSubstitution}
        // @ts-expect-error [EN-7967] - TS2339 - Property 'payload' does not exist on type 'ErrorMessage'.
        onError={errorHandlerFactory((e) => e?.payload?.message)}
        onWebsocketError={() => {
          const message =
            'Dictation Service is down right now and should be back in a few minutes.';
          enqueueToast(message, {
            severity: 'error',
          });
          logger.error(message);

          stopRecording();
        }}
      >
        {children}
      </DictationProvider>
    );
  }
  if (asrPlexFeatureEnabled) {
    return (
      <ASRPlexDictation
        {...baseProps}
        // @ts-expect-error [EN-7967] - TS2339 - Property 'payload' does not exist on type 'ErrorMessage'.
        onError={errorHandlerFactory((e) => e?.payload?.message)}
        activeTemplateId={activeTemplateId}
        aiMode={aiMode}
        onStableText={onStableText}
      >
        {children}
      </ASRPlexDictation>
    );
  }
  return (
    <NvoqQueueProvider
      {...baseProps}
      maxNumberOfConnections={NVOQ_WS_MAXIMUM_CONNECTIONS}
      nvoqAuthorization={nvoqAuthorization}
      nvoqID={nvoqID}
      // @ts-expect-error [EN-7967] - TS2339 - Property 'msg' does not exist on type 'ErrorMessage'.
      onNvoqError={errorHandlerFactory((e) => e?.msg?.error?.message)}
    >
      {children}
    </NvoqQueueProvider>
  );
};

export const enhanceProviderDictation: CreateEnhanceProvider<DictationPluginPropertyOptions> =
  ({
    pluginID,
    nvoqAuthorization,
    nvoqID,
    target,
    enablePicklistDictation,
    activeTemplateId,
    aiMode,
    onStableText,
  }) =>
  (Component: React.ComponentType<SlateProps>) =>
    function EnhanceProviderDictation(props: SlateProps) {
      return (
        <ProviderComponent
          nvoqAuthorization={nvoqAuthorization}
          nvoqID={nvoqID}
          editor={props.editor}
          target={target}
          enablePicklistDictation={enablePicklistDictation}
          activeTemplateId={activeTemplateId}
          aiMode={aiMode}
          onStableText={onStableText}
        >
          <Component {...props} />
        </ProviderComponent>
      );
    };

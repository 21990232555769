import type {
  FilterDefinitionType,
  FilterStateType,
  WorklistFiltersType,
  WorklistSurfaceSearchValues,
} from './types';

import { SORTABLE_WORKLIST_COLUMN_MAP, SORT_ORDER } from 'config/constants';
import { WorklistSurface } from '../Worklist/types';
import type { WorklistSurfaceType } from '../Worklist/types';
import type { SortColumn, SortOrder } from 'generated/graphql';
import { formatStatusName } from 'hooks/useWorklistCustomStatuses';

export const DEFAULT_WORKLIST_SORT_COLUMN_ID = 'studyDate';
export const DEFAULT_SORT_COLUMNS: Array<SortColumn> = [
  SORTABLE_WORKLIST_COLUMN_MAP[DEFAULT_WORKLIST_SORT_COLUMN_ID] as SortColumn,
];

export const DEFAULT_SORT_ORDERS: Array<SortOrder> = [SORT_ORDER.ASCENDING as SortOrder];

export const DEFAULT_WORKLIST_SURFACE_SEARCH_VALUES: WorklistSurfaceSearchValues =
  Object.fromEntries(
    // $FlowFixMe[incompatible-type]
    Object.values(WorklistSurface).map((surface: WorklistSurfaceType) => [surface, ''])
  );

export const DEFAULT_EMPTY_WORKLIST_SURFACE_FILTERS: {
  [key: string]: FilterStateType;
} = Object.fromEntries(
  // $FlowFixMe[incompatible-type]
  Object.values(WorklistSurface).map((surface: WorklistSurfaceType) => [surface, {}])
);

export type FilterConfig = Partial<
  Record<
    WorklistSurfaceType,
    {
      lockedFilters: FilterStateType;
      defaultFilters: FilterDefinitionType;
      hiddenFilters: WorklistFiltersType[];
    }
  >
>;

export const WorklistFilterDateTypes = [
  'submittedAt',
  'submitted_at',
  'study_date',
  'study_date_before',
  'study_date_after',
  'patient_dob',
  'patientDob',
];

export const WorklistFiltersCustomFormatters = {
  custom_status: formatStatusName,
} as const;

import { Text } from '../../core';
import type { CreateDecorate } from '../../types';
import type { HighlightedPluginPropertyOptions } from './types';
import { Range } from 'domains/reporter/RichTextEditor/core';

export const getDecorate: CreateDecorate<HighlightedPluginPropertyOptions> = ({ words }) => {
  return ([node, path]) => {
    const ranges: Array<Range> = [];

    if (Text.isText(node) && typeof node.text === 'string') {
      const { text } = node;
      const nodeWords = text.split(/\s/);
      let offset = 0;

      // Directly search for numerical measurements.
      // This will target all numbers that may be whole or decimal,
      // including those with comma separators at three digit intervals, and negative numbers.
      nodeWords.forEach((word) => {
        const numericMatch = word.match(
          /^[^a-zA-Z0-9]*?([-]?\d+(?:,\d{3})*?(?:\.\d+)?(?:-\d+)?%?)(?:.)?([^a-zA-Z0-9])?$/
        );

        if (numericMatch != null) {
          const matchedText = numericMatch[1];
          const initialOffset = offset + word.indexOf(matchedText);

          ranges.push({
            anchor: { path, offset: initialOffset },
            focus: { path, offset: initialOffset + matchedText.length },
            highlighted: true,
          });
        }

        offset += word.length + 1;
      });

      words.forEach((word) => {
        /**
         * Generate a global regex starting from the provided literal string
         * or the provided generic regex, we need them to be global because
         * String.prototype.matchAll requires them to be this way.
         * Literal string regexes will only mactch whole words, not substrings.
         */
        const regexp =
          typeof word === 'string' ? new RegExp(`\\b${word}\\b`, 'gi') : new RegExp(word, 'g');

        /**
         * Perform a matchAll on the text so that we find all the matching strings
         * the operation will return a list of indexes (to know where the match starts)
         * and matched strings (to know how long the match is)
         * We can then use this information to append a new range to the Slate decorations
         */
        const matches = Array.from(text.matchAll(regexp));

        matches.forEach((match) => {
          ranges.push({
            anchor: { path, offset: match.index },
            focus: { path, offset: match.index + match[0].length },
            highlighted: true,
          });
        });
      });
    }

    return ranges;
  };
};

import styled from 'styled-components';
import backgroundUrl from './background.webp';
import { transparentize } from 'color2k';
import { Colors } from 'styles';

const bgOverlay = transparentize(Colors.black, 0.6);
export const Background = styled.div`
  background-image:
    linear-gradient(180deg, ${bgOverlay} 0%, ${bgOverlay} 100%),
    /* overlay */ url(${backgroundUrl}),
    /* background image */ radial-gradient(${Colors.blue1} 0%, transparent 70%); /* fallback radial gradient */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

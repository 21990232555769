import React, { useMemo } from 'react';
import { Slate } from '../core';
import type { SlateProps } from '../core';
import { compose } from 'ramda';
import { useSlateSingletonContext } from '../../Reporter/SlateSingletonContext';
import { usePlugins } from '../hooks';

export const EnhancedSlate = ({
  editor,
  onChange,
  value,
  children,
}: SlateProps): React.ReactElement => {
  const [{ editor: ctxEditor }] = useSlateSingletonContext();
  const pluginsBag = usePlugins();
  const { getProviderEnhancers } = pluginsBag;

  const EnhancedSlateComponent = useMemo(() => {
    if (ctxEditor == null) return Slate;
    const providerEnhancers = getProviderEnhancers();
    // @ts-expect-error [EN-7967] - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
    return providerEnhancers.length > 0 ? compose(...providerEnhancers)(Slate) : Slate;
  }, [getProviderEnhancers, ctxEditor]);

  return (
    <EnhancedSlateComponent editor={editor} onChange={onChange} value={value}>
      {children}
    </EnhancedSlateComponent>
  );
};

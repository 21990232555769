import { Range } from 'domains/reporter/RichTextEditor/core';
import type { Emitter } from 'mitt';
import { NVOQ_WS_URL } from 'config';
import { APP_VERSION } from 'config/constants';
import { marks, measures } from 'domains/reporter/RichTextEditor/analytics/performance';
import { logger } from 'modules/logger';
import { performance, createMarkForId } from 'utils/performance';
import { nvoqExternalId } from './nvoqExternalId';
import type { ExperimentConfiguration } from 'domains/reporter/Reporter/ExperimentControlTab';
import type { StepEntry } from './useSteps';
import { AudioFormat } from 'domains/reporter/audioTypes';
import { stringifyRange } from 'domains/reporter/RichTextEditor/utils/stringify';
import analytics from 'modules/analytics';
import { reporter } from 'modules/analytics/constants';
import { wordCount } from '../utils';
import type { StableTextResponse } from '../ASRPlex/ASRPlexProtocol';

export const NVOQ_DONE_MESSAGE = {
  apiVersion: '1.0',
  method: 'AUDIODONE',
} as const;

export type NvoqDoneMessage = Readonly<{
  apiVersion: string;
  method: 'AUDIODONE';
}>;

type NvoqBoundaryRequestMessage = Readonly<{
  apiVersion: string;
  method: 'BOUNDARYREQUEST';
  params: {
    requestedBoundaryTime: number;
  };
}>;

export const createNvoqBoundaryRequestMessage = (
  requestedBoundaryTime: number
): NvoqBoundaryRequestMessage => {
  return {
    apiVersion: '1.1',
    method: 'BOUNDARYREQUEST',
    params: {
      requestedBoundaryTime,
    },
  };
};

type Kind = 'STABLETEXT' | 'HYPOTHESISTEXT';

export type NvoqMessage = Readonly<{
  id: string;
  apiVersion: string;
  method: 'TEXT';
  data: {
    text: string;
    substitutedText: string;
    kind: Kind;
    markers: Array<{
      audioStart: number;
      audioLength: number;
      text: string;
    }>;
    textDone: boolean;
    maxAlternates: number;
  };
}>;

export type NvoqStartMessage = Readonly<{
  id: string;
  apiVersion: string;
  method: 'STARTDICTATION';
}>;

export type NvoqError = Readonly<{
  apiVersion: string;
  method: 'TEXT';
  id: string;
  error: {
    reason: string;
    message: string;
  };
}>;

export type VoiceCommandsViewer =
  | '1x1Layout'
  | '1x2Layout'
  | '2x1Layout'
  | '2x2Layout'
  | '3x1Layout'
  | '3x2Layout'
  | '4x1Layout'
  | '4x2Layout'
  | 'Accept'
  | 'AnatomicNavigator'
  | 'Angle'
  | 'Annotations'
  | 'BookmarkImage'
  | 'Cine'
  | 'Circle'
  | 'CobbAngle'
  | 'Contour'
  | 'Ellipse'
  | 'Eraser'
  | 'FastScroll'
  | 'HorizontalFlip'
  | 'Invert'
  | 'JumpTo'
  | 'Landmark'
  | 'Length'
  | 'MagnifyGlass'
  | 'Measure'
  | 'NextLevel'
  | 'Pan'
  | 'Pan'
  | 'PreviousLevel'
  | 'Rect'
  | 'RefreshPage'
  | 'RegularScroll'
  | 'Reject'
  | 'Reset'
  | 'Rotate'
  | 'Rotate90Degrees'
  | 'Vertebrae'
  | 'VerticalFlip'
  | 'WindowLevel'
  | 'WindowLevelPresetBone'
  | 'WindowLevelPresetBrain'
  | 'WindowLevelPresetChestAbdomenPelvis'
  | 'WindowLevelPresetHeadNeck'
  | 'WindowLevelPresetLung'
  | 'Zoom'
  | 'InsertBookmark';

export type VoiceCommandsReporter =
  | 'ClassicMode'
  | 'FocusMode'
  | 'InsertReportTemplate'
  | 'SubmitReport'
  | 'DiscardReport'
  | 'DraftReport';

export type VoiceCommandsEditor =
  | 'DeleteContent'
  | 'Backspace'
  | 'AllCaps'
  | 'Undo'
  | 'Redo'
  | 'NextBracket'
  | 'PreviousBracket'
  | 'NextSection'
  | 'InsertMacro'
  | 'EndOfLine'
  | 'BulletThat'
  | 'NumberThat'
  | 'StartBullet'
  | 'StartNumbering'
  | 'StartIndentedBullet'
  | 'StartIndentedNumbering'
  | 'RemoveBullet'
  | 'RemoveNumbering'
  | 'NextBullet'
  | 'NextNumber'
  | 'NextNumberDynamic'
  | 'StopBullet'
  | 'IncreaseIndent'
  | 'DecreaseIndent'
  | 'NextParagraphInList'
  | 'GoToField'
  | 'PicklistOptionSelection'
  | 'NewField'
  | 'GenerateImpression';

export type VoiceCommandsWorklist = '';

export type VoiceCommandsDebug = 'FileDebugTicket' | 'SubmitDebugTicket';

export const VOICE_COMMAND_TYPES_EDITOR: VoiceCommandsEditor[] = [
  'DeleteContent',
  'Backspace',
  'AllCaps',
  'Undo',
  'Redo',
  'NextBracket',
  'PreviousBracket',
  'NextSection',
  'InsertMacro',
  'EndOfLine',
  'BulletThat',
  'NumberThat',
  'StartBullet',
  'StartNumbering',
  'StartIndentedBullet',
  'StartIndentedNumbering',
  'RemoveBullet',
  'RemoveNumbering',
  'NextBullet',
  'NextNumber',
  'NextNumberDynamic',
  'StopBullet',
  'IncreaseIndent',
  'DecreaseIndent',
  'NextParagraphInList',
  'GoToField',
  'PicklistOptionSelection',
  'NewField',
  'GenerateImpression',
];

export const VOICE_COMMAND_TYPES_SELECTION_CHANGE: VoiceCommandsEditor[] = [
  'NextBracket',
  'PreviousBracket',
];

export const VOICE_COMMAND_TYPES_REPORTER: VoiceCommandsReporter[] = [
  'ClassicMode',
  'FocusMode',
  'InsertReportTemplate',
  'SubmitReport',
  'DiscardReport',
  'DraftReport',
];

export const VOICE_COMMAND_TYPES_VIEWER: VoiceCommandsViewer[] = [
  '1x1Layout',
  '1x2Layout',
  '2x1Layout',
  '2x2Layout',
  '3x1Layout',
  '3x2Layout',
  '4x1Layout',
  '4x2Layout',
  'Accept',
  'AnatomicNavigator',
  'Angle',
  'Annotations',
  'BookmarkImage',
  'Cine',
  'Circle',
  'CobbAngle',
  'Contour',
  'Ellipse',
  'Eraser',
  'FastScroll',
  'HorizontalFlip',
  'Invert',
  'JumpTo',
  'Landmark',
  'Length',
  'MagnifyGlass',
  'Measure',
  'NextLevel',
  'Pan',
  'Pan',
  'PreviousLevel',
  'Rect',
  'RefreshPage',
  'RegularScroll',
  'Reject',
  'Reset',
  'Rotate',
  'Rotate90Degrees',
  'Vertebrae',
  'VerticalFlip',
  'WindowLevel',
  'WindowLevelPresetBone',
  'WindowLevelPresetBrain',
  'WindowLevelPresetChestAbdomenPelvis',
  'WindowLevelPresetHeadNeck',
  'WindowLevelPresetLung',
  'Zoom',
];

export const VOICE_COMMAND_TYPES_WORKLIST: VoiceCommandsWorklist[] = [];

export const VOICE_COMMAND_TYPES_DEBUG: VoiceCommandsDebug[] = [
  'FileDebugTicket',
  'SubmitDebugTicket',
];

export const VOICE_COMMAND_TARGET_EDITOR: 'EDITOR' = 'EDITOR';
export const VOICE_COMMAND_TARGET_REPORTER: 'REPORTER' = 'REPORTER';
export const VOICE_COMMAND_TARGET_VIEWER: 'VIEWER' = 'VIEWER';
export const VOICE_COMMAND_TARGET_WORKLIST: 'WORKLIST' = 'WORKLIST';
export const VOICE_COMMAND_TARGET_DEBUG: 'DEBUG' = 'DEBUG';
export const VOICE_COMMAND_TARGET_UNKNOWN: 'UNKNOWN' = 'UNKNOWN';

export type VoiceCommandTarget =
  | typeof VOICE_COMMAND_TARGET_EDITOR
  | typeof VOICE_COMMAND_TARGET_REPORTER
  | typeof VOICE_COMMAND_TARGET_VIEWER
  | typeof VOICE_COMMAND_TARGET_WORKLIST
  | typeof VOICE_COMMAND_TARGET_DEBUG
  | typeof VOICE_COMMAND_TARGET_UNKNOWN;

export type VoiceCommandTypeExternal =
  | VoiceCommandsViewer
  | VoiceCommandsReporter
  | VoiceCommandsWorklist
  | VoiceCommandsDebug;

export type VoiceCommandType =
  | VoiceCommandsViewer
  | VoiceCommandsReporter
  | VoiceCommandsEditor
  | VoiceCommandsWorklist
  | VoiceCommandsDebug;

export type VoiceCommandTypes =
  | VoiceCommandsViewer[]
  | VoiceCommandsReporter[]
  | VoiceCommandsEditor[]
  | VoiceCommandsWorklist[]
  | VoiceCommandsDebug[];

export const VOICE_COMMAND_TARGET_TO_TYPES: [VoiceCommandTarget, VoiceCommandTypes][] = [
  [VOICE_COMMAND_TARGET_EDITOR, VOICE_COMMAND_TYPES_EDITOR],
  [VOICE_COMMAND_TARGET_REPORTER, VOICE_COMMAND_TYPES_REPORTER],
  [VOICE_COMMAND_TARGET_VIEWER, VOICE_COMMAND_TYPES_VIEWER],
  [VOICE_COMMAND_TARGET_WORKLIST, VOICE_COMMAND_TYPES_WORKLIST],
  [VOICE_COMMAND_TARGET_DEBUG, VOICE_COMMAND_TYPES_DEBUG],
];

// Sirona (not nVoq) because we define the shape
export type SironaSubstitution = Readonly<{
  smid?: string;
  action: VoiceCommandType;
}>;

export type ParsedDictationChunkText = Readonly<{
  type: 'TEXT';
  payload: NvoqMessage;
}>;

export type ParsedDictationChunkSubstitutionEditor = Readonly<{
  type: 'SUBSTITUTION';
  payload: Readonly<{
    target: typeof VOICE_COMMAND_TARGET_EDITOR;
    smid?: string;
    action: VoiceCommandsEditor;
  }>;
}>;

export type ParsedDictationChunkSubstitutionEditorGoToField = Readonly<{
  type: 'SUBSTITUTION';
  payload: Readonly<{
    target: typeof VOICE_COMMAND_TARGET_EDITOR;
    fieldName: string;
    action: 'GoToField';
  }>;
}>;

export type ParsedDictationChunkSubstitutionEditorNextNumberDynamic = Readonly<{
  type: 'SUBSTITUTION';
  payload: Readonly<{
    target: typeof VOICE_COMMAND_TARGET_EDITOR;
    listItemIndex: number;
    originalTextStepEntry: StepEntry | null;
    action: 'NextNumberDynamic';
  }>;
}>;

export type ParsedDictationChunkSubstitutionEditorStartNumbering = Readonly<{
  type: 'SUBSTITUTION';
  payload: Readonly<{
    target: typeof VOICE_COMMAND_TARGET_EDITOR;
    originalTextStepEntry: StepEntry | null;
    action: 'StartNumbering';
  }>;
}>;

export type ParsedDictationChunkSubstitutionEditorPicklistOptionSelection = Readonly<{
  type: 'SUBSTITUTION';
  payload: Readonly<{
    target: typeof VOICE_COMMAND_TARGET_EDITOR;
    pickIndex: number;
    action: 'PicklistOptionSelection';
  }>;
}>;

export type ParsedDictationChunkSubstitutionViewer = Readonly<{
  type: 'SUBSTITUTION';
  payload: Readonly<{
    target: typeof VOICE_COMMAND_TARGET_VIEWER;
    smid?: string;
    action: VoiceCommandsViewer;
  }>;
}>;

export type ParsedDictationChunkSubstitutionWorklist = Readonly<{
  type: 'SUBSTITUTION';
  payload: Readonly<{
    target: typeof VOICE_COMMAND_TARGET_WORKLIST;
    smid?: string;
    action: VoiceCommandsWorklist;
  }>;
}>;

export type ParsedDictationChunkSubstitutionDebug = Readonly<{
  type: 'SUBSTITUTION';
  payload: Readonly<{
    target: typeof VOICE_COMMAND_TARGET_DEBUG;
    smid?: string;
    action: VoiceCommandsDebug;
  }>;
}>;

export type ParsedDictationChunkSubstitutionReporter = Readonly<{
  type: 'SUBSTITUTION';
  payload: Readonly<{
    target: typeof VOICE_COMMAND_TARGET_REPORTER;
    smid?: string;
    action: VoiceCommandsReporter;
  }>;
}>;

export type ParsedDictationChunkExternalSubstitution =
  | ParsedDictationChunkSubstitutionViewer
  | ParsedDictationChunkSubstitutionWorklist
  | ParsedDictationChunkSubstitutionReporter
  | ParsedDictationChunkSubstitutionDebug;

export type ParsedDictationChunkExternalSubstitutionPayload =
  | ParsedDictationChunkSubstitutionViewer['payload']
  | ParsedDictationChunkSubstitutionWorklist['payload']
  | ParsedDictationChunkSubstitutionReporter['payload']
  | ParsedDictationChunkSubstitutionDebug['payload'];

export type ParsedDictationChunk =
  | ParsedDictationChunkText
  | StableTextResponse
  | ParsedDictationChunkSubstitutionEditor
  | ParsedDictationChunkSubstitutionEditorGoToField
  | ParsedDictationChunkSubstitutionEditorPicklistOptionSelection
  | ParsedDictationChunkSubstitutionEditorNextNumberDynamic
  | ParsedDictationChunkSubstitutionEditorStartNumbering
  | ParsedDictationChunkSubstitutionViewer
  | ParsedDictationChunkSubstitutionWorklist
  | ParsedDictationChunkSubstitutionReporter
  | ParsedDictationChunkSubstitutionDebug;

export type NvoqWebsocketEvent =
  | 'on_open'
  | 'on_stable_text'
  | 'on_hypothesis_text'
  | 'on_close'
  | 'on_nvoq_error'
  | 'on_websocket_error';

export type AdditionalParams = {
  selectedConfiguration: ExperimentConfiguration;
  userId: string | null | undefined;
  clinicId: string | null | undefined;
  worklistItemId: string | null | undefined;
  audioInputLabel: string | null | undefined;
  env: string | null | undefined;
  version: string;
  userAgent: string;
};

export type NvoqWebsocketConfig = {
  id: string;
  selection: Range;
  worklistItemId: string;
  additionalParams: AdditionalParams;
};

export const createNvoqWebsocketFactory =
  (
    // @ts-expect-error [EN-7967] - TS2315 - Type 'Emitter' is not generic.
    emitter: Emitter<NvoqWebsocketEvent>,
    {
      nvoqID,
      nvoqAuthorization,
    }: {
      nvoqID: string;
      nvoqAuthorization: string;
    }
  ): ((config: NvoqWebsocketConfig) => WebSocket) =>
  ({ id, selection, worklistItemId, additionalParams }) => {
    const { selectedConfiguration: sc } = additionalParams;
    const externalId = nvoqExternalId(worklistItemId);
    const start = {
      apiVersion: '1.0',
      method: 'STARTDICTATION',
      params: {
        id: nvoqID,
        authorization: nvoqAuthorization,
        audioFormat: {
          encoding: sc.webmEnabled ? AudioFormat.webm : AudioFormat.pcm16khz,
          sampleRate: sc.webmEnabled ? sc.audioSamplingRate : 16000,
        },
        timeStamp: Date.now(),
        returnSubscriptions: ['STABLETEXT', 'HYPOTHESISTEXT'],
        externalId,
        microphone: additionalParams.audioInputLabel ?? 'N/A',
        additionalParams: {
          nonce: id,
          label: sc.label,
          audioSamplingRate: JSON.stringify(sc.audioSamplingRate),
          webmEnabled: JSON.stringify(sc.webmEnabled),
          outputSamplingRate: JSON.stringify(sc.webmEnabled ? sc.audioSamplingRate : 16000),
          leadingSilenceEnabled: JSON.stringify(sc.leadingSilenceDetectionEnabled),
          echoCancellation: JSON.stringify(sc.echoCancellation),
          autoGainControl: JSON.stringify(sc.autoGainControl),
          noiseSuppression: JSON.stringify(sc.noiseSuppression),
          userId: additionalParams.userId,
          clinicId: additionalParams.clinicId,
          worklistItemId: additionalParams.worklistItemId,
          audioInputLabel: additionalParams.audioInputLabel,
          env: additionalParams.env,
          version: additionalParams.version,
          userAgent: additionalParams.userAgent,
          clientVendor: 'Sirona Medical',
          clientProduct: 'Sirona Medical Reporter',
          clientVersion: `${APP_VERSION ?? 'N/A'}`,
        },
        // This is the maximum number of alternative transcripts we want nVoq to produce.
        maxNBest: 5,
      },
    } as const;

    performance.mark(marks.nvoq.WS.Create, id);
    const nvoqUrl = NVOQ_WS_URL;
    const socket = new WebSocket(nvoqUrl);
    analytics.track(reporter.sys.nvoqWebSocketTimeCreated);

    socket.onopen = (e: any) => {
      performance.mark(marks.nvoq.WS.Open, id);
      performance.measure(
        measures.nvoq.WS.TimeToConnect.name,
        createMarkForId(measures.nvoq.WS.TimeToConnect.startMark, id),
        createMarkForId(measures.nvoq.WS.TimeToConnect.endMark, id)
      );

      performance.mark(marks.nvoq.Dictation.StartDictation, id);
      socket.send(JSON.stringify(start));
      emitter.emit('on_open', { id, selection });
      logger.info(
        `[createNvoqWebsocketFactory] Websocket to nVoq opened - externalId: ${externalId}`,
        {
          logId: id,
          externalId,
          id,
          selection,
        }
      );
    };
    socket.onmessage = (e: MessageEvent) => {
      const msg = JSON.parse(typeof e.data === 'string' ? e.data : '') as
        | NvoqMessage
        | NvoqError
        | NvoqStartMessage;

      // @ts-expect-error [EN-7967] - TS2339 - Property 'error' does not exist on type 'Readonly<{ id: string; apiVersion: string; method: "TEXT"; data: { text: string; substitutedText: string; kind: Kind; markers: { audioStart: number; audioLength: number; text: string; }[]; textDone: boolean; maxAlternates: number; }; }> | Readonly<...> | Readonly<...>'.
      if (msg.error) {
        // NOTE(mwood23): nVoq won't tell us how their API functions or commit to anything because
        // they're nVoq and they insist on taking every best practice or specification
        // and tossing it in a dumpster.
        //
        // That being said, I have made the decision to not close the websocket connection on an error
        // because it's possible that there is an error in the middle of a dictation and it appears
        // they send a textDone=true message after the error. Why? No one knows including them.
        //
        // By leaving the socket open until they close it, we have the ability to resolve dictation
        // through an error and also ensure that progressive rendering does not get hung. If this causes
        // a problem, close the socket and test that the reporter cannot have orphaned hypothesis text
        // decorations.
        //
        // if nVoq returns any error, close the WebSocket
        // https://test.nvoq.com/apidoc/dictation#operation/wsMessageErrorHandling
        // socket.close();
        emitter.emit('on_nvoq_error', { msg });
        return;
      }

      switch (msg.method) {
        case 'TEXT':
          const {
            // @ts-expect-error [EN-7967] - TS2339 - Property 'data' does not exist on type 'Readonly<{ id: string; apiVersion: string; method: "TEXT"; data: { text: string; substitutedText: string; kind: Kind; markers: { audioStart: number; audioLength: number; text: string; }[]; textDone: boolean; maxAlternates: number; }; }> | Readonly<...>'.
            data: { kind, textDone, text },
          } = msg;

          if (kind === 'STABLETEXT') {
            if (textDone) {
              // final stable text
              performance.mark(marks.nvoq.Dictation.StableTextReceived, id);
              performance.measure(
                measures.nvoq.Dictation.TimeToLastStableText.name,
                createMarkForId(measures.nvoq.Dictation.TimeToLastStableText.startMark, id),
                createMarkForId(measures.nvoq.Dictation.TimeToLastStableText.endMark, id)
              );
            } else {
              // checking first stable text
              if (
                !performance.hasEntry(createMarkForId(marks.nvoq.Dictation.StableTextReceived, id))
              ) {
                performance.mark(createMarkForId(marks.nvoq.Dictation.StableTextReceived, id));
                performance.measure(
                  measures.nvoq.Dictation.TimeToFirstStableText.name,
                  createMarkForId(measures.nvoq.Dictation.TimeToFirstStableText.startMark, id),
                  createMarkForId(measures.nvoq.Dictation.TimeToFirstStableText.endMark, id)
                );
              }
              // checking first non-empty stable text
              if (
                !performance.hasEntry(
                  createMarkForId(marks.nvoq.Dictation.FirstNonEmptyStableText, id)
                )
              ) {
                const count = wordCount(text);
                if (count > 0) {
                  performance.mark(
                    createMarkForId(marks.nvoq.Dictation.FirstNonEmptyStableText, id)
                  );
                  performance.measure(
                    measures.nvoq.Dictation.HypothesisStableGap.name,
                    createMarkForId(measures.nvoq.Dictation.HypothesisStableGap.startMark, id),
                    createMarkForId(measures.nvoq.Dictation.HypothesisStableGap.endMark, id)
                  );
                }
              }
            }
            logger.info(
              // @ts-expect-error [EN-7967] - TS2339 - Property 'data' does not exist on type 'Readonly<{ id: string; apiVersion: string; method: "TEXT"; data: { text: string; substitutedText: string; kind: Kind; markers: { audioStart: number; audioLength: number; text: string; }[]; textDone: boolean; maxAlternates: number; }; }> | Readonly<...>'.
              `[createNvoqWebsocketFactory] StableText: "${msg.data.text}". StableText from nVoq received - externalId: ${externalId}`,
              {
                logId: stringifyRange(selection),
                externalId,
                // @ts-expect-error [EN-7967] - TS2339 - Property 'data' does not exist on type 'Readonly<{ id: string; apiVersion: string; method: "TEXT"; data: { text: string; substitutedText: string; kind: Kind; markers: { audioStart: number; audioLength: number; text: string; }[]; textDone: boolean; maxAlternates: number; }; }> | Readonly<...>'.
                text: msg.data.text,
              }
            );
            emitter.emit('on_stable_text', { msg, selection });
          } else if (kind === 'HYPOTHESISTEXT') {
            // checking first non-empty hypothesis text
            if (
              !performance.hasEntry(
                createMarkForId(marks.nvoq.Dictation.FirstNonEmptyHypothesisText, id)
              )
            ) {
              const count = wordCount(text);
              if (count > 0) {
                performance.mark(
                  createMarkForId(marks.nvoq.Dictation.FirstNonEmptyHypothesisText, id)
                );
              }
            }
            emitter.emit('on_hypothesis_text', { msg, selection });
          }
          break;

        default:
          break;
      }
    };
    socket.onclose = (e: CloseEvent) => {
      emitter.emit('on_close', { id, selection });
      performance.mark(marks.nvoq.WS.Close, id);
      performance.measure(
        measures.nvoq.WS.TimeConnected.name,
        createMarkForId(measures.nvoq.WS.TimeConnected.startMark, id),
        createMarkForId(measures.nvoq.WS.TimeConnected.endMark, id)
      );
      logger.info(
        `[createNvoqWebsocketFactory] Websocket to nVoq closed - externalId: ${externalId}`,
        {
          logId: stringifyRange(selection),
          externalId,
          selection,
        }
      );
      performance.clearMarksByName([
        createMarkForId(marks.nvoq.WS.Create, id),
        createMarkForId(marks.nvoq.WS.Open, id),
        createMarkForId(marks.nvoq.Dictation.StartDictation, id),
        createMarkForId(marks.nvoq.Dictation.StableTextReceived, id),
        createMarkForId(marks.nvoq.Dictation.FirstNonEmptyHypothesisText, id),
        createMarkForId(marks.nvoq.Dictation.FirstNonEmptyStableText, id),
        createMarkForId(marks.nvoq.Dictation.DoneMessageSent, id),
        createMarkForId(marks.nvoq.WS.Close, id),
      ]);
    };
    socket.onerror = (e: any) => {
      emitter.emit('on_websocket_error');
      logger.error(
        `[createNvoqWebsocketFactory] Error from nVoq Websocket - externalId: ${externalId}`,
        {
          logId: stringifyRange(selection),
          externalId,
          selection,
        },
        e
      );
    };

    return socket;
  };

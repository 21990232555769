import { Range, Editor } from 'domains/reporter/RichTextEditor/core';
import { Transforms } from '../core';
import { getBlocksInRange } from './getBlocksInRange';

export const removeBlocksInRange = (editor: Editor, range?: Range) => {
  const selection = range ?? editor.selection;
  if (selection == null) {
    return;
  }

  const blocks = getBlocksInRange(editor, selection);

  // Delete the blocks from end to start to prevent path shifting during removals.
  for (let i = blocks.length - 1; i >= 0; i--) {
    const [, path] = blocks[i];
    Transforms.removeNodes(editor, { at: path });
  }
};

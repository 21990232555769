import { Point, Range, Path } from 'domains/reporter/RichTextEditor/core';
import { Editor, Span, Node } from '../core';
import type { NodeEntry } from '../core';

export type EditorFindOptions = Partial<
  Readonly<{
    at?: Range | Path | Point | Span | undefined;
    mode?: 'highest' | 'lowest' | 'all' | undefined;
    universal?: boolean | undefined;
    reverse?: boolean | undefined;
    voids?: boolean | undefined;
  }>
>;

/**
 * Iterate through all of the nodes in the editor and return the first match. If
 * no match is found, return undefined.
 */
export const find = <T = Node>(
  editor: Editor,
  match: (node: Node, path: Path) => boolean,
  options: EditorFindOptions = {}
): NodeEntry<T> | null | undefined => {
  // Slate throws when things aren't found so we wrap in a try catch and return undefined on throw.
  try {
    const { at = editor.selection, reverse = false, voids = false } = options;
    if (!at) {
      return;
    }

    let from;
    let to;
    if (Span.isSpan(at)) {
      from = at[0] as Path;
      to = at[1] as Path;
    } else {
      const first = Editor.path(editor, at, { edge: 'start' });
      const last = Editor.path(editor, at, { edge: 'end' });
      from = reverse ? last : first;
      to = reverse ? first : last;
    }
    const nodeEntries = Node.nodes(editor, {
      reverse,
      from,
      to,
      // @ts-expect-error [EN-7967] - TS2322 - Type '([n]: [any]) => boolean' is not assignable to type '(entry: NodeEntry<any>) => boolean'.
      pass: ([n]: [any]) => (voids ? false : Editor.isVoid(editor, n)),
    });
    let foundMatch: NodeEntry<T> | null | undefined = undefined;
    for (const [node, path] of nodeEntries) {
      if (match(node, path)) {
        foundMatch = [node, path];
        break;
      }
    }
    return foundMatch;
  } catch (error: any) {
    return undefined;
  }
};

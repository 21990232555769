import { rect } from 'shape-points';
import type { Vector3 as vec3 } from '@kitware/vtk.js/types';
import { useScale } from '../../modules/useScale';
import { Polygon } from '../../Primitives';
import { splitEvery } from 'ramda';
import { encodeId } from '../../utils/representationId';
import type { MouseHandlers } from '../../types';
import { CURSOR_HANDLE } from './cursors';
import { useMemo } from 'react';
import { useImagingContext } from '../../modules/imaging/ImagingContext';

type HandleProps = {
  id: string;
  point: vec3;
  size?: number;
  color: string;
  visible?: boolean;
  opacity?: number;
  ['data-testid']?: string;
} & MouseHandlers;

export const Handle = ({
  id,
  point,
  size = 14,
  color,
  visible = true,
  opacity,
  onMouseLeave,
  onMouseOver,
  onMouseDown,
  onMouseUp,
  onDragStart,
  onDrag,
  onDragEnd,
  'data-testid': dataTestid,
}: HandleProps): React.ReactElement => {
  const { imagingProvider } = useImagingContext();
  // Scale is used in index space here, so compensate for voxel-spacing.
  const scale = useScale(true);
  const scaledSize = useMemo(() => [scale[0] * size, scale[1] * size], [scale, size]);

  const pointIndex = useMemo(
    () => imagingProvider?.worldToIndex(point) ?? [0, 0, 0],
    [imagingProvider, point]
  );

  const segments = useMemo(
    () =>
      splitEvery(2, rect(pointIndex[0], pointIndex[1], scaledSize[0], scaledSize[1]))
        .map(
          (vec, index) =>
            // @ts-expect-error [EN-7967] - TS2322 - Type 'unknown' is not assignable to type 'number'. | TS2322 - Type 'unknown' is not assignable to type 'number'.
            imagingProvider?.indexToWorld([vec[0], vec[1], pointIndex[2]]) ?? [0, 0, 0]
        )
        .map((vec3, index, points) => [vec3, points[(index + 1) % points.length]]),
    [pointIndex, scaledSize, imagingProvider]
  );
  return (
    <Polygon
      id={encodeId({ type: 'handle' }, id)}
      data-testid={dataTestid}
      // @ts-expect-error [EN-7967] - TS2322 - Type 'number[][][]' is not assignable to type 'readonly Segment[]'.
      segments={segments}
      fill="polys"
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      color={color}
      opacity={opacity}
      onDragStart={onDragStart}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      cursor={CURSOR_HANDLE}
      visible={visible}
    />
  );
};

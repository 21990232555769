import { atom, DefaultValue, selectorFamily } from 'recoil';
import type { RecoilState } from 'recoil';
import {
  broadcastChannelSynchronizerEffect,
  localForagePersisterEffect,
} from 'utils/recoilEffects';
import type { HangingProtocol } from './useHangingProtocol';
import { omit } from 'ramda';
import { env } from 'config/env';

export const previousHangingProtocolState: RecoilState<HangingProtocol | null | undefined> = atom({
  key: 'previousHangingProtocolState',
  default: null,
  effects: [
    broadcastChannelSynchronizerEffect(),
    localForagePersisterEffect({
      version: 1,
    }),
  ],
});

export const previewHangingProtocolState: RecoilState<{
  [key: string]: HangingProtocol | null | undefined;
}> = atom({
  key: 'previewHangingProtocolState',
  default: {},
  effects: [
    broadcastChannelSynchronizerEffect(),
    localForagePersisterEffect({
      version: 1,
      __LEGACY_MIGRATION_DO_NOT_USE__disabled: env.NODE_ENV === 'test',
    }),
  ],
});

export const previewHangingProtocolSelector: (
  arg1?: string | null | undefined
) => RecoilState<HangingProtocol | null | undefined> = selectorFamily<
  HangingProtocol | null | undefined,
  string | null | undefined
>({
  key: 'previewHangingProtocolSelector',
  get:
    (caseSmid?: string | null) =>
    ({ get }) => {
      const state = get(previewHangingProtocolState);
      return state != null && caseSmid != null ? state[caseSmid] : null;
    },
  set:
    (caseSmid?: string | null) =>
    ({ set, reset }, hangingProtocol: DefaultValue | null | undefined | HangingProtocol) => {
      if (caseSmid == null) return;

      if (hangingProtocol instanceof DefaultValue && caseSmid != null) {
        set(previewHangingProtocolState, (state) => omit([caseSmid], state));
        return;
      }

      // @ts-expect-error [EN-7967] - TS2345 - Argument of type '(state: { [key: string]: { readonly __typename?: "HangingProtocol"; readonly smid: string; readonly name: string; readonly numberOfPriors: number; readonly created: Date; readonly updated: Date; readonly studyDescriptions: readonly string[]; readonly studyCriteria?: JSON; readonly data: { ...; }; readonly viewportsC...' is not assignable to parameter of type 'DefaultValue | { [key: string]: { readonly __typename?: "HangingProtocol"; readonly smid: string; readonly name: string; readonly numberOfPriors: number; readonly created: Date; ... 4 more ...; readonly viewportsConfigurations?: readonly { ...; }[]; }; } | ((prevValue: { ...; }) => DefaultValue | { ...; })'.
      set(previewHangingProtocolState, (state) => ({
        ...state,
        [caseSmid]: hangingProtocol,
      }));
    },
});

export const isPreviewingHangingProtocolState: RecoilState<boolean> = atom({
  key: 'isPreviewingHangingProtocolState',
  default: false,
  effects: [broadcastChannelSynchronizerEffect()],
});

import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { Colors } from 'styles';
import { Ellipsis } from 'common/ui/Ellipsis';
import TableLoading from 'common/TableLoading';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import type {
  Column,
  Row,
  // @ts-expect-error [EN-7967] - TS2305 - Module '"@tanstack/react-table"' has no exported member 'TableHelpers'.
  TableHelpers,
  // @ts-expect-error [EN-7967] - TS2305 - Module '"@tanstack/react-table"' has no exported member 'DataUpdater'.
  DataUpdater,
  ColumnDef,
  HeaderGroup,
} from '@tanstack/react-table';
import styled, { css } from 'styled-components';
import TextWithTooltip from './TextWithTooltip';
import { NOOP } from 'config/constants';
import { TableOverlay } from './TableOverlay';

export type TableRowProps<D, RP, TP> = {
  // @ts-expect-error [EN-7967] - TS2314 - Generic type 'Row<TData>' requires 1 type argument(s).
  row: Row<D, RP, TP>;
  role: string;
  ['aria-label']: string;
  highlightRow: boolean;
  tableHelpers: TableHelpers<D, RP, TP>;
  minTableWidth: number;
  css?: string;
} & RP;

export type RenderRowProps<D, RP, TP> = {
  // @ts-expect-error [EN-7967] - TS2314 - Generic type 'Row<TData>' requires 1 type argument(s).
  row: Row<D, RP, TP>;
  bodyRowProps?: Partial<TableRowProps<D, RP, TP>>;
  cellProps?: any;
};

export const DefaultRow = <D extends any, RP extends any, TP extends any>({
  row,
  highlightRow,
  minTableWidth,
  ...props
}: TableRowProps<D, RP, TP>): React.ReactElement => (
  <div
    css={`
      display: flex;
      align-items: center;
      ${highlightRow
        ? `
      &:hover,
      &:focus {
        opacity: 1;
        background-color: ${Colors.gray3};
      }
    `
        : ''}
      min-width: ${minTableWidth}px;
      display: flex;
      flex: 1 0 auto;
      ${props.css ?? ''}
    `}
    role="row"
    {...props}
    selected={row?.getIsSelected()}
  />
);

export type TableBodyProps<D, RP, TP> = {
  hasData: boolean;
  hasNextPage: boolean;
  loading: boolean;
  renderRow: (arg1: RenderRowProps<D, RP, TP>) => React.ReactElement;
  rootRef: (node: Element | null) => void;
  // @ts-expect-error [EN-7967] - TS2314 - Generic type 'Row<TData>' requires 1 type argument(s).
  rows: Array<Row<D, RP, TP>>;
  sentryRef: React.Ref<React.ElementType>;
  cellProps?: any;
  onScroll?: () => void;
  dataTestId?: string;
  className?: string;
};

export const DefaultBody = <D extends any, RP extends any, TP extends any>({
  hasData,
  hasNextPage,
  loading,
  renderRow,
  rootRef,
  rows,
  cellProps = {},
  sentryRef,
  onScroll,
  dataTestId,
}: TableBodyProps<D, RP, TP>): React.ReactElement => (
  <div
    className="table-body"
    ref={rootRef}
    onScroll={onScroll}
    css="flex: 1; overflow: auto; position: relative;"
    data-testid={dataTestId}
  >
    {/* To confine the TableLoading overlay to the table body and prevent
          flashing over static parents, place it here as a child and set
          `relative` position on the table body. */}
    <TableLoading loading={loading} hasData={hasData} />
    {rows.map((row) => renderRow({ row, cellProps }))}
    {hasNextPage && (
      <div key="loading" data-testid="loading">
        Loading...
      </div>
    )}
    {/* Avoid the sentry prematurely entering the root while the table is still loading.
     * Intersection Observer only fires on changes in intersection threshold, so if it is
     * still within the root + margin, loadMore won't fire.
     * This system is still not perfect; see RP-2176
     */}
    {/* @ts-expect-error [EN-7967] - TS2322 - Type 'Ref<ElementType<any, keyof IntrinsicElements>>' is not assignable to type 'LegacyRef<HTMLDivElement>'. */}
    {hasNextPage && !loading && <div ref={sentryRef} />}
  </div>
);

const DEFAULT_COLUMN_PROPS_FOR_ALL_TABLES = {
  minSize: 0,
} as const;

const NO_DEFAULT_COLUMN_PROPS_FOR_THIS_TABLE: Record<string, any> = {};
const NO_TOOLTIPS: Array<number> = [];

type TableProps<D, RP, TP> = {
  // @ts-expect-error [EN-7967] - TS2707 - Generic type 'Column<TData, TValue>' requires between 1 and 2 type arguments.
  columns: ReadonlyArray<Column<D, RP, TP>>;
  data: ReadonlyArray<D>;
  className?: string;
  Row?: (arg1: TableRowProps<D, RP, TP>) => React.ReactElement;
  Body?: (arg1: TableBodyProps<D, RP, TP>) => React.ReactElement;
  highlightRow?: boolean;
  showHeaderSeparators?: boolean;
  onLoadMore?: () => unknown | null;
  hasNextPage?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
  colsWithTooltip?: Array<number>;
  loading?: boolean;
  dataUpdater?: DataUpdater<D, RP, TP>;
  tableRef?: {
    current: TableHelpers<D, RP, TP> | null;
  };
  css?: string;
  rowProps?: RP;
  ['data-testid']?: string;
  // @ts-expect-error [EN-7967] - TS2707 - Generic type 'ColumnDef' requires between 1 and 2 type arguments.
  defaultColumn?: ColumnDef<D, RP, TP>;
  cellProps?: any;
  headerCss?: string;
  rowSelection?: {
    [key: string]: boolean;
  };
  setRowSelection?: (arg1: { [key: string]: boolean }) => void;
  enableMultiRowSelection?: boolean;
} & TP;

/**
 * WARNING- make sure EVERYTHING going into the table is properly memoized.
 * That includes default properties/empty objects/empty arrays.
 * Failure to do so may re-memoize Body/Row definitions and cause internal
 * cells to lose their identity (breaking focused input cells, for example.)
 */
const Table = <
  D extends any,
  RP extends {
    isSelected: boolean;
  },
  TP extends any,
>({
  columns,
  data,
  className,
  Row = DefaultRow,
  Body = DefaultBody,
  highlightRow = false,
  showHeaderSeparators = false,
  onLoadMore = NOOP,
  hasNextPage = false,
  disabled = false,
  disabledMessage = '',
  loading = false,
  colsWithTooltip = NO_TOOLTIPS,

  // Custom function that we supplied to our table instance,
  // so we can call it from our cell renderer (helps prevent editable table fields from losing inputFocus from rerendering)
  // https://codesandbox.io/s/github/tannerlinsley/react-table/tree/v7/examples/editable-data
  dataUpdater = NOOP,

  // $FlowFixMe[incompatible-type]
  // @ts-expect-error [EN-7967] - TS2322 - Type '{}' is not assignable to type 'RP'.
  rowProps = {},

  'data-testid': dataTestId,
  tableRef = { current: null },
  css,
  cellProps,
  defaultColumn = NO_DEFAULT_COLUMN_PROPS_FOR_THIS_TABLE,
  headerCss = '',
  setRowSelection = NOOP,
  rowSelection = {},
  enableMultiRowSelection = false,
  ...reactTableProps
}: TableProps<D, RP, TP>): React.ReactElement => {
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    onLoadMore,
    loading,
    hasNextPage,
    rootMargin: '0px 0px 600px 0px',
  });

  const defaultColumnWithExtraDefaults = useMemo(
    () => ({ ...DEFAULT_COLUMN_PROPS_FOR_ALL_TABLES, defaultColumn }),
    [defaultColumn]
  );

  // Use the state and functions returned from useReactTable to build your UI
  const tableHelpers = useReactTable({
    // @ts-expect-error [EN-7967] - TS2322 - Type 'readonly any[] & TP["columns"]' is not assignable to type 'ColumnDef<D, any>[]'.
    columns,
    // @ts-expect-error [EN-7967] - TS2322 - Type 'readonly D[] & TP["data"]' is not assignable to type 'D[]'.
    data,
    dataUpdater,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    defaultColumn: defaultColumnWithExtraDefaults,
    enableMultiRowSelection,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    ...reactTableProps,
  });

  const { getHeaderGroups, getRowModel } = tableHelpers;

  const headerGroups = getHeaderGroups();

  const minTableWidth = getMinTableWidth(headerGroups);

  const rows = getRowModel().rows;

  const scrollableRootRef = useRef<Element | null>(null);
  const lastScrollDistanceToTopRef = useRef<number | undefined>();

  const rootRefSetter = useCallback(
    (node: Element | null) => {
      // @ts-expect-error [EN-7967] - TS2349 - This expression is not callable.
      rootRef(node);
      scrollableRootRef.current = node;
    },
    [rootRef]
  );

  // capture the current scrollTop position within the table list
  const handleRootScroll = useCallback(() => {
    const rootNode = scrollableRootRef.current;
    if (rootNode) {
      lastScrollDistanceToTopRef.current = rootNode.scrollTop;
    }
  }, []);

  // keep the scroll position when new items are added
  useLayoutEffect(() => {
    const scrollableRoot = scrollableRootRef.current;
    if (scrollableRoot) {
      scrollableRoot.scrollTop = lastScrollDistanceToTopRef.current ?? 0;
    }
  }, [rows, rootRef]);

  useEffect(() => {
    tableRef.current = tableHelpers;
  }, [tableHelpers, tableRef]);

  const renderRow = useCallback(
    ({ row, bodyRowProps, cellProps = {} }: RenderRowProps<D, RP, TP>) => {
      const cells = row.getAllCells();
      return (
        <Row
          isSelected={row.getIsSelected()}
          key={row.id}
          row={row}
          role="row"
          aria-label={'row-' + row.index}
          highlightRow={highlightRow && !row.getIsSelected()}
          tableHelpers={tableHelpers}
          minTableWidth={minTableWidth}
          {...rowProps}
          {...bodyRowProps}
          className={`table-row${row.getIsSelected() ? '-selected' : ''}`}
        >
          {cells.map((cell, index) => {
            const withTooltip = colsWithTooltip.includes(index);
            const size = cell.column.getSize();
            return withTooltip ? (
              <TextWithTooltip
                customEllipsisCss={{
                  flex: `${size} 0 auto`,
                  minWidth: `${cell.column.columnDef.minSize ?? 0}px`,
                  width: `${size}px`,
                  boxSizing: `border-box`,
                }}
                // @ts-ignore [incompatible-type]
                name={flexRender(cell.column.columnDef.cell, {
                  ...cell.getContext(),
                  ...cellProps,
                })}
              />
            ) : (
              <Ellipsis
                key={cell.id}
                css={`
                  flex: ${size} 0 auto;
                  ${cell.column.columnDef.minSize != null
                    ? `min-width: ${cell.column.columnDef.minSize ?? 0}px;`
                    : ''}
                  width: ${size}px;
                  box-sizing: border-box;
                `}
                role="cell"
              >
                {flexRender(cell.column.columnDef.cell, { ...cell.getContext(), ...cellProps })}
              </Ellipsis>
            );
          })}
        </Row>
      );
    },
    [Row, highlightRow, tableHelpers, minTableWidth, rowProps, colsWithTooltip]
  );

  // Render the UI for your table
  return (
    <div
      role="table"
      css={`
        flex: 1;
        min-height: 0;
        display: flex;
        flex-direction: column;
        position: relative;
        ${css ?? ''}
      `}
      className={className}
      data-testid={dataTestId}
    >
      {disabled && <TableOverlay message={disabledMessage} />}
      <div role="rowgroup" className="table-header">
        {headerGroups.map((headerGroup) => (
          <div
            key={headerGroup.id}
            role="row"
            css={`
              min-width: ${minTableWidth}px;
              display: flex;
              flex: 1 0 auto;
            `}
          >
            {headerGroup.headers.map((header, index) => {
              const size = header.getSize();
              return (
                <Fragment key={header.id}>
                  <div
                    css={`
                      font-weight: 500;
                      padding: 5px;
                      color: ${Colors.gray8};
                      box-sizing: border-box;
                      flex: ${size} 0 auto;
                      min-width: ${header.column.columnDef.minSize}px;
                      width: ${size}px;
                      ${headerCss}
                    `}
                    role="columnheader"
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                  {showHeaderSeparators && index < headerGroup.headers.length - 1 && (
                    <div
                      css={`
                        position: relative;
                        width: 1px;
                        height: 18px;
                        top: 6px;
                        right: 8px;
                        background-color: ${Colors.gray10};
                      `}
                    ></div>
                  )}
                </Fragment>
              );
            })}
          </div>
        ))}
      </div>
      <Body
        className="table-body"
        hasData={data.length > 0}
        hasNextPage={hasNextPage}
        loading={loading}
        renderRow={renderRow}
        rootRef={rootRefSetter}
        onScroll={handleRootScroll}
        rows={rows}
        cellProps={cellProps}
        sentryRef={sentryRef}
        dataTestId={`${dataTestId ?? 'table'}-body`}
      />
    </div>
  );
};

type SelectableRowProps = TableRowProps<
  unknown,
  Record<any, any>,
  {
    toggleAllRowsSelected: (arg1: boolean) => void;
  }
>;

export const StyledRow = styled(DefaultRow).attrs((props: SelectableRowProps) => ({
  ...props,
  onClick: props.row.getToggleSelectedHandler(),
  'aria-selected': props.row.getIsSelected() ? 'true' : 'false',
  'min-width': `${props.minTableWidth}px`,
  display: 'flex',
  flex: '1 0 auto',
}));

// @ts-expect-error [EN-7967] - TS2322 - Type 'StyledComponent<(<D extends unknown, RP extends unknown, TP extends unknown>({ row, highlightRow, minTableWidth, ...props }: TableRowProps<D, RP, TP>) => ReactElement<any, string | JSXElementConstructor<any>>), any, { onClick: any; ... 10 more ...; css?: string; }, "role" | ... 10 more ... | "min-width">' is not assignable to type '() => ReactElement<any, string | JSXElementConstructor<any>>'.
export const SelectableRow: () => // $FlowFixMe[incompatible-type] a bit too much to figure out
React.ReactElement = StyledRow`
  background-color: ${(props: SelectableRowProps) =>
    props.row.getIsSelected() ? Colors.gray5 : 'transparent'};
  cursor: pointer;
  ${(props: SelectableRowProps) =>
    props.highlightRow &&
    !props.row.getIsSelected() &&
    css`
      &:hover,
      &:focus {
        opacity: 1;
        background-color: ${Colors.gray3};
      }
    `}
`;

export function getMinTableWidth(
  // @ts-expect-error [EN-7967] - TS2314 - Generic type 'HeaderGroup<TData>' requires 1 type argument(s).
  headerGroups: Array<HeaderGroup<unknown, unknown, unknown>>
): number {
  const deepestHeaderLevel = headerGroups.length - 1;
  return headerGroups[deepestHeaderLevel].headers.reduce(
    (groupWidth, header) => groupWidth + (header.column.columnDef.minSize ?? 0),
    0
  );
}

export default Table;

import type { Scalars } from 'generated/graphql';
import { WorklistColumnId } from 'generated/graphql';

export const WorklistGlobalFilters = Object.freeze({
  PatientSearch: 'patientSearch',
  Views: 'views',
});

export type WorklistGlobalFiltersType =
  (typeof WorklistGlobalFilters)[keyof typeof WorklistGlobalFilters];

export const WorklistFilters = Object.freeze({
  PatientSearch: WorklistGlobalFilters.PatientSearch,
  Views: WorklistGlobalFilters.Views,
  PatientSex: WorklistColumnId.PatientSex,
  StudyDate: WorklistColumnId.StudyDate,
  PatientDob: WorklistColumnId.PatientDob,
  Modality: WorklistColumnId.Modality,
  HasDraftReport: WorklistColumnId.HasDraftReport,
  CustomStatus: WorklistColumnId.CustomStatus,
  SubmittedAt: WorklistColumnId.SubmittedAt,
  AccessionNumber: WorklistColumnId.AccessionNumber,
  Mrn: WorklistColumnId.Mrn,
  Status: WorklistColumnId.Status,
  Age: WorklistColumnId.Age,
  PatientType: WorklistColumnId.PatientType,
  StudyDescription: WorklistColumnId.StudyDescription,
  Site: WorklistColumnId.Site,
  Radiologist: WorklistColumnId.Radiologist,
  BodyPart: WorklistColumnId.BodyPart,
  PatientName: WorklistColumnId.PatientName,
  ReferringPhysician: WorklistColumnId.ReferringPhysician,
  Priority: WorklistColumnId.Priority,
});

export const WorklistFilterSourceName = {
  Modality: 'modality',
  PatientType: 'patient_type',
  PatientSex: 'patient_sex',
  StudyDescription: 'study_description',
  BodyPart: 'body_part',
  Radiologist: 'claimed_by',
  CustomStatus: 'custom_status',
  Status: 'status',
  Site: 'site',
  HasDraftReport: 'has_draft_report',
  PatientName: 'patient_last_name',
  ReferringPhysician: 'referring_physician',
  Priority: 'priority',
} as const;

export const WorklistFilterSourceToName = {
  modality: 'Modality',
  patient_type: 'Patient Type',
  patient_sex: 'Patient Sex',
  study_description: 'Study Description',
  exam_code: 'Exam Code',
  body_part: 'Body Part',
  claimed_by: 'Radiologist',
  custom_status: 'Workflow Status',
  status: 'Status',
  site: 'Site',
  has_draft_report: 'Draft',
  patient_last_name: 'Patient Name',
  referring_physician: 'Ordering Physician',
  priority_code: 'Priority',
  submitted_at: 'Submitted At',
  submittedAt: 'Submitted At',
} as const;

export type WorklistFilterSourceNameType =
  (typeof WorklistFilterSourceName)[keyof typeof WorklistFilterSourceName];

export type WorklistFiltersType = (typeof WorklistFilters)[keyof typeof WorklistFilters];

export const FilterVariant = Object.freeze({
  MultiSelect: 'multiselect',
  DateRange: 'daterange',
  Toggle: 'toggle',
  NumberRange: 'numberrange',
  Text: 'text',
});

export type FilterVariantType = (typeof FilterVariant)[keyof typeof FilterVariant];

export type BaseFilterType = {
  label: string;
  hidden?: boolean;
  isLoading?: boolean;
};

export type MultiSelectFilterItemType = {
  value: string;
  label: string;
  count?: number;
};

export type ToggleFilterType = BaseFilterType & {
  type: typeof FilterVariant.Toggle;
  defaultValue: boolean;
};

export type MultiSelectFilterType = BaseFilterType & {
  type: typeof FilterVariant.MultiSelect;
  isSearchable?: boolean;
  source: WorklistFilterSourceNameType;
  items?: MultiSelectFilterItemType[];
  isBoolean?: boolean; // Must only have 1 selected, if not, it's like no filter is added};,
  isLoading?: boolean;
  formatItems?: (items: MultiSelectFilterItemType[]) => MultiSelectFilterItemType[];
};

export type DateRangeFilterType = BaseFilterType & {
  type: typeof FilterVariant.DateRange;
  minDate?: Date;
  maxDate?: Date;
};

export type NumberRangeFilterType = BaseFilterType & {
  type: typeof FilterVariant.NumberRange;
};

export type TextFilterType = BaseFilterType & {
  type: typeof FilterVariant.Text;
};

export type FilterType =
  | MultiSelectFilterType
  | DateRangeFilterType
  | ToggleFilterType
  | NumberRangeFilterType
  | TextFilterType;

export type FilterValueType = string[];

export type FilterStateType = Partial<Record<WorklistFiltersType, FilterValueType>>;

export type FilterDefinitionType = Partial<Record<WorklistFiltersType, FilterType>>;

export type ProcessedFilterValueType =
  | [Date | null | undefined, Date | null | undefined]
  | boolean
  | null
  | undefined
  | ReadonlyArray<string>
  | string
  | null
  | undefined
  | [number | null | undefined, number | null | undefined];

export type ProcessedDateFilterValueType = [Date | null | undefined, Date | null | undefined];

export type ProcessedBooleanFilterValueType = boolean | null | undefined;

export type ProcessedMultiSelectFilterValueType = ReadonlyArray<string>;

export type ProcessedNumberRangeFilterValueType = [
  number | null | undefined,
  number | null | undefined,
];

export type ProcessedTextFilterValueType = ReadonlyArray<Scalars['String']>;

export type ProcessedFilterStateType = {
  patientSex?: ProcessedMultiSelectFilterValueType | null | undefined;
  patientDob?: ProcessedDateFilterValueType | null | undefined;
  studyDate?: ProcessedDateFilterValueType | null | undefined;
  submittedAt?: ProcessedDateFilterValueType | null | undefined;
  modality?: ProcessedMultiSelectFilterValueType | null | undefined;
  views?: ProcessedMultiSelectFilterValueType | null | undefined;
  hasDraftReport?: ProcessedBooleanFilterValueType | null | undefined;
  customStatus?: ProcessedMultiSelectFilterValueType | null | undefined;
  patientSearch?: ProcessedBooleanFilterValueType | null | undefined;
  bodyPart?: ProcessedMultiSelectFilterValueType | null | undefined;
  mrn?: ProcessedTextFilterValueType | null | undefined;
  status?: ProcessedMultiSelectFilterValueType | null | undefined;
  age?: ProcessedNumberRangeFilterValueType | null | undefined;
  accessionNumber?: ProcessedTextFilterValueType | null | undefined;
  patientType?: ProcessedMultiSelectFilterValueType | null | undefined;
  studyDescription?: ProcessedMultiSelectFilterValueType | null | undefined;
  site?: ProcessedMultiSelectFilterValueType | null | undefined;
  patientName?: ProcessedTextFilterValueType | null | undefined;
  radiologist?: ProcessedMultiSelectFilterValueType | null | undefined;
  referringPhysician?: ProcessedMultiSelectFilterValueType | null | undefined;
  priority?: ProcessedMultiSelectFilterValueType | null | undefined;
};

export type WorklistSurfaceSearchValues = {
  [key: string]: string;
};

export type FilterStateBySurface = {
  [key: string]: FilterStateType;
};

export type NumberRangeTupleString = [string | null | undefined, string | null | undefined];
export type NumberRangeTuple = [number | null | undefined, number | null | undefined];

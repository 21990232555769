import { Operation, Editor } from 'domains/reporter/RichTextEditor/core';
import { REPORTER_DEBUG } from 'config';
import type { AllPluginID } from '../plugins/types';

// Since we don't have a logger for the client, use these for the editor code to keep it consistent
// and to conditional log based on an environmental variable

export const createEditorWarning = (...args: string[]): void =>
  console.warn('SIRONA WARNING: ', ...args);

export const createEditorError = (...args: string[]): void =>
  console.warn('SIRONA ERROR: ', ...args);

export const createEditorLog = (...args: string[]): void => {
  if (REPORTER_DEBUG === 'false') return;

  // eslint-disable-next-line no-console
  console.log(
    'SIRONA LOG: ',
    ...args.map((arg) => `\n\n${typeof arg === 'string' ? arg : JSON.stringify(arg, null, 2)}`)
  );
};

export const createEditorDebugLog = (message: string, editor: Editor, ...args: string[]): void => {
  if (REPORTER_DEBUG === 'false') return;

  // eslint-disable-next-line no-console
  console.log(
    '---EDITOR DEBUG START---\n\n',
    'Message:',
    message,
    '\n\n',
    'Editor state:',
    JSON.stringify(editor.children, null, 2),
    '\n\nEditor selection',
    JSON.stringify(editor.selection, null, 2),
    ...args.map((arg) => `\n\n${JSON.stringify(arg, null, 2)}`),
    '\n\n---EDITOR DEBUG END---'
  );
};

export const createEditorApplyOverrideNotification = (
  pluginID: AllPluginID,
  operation: Operation
) => {
  if (REPORTER_DEBUG === 'false') return;

  // eslint-disable-next-line no-console
  console.info(
    'SIRONA REPORTER:',
    `The ${pluginID} plugin augmented the editor.apply function. This can cause tough to solve bugs. Operation: ${JSON.stringify(
      operation,
      null,
      2
    )}`
  );
};

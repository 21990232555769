import { Component, Fragment } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@material-ui/core';
import analytics from 'modules/analytics';
import { env } from 'config/env';
import localforage from 'localforage';

type ErrorBoundaryProps = {
  name: string;
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  isDialogOpen: boolean;
  error: Error | null | undefined;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    isDialogOpen: false,
    error: null,
  };

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return {
      isDialogOpen: true,
      error: error,
    };
  }

  componentDidCatch(
    error: Error,
    errorInfo: {
      componentStack: string;
    }
  ) {
    analytics.error(error, {
      componentStack: errorInfo.componentStack,
      boundaryName: this.props.name,
    });

    // If a React error reaches this boundary, let's clear the
    // persistent Apollo cache so that we can start fresh when
    // the user refreshes the page.
    localforage.clear();
  }

  handleClose: () => void = () => {
    this.setState({
      isDialogOpen: false,
      error: null,
    });
  };

  render(): React.ReactElement {
    const { isDialogOpen, error } = this.state;
    if (isDialogOpen) {
      return (
        <>
          <Dialog open={isDialogOpen}>
            <DialogTitle>An Error Occurred</DialogTitle>
            <DialogContent>
              <DialogContentText>{error?.message}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }

    // @ts-expect-error [EN-7967] - TS2322 - Type 'ReactNode' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
    return this.props.children;
  }
}

const ConditionalErrorBoundary: React.ComponentType<ErrorBoundaryProps> = ({ name, children }) =>
  env.NODE_ENV === 'development' ? children : <ErrorBoundary name={name}>{children}</ErrorBoundary>;

export default ConditionalErrorBoundary;

import type { PicklistPluginElement } from '../types';
import { InlineTemplateElement } from '../../common/inlineTemplate';
import { PICKLIST_DELIMITER_LEFT, PICKLIST_DELIMITER_RIGHT } from '../constants';
import { forwardRef } from 'react';

type PicklistElementProps = Readonly<{
  selected?: boolean;
  element: PicklistPluginElement;
  children: React.ReactNode;
  handleClick?: () => void;
  ['data-editor-element']?: string;
  ['data-slate-node']?: 'element';
  ['data-slate-inline']?: true;
  ['data-slate-void']?: true;
  dir?: 'rtl';
  leftDelimiter?: string;
  rightDelimiter?: string;
  variant?: string;
}>;

export const PicklistElement = forwardRef<HTMLSpanElement, PicklistElementProps>(
  (
    {
      selected,
      element,
      handleClick,
      leftDelimiter = PICKLIST_DELIMITER_LEFT,
      rightDelimiter = PICKLIST_DELIMITER_RIGHT,
      variant,
      ...rest
    },
    ref
  ) => {
    return (
      <InlineTemplateElement
        ref={ref}
        data-selected={selected}
        {...rest}
        selected={selected}
        handleClick={handleClick}
        element={element}
        testID="picklist"
        leftDelimiter={leftDelimiter}
        rightDelimiter={rightDelimiter}
        variant={variant}
      />
    );
  }
);

PicklistElement.displayName = 'PicklistElement';

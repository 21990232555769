import { useMemo } from 'react';
import { useOpenTabs } from 'hooks/useOpenTabs';
import { useViewerId } from 'hooks/useViewerId';
import { PAGE_TYPES } from 'utils/pageTypes';

type UseViewerMetadata = {
  numberOfViewerWindows: number;
  windowId: string | null | undefined;
  openWindowsIds: ReadonlyArray<string>;
};

export const useViewerMetadata = (): UseViewerMetadata => {
  const windowId = useViewerId();
  const tabs = useOpenTabs();
  const numberOfViewerWindows = tabs.filter((tab) => tab.type === PAGE_TYPES.VIEWER).length;
  const openWindowsIds: Array<string> = useMemo(
    () =>
      tabs
        .filter((tab) => tab.type === PAGE_TYPES.VIEWER)
        .reduce<
          Array<any>
        >((acc, { windowId }) => (windowId != null ? [...acc, windowId] : acc), []),
    [tabs]
  );

  return { numberOfViewerWindows, windowId, openWindowsIds };
};

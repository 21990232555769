import type { CreateEnhanceProvider } from '../../types/plugin';
import type { ProviderComponentProps } from './hooks/useCurrentList';
import type { ListPluginPropertyOptions } from './types';
import type { SlateProps } from 'slate-react';

import { ListProvider } from './hooks/useCurrentList';

export const ProviderComponentWithList = ({
  children,
}: ProviderComponentProps): React.ReactElement => {
  return <ListProvider>{children}</ListProvider>;
};

export const enhanceProviderList: CreateEnhanceProvider<ListPluginPropertyOptions> =
  () => (Component: React.ComponentType<SlateProps>) =>
    function EnhanceProviderList(props: SlateProps) {
      return (
        <ProviderComponentWithList>
          <Component {...props} />
        </ProviderComponentWithList>
      );
    };

import type { EditorElement, EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';

import type { ComponentType } from 'react';

export const HEADING_ERROR_PLUGIN_ID: 'headingError' = 'headingError';
export type HeadingErrorPluginID = typeof HEADING_ERROR_PLUGIN_ID;

export type HeadingErrorPluginElement = Readonly<
  EditorElement & {
    type: HeadingErrorPluginID;
  }
>;

export type HeadingErrorPluginOptions = Readonly<
  EditorPluginOptions & {
    // $FlowFixMe[unclear-type]
    component?: ComponentType<any>;
  }
>;

export type HeadingErrorPluginPropertyOptions = Readonly<
  EditorPluginPropertyOptions &
    HeadingErrorPluginOptions & {
      pluginID: HeadingErrorPluginID;
      // $FlowFixMe[unclear-type]
      component: ComponentType<any>;
      pluginName: string;
    }
>;

import type { GetWorklistQueryVariables } from 'generated/graphql';
import type { ProcessedFilterStateType } from '../../WorklistFilters/types';
import type { WorklistSurfaceType } from '../types';

import {
  ARCHIVE,
  IN_PROGRESS,
  MY_QUEUE,
  PENDING,
  RECENTLY_READ,
  STUDIES_DIALOG,
  WORKLIST_VIEW,
  QC,
  PATIENT_JACKET_PRIORS,
} from 'config/constants';
import { useWorklistFiltersContext } from '../../WorklistFilters/WorklistFiltersContext';
import { ageRangeToDOBRange } from '../../WorklistFilters/utils';

import { useMemo } from 'react';

export const WORKLIST_QUERY_LIMIT = 50;

const STATUS_MAP = {
  [MY_QUEUE]: IN_PROGRESS,
  [IN_PROGRESS]: IN_PROGRESS,
  [PENDING]: PENDING,
  [RECENTLY_READ]: ARCHIVE,
  [ARCHIVE]: ARCHIVE,
  [WORKLIST_VIEW]: null,
  [STUDIES_DIALOG]: null,
  [QC]: QC,
  [PATIENT_JACKET_PRIORS]: null,
  PLACEHOLDER: null,
  GLOBAL_SEARCH: null,
} as const;

export const useWorklistVariables = (
  userId: string | null | undefined,
  search: string,
  surface: WorklistSurfaceType,
  processedFilters: ProcessedFilterStateType
): GetWorklistQueryVariables => {
  const { sortColumns, sortOrders } = useWorklistFiltersContext();

  const selectedStatus = processedFilters.status;

  const isActiveVariable =
    surface !== IN_PROGRESS || selectedStatus == null || selectedStatus.length !== 1
      ? undefined
      : selectedStatus[0] === 'Locked';

  const processedAgeData = useMemo(
    () => (processedFilters.age != null ? ageRangeToDOBRange(processedFilters.age) : null),
    [processedFilters.age]
  );

  // @ts-expect-error [EN-7967] - TS2322 - Type '{ limit: number; claimedBy: string; sortOrder: SortOrder[]; sortColumn: SortColumn[]; status: any; filter: string; patientSex: ProcessedMultiSelectFilterValueType; ... 17 more ...; priorityFilter: ProcessedMultiSelectFilterValueType; }' is not assignable to type 'Exact<{ cursor?: string; limit?: number; sortOrder?: SortOrder | readonly SortOrder[]; sortColumn?: SortColumn | readonly SortColumn[]; claimedBy?: string; site?: string | readonly string[]; ... 23 more ...; priorityFilter?: string | readonly string[]; }>'.
  return useMemo(
    () => ({
      limit: WORKLIST_QUERY_LIMIT,
      claimedBy: surface === MY_QUEUE ? userId : null,
      sortOrder: sortOrders,
      sortColumn: sortColumns,
      status: STATUS_MAP[surface],
      filter: search,
      patientSex: processedFilters.patientSex,
      patientDob: processedFilters.patientDob ?? processedAgeData ?? undefined,
      studyDate: processedFilters.studyDate,
      submittedAt: processedFilters.submittedAt,
      modality: processedFilters.modality,
      priority: surface === PENDING ? ['-priority'] : [],
      activeViews: processedFilters.views,
      hasDraftReport: processedFilters.hasDraftReport,
      customStatus: processedFilters.customStatus,
      accessionNumberPartial: processedFilters.accessionNumber,
      mrnPartial: processedFilters.mrn,
      isActive: isActiveVariable,
      patientType: processedFilters.patientType,
      studyDescription: processedFilters.studyDescription,
      site: processedFilters.site,
      bodyPart: processedFilters.bodyPart,
      patientName: processedFilters.patientName,
      referringPhysician: processedFilters.referringPhysician,
      priorityFilter: processedFilters.priority,
    }),
    [
      isActiveVariable,
      processedAgeData,
      processedFilters.accessionNumber,
      processedFilters.bodyPart,
      processedFilters.customStatus,
      processedFilters.hasDraftReport,
      processedFilters.modality,
      processedFilters.mrn,
      processedFilters.patientDob,
      processedFilters.patientName,
      processedFilters.patientSex,
      processedFilters.patientType,
      processedFilters.site,
      processedFilters.studyDate,
      processedFilters.studyDescription,
      processedFilters.submittedAt,
      processedFilters.views,
      processedFilters.referringPhysician,
      processedFilters.priority,
      search,
      sortColumns,
      sortOrders,
      surface,
      userId,
    ]
  );
};

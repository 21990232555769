import { createContext, useContext, useReducer, useCallback, useMemo, useEffect } from 'react';
import { unreachableCaseError } from 'types';
import { AnimatePresence } from 'framer-motion';
import { Toast as ToastComponent } from './Toast';
import type { ToastProps, ToastKey } from './Toast';
import { nanoid } from 'nanoid';
import { ToastContainer } from './ToastContainer';
import {
  pipe,
  when,
  gt,
  reject,
  head,
  propEq,
  length,
  slice,
  append,
  groupBy,
  prop,
  toPairs,
  map,
} from 'ramda';
import { EMPTY } from 'config/constants';

export type Toast = ToastProps;

export type ToasterState = Readonly<{
  toasts: Toast[];
  queue: Toast[];
  maxNumberOfVisibleToasts: number;
}>;

export type ToasterDispatch = Readonly<{
  enqueueToast: (msg: React.ReactNode, options?: Readonly<Partial<Toast>>) => ToastKey;
  enqueueOrUpdateToast: (
    msg: React.ReactNode,
    toastKey?: ToastKey | null | undefined,
    options?: Readonly<Partial<Toast>>
  ) => ToastKey;
  closeToast: (toastKey: ToastKey) => void;
}>;

const ToasterStateContext = createContext<ToasterState | null | undefined>(undefined);
const ToasterDispatchContext = createContext<ToasterDispatch | null | undefined>(undefined);

export type ToasterProviderProps = Readonly<{
  children: React.ReactNode;
  /**
   * Number of toasts to show visible on the screen at one time.
   */
  maxNumberOfVisibleToasts?: number;
  /**
   * Runs the animations on mount of the toast containers. You want this on
   * unless you are running visual tests.
   */
  runAnimationsOnMount?: boolean;
  /**
   * Initial toasts to show in the app. Useful for snapshot testing.
   */
  initialToasts?: Toast[];
  /**
   * Initial items to place in the queue. Useful for testing.
   */
  initialQueue?: Toast[];
  /**
   * Default toast props that will be deeply merged with all toasts created via the hook.
   */
  defaultToastProps?: Readonly<Partial<Toast>>;
}>;

type ActionType =
  | Readonly<{
      type: 'enqueueToast';
      payload: {
        toast: Toast;
      };
    }>
  | Readonly<{
      type: 'closeToast';
      payload: {
        toastKey: string;
      };
    }>
  | Readonly<{
      type: 'updateMaxNumberOfVisibleToasts';
      payload: {
        maxNumberOfVisibleToasts: number;
      };
    }>
  | Readonly<{
      type: 'updateToast';
      payload: {
        toastKey: ToastKey;
        toast: Toast;
      };
    }>;

const toasterReducer = (state: ToasterState, action: ActionType): ToasterState => {
  switch (action.type) {
    case 'enqueueToast':
      /**
       * Toasts with `isUnique:true` only render once
       */
      const toastAlreadyExists =
        action.payload.toast.isUnique === true &&
        state.toasts.some((toast) => toast.message === action.payload.toast.message);

      if (toastAlreadyExists) {
        return state;
      }

      const toasts = [...state.toasts];
      const queue = [...state.queue];

      // Toasts with the same toastKey get updated
      const toastIdx = toasts.findIndex(
        (toast) => toast.toastKey === action.payload.toast.toastKey
      );
      if (toastIdx !== -1) {
        toasts.splice(toastIdx, 1, action.payload.toast);
      } else if (toasts.length < state.maxNumberOfVisibleToasts) {
        toasts.push(action.payload.toast);
      } else {
        queue.push(action.payload.toast);
      }

      return {
        ...state,
        toasts,
        queue,
      };
    case 'closeToast':
      return {
        ...state,
        // @ts-expect-error [EN-7967] - TS2322 - Type 'Readonly<Readonly<{ message: ReactNode; classes?: { [key: string]: unknown; }; severity?: "info" | "error" | "default" | "success"; actionName?: ReactNode; actionHandler?: (e: Event) => Promise<...>; ... 5 more ...; reduceCloseButtonHeight?: boolean; }> & { ...; }>[] | readonly Readonly<...>[]' is not assignable to type 'Readonly<Readonly<{ message: ReactNode; classes?: { [key: string]: unknown; }; severity?: "info" | "error" | "default" | "success"; actionName?: ReactNode; actionHandler?: (e: Event) => Promise<...>; ... 5 more ...; reduceCloseButtonHeight?: boolean; }> & { ...; }>[]'.
        toasts: pipe(
          reject(propEq('toastKey', action.payload.toastKey)),
          when(
            (newToasts: Array<Toast>) =>
              newToasts.length < state.maxNumberOfVisibleToasts && gt(length(state.queue), 0),
            append(head(state.queue))
          )
        )(state.toasts),
        // @ts-expect-error [EN-7967] - TS2322 - Type 'string' is not assignable to type 'Readonly<Readonly<{ message: ReactNode; classes?: { [key: string]: unknown; }; severity?: "info" | "error" | "default" | "success"; actionName?: ReactNode; actionHandler?: (e: Event) => Promise<...>; ... 5 more ...; reduceCloseButtonHeight?: boolean; }> & { ...; }>[]'.
        queue: pipe(
          reject(propEq('toastKey', action.payload.toastKey)),
          when(
            () =>
              state.toasts.some((t) => t.toastKey === action.payload.toastKey) &&
              gt(length(state.queue), 0),
            slice(0, -1)
          )
        )(state.queue),
      };
    case 'updateMaxNumberOfVisibleToasts':
      return {
        ...state,
        maxNumberOfVisibleToasts: action.payload.maxNumberOfVisibleToasts,
      };

    case 'updateToast': {
      const { toastKey, toast } = action.payload;
      const toastIndex = state.toasts.findIndex((toast) => toast.toastKey === toastKey);
      if (toastIndex === -1) {
        return state;
      }

      const updatedToast = {
        ...state.toasts[toastIndex],
        ...toast,
      } as const;

      const toasts = [...state.toasts];
      toasts[toastIndex] = updatedToast;

      return {
        ...state,
        toasts,
      };
    }

    default:
      // @ts-expect-error [EN-7967] - TS2339 - Property 'type' does not exist on type 'never'.
      return unreachableCaseError(action.type);
  }
};

export const ToasterProvider = ({
  children,
  maxNumberOfVisibleToasts = 1000,
  defaultToastProps,
  initialToasts = EMPTY.ARRAY,
  initialQueue = EMPTY.ARRAY,
}: ToasterProviderProps): React.ReactElement => {
  const [state, dispatch] = useReducer(toasterReducer, {
    toasts: initialToasts,
    queue: initialQueue,
    maxNumberOfVisibleToasts,
  });

  useEffect(() => {
    if (maxNumberOfVisibleToasts !== state.maxNumberOfVisibleToasts) {
      dispatch({
        type: 'updateMaxNumberOfVisibleToasts',
        payload: { maxNumberOfVisibleToasts },
      });
    }
  }, [maxNumberOfVisibleToasts, state.maxNumberOfVisibleToasts, dispatch]);

  const enqueueToast = useCallback(
    (message: React.ReactNode, options?: Readonly<Partial<Toast>>) => {
      const toastKey = nanoid();

      const toast: Toast = {
        position: 'top-right',
        ...defaultToastProps,
        toastKey,
        message,
      };

      dispatch({
        type: 'enqueueToast',
        payload: {
          toast: {
            ...toast,
            ...options,
          },
        },
      });

      return toastKey;
    },
    [dispatch, defaultToastProps]
  );

  const updateToast = useCallback(
    (message: React.ReactNode, toastKey: ToastKey, options?: Readonly<Partial<Toast>>) => {
      const toast: Toast = {
        position: 'top-right',
        ...defaultToastProps,
        toastKey,
        message,
      };

      dispatch({
        type: 'updateToast',
        payload: {
          toastKey,
          toast: {
            ...toast,
            ...options,
          },
        },
      });
    },
    [defaultToastProps]
  );

  const enqueueOrUpdateToast = useCallback(
    (message: React.ReactNode, toastKey?: ToastKey | null, options?: Readonly<Partial<Toast>>) => {
      if (toastKey != null && state.toasts.find((toast) => toast.toastKey === toastKey)) {
        updateToast(message, toastKey, options);
        return toastKey;
      } else {
        return enqueueToast(message, options);
      }
    },
    [enqueueToast, state.toasts, updateToast]
  );

  const closeToast = useCallback(
    (toastKey: ToastKey) => {
      dispatch({ type: 'closeToast', payload: { toastKey } });
    },
    [dispatch]
  );

  const toastContainers = useMemo(() => {
    return pipe(
      groupBy(prop('position')),
      toPairs,
      map(([position, toasties]: [any, any]) => (
        <ToastContainer position={position} key={position}>
          {toasties.map(({ toastKey, message, ...toastProps }) => {
            return (
              <ToastComponent
                message={message}
                key={toastKey}
                toastKey={toastKey}
                onClose={(toastKey) => dispatch({ type: 'closeToast', payload: { toastKey } })}
                {...toastProps}
              />
            );
          })}
        </ToastContainer>
      ))
    )(state.toasts);
  }, [state.toasts, dispatch]);

  const toasterDispatchBag = useMemo(
    () => ({
      enqueueToast,
      enqueueOrUpdateToast,
      closeToast,
    }),
    [enqueueToast, enqueueOrUpdateToast, closeToast]
  );

  return (
    <ToasterStateContext.Provider value={state}>
      <ToasterDispatchContext.Provider value={toasterDispatchBag}>
        {children}
        {/* @ts-expect-error [EN-7967] - TS2559 - Type '{ children: Element[]; }' has no properties in common with type 'IntrinsicAttributes & AnimatePresenceProps'. */}
        <AnimatePresence>{toastContainers}</AnimatePresence>
      </ToasterDispatchContext.Provider>
    </ToasterStateContext.Provider>
  );
};

export const useToasterState = (): ToasterState => {
  const context = useContext(ToasterStateContext);

  if (!context) {
    throw new Error('useToasterState must be used within a ToasterProvider.');
  }

  return context;
};

export const useToasterDispatch = (): ToasterDispatch => {
  const context = useContext(ToasterDispatchContext);

  if (!context) {
    throw new Error('useToasterDispatch must be used within a ToasterProvider.');
  }

  return context;
};

export const useToaster = (): [ToasterState, ToasterDispatch] => {
  const stateContext = useContext(ToasterStateContext);
  const dispatchContext = useContext(ToasterDispatchContext);

  if (!stateContext || !dispatchContext) {
    throw new Error('useToaster must be used within a ToasterProvider.');
  }

  return [stateContext, dispatchContext];
};

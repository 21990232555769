import { Stack } from 'common/ui/Layout';
import { Button } from 'common/ui/Button';
import { Colors } from 'styles';
import Dialog from 'common/ui/Dialog';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Refresh from '@material-ui/icons/Refresh';
import type { ValidationResult } from './useSystemCheck';
import { useMemo } from 'react';
import Tooltip from 'common/ui/Tooltip';
import {
  SysChkContainer,
  SysChkHeader,
  SysChkHeaderVariant,
  SysChkItem,
  SysChkProgress,
} from './SystemCheckUI';

const BYPASS_TOOLTIP_CONTENT =
  'Bypassing the system check will allow you to continue without resolving the issues. Please proceed with caution.';

const getSysCheckStyleAttr = (
  isSystemValid: boolean,
  shouldBypassSystemCheck: boolean,
  isValidationInProgress: boolean
) => {
  if (isValidationInProgress) {
    return {
      systemCheckText: 'Checking system...',
      variant: SysChkHeaderVariant.PROGRESS,
    };
  } else if (shouldBypassSystemCheck && isSystemValid) {
    return {
      systemCheckText: 'System check passed and bypassed',
      variant: SysChkHeaderVariant.BYPASS_PASS,
    };
  } else if (shouldBypassSystemCheck && !isSystemValid) {
    return {
      systemCheckText: 'System check failed but bypassed',
      variant: SysChkHeaderVariant.BYPASS_FAIL,
    };
  } else if (isSystemValid) {
    return {
      systemCheckText: 'System check passed!',
      variant: SysChkHeaderVariant.PASS,
    };
  }

  return {
    systemCheckText: 'System check failed',
    variant: SysChkHeaderVariant.FAIL,
  };
};

const RefreshNotice = () => (
  <div
    css={`
      text-align: center;
      padding: 0 2rem 2rem;
      font-size: 14px;
    `}
  >
    If you've recently enabled or installed the extension, you must refresh this window to get
    accurate results.
  </div>
);

export type Props = {
  isSystemValid: boolean;
  validationResults: Array<ValidationResult>;
  isOpen: boolean;
  shouldBypassSystemCheck: boolean;
  recheck: () => void;
  setBypass: (shouldBypass: boolean) => void;
  setModalState: (arg1: boolean) => void;
};
export const SystemCheckModal = ({
  isSystemValid,
  isOpen,
  shouldBypassSystemCheck,
  validationResults,
  setBypass,
  setModalState,
  recheck,
}: Props): React.ReactElement => {
  const isValidationInProgress = validationResults.length === 0;

  const { systemCheckText, variant } = useMemo(
    () => getSysCheckStyleAttr(isSystemValid, shouldBypassSystemCheck, isValidationInProgress),
    [isSystemValid, shouldBypassSystemCheck, isValidationInProgress]
  );

  return (
    <Dialog open={isOpen} maxWidth="xl" css="min-height: 400px" data-testid="dialog-open">
      <DialogTitle>Sirona System Check</DialogTitle>
      <DialogContent
        css={`
          &.MuiDialogContent-root {
            padding-bottom: 0;
          }
        `}
      >
        <SysChkContainer>
          <SysChkHeader variant={variant}>{systemCheckText}</SysChkHeader>

          {validationResults.length === 0 && <SysChkProgress />}
          {validationResults.map((validationResult) => (
            <SysChkItem
              id={validationResult.id}
              result={validationResult.result}
              key={validationResult.id}
              title={validationResult.reason}
            />
          ))}

          <Stack vertical alignX="center" css="margin: 3rem 0 0rem;">
            {isSystemValid === false && !isValidationInProgress && <RefreshNotice />}
            <Button
              disabled={isValidationInProgress}
              variant="ghost"
              icon={<Refresh />}
              onClick={recheck}
              data-testid="btn-syscheck-recheck"
            >
              Recheck
            </Button>
          </Stack>
        </SysChkContainer>
      </DialogContent>
      <DialogActions css="flex-direction: column;">
        <Stack css="gap: 1rem;">
          <Tooltip
            content={!shouldBypassSystemCheck ? BYPASS_TOOLTIP_CONTENT : null}
            placement="bottom"
            backgroundColor={Colors.gray8}
            color={Colors.gray1}
          >
            <Button
              variant="light"
              onClick={() => setBypass(!shouldBypassSystemCheck)}
              data-testid="btn-syscheck-bypass"
            >
              {shouldBypassSystemCheck ? 'Reset Bypass' : 'Bypass'}
            </Button>
          </Tooltip>
          <Button
            disabled={!(shouldBypassSystemCheck || isSystemValid)}
            onClick={() => setModalState(false)}
            data-testid="btn-syscheck-continue"
          >
            Continue
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

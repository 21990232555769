import type { GetWorklistViewsQuery } from 'generated/graphql';

import {
  ARCHIVE,
  PENDING,
  RECENTLY_READ,
  SEARCH_DEBOUNCE_MS,
  SORTABLE_WORKLIST_COLUMN_MAP,
} from 'config/constants';
import { SortColumn, SortOrder } from 'generated/graphql';
import { useDebouncedState } from 'hooks/useDebouncedState';
import {
  DEFAULT_EMPTY_WORKLIST_SURFACE_FILTERS,
  DEFAULT_SORT_COLUMNS,
  DEFAULT_SORT_ORDERS,
  DEFAULT_WORKLIST_SURFACE_SEARCH_VALUES,
} from './constants';

import { assoc, equals, isEmpty } from 'ramda';
import {
  useContext,
  createContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { useWorklistURLParams } from '../Worklist/useWorklistURLParams';
import type {
  FilterDefinitionType,
  FilterStateBySurface,
  FilterStateType,
  WorklistFiltersType,
  WorklistSurfaceSearchValues,
} from './types';
import { useGenerateFilterConfig } from './hooks/useGenerateFilterConfig';
import { useWorklistURL } from 'hooks/useWorklistURL';
import { FilterVariant } from './types';
import { logger } from 'modules/logger';
import type { WorklistSurfaceType } from '../Worklist/types';
import { WorklistSurface } from '../Worklist/types';
import { useWorklistViews } from '../Worklist/WorklistViews/useWorklistViews';
import { useLocation } from 'react-router-dom';
import { useSavedSortingPreferences } from '../Worklist/hooks/useSavedSortingPreferences';
import { useSavedWorklistViews } from '../Worklist/WorklistViews/useSavedWorklistViews';
import { validateSortColumns, validateSortOrders } from './utils';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';

export type WorklistFiltersContextProps = Readonly<{
  defaultSurface?: WorklistSurfaceType;
  children: React.ReactNode;
  overrideURLSearchParams?: URLSearchParams | null | undefined;
  overrideSurface?: WorklistSurfaceType | null | undefined;
}>;

type WorklistFiltersContextBag = {
  surface: WorklistSurfaceType;
  updateSurface: (arg1: WorklistSurfaceType) => void;
  availableFilters: FilterDefinitionType;
  filters: FilterStateType;
  lockedFilters: FilterStateType;
  updateFilters: (arg1: FilterStateType) => void;
  resetAllFilters: () => void;
  resetFilter: (arg1: WorklistFiltersType) => void;
  sortColumns: Array<SortColumn>;
  sortOrders: Array<SortOrder>;
  onSortChange: (columnId: string[], order: Array<SortOrder>) => void;
  setSearchForSurface: (value: string) => void;
  debouncedSearchForSurface: string;
  searchForSurface: string;
  worklistViews: GetWorklistViewsQuery['worklistViews'];
  areWorklistViewsLoading: boolean;
  getAppliedFilters: () => Array<string>;
  filtersBySurface: FilterStateBySurface;
  debouncedSearches: WorklistSurfaceSearchValues;
  surfacesToDefaultFilters: {
    [key: string]: FilterDefinitionType;
  };
  surfacesToLockedFilters: {
    [key: string]: FilterStateType;
  };
  updateSurfaceAndFilters: (newSurface: WorklistSurfaceType, newFilters: FilterStateType) => void;
  updateSurfaceAndSearch: (newSurface: WorklistSurfaceType, newSearch: string) => void;
};

const WorklistFiltersContext = createContext<WorklistFiltersContextBag | null | undefined>(
  undefined
);

export const WorklistFiltersContextProvider = ({
  children,
  defaultSurface = PENDING,
  overrideURLSearchParams,
  overrideSurface,
}: WorklistFiltersContextProps): React.ReactElement => {
  const {
    surface: urlSurface,
    sortColumn: urlSortColumns,
    sortOrder: urlSortOrders,
    filters: urlFilters,
  } = useWorklistURLParams(overrideURLSearchParams);

  const urlFiltersOnLoad = useRef(urlFilters);

  const { surfaceToFilterConfig } = useGenerateFilterConfig();
  const [surface, setSurface] = useState<WorklistSurfaceType>(urlSurface ?? defaultSurface);
  const [sortColumns, setSortColumns] = useState<Array<SortColumn>>(urlSortColumns);
  const [sortOrders, setSortOrders] = useState<Array<SortOrder>>(urlSortOrders);

  const { worklistViews, areWorklistViewsLoading } = useWorklistViews();
  const { saveSortingPreferences, savedSortingPreferences } = useSavedSortingPreferences();
  const [isCustomWorklistMilestoneOneEnabled] = useFeatureFlagEnabled(
    FF.WORKLIST_CUSTOM_WORKLISTS_MILESTONE_1
  );

  const location = useLocation();

  useEffect(() => {
    if (overrideSurface != null) {
      setSurface(overrideSurface);
    }
  }, [overrideSurface]);

  useEffect(() => {
    setSortColumns(urlSortColumns);
    setSortOrders(urlSortOrders);
  }, [urlSortColumns, urlSortOrders]);

  const isReporter = useMemo(() => location.pathname.includes('/reporter'), [location.pathname]);

  const shouldMutateURL = useMemo(
    () =>
      !isReporter &&
      surface !== WorklistSurface.STUDIES_DIALOG &&
      defaultSurface !== WorklistSurface.STUDIES_DIALOG &&
      surface !== WorklistSurface.PATIENT_JACKET_PRIORS,
    [defaultSurface, isReporter, surface]
  );

  const { mutate: mutateURL } = useWorklistURL({
    disabled: !shouldMutateURL,
    isReporter,
    initialSortColumn: sortColumns,
    initialSortOrder: sortOrders,
    initialSurface: surface,
  });

  const surfacesToDefaultFilters = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(surfaceToFilterConfig).map(([surface, { defaultFilters }]: [any, any]) => [
          surface,
          defaultFilters,
        ])
      ),
    [surfaceToFilterConfig]
  );

  const surfacesToLockedFilters: {
    [key: string]: FilterStateType;
  } = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(surfaceToFilterConfig).map(([surface, { lockedFilters }]: [any, any]) => [
          surface,
          lockedFilters,
        ])
      ),
    [surfaceToFilterConfig]
  );

  const initialFilters: FilterStateType = useMemo(() => {
    if (urlFiltersOnLoad.current != null) {
      const urlFiltersToApply = urlFiltersOnLoad.current;
      const surfaceFilters = surfacesToDefaultFilters[surface];
      const filters: FilterStateType = {};

      Object.keys(urlFiltersToApply).forEach((key) => {
        const urlFilter = urlFiltersToApply[key];
        const initialFilter = surfaceFilters[key];

        if (initialFilter == null) {
          return;
        }

        switch (initialFilter.type) {
          case FilterVariant.MultiSelect:
          case FilterVariant.DateRange:
          case FilterVariant.NumberRange:
            filters[key] = urlFilter.split(',');
            break;
          case FilterVariant.Toggle:
            filters[key] = [urlFilter];
            break;
          case FilterVariant.Text:
            filters[key] = [urlFilter];
            break;
          default:
            break;
        }
      });

      return filters;
    } else {
      return {};
    }
  }, [surfacesToDefaultFilters, surface]);

  const [filtersBySurface, setFiltersBySurface] = useState<FilterStateBySurface>({
    ...DEFAULT_EMPTY_WORKLIST_SURFACE_FILTERS,
    [surface as string]: {
      ...initialFilters,
      ...Object.fromEntries(
        Object.entries(surfacesToDefaultFilters[surface] ?? {})
          .filter(
            ([key, filterConfig]: [any, any]) =>
              filterConfig.type === FilterVariant.Toggle && filterConfig.defaultValue === true
          )
          .map(([key, filterConfig]: [any, any]) => [key, filterConfig.defaultValue])
      ),
    },
  });
  const currentFilterValues = useMemo(() => filtersBySurface[surface], [filtersBySurface, surface]);
  const filterDefinitions = useMemo(
    () => surfacesToDefaultFilters[surface] ?? {},
    [surfacesToDefaultFilters, surface]
  );

  const onSortChange = useCallback(
    (columnIds: string[], orders: Array<SortOrder>) => {
      const mappedColumns: Array<SortColumn> = columnIds.map(
        (id) => SORTABLE_WORKLIST_COLUMN_MAP[id]
      );

      let changedColumns: boolean = !equals(mappedColumns, sortColumns);
      let changedOrders: boolean = !equals(orders, sortOrders);

      if (mappedColumns != null && shouldMutateURL && (changedColumns || changedOrders)) {
        mutateURL({
          sortColumn: mappedColumns !== sortColumns ? mappedColumns : undefined,
          sortOrder: orders !== sortOrders ? orders : undefined,
        });
      }

      if (changedColumns) {
        setSortColumns(mappedColumns);
        changedColumns = true;
      }

      if (changedOrders) {
        setSortOrders(orders);
        changedOrders = true;
      }

      if (surface === WorklistSurface.STUDIES_DIALOG) {
        return;
      }

      if (changedColumns || changedOrders) {
        if (surface === WorklistSurface.WORKLIST_VIEW && currentFilterValues.views != null) {
          saveSortingPreferences(
            surface,
            mappedColumns.join(','),
            orders.join(','),
            currentFilterValues.views[0]
          );
        } else {
          saveSortingPreferences(surface, mappedColumns.join(','), orders.join(','));
        }
      }
    },
    [
      currentFilterValues,
      mutateURL,
      saveSortingPreferences,
      shouldMutateURL,
      sortColumns,
      sortOrders,
      surface,
    ]
  );

  const updateFilters = useCallback(
    (newFilters: FilterStateType) => {
      const previousFilters = filtersBySurface[surface];

      setFiltersBySurface((prevFilters) => ({
        ...prevFilters,
        [surface as string]: { ...prevFilters[surface], ...newFilters },
      }));

      logger.info(`[WorklistFiltersContext] Filters for ${surface} have been updated`, {
        surface,
        oldFilters: previousFilters,
        filters: { ...previousFilters, ...newFilters },
      });
    },
    [filtersBySurface, surface]
  );

  const { saveWorklistViews } = useSavedWorklistViews({
    surface,
    filters: { ...filtersBySurface[surface], ...surfacesToLockedFilters[surface] },
    updateFilters,
  });

  const [searches, debouncedSearches, setSearches] = useDebouncedState<WorklistSurfaceSearchValues>(
    DEFAULT_WORKLIST_SURFACE_SEARCH_VALUES,
    SEARCH_DEBOUNCE_MS
  );

  const debouncedSearchForSurface = useMemo(
    () => debouncedSearches[surface],
    [debouncedSearches, surface]
  );
  const searchForSurface = useMemo(() => searches[surface], [searches, surface]);

  const setSearchForSurface = useCallback(
    (value: string) => setSearches((currentSearch) => assoc(surface, value, currentSearch)),
    [setSearches, surface]
  );

  const resetAllFilters = useCallback(() => {
    setFiltersBySurface((prevFilters) => ({
      ...prevFilters,
      [surface as string]: surfacesToLockedFilters[surface],
    }));
    saveWorklistViews([]);
    setSearchForSurface('');
  }, [saveWorklistViews, setSearchForSurface, surface, surfacesToLockedFilters]);

  const resetFilter = useCallback(
    (filterKey: WorklistFiltersType) => {
      setFiltersBySurface((prevFilters) => {
        const newFilters = { ...prevFilters[surface] } as const;
        delete newFilters[filterKey];
        return { ...prevFilters, [surface as string]: newFilters };
      });
    },
    [surface]
  );

  useEffect(() => {
    if (!shouldMutateURL) {
      return;
    }

    const updatedUrlFilters = Object.keys(currentFilterValues).reduce<
      Partial<Record<keyof typeof currentFilterValues, string>>
    >((acc, key) => {
      const filterValue = currentFilterValues[key];
      const filterDef = filterDefinitions[key];

      if (
        filterValue == null ||
        (Array.isArray(filterValue) && filterValue.length === 0) ||
        filterDef == null
      ) {
        return acc;
      }

      try {
        switch (filterDef?.type) {
          case FilterVariant.MultiSelect:
            if (filterDef?.isBoolean === true) {
              if (filterValue.length === 1) {
                acc[key] = filterValue[0];
              }
            } else {
              acc[key] = Array.isArray(filterValue) ? filterValue.join(',') : filterValue;
            }
            break;
          case FilterVariant.DateRange:
            acc[key] =
              `${filterValue[0] != null ? new Date(filterValue[0])?.toISOString() : 'null'},${
                filterValue[1] != null ? new Date(filterValue[1]).toISOString() : 'null'
              }`;
            break;
          case FilterVariant.Toggle:
            acc[key] = key;
            break;
          case FilterVariant.Text:
            acc[key] = String(filterValue);
            break;
          case FilterVariant.NumberRange:
            acc[key] = Array.isArray(filterValue) ? filterValue.join(',') : filterValue;
            break;
          default:
            break;
        }
      } catch (e: any) {
        logger.error(e);
      }

      return acc;
    }, {});

    if (
      !equals(updatedUrlFilters, urlFilters) &&
      (!isEmpty(updatedUrlFilters) || urlFilters != null)
    ) {
      mutateURL({ filters: updatedUrlFilters });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilterValues, mutateURL, shouldMutateURL, surface]);

  const updateSurface = useCallback(
    (newSurface: WorklistSurfaceType, savedPreferenceKey?: string) => {
      if (newSurface === surface && savedPreferenceKey === newSurface) {
        return;
      }

      let newSortColumn: Array<SortColumn> = DEFAULT_SORT_COLUMNS;
      let newSortOrder: Array<SortOrder> = DEFAULT_SORT_ORDERS;

      if (([RECENTLY_READ, ARCHIVE] as WorklistSurfaceType[]).includes(newSurface)) {
        newSortColumn = [SortColumn.SubmittedAt];
        newSortOrder = [SortOrder.Desc];
      } else if (sortColumns.includes(SortColumn.SubmittedAt)) {
        newSortColumn = [SortColumn.StudyDatetime];
        newSortOrder = [SortOrder.Asc];
      }

      // Override defaults with saved preferences
      if (savedSortingPreferences[savedPreferenceKey ?? newSurface] != null) {
        const params = new URLSearchParams(
          savedSortingPreferences[savedPreferenceKey ?? newSurface]
        );
        const column = params.get('sortColumn') ?? '';
        const order = params.get('sortOrder') ?? '';
        newSortColumn = validateSortColumns(column.split(','), newSortColumn);
        newSortOrder = validateSortOrders(order.split(','), newSortOrder);
      }

      setSurface(newSurface);
      if (newSortColumn != null && newSortOrder != null) {
        setSortColumns(newSortColumn);
        setSortOrders(newSortOrder);
      }

      if (shouldMutateURL) {
        mutateURL({
          tab: newSurface,
          sortColumn: newSortColumn ?? undefined,
          sortOrder: newSortOrder ?? undefined,
        });
      }
    },
    [mutateURL, savedSortingPreferences, shouldMutateURL, sortColumns, surface]
  );

  const updateSurfaceAndFilters = useCallback(
    (newSurface: WorklistSurfaceType, newFilters: FilterStateType) => {
      const previousFilters = filtersBySurface[newSurface];

      setFiltersBySurface((prevFilters) => ({
        ...prevFilters,
        [newSurface as string]: { ...prevFilters[newSurface], ...newFilters },
      }));

      const sortingPreferenceKey =
        isCustomWorklistMilestoneOneEnabled &&
        newSurface === WorklistSurface.WORKLIST_VIEW &&
        newFilters.views?.length > 0
          ? `${newSurface}_${newFilters.views[0]}`
          : newSurface;
      updateSurface(newSurface, sortingPreferenceKey);

      logger.info(`[WorklistFiltersContext] Filters for ${newSurface} have been updated`, {
        newSurface,
        oldFilters: previousFilters,
        filters: { ...previousFilters, ...newFilters },
      });
    },
    [filtersBySurface, isCustomWorklistMilestoneOneEnabled, updateSurface]
  );

  const updateSurfaceAndSearch = useCallback(
    (newSurface: WorklistSurfaceType, newSearch: string) => {
      setSearches((currentSearch) => assoc(newSurface, newSearch, currentSearch));
      updateSurface(newSurface);
    },
    [setSearches, updateSurface]
  );

  const getAppliedFilters = useCallback(() => {
    const filters = { ...filtersBySurface[surface], ...surfacesToLockedFilters[surface] } as const;
    const nonEmptyFilters = Object.fromEntries(
      Object.entries(filters).filter(([name, value]: [any, any]) => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }

        return value != null && value !== '';
      })
    );

    return Object.keys(nonEmptyFilters);
  }, [filtersBySurface, surfacesToLockedFilters, surface]);

  return (
    <WorklistFiltersContext.Provider
      value={{
        sortColumns,
        sortOrders,
        filters: { ...filtersBySurface[surface], ...surfacesToLockedFilters[surface] },
        lockedFilters: surfacesToLockedFilters[surface],
        updateFilters,
        surface,
        updateSurface,
        setSearchForSurface,
        searchForSurface,
        debouncedSearchForSurface,
        onSortChange,
        availableFilters: surfacesToDefaultFilters[surface],
        resetAllFilters,
        resetFilter,
        worklistViews,
        areWorklistViewsLoading,
        getAppliedFilters,
        filtersBySurface,
        debouncedSearches,
        surfacesToDefaultFilters,
        updateSurfaceAndFilters,
        updateSurfaceAndSearch,
        surfacesToLockedFilters,
      }}
    >
      {children}
    </WorklistFiltersContext.Provider>
  );
};

export const useWorklistFiltersContext = (): WorklistFiltersContextBag => {
  const worklistFilters = useContext(WorklistFiltersContext);

  if (!worklistFilters) {
    throw new Error(
      'useWorklistFiltersContext must be used within a WorklistFiltersContextProvider.'
    );
  }

  return worklistFilters;
};

import { useSlateSingletonContext } from '../SlateSingletonContext';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';
import { useMemo } from 'react';
import { useFields } from '../Fields/Fields.Context';
import { getNavigationItemsFromEditor, getNavigationItemsFromReportContent } from './utils';
import type { NavigationItem } from './BaseOutline';
import { BaseOutline } from './BaseOutline';

type ReportOutlineProps = {
  isDisabled: boolean;
};

export const ReportOutline = ({
  isDisabled = false,
}: ReportOutlineProps): React.ReactElement | null => {
  const [isReportOutlineEnabled] = useFeatureFlagEnabled(FF.REPORT_OUTLINE);
  const [{ editor }] = useSlateSingletonContext();

  const [{ content: reportContent, addendum, finalizedAddendums }] = useFields();
  const navigationItems = useMemo((): Array<NavigationItem> => {
    return isDisabled || editor == null || addendum != null || finalizedAddendums.length > 0
      ? getNavigationItemsFromReportContent(reportContent)
      : getNavigationItemsFromEditor(editor);
  }, [addendum, editor, finalizedAddendums.length, isDisabled, reportContent]);

  if (!isReportOutlineEnabled) {
    return null;
  }

  return <BaseOutline navigationItems={navigationItems} isDisabled={isDisabled} />;
};

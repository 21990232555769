import { useSlateSingletonContext } from '../domains/reporter/Reporter/SlateSingletonContext';
import { stringifyRange } from 'domains/reporter/RichTextEditor/utils/stringify';
import { partialEditor } from 'domains/reporter/RichTextEditor/utils';
import { logger } from 'modules/logger';
import { unfurlMacroPlaceholderStatic } from 'domains/reporter/RichTextEditor';
import { useCallback } from 'react';
import useAutoloadTemplate from './useAutoloadTemplate';
import type { ReportTemplateFieldsFragment } from 'generated/graphql';
import { Editor, Transforms } from '../domains/reporter/RichTextEditor/core';
import { hydratePlaceholders } from '../domains/reporter/RichTextEditor/plugins/placeholder/utils';
import { defaultSelection } from '../domains/reporter/RichTextEditor/ui/RichTextEditor';
import { useCurrentWorklistItems } from './useCurrentWorklistItems';
import { useCurrentUser } from './useCurrentUser';
import type { GroupedWorklistItems } from './useCurrentWorklistItems';
import type { Json } from '../generated/graphql';
import { usePicklistState } from '../domains/reporter/Template/usePicklist';
import { prop, uniqBy } from 'ramda';
import { useRequiredFieldIndicator } from './useRequiredFieldIndicator';
import { useImpressionGenerator } from './useImpressionGenerator';
import { FF } from 'modules/feature-flags';
import { useFeatureFlagEnabled } from '../modules/feature-flags/useFeatureFlagEnabled';
import type { Macros } from '../domains/reporter/Template/usePicklist';
import { useRecoilState } from 'recoil';
import { reportTemplateState } from 'domains/reporter/Reporter/state';
import type { AutoloadTemplateState } from './useAutoloadTemplate';

type UseReportTemplateReturn = {
  insertTemplate: (arg1: Json) => void;
  template: ReportTemplateFieldsFragment | null | undefined;
  setTemplate: (arg1?: ReportTemplateFieldsFragment | null | undefined) => void;
  unfurlTemplate: (arg1: ReportTemplateFieldsFragment) => void;
  autoloadTemplate: () => void;
  autoloadTemplateState: AutoloadTemplateState;
};

export type InsertTemplateParams = {
  editor: Editor;
  currentWorklistItems: GroupedWorklistItems;
  ignoreMergeFieldsInDefaultSelection: boolean;
  templateContentToInsert: Json;
};

// Note that this will move the selection twice. Once, to 0, and then to
// the next heading section bracket. The first will make sure that any
// necessary normalization based on the selection after the withoutNormalizing
// happens at 0. The second places the user's selection where they expect it.
export const insertTemplate = ({
  editor,
  currentWorklistItems,
  ignoreMergeFieldsInDefaultSelection,
  templateContentToInsert,
}: InsertTemplateParams): void => {
  if (editor == null) {
    return;
  }
  const editorRange = { anchor: Editor.start(editor, []), focus: Editor.end(editor, []) } as const;
  Editor.withoutNormalizing(editor, () => {
    Transforms.removeNodes(editor, { at: editorRange });
    Transforms.insertNodes(editor, templateContentToInsert, {
      at: [0],
    });
  });

  hydratePlaceholders(editor, currentWorklistItems);

  // the setTimeout allows other defaultSelection calls from mounting to finish and allow correct selection resulting from template insertion to be used
  setTimeout(() => {
    defaultSelection(editor, { ignoreMergeFields: ignoreMergeFieldsInDefaultSelection });
  }, 0);
};

export const useReportTemplate = (): UseReportTemplateReturn => {
  const [{ editor, pluginsBag }] = useSlateSingletonContext();
  const { setMacros } = usePicklistState();

  const [template, setTemplate] = useRecoilState(reportTemplateState);
  const { currentWorklistItems, loading } = useCurrentWorklistItems();
  const { data } = useCurrentUser();
  const me = data?.me;
  const ignoreMergeFieldsInDefaultSelection =
    me?.reporterSettings.mergeFieldsSettings?.ignoreDefaultSelection ?? false;
  const [isAutoImpressionsEnabled] = useFeatureFlagEnabled(FF.REPORTER_AUTO_IMPRESSIONS);
  const { resetRequiredFields } = useRequiredFieldIndicator();
  const { resetGenerateImpressionState, setIsFirstNavigationToImpressionSection } =
    useImpressionGenerator();

  const insertTemplateCallback = useCallback(
    (templateToInsert) => {
      if (editor == null) {
        return;
      }
      resetRequiredFields();
      insertTemplate({
        editor,
        currentWorklistItems,
        ignoreMergeFieldsInDefaultSelection,
        templateContentToInsert: templateToInsert.content,
      });
      if (isAutoImpressionsEnabled) {
        resetGenerateImpressionState();
        setIsFirstNavigationToImpressionSection(true);
      }
    },
    [
      editor,
      currentWorklistItems,
      ignoreMergeFieldsInDefaultSelection,
      resetRequiredFields,
      isAutoImpressionsEnabled,
      resetGenerateImpressionState,
      setIsFirstNavigationToImpressionSection,
    ]
  );

  const unfurlTemplate = useCallback(
    (template: ReportTemplateFieldsFragment) => {
      if (pluginsBag == null || pluginsBag.getEditorStateEnhancers() == null) {
        const unfurlTemplateError =
          '[ReporterComponent] No Slate editor state enhancers found. Maybe the editor has not fully initialized. Please try again.';
        logger.error(unfurlTemplateError, {
          logId: editor?.selection != null ? stringifyRange(editor.selection) : 'null',
          editor: editor != null ? partialEditor(editor) : 'null',
          template: {
            name: template.name,
            smid: template.id,
          },
        });
        return;
      }

      const newTemplate = {
        ...template,
        sections: unfurlMacroPlaceholderStatic(
          template.sections,
          template.macros,
          pluginsBag.getEditorStateEnhancers()
        ),
        // in the unified editor world, templates have 'content' but may not apply to old templates
        ...(template.content != null && {
          content: unfurlMacroPlaceholderStatic(
            template.content,
            template.macros,
            pluginsBag.getEditorStateEnhancers()
          ),
        }),
      } as const;

      setMacros((stateMacros: Macros): Macros => {
        const oldMacros = stateMacros ?? [];
        const newMacros = (newTemplate.macros ?? []).map((m) => ({ ...m, nestedMacros: [] }));

        return uniqBy(prop('smid'), [...oldMacros, ...newMacros]);
      });
      setTemplate(newTemplate);
    },
    [editor, pluginsBag, setMacros, setTemplate]
  );

  const { autoloadTemplate, autoloadTemplateState } = useAutoloadTemplate({
    unfurlTemplate,
    loading,
    setTemplate,
  });

  return {
    insertTemplate: insertTemplateCallback,
    template,
    unfurlTemplate,
    setTemplate,
    autoloadTemplate,
    autoloadTemplateState,
  };
};

import { Icon } from 'common/ui/Icon';
import type { PickerEvent } from '../modules/usePicker';
import { useHTMLActionFromPickerClick } from '../modules/useHTMLActionFromPickerClick';

type EditableProps = {
  id: string;
  onClick?: (event: PickerEvent) => void;
};

export const Editable = ({ id, onClick }: EditableProps): React.ReactElement => {
  const ref = useHTMLActionFromPickerClick({ id, onClick });

  return (
    // @ts-expect-error [EN-7967] - TS2322 - Type 'Ref<ElementType<any, keyof IntrinsicElements>>' is not assignable to type 'LegacyRef<HTMLSpanElement>'. | TS2322 - Type '(event: PickerEvent) => void' is not assignable to type 'MouseEventHandler<HTMLSpanElement>'.
    <span ref={ref} onClick={onClick}>
      <Icon name="edit_pencil" size="small"></Icon>
    </span>
  );
};

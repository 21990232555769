import { Node } from 'domains/reporter/RichTextEditor/core';
import isHotkey from 'is-hotkey';
import { Editor, Transforms } from 'slate';
import type { CreateOnKeyDown } from '../../types';
import { find } from '../../utils';
import type { DeepLinkPluginPropertyOptions } from './types';
import { ReactEditor } from 'slate-react';
import { DeepLinkVariants } from './constants';

export const onKeyDown: CreateOnKeyDown<DeepLinkPluginPropertyOptions> =
  (options) =>
  (e: KeyboardEvent, { editor, nodeState }) => {
    const { pluginID, getIsEditingStudyNode } = options;
    // If the user is at the right outside edge of a section header node, adjust the selection to be
    // the left outside edge (skip over the node)
    if (isHotkey('left', e) && editor.selection != null) {
      const { selection } = editor;
      const matchEntryBefore = find(editor, (n: Node) => n.type === pluginID, {
        at: Editor.before(editor, selection),
      });

      if (matchEntryBefore != null) {
        if (matchEntryBefore[0].variant === DeepLinkVariants.STUDY && getIsEditingStudyNode) {
          try {
            const key = ReactEditor.findKey(editor, matchEntryBefore[0]);
            // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'import("slate-react").Key' is not assignable to parameter of type 'React.Key'.
            const isEditing = getIsEditingStudyNode(key);
            // don't delete the entire node if in editing mode
            if (isEditing) {
              return true;
            }
          } catch (e: any) {
            return true;
          }
        }
        const targetPoint = Editor.before(editor, matchEntryBefore[1]);

        if (targetPoint != null) {
          e.preventDefault();
          Transforms.select(editor, targetPoint);

          return true;
        }
      }
    }

    // If the user is at the left outside edge of a section header node, adjust the selection to be
    // the right outside edge (skip over the node)
    if (isHotkey('right', e) && editor.selection != null) {
      const { selection } = editor;

      const matchEntryAfter = find(editor, (n: Node) => n.type === pluginID, {
        at: Editor.after(editor, selection),
      });

      if (matchEntryAfter != null) {
        if (matchEntryAfter[0].variant === DeepLinkVariants.STUDY && getIsEditingStudyNode) {
          try {
            const key = ReactEditor.findKey(editor, matchEntryAfter[0]);
            // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'import("slate-react").Key' is not assignable to parameter of type 'React.Key'.
            const isEditing = getIsEditingStudyNode(key);
            // don't delete the entire node if in editing mode
            if (isEditing) {
              return true;
            }
          } catch (e: any) {
            return true;
          }
        }
        const targetPoint = Editor.after(editor, matchEntryAfter[1]);

        if (targetPoint != null) {
          e.preventDefault();
          Transforms.select(editor, targetPoint);

          return true;
        }
      }
    }
  };

import { Node } from 'domains/reporter/RichTextEditor/core';
import { Editor, Element } from '../core';

export const areMultipleNodesSelected = (editor: Editor): boolean => {
  const { selection } = editor;
  if (selection == null) {
    return false;
  }

  const selectedNodes = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n: Node) => !Editor.isEditor(n) && Element.isElement(n),
    mode: 'highest',
  });

  let count = 0;
  for (const [node] of selectedNodes) {
    if (node != null) {
      count++;
    }

    if (count > 1) {
      return true;
    }
  }

  return false;
};

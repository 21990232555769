import { Path, Editor, Text, Transforms, Node } from '../core';
import { getPointRefSafe } from '../utils';
import { pipe, trim, length, allPass, prop, anyPass } from 'ramda';
import {
  capitalizeFirstWord,
  uncapitalizeFirstWord,
  chompLeft,
  chompRight,
  endsWithPunctuation,
  endsWithPunctuationOneOrMultipleWhitespace,
  endsWithEndOfSentencePunctuationOneOrMultipleWhitespace,
  startsWithPunctuation,
  isAcronym,
  isAnatomicalLocation,
  startsWith,
  startsWithContent,
  startsWithProperNoun,
  startsWithWhitespace,
  startsWithWhitespaceContent,
  endsWithContent,
  endsWithContentWhitespace,
  removeLastCharacterOfString,
  startsWithComma,
  endsWithComma,
  stripLeadingComma,
  appendWhitespaceIfNeeded,
  prependWhitespaceIfNeeded,
  startsWithWhitespacePunctuation,
  lengthOfStartingWhitespace,
  lengthOfEndingWhiteSpace,
  firstLetterOfFirstWord,
  endsWithUnitOfMeasure,
  startsWithUnitOfMeasure,
  startsWithWhitespaceUnitOfMeasure,
  removeUnitOfMeasureFromEndOfString,
  getUnitOfMeasureFromEndOfString,
  getUnitOfMeasureFromStartOfString,
  startsWithNumber,
  firstCharacterShouldHaveSpaceAfter,
  firstCharacterShouldHaveSpaceBefore,
  endsWithNumber,
  endsWithDecimal,
  endsWithWhitespace,
  startsWithUppercase,
  maybeUncapitalizeFirstLetterAfterTarget,
  endsWithNoCapitalizationAfterCharacter,
  endsWithSpacesAfterCharacter,
  containsEndOfSentencePunctuation,
  capitalizeWordsAfterPunctuation,
  endsWithPunctuationWhitespaceLeftParenthesis,
  NO_CAPITALIZATION_AFTER,
} from './normalizationHelpers';
import { SECTION_HEADER_PLUGIN_ID } from '../plugins/sectionHeader';
import { PARAGRAPH_PLUGIN_ID } from '../plugins';
import type { NodeEntry } from 'slate';
import { isSelectionInLineBreak } from '../plugins/lineBreak/utils';
import { logger } from 'modules/logger';
import { partialEditor } from '../utils/partialEditor';
import { CustomElement } from '../slate-custom-types';
import { Element } from 'slate';

export type NormalizeTextAffinity = 'left' | 'right' | 'both';

type TextNormalizationHandler = (
  editor: Editor,
  node: Text,
  arg3: {
    at: Path;
    affinity: NormalizeTextAffinity;
    respectParagraphBoundaries: boolean;
  }
) => void;

const hasCommonParagraphAncestor = (editor: Editor, path: Path, another: Path) => {
  const commonPath = Path.common(path, another);

  for (const [levelNode] of Array.from(Node.levels(editor, commonPath))) {
    if ((levelNode as CustomElement).type === PARAGRAPH_PLUGIN_ID) {
      return true;
    }
  }

  return false;
};

const isInSectionHeader = (editor: Editor, textNodeBeforePath: Path) => {
  return (
    Array.from(
      Editor.levels(editor, {
        at: textNodeBeforePath,
        match: (n) => Element.isElement(n) && n.type === SECTION_HEADER_PLUGIN_ID,
      })
    ).length > 0
  );
};

const leftAffinityHandler: TextNormalizationHandler = (
  editor,
  node,
  { at, affinity, respectParagraphBoundaries }
) => {
  // Maybe filter by text nodes that contain content?
  let textEntryBefore: NodeEntry<never> | null | undefined = Editor.previous(editor, {
    at,
    match: (n) => Text.isText(n) && Node.string(n).length > 0,
  });
  const textEntryBeforeWithContent = Editor.previous(editor, {
    at,
    match: (n) => allPass([Text.isText, pipe(prop('text'), trim, length, Boolean)])(n),
  });
  let textEntryBeforeNoFilter = Editor.previous(editor, {
    at,
    match: (n) => Text.isText(n),
    mode: 'lowest',
  });

  if (
    respectParagraphBoundaries &&
    textEntryBefore != null &&
    !hasCommonParagraphAncestor(editor, textEntryBefore[1], at)
  ) {
    textEntryBefore = null;
  }

  if (
    respectParagraphBoundaries &&
    textEntryBeforeWithContent != null &&
    !hasCommonParagraphAncestor(editor, textEntryBeforeWithContent[1], at)
  ) {
    textEntryBefore = null;
  }

  // At the start of the document
  if (!textEntryBefore) {
    node.text = capitalizeFirstWord(node.text);
  } else {
    let isNodeBeforeLineBreak = false;

    const [textNodeBefore, textNodeBeforePath] = textEntryBefore;
    const isTextNodeBeforeInsideSectionHeader = isInSectionHeader(editor, textNodeBeforePath);
    try {
      if (textEntryBeforeNoFilter != null && Node.string(textEntryBeforeNoFilter[0]).length === 0) {
        isNodeBeforeLineBreak = isSelectionInLineBreak(
          editor,
          Editor.range(editor, textEntryBeforeNoFilter[1])
        );

        if (!isNodeBeforeLineBreak) {
          textEntryBeforeNoFilter = Editor.previous(editor, { at: textEntryBeforeNoFilter[1] });
          isNodeBeforeLineBreak =
            textEntryBeforeNoFilter != null
              ? isSelectionInLineBreak(editor, Editor.range(editor, textEntryBeforeNoFilter[1]))
              : false;
        }
      }
    } catch (e: any) {
      logger.warn(
        '[normalizeTextNodeAtPath] Error occurred when checking for line breaks for normalization',
        {
          error: e,
          node,
          at,
          editor: partialEditor(editor),
        }
      );
    }

    if (
      !endsWithPunctuationWhitespaceLeftParenthesis((textNodeBefore as Text).text) &&
      anyPass([endsWithContent, endsWithContentWhitespace, endsWithNoCapitalizationAfterCharacter])(
        (textNodeBefore as Text).text
      )
    ) {
      if (
        !isAcronym(chompLeft(node.text)) &&
        !isAnatomicalLocation(chompLeft(node.text).split(' ')[0]) &&
        !startsWithProperNoun(chompLeft(node.text)) &&
        anyPass([startsWithContent, startsWithWhitespaceContent])(node.text)
      ) {
        node.text = uncapitalizeFirstWord(node.text);
      }
    }

    let isNodeBeforeHeading = false;

    // Heuristic of 3 or less words indicates before the first `:` to mark a heading.
    if (
      (chompRight((textNodeBefore as Text).text).endsWith(':') ||
        chompLeft(node.text).startsWith(':')) &&
      chompRight((textNodeBefore as Text).text).split(' ').length <= 3
    ) {
      isNodeBeforeHeading = true;
      node.text = capitalizeFirstWord(node.text);
    }

    // for each punctuation in NO_CAPITALIZATION_AFTER, we want to maybe uncapitalize the word after it
    // except for the case of `:` where we only want to uncapitalize if the node before is not a heading
    NO_CAPITALIZATION_AFTER.split('').forEach((punctuation) => {
      if (isNodeBeforeHeading) {
        return;
      }

      node.text = maybeUncapitalizeFirstLetterAfterTarget(node.text, punctuation);
    });

    if (
      endsWithContentWhitespace((textNodeBefore as Text).text) &&
      startsWithWhitespace(node.text)
    ) {
      node.text = chompLeft(node.text);
    }

    if (
      endsWithContentWhitespace((textNodeBefore as Text).text) &&
      (anyPass([startsWithPunctuation, startsWithComma])(node.text) ||
        !firstCharacterShouldHaveSpaceBefore(node.text))
    ) {
      Transforms.delete(editor, {
        at: Editor.end(editor, textNodeBeforePath),
        distance: lengthOfEndingWhiteSpace((textNodeBefore as Text).text),
        unit: 'character',
        reverse: true,
      });
    }

    if (
      startsWithContent(node.text) &&
      (endsWithEndOfSentencePunctuationOneOrMultipleWhitespace((textNodeBefore as Text).text) ||
        isTextNodeBeforeInsideSectionHeader ||
        isNodeBeforeLineBreak ||
        isNodeBeforeHeading)
    ) {
      // debugging edge cases for capitalization in the middle of text (CF-281)
      logger.info(
        `[normalizeTextNodeAtPath] Capitalizing first word after punctuation: 
          endsWithEndOfSentencePunctuationOneOrMultipleWhitespace: ${endsWithEndOfSentencePunctuationOneOrMultipleWhitespace((textNodeBefore as Text).text).toString()}, 
          isTextNodeBeforeInsideSectionHeader: ${isTextNodeBeforeInsideSectionHeader.toString()}, 
          isNodeBeforeLineBreak: ${isNodeBeforeLineBreak.toString()}, 
          isNodeBeforeHeading: ${isNodeBeforeHeading.toString()}`
      );
      node.text = capitalizeFirstWord(node.text);
    }

    if (
      !isNodeBeforeLineBreak &&
      (endsWithPunctuation((textNodeBefore as Text).text) || isTextNodeBeforeInsideSectionHeader) &&
      startsWithContent(node.text) &&
      (!endsWithDecimal((textNodeBefore as Text).text) || !startsWithNumber(node.text)) // do not prepend a space if the node is the second half of a decimal
    ) {
      // debugging edge cases for capitalization in the middle of text (CF-281)
      logger.info(
        `[normalizeTextNodeAtPath] Capitalizing first word and adding whitespace:  
            endsWithPunctuation: ${endsWithPunctuation((textNodeBefore as Text).text).toString()},
            isTextNodeBeforeInsideSectionHeader: ${isTextNodeBeforeInsideSectionHeader.toString()}
            endsWithDecimal: ${endsWithDecimal((textNodeBefore as Text).text).toString()}
            startsWithNumber: ${startsWithNumber(node.text).toString()}`
      );
      node.text = capitalizeFirstWord(node.text);
      node.text = prependWhitespaceIfNeeded(node.text);
    }

    if (
      endsWithComma((textNodeBefore as Text).text) ||
      endsWithSpacesAfterCharacter((textNodeBefore as Text).text)
    ) {
      // There are two scenarios here. The node before is either "," or ", " (or potentially more whitespace).
      // If it is the latter, we trim the previous node's whitespace and add a space to the start of the current node.
      if (!endsWithWhitespace((textNodeBefore as Text).text)) {
        node.text = prependWhitespaceIfNeeded(node.text);
      } else if (endsWithWhitespace((textNodeBefore as Text).text)) {
        node.text = prependWhitespaceIfNeeded(node.text);
        Transforms.delete(editor, {
          at: Editor.start(editor, textNodeBeforePath),
          unit: 'character',
          distance: (textNodeBefore as Text).text.length,
        });
        Transforms.insertText(editor, chompRight((textNodeBefore as Text).text), {
          at: Editor.start(editor, textNodeBeforePath),
        });
      }
      if (
        !isNodeBeforeHeading &&
        !isAcronym(chompLeft(node.text)) &&
        !isAnatomicalLocation(chompLeft(node.text).split(' ')[0]) &&
        !startsWithProperNoun(chompLeft(node.text))
      ) {
        node.text = uncapitalizeFirstWord(node.text);
      }
    }

    if (
      !isNodeBeforeHeading &&
      startsWithComma(node.text) &&
      !isAcronym(stripLeadingComma(node.text)) &&
      !isAnatomicalLocation(stripLeadingComma(node.text)) &&
      !startsWithProperNoun(stripLeadingComma(node.text))
    ) {
      node.text = uncapitalizeFirstWord(node.text);
    }

    // If the textEntryBefore only contains whitespace sometimes we want to run special logic
    if (
      textEntryBeforeWithContent &&
      textEntryBefore &&
      !Path.equals(textEntryBeforeWithContent[1], textEntryBefore[1])
    ) {
      const [textNodeBeforeWithContent, textPathBeforeWithContent] = textEntryBeforeWithContent;
      const isTTextNodeBeforeWithContentInsideSectionHeader = isInSectionHeader(
        editor,
        textPathBeforeWithContent
      );

      if (
        (anyPass([endsWithPunctuationOneOrMultipleWhitespace, endsWithPunctuation])(
          (textNodeBeforeWithContent as Text).text
        ) ||
          isTTextNodeBeforeWithContentInsideSectionHeader) &&
        startsWithContent(node.text)
      ) {
        node.text = capitalizeFirstWord(node.text);
      }
    }

    // replace 'by' with 'x', if the context indicates a measurement
    if (
      (endsWithNumber((textNodeBefore as Text).text) ||
        endsWithDecimal((textNodeBefore as Text).text)) &&
      startsWith('by ', { caseInsensitive: true })(node.text)
    ) {
      node.text = node.text.replace(/\bby\b/i, 'x');

      if (!endsWithWhitespace((textNodeBefore as Text).text)) {
        node.text = prependWhitespaceIfNeeded(node.text);
      }
    }

    // This is inserting a space before the number
    if (
      !isNodeBeforeLineBreak &&
      firstCharacterShouldHaveSpaceBefore(node.text) &&
      endsWithContent((textNodeBefore as Text).text) &&
      !startsWithComma(node.text) &&
      !startsWithPunctuation(node.text) &&
      (!startsWithNumber(node.text) || !endsWithNumber((textNodeBefore as Text).text))
    ) {
      node.text = prependWhitespaceIfNeeded(node.text);
    }
  }
};

const rightAffinityHandler: TextNormalizationHandler = (
  editor,
  node,
  { at, affinity, respectParagraphBoundaries }
) => {
  const textEntryBefore = Editor.previous(editor, {
    at,
    match: (n) => Text.isText(n) && Node.string(n).length > 0,
  });

  let textEntryAfter: NodeEntry<never> | null | undefined = Editor.next(editor, {
    at,
    match: (n) => Text.isText(n) && Node.string(n).length > 0,
  });

  let textEntryAfterWithContent: NodeEntry<never> | null | undefined = Editor.next(editor, {
    at,
    match: (n) =>
      allPass([
        Text.isText,
        pipe(
          // $FlowFixMe[prop-missing]
          // $FlowFixMe[incompatible-type]
          prop('text'),
          trim,
          length,
          Boolean
        ),
      ])(n),
  });

  if (
    respectParagraphBoundaries &&
    textEntryAfter != null &&
    !hasCommonParagraphAncestor(editor, textEntryAfter[1], at)
  ) {
    textEntryAfter = null;
  }

  if (
    respectParagraphBoundaries &&
    textEntryAfterWithContent != null &&
    !hasCommonParagraphAncestor(editor, textEntryAfterWithContent[1], at)
  ) {
    textEntryAfterWithContent = null;
  }

  if (textEntryAfter) {
    const [textNodeAfter, textNodeAfterPath] = textEntryAfter;

    if (
      anyPass([startsWithContent, startsWithWhitespaceContent])((textNodeAfter as Text).text) &&
      endsWithPunctuation(node.text)
    ) {
      const [letterToCapitalize, letterToCapitalizeIndex] = firstLetterOfFirstWord(
        (textNodeAfter as Text).text
      );
      if (letterToCapitalizeIndex > -1) {
        const pointRef = Editor.pointRef(
          editor,
          {
            path: textNodeAfterPath,
            offset: letterToCapitalizeIndex,
          },
          { affinity: 'forward' }
        );

        Transforms.insertText(editor, letterToCapitalize.toUpperCase(), {
          at: getPointRefSafe(pointRef),
        });
        Transforms.delete(editor, {
          at: getPointRefSafe(pointRef),
          distance: 1,
          unit: 'character',
        });

        pointRef.unref();
      }
    }

    if (
      anyPass([endsWithContent, endsWithPunctuation])(node.text) &&
      startsWithWhitespacePunctuation((textNodeAfter as Text).text)
    ) {
      Transforms.delete(editor, {
        at: Editor.start(editor, textNodeAfterPath),
        unit: 'character',
        distance: lengthOfStartingWhitespace((textNodeAfter as Text).text),
      });
    }

    const nextWordFollowsProperNounRules =
      textEntryBefore != null &&
      hasCommonParagraphAncestor(editor, textEntryBefore[1], at) &&
      !endsWithPunctuation(chompRight((textEntryBefore[0] as Text).text)) &&
      startsWithUppercase(chompLeft((textNodeAfter as Text).text));

    if (
      endsWithContent(node.text) &&
      !isAcronym(chompLeft((textNodeAfter as Text).text)) &&
      !isAnatomicalLocation(chompLeft((textNodeAfter as Text).text)) &&
      anyPass([startsWithContent, startsWithWhitespaceContent])((textNodeAfter as Text).text) &&
      !nextWordFollowsProperNounRules
    ) {
      const [letterToUncapitalize, letterToUncapitalizeIndex] = firstLetterOfFirstWord(
        (textNodeAfter as Text).text
      );
      if (letterToUncapitalizeIndex > -1) {
        const pointRef = Editor.pointRef(
          editor,
          {
            path: textNodeAfterPath,
            offset: letterToUncapitalizeIndex,
          },
          { affinity: 'forward' }
        );

        Transforms.insertText(editor, letterToUncapitalize.toLowerCase(), {
          at: getPointRefSafe(pointRef),
        });
        Transforms.delete(editor, {
          at: getPointRefSafe(pointRef),
          distance: 1,
          unit: 'character',
        });

        pointRef.unref();
      }
    }

    if (
      endsWithPunctuation(node.text) &&
      anyPass([startsWithPunctuation, startsWithWhitespacePunctuation])(
        (textNodeAfter as Text).text
      )
    ) {
      node.text = removeLastCharacterOfString(node.text);
    }

    if (endsWithUnitOfMeasure(chompRight(node.text))) {
      if (
        startsWithUnitOfMeasure((textNodeAfter as Text).text) &&
        getUnitOfMeasureFromEndOfString(node.text) ===
          getUnitOfMeasureFromStartOfString((textNodeAfter as Text).text)
      ) {
        node.text = chompRight(node.text);
        node.text = removeUnitOfMeasureFromEndOfString(node.text);
      }

      if (
        startsWithWhitespaceUnitOfMeasure((textNodeAfter as Text).text) &&
        getUnitOfMeasureFromEndOfString(node.text) ===
          getUnitOfMeasureFromStartOfString(chompLeft((textNodeAfter as Text).text))
      ) {
        node.text = pipe(chompRight, removeUnitOfMeasureFromEndOfString, chompRight)(node.text);
      }
    }

    /*
      This is unfortunately getting confusing, but the best way I could handle this for now
      This rule needs to only run if it is a right affinity, and theres no textNode before
      if there is a textNode before, it needs to let the bothAffinityHandler run below and handle appending whitespaces
      this is another good example of how we could refactor normalization with the rfc proposal.
    */
    if (
      startsWithContent((textNodeAfter as Text).text) &&
      // if theres not a node before or after, or the affinity is not both, do this, otherwise use the bothAffinityHandler
      (!textEntryBefore || !textEntryAfter || affinity !== 'both') &&
      // do not do this if the node ends in a number and the node after starts with a number
      (!endsWithNumber(node.text) || !startsWithNumber((textNodeAfter as Text).text))
    ) {
      node.text = appendWhitespaceIfNeeded(node.text);
    }

    // if the first character shouldnt have a space after, and there is a whitespace in the node after, remove it
    if (
      !firstCharacterShouldHaveSpaceAfter(node.text.trim()) &&
      lengthOfStartingWhitespace((textNodeAfter as Text).text)
    ) {
      Transforms.delete(editor, {
        at: Editor.start(editor, textNodeAfterPath),
        unit: 'character',
        distance: lengthOfStartingWhitespace((textNodeAfter as Text).text),
      });
    }
  }

  if (textEntryAfterWithContent) {
    const [textNodeAfterWithContent] = textEntryAfterWithContent;

    if (endsWithUnitOfMeasure(chompRight(node.text))) {
      if (
        startsWithUnitOfMeasure((textNodeAfterWithContent as Text).text) &&
        getUnitOfMeasureFromEndOfString(node.text) ===
          getUnitOfMeasureFromStartOfString((textNodeAfterWithContent as Text).text)
      ) {
        node.text = pipe(chompRight, removeUnitOfMeasureFromEndOfString, chompRight)(node.text);
      }

      if (
        startsWithWhitespaceUnitOfMeasure((textNodeAfterWithContent as Text).text) &&
        getUnitOfMeasureFromEndOfString(node.text) ===
          getUnitOfMeasureFromStartOfString(chompLeft((textNodeAfterWithContent as Text).text))
      ) {
        node.text = pipe(chompRight, removeUnitOfMeasureFromEndOfString, chompRight)(node.text);
      }
    }
  }
};

const bothAffinityHandler: TextNormalizationHandler = (
  editor,
  node,
  { at, respectParagraphBoundaries }
) => {
  const textEntryBefore = Editor.previous(editor, {
    at,
    match: (n) => Text.isText(n) && Node.string(n).length > 0,
  });

  const textEntryAfter = Editor.next(editor, {
    at,
    match: (n) => Text.isText(n) && Node.string(n).length > 0,
  });

  let textEntryAfterWithContent = Editor.next(editor, {
    at,
    // $FlowFixMe[incompatible-type]
    // $FlowFixMe[prop-missing]
    match: (n) => allPass([Text.isText, pipe(prop('text'), trim, length, Boolean)])(n),
  });

  // At the end of a paragraph, we don't want to append a space.
  if (
    respectParagraphBoundaries &&
    textEntryAfterWithContent != null &&
    !hasCommonParagraphAncestor(editor, textEntryAfterWithContent[1], at)
  ) {
    textEntryAfterWithContent = undefined;
  }

  if (!textEntryAfter || !textEntryBefore) return;

  const [textNodeAfter, textNodeAfterPath] = textEntryAfter;
  const [textNodeBefore, textNodeBeforePath] = textEntryBefore;

  const nextWordFollowsProperNounRules =
    hasCommonParagraphAncestor(editor, textNodeBeforePath, at) &&
    !endsWithPunctuation(chompRight((textNodeBefore as Text).text)) &&
    startsWithUppercase(chompLeft((textNodeAfter as Text).text));

  // It's important that the comma normalization happens before the whitespace normalization that proceeds it.
  if (
    (endsWithComma(node.text) || endsWithSpacesAfterCharacter(node.text)) &&
    (!isAcronym(chompLeft((textNodeAfter as Text).text)) || !nextWordFollowsProperNounRules) &&
    at[0] === textNodeAfterPath[0]
  ) {
    Editor.withoutNormalizing(editor, () => {
      const [letterToUncapitalize, letterToUncapitalizeIndex] = firstLetterOfFirstWord(
        (textNodeAfter as Text).text
      );
      if (letterToUncapitalizeIndex > -1) {
        const pointRef = Editor.pointRef(
          editor,
          {
            path: textNodeAfterPath,
            offset: letterToUncapitalizeIndex,
          },
          { affinity: 'forward' }
        );

        Transforms.insertText(editor, letterToUncapitalize.toLowerCase(), {
          at: getPointRefSafe(pointRef),
        });
        Transforms.delete(editor, {
          at: getPointRefSafe(pointRef),
          distance: 1,
          unit: 'character',
        });

        pointRef.unref();
      }
    });
  }

  if (
    startsWithComma(node.text) &&
    !isAcronym(stripLeadingComma(node.text)) &&
    !isAnatomicalLocation(stripLeadingComma(node.text)) &&
    !startsWithProperNoun(stripLeadingComma(node.text))
  ) {
    node.text = uncapitalizeFirstWord(node.text);
  }

  if (
    textEntryAfterWithContent != null &&
    startsWithContent((textNodeAfter as Text).text) &&
    firstCharacterShouldHaveSpaceAfter(node.text.trim()) &&
    // if the node before and after isnt numbers, ex : 10|20, add a space
    (!startsWithNumber((textNodeAfter as Text).text) ||
      (!endsWithNumber((textNodeBefore as Text)?.text) && !endsWithNumber(node.text)))
  ) {
    node.text = appendWhitespaceIfNeeded(node.text);
  }
};

const hasLeftAffinity = (affinity: NormalizeTextAffinity) => ['left', 'both'].includes(affinity);
const hasRightAffinity = (affinity: NormalizeTextAffinity) => ['right', 'both'].includes(affinity);
const hasBothAffinity = (affinity: NormalizeTextAffinity) => ['both'].includes(affinity);

/**
 * Normalizes a text node at a given path. Assumes the pass is already split!
 *
 * @see normalizeTextNodeAtPath
 */
export const normalizeTextNodeAtPath = (
  editor: Editor,
  textNodeToNormalize: Text,
  {
    at,
    affinity = 'both',
    respectParagraphBoundariesLeft = true,
    respectParagraphBoundariesRight = true,
  }: {
    at: Path;
    affinity?: NormalizeTextAffinity;
    respectParagraphBoundariesLeft?: boolean;
    respectParagraphBoundariesRight?: boolean;
  }
): Text => {
  const node: Text = { ...textNodeToNormalize };

  // If the text node is empty, there is nothing to normalize which has not already been normalized
  if (node.text === '') return node;

  Editor.withoutNormalizing(editor, () => {
    if (hasLeftAffinity(affinity)) {
      leftAffinityHandler(editor, node, {
        at,
        affinity,
        respectParagraphBoundaries: respectParagraphBoundariesLeft,
      });
    }

    if (hasRightAffinity(affinity)) {
      rightAffinityHandler(editor, node, {
        at,
        affinity,
        respectParagraphBoundaries: respectParagraphBoundariesRight,
      });
    }

    if (hasBothAffinity(affinity)) {
      bothAffinityHandler(editor, node, {
        at,
        affinity,
        respectParagraphBoundaries: respectParagraphBoundariesRight,
      });
    }

    innerTextHandler(node);
  });

  return node;
};

const innerTextHandler = (node: Text) => {
  if (containsEndOfSentencePunctuation(node.text)) {
    node.text = capitalizeWordsAfterPunctuation(node.text);
  }
};

import { useEffect } from 'react';
import analytics from 'modules/analytics';
import { globalContext } from 'modules/analytics/constants';
import { useSplitClient } from './useSplitClient';
import { FFDefaults } from '../featureFlags.generated';

/**
 * NOTE: uses Suspense, make sure to handle it accordingly
 */
export function useSplitFlag<T = string>(
  flagName: string,
  fallback?: string,
  castTo?: (arg1: string) => T
): T {
  const client = useSplitClient();
  const fallbackValue = fallback != null ? fallback : FFDefaults[flagName];

  const flag: string = client != null ? client.getTreatment(flagName) : fallbackValue;

  useEffect(() => {
    analytics.addContext(globalContext.workspace.featureFlag(flagName), fallbackValue);
  }, [flagName, fallbackValue]);

  if (flag === 'control') {
    return castTo ? castTo(fallbackValue) : fallbackValue;
  }

  // @ts-expect-error [EN-7967] - TS2322 - Type 'string | T' is not assignable to type 'T'.
  return castTo ? castTo(flag) : flag;
}

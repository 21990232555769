import {
  ReportStatus,
  discardReportState,
  discardReportStateDefault,
  reportStatusState,
} from '../domains/reporter/Reporter/state';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useCurrentCaseReport } from './useCurrentCaseReport';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useToasterDispatch } from '../common/ui/Toaster/Toaster';
import {
  CHANGE_WORKLIST_ITEM_STATUS,
  RESET_AND_RELEASE_WORKLIST_ITEM,
} from '../modules/Apollo/queries';
import { useMutation } from '@apollo/client';
import { useCurrentCase, useCurrentCaseId } from './useCurrentCase';
import { usePrevious } from 'react-use';
import { logger } from 'modules/logger';
import { worklistUpdateBc } from '../domains/reporter/Reporter/Fields/useCountdown';
import { PAGE_TYPES } from '../utils/pageTypes';
import type { ReportStatuses } from '../domains/reporter/Reporter/state';
import { focusEvent } from 'domains/extension/extensionEventCreators';
import { dispatchExtensionEvent } from '../domains/extension/extensionEventCreators';
import { useWorklistAutoLoad } from './useWorklistAutoLoad';
import { useManagePatientJacket } from './useManagePatientJacket';
import { useResetReviewedStudiesByDate } from '../domains/viewer/ViewportDre/modules/imaging/useTrackViewedSlices';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';
import { WorklistItemStatus } from 'generated/graphql';
import { ToastProps } from 'common/ui/Toaster/Toast';

type UseDiscardReportReturnType = {
  handleDiscardReport: (options?: {
    onSuccess?: () => void;
    onError?: () => void;
  }) => Promise<void>;
  handleUndoDiscardReport: () => Promise<void>;
  handleOpenDiscardReportModal: () => void;
  handleCloseDiscardReportModal: () => void;
};

export const useDiscardReport = (): UseDiscardReportReturnType => {
  const { currentCaseReport: worklistItem, refetch: refetchCurrentCase } = useCurrentCaseReport();
  const [discardReportOptions, setDiscardReportOptions] = useRecoilState(discardReportState);
  const toastKey = useRef('');
  const { enqueueOrUpdateToast, closeToast } = useToasterDispatch();
  const [resetAndReleaseWorklistItem] = useMutation(RESET_AND_RELEASE_WORKLIST_ITEM);
  const [changeWorklistItemStatus] = useMutation(CHANGE_WORKLIST_ITEM_STATUS);
  const { refreshCase } = useCurrentCase();
  const currentCaseId = useCurrentCaseId();
  const prevCaseId = usePrevious(currentCaseId);
  const reportStatus = useRecoilValue<ReportStatuses>(reportStatusState);
  const resetReviewedStudiesByDate = useResetReviewedStudiesByDate();
  const { readNextCase, isWorklistAutoLoadEnabled } = useWorklistAutoLoad();
  const { managePatientJacket } = useManagePatientJacket();
  const [isLockClaimAddendumEnabled] = useFeatureFlagEnabled(FF.LOCK_CLAIM_ADDENDUM);

  const previousWorklistItemClaimedBy = usePrevious(worklistItem?.claimedBy?.id);

  const resetDiscardReportOptions = useCallback(() => {
    setDiscardReportOptions(discardReportStateDefault);
    closeToast(toastKey.current);
  }, [setDiscardReportOptions, closeToast]);

  const isActionInFlight = useMemo(() => {
    return (
      (
        [
          ReportStatus.Submitting,
          ReportStatus.ProvisionalSubmit,
          ReportStatus.ProvisionalAddendumSubmit,
          ReportStatus.AddendumSubmitting,
        ] as ReportStatuses[]
      ).includes(reportStatus) || discardReportOptions.discarding === true
    );
  }, [discardReportOptions.discarding, reportStatus]);

  useEffect(() => {
    // if the user has selected a new case to look at after discarding previous case,
    // reset discardReportOptions
    // or if the worklist item was previously unclaimed and is now claimed and the
    // discard report options match, reset the discard report options
    if (
      worklistItem != null &&
      discardReportOptions.worklistItemSmid != null &&
      (worklistItem.smid !== discardReportOptions.worklistItemSmid ||
        (previousWorklistItemClaimedBy == null &&
          worklistItem?.claimedBy?.id != null &&
          worklistItem?.smid === discardReportOptions.worklistItemSmid))
    ) {
      resetDiscardReportOptions();
    }
  }, [
    discardReportOptions.worklistItemSmid,
    resetDiscardReportOptions,
    worklistItem,
    previousWorklistItemClaimedBy,
  ]);

  const handleUndoDiscardReport = useCallback(async () => {
    if (worklistItem == null) {
      return;
    }

    logger.info(`[useDiscardReport] Discard report flow has been (undone) by user.`, {
      reportSmid: worklistItem.smid,
    });

    closeToast(toastKey.current);

    await changeWorklistItemStatus({
      variables: {
        smid: worklistItem.smid,
        claim: true,
      },
      onCompleted: async () => {
        // Ensure the worklist item is updated so we have the correct claimedBy and autoload template
        await refetchCurrentCase?.();
        const toastMessage = `Report for ${worklistItem.patientName ?? 'Unknown Name'}, ${
          worklistItem.studyDescription ?? 'Unknown Study Description'
        } (ACC: ${worklistItem.accessionNumber ?? 'Unknown'}) has been reclaimed.`;

        enqueueOrUpdateToast(toastMessage, toastKey.current, {
          icon: 'greenCheck',
          severity: 'default',
          yOffset: 45,
        });

        resetDiscardReportOptions();

        worklistUpdateBc.postMessage(worklistItem.smid);
      },
      onError: (e) => {
        closeToast(toastKey.current);

        enqueueOrUpdateToast(
          'Report could not be reclaimed due to server error.',
          toastKey.current,
          {
            icon: 'redWarning',
            yOffset: 45,
          }
        );

        logger.error(
          `[useDiscardReport] Error occurred when reclaiming report: ${e.message}`,
          {
            reportSmid: worklistItem.smid,
          },
          e
        );
      },
    });
  }, [
    changeWorklistItemStatus,
    closeToast,
    enqueueOrUpdateToast,
    refetchCurrentCase,
    resetDiscardReportOptions,
    worklistItem,
  ]);

  const handleDiscardReport = useCallback(
    async ({
      onSuccess,
      onError,
    }: {
      onSuccess?: () => void;
      onError?: () => void;
    } = {}) => {
      if (worklistItem == null || isActionInFlight) {
        return;
      }

      logger.info(`[useDiscardReport] Discard report flow has been (started) by user.`, {
        reportSmid: worklistItem.smid,
      });

      setDiscardReportOptions((prev) => ({ ...prev, discarding: true, worklistItemSmid: null }));

      const defaultToastMessage = `Report for ${worklistItem.patientName ?? 'Unknown Name'}, ${
        worklistItem.studyDescription ?? 'Unknown Study Description'
      } (ACC: ${worklistItem.accessionNumber ?? 'Unknown'}) has been discarded.`;

      let toastMessage = defaultToastMessage;

      if (
        !(
          isLockClaimAddendumEnabled &&
          worklistItem.status === WorklistItemStatus.InProgressAddendum
        )
      ) {
        logger.info(`[useDiscardReport] Calling reset and releasing on the worklist item`, {
          reportSmid: worklistItem.smid,
        });
        await resetAndReleaseWorklistItem({
          variables: {
            smid: worklistItem.smid,
          },
          onCompleted: async () => {
            await refreshCase();

            let nextCaseSmid: string | null = null;
            let toastOptions: {
              icon: 'greenCheck' | 'redWarning';
              severity: 'default' | 'error';
              actionColor: string;
              yOffset: number;
              autoHideDuration?: number;
              actionName?: string;
              actionHandler?: () => Promise<void>;
            } = {
              icon: 'greenCheck',
              severity: 'default',
              actionColor: 'default',
              yOffset: 45,
              ...(isWorklistAutoLoadEnabled
                ? { autoHideDuration: 8000 }
                : {
                    actionName: 'RECLAIM EXAM',
                    actionHandler: handleUndoDiscardReport,
                  }),
            };

            if (isWorklistAutoLoadEnabled) {
              if (currentCaseId != null) {
                logger.info(
                  `[useDiscardReport] Attempting to autoLoad next case after discarding worklistItem ${currentCaseId}`
                );
              }
              try {
                nextCaseSmid = await readNextCase();
                if (nextCaseSmid == null) {
                  toastMessage = `${defaultToastMessage} No more exams available to autoload.`;
                }
              } catch (error: any) {
                toastMessage = `${defaultToastMessage} ${error.message}`;
                toastOptions = {
                  ...toastOptions,
                  icon: 'redWarning',
                };
              }
            }

            toastKey.current = enqueueOrUpdateToast(
              toastMessage,
              null,
              toastOptions as unknown as ToastProps
            );

            setDiscardReportOptions({
              discarding: false,
              open: false,
              worklistItemSmid: worklistItem.smid,
              error: null,
            });

            // Refetch to ensure the worklist item is updated so claimed by is null
            refetchCurrentCase != null && refetchCurrentCase();

            worklistUpdateBc.postMessage(worklistItem.smid);

            // ensures any deeplinks are reset in user's environment
            resetReviewedStudiesByDate();

            onSuccess && onSuccess();

            managePatientJacket({ nextCaseSmid });
          },
          onError: (e) => {
            const errorMessage = e.message.includes('400')
              ? 'Report could not be discarded due to server error.'
              : e.message;
            setDiscardReportOptions((prev) => ({
              ...prev,
              discarding: false,
              error: errorMessage,
            }));

            logger.error(
              `[useDiscardReport] Error occurred when discarding report: ${e.message}`,
              {
                reportSmid: worklistItem.smid,
              },
              e
            );

            onError && onError();
          },
        });
      }

      logger.info(`[useDiscardReport] Discard report flow has been (completed)`, {
        reportSmid: worklistItem.smid,
      });
    },
    [
      worklistItem,
      isActionInFlight,
      setDiscardReportOptions,
      isLockClaimAddendumEnabled,
      resetAndReleaseWorklistItem,
      refreshCase,
      isWorklistAutoLoadEnabled,
      handleUndoDiscardReport,
      enqueueOrUpdateToast,
      refetchCurrentCase,
      resetReviewedStudiesByDate,
      managePatientJacket,
      currentCaseId,
      readNextCase,
    ]
  );

  const handleOpenDiscardReportModal = useCallback(() => {
    if (worklistItem == null) {
      return;
    }

    setDiscardReportOptions({
      discarding: false,
      open: true,
      worklistItemSmid: null,
      error: null,
    });

    if (window.location.pathname.includes(PAGE_TYPES.REPORTER)) {
      dispatchExtensionEvent(focusEvent());
    }
  }, [setDiscardReportOptions, worklistItem]);

  const handleCloseDiscardReportModal = useCallback(() => {
    setDiscardReportOptions({
      discarding: false,
      open: false,
      worklistItemSmid: null,
      error: null,
    });
  }, [setDiscardReportOptions]);

  useEffect(() => {
    if (currentCaseId !== prevCaseId && prevCaseId != null) {
      handleCloseDiscardReportModal();
    }
  }, [currentCaseId, discardReportOptions.open, handleCloseDiscardReportModal, prevCaseId]);

  return useMemo(
    () => ({
      handleDiscardReport,
      handleUndoDiscardReport,
      handleOpenDiscardReportModal,
      handleCloseDiscardReportModal,
    }),
    [
      handleCloseDiscardReportModal,
      handleDiscardReport,
      handleOpenDiscardReportModal,
      handleUndoDiscardReport,
    ]
  );
};

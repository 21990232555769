import { useState, useEffect } from 'react';
import validatePassword from 'utils/validatePassword';
import { equals } from 'ramda';

type PasswordValidations = {
  hasLength: boolean;
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasNumber: boolean;
  hasSpecialChar: boolean;
};

type PasswordValidationResult = [
  string,
  (arg1: string) => void,
  (arg1: string) => void,
  PasswordValidations,
  boolean,
  boolean,
];

export const usePasswordValidation = (): PasswordValidationResult => {
  const [passwordValidations, setPasswordValidations] = useState<PasswordValidations>({
    hasLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isPasswordMatching, setIsPasswordMatching] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    const setAllPasswordValidations = (validation: boolean) => {
      if (!Object.values(passwordValidations).every((e) => e === validation)) {
        setPasswordValidations({
          hasLength: validation,
          hasUpperCase: validation,
          hasLowerCase: validation,
          hasNumber: validation,
          hasSpecialChar: validation,
        });
      }
    };

    if (password === '') {
      setAllPasswordValidations(false);
      setIsDisabled(true);
    } else {
      const validations: Array<string> = validatePassword(password);
      if (!!validations.length && validations.length === Object.keys(passwordValidations).length) {
        setAllPasswordValidations(true);

        setIsPasswordMatching(password === confirmPassword);
        setIsDisabled(password !== confirmPassword);
      } else {
        const newPasswordValidations: PasswordValidations = Object.keys(
          passwordValidations
        ).reduce<PasswordValidations>(
          (carryPasswordValidations: PasswordValidations, key: string) => ({
            ...carryPasswordValidations,
            [key]: validations.includes(key),
          }),
          {
            hasLength: false,
            hasUpperCase: false,
            hasLowerCase: false,
            hasNumber: false,
            hasSpecialChar: false,
          }
        );

        if (!equals(passwordValidations, newPasswordValidations)) {
          setPasswordValidations(newPasswordValidations);
        }

        if (password === confirmPassword) {
          setIsPasswordMatching(true);
        } else {
          setIsPasswordMatching(false);
        }

        setIsDisabled(true);
      }
    }
    if (password !== confirmPassword) {
      setIsPasswordMatching(false);
      setIsDisabled(true);
    } else {
      setIsPasswordMatching(true);
    }
  }, [password, confirmPassword, passwordValidations]);

  return [
    password,
    setPassword,
    setConfirmPassword,
    passwordValidations,
    isPasswordMatching,
    isDisabled,
  ];
};

import type { EditorPlugin } from '../../types';
import { BoldEditable } from './components';
import { deserializeBold } from './deserializeBold';
import { onKeyDownBold } from './onKeyDownBold';
import { getRenderLeaf } from '../../utils';
import { BOLD_PLUGIN_ID } from './types';
import type { BoldPluginOptions, BoldPluginPropertyOptions } from './types';
import { MarkButton } from '../../components';
import FormatBoldIcon from '@material-ui/icons/FormatBold';

export const DEFAULT_BOLD_HOTKEY = 'mod+b';

export const BoldPlugin = ({
  component = BoldEditable,
  pluginName = 'Bold',
  hotkey = DEFAULT_BOLD_HOTKEY,
  icon = FormatBoldIcon,
  ...rest
}: BoldPluginOptions = {}): EditorPlugin => {
  const options: BoldPluginPropertyOptions = {
    pluginID: BOLD_PLUGIN_ID,
    pluginName,
    hotkey,
    component,
    icon,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    renderLeaf: getRenderLeaf(options),
    deserialize: deserializeBold(options),
    onKeyDown: onKeyDownBold(options),
    hoveringToolbarConfig: () => [
      {
        pluginID: options.pluginID,
        toolbarButton: (
          <MarkButton
            icon={options.icon}
            pluginID={options.pluginID}
            pluginName={options.pluginName}
          />
        ),
      },
    ],
  };
};

import { useState, useEffect, useRef } from 'react';

import { useCombobox } from 'downshift';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';
import Input from './Input';
import { usePopper } from 'react-popper';
import Colors from 'styles/colors';
import { maxSize, applyMaxSize, sameWidth } from 'utils/popperModifiers';
import { transparentize } from 'color2k';
import { equals } from 'ramda';

import useInfiniteScroll from 'react-infinite-scroll-hook';
import { NOOP } from 'config/constants';

type LiProps = {
  $disabled?: boolean;
  $highlighted?: boolean;
};

const Li = styled.li`
  padding: 5px 15px;
  color: ${(props: LiProps) => (props.$disabled === true ? Colors.gray6 : undefined)};
  background-color: ${(props: LiProps) =>
    props.$highlighted === true ? Colors.mainBlueHover : 'transparent'};
  transition: background-color 0.2s ease-in-out;
  cursor: default;
`;

export type Change<Item = string> = {
  highlightedIndex: number;
  inputValue: string;
  isOpen: boolean;
  selectedItem: Item | null | undefined;
  type: string;
};

type DropdownComboboxProps<Item = string> = {
  items: ReadonlyArray<Item>;
  onFilter?: (arg1: string) => (arg1: Item) => boolean;
  className?: string;
  placeholder?: string;
  onInputValueChange?: (arg1: Change<Item>) => void;
  selectedItem?: Item;
  onSelectedItemChange?: (arg1: Change<Item>) => void;
  renderItem?: (item: Item, index: number) => React.ReactElement;
  itemToString?: (arg1: Item) => string;
  renderInput?: (arg1: {
    getToggleButtonProps: () => JSX.LibraryManagedAttributes<'div', React.ComponentProps<'div'>> & {
      size: string;
      variant: string;
    };
    getInputProps: () => JSX.LibraryManagedAttributes<'div', React.ComponentProps<'div'>>;
  }) => React.ReactElement;
  multiple?: boolean;
  matchWidth?: boolean;
  ['data-testid']?: string;
  // The following properties are necessary if you want to support
  // lazy loading of new items when the bottom of the list is reached
  onLoadMore?: () => void;
  hasNextPage?: boolean;
  loading?: boolean;
};

const DropdownCombobox = <Item extends unknown>({
  items,

  onFilter = (inputValue: string) => (item: Item) => {
    if (typeof item !== 'string') {
      throw new Error(
        `DropdownCombobox default 'onFilter' logic expects items to be strings, but ${typeof item} was provided.`
      );
    }
    return item.toLowerCase().startsWith(inputValue.toLowerCase());
  },

  className,
  placeholder,
  onInputValueChange,
  renderItem: propRenderItem,
  itemToString,
  renderInput,
  multiple = false,
  matchWidth = true,
  'data-testid': dataTestid,
  onLoadMore = NOOP,
  hasNextPage = false,
  loading = false,
  ...props
}: DropdownComboboxProps<Item>): React.ReactElement => {
  const renderItem = propRenderItem
    ? propRenderItem
    : (item: Item, index: number) => {
        if (typeof item !== 'string') {
          if (!itemToString) {
            throw new Error(
              `DropdownCombobox default 'renderItem' logic expects items to be strings, but ${typeof item} was provided. Please supply an "itemToString" prop to transform the item to a string.`
            );
          }
          return itemToString(item);
        }
        return item;
      };
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const referenceElementRef = useRef<HTMLElement | null | undefined>(null);
  const popperElementRef = useRef<HTMLElement | null | undefined>(null);

  const [value, setValue] = useState('');
  const [sentryRef] = useInfiniteScroll({
    onLoadMore,
    loading,
    hasNextPage,
    rootMargin: '0px 0px 40px 0px',
  });

  const {
    styles: { popper: popperStyle },
    attributes: { popper: popperAttributes },
    update,
  } = usePopper(referenceElement, popperElement, {
    modifiers: [
      maxSize,
      applyMaxSize,
      // @ts-expect-error [EN-7967] - TS2322 - Type '{ readonly name: "sameWidth"; readonly enabled: true; readonly options: { readonly narrowerPopper: false; }; readonly phase: "beforeWrite"; readonly requires: readonly ["computeStyles"]; readonly fn: ({ state, options }: { ...; }) => void; readonly effect: ({ state, options }: { ...; }) => void; } | { ...; }' is not assignable to type 'Partial<Modifier<"maxSize", object>> | Partial<Modifier<"applyMaxSize", object>> | Partial<Modifier<"sameWidth", object>>'.
      matchWidth ? sameWidth : { ...sameWidth, options: { narrowerPopper: true } },
    ],
  });

  const [inputItems, setInputItems] = useState(items);
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getLabelProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    // The following exposes all the options documented here:
    // https://github.com/downshift-js/downshift/tree/master/src/hooks/useCombobox#advanced-props
    ...props,
    // @ts-expect-error [EN-7967] - TS4104 - The type 'readonly Item[]' is 'readonly' and cannot be assigned to the mutable type 'Item[]'.
    items: inputItems,
    ...(multiple ? { selectedItem: null } : {}),
    stateReducer: (state, { type, changes }) => {
      if (multiple) {
        return {
          ...changes,
          isOpen:
            type === useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem
              ? changes.isOpen
              : type !== useCombobox.stateChangeTypes.InputBlur,
          inputValue:
            type === useCombobox.stateChangeTypes.ItemClick ||
            type === useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem
              ? state.inputValue
              : changes.inputValue,
        };
      } else {
        return changes;
      }
    },
    onInputValueChange: (changes) => {
      setInputItems(items.filter(onFilter(changes.inputValue)));
      // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'UseComboboxStateChange<Item>' is not assignable to parameter of type 'Change<Item>'.
      onInputValueChange && onInputValueChange(changes);
    },
  });

  useEffect(() => {
    setReferenceElement(referenceElementRef.current);
    setPopperElement(popperElementRef.current);
    update?.();
  }, [isOpen, update]);

  useEffect(() => {
    if (!equals(inputItems, items)) {
      setInputItems(items);
      update?.();
    }
  }, [items, inputItems, update]);

  return (
    <>
      {/* @ts-expect-error [EN-7967] - TS2322 - Type '{ children: ReactElement<any, string | JSXElementConstructor<any>> | Element; id: string; htmlFor: string; ref: MutableRefObject<HTMLElement>; className: string; "data-testid": string; css: string; }' is not assignable to type 'DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>'. */}
      <div
        data-testid={dataTestid}
        css="display: inline-flex; min-width: 0;"
        // @ts-expect-error [EN-7967] - TS2322 - Type 'MutableRefObject<HTMLElement>' is not assignable to type 'LegacyRef<HTMLLabelElement> & MutableRefObject<HTMLElement>'.
        {...getLabelProps({ ref: referenceElementRef, className })}
      >
        {renderInput ? (
          renderInput({ getToggleButtonProps, getInputProps })
        ) : (
          <Input
            css="flex: 1;"
            icon={
              <span css="display: contents" {...getToggleButtonProps()}>
                <SearchIcon css="margin-left: -2px;" />
              </span>
            }
            {...getInputProps({
              placeholder,
              value,
              onChange: (evt) => setValue(evt.target.value),
            })}
            aria-label="toggle menu"
          />
        )}
      </div>
      {/* @ts-expect-error [EN-7967] - TS2322 - Type '{ children: Element; id: string; role: "listbox"; 'aria-labelledby': string; onMouseLeave: MouseEventHandler<Element>; ref: MutableRefObject<HTMLElement>; style: CSSProperties; css: string; }' is not assignable to type 'DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement>'. */}
      <ul
        css={`
          display: flex;
          list-style: none;
          padding: 0;
          z-index: 1;
          margin: 0;
        `}
        {...getMenuProps({
          ref: popperElementRef,
          style: popperStyle,
          ...popperAttributes,
        })}
      >
        {isOpen && (
          <div
            css={`
              flex: 1;
              min-height: 0;
              margin-bottom: 10px;
              background-color: ${Colors.gray2};
              border-radius: 3px;
              box-shadow: 2px 2px 4px 0 ${transparentize(Colors.gray1, 0.6)};
              padding: 10px 0;
              overflow: auto;
            `}
          >
            {inputItems.length > 0 ? (
              inputItems.map((item, index) => (
                <Li
                  $highlighted={highlightedIndex === index}
                  key={index}
                  {...getItemProps({ item, index })}
                >
                  {renderItem(item, index)}
                </Li>
              ))
            ) : !loading ? (
              <Li $disabled key={-1}>
                No results found.
              </Li>
            ) : null}
            {hasNextPage && (
              <Li $disabled key="loading" data-testid="loading">
                Loading...
              </Li>
            )}
            <div ref={sentryRef} />
          </div>
        )}
      </ul>
    </>
  );
};

export default DropdownCombobox;

export type EditorFormProps = {
  testIdPrefix: string;
  children: React.ReactNode;
  containerCss?: string;
  onSubmit: (arg1: Event) => Promise<void>;
};

export const EditorForm = ({
  testIdPrefix,
  children,
  containerCss,
  onSubmit,
}: EditorFormProps): React.ReactElement => {
  return (
    <form
      data-testid={`${testIdPrefix}-form`}
      // @ts-expect-error [EN-7967] - TS2322 - Type '(arg1: Event) => Promise<void>' is not assignable to type 'FormEventHandler<HTMLFormElement>'.
      onSubmit={onSubmit}
      css={`
        min-width: 0;
        flex: 1 1 auto;
        height: 100%;
        isolation: isolate;
        display: flex;
        flex-flow: column nowrap;
        ${containerCss || ''}
      `}
    >
      {children}
    </form>
  );
};

import { useCallback, useEffect, useMemo } from 'react';
import {
  MY_QUEUE,
  PENDING,
  IN_PROGRESS,
  RECENTLY_READ,
  ARCHIVE,
  WORKLIST_VIEW,
} from 'config/constants';
import type { SortColumn, SortOrder } from 'generated/graphql';
import { useSearchParams } from 'react-router-dom';
import {
  getWorklistURLParams,
  validateSortColumns,
  validateSortOrders,
} from '../domains/worklist/WorklistFilters/utils';
import type { WorklistSurfaceType } from '../domains/worklist/Worklist/types';

type URLFilters = {
  [filterKey: string]: string;
};

export type MutateWorklistURLArguments = {
  sortColumn?: Array<SortColumn>;
  sortOrder?: Array<SortOrder> | null | undefined;
  filters?: URLFilters;
  tab?: string | null | undefined;
};

type WorklistURL = {
  sortColumn: Array<SortColumn>;
  sortOrder: Array<SortOrder>;
  tab: null | WorklistSurfaceType;
  filters: URLFilters | null | undefined;
  mutate: (args: MutateWorklistURLArguments) => void;
};

const VALID_TABS: ReadonlyArray<WorklistSurfaceType> = [
  MY_QUEUE,
  PENDING,
  IN_PROGRESS,
  RECENTLY_READ,
  ARCHIVE,
  WORKLIST_VIEW,
];

const validateTab = (tab: string, defaultTab: WorklistSurfaceType | null = PENDING) => {
  return VALID_TABS.find((t) => t === tab) ?? defaultTab ?? PENDING;
};

export const useWorklistURL = ({
  disabled = false,
  isReporter = false,
  initialSortOrder,
  initialSortColumn,
  initialSurface,
}: {
  disabled?: boolean;
  isReporter?: boolean;
  initialSortOrder: Array<SortOrder>;
  initialSortColumn: Array<SortColumn>;
  initialSurface: WorklistSurfaceType;
}): WorklistURL => {
  const [searchParams, setSearchParams] = useSearchParams();

  const sortedSearchParams = useMemo(() => {
    const params = new URLSearchParams(searchParams);
    params.sort();
    return params;
  }, [searchParams]);

  /**
   * Construct function to update the URL params using the given values
   * - if no new value is supplied for a particular param, existing val is left in URL
   * - `filters` params are cleared when `tab` param changes
   *
   * Note- until the react-table v8 upgrade (VX-622) and we can switch to onSortingChange handlers
   * (https://tanstack.com/table/latest/docs/api/features/sorting#onsortingchange),
   * this function needs to maintain a single identity; otherwise useEffect sorting handlers may
   * trigger each other in infinite loops.
   */
  const mutate = useCallback(
    ({
      tab: updatedTab,
      sortColumn: updatedColumns,
      sortOrder: updatedOrders,
      filters: updatedFilters,
    }: MutateWorklistURLArguments) => {
      if (disabled) {
        return;
      }

      setSearchParams((existingParams) => {
        existingParams.sort();
        const updatedParams = new URLSearchParams(existingParams.toString());
        const {
          surface: currentTab,
          sortColumn: currentSortColumn,
          sortOrder: currentSortOrder,
          filters: currentFilters,
        } = getWorklistURLParams(existingParams);

        // If sortColumn and sortOrder are not the same length, the values will
        // be corrected by tanstack table
        if (updatedColumns != null) {
          const validatedColumns = validateSortColumns(updatedColumns, currentSortColumn);
          updatedParams.set('sortColumn', validatedColumns.join(','));
        }

        if (updatedOrders != null) {
          const validatedOrders = validateSortOrders(updatedOrders, currentSortOrder);
          updatedParams.set('sortOrder', validatedOrders.join(','));
        }

        updatedTab != null && updatedParams.set('tab', validateTab(updatedTab, currentTab));

        if (updatedFilters != null) {
          if (currentFilters != null) {
            // Remove any existing filters before applying new ones
            Object.keys(currentFilters).forEach((key) => {
              updatedParams.delete(`filters[${key}]`);
            });
          }

          Object.keys(updatedFilters).forEach((key) => {
            updatedParams.set(`filters[${key}]`, updatedFilters[key]);
          });
        }

        updatedParams.sort();

        return existingParams.toString() !== updatedParams.toString()
          ? updatedParams
          : existingParams;
      });
    },
    [disabled, setSearchParams]
  );

  // Read current param values from URL; use default vals when params not present
  const {
    surface: tab,
    sortColumn,
    sortOrder,
    filters,
  } = useMemo(() => getWorklistURLParams(searchParams), [searchParams]);

  // Set some default vals
  useEffect(() => {
    if (
      // we don't want to set default search param values in the reporter window
      !isReporter &&
      sortedSearchParams.get('sortColumn') == null &&
      sortedSearchParams.get('sortOrder') == null
    ) {
      mutate({
        sortColumn: initialSortColumn,
        sortOrder: initialSortOrder,
        tab: initialSurface,
      });
    }
  }, [sortedSearchParams, mutate, isReporter, initialSortColumn, initialSortOrder, initialSurface]);

  return {
    sortColumn: sortColumn,
    sortOrder: sortOrder,
    filters,
    tab,
    mutate,
  };
};

import { Text } from 'domains/reporter/RichTextEditor/core';
import type { MarkPluginID } from '../plugins/types';

import styled from 'styled-components';
import type { RendererRenderText } from './types';
import { forEachObjIndexed } from 'ramda';
import { getSourceTextColor } from '../plugins/textSourceStyling/utils';

export type TextComponentProps = Readonly<{
  node: Text;
  renderText: RendererRenderText;
}>;

const StyledText = styled.span`
  /* Make sure empty content still renders line height of element */
  &::before {
    content: '\u{200b}'; // unicode zero width space character
  }

  ${({ source }: { source: string }) => {
    return `color: ${getSourceTextColor(source)};`;
  }}
`;

export const TextComponent = ({
  node: { text, ...marks },
  renderText,
}: TextComponentProps): React.ReactElement => {
  // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
  let children: React.ReactNode = <StyledText>{text}</StyledText>;

  forEachObjIndexed((_val: any, mark: MarkPluginID) => {
    children = renderText({ mark, children });
  }, marks);

  return children;
};

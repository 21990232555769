import assignIn from 'lodash/assignIn';

export const acronym = (string?: string | null): string | null => {
  if (string == null) {
    return '';
  }

  const matches = string.match(/\b(\w)/g);
  return matches ? matches.join('') : null;
};

export const copyGetter = <T extends any>(obj: T): T => {
  return assignIn({}, obj);
};

import { useCallback, useEffect, useMemo } from 'react';
import { usePermission } from 'react-use';
import { atom, useSetRecoilState, useRecoilValue } from 'recoil';
import type { RecoilState } from 'recoil';
import {
  broadcastChannelSynchronizerEffect,
  localStoragePersisterEffect,
} from 'utils/recoilEffects';
import { SRNA_PERSIST_TOKEN } from 'config/constants';
import { useMediaDevices } from '../useMediaDevices';
import { useQuery } from '@apollo/client';
import { GET_TOOL_PREFERENCES } from 'modules/Apollo/queries';
import type { GetToolPreferencesQuery, GetToolPreferencesQueryVariables } from 'generated/graphql';

export type MicrophoneState = {
  audioInputDeviceId: string;
  audioInputDeviceLabel: string;
  userDescribedDictaphone: boolean;
};

const defaultValue = {
  audioInputDeviceId: 'default',
  audioInputDeviceLabel: '',
  userDescribedDictaphone: false,
} as const;

export const microphoneState: RecoilState<MicrophoneState> = atom({
  key: `${SRNA_PERSIST_TOKEN}microphoneState`,
  default: defaultValue,
  effects: [broadcastChannelSynchronizerEffect(), localStoragePersisterEffect()],
});

/**
 * If the microphone permission changes, the media devices list won't
 * update. This hook triggers an event that will refresh the list.
 *
 * @see https://github.com/streamich/react-use/issues/1318
 */
export function useRefreshMediaDevices() {
  const micPermission = usePermission({ name: 'microphone' });
  useEffect(() => {
    const { mediaDevices } = navigator;
    if (mediaDevices == null) return;

    mediaDevices
      .getUserMedia({
        audio: true,
      })
      .finally(() => {
        mediaDevices.dispatchEvent(new Event('devicechange'));
      });
  }, [micPermission]);
}

export type MediaDeviceInfo = {
  deviceId: string;
  groupId: string;
  kind: string;
  label: string;
};

export type UseMicrophoneReturn = {
  audioInputDeviceId: string;
  audioInputDeviceLabel: string;
  audioInputDevices: Array<MediaDeviceInfo>;
  setAudioInputDevice: (audioInputDeviceId: string, audioInputDeviceLabel: string) => void;
  isDictaphone: boolean;
  userDescribedDictaphone: boolean;
  setUserDescribedDictaphone: (userDescribedDictaphone: boolean) => void;
};

export function useMicrophone(): UseMicrophoneReturn {
  const { devices, loaded } = useMediaDevices();
  const { audioInputDeviceId, userDescribedDictaphone } = useRecoilValue(microphoneState);
  const setMicrophoneState = useSetRecoilState(microphoneState);

  // We want to know whether or not the active input device is a dictaphone.
  // The first time the user plugs in a dictaphone, they need to tell us. But after that, it is persisted in the user's config state.
  // Long term, we can centralize a list of known dictaphones and their calibrations and hopefully avoid some of tedium of setup.
  const { data } = useQuery<GetToolPreferencesQuery, GetToolPreferencesQueryVariables>(
    GET_TOOL_PREFERENCES
  );
  const dictaphoneSettings = data?.toolPreferences?.dictaphone;
  const audioInputDevices = useMemo(() => {
    return devices?.filter((device: MediaDeviceInfo) => device.kind === 'audioinput') ?? [];
  }, [devices]);
  const audioInputDevice = useMemo(
    () => audioInputDevices.find((device) => device.deviceId === audioInputDeviceId),
    [audioInputDevices, audioInputDeviceId]
  );
  const audioInputDeviceLabel = audioInputDevice?.label ?? '';
  const DEFAULT_INPUT_PREFIX = 'Default - ';
  const cleanedInputLabel = audioInputDeviceLabel.split(DEFAULT_INPUT_PREFIX).slice(-1)[0];
  const cleanedSettingsLabel =
    dictaphoneSettings != null
      ? dictaphoneSettings.deviceLabel.split(DEFAULT_INPUT_PREFIX).slice(-1)[0]
      : '';
  const deviceLabelMatchesDictaphone =
    audioInputDeviceLabel !== '' && cleanedInputLabel === cleanedSettingsLabel;
  const isDictaphone = deviceLabelMatchesDictaphone || userDescribedDictaphone;

  const setAudioInputDevice = useCallback(
    (audioInputDeviceId: string, audioInputDeviceLabel: string) => {
      setMicrophoneState((v) => ({
        ...v,
        audioInputDeviceId,
        audioInputDeviceLabel,
      }));
    },
    [setMicrophoneState]
  );

  const setUserDescribedDictaphone = useCallback(
    (userDescribedDictaphone: boolean) => {
      setMicrophoneState((v) => ({
        ...v,
        userDescribedDictaphone,
      }));
    },
    [setMicrophoneState]
  );

  // reset the device id back to the default value if the stored preference cannot be found
  useEffect(() => {
    if (loaded === false) return;

    const foundDevice = audioInputDevices.some(({ deviceId }) => deviceId === audioInputDeviceId);
    if (foundDevice !== true && audioInputDeviceId !== defaultValue.audioInputDeviceId) {
      setAudioInputDevice(defaultValue.audioInputDeviceId, defaultValue.audioInputDeviceLabel);
    }
  }, [loaded, audioInputDeviceId, audioInputDevices, setAudioInputDevice]);

  return useMemo(
    () => ({
      audioInputDeviceId,
      audioInputDeviceLabel,
      audioInputDevices,
      setAudioInputDevice,
      userDescribedDictaphone,
      setUserDescribedDictaphone,
      isDictaphone,
    }),
    [
      audioInputDeviceId,
      audioInputDeviceLabel,
      audioInputDevices,
      isDictaphone,
      userDescribedDictaphone,
      setUserDescribedDictaphone,
      setAudioInputDevice,
    ]
  );
}

import vtkCamera from '@kitware/vtk.js/Rendering/Core/Camera';
import type { IView } from 'react-vtk-js';
import { sleep } from 'utils/sleep';

type Status = 'pending' | 'error' | 'success';

/**
 * This waits for the camera to be available and suspends the component
 * until it is. It will throw an error if the camera is not available
 * after the specified number of attempts.
 * The error can be caught and handled by the parent component with an
 * ErrorBoundary.
 */
export function useViewCamera(view?: IView | null): vtkCamera {
  let result;
  let error;
  let status: Status = 'pending';
  const maxAttempts = 20;
  const timeout = 100;

  const promise = new Promise(async (resolve, reject: (error?: any) => void) => {
    const findRenderer = (view?: IView | null) => {
      return view?.getRenderer()?.get();
    };

    let attempts = 0;
    let renderer;
    while (view != null && renderer == null && attempts < maxAttempts) {
      renderer = findRenderer(view);
      attempts++;

      if (renderer == null) {
        await sleep(timeout);
      }
    }

    if (renderer == null) {
      status = 'error';
      error = new Error(
        `No renderer found after ${maxAttempts} attempts (${maxAttempts * timeout}ms)`
      );
      return reject(error);
    }

    const camera = renderer.getActiveCamera();
    if (camera == null) {
      error = new Error('No camera found in renderer');
      status = 'error';
      return reject(error);
    }

    result = camera;
    status = 'success';
    return resolve(result);
  });

  switch (status) {
    case 'pending':
      throw promise;
    // @ts-expect-error [EN-7967] - TS2678 - Type '"error"' is not comparable to type '"pending"'.
    case 'error':
      throw error;
    // @ts-expect-error [EN-7967] - TS2678 - Type '"success"' is not comparable to type '"pending"'.
    case 'success':
      if (result == null) {
        throw new Error('Unreachable error: camera should always be defined at this point');
      }
      return result;
    default:
      throw new Error(`Unknown status: ${status}`);
  }
}

import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { layoutsForAllCasesState, DEFAULT_LAYOUT } from './viewerLoaderState';
import type { Layout, Layouts } from './viewerLoaderState';
import { useViewerMetadata } from './useViewerMetadata';
import { useHangingProtocol } from 'domains/viewer/HangingProtocol/useHangingProtocol';
import { EMPTY } from 'config/constants';
import { pick } from 'ramda';
import { useCurrentCaseId } from 'hooks/useCurrentCase';
import { isPreviewingHangingProtocolState } from '../../HangingProtocol/state';

type UseLayouts = {
  layoutForWindow: Layout | null | undefined;
  layoutsForCase: Layouts;
  setLayoutsForCase: (arg1: Layouts) => void;
  setLayoutForWindow: (arg1: Layout) => void;
  loading: boolean;
};

export const useLayouts = (): UseLayouts => {
  const [allLayouts, setAllLayouts] = useRecoilState(layoutsForAllCasesState);
  const isPreviewingHangingProtocol = useRecoilValue(isPreviewingHangingProtocolState);
  const { openWindowsIds, windowId } = useViewerMetadata();
  const currentCaseId = useCurrentCaseId();
  const { hangingProtocolLayouts, loadingHangingProtocol } = useHangingProtocol();

  const currentLayoutsForCase: Layouts =
    !isPreviewingHangingProtocol && currentCaseId != null
      ? allLayouts[currentCaseId]
      : EMPTY.OBJECT;

  const setLayoutsForCase = useCallback(
    (updatedLayouts: Layouts) => {
      if (currentCaseId == null) return;

      setAllLayouts((currentAllLayouts) => ({
        ...currentAllLayouts,
        [currentCaseId]: updatedLayouts,
      }));
    },
    [currentCaseId, setAllLayouts]
  );

  const setLayoutForWindow = useCallback(
    (layout: Layout) => {
      if (currentCaseId == null || windowId == null) return;

      setAllLayouts((currentAllLayouts) => ({
        ...currentAllLayouts,
        [currentCaseId]: { ...currentAllLayouts[currentCaseId], [windowId]: layout },
      }));
    },
    [currentCaseId, setAllLayouts, windowId]
  );

  // Filter out layouts for any windows which are not currently open.
  // This lets us 'save' the previously used layout and also give an accurate current layout.
  const layoutsForCase: Layouts = useMemo(() => {
    const defaultLayouts: Layouts = Object.fromEntries(
      openWindowsIds.map((windowId) => [windowId, DEFAULT_LAYOUT])
    );

    return pick(openWindowsIds, {
      ...defaultLayouts,
      ...hangingProtocolLayouts,
      ...currentLayoutsForCase,
    });
  }, [currentLayoutsForCase, hangingProtocolLayouts, openWindowsIds]);

  const layoutForWindow = layoutsForCase[windowId];

  return {
    layoutForWindow,
    setLayoutForWindow,
    layoutsForCase,
    setLayoutsForCase,
    loading: loadingHangingProtocol,
  };
};

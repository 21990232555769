import type { EditorElement, EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';
import type { ReportPicklist, ReportPicklistOption } from 'generated/graphql';
import type { ComponentType } from 'react';
import type { AllReporterVariant } from '../../hooks/useReporter';

export const PICKLIST_PLUGIN_ID: 'picklist' = 'picklist';
export type PicklistPluginID = typeof PICKLIST_PLUGIN_ID;

export type PicklistPluginElement = Readonly<
  EditorElement & {
    picklistID: string;
    type: PicklistPluginID;
    name?: string;
  }
>;

export type ReportPicklistBeforeSubmissionOptions = ReportPicklistOption;

export type ReportPicklistBeforeSubmission = Readonly<{
  id: string;
  options: ReportPicklistBeforeSubmissionOptions[];
}>;

export type PicklistItem = ReportPicklist | ReportPicklist | ReportPicklistBeforeSubmission;

export type PicklistPluginOptions = Readonly<
  EditorPluginOptions & {
    // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
    component?: ComponentType<any>;
    showPicklistOptionsInEditor?: boolean;
    onPicklistInsert?: (props: { readonly picklistID: string }) => void;
    onPicklistCopy?: (props: { readonly picklistID: string }) => PicklistItem;
    onPicklistRemove?: (props: { readonly picklistID: string }) => void;
    onPicklistUpdate?: (props: {
      readonly picklistID: string;
      options: ReportPicklistBeforeSubmissionOptions[];
    }) => void;
    disableKeyDown?: boolean;
    variant?: AllReporterVariant;
  }
>;

export type PicklistPluginPropertyOptions = Readonly<
  EditorPluginPropertyOptions &
    PicklistPluginOptions & {
      showPicklistOptionsInEditor: boolean;
      pluginID: PicklistPluginID;
      // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
      component: ComponentType<any>;
      pluginName: string;
    }
>;

// TODO(mwood23) Move this to PicklistSelector once it's converted to flow
export type NodeSelectorState = Readonly<{
  isOpen: boolean;
  moveItemUp: () => void;
  moveItemDown: () => void;
  selectPicklistItem: () => void;
  resetActiveSelectedPicklistOption: () => void;
}>;

import type { EditorPlugin } from '../../types';
import { REQUIRED_FIELDS_PLUGIN_ID } from './types';
import type { RequiredFieldsPluginOptions, RequiredFieldsPluginPropertyOptions } from './types';
import { enhanceEditorStateRequiredFields } from './enhanceEditorStateRequiredFields';

export const RequiredFieldsPlugin = ({
  pluginName = 'Required Fields',
  ...rest
}: RequiredFieldsPluginOptions = {}): EditorPlugin => {
  const options: RequiredFieldsPluginPropertyOptions = {
    pluginID: REQUIRED_FIELDS_PLUGIN_ID,
    pluginName,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    enhanceEditorState: enhanceEditorStateRequiredFields(options),
  };
};

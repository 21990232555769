import { sendEvent, NAMESPACES } from 'modules/EventsManager';
import { extractHIDDeviceInfo, getDeviceValue, setHIDDeviceFilters } from './utils';
import type { HIDNavigator, HIDDevice, HIDDeviceFilter, HIDInputReportEvent } from 'w3c-web-hid';
import { logger } from 'modules/logger';
import { ValueOf } from 'types';

/**
 * Currently, we only support the Philips SpeechMike III.
 * `productId` is not required, but is used to control which devices are connected.
 * There will be future support for other devices, and added to the filters.
 */
export const DEFAULT_DEVICE_FILTERS = [
  { vendorId: 2321, productId: 3100 }, // Philips SpeechMike III (SMP 3700)
  { vendorId: 2321, productId: 3101 }, // Philips SpeechMike III Premium Air (wireless)
  { vendorId: 2321, productId: 3102 }, // Philips SpeechMike III SpeechOne
  // TODO: keep this for future support
  // { vendorId: 1364, productId: 100 }, // Nuance PowerMic 4
];

export const BASE_DEVICE_FILTER_OPTIONS = {
  usagePage: 65440,
};

export const HID_MANAGER_STATES = Object.freeze({
  LOADING: 'loading',
  DONE: 'done',
});

export type HIDManagerStates = typeof HID_MANAGER_STATES;

const SET_EVENT_MODE = 0x0d;
// const HID = 0; // event mode
// const BROWSER = 2; // browser mode
const REPORT_ID = 0;

export interface IHIDDeviceManager {
  navigator: HIDNavigator;
  checkForValidHIDDevice: (options: {
    audioInputLabel?: string;
    isWebHIDEnabled?: boolean;
  }) => Promise<void>;
  closeDevices: (devices: HIDDevice[]) => Promise<void>;
  getDevices: () => Promise<HIDDevice[]>;
  getDevicesByOpenState: (
    label: string
  ) => Promise<{ devicesToOpen: HIDDevice[]; devicesToClose: HIDDevice[] }>;
  isWebHIDSupported: () => boolean;
  openDevices: (devices: HIDDevice[]) => Promise<void>;
  openWebHIDConnectModal: (label: string) => void;
  pairDevice: (label: string) => Promise<void>;
  requestDevices: (filters?: HIDDeviceFilter[]) => Promise<HIDDevice[]>;
}

class HIDDeviceManager implements IHIDDeviceManager {
  navigator = window.navigator as HIDNavigator;
  lastValue: number = 0;
  state: ValueOf<HIDManagerStates> = HID_MANAGER_STATES.DONE;
  // eslint-disable-next-line no-use-before-define
  static instance: HIDDeviceManager | null = null;

  constructor() {
    if (HIDDeviceManager.instance) {
      throw new Error(
        'Cannot instantiate more than one HIDDeviceManager, use HIDDeviceManager.instance'
      );
    }
    this.navigator.hid.addEventListener('disconnect', this.handleDisconnect);
    HIDDeviceManager.instance = this;
  }

  checkForValidHIDDevice = async (options: {
    audioInputLabel?: string;
    isWebHIDEnabled?: boolean;
  }): Promise<void> => {
    if (!this.isWebHIDSupported()) {
      return;
    }

    const { audioInputLabel, isWebHIDEnabled } = options;
    // If already paired, open selected device and close others
    const { devicesToOpen, devicesToClose } = await this.getDevicesByOpenState(audioInputLabel);
    if (devicesToClose.length > 0) {
      await this.closeDevices(devicesToClose, isWebHIDEnabled);
      if (devicesToOpen.length === 0) {
        return;
      }
    }
    if (devicesToOpen.length > 0) {
      await this.openDevices(devicesToOpen, isWebHIDEnabled);
      return;
    }

    const deviceInfo = extractHIDDeviceInfo(audioInputLabel);
    if (deviceInfo == null) {
      return;
    }

    if (isWebHIDEnabled) {
      // If not paired, open pairing modal (need to request permission for access to HID device)
      this.openWebHIDConnectModal(audioInputLabel);
    }
  };

  onPairingCancel = (): void => {
    logger.warn('[WebHID] WebHID device was not selected for pairing.');
    sendEvent(
      NAMESPACES.WEBHID_ERROR,
      'Unable to connect your device. Please try again by navigating to the Microphone Settings and re-selecting the device.'
    );
  };

  setManagerState = (state: ValueOf<HIDManagerStates>): void => {
    this.state = state;
  };

  pairDevice = async (audioDeviceLabel: string, isWebHIDEnabled?: boolean): Promise<void> => {
    const deviceInfo = extractHIDDeviceInfo(audioDeviceLabel);
    if (deviceInfo == null) {
      return;
    }
    const deviceFilters = setHIDDeviceFilters([deviceInfo]);
    // Open browser prompt to pair selected device
    logger.info('[webHID] Requesting to pair WebHID device, with the filters:', deviceFilters);
    const requestedDevice = await this.requestDevices(deviceFilters);

    if (requestedDevice?.length === 0) {
      this.onPairingCancel();
      return;
    }

    logger.info('[WebHID] Device successfully paired:', requestedDevice[0].productName);
    await this.openDevices(requestedDevice, isWebHIDEnabled);
  };

  isWebHIDSupported = (): boolean => {
    if ('hid' in this.navigator) {
      return true;
    }
    logger.error('[WebHID] WebHID API is not supported in this browser.');
    return false;
  };

  getDevicesByOpenState = async (
    audioDeviceLabel?: string
  ): Promise<{ devicesToOpen: HIDDevice[]; devicesToClose: HIDDevice[] }> => {
    const devicesToOpen = [];
    const devicesToClose = [];
    const devices = await this.getDevices();
    const matchingDeviceInfo = extractHIDDeviceInfo(audioDeviceLabel);
    if (matchingDeviceInfo == null) {
      logger.warn('[WebHID] Selected audio device is not a valid WebHID device.');
      return { devicesToOpen, devicesToClose: devices };
    }

    for (const device of devices) {
      if (
        device.vendorId === matchingDeviceInfo.vendorId &&
        device.productId === matchingDeviceInfo.productId
      ) {
        devicesToOpen.push(device);
      } else {
        devicesToClose.push(device);
      }
    }
    return { devicesToOpen, devicesToClose };
  };

  openDevices = async (devices: HIDDevice[], isWebHIDEnabled?: boolean): Promise<void> => {
    for (const device of devices) {
      if (device.opened) {
        continue;
      }
      await this.openHIDDevice(device);
    }
  };

  closeDevices = async (devices: HIDDevice[], isWebHIDEnabled?: boolean): Promise<void> => {
    for (const device of devices) {
      if (!device.opened) {
        continue;
      }
      await this.closeHIDDevice(device);
    }
  };

  openHIDDevice = async (device: HIDDevice): Promise<void> => {
    try {
      if (device.oninputreport == null) {
        await device.open();
        device.oninputreport = this.handleInputReportEvent;
        logger.info('[WebHID] Device successfully opened:', device);
      }
    } catch (error) {
      // Ignore duplicate calls to open the same device
      if (error.message === 'An operation that changes the device state is in progress.') {
        return;
      }
      logger.warn('[WebHID] Failed to open HID device:', error);
    }
  };

  closeHIDDevice = async (device: HIDDevice): Promise<void> => {
    try {
      if (device.oninputreport != null) {
        await device.close();
        device.oninputreport = null;
        logger.info('[WebHID] Device successfully closed:', device);
      }
    } catch (error) {
      logger.warn('[WebHID] Failed to close HID device:', error);
    }
  };

  openWebHIDConnectModal = (label: string): void => {
    sendEvent(NAMESPACES.OPEN_WEBHID_CONNECT_MODAL, label);
  };

  getDevices = async (): Promise<HIDDevice[]> => {
    if (!this.isWebHIDSupported()) {
      return [];
    }
    return await this.navigator.hid.getDevices();
  };

  /**
   * Prompts the user to pair an HID device.
   */
  requestDevices = async (deviceFilters?: HIDDeviceFilter[]): Promise<HIDDevice[]> => {
    if (!this.isWebHIDSupported()) {
      return [];
    }
    const requestDeviceOptions = { filters: setHIDDeviceFilters(deviceFilters) };
    return await this.navigator.hid.requestDevice(requestDeviceOptions);
  };

  sendCommand = async (device: HIDDevice, input: number[]): Promise<void> => {
    const data = new Uint8Array(input);
    await device.sendReport(REPORT_ID, data);
  };

  setButtonMode = async (devices: HIDDevice[], buttonMode: number): Promise<void> => {
    const input = [SET_EVENT_MODE, 0, 0, 0, 0, 0, 0, 0, buttonMode];
    for (const device of devices) {
      await this.sendCommand(device, input);
    }
  };

  handleInputReportEvent = (event: HIDInputReportEvent): void => {
    const val = getDeviceValue(event);
    if (this.lastValue !== val) {
      const value = val || this.lastValue;
      const pressed = val !== 0;
      const eventData = { value, pressed };
      logger.info(`[WebHID] HID input report event:`, eventData);
      sendEvent(NAMESPACES.INPUTREPORT_EVENT, eventData);
      this.lastValue = val;
    }
  };

  handleDisconnect = ({ device }: { device: HIDDevice }): void => {
    logger.info('[WebHID] Device disconnected:', device);
    device.removeEventListener('inputreport', this.handleInputReportEvent);
  };
}

const hidDeviceManager: HIDDeviceManager = new HIDDeviceManager();

export default hidDeviceManager;

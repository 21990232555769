import { Range, Path } from 'domains/reporter/RichTextEditor/core';

export const sentinelPath = (name: string): Path => {
  return name.split('').map((ch) => -ch.charCodeAt(0));
};

export const sentinelSelection = (name: string): Range => {
  const path = sentinelPath(name);
  return {
    anchor: { path, offset: 0 },
    focus: { path, offset: 0 },
  };
};

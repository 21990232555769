import { logger } from 'modules/logger';

import { isNumber } from 'utils/isNumber';

export const PUNCTUATIONS = '.?!:';
const WHITESPACES = '^\\S\\r\\n';
const PROPER_NOUNS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Siemens',
  'Toshiba',
  'Philips',
  'Samsung',
  'Hitachi',
  'Agfa',
  'Konica',
  'Hologic',
  'Hoffa',
].join('|');
const ALWAYS_CAPITALIZED = ['I'].join('|');
const UNITS_OF_MEASURE = 'cm,mm,nm,kg,mg,ml';
const UPPER_CASE_LETTERS = 'A-Z';
const LOWER_CASE_LETTERS = 'a-z';
const NUMBERS = '0-9%'; // treat % as part of a number
const LETTERS = `${UPPER_CASE_LETTERS}${LOWER_CASE_LETTERS}`;
const LETTERS_AND_NUMBERS = `${LETTERS}${NUMBERS}`;
export const NO_SPACES_BEFORE = '%,;:)/';
export const NO_SPACES_AFTER = '(';
const SPACES_AFTER = ';:)';
export const FORWARD_SLASH = '/';
export const LEFT_PARENTHESIS = '(';
export const END_OF_SENTENCE_PUNCTUATION = '.?!';
export const NO_CAPITALIZATION_AFTER = ',;:()/-';

const lettersAndNumberRegex = new RegExp(`[${LETTERS_AND_NUMBERS}]`);

export const capitalizeFirstWord = (s: string): string => {
  const firstWordsLetterIndex = s.search(lettersAndNumberRegex);
  if (firstWordsLetterIndex === -1) return s;

  return `${s.substr(0, firstWordsLetterIndex + 1).toUpperCase()}${s.substring(
    firstWordsLetterIndex + 1
  )}`;
};
export const uncapitalizeFirstWord = (s: string): string => {
  const firstWordsLetterIndex = s.search(lettersAndNumberRegex);
  if (firstWordsLetterIndex === -1) return s;

  return `${s.substr(0, firstWordsLetterIndex + 1).toLowerCase()}${s.substring(
    firstWordsLetterIndex + 1
  )}`;
};
export const maybeUncapitalizeFirstLetterAfterTarget = (s: string, target: string): string => {
  const regex = new RegExp(`\\${target}(\\s*)([${LETTERS_AND_NUMBERS}]+)`, 'g');

  return s.replace(regex, (match, whitespaces, wordAfterTarget) => {
    // Check directly if `wordAfterTarget` is a proper noun or always capitalized an acronym or anatomical part
    if (
      PROPER_NOUNS.split('|').includes(wordAfterTarget) ||
      ALWAYS_CAPITALIZED.split('|').includes(wordAfterTarget) ||
      isAcronym(wordAfterTarget) ||
      isAnatomicalLocation(wordAfterTarget)
    ) {
      // If it's a proper noun or always capitalized or an acronym or anatomical part, return it as is
      return `${target}${whitespaces}${wordAfterTarget}`;
    } else {
      // If not, uncapitalize the first letter of the word
      return `${target}${whitespaces}${wordAfterTarget.charAt(0).toLowerCase()}${wordAfterTarget.slice(1)}`;
    }
  });
};
export const removeUnitOfMeasureFromEndOfString = (s: string): string => {
  return s.replace(new RegExp(`\\b[${UNITS_OF_MEASURE}]+\\b$`, 'i'), '');
};
export const toLower = (s: string): string => s.toLowerCase();
export const endsWith =
  (matcher: string): ((s: string) => boolean) =>
  (s) =>
    s.endsWith(matcher);
export const startsWith =
  (
    matcher: string,
    options?: {
      caseInsensitive: boolean;
    }
  ): ((s: string) => boolean) =>
  (s) => {
    if (options?.caseInsensitive === true) {
      return s.toUpperCase().startsWith(matcher.toUpperCase());
    }
    return s.startsWith(matcher);
  };
export const chompRight = (s: string): string => {
  return s.trimEnd();
};
export const chompLeft = (s: string): string => {
  return s.trimLeft();
};

export const stripLeadingComma = (s: string): string => {
  return s.replace(/^,/, '').trimLeft();
};

export const isPunctuation = (s: string): boolean => new RegExp(`[${PUNCTUATIONS}]`).test(s);
export const isFirstWordAllCaps = (s: string): boolean =>
  new RegExp(`^\\b[${UPPER_CASE_LETTERS}]+\\b`).test(s);
export const isAcronym = (s: string): boolean => {
  const match = s.match(new RegExp(`^\\b[${UPPER_CASE_LETTERS}]+\\b`));
  // Acronym: an abbreviation formed from the initial letters of other words and pronounced as a word
  // Must be more than one letter!
  return match != null && match[0].length > 1;
};
export const isAnatomicalLocation = (s: string): boolean =>
  new RegExp(`[${LETTERS}][${NUMBERS}]+`).test(s);

export const startsWithContent = (s: string): boolean =>
  new RegExp(`^[${LETTERS_AND_NUMBERS}\\(]`).test(s);
export const startsWithProperNoun = (s: string): boolean =>
  new RegExp(`^(${PROPER_NOUNS})`, 'i').test(s);
export const startsWithWhitespace = (s: string): boolean => new RegExp(`^[${WHITESPACES}]`).test(s);
export const startsWithWhitespaceContent = (s: string): boolean =>
  new RegExp(`^[${WHITESPACES}][${LETTERS_AND_NUMBERS}\\(]`).test(s);
export const startsWithPunctuation = (s: string): boolean =>
  new RegExp(`^[${PUNCTUATIONS}]`).test(s);
export const startsWithWhitespacePunctuation = (s: string): boolean =>
  new RegExp(`^[${WHITESPACES}][${PUNCTUATIONS}]`).test(s);
export const startsWithUppercase = (s: string): boolean => /^[A-Z]/.test(s);
export const startsWithUnitOfMeasure = (s: string): boolean =>
  new RegExp(`^\\b[${UNITS_OF_MEASURE}]+\\b`, 'i').test(s);
export const startsWithWhitespaceUnitOfMeasure = (s: string): boolean =>
  new RegExp(`^[${WHITESPACES}]\\b[${UNITS_OF_MEASURE}]+\\b`, 'i').test(s);
export const startsWithComma = (s: string): boolean => /^[,]/.test(s);
export const startsWithOneOrMultipleWhitespacePunctuation = (s: string): boolean =>
  new RegExp(`^[${WHITESPACES}]{1,}[${PUNCTUATIONS}]`).test(s);
export const startsWithNewline = (s: string): boolean => /^\n/.test(s);
export const containsEndOfSentencePunctuation = (s: string): boolean =>
  new RegExp(`[${END_OF_SENTENCE_PUNCTUATION}]`).test(s);
export const endsWithPunctuation = (s: string): boolean => new RegExp(`[${PUNCTUATIONS}]$`).test(s);
export const endsWithComma = (s: string): boolean => /[,]\s*$/.test(s); // ", " and "," are both valid
export const endsWithPunctuationWhitespace = (s: string): boolean =>
  new RegExp(`[${PUNCTUATIONS}][${WHITESPACES}]$`).test(s);
export const endsWithUnitOfMeasure = (s: string): boolean =>
  new RegExp(`\\b[${UNITS_OF_MEASURE}]+\\b$`, 'i').test(s);
export const endsWithWhitespace = (s: string): boolean =>
  new RegExp(`[${WHITESPACES}]$`, 'i').test(s);
export const endsWithWhitespaceUnitOfMeasure = (s: string): boolean =>
  new RegExp(`[${WHITESPACES}]\\b[${UNITS_OF_MEASURE}]+\\b$`, 'i').test(s);
export const endsWithPunctuationOneOrMultipleWhitespace = (s: string): boolean =>
  new RegExp(`[${PUNCTUATIONS}][${WHITESPACES}]+$`).test(s);
export const endsWithEndOfSentencePunctuationOneOrMultipleWhitespace = (s: string): boolean =>
  new RegExp(`[${END_OF_SENTENCE_PUNCTUATION}][${WHITESPACES}]+$`).test(s);
export const endsWithContent = (s: string): boolean =>
  new RegExp(`[${LETTERS_AND_NUMBERS}]$`).test(s);
export const endsWithContentWhitespace = (s: string): boolean =>
  new RegExp(`[${LETTERS_AND_NUMBERS}][${WHITESPACES}]$`).test(s);
export const endsWithNoCapitalizationAfterCharacter = (s: string): boolean => {
  const regex = new RegExp(`[${NO_CAPITALIZATION_AFTER}][${WHITESPACES}]*$`);
  return regex.test(s);
};
export const endsWithSpacesAfterCharacter = (s: string): boolean => {
  return new RegExp(`[${SPACES_AFTER}]$[${WHITESPACES}]*$`).test(s);
};
export const endsWithPunctuationWhitespaceLeftParenthesis = (s: string): boolean =>
  new RegExp(`[${PUNCTUATIONS}][${WHITESPACES}][${LEFT_PARENTHESIS}][${WHITESPACES}]*$`).test(s);
export const endsWithNewline = (s: string): boolean => /\n$/.test(s);

export const getUnitOfMeasureFromStartOfString = (s: string): string | null => {
  const result = new RegExp(`^\\b[${UNITS_OF_MEASURE}]+\\b`).exec(s.trim().toLowerCase());

  if (result == null) return null;

  return result[0];
};
export const getUnitOfMeasureFromEndOfString = (s: string): string | null => {
  const result = new RegExp(`\\b[${UNITS_OF_MEASURE}]+\\b$`).exec(s.trim().toLowerCase());

  if (result == null) return null;

  return result[0];
};

export const removeFirstCharacterOfString = (s: string): string => {
  return s.substr(1, s.length - 1);
};
export const removeLastCharacterOfString = (s: string): string => {
  return s.substr(0, s.length - 1);
};

export const lengthOfStartingWhitespace = (s: string): number => {
  const match = s.match(/^\s+/g);

  return !match || match.length === 0 ? 0 : match[0].length;
};

export const lengthOfEndingWhiteSpace = (s: string): number => {
  const match = s.match(/\s+$/g);

  return !match || match.length === 0 ? 0 : match[0].length;
};

export const stringIncludesOneOf = (str: string, options: string[]): boolean =>
  new RegExp(`(${options.join('|')})`).test(str);

/**
 * Find the last punctuation index in a string. Returns 0 if no punctuation is found
 */
export const findLastIndexOfPunctuation = (str: string): number => {
  const reggie = new RegExp(`[${PUNCTUATIONS}]`, 'g');

  let lastIndex: number = 0;
  while (reggie.exec(str) !== null) {
    lastIndex = reggie.lastIndex;
  }

  return lastIndex;
};

export const appendWhitespaceIfNeeded = (string: string): string => {
  if (endsWithContentWhitespace(string) || string.endsWith('"')) {
    return string;
  }
  return `${string} `;
};

export const prependWhitespaceIfNeeded = (string: string): string => {
  if (startsWithWhitespace(string)) {
    return string;
  }
  return ` ${string}`;
};

export const firstLetterOfFirstWord = (s: string): [string, number] => {
  const firstWordsLetterIndex = s.search(new RegExp(`[${LETTERS_AND_NUMBERS}]`));
  if (firstWordsLetterIndex === -1) {
    logger.error(
      'Cannot get firstLetterOfFirstWordIndex because no letters or numbers were found!',
      `"${s}"`
    );
  }

  return [s[firstWordsLetterIndex], firstWordsLetterIndex];
};

export const startsWithNumber = (s: string): boolean => {
  return isNumber(s[0]);
};

export const endsWithNumber = (s: string): boolean => new RegExp(`[${NUMBERS}]\\s*$`).test(s);

export const endsWithDecimal = (s: string): boolean => {
  return isNumber(s[s.length - 2]) && isPunctuation(s[s.length - 1]);
};

export const firstCharacterShouldHaveSpaceBefore = (s: string): boolean => {
  return !NO_SPACES_BEFORE.split('').includes(s[0]);
};

export const firstCharacterShouldHaveSpaceAfter = (s: string): boolean => {
  return !NO_SPACES_AFTER.split('').includes(s[0]);
};

export const capitalizeWordsAfterPunctuation = (s: string): string => {
  return s.replace(
    new RegExp(`([${END_OF_SENTENCE_PUNCTUATION}] +)([a-z])`, 'g'),
    (match, p1, p2) => p1 + p2.toUpperCase()
  );
};

export const whitespaceRegex: RegExp = new RegExp(`^[${WHITESPACES}]$`);

export const punctuationRegex: RegExp = new RegExp(`^[${PUNCTUATIONS}]+`);

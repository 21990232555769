import { Coordinate } from '@kitware/vtk.js/Rendering/Core/Coordinate/Constants';
import { withCursor } from '../../modules/cursor';
import { withPickerEvent } from '../../modules/usePicker';
import type { WithPickerEventReturn } from '../../modules/usePicker';

export const withCommonPrimitiveHelpers = <T>(
  Component: React.ComponentType<T>
  // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'ComponentType<T>' is not assignable to parameter of type 'ComponentType<T & { cursor?: string; id: string; }>'.
): WithPickerEventReturn<T> => withPickerEvent(withCursor(Component));

export const transformCoordinate = {
  coordinateSystem: Coordinate.WORLD,
} as const;

export function numberToOpacity(number: number): number {
  return Math.max(0.000000000001, Math.min(1, number));
}

import { useCallback } from 'react';
import { Colors } from 'styles';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  broadcastChannelSynchronizerEffect,
  localStoragePersisterEffect,
} from '../utils/recoilEffects';

const activeColorVariants: {
  [key: string]: string;
} = {
  [Colors.study0]: Colors.activeStudy0,
  [Colors.study1]: Colors.activeStudy1,
  [Colors.study2]: Colors.activeStudy2,
  [Colors.study3]: Colors.activeStudy3,
  [Colors.study4]: Colors.activeStudy4,
  [Colors.study5]: Colors.activeStudy5,
  [Colors.study6]: Colors.activeStudy6,
  [Colors.study7]: Colors.activeStudy7,
  [Colors.study8]: Colors.activeStudy8,
  [Colors.study9]: Colors.activeStudy9,
  [Colors.study10]: Colors.activeStudy10,
};

const colorVariants: Array<string> = [
  Colors.study0,
  Colors.study1,
  Colors.study2,
  Colors.study3,
  Colors.study4,
  Colors.study5,
  Colors.study6,
  Colors.study7,
  Colors.study8,
  Colors.study9,
  Colors.study10,
];

export const studyColors = atom<{
  [key: string]: string;
}>({
  key: 'studyColors',
  default: {},
  effects: [broadcastChannelSynchronizerEffect(), localStoragePersisterEffect()],
});

const defaultColor = colorVariants[0];

export const useRegisterStudyColors = (): ((
  /** loadedStudies assumes the first smid is the primary study */
  loadedStudies: string[],
  pendingStudies: string[]
) => void) => {
  const setColors = useSetRecoilState(studyColors);
  const registerStudiesColors = useCallback(
    (loadedStudies: string[], pendingStudies: string[]) => {
      setColors((colors) => {
        if (loadedStudies.length === 0) {
          return colors;
        }

        const storedColors = Object.values(colors);
        const loadedStudiesColors = loadedStudies.reduce<Record<string, any>>(
          (acc, smid, index) => {
            const newColors = [...Object.values(acc), ...storedColors];
            const currentColor = colors[smid] || defaultColor;
            const wasRecentlyLoaded = index !== 0 && currentColor === defaultColor;
            const nextColor = colorVariants.find((color) => !newColors.includes(color));

            return { ...acc, [smid]: wasRecentlyLoaded ? nextColor : currentColor };
          },
          {}
        );

        const pendingStudiesColors = pendingStudies.reduce<Record<string, any>>((acc, smid) => {
          return {
            ...acc,
            [smid]:
              // We don't want to reassign a color if it's already assigned to a loaded study.
              // useStudyColor will return the default color for pending studies.
              colors[smid] != null && colors[smid] !== defaultColor ? colors[smid] : defaultColor,
          };
        }, {});

        return { ...loadedStudiesColors, ...pendingStudiesColors };
      });
    },
    [setColors]
  );

  return registerStudiesColors;
};

export const useStudyColor = (smid?: string | null, isPending?: boolean): [string, string] => {
  const colors = useRecoilValue(studyColors);
  const color = smid != null ? (colors[smid] as string | null | undefined) : null;

  // TODO: Remove isPending validation to allow assigned colors on pending studies. [EN-TBD]
  if (color == null || isPending === true) {
    return [Colors.study0, activeColorVariants[Colors.study0]];
  }

  return [color, activeColorVariants[color]];
};

export const useStudyColors = (): {
  [key: string]: string;
} => {
  const colors = useRecoilValue(studyColors);
  return colors;
};

export const useStudyLabel = (smid?: string | null): string => {
  const colors = useRecoilValue(studyColors);

  const color = smid != null ? (colors[smid] as string | null | undefined) : null;

  if (color == null) {
    return '';
  }

  const index = colorVariants.indexOf(color);

  // If index is 0, label is 'Current'; if > 0, label as 'Prior {index}'
  const label = index === 0 ? 'Current' : `Prior ${index}`;

  return label;
};

type StudyColorInjectedProps = {
  studyColor: {
    color: string;
    activeColor: string;
  };
};
export function withStudyColor<Config>(
  smid: string,
  Component: React.ComponentType<Config & StudyColorInjectedProps>
): React.ComponentType<Config> {
  return function WithStudyColorWrappedComponent(props: Config) {
    const [color, activeColor] = useStudyColor(smid);

    return <Component {...props} studyColor={{ color, activeColor }} />;
  };
}

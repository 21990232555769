import { useState, useEffect } from 'react';
import { useFeatureFlagEnabled, FF } from 'modules/feature-flags';

/**
 * Without this, the RichTextEditor may prematurely mount before all of the feature flags have correctly loaded.
 * This means feature-flagged behaviors that affect the functionality of the RichTextEditor should be added here,
 * and will cause it only to mount when all feature flags are ready to be evaluated.
 */
export const useHaveRichTextEditorFeatureFlagsLoaded = (): boolean => {
  // determines whether the anatomic navigator plugin is loaded or not
  const [, anatomicNavigatorLoading] = useFeatureFlagEnabled(FF.REPORTER_ANATOMIC_NAVIGATOR);

  // determines whether ASR Plex should be used for dictation
  const [, asrPlexLoading] = useFeatureFlagEnabled(FF.REPORTER_ASR_PLEX);

  const [, aiModeLoading] = useFeatureFlagEnabled(FF.REPORTER_AI_MODE);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (anatomicNavigatorLoading === false && asrPlexLoading === false && aiModeLoading === false) {
      setLoaded(true);
    } else {
      setLoaded(false);
    }
  }, [asrPlexLoading, anatomicNavigatorLoading, aiModeLoading]);

  return loaded;
};

import type { Fragment } from '../types/content';

import { Text, Node } from '../core';

export const fragmentToString = (fragment: Node[] | Fragment): string => {
  return fragment
    .map((node) => {
      if (Text.isText(node)) {
        return node.text;
      } else {
        return node.children.map(Node.string).join('');
      }
    })
    .join(' ');
};

/**
 * Provided a QuickJS instance, a string containing the annotation logic,
 * a list of mouse events and the current mouse position,
 * this function will execute the annotation logic and return the configuration
 * for the annotation.
 */
import type { AnnotationStatus } from '../../../AnnotationsManager/annotationCreators';
import type { Vector3 as vec3 } from '@kitware/vtk.js/types';
import type { QuickJSContext, QuickJSRuntime, QuickJSWASMModule } from 'quickjs-emscripten-core';
import { env } from 'config/env';
import { primitiveChecker } from './primitives';
import { assertion, object, number, withDefault, array, bool } from '@recoiljs/refine';
import type { Configuration } from '.';
import { useQuickJS } from 'modules/quick';
import vtkCamera from '@kitware/vtk.js/Rendering/Core/Camera';
import { useMemoCleanup } from 'hooks/useMemoCleanup';

const configurationChecker = object({
  primitives: array(primitiveChecker),
  maxPoints: number(),
  completeOnDoubleClick: withDefault(bool(), false),
});

/**
 * This cache is used to share QuickJS runtimes and contexts between multiple
 * components that use the same annotation logic. This is useful to avoid
 * creating a new runtime and context for each component that shares the same.
 *
 * We are going to cleanup the cache entry when the last component using the
 * annotation logic is unmounted.
 */
const sharedCache = new Map<
  string,
  {
    subscribers: number;
    value: [QuickJSRuntime, QuickJSContext];
  }
>();

// "Should be enough for everyone" -- attributed to B. Gates
const RUNTIME_MEMORY_LIMIT = 1024 * 640;
const RUNTIME_MAX_STACK_SIZE = 1024 * 320;
const RUNTIME_MAX_INTERRUPT_CYCLES = 1024;

function getRuntimeAndContext(
  QuickJs: QuickJSWASMModule,
  annotationLogic: string
): [QuickJSRuntime, QuickJSContext] {
  if (sharedCache.has(annotationLogic)) {
    const entry = sharedCache.get(annotationLogic);
    if (entry) {
      entry.subscribers++;
      return entry.value;
    }
  }

  const runtime = QuickJs.newRuntime();
  runtime.setMemoryLimit(RUNTIME_MEMORY_LIMIT);
  runtime.setMaxStackSize(RUNTIME_MAX_STACK_SIZE);
  // Interrupt computation after 1024 calls to the interrupt handler
  let interruptCycles = 0;
  runtime.setInterruptHandler(() => ++interruptCycles > RUNTIME_MAX_INTERRUPT_CYCLES);

  runtime.setModuleLoader((moduleName) =>
    moduleName === '__internal__module__'
      ? // This prevents the custom module from loading itself
        annotationLogic.replace(/__internal__module__/g, '')
      : 'throw new Error("Module not found")'
  );
  const context = runtime.newContext();

  sharedCache.set(annotationLogic, {
    subscribers: 1,
    value: [runtime, context],
  });

  return [runtime, context];
}

export function useQuickJsContext(annotationLogic: string): QuickJSContext {
  const QuickJs = useQuickJS();

  const context = useMemoCleanup(() => {
    const [runtime, context] = getRuntimeAndContext(QuickJs, annotationLogic);
    return [
      context,
      () => {
        const entry = sharedCache.get(annotationLogic);
        if (entry) {
          entry.subscribers--;

          /**
           * If there are no more subscribers for this annotation logic, we can
           * dispose the runtime and context and remove the entry from the cache.
           */
          if (entry.subscribers === 0) {
            context.dispose();
            runtime.dispose();
            sharedCache.delete(annotationLogic);
          }
        }
      },
    ];
  }, [annotationLogic, QuickJs]);

  return context;
}

export function executeToolAnnotationLogic({
  context,
  annotationLogic,
  points,
  mousePosition,
  camera,
  status,
  label,
}: {
  context: QuickJSContext;
  annotationLogic: string;
  points: ReadonlyArray<vec3>;
  mousePosition: vec3 | null | undefined;
  camera: vtkCamera;
  status: AnnotationStatus;
  label?: string; //TODO: We should support multiple labels for multiple annotations
}): Configuration | null | undefined {
  // console.log is only available in development mode as a convenience for debugging
  // it will fail in production mode
  if (env.NODE_ENV === 'development') {
    const logHandle = context.newFunction('log', (...args) => {
      const nativeArgs = args.map((value) => context.dump(value));
      // eslint-disable-next-line no-console
      console.log('QuickJS:', ...nativeArgs);
    });
    // Partially implement `console` object
    const consoleHandle = context.newObject();
    context.setProp(consoleHandle, 'log', logHandle);
    context.setProp(context.global, 'console', consoleHandle);
    consoleHandle.dispose();
    logHandle.dispose();
  }

  // Add props to global
  context.newObject().consume((vmProps) => {
    // Add points to props
    context.newArray().consume((vmPoints) => {
      (mousePosition ? [...points, mousePosition] : points).forEach((position, index) => {
        context.newArray().consume((vmPoint) => {
          context.newNumber(position[0]).consume((vmX) => context.setProp(vmPoint, 0, vmX));
          context.newNumber(position[1]).consume((vmY) => context.setProp(vmPoint, 1, vmY));
          context.newNumber(position[2]).consume((vmZ) => context.setProp(vmPoint, 2, vmZ));
          context.setProp(vmPoints, index, vmPoint);
        });
      });
      context.setProp(vmProps, 'points', vmPoints);
    });

    // Add status to props
    context.newString(status).consume((vmStatus) => context.setProp(vmProps, 'status', vmStatus));

    // Add optional annotation metadata
    Object.entries({ label }).forEach(([key, value]: [any, any]) => {
      if (typeof value === 'string') {
        context.newString(value).consume((vmValue) => context.setProp(vmProps, key, vmValue));
      }
    });

    // Add camera object to props
    context.newObject().consume((vmCamera) => {
      const cameraDirectionOfProjection = camera.getDirectionOfProjection();
      const cameraViewUp = camera.getViewUp();

      // camera direction
      context.newArray().consume((vmCameraDirectionOfProjection) => {
        cameraDirectionOfProjection.forEach((value, index) => {
          context
            .newNumber(value)
            .consume((vmValue) => context.setProp(vmCameraDirectionOfProjection, index, vmValue));
        });
        context.setProp(vmCamera, 'directionOfProjection', vmCameraDirectionOfProjection);
      });

      // camera view up
      context.newArray().consume((vmCameraViewUp) => {
        cameraViewUp.forEach((value, index) => {
          context
            .newNumber(value)
            .consume((vmValue) => context.setProp(vmCameraViewUp, index, vmValue));
        });
        context.setProp(vmCamera, 'viewUp', vmCameraViewUp);
      });

      context.setProp(vmProps, 'camera', vmCamera);
    });

    context.setProp(context.global, 'props', vmProps);
  });

  const ok = context.evalCode(/*javascript*/ `
    import { drawAnnotation } from '__internal__module__';
    globalThis.result = drawAnnotation(props);
  `);
  context.unwrapResult(ok).dispose();

  const configuration = context
    .getProp(context.global, 'result')
    .consume((value) => assertion<Configuration>(configurationChecker)(context.dump(value)));

  return configuration;
}

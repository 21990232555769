import type { AllReporterVariant } from '../../hooks/useReporter';

import { Editor, Node } from '../../core';
import type { NodeEntry } from '../../core';
import { HeadingLevel } from '../heading/constants';
import { HEADING_PLUGIN_ID } from '../heading/types';
import { Element } from 'slate';

export const getDuplicateHeadingErrorText = (variant: AllReporterVariant): string => {
  switch (variant) {
    case 'report':
      return 'Cannot sign report with duplicate section headers';
    case 'template':
      return 'Cannot save template with duplicate section headers';
    default:
      return 'Cannot have duplicate section headers';
  }
};

export const normalizeText = (text: string): string => {
  return text
    .trim()
    .toLowerCase()
    .replace(/[^\w\s']|_/g, '')
    .replace(/\s+/g, ' ');
};

export const reportHasDuplicateHeadings = (editor: Editor, text?: string): boolean => {
  return (
    Array.from(
      Editor.nodes(editor, {
        at: [],
        match: (n) =>
          Element.isElement(n) &&
          n.type === HEADING_PLUGIN_ID &&
          n.level === HeadingLevel.H1 &&
          Array.from(Node.texts(n)).some(
            (textNodeEntry) =>
              (textNodeEntry[0] as any).headingError === true &&
              (text == null || normalizeText(Node.string(textNodeEntry[0])) === normalizeText(text))
          ),
      })
    ).length > 0
  );
};

export const getHeadingErrorNodesFromParentNode = (root: Node): Array<NodeEntry> => {
  const nodes = Node.nodes(root);
  const headingErrorNodes = [];
  for (const [node] of Array.from(nodes)) {
    if (
      Element.isElement(node) &&
      node.type === HEADING_PLUGIN_ID &&
      Array.from(Node.texts(node)).filter((textNode) => (textNode[0] as any).headingError).length >
        0
    ) {
      headingErrorNodes.push(node);
    }
  }

  return headingErrorNodes;
};

import { useCurrentUser } from 'hooks/useCurrentUser';
import { Spacer, Stack } from '../ui/Layout';
import MFADialog from './MFADialog';
import PasswordDialog from './PasswordDialog';
import Switch from '@material-ui/core/Switch';
import Text from 'common/ui/Text';

import { useEffect, useState } from 'react';

const MultiFactorAuthToggle = ({
  multiFactorAuthEnabled,
  multiFactorAuthLinked,
  setAuthenticatorToken,
  authenticatorToken,
  setSuccess,
}: {
  multiFactorAuthEnabled: boolean;
  multiFactorAuthLinked: boolean;
  setAuthenticatorToken: () => void;
  authenticatorToken: string | null | undefined;
  setSuccess: (arg1: string) => void;
}): React.ReactElement => {
  const { refetch } = useCurrentUser();
  const [showPasswordPrompt, setShowPasswordPrompt] = useState(false);
  const [toggle, setToggle] = useState(multiFactorAuthEnabled && multiFactorAuthLinked);

  const onChange = (e: Event, checked: boolean) => {
    setToggle(checked);
    setShowPasswordPrompt(true);
    setSuccess('');
  };

  useEffect(() => {
    if (toggle && !multiFactorAuthLinked && authenticatorToken == null) {
      setShowPasswordPrompt(true);
    }
  }, [toggle, multiFactorAuthLinked, authenticatorToken]);

  const onSuccess = (): void => {
    setShowPasswordPrompt(false);
    refetch();
  };

  return (
    <Stack vertical>
      <Stack alignY="center">
        <Text>Enable two-factor authentication</Text>
        <Spacer />
        {/* @ts-expect-error [EN-7967] - TS2322 - Type '(e: Event, checked: boolean) => void' is not assignable to type '(event: ChangeEvent<HTMLInputElement>, checked: boolean) => void'. */}
        <Switch checked={toggle} onChange={onChange} data-testid="mfa-toggle" />
      </Stack>
      {multiFactorAuthLinked && multiFactorAuthEnabled ? (
        <MFADialog
          show={showPasswordPrompt}
          onSuccess={onSuccess}
          multiFactorAuthEnabled={toggle}
          onClose={() => {
            setShowPasswordPrompt(false);
            setToggle(!toggle);
          }}
        />
      ) : (
        <PasswordDialog
          show={showPasswordPrompt}
          onSuccess={onSuccess}
          multiFactorAuthEnabled={toggle}
          onClose={() => {
            setShowPasswordPrompt(false);
            setToggle(!toggle);
          }}
          setAuthenticatorToken={setAuthenticatorToken}
        />
      )}
    </Stack>
  );
};

export default MultiFactorAuthToggle;

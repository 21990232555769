import { useMouse } from 'react-use';
import { useContext, useMemo } from 'react';
import { Contexts } from 'react-vtk-js';
import type { Vector3 as vec3 } from '@kitware/vtk.js/types';
import { useViewportId } from './state';
import { useImagingContext, useActiveSlicePlane } from './imaging/ImagingContext';
import vtkPlane from '@kitware/vtk.js/Common/DataModel/Plane';
import { IMAGE_NORMALS } from 'config/constants';
import { make3x3MatrixFromDirection } from '../utils/math';
import { multiply3x3_vect3 } from '@kitware/vtk.js/Common/Core/Math';
import { useActiveSliceImage } from '../modules/useImage';
import { useRecoilCallback } from 'recoil';
import { currentViewTypeAtomFamily } from '../../Viewer/viewerRecoilContext';
import vtkBoundingBox from '@kitware/vtk.js/Common/DataModel/BoundingBox';

export const useMouseToWorldPosition = (): ((arg1: {
  elX: number;
  elY: number;
}) => vec3 | null | undefined) => {
  const viewportId = useViewportId();
  const view = useContext(Contexts.ViewContext);
  const { imagingProvider } = useImagingContext();
  const image = useActiveSliceImage();
  // @ts-expect-error [EN-7967] - TS2339 - Property 'getOpenGLRenderWindow' does not exist on type 'unknown'.
  const openglRenderWindow = view?.getOpenGLRenderWindow()?.get();
  const bounds = openglRenderWindow?.getCanvas().getBoundingClientRect() ?? {
    width: 1,
    height: 1,
  };
  const [canvasWidth, canvasHeight] = openglRenderWindow?.getSize() ?? [0, 0];
  const scaleX = canvasWidth / bounds.width;
  const scaleY = canvasHeight / bounds.height;
  const currentActiveSlicePlane = useActiveSlicePlane();
  // @ts-expect-error [EN-7967] - TS2322 - Type '(args_0: { elX: number; elY: number; }) => vec3' is not assignable to type '(arg1: { elX: number; elY: number; }) => Vector3'.
  return useRecoilCallback(
    ({ snapshot }) =>
      ({ elX, elY }) => {
        // If the x and y mouse position is 0 it means the hook didn't
        // have time to receive the real coordinates so we rather return null
        if (elX === 0 && elY === 0) return null;
        if (imagingProvider == null || image == null) return null;
        // @ts-expect-error [EN-7967] - TS2339 - Property 'getRenderer' does not exist on type 'unknown'.
        const renderer = view?.getRenderer()?.get();
        // @ts-expect-error [EN-7967] - TS2339 - Property 'getOpenGLRenderWindow' does not exist on type 'unknown'.
        const openGLRenderWindow = view?.getOpenGLRenderWindow()?.get();
        if (renderer == null || openGLRenderWindow == null) return null;

        /*
         * our world is represented as a volume
         * displayToWorld point, 0 gives us a point on the near plane on the volume
         * displayToWorld point, 1 gives us a point on the far plane on the volume
         * we create a line from these points and intersect it with the slice to get the point on the slice
         */
        const nearPoint =
          view != null
            ? openGLRenderWindow.displayToWorld(
                scaleX * elX,
                scaleY * (bounds.height - elY),
                0,
                renderer
              )
            : null;
        const farPoint =
          view != null
            ? openGLRenderWindow.displayToWorld(
                scaleX * elX,
                scaleY * (bounds.height - elY),
                1,
                renderer
              )
            : null;

        if (nearPoint == null || farPoint == null) return null;

        /*
         * we create a plane to represent the slice that is drawn in the viewport
         * once we know where our line intersects this plane, we can get the pixel at that point
         * and use our imaging provider to get the world position of that pixel according to the tags
         * this is cumbersome but allows us to store and work with more accurate real world values
         */
        const plane = vtkPlane.newInstance();

        const viewType = snapshot.getLoadable(currentViewTypeAtomFamily(viewportId)).getValue();

        // our position is the top left pixel of the active slice

        const is3dable = imagingProvider.is3Dable();

        let planeNormal, planeOrigin;

        if (is3dable) {
          if (currentActiveSlicePlane == null) return null;

          if (viewType === 'THREE_D_MIP' || viewType === 'THREE_D_DRE') {
            //for volumes we create a plane at the dead center of the volume and intercept the click at that z-depth
            // @ts-expect-error [EN-7967] - TS2339 - Property 'getCamera' does not exist on type 'unknown'.
            planeNormal = view?.getCamera()?.getDirectionOfProjection();
            planeOrigin = vtkBoundingBox.getCenter(image.getBounds());
          } else {
            planeNormal = currentActiveSlicePlane?.sliceNormal;
            planeOrigin = currentActiveSlicePlane.slicePosition;
          }
        } else {
          // for non3dable imaging, our image is always projected onto the first slice of a 3dable volume
          // in order to detect which world space position a user's mouse is in, we need to do the following:
          // project a line through the display
          // calculate the intersection of this line with imaging volume (provider.vtkImage)
          // convert this intersection point to index space
          // convert this index space to world space using the image data of the slice
          planeOrigin = imagingProvider.vtkImage?.indexToWorld([0, 0, 0]);

          //  our direction is the direction of the imaging volume
          const volumeDirectionMatrix = make3x3MatrixFromDirection(
            // @ts-expect-error [EN-7967] - TS2339 - Property 'getDirection' does not exist on type '{ newInstance: (initialValues?: IImageDataInitialValues) => vtkImageData; extend: (publicAPI: object, model: object, initialValues?: IImageDataInitialValues) => void; }'.
            imagingProvider.vtkImage?.getDirection()
          );

          // normalize the direction (3x3 -> 1x3)
          const normalDirectionForViewType = IMAGE_NORMALS[viewType];
          planeNormal = [...normalDirectionForViewType];
          multiply3x3_vect3(volumeDirectionMatrix, planeNormal, planeNormal);
        }

        // set up the plane
        if (planeNormal == null) return null;
        // @ts-expect-error [EN-7967] - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
        plane.setNormal(...planeNormal);
        // @ts-expect-error [EN-7967] - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
        plane.setOrigin(...planeOrigin);
        /*
         * calculate the point at the intersection of our plane and the line defined
         * by the near and far points of the mouse click
         * */
        const pointOnSlice = plane.intersectWithLine(nearPoint, farPoint).x;
        /* we use our imaging volume to transform this point from world space to index space
         * this gives us the pixel coordinates of the point on the slice
         */
        const intersectedPointIndexSpace = imagingProvider.vtkImage.worldToIndex(pointOnSlice);
        /* we use the imageData local to our slice to convert this to a world space point
         * for non3dable volumes, image here will be a single slice imageData with all the
         * information needed to accurately localize a point on that slice
         */
        const intersectedPointWorldSpace = image.indexToWorld(intersectedPointIndexSpace);
        return intersectedPointWorldSpace;
      },
    [
      bounds.height,
      scaleX,
      scaleY,
      view,
      viewportId,
      imagingProvider,
      image,
      currentActiveSlicePlane,
    ]
  );
};

export const useMouseWorldPosition = (enabled: boolean | null = true): vec3 | null | undefined => {
  const view = useContext(Contexts.ViewContext);
  const { elX, elY } = useMouse(
    enabled === true && view != null
      ? // @ts-expect-error [EN-7967] - TS2339 - Property 'getOpenGLRenderWindow' does not exist on type 'unknown'.
        { current: view?.getOpenGLRenderWindow()?.get().getCanvas() }
      : { current: null }
  );
  const mouseToWorldPosition = useMouseToWorldPosition();

  const mouseWorldPosition = useMemo(
    () => mouseToWorldPosition({ elX, elY }),
    [mouseToWorldPosition, elX, elY]
  );
  // If the hook is disabled, we don't want to return any coordinates
  if (enabled === false) return null;

  return mouseWorldPosition;
};

import { WorklistSurface } from '../types';

// TODO: We will fetch this from the backend later on
export const PUBLIC_WORKLISTS = [
  {
    surface: WorklistSurface.PENDING,
    label: 'Pending',
    filters: [{ name: 'status', options: [{ label: 'Verified', value: 'Verified' }] }],
  },
  {
    surface: WorklistSurface.IN_PROGRESS,
    label: 'In Progress',
    filters: [
      {
        name: 'status',
        options: [
          { label: 'Locked', value: 'Locked' },
          { label: 'Countdown', value: 'Countdown' },
          { label: 'Queued', value: 'Queued' },
        ],
      },
    ],
  },
  {
    surface: WorklistSurface.RECENTLY_READ,
    label: 'Recently Read',
    filters: [{ name: 'status', options: [{ label: 'Completed', value: 'Completed' }] }],
  },
  {
    surface: WorklistSurface.QC,
    label: 'QC',
    filters: [{ name: 'status', options: [{ label: 'QC', value: 'QC' }] }],
  },
] as const;

// TODO: We will fetch this from the backend later on
export const PRIVATE_WORKLISTS = [
  {
    surface: WorklistSurface.MY_QUEUE,
    label: 'My Queue',
    filters: [
      {
        name: 'status',
        options: [
          { label: 'Locked', value: 'Locked' },
          { label: 'Countdown', value: 'Countdown' },
          { label: 'Queued', value: 'Queued' },
        ],
      },
    ],
  },
] as const;

import { Stack } from 'common/ui/Layout';
import Skeleton from '@material-ui/lab/Skeleton';

const SHIMMER_HEIGHT = 25;
const NUM_FINDINGS_LINES = 6;
const NUM_IMPRESSIONS_LINES = 4;

const Shimmer = ({
  width,
  height,
  animation,
  css = '',
}: {
  width: string;
  height: number;
  animation: boolean;
  css?: string;
}) => <Skeleton css={css} width={width} height={height} animation={animation ? 'pulse' : false} />;

export const RichTextEditorLoading = ({
  hasAnimation = true,
}: {
  hasAnimation?: boolean;
}): React.ReactElement => {
  return (
    <Stack space="medium" css="width: 100%; height: 100%;" vertical alignX="start" alignY="start">
      <Stack vertical css="width: 100%">
        <Shimmer width="25%" height={SHIMMER_HEIGHT} animation={hasAnimation} />
        <Shimmer width="45%" height={SHIMMER_HEIGHT} animation={hasAnimation} />
      </Stack>
      <Stack vertical css="width: 100%">
        <Shimmer width="25%" height={SHIMMER_HEIGHT} animation={hasAnimation} />
        <Shimmer width="65%" height={SHIMMER_HEIGHT} animation={hasAnimation} />
      </Stack>
      <Stack vertical css="width: 100%">
        <Shimmer width="25%" height={SHIMMER_HEIGHT} animation={hasAnimation} />
        <Shimmer width="34%" height={SHIMMER_HEIGHT} animation={hasAnimation} />
      </Stack>
      <Stack css="width: 100%" space="small">
        <Shimmer width="10%" height={SHIMMER_HEIGHT} animation={hasAnimation} />
        <Shimmer width="45%" height={SHIMMER_HEIGHT} animation={hasAnimation} />
      </Stack>
      <Stack vertical css="width: 100%" space="small">
        <Shimmer width="25%" height={SHIMMER_HEIGHT} animation={hasAnimation} />
        {Array.from({ length: NUM_FINDINGS_LINES }).map((_, sectionIdx) => (
          <Stack space="small" key={sectionIdx}>
            <Shimmer width="5%" height={SHIMMER_HEIGHT} animation={hasAnimation} />
            <Shimmer width="90%" height={SHIMMER_HEIGHT} animation={hasAnimation} />
          </Stack>
        ))}
      </Stack>
      <Stack vertical css="width: 100%" space="medium">
        <Shimmer width="25%" height={SHIMMER_HEIGHT} animation={hasAnimation} />
        {Array.from({ length: NUM_IMPRESSIONS_LINES }).map((_, sectionIdx) => (
          <Stack space="small" key={sectionIdx}>
            <Shimmer
              width="10px"
              height={SHIMMER_HEIGHT}
              css="margin-left: 5%"
              animation={hasAnimation}
            />
            <Shimmer width="75%" height={SHIMMER_HEIGHT} animation={hasAnimation} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

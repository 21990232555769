import { Point, Range, Editor, Transforms } from 'domains/reporter/RichTextEditor/core';
import { useCallback, useMemo, useState, useRef, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { useFeatureFlagEnabled, FF } from 'modules/feature-flags';
import { isFocusModeState, isFocusModeSettingEnabledState } from 'domains/reporter/Reporter/state';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { logger } from 'modules/logger';
import type { Dispatch, SetStateAction } from 'types/react';
import {
  getHeadingSectionEdgesByNode,
  getHeadingNodeWithTextInRange,
  getHeadingSectionEdgesByRegex,
} from 'domains/reporter/RichTextEditor/plugins/heading/utils/normalization';
import { HeadingLevel } from 'domains/reporter/RichTextEditor/plugins/heading/constants';

import {
  getHasMultipleInlineBookmarksInRange,
  getLastInlineBookmarkInRange,
  getLastInlineBookmarkInRangeIfEmpty,
} from 'domains/reporter/RichTextEditor/plugins/inlineBookmark/utils';
import {
  createParagraph,
  getParagraphNodeStartingWithTextInRange,
  findSearchTermInParagraphs,
  deleteTextRanges,
} from '../domains/reporter/RichTextEditor/plugins/paragraph/utils';
import { insertInlineBookmark } from '../domains/reporter/RichTextEditor/plugins/inlineBookmark/utils';

type UseFocusModeReturn = {
  isFocusModeSettingEnabled: boolean;
  isFocusModeSettingVisible: boolean;
  isFocusModeOn: boolean;
  setIsFocusModeOn: (isFocusMode: boolean) => void;
  setIsFocusModeSettingEnabled: (isFocusModeSettingEnabled: boolean) => void;
  toggleFocusMode: () => void;
  handleFocusModeDoubleClick: () => void;
  setLastClickTime: Dispatch<SetStateAction<number>>;
  getInsertionPoint: (editor: Editor, sections: Array<string>) => Point | null | undefined;
  getInsertionRange: (editor: Editor, sections: Array<string>) => Range | null | undefined;
  deleteTextInSection: (editor: Editor, text: string, section: Array<string>) => void;
  mostRecentFocusModeOperationRef: {
    current: string | null | undefined;
  };
};

// used to determine if a double click has occurred (using the mousedown)
const DOUBLE_CLICK_THRESHOLD = 300;

export const FocusModeOperation = Object.freeze({
  Delete: 'delete',
  Append: 'append',
});

export type FocusModeOperationType = keyof typeof FocusModeOperation;

export const useFocusMode = (): UseFocusModeReturn => {
  const [isFocusModeOn, setIsFocusModeOn] = useRecoilState(isFocusModeState);
  const [isFocusModeSettingEnabled, setIsFocusModeSettingEnabled] = useRecoilState(
    isFocusModeSettingEnabledState
  );
  const [, setLastClickTime] = useState(0);
  const [isFocusModeFeatureEnabled] = useFeatureFlagEnabled(FF.REPORTER_FOCUS_MODE);
  const [isASRPlexFeatureEnabled] = useFeatureFlagEnabled(FF.REPORTER_ASR_PLEX);
  const { data } = useCurrentUser();
  const me = data?.me;
  const reporterSettings = me?.reporterSettings;
  const mostRecentFocusModeOperationRef = useRef();

  // Visibility of Focus Mode in the Reporter setting
  const isFocusModeSettingVisible = isFocusModeFeatureEnabled && isASRPlexFeatureEnabled;

  useEffect(() => {
    setIsFocusModeSettingEnabled(reporterSettings?.focusMode?.enabled ?? false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleFocusMode = useCallback(() => {
    setIsFocusModeOn((focusMode) => !focusMode);
  }, [setIsFocusModeOn]);

  // Handle double clicks inside report to exit focus mode
  const handleFocusModeDoubleClick = useCallback(() => {
    const now = new Date().getTime();

    setLastClickTime((prev) => {
      if (now - prev <= DOUBLE_CLICK_THRESHOLD) {
        logger.info('[useFocusMode] Double click detected, exiting focus mode');
        setIsFocusModeOn(false);
      }
      return now;
    });
  }, [setLastClickTime, setIsFocusModeOn]);

  const getInlineBookmarkPointForInsertion = useCallback(
    (editor: Editor, range: Range): Point | null | undefined => {
      const hasMultipleInlineBookmarksInRange = getHasMultipleInlineBookmarksInRange(editor, range);
      const lastInlineBookmarkInRange = getLastInlineBookmarkInRange(editor, range);
      const lastEmptyInlineBookmarkInRange = getLastInlineBookmarkInRangeIfEmpty(editor, range);

      if (lastInlineBookmarkInRange != null && !hasMultipleInlineBookmarksInRange) {
        return Editor.end(editor, lastInlineBookmarkInRange[1]);
      } else if (lastEmptyInlineBookmarkInRange != null && hasMultipleInlineBookmarksInRange) {
        return Editor.end(editor, lastEmptyInlineBookmarkInRange[1]);
      }

      // if no inline bookmarks found
      Transforms.insertNodes(editor, [createParagraph()], {
        at: Editor.end(editor, range),
        select: true,
      });
      // @ts-expect-error [EN-7967] - TS2322 - Type 'BasePoint' is not assignable to type 'BaseRange & { highlighted?: boolean; }'.
      insertInlineBookmark(editor, { select: true, location: Editor.end(editor, range) });

      const { selection } = editor;
      if (selection == null) {
        return Editor.end(editor, range);
      }

      return Editor.end(editor, selection);
    },
    []
  );

  const getInsertionRange = useCallback(
    (editor: Editor, sections: Array<string>): Range | null | undefined => {
      if (sections.length === 0) {
        return null;
      }

      const headingNodeText = sections[0];
      const headingNodeEntry = getHeadingNodeWithTextInRange({
        editor,
        text: headingNodeText,
        level: HeadingLevel.H1,
      });

      if (headingNodeEntry != null) {
        const headingSectionEdges = getHeadingSectionEdgesByNode(
          editor,
          headingNodeEntry,
          HeadingLevel.H1
        );

        if (headingSectionEdges == null) {
          return null;
        }

        // If there is only one section, then we've already found the range
        if (sections.length === 1) {
          return headingSectionEdges;
        }

        const subheadingNodeEntry = getHeadingNodeWithTextInRange({
          editor,
          text: sections[1],
          level: HeadingLevel.H2,
          range: headingSectionEdges,
        });

        let insertionSectionEdges = null;

        if (subheadingNodeEntry != null) {
          insertionSectionEdges = getHeadingSectionEdgesByNode(
            editor,
            subheadingNodeEntry,
            HeadingLevel.H2
          );
        } else {
          const paragraphEntry = getParagraphNodeStartingWithTextInRange({
            editor,
            text: sections[1] + ':',
            range: headingSectionEdges,
          });
          if (paragraphEntry != null) {
            const edges = Editor.edges(editor, paragraphEntry[1]);
            insertionSectionEdges = { anchor: edges[0], focus: edges[1] };
          }
        }

        if (insertionSectionEdges == null) {
          return headingSectionEdges;
        } else {
          return insertionSectionEdges;
        }
      } else {
        const headingSectionEdges = getHeadingSectionEdgesByRegex(editor, sections);
        if (headingSectionEdges == null) {
          return null;
        }

        return headingSectionEdges;
      }
    },
    []
  );

  const getInsertionPoint = useCallback(
    (editor: Editor, sections: Array<string>): Point | null | undefined => {
      if (editor == null) {
        return null;
      }

      if (sections.length === 0) {
        return Editor.end(editor, []);
      }

      if (mostRecentFocusModeOperationRef.current === FocusModeOperation.Delete) {
        return editor.selection?.anchor;
      }

      const headingNodeText = sections[0];
      const headingNodeEntry = getHeadingNodeWithTextInRange({
        editor,
        text: headingNodeText,
        level: HeadingLevel.H1,
      });

      if (headingNodeEntry != null) {
        const headingSectionEdges = getHeadingSectionEdgesByNode(
          editor,
          headingNodeEntry,
          HeadingLevel.H1
        );

        if (headingSectionEdges == null) {
          return Editor.end(editor, []);
        }

        // If there is only one section, then we've already found the range
        if (sections.length === 1) {
          return getInlineBookmarkPointForInsertion(editor, headingSectionEdges);
        }

        const subheadingNodeEntry = getHeadingNodeWithTextInRange({
          editor,
          text: sections[1],
          level: HeadingLevel.H2,
          range: headingSectionEdges,
        });

        let insertionSectionEdges = null;

        if (subheadingNodeEntry != null) {
          insertionSectionEdges = getHeadingSectionEdgesByNode(
            editor,
            subheadingNodeEntry,
            HeadingLevel.H2
          );
        } else {
          const paragraphEntry = getParagraphNodeStartingWithTextInRange({
            editor,
            text: sections[1] + ':',
            range: headingSectionEdges,
          });
          if (paragraphEntry != null) {
            const edges = Editor.edges(editor, paragraphEntry[1]);
            insertionSectionEdges = { anchor: edges[0], focus: edges[1] };
          }
        }

        if (insertionSectionEdges == null) {
          return Editor.end(editor, headingSectionEdges);
        } else {
          return getInlineBookmarkPointForInsertion(editor, insertionSectionEdges);
        }
      } else {
        const headingSectionEdges = getHeadingSectionEdgesByRegex(editor, sections);
        if (headingSectionEdges == null) {
          return Editor.end(editor, []);
        }

        return getInlineBookmarkPointForInsertion(editor, headingSectionEdges);
      }
    },
    [getInlineBookmarkPointForInsertion]
  );

  const deleteTextInSection = useCallback(
    (editor: Editor, text: string, sections: Array<string>): void => {
      if (editor == null || sections.length === 0) {
        return;
      }
      // Get the extent of the section so we can search for paragraph nodes
      // in which to delete the specified text.
      const headingSectionEdges = getInsertionRange(editor, sections);
      if (headingSectionEdges == null) {
        return;
      }

      const results = findSearchTermInParagraphs(editor, headingSectionEdges, text);
      const lastDeletedAt = deleteTextRanges(editor, results);
      logger.info('[deleteTextInSection] Deleted existing default text in focus mode', {
        results,
        lastDeletedAt,
      });
      if (lastDeletedAt) {
        // @ts-expect-error [EN-7967] - TS2322 - Type '"delete"' is not assignable to type 'undefined'.
        mostRecentFocusModeOperationRef.current = FocusModeOperation.Delete;
      }
    },
    [getInsertionRange]
  );

  return useMemo(
    () => ({
      isFocusModeSettingVisible,
      isFocusModeSettingEnabled,
      isFocusModeOn,
      setIsFocusModeOn,
      setIsFocusModeSettingEnabled,
      toggleFocusMode,
      handleFocusModeDoubleClick,
      setLastClickTime,
      getInsertionPoint,
      getInsertionRange,
      deleteTextInSection,
      mostRecentFocusModeOperationRef,
    }),
    [
      isFocusModeSettingVisible,
      isFocusModeSettingEnabled,
      isFocusModeOn,
      setIsFocusModeOn,
      setIsFocusModeSettingEnabled,
      toggleFocusMode,
      handleFocusModeDoubleClick,
      setLastClickTime,
      getInsertionPoint,
      getInsertionRange,
      deleteTextInSection,
      mostRecentFocusModeOperationRef,
    ]
  );
};

import { useContext, useEffect, useMemo, useRef } from 'react';
import { Contexts } from 'react-vtk-js';
import { useRecoilValue } from 'recoil';
import { scaleState, useViewportId } from './state';
import { useActiveSliceImage } from './useImage';
import vtkCamera from '@kitware/vtk.js/Rendering/Core/Camera';
import { useViewCamera } from './useViewCamera';
import { useRenderer } from './useRenderer';
import { useOpenGLRenderWindow } from './useOpenGLRenderWindow';
import type { IView } from 'react-vtk-js';

const getScale = (camera: vtkCamera) => camera?.getParallelScale() ?? 1;

export const useScaleListener = (callback: (arg1: number) => void): void => {
  const view = useContext<IView | null | undefined>(Contexts.ViewContext);
  const camera = view?.getCamera();
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    if (camera == null) return;

    // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'import("@kitware/vtk.js").vtkCamera' is not assignable to parameter of type 'import("/home/runner/work/services-frontend/services-frontend/services-frontend/frontend/node_modules/@kitware/vtk.js/Rendering/Core/Camera").vtkCamera'.
    callbackRef.current(getScale(camera));

    // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'import("@kitware/vtk.js").vtkCamera' is not assignable to parameter of type 'import("/home/runner/work/services-frontend/services-frontend/services-frontend/frontend/node_modules/@kitware/vtk.js/Rendering/Core/Camera").vtkCamera'.
    const subscription = camera.onModified(() => callbackRef.current(getScale(camera)));

    return () => {
      subscription.unsubscribe();
    };
  }, [camera]);
};

/* @param compensateForVoxelSpacing: bool
 * true --> Indicates that scaling factors should be adjusted for voxel-spacing.
 *          This helps functions that use worldToIndex and indexToWorld transformations.
 *          Use this if you are operating in index space (unit voxel-spacing).
 * false --> Simply return scaling factor based on zoom. Use this if you are operating
 *           in world space.
 * returns [number, number, number] that indicate separate scaling factors in X, Y, and Z directions.
 */
export const useScale = (compensateForVoxelSpacing: boolean): [number, number, number] => {
  const viewportId = useViewportId();
  const view = useContext(Contexts.ViewContext);
  const image = useActiveSliceImage();
  // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'IView'.
  const camera = useViewCamera(view);
  const parallelScale = useRecoilValue(scaleState(viewportId)) ?? camera.getParallelScale();
  const devicePixelRatio = window.devicePixelRatio;
  const renderer = useRenderer();
  const openGLRenderWindow = useOpenGLRenderWindow();
  const rendererHeight = openGLRenderWindow.getViewportSize(renderer)[1];

  return useMemo(() => {
    const _scale = parallelScale / rendererHeight;
    const scale = [_scale, _scale, _scale];

    if (compensateForVoxelSpacing && image != null) {
      const spacing = image.getSpacing();
      scale[0] /= spacing[0];
      scale[1] /= spacing[1];
      scale[2] /= spacing[2];
    }
    return [scale[0] * devicePixelRatio, scale[1] * devicePixelRatio, scale[2] * devicePixelRatio];
  }, [rendererHeight, devicePixelRatio, parallelScale, compensateForVoxelSpacing, image]);
};

import { useRef, useCallback, useEffect } from 'react';
import { useGamepadBindings } from 'utils/gamepad';
import { useWebHIDBindings } from 'modules/WebHID/useWebHID';

type UseShouldSkipWebsocketCreationReturn = {
  /** Ref to determine if WebSocket creation should be skipped */
  shouldSkipWebsocketCreationRef: {
    current: boolean;
  };
  /**
   * Set the current ref value for whether to skip WebSocket creation
   * @param shouldSkip - Flag indicating whether to skip or not
   */
  setShouldSkipWebsocketCreation: (shouldSkip: boolean) => void;
  /** Ref to the element that will have user activity (mousedown and keydown) listeners attached */
  userActivityElementRef: {
    current: HTMLElement | null | undefined;
  };
};

export const useShouldSkipWebsocketCreation = (): UseShouldSkipWebsocketCreationReturn => {
  const shouldSkipWebsocketCreationRef = useRef(false);
  const userActivityElementRef = useRef();

  const setShouldSkipWebsocketCreation = useCallback((shouldSkip: boolean) => {
    shouldSkipWebsocketCreationRef.current = shouldSkip;
  }, []);

  const gamepadCallback = useCallback(
    (action, pressed) => {
      if (pressed === true && (action === 'forward' || action === 'back')) {
        setShouldSkipWebsocketCreation(false);
      }
    },
    [setShouldSkipWebsocketCreation]
  );

  useGamepadBindings({ callback: gamepadCallback });
  useWebHIDBindings({ callback: gamepadCallback });

  useEffect(() => {
    // only job is to help correctly clean up listeners
    let currentElementRef;

    const handleUserEditorInteraction = () => {
      setShouldSkipWebsocketCreation(false);
    };

    // adds eventListeners to an element to track mousedown and keydown.
    const attachListenersIfElementExists = () => {
      const currentElement = userActivityElementRef.current;
      if (currentElement) {
        // @ts-expect-error [EN-7967] - TS2339 - Property 'addEventListener' does not exist on type 'never'.
        currentElement.addEventListener('mousedown', handleUserEditorInteraction);
        // @ts-expect-error [EN-7967] - TS2339 - Property 'addEventListener' does not exist on type 'never'.
        currentElement.addEventListener('keydown', handleUserEditorInteraction);
        clearInterval(intervalId);
        currentElementRef = currentElement;
      }
    };

    const intervalId = setInterval(attachListenersIfElementExists, 0);

    return () => {
      // Cleanup: remove the listeners (if they were added) and clear the interval
      if (currentElementRef) {
        currentElementRef.removeEventListener('mousedown', handleUserEditorInteraction);
        currentElementRef.removeEventListener('keydown', handleUserEditorInteraction);
      }
      clearInterval(intervalId);
    };
  }, [setShouldSkipWebsocketCreation]);

  return {
    shouldSkipWebsocketCreationRef,
    setShouldSkipWebsocketCreation,
    userActivityElementRef,
  };
};

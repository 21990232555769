import { enhanceProviderExpandSelection } from './enhanceProviderExpandSelection';
import type { EditorPlugin } from '../../types';
import type { EditorPluginOptions, EditorPluginPropertyOptions } from '../../types/plugin';

export const ExpandSelection_PLUGIN_ID: 'expandSelection' = 'expandSelection';
export type ExpandSelectionPluginID = typeof ExpandSelection_PLUGIN_ID;

export type ExpandSelectionPluginOptions = Readonly<
  EditorPluginOptions & {
    forceExpandPicklist?: boolean;
  }
>;

export type ExpandSelectionPluginPropertyOptions = Readonly<
  EditorPluginPropertyOptions & ExpandSelectionPluginOptions
>;

export const ExpandSelectionPlugin = ({
  forceExpandPicklist = false,
  ...rest
}: ExpandSelectionPluginOptions = {}): EditorPlugin => {
  const options: ExpandSelectionPluginPropertyOptions = {
    pluginID: 'expandSelection',
    pluginName: 'ExpandSelection',
    forceExpandPicklist,
  };

  return {
    pluginID: options.pluginID,
    enhanceProvider: enhanceProviderExpandSelection(options),
  };
};

import { Editor } from 'domains/reporter/RichTextEditor/core';
import { some } from './some';
import type { SomeOptions } from './some';

export type IsNodeTypeInOptions = SomeOptions;

/**
 * Checks if a node is active within a selection. Defaults to checking the
 * editor's current selection.
 */
export const isNodeTypeIn = (
  editor: Editor,
  type: string,
  options: IsNodeTypeInOptions = {}
): boolean => {
  return some(editor, (n) => n.type === type, options);
};

import { Text, Range } from 'domains/reporter/RichTextEditor/core';
import type { EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';
import type { ComponentType } from 'react';
import type { ParsedDictationChunkExternalSubstitutionPayload } from './NvoqQueue/createNvoqWebsocketFactory';
import type { RecorderTarget } from 'common/Recorder/useRecorder';

export const DICTATION_PLUGIN_ID: 'dictation' = 'dictation';
export type DictationPluginID = typeof DICTATION_PLUGIN_ID;

export type DictationPluginElementOrDecorate = Readonly<
  Text & {
    selectionRef: Range;
    hypothesisText: string;
    type: DictationPluginID;
  }
>;

export type RecorderVoiceCommandProps = Readonly<{
  type: 'RecorderVoiceCommand';
  /**
   * Selection at the point in which the user dictated the substitution, not necessarily
   * where the user's current selection is!
   */
  selection: Range;
  substitution: ParsedDictationChunkExternalSubstitutionPayload;
}>;

export type OnExternalSubstitution = (props: RecorderVoiceCommandProps) => void;

/**
 * Called before processing any stable text internally that comes from nVoq
 * and after handling substitutions. Return true to handle the event otherwise, it will
 * be handled internally.
 */
export type OnStableText = (props: {
  /**
   * Selection at the point in which the user dictated the substitution, not necessarily
   * where the user's current selection is!
   */
  selection: Range;
  stableText: string;
}) => boolean;

export type DictationPluginOptions = Readonly<
  EditorPluginOptions & {
    nvoqID: string;
    nvoqAuthorization: string;
    target: RecorderTarget;
    enablePicklistDictation?: boolean;
    // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
    component?: ComponentType<any>;
    activeTemplateId?: string;
    aiMode?: boolean;
    onStableText?: OnStableText;
  }
>;

export type DictationPluginPropertyOptions = Readonly<
  EditorPluginPropertyOptions &
    DictationPluginOptions & {
      pluginName: string;
      pluginID: DictationPluginID;
      nvoqID: string;
      nvoqAuthorization: string;
      target: RecorderTarget;
      enablePicklistDictation: boolean;
      // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
      component: ComponentType<any>;
    }
>;

export type WebsocketCreationOptions = {
  shouldSkipWebsocketCreation: boolean;
};

import {
  APP_VERSION as _APP_VERSION,
  APP_BUILD as _APP_BUILD,
  IS_TEST_ENV,
} from 'config/constants';
import Text from 'common/ui/Text';
import { Stack } from 'common/ui/Layout';

type Props = {
  className?: string;
  css?: string;
};

const APP_VERSION = IS_TEST_ENV ? 'test' : _APP_VERSION;
const APP_BUILD = IS_TEST_ENV ? '??????' : _APP_BUILD;

const VersionAndBuild = ({ className }: Props): React.ReactElement => {
  return (
    <Stack className={className} vertical>
      {APP_VERSION && <Text variant="body1">Version: {APP_VERSION}</Text>}
      {APP_BUILD && <Text variant="body1">Build: {APP_BUILD}</Text>}
    </Stack>
  );
};

export default VersionAndBuild;

import type { EditorPlugin } from '../../types';
import { LIST_PLUGIN_ID } from './types';
import type { ListPluginOptions, ListPluginPropertyOptions } from './types';
import { getRenderElement } from '../../utils';
import { onKeyDownLists } from './onKeyDownList';
import { ListEditable } from './components';
import { enhanceEditorStateList } from './enhanceEditorStateList';
import { enhanceProviderList } from './enhanceProviderList';
import { toolbarConfigList } from './toolbarConfigList';
import { LIST_TOOLBAR_MODE_OPTIONS } from './constants';

export const ListPlugin = ({
  component = ListEditable,
  toolbarMode = LIST_TOOLBAR_MODE_OPTIONS.expanded,
  setCurrentActiveListVariant,
  ...rest
}: ListPluginOptions): EditorPlugin => {
  const options: ListPluginPropertyOptions = {
    pluginID: LIST_PLUGIN_ID,
    component,
    setCurrentActiveListVariant,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    // @ts-ignore [incompatible-call] - will be resolved in follow-up Lists PR
    renderElement: getRenderElement<ListPluginPropertyOptions>(options),
    enhanceEditorState: enhanceEditorStateList(options),
    enhanceProvider: enhanceProviderList(options),
    onKeyDown: onKeyDownLists(options),
    toolbarConfig:
      toolbarMode === LIST_TOOLBAR_MODE_OPTIONS.stacked
        ? () => toolbarConfigList(options)()
        : undefined,
  };
};

import { Element, Text } from 'domains/reporter/RichTextEditor/core';
/**
 * Slate is in a mature beta. There will likely be more breaking
 * changes that happen as it gets to 1.0. This is an abstraction
 * layer between our application code and Slate to guard against
 * breaking changes, add additional helpers, and to augment functionality
 * core to Slate.
 */

import { Range as SlateRange } from 'slate';
import type { NodeEntry } from 'slate';
import './scrubber';
import * as React from 'react';
export * from 'slate';
export * from 'slate-react';
export * from 'slate-history';

// Override Slate "Range" to include our "highlighted" property.
export interface Range extends SlateRange {
  highlighted?: boolean;
}

// Override the MDN Range type; we will always use Slate's Range type in contexts where we import Range from this file..
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Range = {
  ...SlateRange,
};

// Slate doesn't add generics to these commonly used types.
/**
 * `RenderElementProps` are passed to the `renderElement` handler.
 */
export type RenderElementProps<T = Element> = {
  children: React.ReactNode;
  element: T;
  attributes: {
    ['data-slate-node']: 'element';
    ['data-slate-inline']?: true;
    ['data-slate-void']?: true;
    dir?: 'rtl';
    // @ts-expect-error [EN-7967] - TS2724 - 'React' has no exported member named 'Element'. Did you mean 'CElement'?
    ref: React.Ref<React.Element>;
  };
};

/**
 * `RenderLeafProps` are passed to the `renderLeaf` handler.
 */
export type RenderLeafProps<T = Text> = {
  children: React.ReactNode;
  leaf: T;
  text: Text;
  attributes: {
    ['data-slate-leaf']: true;
  };
};

// Slate doesn't export these types.
// These types aren't exact because React doesn't have Flow definitions for CSSProperties or TextareaProps that I can see
// https://github.com/facebook/react/blob/master/packages/react/index.js
/**
 * `EditableProps` are passed to the `<Editable>` component.
 */
export type EditableProps = {
  decorate?: (entry: NodeEntry) => Range[];
  onDOMBeforeInput?: (event: Event) => void;
  placeholder?: string;
  readOnly?: boolean;
  role?: string;
  style?: Record<any, any>;
  css?: string;
  className?: string;
  renderElement?: (props: RenderElementProps) => React.ReactElement;
  renderLeaf?: (props: RenderLeafProps) => React.ReactElement;
  // @ts-expect-error [EN-7967] - TS2724 - 'React' has no exported member named 'Element'. Did you mean 'CElement'?
  as?: React.Element;
  onDrop?: (event: DragEvent) => boolean;
  onDragStart?: (event: DragEvent) => boolean;
};

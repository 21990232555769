/* THIS FILE IS AUTO-GENERATED. DO NOT EDIT. */

export const FF = Object.freeze({
  ANATOMIC_NAVIGATOR: 'ANATOMIC_NAVIGATOR',
  AUTOLOAD_PRIORS: 'AUTOLOAD_PRIORS',
  EDIT_CASE_PRIORITY: 'EDIT_CASE_PRIORITY',
  ENABLE_REPORTER_SUBMISSION: 'ENABLE_REPORTER_SUBMISSION',
  GSPS_ANNOTATIONS: 'GSPS_ANNOTATIONS',
  HANGING_PROTOCOL_DESERIALIZATION: 'HANGING_PROTOCOL_DESERIALIZATION',
  HANGING_PROTOCOL_VERSION: 'HANGING_PROTOCOL_VERSION',
  HANGING_PROTOCOL_PHASE: 'HANGING_PROTOCOL_PHASE',
  HP_MATCH_ON_SMID: 'HP_MATCH_ON_SMID',
  IMAGE_BOOKMARK: 'IMAGE_BOOKMARK',
  LAYOUT_CONFIG: 'LAYOUT_CONFIG',
  LOCK_CLAIM_ADDENDUM: 'LOCK_CLAIM_ADDENDUM',
  LUNG_WORKFLOW: 'LUNG_WORKFLOW',
  MIN_COLUMNS_TO_ENABLE_MULTIPLE_EXPANSION_AREAS: 'MIN_COLUMNS_TO_ENABLE_MULTIPLE_EXPANSION_AREAS',
  PREFETCH_CLAIMED_WORKLIST_ITEMS: 'PREFETCH_CLAIMED_WORKLIST_ITEMS',
  PREFETCH_HOVERED_WORKLIST_ITEM: 'PREFETCH_HOVERED_WORKLIST_ITEM',
  PREFETCH_READ_CASE_ITEM: 'PREFETCH_READ_CASE_ITEM',
  PREFETCH_TOP_WORKLIST_ITEMS: 'PREFETCH_TOP_WORKLIST_ITEMS',
  PREFETCH_TOP_WORKLIST_ITEMS_BIG_BATCHING: 'PREFETCH_TOP_WORKLIST_ITEMS_BIG_BATCHING',
  PREFETCH_TOP_WORKLIST_ITEMS_LARGE: 'PREFETCH_TOP_WORKLIST_ITEMS_LARGE',
  REPORTER_AI_MODE: 'REPORTER_AI_MODE',
  REPORTER_ANATOMIC_NAVIGATOR: 'REPORTER_ANATOMIC_NAVIGATOR',
  REPORTER_ASR_PLEX: 'REPORTER_ASR_PLEX',
  ACCURATE_TEXT_PLACEMENT: 'ACCURATE_TEXT_PLACEMENT',
  REPORTER_AUTO_IMPRESSIONS: 'REPORTER_AUTO_IMPRESSIONS',
  REPORTER_AUTO_FILL_COMPARISON: 'REPORTER_AUTO_FILL_COMPARISON',
  REPORTER_EXPERIMENTAL_OPTIONS: 'REPORTER_EXPERIMENTAL_OPTIONS',
  REPORTER_FOCUS_MODE: 'REPORTER_FOCUS_MODE',
  REPORTER_SHOW_DICTATION_STEP_NOTIFICATIONS: 'REPORTER_SHOW_DICTATION_STEP_NOTIFICATIONS',
  REPORTER_RECORDING_STOP_DELAY: 'REPORTER_RECORDING_STOP_DELAY',
  REPORTER_WEBHID: 'REPORTER_WEBHID',
  REPORT_CONTENT_UNMASKED: 'REPORT_CONTENT_UNMASKED',
  REPORT_OUTLINE: 'REPORT_OUTLINE',
  REQUEST_NODULE_MSI: 'REQUEST_NODULE_MSI',
  SHOW_MOUSE_SENSITIVITY_SETTINGS: 'SHOW_MOUSE_SENSITIVITY_SETTINGS',
  SLATE_OPS_LOG: 'SLATE_OPS_LOG',
  URT_ENABLED: 'URT_ENABLED',
  URT_MED_CHECK_ENABLED: 'URT_MED_CHECK_ENABLED',
  USE_INVERTED_IMAGE_DETECTION: 'USE_INVERTED_IMAGE_DETECTION',
  VIEWER_DATA_REFETCH: 'VIEWER_DATA_REFETCH',
  VIEWER_TRANSFER_TYPE: 'VIEWER_TRANSFER_TYPE',
  VIEWER_TRANSFER_PROTOCOL: 'VIEWER_TRANSFER_PROTOCOL',
  WORKLIST_ASSIGN_EXAMS: 'WORKLIST_ASSIGN_EXAMS',
  WORKLIST_AUTO_LOAD: 'WORKLIST_AUTO_LOAD',
  WORKLIST_COLUMN_FILTERS: 'WORKLIST_COLUMN_FILTERS',
  WORKLIST_CUSTOM_STATUS: 'WORKLIST_CUSTOM_STATUS',
  WORKLIST_CUSTOM_WORKLISTS_MILESTONE_1: 'WORKLIST_CUSTOM_WORKLISTS_MILESTONE_1',
  WORKLIST_CUSTOM_WORKLISTS_MILESTONE_2: 'WORKLIST_CUSTOM_WORKLISTS_MILESTONE_2',
  WORKLIST_DERIVED_NAME_FILTERS: 'WORKLIST_DERIVED_NAME_FILTERS',
  WORKLIST_IMPROVED_FILTERS: 'WORKLIST_IMPROVED_FILTERS',
  WORKLIST_PAGINATION: 'WORKLIST_PAGINATION',
  WORKLIST_PERFORMANCE_OPTIMIZATION: 'WORKLIST_PERFORMANCE_OPTIMIZATION',
  WORKLIST_RECENTLY_READ_DAYS: 'WORKLIST_RECENTLY_READ_DAYS',
  refresh_viewer_on_new_case: 'refresh_viewer_on_new_case',
  VIEWPORT_TYPE_THREE_D_DRE_ENABLED: 'VIEWPORT_TYPE_THREE_D_DRE_ENABLED',
  IMAGE_REGISTRATION_ALIGNMENT: 'IMAGE_REGISTRATION_ALIGNMENT',
  IMAGE_REGISTRATION_MODEL_VERSION: 'IMAGE_REGISTRATION_MODEL_VERSION',
  PET_CT_OVERLAY_ENABLED: 'PET_CT_OVERLAY_ENABLED',
  REPORTER_EXAM_COUNT: 'REPORTER_EXAM_COUNT',
  PATIENT_JACKET_PRIOR_SUMMARY: 'PATIENT_JACKET_PRIOR_SUMMARY',
  PATIENT_JACKET_V2: 'PATIENT_JACKET_V2',
  FAST_ANNOTATIONS: 'FAST_ANNOTATIONS',
  REPORTER_BYPASS_SIGNING: 'REPORTER_BYPASS_SIGNING',
  RESET_CAMERA_ON_SCROLL_DIMENSIONS_ONLY: 'RESET_CAMERA_ON_SCROLL_DIMENSIONS_ONLY',
  MG_CAD_SR_TEXT_RENDER: 'MG_CAD_SR_TEXT_RENDER',
  MOVE_TO_QUALITY_CONTROL: 'MOVE_TO_QUALITY_CONTROL',
  ORDER_MANAGER: 'ORDER_MANAGER',
  ALLOW_MAMMO_SSC_FEATURES: 'ALLOW_MAMMO_SSC_FEATURES',
});
export const FFDefaults = Object.freeze({
  ANATOMIC_NAVIGATOR: 'off',
  AUTOLOAD_PRIORS: 'on',
  EDIT_CASE_PRIORITY: 'on',
  ENABLE_REPORTER_SUBMISSION: 'on',
  GSPS_ANNOTATIONS: 'on',
  HANGING_PROTOCOL_DESERIALIZATION: 'legacy',
  HANGING_PROTOCOL_VERSION: 'V1',
  HANGING_PROTOCOL_PHASE: 'phase-2',
  HP_MATCH_ON_SMID: 'off',
  IMAGE_BOOKMARK: 'on',
  LAYOUT_CONFIG: '8,3',
  LOCK_CLAIM_ADDENDUM: 'off',
  LUNG_WORKFLOW: 'off',
  MIN_COLUMNS_TO_ENABLE_MULTIPLE_EXPANSION_AREAS: '2',
  PREFETCH_CLAIMED_WORKLIST_ITEMS: 'off',
  PREFETCH_HOVERED_WORKLIST_ITEM: 'off',
  PREFETCH_READ_CASE_ITEM: 'off',
  PREFETCH_TOP_WORKLIST_ITEMS: 'on',
  PREFETCH_TOP_WORKLIST_ITEMS_BIG_BATCHING: '5',
  PREFETCH_TOP_WORKLIST_ITEMS_LARGE: '20',
  REPORTER_AI_MODE: 'off',
  REPORTER_ANATOMIC_NAVIGATOR: 'off',
  REPORTER_ASR_PLEX: 'off',
  ACCURATE_TEXT_PLACEMENT: 'off',
  REPORTER_AUTO_IMPRESSIONS: 'off',
  REPORTER_AUTO_FILL_COMPARISON: 'off',
  REPORTER_EXPERIMENTAL_OPTIONS: 'off',
  REPORTER_FOCUS_MODE: 'off',
  REPORTER_SHOW_DICTATION_STEP_NOTIFICATIONS: 'off',
  REPORTER_RECORDING_STOP_DELAY: '250',
  REPORTER_WEBHID: 'off',
  REPORT_CONTENT_UNMASKED: 'off',
  REPORT_OUTLINE: 'off',
  REQUEST_NODULE_MSI: 'off',
  SHOW_MOUSE_SENSITIVITY_SETTINGS: 'off',
  SLATE_OPS_LOG: 'on',
  URT_ENABLED: 'off',
  URT_MED_CHECK_ENABLED: 'off',
  USE_INVERTED_IMAGE_DETECTION: 'on',
  VIEWER_DATA_REFETCH: 'off',
  VIEWER_TRANSFER_TYPE: 'url-pixels',
  VIEWER_TRANSFER_PROTOCOL: 'http',
  WORKLIST_ASSIGN_EXAMS: 'off',
  WORKLIST_AUTO_LOAD: 'on',
  WORKLIST_COLUMN_FILTERS: 'on',
  WORKLIST_CUSTOM_STATUS: 'on',
  WORKLIST_CUSTOM_WORKLISTS_MILESTONE_1: 'on',
  WORKLIST_CUSTOM_WORKLISTS_MILESTONE_2: 'off',
  WORKLIST_DERIVED_NAME_FILTERS: 'off',
  WORKLIST_IMPROVED_FILTERS: 'on',
  WORKLIST_PAGINATION: 'off',
  WORKLIST_PERFORMANCE_OPTIMIZATION: 'off',
  WORKLIST_RECENTLY_READ_DAYS: '7',
  refresh_viewer_on_new_case: 'off',
  VIEWPORT_TYPE_THREE_D_DRE_ENABLED: 'on',
  IMAGE_REGISTRATION_ALIGNMENT: 'off',
  IMAGE_REGISTRATION_MODEL_VERSION: 'any',
  PET_CT_OVERLAY_ENABLED: 'off',
  REPORTER_EXAM_COUNT: 'on',
  PATIENT_JACKET_PRIOR_SUMMARY: 'on',
  PATIENT_JACKET_V2: 'off',
  FAST_ANNOTATIONS: 'off',
  REPORTER_BYPASS_SIGNING: 'off',
  RESET_CAMERA_ON_SCROLL_DIMENSIONS_ONLY: 'on',
  MG_CAD_SR_TEXT_RENDER: 'off',
  MOVE_TO_QUALITY_CONTROL: 'off',
  ORDER_MANAGER: 'off',
  ALLOW_MAMMO_SSC_FEATURES: 'off',
});
export type FeatureFlag = (typeof FF)[keyof typeof FF];

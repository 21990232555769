import { differenceInSeconds } from 'date-fns';

const TIME_LIMIT = 180; // 3 minutes

export function getCountdownSeconds(
  submittedAt: string | null | undefined | Date | null | undefined
): number | null {
  if (submittedAt == null) {
    return null;
  }

  return TIME_LIMIT - differenceInSeconds(new Date(), new Date(submittedAt));
}

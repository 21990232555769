import type { DeepLinkPluginElement } from '../domains/reporter/RichTextEditor/plugins/deepLink/types';
import type { Key } from 'react';

import { useToasterDispatch } from '../common/ui/Toaster/Toaster';
import {
  useSlateSelectionSingletonContext,
  useSlateSingletonContext,
} from '../domains/reporter/Reporter/SlateSingletonContext';
import { editableStudyDeepLinkState } from '../domains/reporter/Reporter/state';
import {
  getComparisonField,
  getDeepLinkKey,
  populateComparisonStudyNodes,
} from '../domains/reporter/RichTextEditor/plugins/deepLink/utils';
import { useStudies } from '../domains/viewer/Viewer/StudyLoader/useStudies';
import { useReviewedStudiesByDate } from '../domains/viewer/ViewportDre/modules/imaging/useTrackViewedSlices';
import { NAMESPACES, sendEvent } from '../modules/EventsManager/eventsManager';
import { useCaseSync } from './useCaseSync';
import { useCurrentComparativeStudies } from './useCurrentCase';
import { useHeadingKeywords } from './useHeadingKeywords';
import useStudyIds from './useStudyIds';
import useWorklistId from './useWorklistId';
import { logger } from 'modules/logger';
import { useCallback, useEffect } from 'react';
import { Editor, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';
import { DeepLinkVariants } from '../domains/reporter/RichTextEditor/plugins/deepLink/constants';
import { useSetRecoilState } from 'recoil';

type DeepLinkReturnType = {
  handleDeepLinkClick: (element: DeepLinkPluginElement | null, editor: Editor | null) => void;
  handleEditDeepLinkClick: (element: DeepLinkPluginElement | null, editor: Editor | null) => void;
  getIsEditingStudyNode: (key: Key) => boolean;
};

export const useDeepLinkStudy = ({
  isAutoFillComparisonEnabled = false,
}: {
  isAutoFillComparisonEnabled?: boolean;
}): DeepLinkReturnType => {
  const [{ editor }] = useSlateSingletonContext();
  const [{ selection }] = useSlateSelectionSingletonContext();
  const setEditableStudyDeepLinks = useSetRecoilState(editableStudyDeepLinkState);
  const headingKeywords = useHeadingKeywords();
  const reviewedStudiesByDate = useReviewedStudiesByDate();
  const syncCase = useCaseSync();
  const currentCaseId = useWorklistId();
  const [currentComparativeStudies, setCurrentComparativeStudies] = useCurrentComparativeStudies();
  const studyIds = useStudyIds();
  const { studies } = useStudies();
  const currentStudySmid = studies[0]?.smid;
  const { enqueueToast } = useToasterDispatch();
  const [imageBookmarkFlag] = useFeatureFlagEnabled(FF.IMAGE_BOOKMARK);

  // Populate comparison study nodes
  useEffect(() => {
    if (!isAutoFillComparisonEnabled || editor == null || reviewedStudiesByDate == null) return;

    logger.info('[AutoFillComparison] Reviewed Study Smids from Viewer.', reviewedStudiesByDate);

    populateComparisonStudyNodes(
      editor,
      reviewedStudiesByDate,
      headingKeywords.comparison,
      currentStudySmid,
      studyIds
    );
  }, [
    editor,
    headingKeywords.comparison,
    isAutoFillComparisonEnabled,
    reviewedStudiesByDate,
    currentStudySmid,
    studyIds,
  ]);

  // On click comparison node load study in viewer
  const loadLazyStudy = useCallback(
    (smid: string) => {
      if (currentCaseId == null) return;

      syncCase(currentCaseId, currentComparativeStudies.concat(smid));

      if (smid) {
        setCurrentComparativeStudies({
          caseSmid: currentCaseId,
          studySmids: currentComparativeStudies.concat(smid),
        });
      }
    },
    [syncCase, currentCaseId, currentComparativeStudies, setCurrentComparativeStudies]
  );

  // Single click on deep link scrolls to study in viewer and opens menu
  const handleDeepLinkClick = useCallback(
    (element, editor) => {
      if (element == null) return;
      const { children: _children, type, ...node } = element;
      const isImageBookmark =
        (imageBookmarkFlag || element.variant === DeepLinkVariants.POINT) &&
        element.variant !== DeepLinkVariants.STUDY;
      const isAutoFillComparison =
        isAutoFillComparisonEnabled && element.variant === DeepLinkVariants.STUDY;

      if (isImageBookmark) {
        sendEvent(NAMESPACES.DEEP_LINK, {
          type: 'HANDLE_IMAGE',
          payload: node,
        });
      } else if (isAutoFillComparison) {
        if (editor == null || element == null) return;

        const { studySmid: clickedStudySmid = '' } = node.context;
        if (![currentStudySmid, ...studyIds].includes(clickedStudySmid)) {
          if (studyIds.length >= 10) {
            enqueueToast(
              'You can view a maximum of 10 prior studies simultaneously. Please remove a study from the Viewer to proceed.',
              { severity: 'error' }
            );
          } else {
            loadLazyStudy(clickedStudySmid);
          }
        }
        sendEvent(NAMESPACES.DEEP_LINK, {
          type: 'HANDLE_STUDY',
          payload: node,
        });
        logger.info('[AutoFillComparison] Event HANDLE_STUDY sent to viewer', node);

        // Move selection to the end of the deep link element after click
        ReactEditor.focus(editor);
        try {
          const path = ReactEditor.findPath(editor, element);
          const after = Editor.after(editor, path);
          if (after == null) return;
          Transforms.select(editor, after);
        } catch (e: any) {
          logger.error('Error selecting after of the deep link element', e);
        }

        // selection has delayed effect, so we need to wait for it to take effect
        setTimeout(() => {
          const deepLinkKey = getDeepLinkKey(editor, element);
          // Only have one menu open after click
          setEditableStudyDeepLinks((previousState) => {
            // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
            const updateState = new Map(previousState ?? {});
            // Iterate through all entries in updateState
            previousState.forEach((value, key) => {
              // @ts-expect-error [EN-7967] - TS2367 - This comparison appears to be unintentional because the types 'import("slate-react").Key' and 'React.Key' have no overlap.
              if (key !== deepLinkKey) updateState.set(key, 'readOnly');
            });
            // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'React.Key' is not assignable to parameter of type 'import("slate-react").Key'.
            updateState.set(deepLinkKey, 'menuOpen');

            logger.info('[AutoFillComparison] Deep Link Clicked - Menu Open', element);

            return updateState;
          });
        }, 0);
      }
    },
    [
      imageBookmarkFlag,
      isAutoFillComparisonEnabled,
      currentStudySmid,
      studyIds,
      enqueueToast,
      loadLazyStudy,
      setEditableStudyDeepLinks,
    ]
  );

  // Double click on deep link or when edit button is clicked in menu
  const handleEditDeepLinkClick = useCallback(
    (element, editor) => {
      if (editor == null || element == null || !isAutoFillComparisonEnabled) return;
      const { children: _children, type, ...node } = element;
      logger.info('[AutoFillComparison] Deep Link Clicked - Editing', node);
      // Only have one menu open after click
      setEditableStudyDeepLinks((previousState) => {
        const deepLinkKey = getDeepLinkKey(editor, element);

        // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
        const updateState = new Map(previousState ?? {});
        // Iterate through all entries in updateState
        previousState.forEach((value, key) => {
          // @ts-expect-error [EN-7967] - TS2367 - This comparison appears to be unintentional because the types 'import("slate-react").Key' and 'React.Key' have no overlap.
          if (key !== deepLinkKey) updateState.set(key, 'readOnly');
        });
        // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'React.Key' is not assignable to parameter of type 'import("slate-react").Key'.
        updateState.set(deepLinkKey, 'editing');
        return updateState;
      });

      // Move selection to the end of the deep link element to start editing
      setTimeout(() => {
        ReactEditor.focus(editor);
        try {
          const path = ReactEditor.findPath(editor, element);
          const end = Editor.end(editor, path);
          if (end == null) return;
          Transforms.select(editor, end);
        } catch (e: any) {
          logger.info('[AutoFillComparison] Error selecting the end of the deep link element', e);
        }
      }, 0);
    },
    [isAutoFillComparisonEnabled, setEditableStudyDeepLinks]
  );

  const getIsEditingStudyNode = useCallback(
    (key: Key) => {
      let isEditing = false;
      // enclose in set function to get the latest state
      setEditableStudyDeepLinks((previousState) => {
        // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'React.Key' is not assignable to parameter of type 'import("slate-react").Key'.
        isEditing = previousState.get(key) === 'editing';
        return previousState;
      });
      return isEditing;
    },
    [setEditableStudyDeepLinks]
  );

  // Handle outside click to close menu
  useEffect(() => {
    if (editor == null || selection == null || !isAutoFillComparisonEnabled) return;

    const node = getComparisonField(editor, headingKeywords.comparison);
    if (node == null) return;

    const isInsideComparison = Range.includes(selection, node[1]);
    // Reset all editable study deep links if the selection is outside of the comparison field
    if (!isInsideComparison) {
      setEditableStudyDeepLinks(new Map());
    }
  }, [
    editor,
    headingKeywords.comparison,
    isAutoFillComparisonEnabled,
    selection,
    setEditableStudyDeepLinks,
  ]);

  return {
    handleDeepLinkClick,
    handleEditDeepLinkClick,
    getIsEditingStudyNode,
  };
};

import { Chip } from 'common/ui/Chip/Chip';
import { css } from 'styled-components';

const chipCss = css`
  white-space: nowrap;
  padding: 2px 6px;
  max-width: 100%;
`;

export const StudyLabelChip = ({
  studyColor,
  studyLabel,
  relevant = false,
  'data-testid': dataTestId,
}: {
  studyColor: string | null | undefined;
  studyLabel: string | null | undefined;
  relevant?: boolean | null | undefined;
  ['data-testid']?: string;
}): React.ReactElement | null => {
  if (relevant === true) {
    return (
      <Chip data-testid={dataTestId} size="xs" variant="outline" css={chipCss}>
        Relevant
      </Chip>
    );
  }

  if (studyLabel == null || studyLabel === '') {
    return null;
  }

  return (
    <Chip
      data-testid={dataTestId}
      size="xs"
      css={css`
        ${chipCss};
        background: ${studyColor};
      `}
    >
      {studyLabel}
    </Chip>
  );
};

import { PARAGRAPH_PLUGIN_ID } from './types';
import { splitInlineBookmark } from '../inlineBookmark/utils';
import type { ParagraphPluginElementMutable } from './types';
import { Text, Node, Editor, Transforms, Point } from '../../core';
import { Element, Location, NodeEntry, Path, Range } from 'slate';
import { ParagraphPluginElement } from '../../slate-custom-types';

export const createParagraph = (text?: string): ParagraphPluginElementMutable => ({
  type: PARAGRAPH_PLUGIN_ID,
  children: [{ text: text ?? '' }],
});

export const createParagraphWithChildren = (
  children: Array<Node>
): ParagraphPluginElementMutable => ({
  type: PARAGRAPH_PLUGIN_ID,
  children: children,
});

export const createInlineParagraphWithChildren = (
  children: Array<Node>
): ParagraphPluginElementMutable => ({
  type: PARAGRAPH_PLUGIN_ID,
  shouldForceInline: true,
  children: children,
});

export const createInlineParagraph = (text?: string): ParagraphPluginElementMutable => ({
  type: PARAGRAPH_PLUGIN_ID,
  shouldForceInline: true,
  children: [{ text: text ?? ' ' }],
});

export const isInlineNode = (node: Node): boolean => {
  return (
    Element.isElement(node) &&
    (node.type === PARAGRAPH_PLUGIN_ID && node.shouldForceInline) === true
  );
};

export const isInlineParagraph = (node: Node): boolean => {
  return Element.isElement(node) && node.type === PARAGRAPH_PLUGIN_ID && isInlineNode(node);
};

export const isRegularParagraph = (node: Node): boolean => {
  return Element.isElement(node) && node.type === PARAGRAPH_PLUGIN_ID && !isInlineNode(node);
};

export const isNewline = (node?: Node): boolean => {
  return (
    node != null &&
    Element.isElement(node) &&
    isRegularParagraph(node) &&
    Array.isArray(node.children) &&
    node.children.every((n) => Text.isText(n)) &&
    Node.string(node).trim() === ''
  );
};

export const isEmptyParagraph = (node: Node): boolean => {
  return (
    Element.isElement(node) &&
    node.type === PARAGRAPH_PLUGIN_ID &&
    Node.string(node).trim() === '' &&
    Array.isArray(node.children) &&
    node.children.length === 1
  );
};

export const getOrInsertNextInlineParagraph = (
  editor: Editor,
  selection: Range
): ParagraphPluginElement | null | undefined => {
  const currentNodeEntry = Editor.node(editor, Editor.start(editor, selection));
  let nextInlineParagraphEntry = Editor.next(editor, {
    at: selection,
    match: (n: Node) => isInlineParagraph(n),
  });

  if (currentNodeEntry != null && nextInlineParagraphEntry == null) {
    Transforms.insertNodes(editor, [createInlineParagraph()], {
      at: Editor.after(editor, currentNodeEntry[1]) ?? Editor.end(editor, []),
    });
    nextInlineParagraphEntry = Editor.next(editor, {
      at: selection,
      match: (n: Node) => isInlineParagraph(n),
    });
  }

  return nextInlineParagraphEntry as unknown as ParagraphPluginElement;
};

export const insertParagraph = (editor: Editor): void => {
  const { selection } = editor;
  if (selection == null) return;

  Editor.withoutNormalizing(editor, () => {
    splitInlineBookmark(editor);

    Transforms.insertNodes(editor, createParagraph(), { at: selection });
  });
};

export const getParagraphNodeStartingWithTextInRange = ({
  editor,
  text,
  range,
}: {
  editor: Editor;
  text: string;
  range: Range;
}): NodeEntry | null | undefined => {
  const paragraphNode = Editor.nodes(editor, {
    at: range,
    match: (n: Node) =>
      Element.isElement(n) &&
      n.type === PARAGRAPH_PLUGIN_ID &&
      Node.string(n).trim().toLowerCase().startsWith(text.toLowerCase()),
  }).next();

  if (!paragraphNode.done) {
    return paragraphNode.value as NodeEntry;
  }

  return null;
};

// Given a location and a search term, this function will search
// the provided slate location for the search term,
// even if the term is split across multiple nodes.
// NOTE: this currently only works if it is split across multiple
// nodes within the SAME paragraph
export const findSearchTermInParagraphs = (
  editor: Editor,
  at: Location,
  searchTerm: string
): Array<Range> => {
  const matchedRanges: Array<Range> = [];

  const searchTermLower = searchTerm.toLowerCase();

  const paragraphNodeGenerator = Editor.nodes(editor, {
    at,
    match: (n: Node) => Element.isElement(n) && n.type === PARAGRAPH_PLUGIN_ID,
  });

  for (const [, paragraphPath] of Array.from(paragraphNodeGenerator)) {
    const fullText = Editor.string(editor, paragraphPath);
    const fullTextLower = fullText.toLowerCase();

    let index = fullTextLower.indexOf(searchTermLower);

    while (index !== -1) {
      const startOffset = index;
      const endOffset = index + searchTerm.length;

      const matchRange = calculateRangeForOffsets(editor, paragraphPath, startOffset, endOffset);

      if (matchRange != null) {
        const { anchor, focus } = matchRange;
        matchedRanges.push({
          anchor,
          focus,
        });
      }

      index = fullTextLower.indexOf(searchTermLower, index + 1);
    }
  }

  return matchedRanges;
};

// Given an array of ranges, this will go through the ranges in reverse, from the
// end of the slate document to the start, and delete the text in each range.
export const deleteTextRanges = (
  editor: Editor,
  ranges: Array<Range>
): Range | null | undefined => {
  const sortedRanges = [...ranges].sort((a, b) => {
    if (Point.isAfter(a.anchor, b.anchor)) {
      return -1;
    } else if (Point.isAfter(b.anchor, a.anchor)) {
      return 1;
    } else {
      return 0;
    }
  });

  let lastDeletedAt = null;

  sortedRanges.forEach((match) => {
    const range = {
      anchor: match.anchor,
      focus: match.focus,
    } as const;

    Transforms.select(editor, range);
    Editor.deleteFragment(editor);
    lastDeletedAt = editor.selection;
  });

  return lastDeletedAt;
};

// Given a path and a start and end offset, this function will return a range
// if the the texts at this path contain the start and end offsets. This is often
// used in conjunction with searchParagraphsForSearchTerm to find text that
// is split across multiple nodes.
export const calculateRangeForOffsets = (
  editor: Editor,
  nodePath: Path,
  startOffset: number,
  endOffset: number
): Range | null | undefined => {
  let anchor = null;
  let focus = null;
  let totalOffset = 0;

  for (const [node, path] of Array.from(
    Editor.nodes(editor, {
      at: nodePath,
      match: Text.isText,
    })
  )) {
    const textLength = node.text.length;

    // Determine if the start or end offset falls within this text node
    if (totalOffset <= startOffset && startOffset < totalOffset + textLength) {
      anchor = {
        path,
        offset: startOffset - totalOffset,
      };
    }

    if (totalOffset <= endOffset && endOffset <= totalOffset + textLength) {
      focus = {
        path,
        offset: endOffset - totalOffset,
      };
      break;
    }

    totalOffset += textLength;
  }

  if (anchor != null && focus != null) {
    return {
      anchor,
      focus,
    };
  }

  return null;
};

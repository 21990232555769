import { HeadingLevel as GraphQLHeadingLevel } from 'generated/graphql';
import type { HeadingStyle, TextTransform, TextDecoration } from 'generated/graphql';
import { HeadingLevel } from '../constants';
import type { THeadingLevel } from '../constants';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_TEXT_DECORATION,
  DEFAULT_FONT_FAMILY,
} from 'hooks/useReporterStyles';
import { Fonts } from 'styles';
import { mapHeadingLevel } from './normalization';
import { ReactComponent as HeaderOneIcon } from 'assets/H1.svg';
import { ReactComponent as HeaderTwoIcon } from 'assets/H2.svg';
import { ReactComponent as HeaderOneSelectedIcon } from 'assets/H1Selected.svg';
import { ReactComponent as HeaderTwoSelectedIcon } from 'assets/H2Selected.svg';
import { ReactComponent as HeaderOneDisabledIcon } from 'assets/H1Disabled.svg';
import { ReactComponent as HeaderTwoDisabledIcon } from 'assets/H2Disabled.svg';

export const getDefaultHeadingStyles = (): Array<HeadingStyle> => {
  return Object.keys(GraphQLHeadingLevel).map(
    (level): HeadingStyle => ({
      // @ts-expect-error [EN-7967] - TS2322 - Type 'string' is not assignable to type 'HeadingLevel'.
      level,
      textStyle: {
        fontSize: DEFAULT_FONT_SIZE,
        // @ts-expect-error [EN-7967] - TS2322 - Type '"capitalize" | "uppercase"' is not assignable to type 'TextTransform'.
        textTransform: level === 'H1' ? 'uppercase' : 'capitalize',
        textDecoration: {
          isBold: false,
          isItalic: false,
          isUnderline: false,
        },
      },
    })
  );
};

export const getDefaultHeadingTextTransform = (level: THeadingLevel): TextTransform => {
  switch (level) {
    case HeadingLevel.H1:
      // @ts-expect-error [EN-7967] - TS2322 - Type '"uppercase"' is not assignable to type 'TextTransform'.
      return 'uppercase';
    case HeadingLevel.H2:
      // @ts-expect-error [EN-7967] - TS2322 - Type '"capitalize"' is not assignable to type 'TextTransform'.
      return 'capitalize';
    default:
      // @ts-expect-error [EN-7967] - TS2322 - Type '"uppercase"' is not assignable to type 'TextTransform'.
      return 'uppercase';
  }
};

export const getHeadingIcon = ({
  level,
  isActive,
  isDisabled,
}: {
  level: THeadingLevel;
  isActive: boolean;
  isDisabled: boolean;
}): // $FlowFixMe[unclear-type]
React.ComponentType<any> => {
  switch (level) {
    case HeadingLevel.H1:
      return isActive ? HeaderOneSelectedIcon : isDisabled ? HeaderOneDisabledIcon : HeaderOneIcon;
    case HeadingLevel.H2:
      return isActive ? HeaderTwoSelectedIcon : isDisabled ? HeaderTwoDisabledIcon : HeaderTwoIcon;
    default:
      return HeaderOneIcon;
  }
};

export const getHeadingFontSize = (
  headingStyles: Array<HeadingStyle>,
  level: THeadingLevel
): number => {
  return (
    headingStyles.find(
      (headingStyle) =>
        // @ts-expect-error [EN-7967] - TS2367 - This comparison appears to be unintentional because the types 'HeadingLevel' and 'number' have no overlap.
        mapHeadingLevel(headingStyle.level) === level || headingStyle.level === level
    )?.textStyle?.fontSize ?? DEFAULT_FONT_SIZE
  );
};

export const getHeadingTextTransform = (
  headingStyles: Array<HeadingStyle>,
  level: THeadingLevel
): TextTransform => {
  return (
    headingStyles.find(
      (headingStyle) =>
        // @ts-expect-error [EN-7967] - TS2367 - This comparison appears to be unintentional because the types 'HeadingLevel' and 'number' have no overlap.
        mapHeadingLevel(headingStyle.level) === level || headingStyle.level === level
    )?.textStyle?.textTransform ?? getDefaultHeadingTextTransform(level)
  );
};

export const getHeadingTextDecoration = (
  headingStyles: Array<HeadingStyle>,
  level: THeadingLevel
): TextDecoration => {
  return (
    headingStyles.find(
      (headingStyle) =>
        // @ts-expect-error [EN-7967] - TS2367 - This comparison appears to be unintentional because the types 'HeadingLevel' and 'number' have no overlap.
        mapHeadingLevel(headingStyle.level) === level || headingStyle.level === level
    )?.textStyle?.textDecoration ?? DEFAULT_TEXT_DECORATION
  );
};

export const getHeadingFontFamily = (
  headingStyles: Array<HeadingStyle>,
  level: THeadingLevel
): string => {
  const fontFamily = headingStyles.find(
    // @ts-expect-error [EN-7967] - TS2367 - This comparison appears to be unintentional because the types 'HeadingLevel' and 'number' have no overlap.
    (headingStyle) => mapHeadingLevel(headingStyle.level) === level || headingStyle.level === level
  )?.textStyle?.fontFamily;

  return fontFamily != null ? Fonts[fontFamily] : DEFAULT_FONT_FAMILY;
};

export const getHeadingSettingsLabel = (level: GraphQLHeadingLevel): string => {
  const headingLevel = mapHeadingLevel(level);
  switch (headingLevel) {
    case HeadingLevel.H1:
      return 'Headings (H1)';
    case HeadingLevel.H2:
      return 'Subheadings (H2)';
    default:
      return '';
  }
};

import type { EditorPlugin } from '../../types';
import { HISTORY_PLUGIN_ID } from './types';
import type { HistoryPluginOptions, HistoryPluginPropertyOptions } from './types';
import { withHistory } from 'slate-history';
import { onKeyDownHistory } from './onKeyDownHistory';

export const HistoryPlugin = ({
  pluginName = 'History',
}: HistoryPluginOptions = {}): EditorPlugin => {
  const options: HistoryPluginPropertyOptions = {
    pluginID: HISTORY_PLUGIN_ID,
    pluginName,
  };

  return {
    pluginID: options.pluginID,
    enhanceEditorState: withHistory,
    onKeyDown: onKeyDownHistory(options),
  };
};

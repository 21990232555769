import * as React from 'react';
import type { CreateRenderLeaf } from '../../types';
import type { DictationPluginPropertyOptions } from './types';

export const renderLeafDictation: CreateRenderLeaf<DictationPluginPropertyOptions> = ({
  component: Component,
  pluginID,
}) =>
  // @ts-expect-error [EN-7967] - TS2322 - Type '({ leaf, attributes, children, ...rest }: RenderLeafProps<BaseText>) => ReactNode' is not assignable to type 'RenderLeaf<BaseText>'.
  function RenderLeafDictation({ leaf, attributes, children, ...rest }): React.ReactNode {
    if (typeof pluginID === 'string' && leaf[pluginID] != null && leaf.text != null) {
      return <Component data-editor-leaf={pluginID} leaf={leaf} children={children} {...rest} />;
    }

    return children;
  };

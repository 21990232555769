import type { ApolloError } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import { memo, useState } from 'react';
import analytics from 'modules/analytics';
import { reporter } from 'modules/analytics/constants';

import { Button } from 'common/ui/Button';
import { Colors } from 'styles';
import { Stack } from 'common/ui/Layout';
import { useFieldsCountdown, useFieldsDispatch } from './Fields.Context';
import {
  reportStatusState,
  IN_FLIGHT_STATUSES,
  ReportStatus,
  headingErrorState,
  DISPLAY_DISCARD_REPORT_BUTTON_STATUSES,
  dictationQueueSizeState,
} from '../state';
import type { ReportStatuses } from '../state';
import { useRecoilValue } from 'recoil';
import { FF, useSplitFlag } from 'modules/feature-flags';
import Tooltip from 'common/ui/Tooltip';
import { CASE_ACTIONS } from 'config/constants';
import { useReportLockedState } from '../hooks/useReportLockedState';
import { useCurrentWorklistItems } from 'hooks/useCurrentWorklistItems';
import Text from 'common/ui/Text';
import { formatStudyInformation } from 'common/StudyInformationCard';
import { useIsCaseGroupSubmitting } from '../hooks/useIsCaseGroupSubmitting';
import { useDiscardReport } from 'hooks/useDiscardReport';
import { useOpenCase } from 'hooks/useOpenCase';
import { EmptyPlaceholderFieldsWarning } from './Dialog/EmptyPlaceholderFieldsWarning';
import { RequiredFieldsEnforcement } from './Dialog/RequiredFieldsEnforcement';
import UnsignedReportWarningDialog from '../UnsignedReportWarningDialog/UnsignedReportWarningDialog';
import Input from 'common/ui/Input';
import { useDebouncedCallback } from 'use-debounce';
import { AUTO_SAVE_DEBOUNCE_MS } from './constants';
import { useMutation } from '@apollo/client';
import { ASSIGN_EXAM_COUNT } from 'modules/Apollo/queries';
import { useToasterDispatch } from 'common/ui/Toaster';
import { logger } from 'modules/logger';
/* eslint-disable import/extensions */
import FieldsSubmitButton from './Fields.SubmitButton';
import { NAMESPACES, sendEvent } from 'modules/EventsManager';

const PendingDictationsTooltip = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const { pendingDictations, pendingWebsocketDictations, openWebsocketConnections } =
    useRecoilValue(dictationQueueSizeState);

  const pending = Math.max(pendingDictations + pendingWebsocketDictations, 0);
  const isDictationComplete = Math.max(pending + openWebsocketConnections, 0) === 0;

  if (isDictationComplete) {
    return <span>{children}</span>;
  }

  return (
    <Tooltip
      centerContent
      content={
        <>
          Actively Transcribing {openWebsocketConnections}
          <br />
          Queued Transcriptions {pending}
        </>
      }
    >
      <span>{children}</span>
    </Tooltip>
  );
};

export const DraftButton = ({
  PendingDictationsTooltip,
  submitting,
  isDisabled,
  onSaveDraft,
  isSavingDraft,
}: {
  // @ts-expect-error [EN-7967] - TS2502 - 'PendingDictationsTooltip' is referenced directly or indirectly in its own type annotation.
  PendingDictationsTooltip: typeof PendingDictationsTooltip;
  submitting: boolean;
  isDisabled: boolean;
  onSaveDraft: () => Promise<void>;
  isSavingDraft: boolean;
}): React.ReactElement => {
  const status = useRecoilValue<ReportStatuses>(reportStatusState);

  return (
    <PendingDictationsTooltip>
      <Button
        variant="ghost"
        data-testid="save-draft-button"
        onClick={onSaveDraft}
        css={`
          margin-right: ${[
            ReportStatus.ProvisionalSubmit,
            ReportStatus.ProvisionalAddendumSubmit,
            // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'ReportStatuses' is not assignable to parameter of type '"provisionalReportSubmit" | "provisionalAddendumSubmit"'.
          ].includes(status)
            ? -32 // The countdown box model is odd due to the CSS, so easiest way to reduce the spacing is to add a negative margin
            : 16}px;
          padding: 4px 24px;
        `}
        disabled={
          isSavingDraft ||
          submitting ||
          isDisabled ||
          // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'ReportStatuses' is not assignable to parameter of type '"provisionalReportSubmit" | "provisionalAddendumSubmit"'.
          [ReportStatus.ProvisionalSubmit, ReportStatus.ProvisionalAddendumSubmit].includes(status)
        }
      >
        {isSavingDraft ? (
          <Stack space="small">
            <div
              css={`
                margin: 0 12px -5px -15px;
                padding: 2px 0 0 0;
              `}
            >
              <CircularProgress size="20px" style={{ color: Colors.white }} />
            </div>
            <Text color={Colors.gray7}>Draft</Text>
          </Stack>
        ) : (
          <Text color={Colors.gray8}>Draft</Text>
        )}
      </Button>
    </PendingDictationsTooltip>
  );
};

type FieldsSubmitProps = {
  isDisabled: boolean;
  isClaimedByMe: boolean;
  initialExamCount?: number;
  smid?: string;
};

export const FieldsSubmit: React.ComponentType<FieldsSubmitProps> = memo(
  ({
    isDisabled = false,
    isClaimedByMe = false,
    initialExamCount = 1,
    smid,
  }: FieldsSubmitProps): React.ReactElement => {
    const { claimedBy } = useReportLockedState();
    const {
      handleSubmit: onSubmit,
      handleCancelSubmit: onCancelSubmit,
      handleCancelAddAddendum,
      handleAddendumAdd,
      handleSaveDraft,
    } = useFieldsDispatch();
    const { handleOpenDiscardReportModal } = useDiscardReport();
    const { countdownSeconds } = useFieldsCountdown();
    const status = useRecoilValue<ReportStatuses>(reportStatusState);
    const hasHeadingError = useRecoilValue<boolean>(headingErrorState);
    const [isReporterSubmissionEnabled] = useSplitFlag(
      FF.ENABLE_REPORTER_SUBMISSION,
      'on',
      (v) => v === 'on'
    );
    const [submitting, setSubmitting] = useState(false);
    const [isSavingDraft, setIsSavingDraft] = useState(false);
    const { caseInGroupSubmitting, isCaseGroupSubmitting, isCurrentCaseSubmitting } =
      useIsCaseGroupSubmitting();
    const { openCase } = useOpenCase();

    const handleSubmit = async (onSuccess?: () => void, onError?: () => void) => {
      setSubmitting(true);
      try {
        await onSubmit({ onSuccess, onError });
        if (currentWorklistItems.length > 1) {
          analytics.track(reporter.usr.submitLinkedCases, {
            worklistItems: currentWorklistItems.map(({ smid }) => smid),
          });
        }

        sendEvent(NAMESPACES.CROSS_WINDOW_DATA_REFETCH, {
          type: 'claimedItems',
        });
      } finally {
        analytics.stopReadSession();
        setSubmitting(false);
      }
    };

    const { currentWorklistItems } = useCurrentWorklistItems();
    const shouldDisplayWorklistItems = currentWorklistItems.length > 1;

    const isOtherCaseInGroupSubmitting = isCaseGroupSubmitting && !isCurrentCaseSubmitting;

    const shouldDisplayDiscardReportButton =
      isClaimedByMe === true &&
      (DISPLAY_DISCARD_REPORT_BUTTON_STATUSES as ReportStatuses[]).includes(status);

    const [isExamCountEnabled] = useSplitFlag(FF.REPORTER_EXAM_COUNT, 'on', (v) => v === 'on');
    const [examCount, setExamCount] = useState(initialExamCount ?? 1);
    const { enqueueToast } = useToasterDispatch();
    const [assignExamCount] = useMutation(ASSIGN_EXAM_COUNT, {
      onError(error: ApolloError): undefined {
        enqueueToast('Failed to update exam count to report. Please try again later.');
        logger.error(`Failed to update exam count to report with examCount: ${examCount}`, error);
        setExamCount(initialExamCount ?? 1);
      },
    });

    const isCountdownMode =
      (isClaimedByMe && status === ReportStatus.ProvisionalSubmit) ||
      // You can submit addendums if a case is claimed, unclaimed, or claimed by someone else
      status === ReportStatus.ProvisionalAddendumSubmit;

    const isDisabledInFlightOrSubmitting =
      isDisabled || (IN_FLIGHT_STATUSES as ReportStatuses[]).includes(status) || submitting;

    const onSaveDraft = async () => {
      setIsSavingDraft(true);
      await handleSaveDraft();
      setIsSavingDraft(false);
    };

    const debouncedExamCount = useDebouncedCallback((examCount: number) => {
      if (currentWorklistItems.length === 0 || !examCount || smid == null) return;
      assignExamCount({
        variables: { input: { smid, count: examCount } },
      });
    }, AUTO_SAVE_DEBOUNCE_MS);

    const handleExamCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputNum = Number(e.target.value);
      if (e.target.value === '' || inputNum < 1) {
        setExamCount(Number(e.target.value));
        return;
      }
      // exam count should be at least 1
      setExamCount(inputNum);
      logger.info(`Exam count changed to ${inputNum}`);
      debouncedExamCount(inputNum);
    };

    const handleExamCountValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputNum = Number(e.target.value);
      const examCount = e.target.value === '' || inputNum < 1 ? 1 : inputNum;
      // exam count should be at least 1
      setExamCount(examCount);
    };

    return (
      <Stack
        css={`
          box-sizing: border-box;
        `}
        vertical
      >
        <RequiredFieldsEnforcement />
        <EmptyPlaceholderFieldsWarning />
        <UnsignedReportWarningDialog submitting={submitting} onSubmit={handleSubmit} />
        <Stack vertical alignX="end" alignY="center" space="medium">
          <Stack
            space="medium"
            alignX={
              shouldDisplayDiscardReportButton === true || status === ReportStatus.AddendumAdd
                ? 'between'
                : 'end'
            }
            alignY={isExamCountEnabled ? 'start' : 'center'}
            stretchX
          >
            {shouldDisplayDiscardReportButton === true && (
              <Button
                variant="ghost-error"
                data-testid="discard-report-button"
                onClick={handleOpenDiscardReportModal}
                disabled={status === ReportStatus.ProvisionalSubmit || isOtherCaseInGroupSubmitting}
              >
                Discard
              </Button>
            )}
            {status === ReportStatus.AddendumAdd && (
              <Button
                variant="ghost-error"
                data-testid="discard-addendum-button"
                onClick={() => handleCancelAddAddendum()}
              >
                Discard
              </Button>
            )}
            <Stack vertical={isExamCountEnabled} alignX="end" alignY="center" space="medium">
              {isExamCountEnabled && (
                <Stack css="margin-bottom: 8px!important" horizontal alignY="center">
                  <label
                    css={`
                      color: ${isDisabledInFlightOrSubmitting || isCountdownMode
                        ? Colors.gray8
                        : Colors.white};
                    `}
                    htmlFor="exam-count"
                  >
                    Exam Count:
                  </label>
                  <Input
                    css={`
                      width: ${`${34 + (examCount.toString().length - 1) * 6}px`};
                      margin-left: 8px;
                      border-radius: 4px;
                      padding: 0 10px;
                      -moz-appearance: textfield;
                      ::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                      }
                      ::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                      }
                      :disabled {
                        background: ${Colors.gray5};
                        color: ${Colors.gray8};
                        cursor: not-allowed;
                      }
                    `}
                    as="input"
                    id="exam-count"
                    onKeyDown={(event) => {
                      if (event.key === '.') {
                        event.preventDefault();
                      }
                    }}
                    onInput={(event) =>
                      ((event.target as HTMLInputElement).value = (
                        event.target as HTMLInputElement
                      ).value.replace(/[^0-9]*/g, ''))
                    }
                    value={examCount}
                    onChange={handleExamCountChange}
                    onBlur={handleExamCountValidation}
                    type="number"
                    data-testid="exam-count"
                    disabled={isDisabledInFlightOrSubmitting || isCountdownMode}
                  />
                </Stack>
              )}
              <Stack horizontal alignX="end" alignY="center">
                {(claimedBy == null || isClaimedByMe === true) && // if unclaimed OR optimistic rendering only as soon as user's submission is done
                  status === ReportStatus.Submitted && (
                    <PendingDictationsTooltip>
                      <Button
                        css="margin-right: 16px;"
                        variant="ghost"
                        data-testid="add-addendum-button"
                        onClick={handleAddendumAdd}
                      >
                        Add Addendum
                      </Button>
                    </PendingDictationsTooltip>
                  )}
                <DraftButton
                  PendingDictationsTooltip={PendingDictationsTooltip}
                  submitting={submitting}
                  isDisabled={isDisabled || isOtherCaseInGroupSubmitting}
                  onSaveDraft={onSaveDraft}
                  isSavingDraft={isSavingDraft}
                />
                {isReporterSubmissionEnabled && (
                  <PendingDictationsTooltip>
                    <FieldsSubmitButton
                      isCountdownMode={isCountdownMode}
                      countdownSeconds={countdownSeconds}
                      isClaimedByMe={isClaimedByMe}
                      onSubmit={handleSubmit}
                      onCancelSubmit={onCancelSubmit}
                      status={status}
                      disabled={isDisabledInFlightOrSubmitting || hasHeadingError}
                      isOtherCaseInGroupSubmitting={isOtherCaseInGroupSubmitting}
                    />
                  </PendingDictationsTooltip>
                )}
              </Stack>
            </Stack>
          </Stack>
          {isOtherCaseInGroupSubmitting && caseInGroupSubmitting?.smid != null && (
            <Text variant="small">
              To edit or cancel signing of the group,{' '}
              <Text
                as="button"
                onClick={async () => {
                  await openCase({ smid: caseInGroupSubmitting.smid, action: CASE_ACTIONS.READ });
                }}
                variant="small"
                css={`
                  cursor: pointer;
                  color: ${Colors.blue4};
                  text-decoration: underline;
                  background-color: transparent;
                  border: none;
                  padding: 0;
                `}
              >
                open the primary exam
              </Text>
              .
            </Text>
          )}
          {shouldDisplayWorklistItems && (
            <Stack vertical alignX="end" css="padding-top: 1.2rem">
              <Text variant="button">
                {(() => {
                  switch (status) {
                    case ReportStatus.Submitted:
                      return 'Report signed for:';
                    case ReportStatus.AddendumAdd:
                      return 'Signing addendum for:';
                    default:
                      return 'Signing report for:';
                  }
                })()}
              </Text>
              {currentWorklistItems.map(({ studyDescription, accessionNumber, studyDate }, idx) => (
                <Text variant="small" key={`text-for-link-${idx}`}>
                  {formatStudyInformation(studyDescription, accessionNumber, studyDate)}
                </Text>
              ))}
            </Stack>
          )}
        </Stack>
      </Stack>
    );
  }
);

FieldsSubmit.displayName = 'FieldsSubmit';

import { createContext } from 'react';
import type { VtkAnnotation, SegmentPointPositionTuple } from '../types';

export const AnnotationMutationsContext: React.Context<{
  updateAnnotation: (
    id: string
  ) => (annotation: Partial<VtkAnnotation>) => Promise<undefined> | undefined | null | undefined;
  updateAnnotationSegments: (
    arg1: string,
    arg2?: boolean | null | undefined
  ) => (arg1: Array<SegmentPointPositionTuple>) => Promise<unknown> | undefined | null | undefined;
  deleteAnnotation: (arg1: string) => Promise<unknown> | undefined | null | undefined;
}> = createContext({
  updateAnnotation: null,
  deleteAnnotation: null,
  updateAnnotationSegments: null,
});

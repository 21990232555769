import { useCurrentCaseId } from 'hooks/useCurrentCase';
import { useEffect, useRef, useCallback } from 'react';
import { useViewerId } from 'hooks/useViewerId';
import analytics from 'modules/analytics';
import { marks, measures } from '../performance';
import { viewer } from 'modules/analytics/constants';
import { performance } from 'utils/performance';
import { logger } from 'modules/logger';

type ViewportRenderMeta = {
  instanceCount: number;
  fromCache?: boolean;
  lazyLoaded?: boolean;
  renderMethod: string;
  unpackingMethod: 'tardah';
};

type ViewportMetrics = Partial<
  {
    stackSmid?: string;
    finished: boolean;
    error: boolean;
    renderTime?: number;
    renderTimeFromLoadStart?: number;
    isPriorStudy?: boolean;
    timeToViewportLoadStart?: number;
    interactable?: boolean;
    interactableTime?: number;
    interactableTimeFromLoadStart?: number;
    finishedTime?: number;
    finishedTimeFromLoadStart?: number;
    errorTime?: number;
    empty?: boolean;
  } & ViewportRenderMeta
>;

type AllViewportMetrics = Partial<{
  [viewportId: string]: ViewportMetrics;
}>;

const getMetaAnalytics = () => {
  const memoryAnalytics = analytics.getCurrentMemory();
  const isOnInitialLoad = analytics.isOnInitialLoad();

  return { ...memoryAnalytics, is_on_initial_load: isOnInitialLoad };
};

const __VIEWPORT_META__: AllViewportMetrics = {};

function getInitialViewports() {
  return Object.keys(__VIEWPORT_META__).filter((key) => {
    const meta = __VIEWPORT_META__[key];
    return meta.lazyLoaded !== true;
  });
}

function resetInitialViewports() {
  Object.keys(__VIEWPORT_META__).forEach((key) => {
    delete __VIEWPORT_META__[key];
  });
}

export const useViewportMetrics = (viewportsToRender: string[]) => {
  const currentCaseSmid = useCurrentCaseId();
  const metricsReported = useRef(false);
  const interactableReported = useRef(false);
  const intervalRef = useRef(null);
  const viewerId = useViewerId();

  useEffect(() => {
    resetInitialViewports();
  }, []);

  // Effect to monitor viewer performance and log analytics. Once all viewports have been
  // rendered, a final event with overall metadata for all viewports will be sent to analytics.
  useEffect(() => {
    const reportFinalMetrics = () => {
      const baseMetrics = {
        instance_count: 0,
        first_viewport_finished: 0,
        all_viewports_finished: 0,
        first_viewport_rendered: 0,
        all_viewports_rendered: 0,
        first_viewport_interactable: 0,
        all_viewports_interactable: 0,
        has_viewports_with_error: false,
        first_viewport_error: -1,
        all_viewport_error: -1,
        slices_per_second: -1,
      } as const;

      const aggregatedMetrics = Object.entries(__VIEWPORT_META__).reduce(
        (acc, [viewportId, meta]: [any, any]) => {
          const addend = meta.finished === true ? meta.instanceCount : 0;
          const metrics = meta.fromCache ? acc.cache : acc.network;

          if (acc.render_method == null) {
            // Take the first render method since all viewports use the same render method
            acc.render_method = meta.renderMethod;
          }

          if (meta.isPriorStudy === true) {
            acc.num_prior_studies++;
          } else {
            acc.num_current_studies++;
          }

          acc.total_instance_count += addend;
          metrics.instance_count += addend;
          // @ts-expect-error [EN-7967] - TS2322 - Type 'number' is not assignable to type '0'.
          metrics.first_viewport_rendered = Math.min(
            metrics.first_viewport_rendered,
            meta.renderTime
          );
          // @ts-expect-error [EN-7967] - TS2322 - Type 'number' is not assignable to type '0'.
          metrics.all_viewports_rendered = Math.max(
            metrics.all_viewports_rendered,
            meta.renderTime
          );
          // @ts-expect-error [EN-7967] - TS2322 - Type 'number' is not assignable to type '0'.
          metrics.first_viewport_interactable = Math.min(
            metrics.first_viewport_interactable,
            meta.interactableTime
          );
          // @ts-expect-error [EN-7967] - TS2322 - Type 'number' is not assignable to type '0'.
          metrics.all_viewports_interactable = Math.max(
            metrics.all_viewports_interactable,
            meta.interactableTime
          );
          // @ts-expect-error [EN-7967] - TS2322 - Type 'number' is not assignable to type '0'.
          metrics.first_viewport_finished = Math.min(
            metrics.first_viewport_finished,
            meta.finishedTime
          );
          // @ts-expect-error [EN-7967] - TS2322 - Type 'number' is not assignable to type '0'.
          metrics.all_viewports_finished = Math.max(
            metrics.all_viewports_finished,
            meta.finishedTime
          );
          // @ts-expect-error [EN-7967] - TS2322 - Type 'number' is not assignable to type '-1'.
          metrics.first_viewport_error = meta.error
            ? Math.min(metrics.first_viewport_error, meta.errorTime)
            : metrics.first_viewport_error;
          // @ts-expect-error [EN-7967] - TS2322 - Type 'number' is not assignable to type '-1'.
          metrics.all_viewport_error = meta.error
            ? Math.max(metrics.all_viewport_error, meta.errorTime)
            : metrics.all_viewport_error;
          metrics.has_viewports_with_error = metrics.has_viewports_with_error || meta.error;

          acc.all_viewports_rendered_from_load_start = Math.max(
            acc.all_viewports_rendered_from_load_start,
            meta.renderTimeFromLoadStart
          );
          acc.all_viewports_interactable_from_load_start = Math.max(
            acc.all_viewports_interactable_from_load_start,
            meta.interactableTimeFromLoadStart
          );
          acc.all_viewports_finished_from_load_start = Math.max(
            acc.all_viewports_finished_from_load_start,
            meta.finishedTimeFromLoadStart
          );

          return acc;
        },
        {
          render_method: null,
          all_viewports_rendered_from_load_start: -1,
          all_viewports_interactable_from_load_start: -1,
          all_viewports_finished_from_load_start: -1,
          num_prior_studies: 0,
          num_current_studies: 0,
          total_instance_count: 0,
          network: { ...baseMetrics },
          cache: { ...baseMetrics },
        }
      );

      // @ts-expect-error [EN-7967] - TS2322 - Type 'number' is not assignable to type '-1'.
      aggregatedMetrics.network.slices_per_second =
        aggregatedMetrics.network.instance_count > 0
          ? aggregatedMetrics.network.instance_count /
            (aggregatedMetrics.network.all_viewports_finished / 1000)
          : -1;
      // @ts-expect-error [EN-7967] - TS2322 - Type 'number' is not assignable to type '-1'.
      aggregatedMetrics.cache.slices_per_second =
        aggregatedMetrics.cache.instance_count > 0
          ? aggregatedMetrics.cache.instance_count /
            (aggregatedMetrics.cache.all_viewports_finished / 1000)
          : -1;

      analytics.timing('viewer_viewports_initial_render_finished');
      analytics.track(viewer.sys.renderMetadata, {
        ...getMetaAnalytics(),
        ...aggregatedMetrics,
        viewerId,
        engine: 'DRE',
        case_smid: currentCaseSmid,
      });

      // Track memory every minute after all initial viewports are loaded
      const trackMemory = () => {
        analytics.track(viewer.sys.renderMemory, {
          ...analytics.getCurrentMemory(),
        });
      };

      trackMemory();
      intervalRef.current = setInterval(trackMemory, 60 * 1000);
    };

    const reportInteractable = () => {
      const baseMetrics = {
        instance_count: 0,
        first_viewport_interactable: 0,
        all_viewports_interactable: 0,
        has_viewports_with_error: false,
        first_viewport_error: -1,
        all_viewport_error: -1,
      } as const;

      const aggregatedMetrics = Object.entries(__VIEWPORT_META__).reduce(
        (acc, [viewportId, meta]: [any, any]) => {
          const metrics = meta.fromCache ? acc.cache : acc.network;

          if (meta.interactable === true) {
            acc.total_viewports++;
            metrics.instance_count++;
          }

          if (acc.render_method == null) {
            // Take the first render method since all viewports use the same render method
            acc.render_method = meta.renderMethod;
          }

          if (meta.empty === false) {
            if (meta.isPriorStudy === true) {
              acc.num_prior_studies++;
            } else {
              acc.num_current_studies++;
            }
          }

          acc.all_viewports_loading_from_load_start = Math.max(
            acc.all_viewports_loading_from_load_start,
            meta.timeToViewportLoadStart
          );

          // @ts-expect-error [EN-7967] - TS2322 - Type 'number' is not assignable to type '0'.
          metrics.first_viewport_interactable = Math.min(
            metrics.first_viewport_interactable,
            meta.interactableTime
          );
          // @ts-expect-error [EN-7967] - TS2322 - Type 'number' is not assignable to type '0'.
          metrics.all_viewports_interactable = Math.max(
            metrics.all_viewports_interactable,
            meta.interactableTime
          );
          // @ts-expect-error [EN-7967] - TS2322 - Type 'number' is not assignable to type '-1'.
          metrics.first_viewport_error = meta.error
            ? Math.min(metrics.first_viewport_error, meta.errorTime)
            : metrics.first_viewport_error;
          // @ts-expect-error [EN-7967] - TS2322 - Type 'number' is not assignable to type '-1'.
          metrics.all_viewport_error = meta.error
            ? Math.max(metrics.all_viewport_error, meta.errorTime)
            : metrics.all_viewport_error;
          metrics.has_viewports_with_error = metrics.has_viewports_with_error || meta.error;

          acc.all_viewports_interactable_from_load_start = Math.max(
            acc.all_viewports_interactable_from_load_start,
            meta.interactableTimeFromLoadStart
          );
          return acc;
        },
        {
          render_method: null,
          all_viewports_interactable_from_load_start: -1,
          all_viewports_loading_from_load_start: -1,
          num_prior_studies: 0,
          num_current_studies: 0,
          total_viewports: 0,
          network: { ...baseMetrics },
          cache: { ...baseMetrics },
        }
      );
      analytics.timing('viewer_viewports_interactable');
      analytics.track(viewer.sys.allInteractable, {
        ...getMetaAnalytics(),
        ...aggregatedMetrics,
        viewerId,
        engine: 'DRE',
        case_smid: currentCaseSmid,
      });
    };

    analytics.startPerformanceObserver(viewer.sys.viewportRendering, (entry: PerformanceEntry) => {
      const match = entry.name.match(/viewport_rendering\.(.*)\.([a-z]+)$/);

      if (match === null) {
        return {};
      }

      const viewportId = match[1];
      const eventType = match[2];
      const duration = Math.round(entry.duration);
      const viewportMeta = getViewportMeta(viewportId);
      let durationFromLoadStart = -1;

      if (eventType === 'rendered') {
        viewportMeta.renderTime = duration;
        durationFromLoadStart = viewportMeta.renderTimeFromLoadStart ?? -1;
      } else if (eventType === 'interactable') {
        viewportMeta.interactable = true;
        viewportMeta.interactableTime = duration;
        durationFromLoadStart = viewportMeta.interactableTimeFromLoadStart ?? -1;
      } else if (eventType === 'finished') {
        viewportMeta.finished = true;
        viewportMeta.finishedTime = duration;
        durationFromLoadStart = viewportMeta.finishedTimeFromLoadStart ?? -1;
      } else if (eventType === 'error') {
        viewportMeta.error = true;
        viewportMeta.errorTime = duration;
      }

      const initialViewportIds = getInitialViewports();
      const hasInitialViewportIds = initialViewportIds.length > 0;
      const allInitialViewportsHaveInitialSlice = initialViewportIds.every((key) => {
        const meta = __VIEWPORT_META__[key];
        return meta.interactable === true || meta.empty === true || meta.error;
      });
      const allInitialViewportsFinished = initialViewportIds.every((key) => {
        const meta = __VIEWPORT_META__[key];
        return meta.finished === true || meta.empty === true || meta.error;
      });
      if (
        hasInitialViewportIds &&
        allInitialViewportsHaveInitialSlice &&
        eventType === 'interactable' &&
        initialViewportIds.includes(viewportId) &&
        !interactableReported.current
      ) {
        interactableReported.current = true;
        setTimeout(reportInteractable, 0);
      }

      if (hasInitialViewportIds && allInitialViewportsFinished && !metricsReported.current) {
        metricsReported.current = true;
        setTimeout(reportFinalMetrics, 0);
      }

      return {
        ...getMetaAnalytics(),
        viewerId,
        engine: 'DRE',
        current_case_smid: currentCaseSmid,
        stack_smid: viewportMeta.stackSmid,
        is_prior_study: viewportMeta.isPriorStudy,
        instance_count: viewportMeta.instanceCount,
        from_cache: viewportMeta.fromCache,
        render_method: viewportMeta.renderMethod,
        unpacking_method: viewportMeta.unpackingMethod,
        lazy_loaded: viewportMeta.lazyLoaded ?? false,
        event_type: eventType,
        duration_from_load_event_start_ms: durationFromLoadStart,
        slices_per_second:
          viewportMeta.finished === true &&
          viewportMeta.instanceCount != null &&
          viewportMeta.instanceCount > 0
            ? viewportMeta.instanceCount / (duration / 1000)
            : -1,
      };
    });

    return () => {
      analytics.stopPerformanceObserver();
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    };
  }, [viewerId, currentCaseSmid, viewportsToRender.length]);
};

// Track meta data (like number of instances and if the image was loaded from cache)
const trackViewportMeta = (viewportId: string, meta: Partial<ViewportRenderMeta>) => {
  __VIEWPORT_META__[viewportId] = {
    ...__VIEWPORT_META__[viewportId],
    ...meta,
  };
};

const getViewportMeta = (viewportId: string): Partial<ViewportMetrics> => {
  const currentMeta = __VIEWPORT_META__[viewportId];

  if (currentMeta != null) {
    return currentMeta;
  }

  logger.warn('Requesting viewport meta before track viewport meta has been called');

  __VIEWPORT_META__[viewportId] = {};
  return __VIEWPORT_META__[viewportId];
};

export const logViewportLoadStart = (viewportId: string) => {
  const timeFromLoadStart = analytics.getTimeFromLoadEvent();
  const existingMeta = getViewportMeta(viewportId);

  __VIEWPORT_META__[viewportId] = {
    ...existingMeta,
    timeToViewportLoadStart: timeFromLoadStart,
  };
};

export const logEmptyViewport = (viewportId: string) => {
  performance.mark(marks.Render.Start(viewportId));
  const existingMeta = getViewportMeta(viewportId);

  __VIEWPORT_META__[viewportId] = {
    ...existingMeta,
    empty: true,
    instanceCount: 0,
  };

  performance.measure(...measures.Viewport.TimeToInteractable(viewportId));
};

const logViewportRendered = (viewportId: string) => {
  const timeFromLoadStart = analytics.getTimeFromLoadEvent();
  const existingMeta = getViewportMeta(viewportId);

  existingMeta.renderTimeFromLoadStart = timeFromLoadStart;
  performance.measure(...measures.Viewport.TimeToRendered(viewportId));
};

const logViewportInteractable = (viewportId: string) => {
  const timeFromLoadStart = analytics.getTimeFromLoadEvent();
  const existingMeta = getViewportMeta(viewportId);

  existingMeta.interactableTimeFromLoadStart = timeFromLoadStart;
  performance.measure(...measures.Viewport.TimeToInteractable(viewportId));
};

const logViewportFinished = (viewportId: string) => {
  const timeFromLoadStart = analytics.getTimeFromLoadEvent();
  const existingMeta = getViewportMeta(viewportId);

  existingMeta.finishedTimeFromLoadStart = timeFromLoadStart;
  performance.measure(...measures.Viewport.TimeToFinished(viewportId));
};

const logViewportError = (viewportId: string) => {
  performance.measure(...measures.Viewport.TimeToError(viewportId));
};

export const useViewportRenderMetrics = (
  isPriorStudy: boolean
): {
  logViewportRenderStart: (viewportId: string, stackSmid?: string) => void;
  logViewportRendered: (viewportId: string) => void;
  logViewportInteractable: (viewportId: string) => void;
  logViewportFinished: (viewportId: string) => void;
  logViewportError: (viewportId: string) => void;
  trackViewportMeta: (viewportId: string, meta: Partial<ViewportRenderMeta>) => void;
} => {
  const firstViewportStarted = useRef(false);

  const logViewportRenderStart = useCallback(
    (viewportId: string, stackSmid?: string) => {
      __VIEWPORT_META__[viewportId] = {
        ...__VIEWPORT_META__[viewportId],
        stackSmid,
        isPriorStudy,
        finished: false,
        error: false,
      };
      performance.mark(marks.Render.Start(viewportId));

      if (!firstViewportStarted.current) {
        firstViewportStarted.current = true;

        // NOTE(fzivolo): on test environments timing is missing, we don't need it anyway
        analytics.timing?.('viewer_viewports_initial_render_start');
      }
    },
    [isPriorStudy]
  );

  return {
    trackViewportMeta,
    logViewportRenderStart,
    logViewportRendered,
    logViewportInteractable,
    logViewportFinished,
    logViewportError,
  };
};

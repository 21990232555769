import { useMemo } from 'react';
import { hexToVTKColor } from '../utils/colors';
import type { Vector3 as vec3 } from '@kitware/vtk.js/types';
import { LinearPolyData } from './helpers/LinearPolyData';
import * as config from '../config';
import type { MouseHandlers } from '../types';
import { withCommonPrimitiveHelpers, transformCoordinate, numberToOpacity } from './helpers/common';
import { DisplayLocation } from '@kitware/vtk.js/Rendering/Core/Property2D/Constants';
import BatchedGeometry2DRepresentation from './helpers/BatchedGeometry2DRepresentation';
import type { BasePrimitiveProps } from './PrimitiveTypes';
import { useAnnotationColors } from '../Annotations/helpers/useAnnotationColors';

type LineProps = BasePrimitiveProps & {
  start: vec3;
  end: vec3;
  enablePickResults?: boolean;
};

export const Line: React.ComponentType<LineProps & MouseHandlers> = withCommonPrimitiveHelpers(
  ({
    id,
    start,
    end,
    color,
    opacity = 1,
    thickness = config.thickness,
    visible = true,
  }: LineProps) => {
    const { defaultColor } = useAnnotationColors();
    const property = useMemo(
      () => ({
        color: hexToVTKColor(color ?? defaultColor),
        opacity: numberToOpacity(opacity),
        displayLocation: DisplayLocation.FOREGROUND,
        lineWidth: thickness,
      }),
      [color, thickness, opacity, defaultColor]
    );

    const points = useMemo(() => [...start, ...end], [start, end]);
    const actorProperties = useMemo(
      () => ({
        visibility: visible,
      }),
      [visible]
    );

    return (
      <BatchedGeometry2DRepresentation
        id={id}
        // @ts-expect-error [EN-7967] - TS2559 - Type '{ visibility: boolean; }' has no properties in common with type 'Partial<IActor2DInitialValues>'.
        actor={actorProperties}
        property={property}
        transformCoordinate={transformCoordinate}
      >
        <LinearPolyData points={points} />
      </BatchedGeometry2DRepresentation>
    );
  }
);

import { useCurrentUser } from 'hooks/useCurrentUser';
import { UPDATE_WORKLIST_SETTINGS, GET_ME } from 'modules/Apollo/queries';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  GetMeQuery,
  GetMeQueryVariables,
  DefaultCustomWorklist,
  UpdateWorklistSettingsMutation,
  UpdateWorklistSettingsMutationVariables,
} from 'generated/graphql';
import { useMutation } from '@apollo/client';
import { logger } from 'modules/logger';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';
import { useWorklistViews } from '../WorklistViews/useWorklistViews';
import { WorklistSurface } from '../types';

type UseDefaultCustomWorklistsReturn = {
  saveLastViewedCustomWorklists: (
    lastViewedCustomWorklists: DefaultCustomWorklist[]
  ) => Promise<void>;
  isSaveLastViewedCustomWorklistsLoading: boolean;
  defaultCustomWorklists: DefaultCustomWorklist[];
  saveDefaultCustomWorklists: (defaultCustomWorklists: DefaultCustomWorklist[]) => Promise<void>;
  isSaveDefaultCustomWorklistsLoading: boolean;
  isDefaultCustomWorklistsLoading: boolean;
};

export const useDefaultCustomWorklists = (): UseDefaultCustomWorklistsReturn => {
  const { data: meData, loading: meLoading } = useCurrentUser();
  const [isSaveLastViewedCustomWorklistsLoading, setIsSaveLastViewedCustomWorklistsLoading] =
    useState(false);
  const [isSaveDefaultCustomWorklistsLoading, setIsSaveDefaultCustomWorklistsLoading] =
    useState(false);
  const [isCustomWorklistsMilestoneOneEnabled] = useFeatureFlagEnabled(
    FF.WORKLIST_CUSTOM_WORKLISTS_MILESTONE_1
  );

  const { worklistViews, areWorklistViewsLoading } = useWorklistViews();

  const worklistSettings = useMemo(() => {
    return meData?.me?.worklistSettings;
  }, [meData?.me?.worklistSettings]);

  const defaultCustomWorklistsFromSettings = useMemo(() => {
    return [...(worklistSettings?.defaultCustomWorklistsSettings?.defaultCustomWorklists ?? [])];
  }, [worklistSettings?.defaultCustomWorklistsSettings?.defaultCustomWorklists]);

  const lastViewedCustomWorklistsFromSettings = useMemo(() => {
    return [...(worklistSettings?.lastViewedCustomWorklists ?? [])];
  }, [worklistSettings?.lastViewedCustomWorklists]);

  const [updateWorklistSettingsMutation] = useMutation<
    UpdateWorklistSettingsMutation,
    UpdateWorklistSettingsMutationVariables
  >(UPDATE_WORKLIST_SETTINGS);

  const saveLastViewedCustomWorklists = useCallback(
    async (lastViewedCustomWorklists: DefaultCustomWorklist[]) => {
      setIsSaveLastViewedCustomWorklistsLoading(true);
      const variables = {
        lastViewedCustomWorklists,
      } as const;

      const response = lastViewedCustomWorklists.map((worklist) => ({
        ...worklist,
        __typename: 'DefaultCustomWorklist' as const,
      }));

      try {
        await updateWorklistSettingsMutation({
          variables,
          optimisticResponse: {
            __typename: 'Mutation',
            updateWorklistSettings: {
              ...worklistSettings,
              __typename: 'WorklistSettings',
              lastViewedCustomWorklists: response,
            },
          },
          update: (proxy) => {
            const data = proxy.readQuery<GetMeQuery, GetMeQueryVariables>({ query: GET_ME }) ?? {};

            proxy.writeQuery({
              query: GET_ME,
              data: {
                me: {
                  // @ts-expect-error [EN-7967] - TS2339 - Property 'me' does not exist on type '{}'.
                  ...data?.me,
                  worklistSettings: {
                    // @ts-expect-error [EN-7967] - TS2339 - Property 'me' does not exist on type '{}'.
                    ...data?.me?.worklistSettings,
                    lastViewedCustomWorklists: response,
                  },
                },
              },
            });
          },
        });

        logger.info('[useDefaultCustomWorklists] Last viewed custom worklists have been saved', {
          lastViewedCustomWorklists,
        });
      } catch (error) {
        logger.error('[useDefaultCustomWorklists] Error saving last viewed custom worklists', {
          error,
          lastViewedCustomWorklists,
        });
      }

      setIsSaveLastViewedCustomWorklistsLoading(false);
    },
    [worklistSettings, updateWorklistSettingsMutation]
  );

  const saveDefaultCustomWorklists = useCallback(
    async (newDefaultCustomWorklists: DefaultCustomWorklist[]) => {
      setIsSaveDefaultCustomWorklistsLoading(true);
      const defaultCustomWorklistsSettings = {
        isEnabled: worklistSettings?.defaultCustomWorklistsSettings?.isEnabled ?? false,
        defaultCustomWorklists: newDefaultCustomWorklists,
      };

      const variables = {
        defaultCustomWorklistsSettings,
      } as const;

      const response = {
        defaultCustomWorklistsSettings: {
          ...defaultCustomWorklistsSettings,
          defaultCustomWorklists: newDefaultCustomWorklists.map((worklist) => ({
            ...worklist,
            __typename: 'DefaultCustomWorklist' as const,
          })),
        },
      };

      try {
        await updateWorklistSettingsMutation({
          variables,
          optimisticResponse: {
            __typename: 'Mutation',
            updateWorklistSettings: {
              ...worklistSettings,
              __typename: 'WorklistSettings',
              ...response,
            },
          },
          update: (proxy) => {
            const data = proxy.readQuery<GetMeQuery, GetMeQueryVariables>({ query: GET_ME }) ?? {};

            proxy.writeQuery({
              query: GET_ME,
              data: {
                me: {
                  // @ts-expect-error [EN-7967] - TS2339 - Property 'me' does not exist on type '{}'.
                  ...data?.me,
                  worklistSettings: {
                    // @ts-expect-error [EN-7967] - TS2339 - Property 'me' does not exist on type '{}'.
                    ...data?.me?.worklistSettings,
                    ...response,
                  },
                },
              },
            });
          },
        });
        logger.info('[useDefaultCustomWorklists] Default custom worklists have been saved', {
          defaultCustomWorklists: newDefaultCustomWorklists,
        });
      } catch (error) {
        logger.error('[useDefaultCustomWorklists] Error saving default custom worklists', {
          error,
          defaultCustomWorklists: newDefaultCustomWorklists,
        });
      }

      setIsSaveDefaultCustomWorklistsLoading(false);
    },
    [worklistSettings, updateWorklistSettingsMutation]
  );

  // This validates a list of custom worklists by checking to make sure that the custom worklists are
  // valid worklist views if required. This ensures that if a worklist view has been deleted, we do not
  // attempt to load it into the worklist
  const getValidCustomWorklists = useCallback(
    (customWorklists: DefaultCustomWorklist[]) => {
      if (
        customWorklists.some(
          (worklist) =>
            worklist.surface === WorklistSurface.WORKLIST_VIEW && worklist.worklistViewID != null
        )
      ) {
        const validCustomWorklists = customWorklists.filter((worklist) => {
          return (
            worklist.surface !== WorklistSurface.WORKLIST_VIEW ||
            (worklist.worklistViewID != null &&
              worklistViews.some((worklistView) => worklistView.smid === worklist.worklistViewID))
          );
        });
        return validCustomWorklists;
      }

      return customWorklists;
    },
    [worklistViews]
  );

  // We pre-emptively return the validated custom worklists, though we don't actually save them to
  // the user config here. We save them in the useEffect below.
  const defaultCustomWorklists = useMemo(() => {
    if (
      isCustomWorklistsMilestoneOneEnabled &&
      worklistSettings?.defaultCustomWorklistsSettings?.isEnabled === true
    ) {
      return getValidCustomWorklists(defaultCustomWorklistsFromSettings);
    }

    return getValidCustomWorklists(lastViewedCustomWorklistsFromSettings);
  }, [
    defaultCustomWorklistsFromSettings,
    isCustomWorklistsMilestoneOneEnabled,
    lastViewedCustomWorklistsFromSettings,
    worklistSettings?.defaultCustomWorklistsSettings?.isEnabled,
    getValidCustomWorklists,
  ]);

  useEffect(() => {
    if (areWorklistViewsLoading) {
      return;
    }

    const validLastViewedCustomWorklists = getValidCustomWorklists(
      lastViewedCustomWorklistsFromSettings
    );

    if (validLastViewedCustomWorklists.length !== lastViewedCustomWorklistsFromSettings.length) {
      saveLastViewedCustomWorklists(validLastViewedCustomWorklists);
      logger.info(
        `[useDefaultCustomWorklists] Overriding saved last viewed custom worklists due to ${lastViewedCustomWorklistsFromSettings.length - validLastViewedCustomWorklists.length} invalid worklist views`,
        {
          validWorklists: validLastViewedCustomWorklists,
          lastViewedCustomWorklists: lastViewedCustomWorklistsFromSettings,
        }
      );
    }

    const validDefaultCustomWorklists = getValidCustomWorklists(defaultCustomWorklistsFromSettings);
    if (validDefaultCustomWorklists.length !== defaultCustomWorklistsFromSettings.length) {
      saveDefaultCustomWorklists(validDefaultCustomWorklists);
      logger.info(
        `[useDefaultCustomWorklists] Overriding saved default custom worklists due to ${defaultCustomWorklistsFromSettings.length - validDefaultCustomWorklists.length} invalid worklist views`,
        {
          validWorklists: validDefaultCustomWorklists,
          defaultCustomWorklists: defaultCustomWorklistsFromSettings,
        }
      );
    }
  }, [
    worklistViews,
    areWorklistViewsLoading,
    getValidCustomWorklists,
    lastViewedCustomWorklistsFromSettings,
    defaultCustomWorklistsFromSettings,
    saveLastViewedCustomWorklists,
    saveDefaultCustomWorklists,
  ]);

  return {
    saveLastViewedCustomWorklists,
    isSaveLastViewedCustomWorklistsLoading,
    saveDefaultCustomWorklists,
    isSaveDefaultCustomWorklistsLoading,
    defaultCustomWorklists,
    isDefaultCustomWorklistsLoading: meLoading || areWorklistViewsLoading,
  };
};

import type { CurrentActiveListVariantState } from '../../../list/hooks/useCurrentList';
import type { StableTextResponse } from '../../ASRPlex/ASRPlexProtocol';
import type { NvoqMessage, VoiceCommandsEditor } from '../createNvoqWebsocketFactory';

const generateNextParagraphInListSironaSubstitution = (
  substitutedText: string,
  variant: CurrentActiveListVariantState
): string => {
  if (substitutedText.includes('\n\n') && variant != null) {
    const voiceCommand: VoiceCommandsEditor = 'NextParagraphInList';
    substitutedText = substitutedText.replace(
      /\n\n/g,
      `<srna>{"action": "${voiceCommand}"}</srna>`
    );
  }

  return substitutedText;
};

export const generateNextParagraphInListSironaSubstitutionForNvoq = (
  msg: NvoqMessage,
  variant: CurrentActiveListVariantState
): NvoqMessage => {
  const substitutedText = generateNextParagraphInListSironaSubstitution(msg.data.text, variant);

  return {
    ...msg,
    data: {
      ...msg.data,
      text: substitutedText,
      substitutedText,
    },
  };
};

export const generateNextParagraphInListSironaSubstitutionForMarkers = (
  msg: StableTextResponse,
  variant: CurrentActiveListVariantState
): StableTextResponse => {
  const substitutedText = generateNextParagraphInListSironaSubstitution(msg.payload.text, variant);

  return {
    ...msg,
    payload: {
      ...msg.payload,
      text: substitutedText,
      substitutedText,
    },
  };
};

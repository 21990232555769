import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';
import type { ToastProps } from '../common/ui/Toaster/Toast';
import { useToasterDispatch } from '../common/ui/Toaster/Toaster';

/**
 * This hook queues up a toast (if the relevant feature flag is enabled)
 * It helps the user to visualize/debug the dictation steps that are being triggered.
 * At time of writing, not intended to be a user-facing feature.
 */
export const useReporterDictationStepToast = (): {
  enqueueDictationStepToast: (msg: React.ReactNode, options?: Partial<ToastProps>) => void;
} => {
  const [dictationStepNotificationsEnabled] = useFeatureFlagEnabled(
    FF.REPORTER_SHOW_DICTATION_STEP_NOTIFICATIONS
  );
  const { enqueueToast } = useToasterDispatch();

  const enqueueDictationStepToast = (msg: React.ReactNode, options: Partial<ToastProps> = {}) => {
    const defaultOptions = {
      autoHideDuration: 10000,
      icon: 'greenCheck',
    } as const;

    if (dictationStepNotificationsEnabled) {
      enqueueToast(msg, { ...defaultOptions, ...options });
    }
  };

  return { enqueueDictationStepToast };
};

import { useEffect } from 'react';
import { useMount, usePrevious } from 'react-use';
import { useLazyQuery } from '@apollo/client';
import { GET_ME } from 'modules/Apollo/queries';
import analytics from 'modules/analytics';
import { isRefreshTokenProvided } from 'utils/token';
import { workspace } from 'modules/analytics/constants';
import { useCurrentCaseId } from 'hooks/useCurrentCase';
import useStudyIds from './useStudyIds';
import { useLocation } from 'react-router-dom';
import { resetImagingProviderTrackers } from 'modules/viewer/workers/PixelDataSharedWorker';
import { useRecoilValue } from 'recoil';
import { experimentConfigurationState } from 'domains/reporter/Reporter/ExperimentControlTab';

export const useAnalytics = () => {
  const experimentConfiguration = useRecoilValue(experimentConfigurationState);
  const [getMe, { data }] = useLazyQuery(GET_ME, {
    fetchPolicy: 'network-only',
  });
  const user = data?.me;

  useMount(() => {
    analytics.timing('react_app_rendered');
  });

  // Reset the timing metrics when the user navigates to a new page
  const location = useLocation();
  const previousLocation = usePrevious(location);
  useEffect(() => {
    if (location.pathname !== previousLocation?.pathname) {
      analytics.resetTimings();
      resetImagingProviderTrackers();
    }
  }, [location, previousLocation]);

  useEffect(() => {
    // Don't call this query if the user is not logged in
    if (isRefreshTokenProvided()) {
      getMe();
    }
  }, [getMe]);

  useEffect(() => {
    if (user != null) {
      const segmentData = {
        experimentConfiguration: { ...experimentConfiguration },
        reporterSettings: user.reporterSettings,
      } as const;
      analytics.identify(user, { segmentData });
    }
  }, [user, experimentConfiguration]);

  useEffect(() => {
    const onlineAction = () => {
      analytics.track(workspace.sys.system, { type: 'online' });
    };
    const offlineAction = () => {
      analytics.track(workspace.sys.system, { type: 'offline' });
    };

    const pageVisibility = (_: any) => {
      analytics.track(workspace.sys.system, {
        type: 'page_visibility',
        visible: !document.hidden,
      });
    };

    window.addEventListener('visibilitychange', pageVisibility);
    window.addEventListener('online', onlineAction);
    window.addEventListener('offline', offlineAction);

    return () => {
      window.removeEventListener('online', onlineAction);
      window.removeEventListener('offline', offlineAction);
      window.removeEventListener('visibilitychange', pageVisibility);
    };
  }, []);

  return;
};

export const useCaseAnalytics = () => {
  const currentCaseId = useCurrentCaseId();
  const previousCaseId = usePrevious(currentCaseId);

  useEffect(() => {
    if (previousCaseId != null && previousCaseId !== currentCaseId) {
      // Reset the timestamp of the page load time so we can accurately
      // measure metrics relative to the time a study was requested to load.
      analytics.resetLoadEventStart();
    }
  }, [previousCaseId, currentCaseId]);

  const priorStudiesSmids = useStudyIds();
  useEffect(() => {
    analytics.updateReadSession({
      priorStudiesSmids,
    });
  });
};

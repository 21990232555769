import { useEffect, useMemo } from 'react';
import { useToolPreferences } from 'hooks/usePreferences';
import { sendEvent, NAMESPACES } from 'modules/EventsManager';
import Mousetrap from 'mousetrap';
import { unreachableCaseError } from 'types';
import { innerJoin, pipe, map } from 'ramda';
import type { Shortcut } from 'generated/graphql';

export type ReporterShortcutID =
  | 'REPORTER_TOGGLE_DICTATION'
  | 'REPORTER_PREVIOUS'
  | 'REPORTER_NEXT'
  | 'REPORTER_SUBMIT_REPORT'
  | 'REPORTER_GENERATE_IMPRESSION'
  | 'REPORTER_MED_CHECK'
  | 'REPORTER_DISCARD_REPORT'
  | 'REPORTER_DRAFT_REPORT';

export const REPORTER_SHORTCUT_IDS: ReporterShortcutID[] = [
  'REPORTER_TOGGLE_DICTATION',
  'REPORTER_PREVIOUS',
  'REPORTER_NEXT',
  'REPORTER_SUBMIT_REPORT',
  'REPORTER_GENERATE_IMPRESSION',
  'REPORTER_MED_CHECK',
  'REPORTER_DISCARD_REPORT',
  'REPORTER_DRAFT_REPORT',
];

const generateReportHandlerByID = (
  shortcutID: string
): /**
 * Mousetrap sends an empty object in place of a proper Event instance on Node,
 * so we have to check for that not to creash during unit tests
 */
((e: Event | Record<any, any>, combo?: string) => void) => {
  switch (shortcutID) {
    case 'REPORTER_TOGGLE_DICTATION':
      return (evt: Event | Record<any, any>) => {
        if (evt instanceof Event) evt.preventDefault();
        sendEvent(NAMESPACES.REPORTER_SHORTCUT, {
          type: 'keyPress',
          action: 'toggleRecording',
        });
      };
    case 'REPORTER_PREVIOUS':
      return (evt: Event | Record<any, any>) => {
        if (evt instanceof Event) evt.preventDefault();
        sendEvent(NAMESPACES.REPORTER_SHORTCUT, {
          type: 'keyPress',
          action: 'previousBracket',
        });
      };
    case 'REPORTER_NEXT':
      return (evt: Event | Record<any, any>) => {
        if (evt instanceof Event) evt.preventDefault();
        sendEvent(NAMESPACES.REPORTER_SHORTCUT, { type: 'keyPress', action: 'nextBracket' });
      };
    case 'REPORTER_SUBMIT_REPORT':
      return (evt: Event | Record<any, any>) => {
        if (evt instanceof Event) evt.preventDefault();
        sendEvent(NAMESPACES.REPORTER_SHORTCUT, { type: 'keyPress', action: 'submitReport' });
      };

    case 'REPORTER_GENERATE_IMPRESSION':
      return (evt: Event | Record<any, any>) => {
        if (evt instanceof Event) evt.preventDefault();
        sendEvent(NAMESPACES.REPORTER_SHORTCUT, { type: 'keyPress', action: 'generateImpression' });
      };

    case 'REPORTER_MED_CHECK':
      return (evt: Event | Record<any, any>) => {
        if (evt instanceof Event) evt.preventDefault();
        sendEvent(NAMESPACES.URT_SHORTCUT, { type: 'keyPress', action: 'medCheck' });
      };

    case 'REPORTER_DISCARD_REPORT':
      return (evt: Event | Record<any, any>) => {
        if (evt instanceof Event) evt.preventDefault();
        sendEvent(NAMESPACES.REPORTER_SHORTCUT, { type: 'keyPress', action: 'discardReport' });
      };

    case 'REPORTER_DRAFT_REPORT':
      return (evt: Event | Record<any, any>) => {
        if (evt instanceof Event) evt.preventDefault();
        sendEvent(NAMESPACES.REPORTER_SHORTCUT, { type: 'keyPress', action: 'draftReport' });
      };

    default:
      return unreachableCaseError(shortcutID);
  }
};

export const generateGlobalReporterShortcutsHandlers = (
  shortcuts: ReadonlyArray<Readonly<Shortcut>>
): {
  key: string;
  callback: (e: Event, combo?: string) => void;
  id: string;
}[] =>
  // @ts-expect-error [EN-7967] - TS2740 - Type '{}' is missing the following properties from type '{ key: string; callback: (e: Event, combo?: string) => void; id: string; }[]': length, pop, push, concat, and 29 more.
  pipe(
    (val) =>
      innerJoin(
        // @ts-expect-error [EN-7967] - TS2339 - Property 'id' does not exist on type 'unknown'.
        (shortcutEntity, shortcutID) => shortcutEntity.id === shortcutID,
        val,
        REPORTER_SHORTCUT_IDS
      ),
    // @ts-expect-error [EN-7967] - TS2345 - Argument of type '(list: readonly Shortcut[]) => { key: string; callback: (e: Event | Record<any, any>, combo?: string) => void; id: string; }[]' is not assignable to parameter of type '(a: unknown[]) => { key: string; callback: (e: Event | Record<any, any>, combo?: string) => void; id: string; }[]'.
    map((shortcut: Shortcut) => ({
      key: shortcut.key ?? '',
      callback: generateReportHandlerByID(shortcut.id),
      id: shortcut.id,
    }))
  )(shortcuts);

export const useCrossWindowReporterShortcuts = () => {
  const [toolPreferences] = useToolPreferences();

  const reporterGlobalShortcutHandlers = useMemo(
    () =>
      toolPreferences
        ? generateGlobalReporterShortcutsHandlers(toolPreferences.keyboard.shortcuts)
        : [],
    [toolPreferences]
  );

  useEffect(() => {
    reporterGlobalShortcutHandlers.forEach(({ key, callback }) => {
      if (key == null) return;
      Mousetrap.bind(key, callback);
    });

    return () => {
      reporterGlobalShortcutHandlers.forEach(({ key, callback }) => {
        if (key == null) return;
        Mousetrap.unbind(key);
      });
    };
  }, [reporterGlobalShortcutHandlers]);
};

import { useCallback, useState } from 'react';
import { CustomAnnotation, defaultConfiguration } from '../Annotations/Custom';
import { useTrackToolboxAnnotationRenderTime } from '../modules/useTrackAnnotationRenderTime';
import { useMouseDefinedSegments, sendExpandViewportEvent } from './utils';
import { useMouseWorldPosition } from '../modules/useMouseWorldPosition';
import { useEventAction } from '../modules/usePicker';
import type { CompleteCallback } from './types';
import type { AnnotationTypes } from 'generated/graphql';
import type { Vector3 as vec3 } from '@kitware/vtk.js/types';
import { DEFAULT_ANNOTATION_TRANSFORMATIONS } from 'config/constants';

type CustomAnnotationToolProps = {
  annotationType: AnnotationTypes;
  annotationLogic: string;
  annotationLogicId: string;
  onComplete: CompleteCallback;
  onCancel: () => unknown;
  initialPoints?: ReadonlyArray<vec3>;
};

export function CustomAnnotationTool({
  annotationType,
  annotationLogicId,
  annotationLogic,
  onComplete,
  onCancel,
  initialPoints = [],
}: CustomAnnotationToolProps): React.ReactElement | null {
  const [configuration, setConfiguration] = useState({ ...defaultConfiguration });
  const mousePosition = useMouseWorldPosition();

  const handleComplete = useCallback(
    async (segments) => {
      return onComplete({
        type: annotationType,
        segments,
        customLogic: {
          id: annotationLogicId,
          logic: annotationLogic,
        },
      });
    },
    [onComplete, annotationType, annotationLogicId, annotationLogic]
  );

  const [segments, resetSegments] = useMouseDefinedSegments(
    0,
    (points) => {
      return configuration.maxPoints ?? Infinity;
    },
    async (segments) => {
      // No need to wait for this to complete
      handleComplete(segments);
    },
    onCancel,
    initialPoints
  );

  useEventAction('doubleClick', async () => {
    if (mousePosition == null) return;

    if (segments == null) {
      sendExpandViewportEvent();
      resetSegments();
      return;
    } else if (configuration.completeOnDoubleClick === true) {
      resetSegments();
      handleComplete(segments);
    }
  });

  useTrackToolboxAnnotationRenderTime(segments);

  if (segments == null) {
    return null;
  }

  // We are drawing the annotation in real time.
  return (
    <CustomAnnotation
      id="custom-annotation-tool"
      segments={segments}
      mousePosition={mousePosition}
      annotationLogic={annotationLogic}
      onConfigurationUpdate={setConfiguration}
      allowedTransformations={DEFAULT_ANNOTATION_TRANSFORMATIONS}
    />
  );
}

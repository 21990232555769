import type { WSTransferType, TransferProtocol } from 'modules/viewer/PixelDataLoader';
import { FF, FFDefaults } from 'modules/feature-flags/featureFlags.generated';
import { useSplitFlag } from 'modules/feature-flags/useSplitFlag';
import { useMemo } from 'react';

export const useViewerDownloadingFlags = (): {
  viewerTransferType: WSTransferType;
  viewerTransferProtocol: TransferProtocol;
  loadingViewerDownloadingFlags: boolean;
} => {
  const [viewerTransferType, loadingViewerTransferType] = useSplitFlag<WSTransferType>(
    FF.VIEWER_TRANSFER_TYPE,
    FFDefaults.VIEWER_TRANSFER_TYPE
  );
  const [viewerTransferProtocol, loadingViewerTransferProtocol] = useSplitFlag<TransferProtocol>(
    FF.VIEWER_TRANSFER_PROTOCOL,
    FFDefaults.VIEWER_TRANSFER_PROTOCOL
  );

  return useMemo(
    () => ({
      viewerTransferType,
      viewerTransferProtocol,
      loadingViewerDownloadingFlags: loadingViewerTransferType || loadingViewerTransferProtocol,
    }),
    [
      viewerTransferType,
      viewerTransferProtocol,
      loadingViewerTransferType,
      loadingViewerTransferProtocol,
    ]
  );
};

import { logger } from 'modules/logger';
// @ts-expect-error [EN-7967] - TS2305 - Module '"@splitsoftware/splitio"' has no exported member 'SplitClient'.
import type { SplitClient } from '@splitsoftware/splitio';
import { getFactory } from './factory';
import { makeGetTreatmentMockable } from './mocks';
import { env } from 'config/env';

export type StatefulSplitClientState = 'pending' | 'ready' | 'timeout';

export class StatefulSplitClient {
  splitClient: SplitClient;
  state: StatefulSplitClientState;
  stateChangeHandlers: Set<(arg1: StatefulSplitClientState) => void>;

  constructor(client: SplitClient) {
    this.splitClient = client;
    this.state = 'pending';
    logger.info('Split Client pending');
    this.stateChangeHandlers = new Set();

    client
      .once(client.Event.SDK_READY, () => {
        this.state = 'ready';
        logger.info('Split Client ready');
        this.stateChangeHandlers.forEach((handler) => handler(this.state));
      })
      .once(client.Event.SDK_READY_TIMED_OUT, () => {
        this.state = 'timeout';
        logger.info('Split Client timed out');
        this.stateChangeHandlers.forEach((handler) => handler(this.state));
      });
  }

  onStateChange(callback: (arg1: StatefulSplitClientState) => void) {
    this.stateChangeHandlers.add(callback);
  }

  offStateChange(callback: (arg1: StatefulSplitClientState) => void) {
    this.stateChangeHandlers.delete(callback);
  }
}

export const statefulSplitClientMap: Map<string, StatefulSplitClient> = new Map();

export function getClientWithState(email: string): StatefulSplitClient {
  const existingClient = statefulSplitClientMap.get(email);
  if (existingClient != null) {
    return existingClient;
  }

  statefulSplitClientMap.forEach((statefulClient) => statefulClient.splitClient.destroy());
  statefulSplitClientMap.clear();

  logger.info('Initializing Split.io SDK client with email:', email);

  const factory = getFactory(email);
  const client = factory.client(email);

  const statefulClient = new StatefulSplitClient(
    env.LOCAL_SPLITS === 'true' ? makeGetTreatmentMockable(client) : client
  );

  statefulSplitClientMap.set(email, statefulClient);
  return statefulClient;
}

import { Node, withReact, createEditor, Transforms, Editor, ReactEditor, Element } from '../core';
import { useMount } from 'react-use';
import { IdentityFn } from 'config/constants';

import { withOpsLog } from '@sironamedical/slate-ops-log';
import { BaseEditor } from './Editable';
import type { EditorPlugin, SlateContent } from '../types';
import type { CursorStyle, LineIndicator as LineIndicatorType } from 'generated/graphql';
import {
  usePlugins,
  PluginsProvider,
  NodeStateProvider,
  ReporterProvider,
  RangeMasksProvider,
} from '../hooks';
import type { AllReporterVariant, PluginsBag } from '../hooks';
import { compose } from 'ramda';
import { useMemo, useEffect, Component, Suspense, useCallback, useRef } from 'react';
import { HoveringToolbar } from './HoveringToolbar';
import { PortalToolbarButton } from './PortalToolbar';
import { safeDeselect, getLowestBracket, partialEditor } from '../utils';
import { ReporterDevTools } from '../../Reporter/ReporterDevTools';
import analytics from 'modules/analytics';
import { ReporterOpsLogger } from './ReporterOpsLogger';
import { env } from 'config/env';
import { selectNextHeadingSectionBracket } from '../utils/bracketNavigation';
import { useSlateSelection } from 'slate-react';
import { useSlateSelectionSingletonContext } from '../../Reporter/SlateSingletonContext';
import { EnhancedSlate } from './EnhancedSlate';
import { Renderer } from '../renderer';
import type { ReactEditorType } from 'slate-react';
import { PARAGRAPH_PLUGIN_ID } from '../plugins';
import { logger } from 'modules/logger';
import { useHaveRichTextEditorFeatureFlagsLoaded } from 'hooks/useHaveRichTextEditorFeatureFlagsLoaded';
import { useUnifiedEditorSizes } from './UnifiedEditor';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { CursorIndicator } from './CursorIndicator';
import { LineIndicator } from './LineIndicator';
import { useRecorder } from 'common/Recorder/useRecorder/useRecorder';
import { AutoGenerateImpressions } from '../../Reporter/ImpressionGenerator/AutoGenerateImpressions';
import { useFeatureFlagEnabled, FF } from 'modules/feature-flags';
import { RichTextEditorLoading } from './RichTextEditorLoading';
import type { AutoloadTemplateState } from 'hooks/useAutoloadTemplate';

const isTestEnv = env.NODE_ENV === 'test' || env.STORYBOOK_STORYSHOTS === 'true';

export type EnhancedRendererComponentProps = Readonly<{
  value: SlateContent;
  editor?: Editor | null | undefined;
  isAddendumEditor?: boolean;
  onChange: (value: Array<Node>) => void;
  aiMode?: boolean;
  onReportChange?: (content: SlateContent) => void;
  /**
   * Called onMount after the Slate singleton has been created.
   */
  onInit?: (props: { editor: Editor; pluginsBag: PluginsBag }) => void;
}>;

export type RichTextEditorComponentProps = Readonly<{
  value: SlateContent;
  onChange: (value: Array<Node>) => void;
  editor?: Editor | null | undefined;
  /**
   * Apply the default selection for the reporter. Otherwise the starting selection will be null.
   *
   * @default true
   */
  enableDefaultSelection?: boolean;
  /**
   * Toggles the hovering toolbar functionality.
   *
   * @default true
   */
  enableHoveringToolbar?: boolean;
  /**
   * Apply style to the contenteditable
   */
  style?: Record<any, any>;
  /**
   * Apply cursor style settings
   */
  cursorStyle?: CursorStyle;
  /**
   * Apply line indicator settings
   */
  lineIndicator?: LineIndicatorType;
  /**
   * Called onMount after the Slate singleton has been created.
   */
  onInit?: (props: { editor: Editor; pluginsBag: PluginsBag }) => void;
  /**
   * Called when unmounting to unreference the Slate editor and plugins.
   * This is an important clean-up step to prevent the Slate context from possessing a stale editor,
   * in case this component is recreated without a page refresh.
   */
  onDestroy?: () => void;
  /**
   * Used to decide if a default selection should be made on the new editor.
   * If a template is being autoloaded, defer to that process,
   * since attempting to make a selection may conflict with it.
   */
  autoloadTemplateState?: AutoloadTemplateState;
  variant: AllReporterVariant;
  placeholder?: string | null | undefined;
  isAddendumEditor?: boolean;
  isLoading?: boolean;
}>;

export const defaultSelection = (
  editor: Editor,
  {
    ignoreMergeFields,
  }: {
    ignoreMergeFields: boolean;
  } = { ignoreMergeFields: false }
) => {
  // If there's an empty bracket, select it. Otherwise select the first bracket.
  const firstBracket =
    getLowestBracket(editor, { textEmpty: true, ignoreMergeFields }) ||
    getLowestBracket(editor, { ignoreMergeFields });
  if (firstBracket != null) {
    Transforms.select(editor, Editor.range(editor, firstBracket[1]));
    return;
  }

  const node = selectNextHeadingSectionBracket(editor, {
    startPoint: Editor.end(editor, []),
    fallbackPoint: 'end',
    ignoreMergeFieldsInNavigation: ignoreMergeFields,
  });
  if (node == null) {
    // In this case there is no headings or brackets in the editor so we
    // set the selection to be the first paragraph.
    try {
      const start = Editor.start(editor, []);
      const startBlockNodeEntry = Editor.node(editor, start, {
        depth: 1,
      });
      if (
        startBlockNodeEntry != null &&
        Element.isElement(startBlockNodeEntry[0]) &&
        startBlockNodeEntry[0].type === PARAGRAPH_PLUGIN_ID
      ) {
        Transforms.select(editor, startBlockNodeEntry[1]);
      } else {
        const firstParagraphNodeEntry = Editor.next(editor, {
          at: start,
          match: (n) => Element.isElement(n) && n.type === PARAGRAPH_PLUGIN_ID,
        });
        if (firstParagraphNodeEntry != null) {
          Transforms.select(editor, firstParagraphNodeEntry[1]);
        } else {
          logger.error('[RichTextEditor] Cannot find default selection for editor', {
            editor: partialEditor(editor),
            ignoreMergeFields,
          });
        }
      }
    } catch (err: any) {
      const errorMessage = '[RichTextEditor] Error occurred during defaultSelection';

      logger.error(
        errorMessage,
        {
          editor: partialEditor(editor),
          ignoreMergeFields,
        },
        err
      );
    }
  }
};

export class ErrorBoundary extends Component<{
  editor: Editor;
  ignoreMergeFields?: boolean;
  children: React.ReactNode;
}> {
  componentDidCatch(error: Error, errorInfo: unknown) {
    logger.error(error, errorInfo);

    // If we get an error from inside Slate, reset its selection to the default, since this
    // is the most likely cause of the error (Cannot resolve a DOM point from Slate point)
    // NOTE: this will not hide the error overlay in development mode!!
    defaultSelection(this.props.editor, {
      ignoreMergeFields: this.props.ignoreMergeFields ?? false,
    });
    analytics.error(error);
  }
  render(): React.ReactElement {
    // @ts-expect-error [EN-7967] - TS2322 - Type 'ReactNode' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
    return this.props.children;
  }
}

function SyncSlateSelection({ editor, variant }: { editor?: Editor; variant: AllReporterVariant }) {
  const selection = useSlateSelection();
  const [, setSlateSelectionSingletonContext] = useSlateSelectionSingletonContext();

  useEffect(() => {
    setSlateSelectionSingletonContext((v) => ({ ...v, selection }));
  }, [selection, setSlateSelectionSingletonContext]);

  return null;
}

const EnhancedRendererComponent = ({
  editor: editorOverride,
  isAddendumEditor = false,
  onInit,
  value,
  onChange,
  aiMode = false,
  onReportChange = IdentityFn,
}: EnhancedRendererComponentProps): React.ReactElement => {
  const editor: ReactEditorType = useMemo(() => editorOverride || createEditor(), [editorOverride]);
  const pluginsBag = usePlugins();

  // ensures that EnhancedSlate has access to properly-initialized SlateSingletonContext
  // in order to access provider enhancers
  useMount(() => {
    onInit?.({ editor, pluginsBag });
  });

  useEffect(() => {
    if (aiMode && editorOverride != null) {
      onReportChange(editor.children);
    }
  }, [aiMode, onReportChange, editorOverride, editor.children]);

  const handleChange = useCallback(
    (v) => {
      if (aiMode) {
        onChange(v);
      }
    },
    [aiMode, onChange]
  );

  return (
    <EnhancedSlate editor={editor} onChange={handleChange} value={editor.children}>
      {/* @ts-ignore [incompatible-type] used for ai mode */}
      <Renderer content={editorOverride != null && aiMode ? editor.children : value} />
    </EnhancedSlate>
  );
};

export const RichTextEditorComponent = ({
  onChange,
  isLoading,
  value,
  enableDefaultSelection = true,
  enableHoveringToolbar = true,
  editor: editorOverride,
  style,
  cursorStyle,
  lineIndicator,
  onInit,
  onDestroy,
  autoloadTemplateState,
  variant,
  placeholder = null,
  isAddendumEditor = false,
}: RichTextEditorComponentProps): React.ReactElement => {
  const sizes = useUnifiedEditorSizes();
  const pluginsBag = usePlugins();
  const { getEditorStateEnhancers } = pluginsBag;
  const { data } = useCurrentUser();
  // tells us when the user clicks or types into the RichTextEditor
  const { userActivityElementRef, setTextEditorElement } = useRecorder();
  const ignoreMergeFields =
    data?.me?.reporterSettings?.mergeFieldsSettings?.ignoreDefaultSelection ?? false;
  const [isAutoImpressionsEnabled] = useFeatureFlagEnabled(FF.REPORTER_AUTO_IMPRESSIONS);
  const hasDefaultSelectionRef = useRef(false);

  const editor = useMemo(
    () =>
      compose(
        !isTestEnv ? withOpsLog : IdentityFn,
        // @ts-expect-error [EN-7967] - TS2345 - Argument of type '[<T = EditorType>(editor: T) => any, ...EnhanceEditorState[]]' is not assignable to parameter of type '[...func: ((a: any) => any)[], f7: (a: unknown) => unknown, f6: (a: unknown) => unknown, f5: (a: unknown) => unknown, f4: (a: unknown) => unknown, f3: (a: unknown) => unknown, f2: (a: unknown) => unknown, f1: (...args: any[]) => unknown]'.
        withReact,
        ...getEditorStateEnhancers()
      )(editorOverride || createEditor()),
    [getEditorStateEnhancers, editorOverride]
  );

  useEffect(() => {
    // If a template is autoloading, allow that process to set the selection instead.
    if (
      !hasDefaultSelectionRef.current &&
      enableDefaultSelection &&
      autoloadTemplateState !== 'pending'
    ) {
      defaultSelection(editor, {
        ignoreMergeFields,
      });
      hasDefaultSelectionRef.current = true;
    }
  }, [enableDefaultSelection, editor, autoloadTemplateState, ignoreMergeFields]);

  useEffect(() => {
    try {
      ReactEditor.focus(editor);
    } catch (e: any) {
      logger.error(
        '[RichTextEditor] Error occurred when focusing the editor on load.',
        {
          editor: partialEditor(editor),
          ignoreMergeFields,
        },
        e
      );
    }

    onInit?.({ editor, pluginsBag });

    return () => {
      onDestroy?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary editor={editor} ignoreMergeFields={ignoreMergeFields}>
      {/* @ts-expect-error [EN-7967] - TS2322 - Type '{ current: HTMLElement; }' is not assignable to type 'LegacyRef<HTMLDivElement>'. */}
      <div ref={userActivityElementRef} css={{ display: 'flex', flex: '1', maxWidth: '100%' }}>
        <div ref={setTextEditorElement} css={{ display: 'flex', flex: '1', maxWidth: '100%' }}>
          <EnhancedSlate
            editor={editor}
            onChange={(content) => {
              // @ts-expect-error [EN-7967] - TS2339 - Property 'operations' does not exist on type 'unknown'.
              const isContentChange = editor.operations.some((op) => 'set_selection' !== op.type);
              //Slate triggers a change on a selection as well so we prevent the onChange from
              //firing when it is only a set selection operation
              if (isContentChange) {
                if (content.length === 0) {
                  logger.info("[RichTextEditor] Editor's content is empty, adding a paragraph.");
                  Transforms.insertNodes(editor, {
                    type: PARAGRAPH_PLUGIN_ID,
                    children: [{ text: '' }],
                  });
                  defaultSelection(editor, { ignoreMergeFields });
                  onChange([{ type: PARAGRAPH_PLUGIN_ID, children: [{ text: '' }] }]);
                  return;
                }

                onChange(content);
              }
            }}
            value={value}
          >
            {enableHoveringToolbar && <HoveringToolbar />}
            <PortalToolbarButton
              pluginID="placeholder"
              height={variant === 'fragment' ? '4.5rem' : '6rem'}
            />
            <PortalToolbarButton
              pluginID="list"
              height={variant === 'fragment' ? '4.5rem' : '6rem'}
            />
            {cursorStyle != null && sizes != null && (
              <CursorIndicator
                isAddendumEditor={isAddendumEditor}
                containerWidth={sizes.width}
                containerHeight={sizes.height}
                color={cursorStyle.color}
              />
            )}
            {lineIndicator != null && lineIndicator.enabled && sizes != null && (
              <LineIndicator
                containerWidth={sizes.width}
                containerHeight={sizes.height}
                variant={lineIndicator.variant}
                placement={lineIndicator.placement}
                editor={editor}
                isAddendumEditor={isAddendumEditor}
              />
            )}
            <BaseEditor
              css={`
                ${cursorStyle?.color != null ? `* { caret-color: transparent;}` : ''}
                ${isLoading === true ? 'display: none' : ''}
              `}
              placeholder={placeholder ?? ''}
              style={style}
              onDrop={undefined}
              onDragStart={undefined}
            />
            {isLoading === true && <RichTextEditorLoading />}
            {variant === 'report' && isAutoImpressionsEnabled && <AutoGenerateImpressions />}
            {env.MODE === 'development' && (
              <Suspense fallback={<></>}>
                <ReporterDevTools />
              </Suspense>
            )}
            {env.NODE_ENV !== 'test' && <ReporterOpsLogger initialState={value} />}
            <SyncSlateSelection editor={editor} variant={variant} />
          </EnhancedSlate>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export type RichTextEditorProps = Readonly<
  RichTextEditorComponentProps & {
    plugins: EditorPlugin[];
    editor?: Editor | null | undefined;
    variant: AllReporterVariant;
    isDisabled?: boolean;
    aiMode?: boolean;
    onReportChange?: (content: SlateContent) => void;
    isLoading?: boolean;
    /**
     * Run the browser in a headless manner. In this mode, the selection of the editor will never be set to null
     * unless you set it yourself using safeDeselect from utils. This allows for all operations to be ran under
     * the hood as expected and updates the selection as operations are applied.
     *
     * NON-HEADLESS MODE IS NOT FULLY SUPPORTED. YOU WILL LIKELY RUN INTO BUGS IF YOU USE IT DUE TO THE PICKLIST
     * AND INLINE BOOKMARK PLUGINS.
     *
     * @default true
     */
    headlessMode?: boolean;
    /**
     * A ref to point nav items to.
     */
    navMountPoint?: HTMLElement | null | undefined;
  }
>;

export const RichTextEditor = ({
  plugins,
  variant,
  navMountPoint,
  headlessMode = true,
  isAddendumEditor = false,
  isDisabled = false,
  value,
  onInit,
  editor,
  aiMode,
  onReportChange,
  isLoading = false,
  ...rest
}: RichTextEditorProps): React.ReactElement => {
  // Set this before bootstrapping the editor
  useEffect(() => {
    if (headlessMode) {
      Transforms.deselect = () => {};
    } else {
      Transforms.deselect = safeDeselect;
    }
  }, [headlessMode]);

  const featureFlagsLoaded = useHaveRichTextEditorFeatureFlagsLoaded();

  return (
    <ReporterProvider variant={variant} headlessMode={headlessMode} navMountPoint={navMountPoint}>
      <NodeStateProvider>
        <PluginsProvider plugins={plugins}>
          <RangeMasksProvider>
            {featureFlagsLoaded === false ? (
              <RichTextEditorLoading />
            ) : isDisabled === true ? (
              <EnhancedRendererComponent
                editor={editor}
                value={value}
                onInit={onInit}
                onChange={rest.onChange}
                aiMode={aiMode}
                onReportChange={onReportChange}
              />
            ) : (
              <RichTextEditorComponent
                variant={variant}
                value={value}
                onInit={onInit}
                isAddendumEditor={isAddendumEditor}
                editor={editor}
                isLoading={isLoading}
                {...rest}
              />
            )}
          </RangeMasksProvider>
        </PluginsProvider>
      </NodeStateProvider>
    </ReporterProvider>
  );
};

import { worldToIndex } from 'utils/dicomTagUtils';
import { useViewType, useViewportId } from '../../modules/state';
import type { Segment } from '../../types';
import { useImagingContext } from '../../modules/imaging/ImagingContext';
import { useCallback } from 'react';

export const usePlaceSegmentOnPlane = (): (<T extends ReadonlyArray<Segment>>(
  segments: T
) => T) => {
  const viewType = useViewType();
  const viewportId = useViewportId();
  const { imagingProvider } = useImagingContext();
  const activeSlice = imagingProvider?.getActiveSlice(viewType, viewportId);

  return useCallback(
    <T extends ReadonlyArray<Segment>>(segments: T): T => {
      // if we are unitialized, return identity function
      if (!imagingProvider) return segments;
      if (activeSlice == null) return segments;

      const tags = imagingProvider.getFrameTagsForViewIndex(viewType, activeSlice);
      const image = imagingProvider.vtkImage;
      const is3Dable = imagingProvider.is3Dable();
      if (is3Dable || !tags || !image) return segments;
      // @ts-expect-error [EN-7967] - TS2322 - Type 'any[][]' is not assignable to type 'T'.
      return segments.map((segment) => {
        return segment.map((point) => image.indexToWorld(worldToIndex(tags)(point)));
      });
    },
    [imagingProvider, viewType, activeSlice]
  );
};

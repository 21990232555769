import { Path } from 'domains/reporter/RichTextEditor/core';
import { ReactEditor, Node, Editor, Transforms } from '../../RichTextEditor/core';
import type { NavigationItem } from './BaseOutline';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { HeadingLevel } from '../../RichTextEditor/plugins/heading/constants';
import {
  isHeadingNode,
  removePunctuationAndWhitespace,
} from '../../RichTextEditor/plugins/heading/utils/normalization';
import { isEligibleNamedField } from '../../RichTextEditor/utils/fieldNaming';
import { getPathRefSafe } from '../../RichTextEditor/utils/refHelpers';
import type { SlateContent } from '../types';
import { walkSlateContent } from '../../RichTextEditor/utils/walkSlateContent';
import { Element } from 'slate';
import {
  InlineBookmarkElement,
  PicklistPluginElement,
} from 'domains/reporter/RichTextEditor/slate-custom-types';

export const getNavigationItemsFromReportContent = (
  content: SlateContent
): Array<NavigationItem> => {
  const items: Array<NavigationItem> = [];
  if (content == null) {
    return [];
  }

  const addNavigationItem = (node: Node) => {
    items.push(getNavigationItem(node, null, null));
  };

  walkSlateContent(addNavigationItem)(content);

  return items.filter((item) => item != null);
};

export const getNavigationItemsFromEditor = (editor?: Editor | null): Array<NavigationItem> => {
  const items: Array<NavigationItem> = [];
  if (editor?.children == null) {
    return [];
  }

  for (const [node, path] of Array.from(Node.nodes(editor))) {
    items.push(getNavigationItem(node, path, editor));
  }
  return items.filter((node) => node != null);
};

export const getNavigationItem = (
  node: Node,
  path?: Path | null,
  editor?: Editor | null
): NavigationItem | null | undefined => {
  if (isHeadingNode(node, HeadingLevel.H1)) {
    return {
      node,
      name: removePunctuationAndWhitespace(Node.string(node)),
      path: path != null && editor != null ? Editor.pathRef(editor, path) : null,
    };
  } else if (Element.isElement(node) && isEligibleNamedField(node.type)) {
    return {
      node,
      name: removePunctuationAndWhitespace(
        (node as PicklistPluginElement | InlineBookmarkElement).name ?? ''
      ),
      path: path != null && editor != null ? Editor.pathRef(editor, path) : null,
    };
  }

  return null;
};

export const selectNavigationItem = (editor: Editor, item: NavigationItem): void => {
  const fieldPath = item.path != null ? getPathRefSafe(item.path) : null;
  if (fieldPath == null) {
    return;
  }

  try {
    Transforms.select(editor, fieldPath);

    const domRange = ReactEditor.toDOMRange(editor, Editor.range(editor, fieldPath));
    const domSelection = window.getSelection();

    domSelection.removeAllRanges();
    domSelection.addRange(domRange);
    const [targetNode] = Editor.node(editor, fieldPath);
    const target = ReactEditor.toDOMNode(editor, targetNode);

    scrollIntoViewIfNeeded(target, {
      behavior: 'smooth',
      scrollMode: 'if-needed',
      block: 'end',
    });
  } catch (e: any) {
    // no-op, this can happen if we run Slate headlessly since there will be no
    // associated DOM
  }
};

import PixelWorker from './pixel-worker?sharedworker';
import type {
  InboundMessageData,
  WorkerMessageData,
  WorkerSingleMessageData,
} from './PixelWorkerConnection';
import { logger } from 'modules/logger';
import analytics from 'modules/analytics';
import { globalContext } from 'modules/analytics/constants';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import type { WorkerLoadedImagingProvider } from 'domains/viewer/ViewportDre/modules/imaging/WorkerLoadedImagingProvider';
import { PixelDataSharedWorkerError } from './PixelWorkerConnection';
import type { FullSingleLayerStack } from 'domains/viewer/ViewportsConfigurations/types';

// @ts-expect-error [EN-7967] - TS2430 - Interface 'PixelDataPort' incorrectly extends interface 'MessagePort'.
interface PixelDataPort extends MessagePort {
  readonly postMessage: (message: InboundMessageData, transfer?: Iterable<unknown>) => void;
}

// @ts-expect-error [EN-7967] - TS2430 - Interface 'PixelDataSharedWorker' incorrectly extends interface 'SharedWorker'.
export interface PixelDataSharedWorker extends SharedWorker {
  port: PixelDataPort;
}

export const providerMap: Map<string, WorkerLoadedImagingProvider<FullSingleLayerStack>> = new Map<
  string,
  WorkerLoadedImagingProvider<FullSingleLayerStack>
>();

const defaultOnMessage = (messageEvent: MessageEvent) => {
  if (messageEvent.data == null) {
    providerMap.forEach((provider) => {
      provider.failLoading(PixelDataSharedWorkerError.OutOfMemory);
      provider._resolveLoad();
    });
    return;
  }

  const data = messageEvent.data as WorkerMessageData;

  if (data.type === 'batched-messages') {
    data.messages.forEach(handleWorkerMessage);
  } else {
    handleWorkerMessage(data);
  }
};

export function createPixelDataSharedWorker(
  onmessage?: (messageEvent: MessageEvent) => void
): PixelDataSharedWorker {
  const pixelDataSharedWorker: PixelDataSharedWorker = new PixelWorker();
  pixelDataSharedWorker.port.start();

  pixelDataSharedWorker.port.onmessage = onmessage ?? defaultOnMessage;
  pixelDataSharedWorker.port.postMessage({ type: 'ping' });

  return pixelDataSharedWorker;
}

// TRACKING

export const analyticsStackInitialLoadsTracker: Set<string> = new Set<string>();
export const analyticsStackUnhangedLoadsTracker: Set<string> = new Set<string>();
export const providerTracker: Map<
  string,
  WorkerLoadedImagingProvider<FullSingleLayerStack>
> = new Map<string, WorkerLoadedImagingProvider<FullSingleLayerStack>>();

let hangedFramesCount = 0;
let hangedLoadsDoneTime = 0;

export function resetImagingProviderTrackers() {
  hangedFramesCount = 0;
  hangedLoadsDoneTime = 0;
  analyticsStackInitialLoadsTracker.clear();
  analyticsStackUnhangedLoadsTracker.clear();
  providerTracker.clear();
}

function handleWorkerMessage(data: WorkerSingleMessageData) {
  // @ts-expect-error [EN-7967] - TS2339 - Property 'stackSmid' does not exist on type 'HandleFrameMessage | LoadCompleteMessage | LoadErrorMessage | AnalyticsErrorMessage | AnalyticsTrackMessage | TransferCancelledMessage | PongMessage'.
  const provider = providerMap.get(data.stackSmid ?? '');

  switch (data.type) {
    case 'load-complete':
      analytics.timing('viewer_ws_imaging_provider_load_complete');

      if (analyticsStackInitialLoadsTracker.has(data.stackSmid)) {
        analyticsStackInitialLoadsTracker.delete(data.stackSmid);
        analytics.loadedFrames += providerTracker.get(data.stackSmid)?.stackSize ?? 0;
        providerTracker.delete(data.stackSmid);
        if (analyticsStackInitialLoadsTracker.size === 0) {
          analytics.timing('viewer_ws_imaging_provider_all_loads_complete');
          hangedLoadsDoneTime = performance.now();
          const time = hangedLoadsDoneTime - analytics.sessionStartTime;
          hangedFramesCount = analytics.loadedFrames;
          const averageHangedFPS = analytics.loadedFrames / (time / 1000);
          analytics.addContext(
            globalContext.viewer.loadMetrics.hangedFramesPerSecond,
            averageHangedFPS
          );
        }
      }

      if (analyticsStackUnhangedLoadsTracker.has(data.stackSmid)) {
        analyticsStackUnhangedLoadsTracker.delete(data.stackSmid);
        analytics.loadedFrames += providerTracker.get(data.stackSmid)?.stackSize ?? 0;
        providerTracker.delete(data.stackSmid);
        if (
          analyticsStackInitialLoadsTracker.size === 0 &&
          analyticsStackUnhangedLoadsTracker.size === 0
        ) {
          analytics.timing('viewer_ws_imaging_provider_unhanged_loads_complete');
          const unhangedLoadsDoneTime = performance.now();
          const time = unhangedLoadsDoneTime - analytics.sessionStartTime;

          const averageUnhangedFPS =
            (analytics.loadedFrames - hangedFramesCount) /
            ((unhangedLoadsDoneTime - hangedLoadsDoneTime) / 1000);
          analytics.addContext(
            globalContext.viewer.loadMetrics.unhangedFramesPerSecond,
            averageUnhangedFPS
          );
          const averageAllFPS = analytics.loadedFrames / (time / 1000);
          analytics.addContext(globalContext.viewer.loadMetrics.allFramesPerSecond, averageAllFPS);
        }
      }

      provider?._resolveLoad();
      break;
    case 'handle-frame':
    /* eslint-disable no-fallthrough */
    case 'handle-initial-frame':
      if (data.type === 'handle-initial-frame') {
        analytics.timing('viewer_ws_imaging_provider_handle_initial_frame');
      }
      provider?.handleFrame(data);
      break;
    /* eslint-enable no-fallthrough */
    case 'load-error':
      const { error } = data;
      if (provider != null) {
        provider.failLoading(error);
        provider._resolveLoad();
        logger.error(`load-error for ${provider.stack.smid}`, error);
      } else {
        logger.error(`load-error for unknown stack`, error);
      }
      break;
    case 'analytics-error':
      analytics.error(data.error, data.data);
      break;
    case 'analytics-track':
      analytics.track(data.name, data.data);
      break;
    case 'transfer-cancelled':
      logger.info('Transfer was cancelled');
      break;
    case 'pong':
      break;
    default:
      // @ts-expect-error [EN-7967] - TS2339 - Property 'type' does not exist on type 'never'.
      throw new Error(`Unknown message received: ${data.type}`);
  }
}

export const clearProviderMap = (): void => {
  if (providerMap == null) {
    return;
  }
  //before routing to a new case, we want to clear provider map for proper GC.
  const dataArray = vtkDataArray.newInstance({
    name: 'Scalars',
    values: [],
  });
  for (const key of providerMap.keys()) {
    const provider = providerMap.get(key);
    if (provider != null) {
      provider.cancelLoad();
      provider.vtkImage?.getPointData().setScalars(dataArray);
      provider.synchronizationBroadcaster.removeHandler();
    }
  }
  providerMap.clear();
};

import styled from 'styled-components';
import { Grid, Stack } from 'common/ui/Layout';
import { Icon } from 'common/ui/Icon';
import { Colors } from 'styles';
import { CircularProgress, DialogContentText } from '@material-ui/core';
import { SysChkValidator } from './validators';
import sizes from 'styles/sizes';
import { CHROME_EXTENSION_INSTALL_PAGE } from 'config/constants';
import spacings from 'styles/spacings';
import { PropsWithChildren } from 'react';

const ICON_COL_WIDTH = `${spacings.large}rem`;
const H_PADDING = `${spacings.small}rem`;

export const SysChkContainer = styled(DialogContentText)`
  font-size: ${sizes.textBody1};
  margin-bottom: 0;

  a {
    color: ${Colors.blue5};
  }
`;

export const SysChkProgress: React.FC = () => (
  <Stack alignX="center">
    <CircularProgress
      size="20px"
      css={`
        margin: 3rem 0 2rem;
        color: ${Colors.gray6};
      `}
      data-testid="syscheck-progress"
    />
  </Stack>
);

export enum SysChkHeaderVariant {
  PASS = 'PASS',
  PROGRESS = 'PROGRESS',
  FAIL = 'FAIL',
  WARN = 'WARN',
  BYPASS_PASS = 'BYPASS_PASS',
  BYPASS_FAIL = 'BYPASS_FAIL',
}
interface HeaderStyle {
  iconName: string;
  iconColor: string;
  bgColor: string;
  color: string;
}
const variantToStyles: Record<SysChkHeaderVariant, HeaderStyle> = {
  [SysChkHeaderVariant.PASS]: {
    iconName: 'check_circle',
    iconColor: Colors.green4,
    bgColor: Colors.green2,
    color: Colors.white80Percent,
  },
  [SysChkHeaderVariant.WARN]: {
    iconName: 'warning',
    iconColor: Colors.yellow5,
    bgColor: Colors.yellow2,
    color: Colors.gray4,
  },
  [SysChkHeaderVariant.FAIL]: {
    iconName: 'error',
    iconColor: Colors.red5,
    bgColor: Colors.red1,
    color: Colors.white80Percent,
  },
  [SysChkHeaderVariant.PROGRESS]: {
    iconName: 'refresh',
    iconColor: Colors.gray7,
    bgColor: Colors.gray3,
    color: Colors.white80Percent,
  },
  [SysChkHeaderVariant.BYPASS_PASS]: {
    iconName: 'error_outline',
    iconColor: Colors.orange6,
    bgColor: Colors.orange1,
    color: Colors.white80Percent,
  },
  [SysChkHeaderVariant.BYPASS_FAIL]: {
    iconName: 'error_outline',
    iconColor: Colors.orange6,
    bgColor: Colors.orange1,
    color: Colors.white80Percent,
  },
};
/** Header within the system check container. Displays the overall result of pass/fail/bypass */
export const SysChkHeader: React.FC<
  PropsWithChildren<{
    variant?: SysChkHeaderVariant;
    bgColor?: string;
    iconName?: string;
    iconColor?: string;
    color?: string;
  }>
> = ({ variant, bgColor, iconName, iconColor, color, children }) => {
  const style: HeaderStyle = variant
    ? variantToStyles[variant]
    : {
        bgColor,
        iconName,
        iconColor,
        color,
      };

  return (
    <Grid
      columns={[`${ICON_COL_WIDTH} 1fr`]}
      rows={['1fr']}
      css={`
        gap: 1;
        align-content: space-evenly;
        align-items: center;
        margin: 0 0 ${spacings.xsmall}rem;
        padding: ${spacings.xsmall}rem ${H_PADDING};
        background-color: ${style.bgColor};
        border-radius: 4px;
        transition: background-color 0.2s ease-in-out;
      `}
    >
      <Stack>
        <Icon
          size="small"
          name={style.iconName}
          css={`
            color: ${style.iconColor};
            transition: color 0.2s ease-in-out;
          `}
        />
      </Stack>
      <Stack
        css={`
          font-weight: bold;
          color: ${style.color};
        `}
      >
        {children}
      </Stack>
    </Grid>
  );
};

/** Visual element to encapsulate each check */
export const SysChkItem: React.FC<
  PropsWithChildren<{
    id: string;
    result: boolean;
    title: string;
  }>
> = ({ result, title, id, children }) => {
  return (
    <div
      key={id}
      css={`
        margin: ${spacings.xsmall}rem 0;
      `}
    >
      <Grid
        columns={[`${ICON_COL_WIDTH} 1fr`]}
        rows={['1fr']}
        css={`
          gap: 1;
          padding: 0 ${H_PADDING};
        `}
      >
        <Stack css="align-self: center;">
          <Icon
            size="small"
            name={result === true ? 'check' : 'clear'}
            css={`
              color: ${result === true ? Colors.green4 : Colors.red4};
            `}
          />
        </Stack>
        <Stack>{title}</Stack>
      </Grid>
      {result === false && (
        <div
          css={`
            --border-size: 3px;
            --border-dist: 1.5rem;
            margin-left: calc(
              ${ICON_COL_WIDTH} + ${H_PADDING} + 0.1rem - var(--border-dist) - var(--border-size)
            );
            font-size: ${sizes.textCaption};
            margin-bottom: ${spacings.xsmall}rem;
            margin-top: ${spacings.xxsmall}rem;
            border-left: var(--border-size) solid ${Colors.gray3};
            padding-left: var(--border-dist);
          `}
        >
          {children}
          <SysChkRemediations validatorId={id} />
        </div>
      )}
    </div>
  );
};

/** Renders the remediation steps for each validator */
const SysChkRemediations = ({
  validatorId,
}: {
  validatorId: string;
}): React.ReactElement | null => {
  switch (validatorId) {
    case SysChkValidator.ExtensionInstallState:
      return (
        <div>
          <Stack
            justifyContent="center"
            css={`
              margin: ${spacings.xsmall}rem 0;
              color: ${Colors.red5};
              font-weight: bold;
              border-radius: 4px;
              gap: ${spacings.xxsmall}rem;
            `}
          >
            <Icon name="priority_high" size="small" />{' '}
            <span>You will be unable to read cases without this step.</span>
          </Stack>
          Install the <strong>Sirona Medical Workspace Manager</strong> extension from the{' '}
          <a href={CHROME_EXTENSION_INSTALL_PAGE} target="_blank" rel="noreferrer">
            Chrome Web Store
          </a>
          .
        </div>
      );
    case SysChkValidator.ExtensionMinVersion:
      return (
        <div>
          Update the Sirona Medical Workspace Manager extension by opening a new tab, navigating to{' '}
          <code>chrome://extensions</code> and selecting "Update" on the top left.
        </div>
      );
    default:
      return null;
  }
};

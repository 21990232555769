import { motion } from 'framer-motion';
import AuthLayout from '../Layouts/Auth/AuthLayout';

const MotionDiv = motion.div;

type Props = {
  children: Array<React.ReactNode>;
  title: string;
  onSubmit: (evt: React.SyntheticEvent<HTMLFormElement>) => Promise<void>;
};

const AuthPage = ({ title, children, ...props }: Props): React.ReactElement => {
  return (
    <AuthLayout title={title}>
      <MotionDiv
        key="login"
        css={`
          display: flex;
          flex-direction: column;
          justify-content: center;
        `}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <form
          css={`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          `}
          {...props}
        >
          {children}
        </form>
      </MotionDiv>
    </AuthLayout>
  );
};

export default AuthPage;

import { useCallback, useMemo, useState } from 'react';
import Text from 'common/ui/Text';
import { IconButton, Switch } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useMutation } from '@apollo/client';
import { Stack, Spacer } from 'common/ui/Layout';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { SettingsCard, SettingsCardContent, SettingsCardHeader } from './SettingsLayoutComponents';
import { GET_ME, UPDATE_WORKLIST_SETTINGS } from 'modules/Apollo/queries';
import { MenuSeparator } from '../ui/MenuSeparator/index';
import { assocPath } from 'ramda';
import { GetMeQuery, GetMeQueryVariables } from 'generated/graphql';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';
import { SearchBar } from 'domains/worklist/Worklist/WorklistViews/SearchBar';
import { useWorklistViews } from 'domains/worklist/Worklist/WorklistViews/useWorklistViews';
import {
  PRIVATE_WORKLISTS,
  PUBLIC_WORKLISTS,
} from 'domains/worklist/Worklist/WorklistPageV2/constants';
import { WorklistSurface } from 'domains/worklist/Worklist/types';
import SearchIcon from '@material-ui/icons/Search';
import Table, { DefaultRow } from 'common/Table';
import { Colors } from 'styles';
import { Button } from 'common/ui/Button';
import { useDefaultCustomWorklists } from 'domains/worklist/Worklist/hooks/useDefaultCustomWorklists';
import { logger } from 'modules/logger';
import CircularProgress from '@material-ui/core/CircularProgress';

type WorklistSettingsTabProps = {
  onClose: () => void;
  setError: (error?: string | null | undefined) => void;
};

export const WorklistSettingsTab = ({
  onClose,
  setError,
}: WorklistSettingsTabProps): React.ReactElement => {
  const [updateWorklistSettings] = useMutation(UPDATE_WORKLIST_SETTINGS);
  const { data } = useCurrentUser();
  const worklistSettings = data?.me.worklistSettings;
  const [isCustomWorklistsMilestoneOneEnabled] = useFeatureFlagEnabled(
    FF.WORKLIST_CUSTOM_WORKLISTS_MILESTONE_1
  );
  const areSettingsLoading = useMemo(
    () => worklistSettings?.defaultCustomWorklistsSettings == null,
    [worklistSettings?.defaultCustomWorklistsSettings]
  );

  const openPatientJacketOnRead = worklistSettings?.openPatientJacketOnRead ?? true;

  const isDefaultCustomWorklistsEnabled = useMemo(() => {
    return worklistSettings?.defaultCustomWorklistsSettings?.isEnabled ?? false;
  }, [worklistSettings?.defaultCustomWorklistsSettings?.isEnabled]);
  const defaultCustomWorklists = useMemo(() => {
    return worklistSettings?.defaultCustomWorklistsSettings?.defaultCustomWorklists ?? [];
  }, [worklistSettings?.defaultCustomWorklistsSettings?.defaultCustomWorklists]);

  const { worklistViews, areWorklistViewsLoading } = useWorklistViews();
  const { saveDefaultCustomWorklists, isSaveDefaultCustomWorklistsLoading } =
    useDefaultCustomWorklists();

  const allPossibleWorklists = useMemo(() => {
    return [
      ...PUBLIC_WORKLISTS.map((worklist) => ({
        surface: worklist.surface,
        name: worklist.label,
        worklistViewID: null,
      })),
      ...PRIVATE_WORKLISTS.map((worklist) => ({
        surface: worklist.surface,
        name: worklist.label,
        worklistViewID: null,
      })),
      ...worklistViews.map((worklist) => ({
        surface: WorklistSurface.WORKLIST_VIEW,
        worklistViewID: worklist.smid,
        name: worklist.name,
      })),
    ];
  }, [worklistViews]);

  const [worklistSearch, setWorklistSearch] = useState('');
  const filteredWorklists = useMemo(() => {
    if (worklistSearch === '') {
      return allPossibleWorklists;
    }
    return allPossibleWorklists.filter((worklist) =>
      worklist.name.toLowerCase().includes(worklistSearch.toLowerCase())
    );
  }, [allPossibleWorklists, worklistSearch]);

  const toggleOpenPatientJacketOnRead = useCallback(
    async (evt: never) => {
      const updatedResponse = {
        ...worklistSettings,
        openPatientJacketOnRead: !openPatientJacketOnRead,
      } as const;

      await updateWorklistSettings({
        variables: { openPatientJacketOnRead: !openPatientJacketOnRead },
        optimisticResponse: {
          __typename: 'Mutation',
          updateWorklistSettings: {
            __typename: 'WorklistSettings',
            ...updatedResponse,
          },
        },
        update: (proxy) => {
          proxy.writeQuery({
            query: GET_ME,
            data: assocPath(
              ['me', 'worklistSettings', 'openPatientJacketOnRead'],
              !openPatientJacketOnRead,
              data
            ),
          });
        },
      });
    },
    [data, openPatientJacketOnRead, updateWorklistSettings, worklistSettings]
  );

  const toggleHasDefaultCustomWorklists = useCallback(async () => {
    const defaultCustomWorklistsSettings = {
      ...(worklistSettings?.defaultCustomWorklistsSettings ?? {}),
      isEnabled: !isDefaultCustomWorklistsEnabled,
    };

    const updatedResponse = {
      ...worklistSettings,
      defaultCustomWorklistsSettings,
    } as const;

    await updateWorklistSettings({
      variables: { defaultCustomWorklistsSettings },
      optimisticResponse: {
        __typename: 'Mutation',
        updateWorklistSettings: {
          __typename: 'WorklistSettings',
          ...updatedResponse,
          defaultCustomWorklistsSettings: {
            ...defaultCustomWorklistsSettings,
            __typename: 'DefaultCustomWorklistsSettings',
          },
        },
      },
      update: (proxy) => {
        const data = proxy.readQuery<GetMeQuery, GetMeQueryVariables>({ query: GET_ME }) ?? {};

        proxy.writeQuery({
          query: GET_ME,
          data: {
            me: {
              // @ts-expect-error [EN-7967] - TS2339 - Property 'me' does not exist on type '{}'.
              ...data?.me,
              worklistSettings: {
                // @ts-expect-error [EN-7967] - TS2339 - Property 'me' does not exist on type '{}'.
                ...data?.me?.worklistSettings,
                defaultCustomWorklistsSettings: {
                  ...defaultCustomWorklistsSettings,
                  __typename: 'DefaultCustomWorklistsSettings',
                },
              },
            },
          },
        });
      },
    });
    logger.info('[WorklistSettingsTab] Default custom worklists have been enabled');
  }, [isDefaultCustomWorklistsEnabled, updateWorklistSettings, worklistSettings]);

  const [selectedRowSurface, setSelectedRowSurface] = useState(null);
  const [selectedRowWorklistViewID, setSelectedRowWorklistViewID] = useState(null);

  const isDefaultCurrentlySelected = useMemo(
    () =>
      worklistSettings?.defaultCustomWorklistsSettings?.defaultCustomWorklists?.find(
        (worklist) =>
          worklist.surface === selectedRowSurface &&
          worklist.worklistViewID === selectedRowWorklistViewID
      ) != null,
    [
      selectedRowSurface,
      selectedRowWorklistViewID,
      worklistSettings?.defaultCustomWorklistsSettings?.defaultCustomWorklists,
    ]
  );

  return (
    <SettingsCard>
      <SettingsCardHeader>
        <Stack alignY="center">
          <Text variant="display2">Worklist Settings</Text>
          <Spacer />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </SettingsCardHeader>
      <SettingsCardContent>
        <Stack vertical space="medium">
          <Stack vertical space="small">
            <Text variant="display1" display="block">
              General
            </Text>
            <Stack alignY="center" alignX="between">
              <Text>Automatically open patient jacket when opening an exam</Text>
              <Switch
                data-testid="open-patient-jacket-switch"
                checked={openPatientJacketOnRead}
                onChange={toggleOpenPatientJacketOnRead}
              />
            </Stack>
            <MenuSeparator />
            {isCustomWorklistsMilestoneOneEnabled && !areSettingsLoading && (
              <>
                <Text variant="display1" display="block">
                  Default Worklists on Login
                </Text>
                <Stack alignY="center" alignX="between">
                  <Text>Select a worklist to display when you log in.</Text>
                  <Switch
                    data-testid="default-worklist-switch"
                    checked={isDefaultCustomWorklistsEnabled}
                    onChange={toggleHasDefaultCustomWorklists}
                  />
                </Stack>
                {isDefaultCustomWorklistsEnabled && (
                  <Stack
                    alignX="start"
                    alignY="center"
                    css="padding: 0px 16px;"
                    vertical
                    space="small"
                    data-stack-stretch
                  >
                    <Text variant="display1" display="block">
                      Available Worklists
                    </Text>
                    <SearchBar
                      type="search"
                      placeholder="Search worklists"
                      data-testid="worklist-settings-default-worklist-search"
                      icon={<SearchIcon />}
                      value={worklistSearch}
                      onChange={({ currentTarget: { value } }) => {
                        setWorklistSearch(value);
                      }}
                      css="width: 50%"
                    />
                    <Table
                      headerCss={`
                        background-color: ${Colors.gray2};
                        padding: 4px 24px !important;
                        border-radius: 6px 0px 0px 0px;
                        &:last-child {
                          border-radius: 0px 6px 0px 0px;
                        }
                      `}
                      css={`
                        background-color: ${Colors.gray0} !important;
                        border-radius: 6px;
                        width: 100%;
                        max-height: 300px;
                      `}
                      data-testid="worklist-settings-default-worklist-table"
                      Row={(props) => {
                        const row = props.row;
                        const isSelected =
                          row.original.surface === selectedRowSurface &&
                          row.original.worklistViewID === selectedRowWorklistViewID;

                        return (
                          <DefaultRow
                            data-testid={`default-worklist-row-${row.original.name}`}
                            css={`
                              height: 24px;
                              padding: 0px 8px 0px 24px;
                              cursor: pointer;
                              background-color: ${isSelected ? Colors.blue2 : 'inherit'};
                              &:hover {
                                background-color: ${Colors.blue2};
                              }
                            `}
                            onClick={() => {
                              setSelectedRowSurface(row.original.surface);
                              setSelectedRowWorklistViewID(row.original.worklistViewID);
                            }}
                            {...props}
                          />
                        );
                      }}
                      highlightRow={true}
                      hasNextPage={false}
                      loading={areWorklistViewsLoading}
                      columns={[
                        {
                          id: 'name',
                          header: 'Worklist Name',
                          accessorKey: 'name',
                        },
                        {
                          id: 'default',
                          header: '',
                          accessor: 'surface',
                          cell: ({ row }) => {
                            const isDefault =
                              defaultCustomWorklists.find((worklist) => {
                                if (worklist.surface === WorklistSurface.WORKLIST_VIEW) {
                                  return (
                                    row.original.surface === worklist.surface &&
                                    worklist.worklistViewID === row.original.worklistViewID
                                  );
                                }

                                return worklist.surface === row.original.surface;
                              }) != null;

                            return (
                              <Stack alignX="end">
                                {isDefault ? (
                                  <Text variant="body2" color="gray8" css="font-style: italic;">
                                    Current default
                                  </Text>
                                ) : (
                                  ''
                                )}
                              </Stack>
                            );
                          },
                        },
                      ]}
                      data={filteredWorklists}
                    />
                    <Stack stretchX alignX="end" space="small">
                      <Button
                        css="padding: 4px 24px"
                        variant="ghost"
                        onClick={() => {
                          setSelectedRowSurface(null);
                          setSelectedRowWorklistViewID(null);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        css="padding: 4px 24px"
                        variant="primary"
                        loading={isSaveDefaultCustomWorklistsLoading}
                        disabled={selectedRowSurface === null || isDefaultCurrentlySelected}
                        onClick={async () => {
                          await saveDefaultCustomWorklists([
                            {
                              surface: selectedRowSurface,
                              worklistViewID: selectedRowWorklistViewID,
                            },
                          ]);
                        }}
                      >
                        <Stack alignY="center" space="medium">
                          Save Default
                          {isSaveDefaultCustomWorklistsLoading ? (
                            <CircularProgress size="12px" css="color: white; margin-left: 4px" />
                          ) : null}
                        </Stack>
                      </Button>
                    </Stack>
                  </Stack>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </SettingsCardContent>
    </SettingsCard>
  );
};

import { withInlineTemplate } from '../common/inlineTemplate';
import type { CreateEnhanceEditorState } from '../../types';
import type { PlaceholderPluginPropertyOptions, PlaceholderPluginElement } from './types';

export const enhanceEditorStatePlaceholder: CreateEnhanceEditorState<
  PlaceholderPluginPropertyOptions
> = (options) => (editor) => {
  return withInlineTemplate<PlaceholderPluginElement>({
    pluginID: options.pluginID,
  })(editor);
};

import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
} from 'react';
import { unreachableCaseError } from 'types';
import { usePrevious } from 'react-use';
import { findLast, prop, uniqBy } from 'ramda';
import { getCountdownSeconds } from 'utils/report';
import type {
  UpdateReportMutation,
  UpdateReportMutationVariables,
  SubmitReportMutation,
  SubmitReportMutationVariables,
  CancelReportMutation,
  CancelReportMutationVariables,
  CreateAddendumMutation,
  CreateAddendumMutationVariables,
  UpdateAddendumMutation,
  UpdateAddendumMutationVariables,
  DeleteAddendumMutation,
  DeleteAddendumMutationVariables,
  SubmitAddendumMutation,
  SubmitAddendumMutationVariables,
  CancelAddendumMutation,
  CancelAddendumMutationVariables,
} from 'generated/graphql';
import {
  worklistItemToSlateContent,
  getDefaultSlateFieldChildren,
  generateAddendumSlateContentForAddendum,
  createAddendumFieldName,
  getAllPicklistsForTemplate,
  normalizeSlateContent,
  convertFieldsToUnified,
  convertUnifiedToFields,
} from './utils';
import { env } from 'config/env';
import { nanoid } from 'nanoid';
import { useDebouncedCallback } from 'use-debounce';
import { reporterSavingState, ReporterSaveState } from 'config/recoilState';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { AUTO_SAVE_DEBOUNCE_MS, AUTO_SAVE_MAX_WAIT_MS } from './constants';
import {
  UPDATE_REPORT,
  CREATE_ADDENDUM,
  SUBMIT_ADDENDUM,
  UPDATE_ADDENDUM,
  DELETE_ADDENDUM,
  SUBMIT_REPORT,
  CANCEL_REPORT,
  CANCEL_ADDENDUM,
  GET_WORKLIST_ITEM,
} from 'modules/Apollo/queries';
import { useMutation } from '@apollo/client';
import type { SlateContent } from '../types';
import {
  reportStatusState,
  PROVISIONAL_SUBMIT_STATUSES,
  ReportStatus,
  headingErrorState,
  hasReportContentChangedState,
  emptyPlaceholderDialogState,
  emptyRequiredFieldsDialogRecoilState,
  draftReportWorklistSmid,
  unsignedReportDialogState,
  defaultUnsignedReportWarningDialogState,
  reporterIsBypassAndSign,
} from '../state';
import type { ReportStatuses } from '../state';
import { usePicklistState } from '../../Template/usePicklist';
import { useCountdown, worklistUpdateBc } from './useCountdown';
import type { RichTextEditorComponentProps } from '../../RichTextEditor';
import { useRecorder } from 'common/Recorder/useRecorder';
import analytics from 'modules/analytics';
import { useToasterDispatch } from 'common/ui/Toaster';
import { type ToastProps, type ToastKey } from 'common/ui/Toaster/Toast';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { reporter } from 'modules/analytics/constants';
import {
  useEventsListener,
  NAMESPACES,
  sendEvent,
  ShowCrossTabNotification,
} from 'modules/EventsManager';
import { getPicklistsNodesFromParentNode } from '../../RichTextEditor/plugins/picklist/utils';
import { getEmptyPlaceholderFields } from '../../RichTextEditor/plugins/placeholder/utils';
import { createParagraph } from '../../RichTextEditor/plugins/paragraph/utils';
import { getHeadingErrorNodesFromParentNode } from '../../RichTextEditor/plugins/headingError/utils';
import { logger } from 'modules/logger';
import type { Toast } from 'common/ui/Toaster/Toaster';
import { readCaseState } from 'common/External/state';
import { useGamepadBindings } from 'utils/gamepad';
import { useWebHIDBindings } from 'modules/WebHID/useWebHID';
import { GamepadActionId } from 'generated/graphql';
import { useEditorBackup } from 'hooks/useEditorBackup';
import { getEmptyRequiredFields } from '../../RichTextEditor/utils/requiredFields';
import { useTypedCharacters } from 'hooks/useTypedCharacters';
import { RICH_TEXT_EDITOR } from 'hooks/useMostRecentInput';
import { useRequiredFieldIndicator } from 'hooks/useRequiredFieldIndicator';
import { PAGE_TYPES } from 'utils/pageTypes';
import { FF, useFeatureFlagEnabled, useSplitFlag } from 'modules/feature-flags';
import { useCurrentCaseReport } from 'hooks/useCurrentCaseReport';
import { useUnsignedReportWarningDialog } from '../UnsignedReportWarningDialog/useUnsignedReportWarningDialog';
import { useWorklistItemAnalytics } from 'hooks/useWorklistItemAnalytics';
import { useDiscardReport } from 'hooks/useDiscardReport';
import { discardReportState } from 'domains/reporter/Reporter/state';
import { useFocusMode } from 'hooks/useFocusMode';
import { useWorklistAutoLoad } from 'hooks/useWorklistAutoLoad';
import { RESPONSE_EVENTS as EXTENSION_RESPONSE_EVENTS } from '../../../extension/constants';
import {
  addExtensionListener,
  removeExtensionListener,
} from '../../../extension/extensionEventCreators';
import type {
  ReportMacros,
  LoadedReportWorklistItem,
  ReportTemplate,
} from 'hooks/useCurrentCaseReport';
import type { Macros } from '../../Template/usePicklist';
import { useReportVersion } from 'hooks/useReportVersion';
import { useManagePatientJacket } from 'hooks/useManagePatientJacket';
import type { AutoloadTemplateState } from 'hooks/useAutoloadTemplate';
import { useReporterHeartbeat } from 'hooks/useReporterHeartbeat';

export const newline = { type: 'paragraph', children: [{ text: '' }] } as const;

const ADDENDUM_NAME_PLACEHOLDER = 'New Addendum';

type UpdateType = 'contentChange' | 'templateApply';

type fieldsUpdateProps = {
  contentToSubmit: SlateContent;
  activateTemplateIDToSubmit?: string;
  isExplicitDraftSave?: boolean;
  onSuccess?: () => void;
  onError?: () => void;
};

type Addendum = {
  content: SlateContent;
  name: string;
};

export type FieldsState = {
  reportID: string;
  reporterToolbarMountPoint: HTMLElement | null | undefined;
  worklistItem: LoadedReportWorklistItem;
  previousWorklistItemRef: {
    current: LoadedReportWorklistItem | null | undefined;
  };
  content: SlateContent;
  finalizedAddendums: Addendum[];
  addendum: Addendum | null | undefined;
  addendumSMID: string | null | undefined;
  addendumSubmittedDate: Date | null | undefined;
  editorKey: string;
  macros: ReportMacros;
  /**
   * When loading the worklist item, the backend returns the default template in the response.
   * `activateTemplateID` is the id for that template that is applied by default when the user
   * opens the report, and also serves as the template id for any subsequent template insertions.
   */
  activateTemplateID?: string;
  updateType: UpdateType | null | undefined;
  touched?: boolean;
};

export type FieldsCountdownContextProps = {
  countdownSeconds: number | null;
};

const initFieldsState = ({
  worklistItem,
  previousWorklistItemRef,
  reporterToolbarMountPoint,
}: Readonly<{
  worklistItem: LoadedReportWorklistItem;
  previousWorklistItemRef: {
    current: LoadedReportWorklistItem | null | undefined;
  };
  reporterToolbarMountPoint: HTMLElement | null | undefined;
}>) => ({
  reportID: '',
  addendum: undefined,
  addendumSMID: undefined,
  addendumSubmittedDate: undefined,
  editorKey: nanoid(),
  reporterToolbarMountPoint,
  worklistItem,
  previousWorklistItemRef,
  updateType: undefined,
  content: worklistItemToSlateContent(worklistItem),
  macros: uniqBy(prop('smid'), [
    ...(worklistItem?.report?.template?.macros ?? []),
    ...(worklistItem?.report?.macros ?? []),
  ]),

  activateTemplateID: worklistItem?.report?.template?.id,
  finalizedAddendums:
    worklistItem?.report?.addendums
      ?.filter(({ submittedAt, sentAt: sentToMirthAt }) => {
        const addendumSecondsFromServer = getCountdownSeconds(submittedAt);
        return (
          (addendumSecondsFromServer != null && addendumSecondsFromServer < 0) ||
          sentToMirthAt !== null
        );
      })
      .map((addendum: any, index: any) => ({
        name: createAddendumFieldName({
          lastName: addendum.owner?.lastName,
          addendumNumber: index + 1,
          createdDate: addendum.created,
        }),
        content: generateAddendumSlateContentForAddendum(addendum) ?? [],
      })) ?? [],
});

type ActionType =
  | {
      type: 'initFieldsState';
      payload: {
        worklistItem: LoadedReportWorklistItem;
        previousWorklistItemRef: {
          current: LoadedReportWorklistItem | null | undefined;
        };
        reporterToolbarMountPoint: HTMLElement;
      };
    }
  | {
      type: 'setReporterToolbarMountPoint';
      payload: {
        reporterToolbarMountPoint: HTMLElement;
      };
    }
  | {
      type: 'updateReportContent';
      payload: {
        content: SlateContent;
      };
    }
  | {
      type: 'insertTemplate';
      payload: {
        macros: ReportMacros;
        activateTemplateID: string;
      };
    }
  | {
      type: 'addAddendum';
    }
  | {
      type: 'addAddendumSMID';
      payload: {
        addendumSMID: string;
      };
    }
  | {
      type: 'submitAddendum';
      payload: {
        addendumSubmittedDate: Date | null | undefined;
      };
    }
  | {
      type: 'updateAddendumContent';
      payload: {
        addendumContent: SlateContent;
      };
    }
  | {
      type: 'restoreAddendum';
      payload: {
        addendum: Addendum;
        addendumSMID: string;
        addendumSubmittedDate: Date | null | undefined;
      };
    }
  | {
      type: 'clearAddAddendum';
    }
  | {
      type: 'finalizeProvisionalAddendum';
      payload: {
        submittingUserLastName: string | null | undefined;
        addendumSubmittedDate?: Date | null | undefined;
      };
    };

const fieldsReducer = (state: FieldsState, action: ActionType): FieldsState => {
  switch (action.type) {
    case 'initFieldsState':
      return initFieldsState({
        ...action.payload,
      });
    case 'setReporterToolbarMountPoint':
      return { ...state, ...action.payload };
    case 'updateReportContent':
      return {
        ...state,
        ...action.payload,
        // After every `insertTemplate` action, we will also receive a `updateReportContent` action
        // We don't want to attribute this action to the user, so we reset the `updateType` to null
        // Any subsequent changes will be attributed to the user
        updateType: state.updateType === 'templateApply' ? null : 'contentChange',
      };
    case 'insertTemplate':
      return {
        ...state,
        ...action.payload,
        updateType: 'templateApply',
      };
    case 'addAddendum':
      return {
        ...state,
        addendum: {
          name: ADDENDUM_NAME_PLACEHOLDER,
          content: getDefaultSlateFieldChildren(),
        },
      };
    case 'restoreAddendum':
      let addendumContent = action.payload.addendum?.content;
      // We expect addendum content. If it's empty, it's likely an internal test case.
      if (action.payload.addendum?.content.length !== 0) {
        addendumContent =
          action.payload.addendum?.content.__typename === 'AddendumSironaSlate'
            ? action.payload.addendum?.content.sironaSlate
            : action.payload.addendum?.content.textBlob
                .split('\n')
                .map((text: any) => createParagraph(text));
      }
      return {
        ...state,
        addendumSMID: action.payload.addendumSMID,
        addendumSubmittedDate: action.payload.addendumSubmittedDate,
        addendum: {
          name: action.payload.addendum?.name ?? ADDENDUM_NAME_PLACEHOLDER,
          content: addendumContent,
        },
      };
    case 'updateAddendumContent':
      return {
        ...state,
        addendum: {
          name: state.addendum?.name ?? '',
          content: action.payload.addendumContent,
        },
        updateType: 'contentChange',
      };
    case 'addAddendumSMID':
      return { ...state, ...action.payload };
    case 'submitAddendum':
      return { ...state, ...action.payload };
    case 'clearAddAddendum':
      return { ...state, addendumSMID: undefined, addendum: undefined };
    case 'finalizeProvisionalAddendum':
      return {
        ...state,
        finalizedAddendums: [
          ...state.finalizedAddendums,
          {
            name: createAddendumFieldName({
              addendumNumber: state.finalizedAddendums.length + 1,
              lastName: action.payload.submittingUserLastName,
              createdDate: state.addendumSubmittedDate ?? action.payload.addendumSubmittedDate,
            }),
            content: state.addendum?.content,
          },
        ],
        addendumSMID: undefined,
        addendum: undefined,
      };
    default:
      unreachableCaseError((action as unknown as ActionType).type);
  }

  return state;
};

export type FieldsDispatch = {
  dispatch: (arg1: ActionType) => void;
  handleInsertTemplate: () => void;
  onReportChange: (content: SlateContent) => void;
  onAddendumChange: (content: SlateContent) => void;
  handleSubmit: (options?: {
    force?: boolean;
    onSuccess?: () => void;
    onError?: () => void;
  }) => Promise<void>;
  handleCancelSubmit: () => Promise<void>;
  handleAddendumAdd: () => Promise<void>;
  handleCancelAddAddendum: (options?: {
    onSuccess?: () => void;
    onError?: () => void;
  }) => Promise<void>;
  handleSaveDraft: (options?: { onSuccess?: () => void; onError?: () => void }) => Promise<void>;
};

const FieldsStateContext = createContext<FieldsState | null | undefined>(undefined);
const FieldsCountdownContext = createContext<FieldsCountdownContextProps | null | undefined>(
  undefined
);
const FieldsDispatchContext = createContext<FieldsDispatch | null | undefined>(undefined);

const getReportSMID = (worklistItem: LoadedReportWorklistItem) => {
  const { report } = worklistItem;
  if (report == null) {
    throw new Error('Cannot update a report without a smid.');
  }

  return report.smid;
};

const enqueueAutoSaveErrorToast = ({
  enqueueToast,
  toastKey,
}: {
  enqueueToast: (msg: React.ReactNode, options?: Readonly<Partial<Toast>>) => ToastKey;
  toastKey: string;
}) => {
  enqueueToast('Auto saving failed, contact Sirona if same error persists.', {
    toastKey,
    autoHideDuration: null,
    yOffset: 45,
    icon: 'redWarning',
  });
};

const getCaseInfo = (worklistItem: LoadedReportWorklistItem) =>
  `${worklistItem?.patientName ?? 'Patient Name: N/A'}, ${worklistItem?.studyDescription ?? 'Study Description: N/A'} (${worklistItem?.accessionNumber ?? 'Accession Number: N/A'})`;

const defaultToastOptions = {
  severity: 'error',
  position: 'bottom-right',
} as const;

export type FieldsProviderProps = Readonly<{
  worklistItem: LoadedReportWorklistItem;
  template?: ReportTemplate;
  clearTemplate: () => void;
  isDisabled: boolean;
  children?: React.ReactNode;
  reporterToolbarMountPoint: HTMLElement | null | undefined;
  isClaimedByMe: boolean;
  onInit?: RichTextEditorComponentProps['onInit'];
  onDestroy?: RichTextEditorComponentProps['onDestroy'];
  autoloadTemplateState?: AutoloadTemplateState;
  insertTemplate: (arg1: ReportTemplate) => void;
  aiMode?: boolean;
}>;

export const FieldsProvider = ({
  children,
  worklistItem,
  template,
  isDisabled,
  clearTemplate,
  reporterToolbarMountPoint,
  insertTemplate,
  aiMode = false,
  isClaimedByMe,
}: FieldsProviderProps): React.ReactElement => {
  const previousWorklistItemRef = useRef<LoadedReportWorklistItem | null>(null);
  const [state, dispatch] = useReducer(
    fieldsReducer,
    {
      worklistItem,
      previousWorklistItemRef,
      reporterToolbarMountPoint,
    },
    initFieldsState
  );
  const { content, activateTemplateID, macros, addendumSMID, addendum, updateType } = state;

  const analyticsData = useWorklistItemAnalytics();
  const previousContent = usePrevious(content);
  const previousAddendum = usePrevious(addendum);
  const [isReporterSubmissionEnabled] = useSplitFlag(
    FF.ENABLE_REPORTER_SUBMISSION,
    'on',
    (v) => v === 'on'
  );
  const { workstationID } = useRecoilValue(readCaseState);
  const { picklists, setPicklists, setMacros } = usePicklistState();
  const [savingState, setSavingState] = useRecoilState(reporterSavingState);
  const [hasReportContentChanged, setHasReportContentChanged] = useRecoilState(
    hasReportContentChangedState
  );
  const setDraftReportWorklistSmid = useSetRecoilState(draftReportWorklistSmid);
  const [unsignedReportDialogOptions, setUnsignedReportWarningDialogState] =
    useRecoilState(unsignedReportDialogState);
  const [status, setStatus] = useRecoilState<ReportStatuses>(reportStatusState);
  const [hasHeadingError, setHasHeadingError] = useRecoilState(headingErrorState);
  const { data } = useCurrentUser();
  const { refetch: refetchCurrentCase, currentCaseId, isAddendumLocked } = useCurrentCaseReport();
  const { onDialogActionSuccess, onDialogActionError } = useUnsignedReportWarningDialog();
  const { handleDiscardReport, handleOpenDiscardReportModal } = useDiscardReport();
  const discardReportOptions = useRecoilValue(discardReportState);
  const { toggleFocusMode, isFocusModeOn, setIsFocusModeOn, isFocusModeSettingEnabled } =
    useFocusMode();
  const isBypassAndSign = useRecoilValue(reporterIsBypassAndSign);
  const { incrementReportVersion } = useReportVersion();
  const { managePatientJacket } = useManagePatientJacket();
  const [isLockClaimAddendumEnabled] = useFeatureFlagEnabled(FF.LOCK_CLAIM_ADDENDUM);
  const { sendHeartbeat } = useReporterHeartbeat({});

  const isFocusModeAllowed = useMemo(() => {
    return (
      isFocusModeSettingEnabled &&
      !isDisabled &&
      ([ReportStatus.Idle, ReportStatus.Init] as unknown as Partial<ReportStatuses>).includes(
        status
      )
    );
  }, [isFocusModeSettingEnabled, isDisabled, status]);

  const me = data?.me;
  const [updateReport] = useMutation<UpdateReportMutation, UpdateReportMutationVariables>(
    UPDATE_REPORT
  );
  const [submitReport] = useMutation<SubmitReportMutation, SubmitReportMutationVariables>(
    SUBMIT_REPORT
  );
  const [cancelReport] = useMutation<CancelReportMutation, CancelReportMutationVariables>(
    CANCEL_REPORT
  );
  const [createAddendum] = useMutation<CreateAddendumMutation, CreateAddendumMutationVariables>(
    CREATE_ADDENDUM
  );
  const [submitAddendum] = useMutation<SubmitAddendumMutation, SubmitAddendumMutationVariables>(
    SUBMIT_ADDENDUM
  );
  const [updateAddendum] = useMutation<UpdateAddendumMutation, UpdateAddendumMutationVariables>(
    UPDATE_ADDENDUM
  );
  const [deleteAddendum] = useMutation<DeleteAddendumMutation, DeleteAddendumMutationVariables>(
    DELETE_ADDENDUM
  );
  const [cancelAddendum] = useMutation<CancelAddendumMutation, CancelAddendumMutationVariables>(
    CANCEL_ADDENDUM
  );
  const { enqueueToast, enqueueOrUpdateToast, closeToast } = useToasterDispatch();
  const [latestToastKey, setLatestToastKey] = useState<ToastKey | null>(null);
  const { countdownSeconds, startCountdown, cancelCountdown, clearTimer } = useCountdown({
    worklistItem,
  });
  const { trackTypedCharacters } = useTypedCharacters(RICH_TEXT_EDITOR);
  const { isRecording, stopRecording } = useRecorder();

  const { setEditorBackup, restoreEditorBackup } = useEditorBackup();
  const { setAllRequiredFieldsTouched } = useRequiredFieldIndicator();

  const { readNextCase, isWorklistAutoLoadEnabled } = useWorklistAutoLoad();

  useEffect(() => {
    setMacros((macroState: Macros): Macros => {
      const oldMacros = macroState ?? [];
      const newMacros = macros ?? [];
      return uniqBy(prop('smid'), [...oldMacros, ...newMacros]);
    });
  }, [setMacros, macros]);

  const restoreStatus = useCallback(() => {
    const { report } = worklistItem;

    if (countdownSeconds != null || report == null) return;

    const { addendums } = report;

    const latestAddendum = isLockClaimAddendumEnabled
      ? addendums.find((addendum) => addendum.sentAt == null)
      : findLast((addendum) => addendum?.owner?.id === me?.id, addendums);

    if (latestAddendum) {
      const { submittedAt, sentAt: sentToMirthAt, smid, content } = latestAddendum;
      const addendumSecondsFromServer = getCountdownSeconds(submittedAt);
      const addendumPayload = {
        addendumSMID: smid,
        addendum: {
          name:
            isLockClaimAddendumEnabled && !isClaimedByMe
              ? createAddendumFieldName({
                  lastName: latestAddendum.owner?.lastName,
                  addendumNumber: addendums.length,
                  createdDate: latestAddendum.updated,
                  updatedDate: latestAddendum.updated,
                  isAddendumLocked,
                })
              : ADDENDUM_NAME_PLACEHOLDER,
          content,
        },
        addendumSubmittedDate: submittedAt,
      };

      if (addendumSecondsFromServer == null) {
        setStatus(ReportStatus.AddendumAdd);

        dispatch({
          type: 'restoreAddendum',
          payload: addendumPayload,
        });
      } else if (addendumSecondsFromServer > 0 && !isBypassAndSign && sentToMirthAt == null) {
        startCountdown({ submittedAt: submittedAt });
        setStatus(ReportStatus.ProvisionalAddendumSubmit);
        dispatch({
          type: 'restoreAddendum',
          payload: addendumPayload,
        });
      } else {
        setStatus(ReportStatus.Submitted);
      }

      return;
    }

    const { submittedAt, sentAt: sentToMirthAt } = report;
    const reportSecondsFromServer = getCountdownSeconds(submittedAt);

    if (reportSecondsFromServer != null) {
      if (reportSecondsFromServer > 0 && !isBypassAndSign && sentToMirthAt == null) {
        setStatus(ReportStatus.ProvisionalSubmit);
        startCountdown({ submittedAt: submittedAt });
      } else {
        setStatus(ReportStatus.Submitted);
      }

      return;
    }
    // This line allows us to easily mock a specified report status within storybook
    env.STORYBOOK_STORYSHOTS !== 'true' && setStatus(ReportStatus.Idle);
  }, [
    worklistItem,
    countdownSeconds,
    isLockClaimAddendumEnabled,
    setStatus,
    me?.id,
    isBypassAndSign,
    isClaimedByMe,
    isAddendumLocked,
    startCountdown,
  ]);

  useEffect(() => {
    if (worklistItem?.smid !== previousWorklistItemRef?.current?.smid) {
      previousWorklistItemRef.current = worklistItem;
      setHasReportContentChanged(false);
      setDraftReportWorklistSmid(null);
      setUnsignedReportWarningDialogState(defaultUnsignedReportWarningDialogState);
      restoreStatus();
    }
  }, [
    worklistItem,
    setHasReportContentChanged,
    setDraftReportWorklistSmid,
    setUnsignedReportWarningDialogState,
    restoreStatus,
  ]);

  useEffect(() => {
    if (reporterToolbarMountPoint != null) {
      dispatch({
        type: 'setReporterToolbarMountPoint',
        payload: {
          reporterToolbarMountPoint,
        },
      });
    }
  }, [reporterToolbarMountPoint]);

  const sendFieldsUpdate = useCallback(
    async ({
      contentToSubmit,
      activateTemplateIDToSubmit,
      isExplicitDraftSave,
      onSuccess,
      onError,
      content,
      updatedVersion,
    }) => {
      // Only send picklists found on the current content
      const contentPicklistNodeIds = getPicklistsNodesFromParentNode({
        children: content,
      }).map(({ picklistID }) => picklistID);
      const filteredPicklists = picklists
        .filter(({ id }) => contentPicklistNodeIds.includes(id))
        .map(({ __typename, ...picklist }) => ({
          ...picklist,
          options: picklist.options.map(({ __typename, ...option }) => ({
            ...option,
            default: option.default != null ? option.default : false,
          })),
        }));

      await updateReport({
        variables: {
          smid: getReportSMID(worklistItem),
          worklistItemSmid: worklistItem.smid,
          payload: {
            sections: content,
            templateId: activateTemplateIDToSubmit,
            picklists: filteredPicklists,
            workstationID,
            version: updatedVersion,
          },
        },
        onCompleted: async () => {
          if (isExplicitDraftSave) {
            closeToast(`update-report-error-${getReportSMID(worklistItem)}`);
            closeToast(`save-draft-error-${getReportSMID(worklistItem)}`);

            const defaultToastMsg = `Draft report for ${getCaseInfo(worklistItem)} has been saved.`;

            let toastMsg = defaultToastMsg;
            let toastOptions = {
              yOffset: 45,
              icon: 'greenCheck',
              ...(isWorklistAutoLoadEnabled === true
                ? {
                    autoHideDuration: 8000,
                  }
                : {}),
            };

            let nextCaseSmid: string | null = null;

            if (isWorklistAutoLoadEnabled) {
              if (currentCaseId != null) {
                logger.info(
                  `[Fields.Context] Attempting to autoLoad next case after drafting worklistItem ${currentCaseId}`
                );
              }
              try {
                nextCaseSmid = await readNextCase();
                if (nextCaseSmid == null) {
                  toastMsg = `${defaultToastMsg} No more exams available to autoload.`;
                }
              } catch (error: any) {
                toastMsg = `${defaultToastMsg} ${error.message}`;
                toastOptions = {
                  ...toastOptions,
                  icon: 'redWarning',
                };
              }
            }

            managePatientJacket({ nextCaseSmid });
            enqueueToast(toastMsg, toastOptions as ToastProps);
            sendEvent(NAMESPACES.CROSS_TAB_NOTIFICATION, {
              type: 'showCrossTabNotification',
              payload: {
                toastKey: nanoid(),
                targetPageTypes: [PAGE_TYPES.WORKLIST],
                message: toastMsg,
                ...toastOptions,
              },
            } as ShowCrossTabNotification);
            setDraftReportWorklistSmid(worklistItem.smid);
            setHasReportContentChanged(false);
            onSuccess && onSuccess();
          } else {
            closeToast(`update-report-error-${getReportSMID(worklistItem)}`);
            setEditorBackup(convertFieldsToUnified(content));
            setHasReportContentChanged(updateType === 'contentChange');
          }
          setSavingState(ReporterSaveState.Saved);
        },
        onError: (e) => {
          // RP-3354: ignore version conflict errors since the most recent changes have already been saved to the server
          const hasVersionConflict = e.message.includes(
            'Cannot update report because of version conflict'
          );
          if (hasVersionConflict) {
            logger.warn('[Fields.Context] ', e.message);
            onError && onError();
            return;
          }

          const doesNotHavePermission = e.message.includes(
            'You do not have permission to perform this action'
          );
          if (isExplicitDraftSave) {
            enqueueToast(
              `Draft report for ${getCaseInfo(worklistItem)} could not be saved due to a connection error.`,
              {
                toastKey: `save-draft-error-${getReportSMID(worklistItem)}`,
                autoHideDuration: null,
                yOffset: 45,
                icon: 'redWarning',
              }
            );
          } else if (doesNotHavePermission) {
            if (isRecording) {
              stopRecording();
            }
            refetchCurrentCase && refetchCurrentCase();
            enqueueToast(
              `You no longer have permission to edit this report. It may have been claimed by another user or a clinic administrator.`,
              {
                toastKey: `update-report-error-${getReportSMID(worklistItem)}`,
                autoHideDuration: null,
                yOffset: 45,
                icon: 'redWarning',
                isUnique: true,
              }
            );
          } else {
            enqueueAutoSaveErrorToast({
              enqueueToast,
              toastKey: `update-report-error-${getReportSMID(worklistItem)}`,
            });
          }

          if (e.networkError == null) {
            restoreEditorBackup(convertFieldsToUnified(content));
          }

          if (doesNotHavePermission) {
            logger.warn(
              '[Fields.Context] User does not have permission to update report',
              {
                reportSmid: getReportSMID(worklistItem),
                content,
                isExplicitDraftSave,
              },
              e
            );
          } else {
            logger.error(
              '[Fields.Context] Error occurred when updating report',
              {
                reportSmid: getReportSMID(worklistItem),
                content,
                isExplicitDraftSave,
              },
              e
            );
          }

          setSavingState(ReporterSaveState.Error);
          setHasReportContentChanged(updateType === 'contentChange');

          onError && onError();
        },
      });
    },
    [
      closeToast,
      currentCaseId,
      enqueueToast,
      isRecording,
      isWorklistAutoLoadEnabled,
      managePatientJacket,
      picklists,
      readNextCase,
      refetchCurrentCase,
      restoreEditorBackup,
      setDraftReportWorklistSmid,
      setEditorBackup,
      setHasReportContentChanged,
      setSavingState,
      stopRecording,
      updateReport,
      updateType,
      worklistItem,
      workstationID,
    ]
  );

  const fieldsUpdate = useCallback(
    async ({
      contentToSubmit,
      activateTemplateIDToSubmit,
      isExplicitDraftSave = false,
      onSuccess,
      onError,
    }: fieldsUpdateProps) => {
      let content = contentToSubmit;
      if (content == null) {
        return;
      }

      content = convertUnifiedToFields(content);

      // Do not save the draft until its valid
      if (content.find((field) => field.children.length === 0)) {
        // if it's a heading error, send a warning log, because the user is aware of the error
        if (hasHeadingError === true) {
          const draftErrorMessage = '[Fields.Context] There is a heading error. Not saving.';
          logger.warn(draftErrorMessage, {
            reportSmid: getReportSMID(worklistItem),
            fieldsContent: contentToSubmit,
            unifiedContent: content,
          });
        } else {
          const draftErrorMessage =
            '[Fields.Context] Draft report is missing children for field. Not saving.';
          logger.error(draftErrorMessage, {
            reportSmid: getReportSMID(worklistItem),
            fieldsContent: contentToSubmit,
            unifiedContent: content,
          });
        }

        setSavingState(ReporterSaveState.Error);

        return;
      }

      // We need to ensure that the next version is correct before sending the update
      const updatedVersion = await incrementReportVersion();

      logger.info('[Fields.Context] Updating report...');

      await sendFieldsUpdate({
        contentToSubmit,
        activateTemplateIDToSubmit,
        isExplicitDraftSave,
        onSuccess,
        onError,
        content,
        updatedVersion,
      });
    },
    [hasHeadingError, setSavingState, worklistItem, incrementReportVersion, sendFieldsUpdate]
  );

  const fieldsUpdateDebounced = useDebouncedCallback(
    async ({ contentToSubmit, activateTemplateIDToSubmit }: fieldsUpdateProps) =>
      // autosave happens here
      fieldsUpdate({
        contentToSubmit,
        activateTemplateIDToSubmit,
      }),
    AUTO_SAVE_DEBOUNCE_MS,
    {
      trailing: true,
      leading: true,
      maxWait: AUTO_SAVE_MAX_WAIT_MS,
    }
  );

  const onReportChange = useCallback(
    (newContent: SlateContent) => {
      dispatch({
        type: 'updateReportContent',
        payload: {
          content: newContent,
        },
      });

      const headingErrorNodes = getHeadingErrorNodesFromParentNode({ children: newContent });
      setHasHeadingError(headingErrorNodes.length > 0);
    },
    [dispatch, setHasHeadingError]
  );

  const addendumUpdate = useCallback(
    async ({
      contentToSubmit,
      addendumSMID,
      isExplicitDraftSave = false,
      onSuccess,
      onError,
    }: {
      contentToSubmit: SlateContent;
      addendumSMID: string;
      isExplicitDraftSave?: boolean;
      onSuccess?: () => void;
      onError?: () => void;
    }) => {
      if (
        contentToSubmit == null ||
        addendumSMID == null ||
        (isClaimedByMe === false && isLockClaimAddendumEnabled)
      )
        return;

      await updateAddendum({
        variables: {
          smid: addendumSMID,
          payload: { content: contentToSubmit, workstationID },
        },
        onCompleted: () => {
          setSavingState(ReporterSaveState.Saved);

          if (isExplicitDraftSave) {
            closeToast(`save-draft-addendum-error-${getReportSMID(worklistItem)}`);
            const toastMsg = `Draft addendum for ${getCaseInfo(worklistItem)} has been saved.`;
            const toastOptions = {
              yOffset: 45,
              icon: 'greenCheck',
            } as const;
            enqueueToast(toastMsg, toastOptions);
            sendEvent(NAMESPACES.CROSS_TAB_NOTIFICATION, {
              type: 'showCrossTabNotification',
              payload: {
                toastKey: nanoid(),
                targetPageTypes: [PAGE_TYPES.WORKLIST],
                message: toastMsg,
                ...toastOptions,
              },
            });
            setDraftReportWorklistSmid(worklistItem.smid);
            setHasReportContentChanged(false);
            onSuccess && onSuccess();
          } else {
            closeToast(`update-report-error-${getReportSMID(worklistItem)}`);
            setEditorBackup(contentToSubmit);
          }
        },
        onError: (e) => {
          if (isExplicitDraftSave) {
            enqueueToast(
              `Draft addendum for ${getCaseInfo(worklistItem)} could not bes saved due to connection error.`,
              {
                toastKey: `save-draft-addendum-error-${getReportSMID(worklistItem)}`,
                autoHideDuration: null,
                yOffset: 45,
                icon: 'redWarning',
              }
            );
          } else {
            enqueueAutoSaveErrorToast({
              enqueueToast,
              toastKey: `update-addendum-error-${getReportSMID(worklistItem)}`,
            });
          }

          if (e.networkError == null) {
            restoreEditorBackup(contentToSubmit);
          }

          logger.error(
            '[Fields.Context] Error occurred when updating addendum',
            {
              addendumSmid: addendumSMID,
              content: contentToSubmit,
            },
            e
          );

          setSavingState(ReporterSaveState.Error);

          onError && onError();
        },
      });
    },
    [
      closeToast,
      enqueueToast,
      isClaimedByMe,
      isLockClaimAddendumEnabled,
      restoreEditorBackup,
      setDraftReportWorklistSmid,
      setEditorBackup,
      setHasReportContentChanged,
      setSavingState,
      updateAddendum,
      worklistItem,
      workstationID,
    ]
  );

  const addendumUpdateDebounced = useDebouncedCallback(addendumUpdate, AUTO_SAVE_DEBOUNCE_MS, {
    trailing: true,
    leading: false,
    maxWait: AUTO_SAVE_MAX_WAIT_MS,
  });

  const onAddendumChange = useCallback(
    (newAddendumContent: SlateContent) => {
      sendHeartbeat();
      dispatch({
        type: 'updateAddendumContent',
        payload: { addendumContent: newAddendumContent },
      });
    },
    [sendHeartbeat]
  );

  const handleSaveDraft = useCallback(
    async ({
      onSuccess,
      onError,
    }: {
      onSuccess?: () => void;
      onError?: () => void;
    } = {}) => {
      if (savingState === ReporterSaveState.Saving) {
        return;
      }

      if (status !== ReportStatus.AddendumAdd) {
        setSavingState(ReporterSaveState.Saving);
        await fieldsUpdate({
          contentToSubmit: content,
          activateTemplateIDToSubmit: activateTemplateID,
          isExplicitDraftSave: true,
          onSuccess,
          onError,
        });
      } else if (status === ReportStatus.AddendumAdd && addendumSMID != null) {
        setSavingState(ReporterSaveState.Saving);
        await addendumUpdate({
          contentToSubmit: addendum?.content,
          addendumSMID,
          isExplicitDraftSave: true,
          onSuccess,
          onError,
        });
      } else {
        logger.info('[Fields.Context] Draft report did not save.', {
          status,
          addendumSMID,
          worklistItemSmid: worklistItem.smid,
          content: status === ReportStatus.AddendumAdd ? addendum?.content : content,
        });
      }

      if (isRecording) {
        await stopRecording();
      }
    },
    [
      savingState,
      status,
      addendumSMID,
      isRecording,
      setSavingState,
      fieldsUpdate,
      content,
      activateTemplateID,
      addendumUpdate,
      addendum?.content,
      worklistItem.smid,
      stopRecording,
    ]
  );

  const handleInsertTemplate = useCallback(() => {
    if (template == null) return;

    const templateMacros = template.macros ?? [];
    const worklistMacros = worklistItem.report?.macros ?? [];

    dispatch({
      type: 'insertTemplate',
      payload: {
        macros: uniqBy(prop('smid'), [...templateMacros, ...worklistMacros]),
        activateTemplateID: template.id,
      },
    });

    setPicklists(getAllPicklistsForTemplate(template));
    const headingContent = normalizeSlateContent(
      template.content ?? convertFieldsToUnified(template.sections)
    );
    const templateToInsert = {
      ...template,
      content: headingContent,
    } as const;

    insertTemplate(templateToInsert);
    setHasReportContentChanged(false);
  }, [
    template,
    worklistItem.report?.macros,
    setPicklists,
    insertTemplate,
    setHasReportContentChanged,
  ]);

  const [placeholderFieldDialogState, setPlaceholderFieldDialogState] = useRecoilState(
    emptyPlaceholderDialogState
  );
  const [emptyRequiredFieldsDialogState, setEmptyRequiredFieldsDialogState] = useRecoilState(
    emptyRequiredFieldsDialogRecoilState
  );

  const handleCancelSubmit = useCallback(async () => {
    if (status === ReportStatus.ProvisionalSubmit) {
      const reportSmid = getReportSMID(worklistItem);

      cancelCountdown();
      setStatus(ReportStatus.Cancelling);
      await cancelReport({
        variables: { smid: reportSmid },
      });
      setStatus(ReportStatus.Idle);
      analytics.track(reporter.usr.submitReportCancel, {
        ...analyticsData,
        smid: reportSmid,
      });
    } else if (status === ReportStatus.ProvisionalAddendumSubmit) {
      cancelCountdown();
      if (addendumSMID == null) return;

      setStatus(ReportStatus.AddendumCancelling);
      analytics.track(reporter.usr.submitReportCancel, {
        ...analyticsData,
        smid: addendumSMID,
      });

      await cancelAddendum({
        variables: { smid: addendumSMID },
        update: (proxy, { data }) => {
          if (data == null) return;

          proxy.writeQuery({
            query: GET_WORKLIST_ITEM,
            data: {
              worklistItem: {
                ...worklistItem,
                report: {
                  ...worklistItem.report,
                  addendums: worklistItem?.report?.addendums?.map((addendum: any) => {
                    if (addendum.smid === data.cancelAddendum.smid) {
                      return { ...addendum, submittedAt: null };
                    }

                    return addendum;
                  }),
                },
              },
            },
          });
        },
      });
      setStatus(ReportStatus.AddendumAdd);
      return;
    }
  }, [
    status,
    worklistItem,
    cancelCountdown,
    setStatus,
    cancelReport,
    analyticsData,
    addendumSMID,
    cancelAddendum,
  ]);

  const handleSubmit: (options?: {
    force?: boolean;
    onSuccess?: () => void;
    onError?: () => void;
  }) => Promise<void> = useCallback(
    async (options) => {
      if (!isReporterSubmissionEnabled) {
        logger.warn(
          '[Fields.Context] Attempted to sign but the user does not have the feature enabled',
          {
            reportSmid: getReportSMID(worklistItem),
            status,
          }
        );
        return;
      }

      if (options?.force !== true && savingState === ReporterSaveState.Saving) {
        logger.warn('[Fields.Context] Attempted to sign report without saving latest changes', {
          reportSmid: getReportSMID(worklistItem),
        });
        enqueueToast('Please wait until your changes are saved before signing the report.', {
          icon: 'yellowWarning',
        });
        return;
      }

      if (
        options?.force !== true &&
        (placeholderFieldDialogState.open === true ||
          emptyRequiredFieldsDialogState.open === true ||
          (
            [
              ReportStatus.Submitting,
              ReportStatus.ProvisionalSubmit,
              ReportStatus.ProvisionalAddendumSubmit,
              ReportStatus.AddendumSubmitting,
            ] as unknown as ReportStatuses
          ).includes(status))
      ) {
        logger.warn('[Fields.Context] Attempted to sign report in an invalid state', {
          reportSmid: getReportSMID(worklistItem),
          status,
          placeholderFieldDialogOpen: placeholderFieldDialogState.open,
          emptyRequiredFieldsDialogStateOpen: emptyRequiredFieldsDialogState.open,
        });

        return;
      }

      logger.debug('[FieldsProvider] Initiating report submission process...');

      await fieldsUpdateDebounced.flush();
      await addendumUpdateDebounced.flush();

      const reportSmid = getReportSMID(worklistItem);

      const warnIfEmptyRequiredFields = (slateContent: SlateContent | null): boolean => {
        if (emptyRequiredFieldsDialogState.open === false && slateContent) {
          const emptyRequiredFields = getEmptyRequiredFields(slateContent);

          if (emptyRequiredFields.length > 0) {
            setEmptyRequiredFieldsDialogState({
              emptyRequiredFields,
              open: true,
            });

            setAllRequiredFieldsTouched();
            logger.info('[Fields.Context] Attempted to submit report with empty required fields', {
              count: emptyRequiredFields.length,
            });
            return true;
          }
        }
        return false;
      };

      switch (status) {
        // No-op, this is the default state before we've interpreted the countdown state
        case ReportStatus.Init:
          return;
        case ReportStatus.Idle:
          // When the Submit button is pressed, stop the dictation
          if (isRecording === true) {
            await stopRecording();
          }

          if (placeholderFieldDialogState.open === false) {
            const emptyPlaceholders = getEmptyPlaceholderFields(content);

            if (emptyPlaceholders.length > 0) {
              setPlaceholderFieldDialogState({
                emptyPlaceholders,
                open: true,
              });
              options?.onError && options?.onError();
              return;
            }
          }

          if (warnIfEmptyRequiredFields(content)) {
            options?.onError && options?.onError();
            return;
          }

          /* If there is no saved report and the editor content has not changed,
             the user is signing a report that is simply an unchanged template.
             In this case, we need to save the report, since no autosave will have yet happened. */
          const { created, sectionsLastUpdated } = worklistItem.report;
          const hasSavedReportContent =
            sectionsLastUpdated != null && sectionsLastUpdated !== created;
          if (!hasReportContentChanged && !hasSavedReportContent) {
            logger.info('[Fields.Context] Saving an unchanged report before signing ...', {
              reportSmid: getReportSMID(worklistItem),
            });
            setSavingState(ReporterSaveState.Saving);
            await fieldsUpdate({
              contentToSubmit: content,
              activateTemplateIDToSubmit: activateTemplateID,
            });
          }

          // Now, submit the report.
          setStatus(ReportStatus.Submitting);

          let data, errors;
          try {
            logger.info(
              `[Fields.Context] Attempting to submit report with isBypassAndSign ${isBypassAndSign === true ? 'is' : 'is not'} enabled`
            );
            ({ data, errors } = await submitReport({
              variables: {
                smid: reportSmid,
                immediate_submit: isBypassAndSign,
              },
            }));
          } catch (err: any) {
            errors = [err];
            logger.error(
              '[Fields.Context] Error occurred when submitting report',
              {
                smid: reportSmid,
              },
              err
            );
          }

          analytics.track(reporter.usr.submitReport, {
            ...analyticsData,
            smid: reportSmid,
          });

          if (!data || (errors && errors.length)) {
            setStatus(ReportStatus.Idle);
            const toastKey = enqueueOrUpdateToast(
              `Report ${reportSmid} was not signed, an error occurred.`,
              latestToastKey,
              defaultToastOptions
            );
            setLatestToastKey(toastKey);
            options?.onError && options?.onError();
            return;
          }

          if (data.submitReport.__typename === 'SubmitReportProblem') {
            const { invalidInputs, unknownProblem } = data.submitReport;

            if (invalidInputs.length) {
              invalidInputs.forEach((input) => {
                if (input != null) {
                  const toastKey = enqueueOrUpdateToast(
                    input.message,
                    latestToastKey,
                    defaultToastOptions
                  );
                  setLatestToastKey(toastKey);
                }
              });
            } else if (unknownProblem != null) {
              const toastKey = enqueueOrUpdateToast(
                unknownProblem,
                latestToastKey,
                defaultToastOptions
              );
              setLatestToastKey(toastKey);
            } else {
              const toastKey = enqueueOrUpdateToast(
                `Report ${reportSmid} was not signed, an error occurred.`,
                latestToastKey,
                defaultToastOptions
              );
              setLatestToastKey(toastKey);
            }
            setStatus(ReportStatus.Idle);
            return;
          }

          trackTypedCharacters();

          if (isBypassAndSign) {
            setStatus(ReportStatus.Submitted);
          } else {
            startCountdown();
          }

          let nextCaseSmid: string | null = null;

          if (isWorklistAutoLoadEnabled) {
            if (currentCaseId != null) {
              logger.info(
                `[Fields.Context] Attempting to autoLoad next case after submitting worklistItem ${currentCaseId}`
              );
            }
            let toastOptions = {
              yOffset: 45,
              icon: 'greenCheck',
              ...(isWorklistAutoLoadEnabled === true
                ? {
                    autoHideDuration: 8000,
                  }
                : {}),
            };

            const defaultToastMsg = `Report for ${getCaseInfo(worklistItem)} has been signed.`;
            let toastMsg = defaultToastMsg;
            try {
              nextCaseSmid = await readNextCase();

              if (nextCaseSmid == null) {
                toastMsg = `${defaultToastMsg} No more exams available to autoload.`;
              }
            } catch (error: any) {
              toastMsg = `${defaultToastMsg} ${error.message}`;
              toastOptions = {
                ...toastOptions,
                icon: 'redWarning',
              };
            }

            const toastKey = enqueueOrUpdateToast(
              toastMsg,
              latestToastKey,
              toastOptions as ToastProps
            );
            setLatestToastKey(toastKey);
          }

          managePatientJacket({ nextCaseSmid });

          // If the user was closing the window, we want to close it now that the report has been signed
          options?.onSuccess && options.onSuccess();
          return;
        // No-op, this is when the user is submitting a request to the server
        case ReportStatus.Submitting:
          return;
        case ReportStatus.ProvisionalSubmit:
          return;
        // No-op, state cannot be changed while 'cancelling'
        case ReportStatus.Cancelling:
          return;
        // No-op, button is disabled. Users add an addendum with a different button
        case ReportStatus.Submitted:
          return;
        case ReportStatus.AddendumAdd:
          if (isRecording === true) {
            await stopRecording();
          }

          await addendumUpdateDebounced.flush();

          if (warnIfEmptyRequiredFields(addendum?.content)) {
            options?.onError && options?.onError();
            return;
          }

          setStatus(ReportStatus.AddendumSubmitting);

          if (addendumSMID == null) {
            options?.onError && options?.onError();
            return;
          }

          analytics.track(reporter.usr.submitAddendum, {
            ...analyticsData,
            smid: addendumSMID,
          });
          trackTypedCharacters();

          const { data: submitAddendumData } = await submitAddendum({
            variables: { smid: addendumSMID, immediate_submit: isBypassAndSign },
            update: (proxy, { data }) => {
              if (data == null) return;
              const addendums = worklistItem?.report?.addendums || [];

              proxy.writeQuery({
                query: GET_WORKLIST_ITEM,
                data: {
                  worklistItem: {
                    ...worklistItem,
                    report: {
                      ...worklistItem.report,
                      addendums: addendums.map((a) => {
                        if (a.smid === data.submitAddendum.smid) return data.submitAddendum;

                        return a;
                      }),
                    },
                  },
                },
              });
            },
            onError: (e) => {
              enqueueToast(`Addendum ${addendumSMID} was not signed, an error occurred.`, {
                icon: 'redWarning',
              });
              logger.error('[Fields.Context] Error occurred when submitting addendum: ', e);
              options?.onError && options?.onError();
            },
          });

          if (isBypassAndSign) {
            setStatus(ReportStatus.Submitted);
            // immediately show timestamp and user
            dispatch({
              type: 'finalizeProvisionalAddendum',
              payload: {
                submittingUserLastName: me?.lastName,
                addendumSubmittedDate: submitAddendumData?.submitAddendum?.submittedAt,
              },
            });
          } else {
            startCountdown();
            setStatus(ReportStatus.ProvisionalAddendumSubmit);
          }

          dispatch({
            type: 'submitAddendum',
            payload: { addendumSubmittedDate: submitAddendumData?.submitAddendum?.submittedAt },
          });
          managePatientJacket();
          // If the user was closing the window, we want to close it now that the report has been signed
          options?.onSuccess && options.onSuccess();

          return;
        case ReportStatus.AddendumSubmitting:
          return;
        case ReportStatus.ProvisionalAddendumSubmit:
          return;
        case ReportStatus.AddendumCancelling:
          return;
        default:
          return unreachableCaseError(status);
      }
    },
    [
      isReporterSubmissionEnabled,
      placeholderFieldDialogState.open,
      emptyRequiredFieldsDialogState.open,
      status,
      fieldsUpdateDebounced,
      addendumUpdateDebounced,
      worklistItem,
      setEmptyRequiredFieldsDialogState,
      setAllRequiredFieldsTouched,
      isRecording,
      content,
      setStatus,
      analyticsData,
      trackTypedCharacters,
      isBypassAndSign,
      isWorklistAutoLoadEnabled,
      addendum?.content,
      addendumSMID,
      submitAddendum,
      stopRecording,
      setPlaceholderFieldDialogState,
      submitReport,
      enqueueOrUpdateToast,
      latestToastKey,
      startCountdown,
      currentCaseId,
      readNextCase,
      managePatientJacket,
      me?.lastName,
      enqueueToast,
      savingState,
      activateTemplateID,
      fieldsUpdate,
      hasReportContentChanged,
      setSavingState,
    ]
  );

  const handleAddendumAdd = useCallback(async () => {
    const { data } = await createAddendum({
      variables: {
        reportSmid: getReportSMID(worklistItem),
      },
      update: (proxy, { data }) => {
        if (data == null) return;

        proxy.writeQuery({
          query: GET_WORKLIST_ITEM,
          data: {
            worklistItem: {
              ...worklistItem,
              report: {
                ...worklistItem.report,
                addendums: [...(worklistItem?.report?.addendums ?? []), data.createAddendum],
              },
            },
          },
        });
      },
      onError: (e) => {
        logger.error('[Fields.Context] Error occurred when creating addendum: ', e);

        enqueueToast('An error occurred when creating an addendum.', {
          icon: 'redWarning',
        });

        return;
      },
    });

    if (data == null) return;

    dispatch({ type: 'addAddendum' });
    setStatus(ReportStatus.AddendumAdd);

    if (typeof data?.createAddendum?.smid === 'string') {
      dispatch({ type: 'addAddendumSMID', payload: { addendumSMID: data.createAddendum.smid } });
    }

    sendEvent(NAMESPACES.CROSS_WINDOW_DATA_REFETCH, {
      type: 'claimedItems',
    });

    refetchCurrentCase && refetchCurrentCase();
  }, [createAddendum, worklistItem, setStatus, refetchCurrentCase, enqueueToast]);

  const handleCancelAddAddendum = useCallback(
    async ({
      onSuccess,
      onError,
    }: {
      onSuccess?: () => void;
      onError?: () => void;
    } = {}) => {
      if (addendumSMID == null) return;

      addendumUpdateDebounced.cancel();

      dispatch({ type: 'clearAddAddendum' });
      setStatus(ReportStatus.Submitted);
      await deleteAddendum({
        variables: { smid: addendumSMID },
        update: (proxy, { data }) => {
          if (data == null || !data.deleteAddendum.confirmed) return;

          proxy.writeQuery({
            query: GET_WORKLIST_ITEM,
            data: {
              worklistItem: {
                ...worklistItem,
                report: {
                  ...worklistItem.report,
                  addendums:
                    worklistItem?.report?.addendums?.filter(({ smid }) => smid !== addendumSMID) ??
                    [],
                },
              },
            },
          });
        },
        onCompleted: () => {
          onSuccess && onSuccess();
        },
        onError: () => {
          onError && onError();
        },
      });
      managePatientJacket();
    },
    [
      addendumSMID,
      addendumUpdateDebounced,
      setStatus,
      deleteAddendum,
      managePatientJacket,
      worklistItem,
    ]
  );

  useEffect(() => {
    if (isDisabled && !aiMode) return;
    handleInsertTemplate();
    /**
     * RP-3188: Since we only want to call `handleInsertTemplate` when the template changes,
     * we are not including `handleInsertTemplate` in the dependency array.
     * `handleInsertTemplate` calls `insertTemplateCallback`, which has a dependency
     * on `currentWorklistItems`, which in turn is being recalculated due to the change
     * in the worklist item's template change.
     * We could include `handleInsertTemplate` here, but if we do, we would need to instead
     * change the dependency from `worklistItem` to `worklistItem.smid` inside the calculation
     * of `currentWorklistItems` in `useCurrentWorklistItems`, but doing this this could cause
     * a regression across various references of `currentWorklistItems` in use.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template, isDisabled, aiMode]);

  // This effect updates the client state to represent the `sent_at` status to mirth
  // (after 3 minutes, the report is locked in and cannot be edited further)
  useEffect(() => {
    if (countdownSeconds == null || countdownSeconds > 0) return;
    clearTimer();

    if ((PROVISIONAL_SUBMIT_STATUSES as unknown as ReportStatuses).includes(status)) {
      setStatus(ReportStatus.Submitted);
    }

    if (status === ReportStatus.ProvisionalAddendumSubmit) {
      dispatch({
        type: 'finalizeProvisionalAddendum',
        payload: { submittingUserLastName: me?.lastName },
      });
    }
  }, [countdownSeconds, status, setStatus, clearTimer, me, worklistItem]);

  useEffect(() => {
    if (isFocusModeOn && !isFocusModeAllowed) {
      setIsFocusModeOn(false);
    }
  }, [isDisabled, isFocusModeOn, isFocusModeAllowed, setIsFocusModeOn]);

  useEffect(() => {
    if (
      (isDisabled && !aiMode) ||
      status !== ReportStatus.Idle ||
      content === previousContent ||
      (updateType !== 'contentChange' && updateType !== 'templateApply')
    ) {
      return;
    }

    // saving state is set to ReporterSaveState.Saving outside of debounced callback, so that state is set as soon as the user makes a change to the fields
    setSavingState(ReporterSaveState.Saving);

    fieldsUpdateDebounced({
      contentToSubmit: content,
      activateTemplateIDToSubmit: activateTemplateID,
    });

    if (updateType === 'templateApply') {
      fieldsUpdateDebounced.flush();
    }
  }, [
    fieldsUpdateDebounced,
    content,
    previousContent,
    activateTemplateID,
    status,
    isDisabled,
    updateType,
    setSavingState,
    setHasReportContentChanged,
    aiMode,
  ]);

  useEffect(() => {
    if (status === ReportStatus.Submitted && countdownSeconds === null) {
      worklistUpdateBc.postMessage(worklistItem.smid);
    }
  }, [status, worklistItem.smid, countdownSeconds]);

  useEffect(() => {
    if (
      status !== ReportStatus.AddendumAdd ||
      addendum?.content === previousAddendum?.content ||
      addendumSMID == null
    ) {
      return;
    }

    // saving state is set to ReporterSaveState.Saving outside of debounced callback, so that state is set as soon as the user makes a change to the addendum
    setSavingState(ReporterSaveState.Saving);

    addendumUpdateDebounced({ contentToSubmit: addendum?.content, addendumSMID });
  }, [addendumUpdateDebounced, addendum, previousAddendum, addendumSMID, status, setSavingState]);

  // The user can submit the report with a voice command, keyboard shortcut, or gamepad button
  // voice command:
  useEventsListener(NAMESPACES.RECORDER_VOICE_COMMAND, ({ source, payload }) => {
    if (payload.substitution.action === 'SubmitReport') {
      if (unsignedReportDialogOptions.open) {
        handleSubmit({
          onSuccess: onDialogActionSuccess,
          onError: onDialogActionError,
        });
      } else {
        handleSubmit();
      }
    }

    if (payload.substitution.action === 'DraftReport') {
      if (unsignedReportDialogOptions.open) {
        handleSaveDraft({
          onSuccess: onDialogActionSuccess,
          onError: onDialogActionError,
        });
      } else {
        handleSaveDraft();
      }
    }

    if (payload.substitution.action === 'DiscardReport') {
      if (status === ReportStatus.AddendumAdd) {
        handleCancelAddAddendum();
      } else if (discardReportOptions.open === true || unsignedReportDialogOptions.open === true) {
        handleDiscardReport({
          onSuccess: onDialogActionSuccess,
          onError: onDialogActionError,
        });
      } else {
        handleOpenDiscardReportModal();
      }
    }
  });

  // keyboard shortcut:
  useEventsListener(NAMESPACES.REPORTER_SHORTCUT, ({ source, payload }) => {
    if (payload.action === 'submitReport') {
      if (unsignedReportDialogOptions.open) {
        handleSubmit({
          onSuccess: onDialogActionSuccess,
          onError: onDialogActionError,
        });
      } else {
        handleSubmit();
      }
    }

    if (payload.action === 'draftReport') {
      if (unsignedReportDialogOptions.open) {
        handleSaveDraft({
          onSuccess: onDialogActionSuccess,
          onError: onDialogActionError,
        });
      } else {
        handleSaveDraft();
      }
    }

    if (payload.action === 'discardReport') {
      if (status === ReportStatus.AddendumAdd) {
        handleCancelAddAddendum();
      } else if (discardReportOptions.open === true || unsignedReportDialogOptions.open === true) {
        handleDiscardReport({
          onSuccess: onDialogActionSuccess,
          onError: onDialogActionError,
        });
      } else {
        handleOpenDiscardReportModal();
      }
    }
  });

  // custom event:
  useEffect(() => {
    const handler = ({ detail }) => {
      if (
        detail === 'reporter-submit-report' &&
        status !== ReportStatus.ProvisionalSubmit &&
        status !== ReportStatus.ProvisionalAddendumSubmit
      ) {
        handleSubmit();
      }

      if (detail === 'reporter-discard-report') {
        if (status === ReportStatus.AddendumAdd) {
          handleCancelAddAddendum();
        } else if (
          discardReportOptions.open === true ||
          unsignedReportDialogOptions.open === true
        ) {
          handleDiscardReport({
            onSuccess: onDialogActionSuccess,
            onError: onDialogActionError,
          });
        } else {
          handleOpenDiscardReportModal();
        }
      }
    };

    addExtensionListener(
      EXTENSION_RESPONSE_EVENTS.SHORTCUT_TRIGGERED,
      handler as unknown as EventListener
    );
    return () =>
      removeExtensionListener(
        EXTENSION_RESPONSE_EVENTS.SHORTCUT_TRIGGERED,
        handler as unknown as EventListener
      );
  }, [
    discardReportOptions.open,
    handleCancelAddAddendum,
    handleDiscardReport,
    handleOpenDiscardReportModal,
    handleSubmit,
    onDialogActionError,
    onDialogActionSuccess,
    status,
    unsignedReportDialogOptions.open,
  ]);

  // gamepad button:
  const gamepadCallback = useCallback(
    (action: GamepadActionId, pressed: boolean) => {
      if (
        action === GamepadActionId.SubmitReport &&
        pressed &&
        status !== ReportStatus.ProvisionalSubmit &&
        status !== ReportStatus.ProvisionalAddendumSubmit
      ) {
        handleSubmit();
        return;
      }

      if (action === GamepadActionId.ToggleFocusMode && pressed && isFocusModeAllowed) {
        toggleFocusMode();
        return;
      }
    },
    [status, handleSubmit, toggleFocusMode, isFocusModeAllowed]
  );

  useGamepadBindings({ callback: gamepadCallback });
  useWebHIDBindings({ callback: gamepadCallback });

  const dispatchBag = useMemo(
    () => ({
      dispatch,
      handleInsertTemplate,
      onReportChange,
      handleSubmit,
      handleAddendumAdd,
      handleCancelSubmit,
      handleCancelAddAddendum,
      onAddendumChange,
      handleSaveDraft,
    }),
    [
      dispatch,
      handleInsertTemplate,
      onReportChange,
      handleSubmit,
      handleCancelSubmit,
      handleAddendumAdd,
      handleCancelAddAddendum,
      onAddendumChange,
      handleSaveDraft,
    ]
  );

  const countdownBag = useMemo(() => ({ countdownSeconds }), [countdownSeconds]);
  return (
    <FieldsStateContext.Provider value={state}>
      <FieldsDispatchContext.Provider value={dispatchBag}>
        <FieldsCountdownContext.Provider value={countdownBag}>
          {children}
        </FieldsCountdownContext.Provider>
      </FieldsDispatchContext.Provider>
    </FieldsStateContext.Provider>
  );
};

export const useFieldsState = (): FieldsState => {
  const fieldsContext = useContext(FieldsStateContext);

  if (!fieldsContext) {
    throw new Error('useFieldsState must be used within a FieldsProvider.');
  }

  return fieldsContext;
};

export const useFieldsCountdown = (): FieldsCountdownContextProps => {
  const fieldsContext = useContext(FieldsCountdownContext);

  if (!fieldsContext) {
    throw new Error('useFieldsCountdown must be used within a FieldsProvider.');
  }

  return fieldsContext;
};

export const useFieldsDispatch = (): FieldsDispatch => {
  const fieldsDispatch = useContext(FieldsDispatchContext);

  if (!fieldsDispatch) {
    throw new Error('useFieldsDispatch must be used within a FieldsProvider.');
  }

  return fieldsDispatch;
};

export const useFields = (): [FieldsState, FieldsDispatch] => {
  return [useFieldsState(), useFieldsDispatch()];
};

export type ThrottledCallback<A> = {
  cancel(): void;
  (arg1: A): void;
};
export const rafThrottle = <A>(callback: (arg1: A) => unknown): ThrottledCallback<A> => {
  let requestId = null;

  let lastArgs;

  const later = (context: Record<any, any>) => () => {
    requestId = null;
    callback.apply(context, lastArgs);
  };

  const throttled = function (this: Record<any, any>, ...args: [A]) {
    lastArgs = args;
    if (requestId === null) {
      requestId = requestAnimationFrame(later(this));
    }
  };

  throttled.cancel = () => {
    if (requestId == null) return;
    cancelAnimationFrame(requestId);
    requestId = null;
  };

  return throttled;
};

import { useRef } from 'react';
import { equals } from 'ramda';

export const useEqualValue = <T, S>(
  value: Array<T>,
  // $FlowFixMe[incompatible-type] don't know how to tell Flow S can be T
  // @ts-expect-error [EN-7967] - TS2322 - Type '(value: T) => T' is not assignable to type '(arg1: T) => S'.
  map: (arg1: T) => S = (value: T) => value
): Array<S> => {
  const prevValue = useRef<Array<S>>([]);
  const newValue = value.map(map);

  if (!equals(prevValue.current, newValue)) {
    prevValue.current = newValue;
  }

  return prevValue.current;
};

export const useStableValue = <T>(value: T, comparator: (arg1: T, arg2: T) => boolean): T => {
  const prevValue = useRef<T>(value);

  if (!comparator(prevValue.current, value)) {
    prevValue.current = value;
  }

  return prevValue.current;
};

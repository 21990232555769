import type { DocumentNode, OperationVariables } from '@apollo/client';
import { client } from './client';
import { Cache, ResponseWithContentLength } from './precacheLink';

export function getPrecacheOperationKey(
  query: DocumentNode,
  variables?: OperationVariables
): string {
  // @ts-expect-error [EN-7967] - TS2339 - Property 'name' does not exist on type 'DefinitionNode'.
  return query.definitions[0].name.value + JSON.stringify(variables);
}

export async function precacheOperation<
  Q extends DocumentNode,
  V extends OperationVariables | undefined,
>(
  query: Q,
  variables: V,
  options?: {
    refetch?: boolean;
  }
): Promise<Q | null | undefined> {
  const { data } = await client.query<Q, V>({
    query,
    variables,
    fetchPolicy: 'no-cache',
    context: {
      precachePolicy: options?.refetch === true ? 'cache-and-network' : 'cache-first',
    },
  });
  return data;
}

export async function writePrecacheQuery<
  Q extends DocumentNode,
  V extends OperationVariables | undefined,
>({ query, variables, data }: { query: Q; variables: V; data: Q }): Promise<void> {
  const cache = await Cache.open();

  // We use the operation name and variables as the cache key
  const operationKey = getPrecacheOperationKey(query, variables);
  const operationUrl = new URL(operationKey, window.location.origin);

  const response = new ResponseWithContentLength(JSON.stringify(data));

  // Write the response to the cache
  // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'URL' is not assignable to parameter of type 'RequestInfo'.
  await cache.put(operationUrl, response);
}

export async function deletePrecacheQuery<
  Q extends DocumentNode,
  V extends OperationVariables | undefined,
>({ query, variables }: { query: Q; variables: V }): Promise<void> {
  const cache = await Cache.open();

  // We use the operation name and variables as the cache key
  const operationKey = getPrecacheOperationKey(query, variables);
  const operationUrl = new URL(operationKey, window.location.origin);

  // Delete the response from the cache
  // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'URL' is not assignable to parameter of type 'RequestInfo'.
  await cache.delete(operationUrl);
}

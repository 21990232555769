import { css } from 'styled-components';
import WarningIcon from '@material-ui/icons/Warning';
import { Colors } from 'styles';
import type { ReporterSaveStateType } from 'config/recoilState';
import { ReporterSaveState } from 'config/recoilState';
import { Stack } from 'common/ui/Layout';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Text from 'common/ui/Text';
import CircularProgress from '@material-ui/core/CircularProgress';

const ICON_SIZE = 16;

type StatusIndicatorProps = {
  status: ReporterSaveStateType;
  lastUpdated?: Date | null | undefined;
};

export const StatusIndicator: React.ComponentType<StatusIndicatorProps> = ({
  status,
  lastUpdated,
}) => {
  const getStatusIcon = (status: ReporterSaveStateType) => {
    const style = `
    height: ${ICON_SIZE}px;
    width: ${ICON_SIZE}px;
  `;

    switch (status) {
      case ReporterSaveState.Saved:
        return (
          <CheckCircle
            data-testid="report-status-saved-icon"
            css={css`
              ${style}
              color: ${Colors.green5};
            `}
          />
        );
      case ReporterSaveState.Saving:
        return (
          <CircularProgress
            data-testid="report-status-saving-icon"
            size={ICON_SIZE}
            css={css`
              ${style};
              color: ${Colors.blue4};
            `}
          />
        );
      case ReporterSaveState.Error:
        return (
          <WarningIcon
            css={css`
              ${style}
              color: ${Colors.red5};
            `}
          />
        );
      default:
        return null;
    }
  };

  function getTimeDifference(startTime: Date): string {
    const now = new Date();

    const nowTime = now.getTime();
    const startTimeMs = startTime.getTime();

    const diffMilliseconds = nowTime - startTimeMs;

    const diffMinutes = Math.floor(diffMilliseconds / 60000);

    if (diffMinutes >= 60) {
      return 'over an hour ago';
    } else if (diffMinutes > 0) {
      return `${diffMinutes} minute${diffMinutes !== 1 ? 's' : ''} ago`;
    } else {
      return `less than a minute ago`;
    }
  }

  const getStatusCaption = (status: ReporterSaveStateType) => {
    switch (status) {
      case ReporterSaveState.Saved:
        return lastUpdated != null
          ? `Saved ${getTimeDifference(new Date(lastUpdated))}`
          : 'Changes saved';
      case ReporterSaveState.Saving:
        return 'Saving...';
      case ReporterSaveState.Error:
        return 'Autosave failed';
      default:
        return '';
    }
  };

  const icon = getStatusIcon(status);
  const caption = getStatusCaption(status);

  return (
    <Stack alignY="center" data-testid="reporter-status-indicator">
      {icon}
      <Text
        variant="caption"
        css={css`
          color: ${Colors.gray8};
          margin-left: 4px;
        `}
      >
        {caption}
      </Text>
    </Stack>
  );
};

import type { EditorPlugin } from '../../types';
import { getRenderElement } from '../../utils/getRenderElement';
import { LineBreakEditable } from './components';
import { LINE_BREAK_PLUGIN_ID } from './types';
import type { LineBreakPluginOptions, LineBreakPluginPropertyOptions } from './types';
import { enhanceEditorStateLineBreak } from './enhanceEditorStateLineBreak';
import { onKeyDownLineBreak } from './onKeyDownLineBreak';

export const LineBreakPlugin = ({
  pluginName = 'lineBreak',
  component = LineBreakEditable,
  ...rest
}: LineBreakPluginOptions = {}): EditorPlugin => {
  const options: LineBreakPluginPropertyOptions = {
    pluginID: LINE_BREAK_PLUGIN_ID,
    pluginName,
    component,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    renderElement: getRenderElement(options, { spreadAttributes: false }),
    enhanceEditorState: enhanceEditorStateLineBreak(options),
    onKeyDown: onKeyDownLineBreak(options),
  };
};

import type {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
} from '@apollo/client';
import { logger } from '../logger';
import { precacheOperation, getPrecacheOperationKey } from './precacheOperation';
import { useQuery } from '@apollo/client';

const DEFAULT_REFETCH_DELAY = 10000;

const refetchedOperations = new Set();

export const usePrecacheQuery = <TData extends DocumentNode, TVariables extends OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
  precacheOptions?: {
    shouldRefetch?: boolean;
    refetchDelay?: number;
    precacheEnabled?: boolean;
  }
): QueryResult<TData, TVariables> => {
  const result = useQuery<TData, TVariables>(query, {
    ...options,
    context: {
      // if there aren't any enabled precache flags, we can consider
      // the precache to be 'off', and not store anything there even
      // from "normal" query calls. This helps be more "zero-footprint",
      // but also our metrics check the cache for the data to mark
      // whether it was precached or not, so we don't want to add stuff
      // that could get picked up on a page refresh
      precachePolicy: precacheOptions?.precacheEnabled === true ? 'cache-first' : 'network-only',
    },
    onCompleted: (...args) => {
      options?.onCompleted?.(...args);

      if (precacheOptions?.shouldRefetch === false) {
        logger.debug('[usePrecacheQuery] shouldRefetch is false, skipping refetch');
        return;
      }

      // Give 10 seconds to the Viewer to load, after that we can attempt to
      // fetch fresh data from the server and in case refresh the Viewer to
      // reflect any changes.
      // We want to delay this operation because the fetch operation can affect
      // the performance of the Viewer.
      // We can consider this a good trade-off to ensure users don't see stale
      // data for too long.
      const operationKey = getPrecacheOperationKey(query, options?.variables);

      // If we already refetched this operation, we don't need to do it again
      if (refetchedOperations.has(operationKey)) {
        logger.debug('[usePrecacheQuery] operation already refetched, skipping refetch');
        return;
      }

      // Mark this operation as refetched so we don't do it again in the future
      refetchedOperations.add(operationKey);

      logger.debug('[usePrecacheQuery] refetching operation scheduled');
      setTimeout(async () => {
        logger.debug('[usePrecacheQuery] refetching operation (precache)');
        await precacheOperation(query, options?.variables, { refetch: true });

        logger.debug('[usePrecacheQuery] refetching operation (Apollo refetch)');
        await result.refetch();
      }, precacheOptions?.refetchDelay ?? DEFAULT_REFETCH_DELAY);
    },
  });
  return result;
};

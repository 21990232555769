import type { InlineBookmarkPluginElement } from '../types';
import { InlineTemplateElement } from '../../common/inlineTemplate';
import { forwardRef } from 'react';

type InlineBookmarkElementProps = {
  selected?: boolean;
  element: InlineBookmarkPluginElement;
  children: React.ReactNode;
  handleClick?: () => void;
  ['data-editor-element']?: string;
  ['data-slate-node']?: 'element';
  ['data-slate-inline']?: true;
  ['data-slate-void']?: true;
  dir?: 'rtl';
  variant?: string;
};

export const InlineBookmarkElement = forwardRef<HTMLSpanElement, InlineBookmarkElementProps>(
  ({ selected, element, handleClick, ...rest }, ref) => {
    return (
      <InlineTemplateElement
        ref={ref}
        {...rest}
        selected={selected}
        testID="inlineBookmark"
        data-selected={selected}
        element={element}
        handleClick={handleClick}
      />
    );
  }
);
InlineBookmarkElement.displayName = 'InlineBookmarkElement';

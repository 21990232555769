import { useMemo, createContext, useContext, useEffect, useState } from 'react';
import type {
  GetGroupedWorklistItemsQuery,
  GetGroupedWorklistItemsQueryVariables,
} from 'generated/graphql';
import { useCurrentCase } from 'hooks/useCurrentCase';
import { useQuery } from '@apollo/client';
import { GET_GROUPED_WORKLIST_ITEMS } from 'modules/Apollo/queries';
import { useSlateSingletonContext } from 'domains/reporter/Reporter/SlateSingletonContext';
import { Editor, Transforms, Element } from 'slate';
import type { Text } from 'slate';
import { PLACEHOLDER_PLUGIN_ID } from '../domains/reporter/RichTextEditor/plugins/placeholder/types';
import { PLACEHOLDER_FIELDS_TO_APPLY_TO_LINK_CASES } from 'domains/reporter/RichTextEditor/plugins/placeholder/constants';
import type {
  PlaceholderIDToApplyToLinkCases,
  PlaceholderFieldToApplyToLinkCases,
} from 'domains/reporter/RichTextEditor/plugins/placeholder/constants';
import {
  resolvePlaceholderID,
  getUniqueWorklistItems,
  getDuplicateWorklistItem,
  handleInsertPlaceholders,
} from '../domains/reporter/RichTextEditor/plugins/placeholder/utils';
import { NAMESPACES, useEventsListener } from '../modules/EventsManager/eventsManager';

export const sortByStudyDescAndAccession = (
  a: GroupedWorklistItem | PartialWorklistItem,
  b: GroupedWorklistItem | PartialWorklistItem
): number => {
  const aStudyDesc = a.studyDescription ?? '';
  const bStudyDesc = b.studyDescription ?? '';
  const aAcc = a.accessionNumber ?? '';
  const bAcc = b.accessionNumber ?? '';
  return aStudyDesc.localeCompare(bStudyDesc) || aAcc.localeCompare(bAcc);
};

export type PartialWorklistItem = {
  accessionNumber: string | null | undefined;
  claimedBy: string | null | undefined;
  smid: string;
  studyDate: Date | null | undefined;
  studyDescription: string | null | undefined;
  groupId: string | null | undefined;
};
export type GroupedWorklistItem = GetGroupedWorklistItemsQuery['groupedWorklistItems'][0];
export type GroupedWorklistItems = ReadonlyArray<GroupedWorklistItem>;

export const handleAddWorklistItem = (
  addedWorklistItem: GroupedWorklistItem,
  currentWorklistItems: GroupedWorklistItems,
  editor: Editor
) => {
  Editor.withoutNormalizing(editor, () => {
    const placeholders = Object.entries(PLACEHOLDER_FIELDS_TO_APPLY_TO_LINK_CASES) as [
      PlaceholderIDToApplyToLinkCases,
      PlaceholderFieldToApplyToLinkCases,
    ][];

    // For each placeholder, find the unique worklist items for that placeholder field.
    placeholders.forEach(([placeholderID, placeholderField]: [any, any]) => {
      const uniqueWorklistItems = getUniqueWorklistItems(currentWorklistItems, placeholderField);

      // If the added item is one of the unique items for this placeholder, then we need to insert a new placeholder node. This node should be inserted after the placeholder node for the unique item that comes before the added item.
      if (uniqueWorklistItems.some((item) => item.smid === addedWorklistItem.smid)) {
        const addedWorklistIdx = uniqueWorklistItems.findIndex(
          (item) => item.smid === addedWorklistItem.smid
        );

        // We want to insert the placeholder after the placeholder for the unique item that comes before the added item.
        // But the user may have deleted that placeholder previously. So we look backwards iteratively until we find a placeholder that exists.
        let uniqueInFrontOfMeIdx = addedWorklistIdx - 1;
        let placeholdersForUniqueInFrontOfMe = null;
        while (uniqueInFrontOfMeIdx >= 0) {
          const uniqueInFrontOfMe = uniqueWorklistItems[uniqueInFrontOfMeIdx];
          placeholdersForUniqueInFrontOfMe = Editor.nodes(editor, {
            at: [],
            match: (n) =>
              Element.isElement(n) &&
              n.type === PLACEHOLDER_PLUGIN_ID &&
              n.placeholderID === placeholderID &&
              n.workListItemSmid === uniqueInFrontOfMe.smid,
          });

          if (placeholdersForUniqueInFrontOfMe != null) {
            for (const [node, path] of placeholdersForUniqueInFrontOfMe) {
              // handleInsertPlaceholders will removeNodes at the provided path, so we include the node for the uniqueInFrontOfMe so that it will be re-inserted.
              const nodesToInsert = [
                node,
                { text: ', ' },
                {
                  type: PLACEHOLDER_PLUGIN_ID,
                  placeholderID,
                  workListItemSmid: addedWorklistItem.smid,
                  children: [{ text: resolvePlaceholderID(placeholderID, addedWorklistItem) }],
                },
              ];

              // handleInsertPlaceholders will move the selection. We want to preserve the selection, so we store it before and restore it after.
              const selectionBeforeInsert = editor.selection;
              handleInsertPlaceholders(editor, path, nodesToInsert);
              if (selectionBeforeInsert != null) {
                Transforms.select(editor, selectionBeforeInsert);
              }
              return;
            }
          }

          uniqueInFrontOfMeIdx--;
        }
      }
    });
  });
};

// Important to note that, at this point, currentWorklistItems *does not include* the removedWorklistItem.
export const handleRemoveWorklistItem = (
  removedWorklistItem: GroupedWorklistItem,
  currentWorklistItems: GroupedWorklistItems,
  editor: Editor
) => {
  Editor.withoutNormalizing(editor, () => {
    const placeholders = Object.entries(PLACEHOLDER_FIELDS_TO_APPLY_TO_LINK_CASES) as [
      PlaceholderIDToApplyToLinkCases,
      PlaceholderFieldToApplyToLinkCases,
    ][];

    // For each placeholder, find the worklist items which have a duplicate value to the removed worklist item for that placeholder field.
    placeholders.forEach(([placeholderID, placeholderField]: [any, any]) => {
      const duplicateWorklistItem = getDuplicateWorklistItem(
        removedWorklistItem,
        currentWorklistItems,
        placeholderField
      );

      // For each placeholder associated with the removed worklist item, we need to either:
      // 1: Replace it's workListItemSmid, if there is a duplicate value among the remaining worklist items.
      // 2: Remove it, if there is no duplicate value among the remaining worklist items. Also, remove the preceding ", " if there is one.
      const placeholdersOfRemoved = Editor.nodes(editor, {
        at: [],
        match: (n) =>
          Element.isElement(n) &&
          n.type === PLACEHOLDER_PLUGIN_ID &&
          n.placeholderID === placeholderID &&
          n.workListItemSmid === removedWorklistItem.smid,
      });
      for (const [, path] of Array.from(placeholdersOfRemoved)) {
        if (duplicateWorklistItem != null) {
          Transforms.setNodes(
            editor,
            { workListItemSmid: duplicateWorklistItem.smid },
            { at: path }
          );
        } else {
          Transforms.removeNodes(editor, { at: path });
          Transforms.insertNodes(editor, { children: [{ text: '' }] }, { at: path });

          const precedingNode = Editor.previous(editor, { at: path });
          if (precedingNode != null && (precedingNode[0] as Text).text === ', ') {
            Transforms.removeNodes(editor, { at: precedingNode[1] });
            Transforms.insertNodes(editor, { text: '' }, { at: precedingNode[1] });
          }
        }
      }
    });
  });
};

const CurrentWorklistItems = createContext({
  loading: true,
  currentWorklistItems: [],
});

export const CurrentWorklistItemsProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const [previousWorklistItems, setPreviousWorklistItems] = useState<GroupedWorklistItems>([]);
  const [{ editor }] = useSlateSingletonContext();
  const { currentCase: worklistItem, refreshCase, loadingCase } = useCurrentCase();

  const {
    refetch,
    loading: loadingGroup,
    data: { groupedWorklistItems = [] } = {},
  } = useQuery<GetGroupedWorklistItemsQuery, GetGroupedWorklistItemsQueryVariables>(
    GET_GROUPED_WORKLIST_ITEMS,
    {
      fetchPolicy: 'cache-and-network',
      skip: worklistItem == null,
      variables: {
        groupId: worklistItem?.groupId,
      },
    }
  );

  const loading = loadingCase || loadingGroup;

  useEventsListener(NAMESPACES.CROSS_WINDOW_DATA_REFETCH, async ({ source, payload }) => {
    if (source === 'remote' && payload.type === 'worklistItemGroup') {
      await refreshCase();
      await refetch();
    }
  });

  const currentWorklistItems = useMemo(() => {
    if (worklistItem == null || loading) return [];

    const currentCaseAsGroupedWorklistItem = {
      smid: worklistItem.smid,
      accessionNumber: worklistItem.accessionNumber,
      patientAge: worklistItem.patientAge,
      patientSex: worklistItem.patientSex,
      dosage: worklistItem.dosage,
      studyDescription: worklistItem.studyDescription,
      studyReason: worklistItem.studyReason,
      studyDate: worklistItem.studyDate,
      referringPhysician: worklistItem.referringPhysician,
      techNotes: worklistItem.techNotes,
      studies: worklistItem.studies.map((study) => ({
        laterality: study.laterality,
        studyDate: study.studyDate,
      })),
      customMergeFields: worklistItem.customMergeFields,
    } as const;

    // We return the current worklist items such that the first element is the current case,
    // and the rest are sorted by study description. This is consistent with the LinkCases UI.
    return groupedWorklistItems.length > 0
      ? [
          currentCaseAsGroupedWorklistItem,
          ...groupedWorklistItems
            .filter((item) => item.smid !== worklistItem.smid)
            .sort(sortByStudyDescAndAccession),
        ]
      : [currentCaseAsGroupedWorklistItem];
  }, [groupedWorklistItems, loading, worklistItem]);

  // This effect is responsible for invoking handleAdd/handleRemoveWorklistItem
  // as worklistItems are added/removed from groupedWorklistItems.
  useEffect(() => {
    if (loading || editor == null) return;
    if (currentWorklistItems.length === previousWorklistItems.length) return;

    if (previousWorklistItems.length === 0) {
      setPreviousWorklistItems(currentWorklistItems);
      return;
    }

    const previousWorklistSmids = previousWorklistItems.map((item) => item.smid);
    const addedWorklistItems = currentWorklistItems.filter(
      (item) => !previousWorklistSmids.includes(item.smid) && item.smid !== worklistItem?.smid
    );
    if (addedWorklistItems.length > 0) {
      addedWorklistItems.forEach((item) =>
        handleAddWorklistItem(item, currentWorklistItems, editor)
      );
      setPreviousWorklistItems(currentWorklistItems);
      return;
    }

    const currentWorklistSmids = currentWorklistItems.map((item) => item.smid);
    const removedWorklistItems = previousWorklistItems.filter(
      (item) => !currentWorklistSmids.includes(item.smid) && item.smid !== worklistItem?.smid
    );
    if (removedWorklistItems.length > 0) {
      removedWorklistItems.forEach((item) =>
        handleRemoveWorklistItem(item, currentWorklistItems, editor)
      );
      setPreviousWorklistItems(currentWorklistItems);
      return;
    }
  }, [currentWorklistItems, editor, loading, previousWorklistItems, worklistItem?.smid]);

  const contextValue = useMemo(
    () => ({ loading, currentWorklistItems }),
    [loading, currentWorklistItems]
  );

  return (
    <CurrentWorklistItems.Provider value={contextValue}>{children}</CurrentWorklistItems.Provider>
  );
};

/**
 * Note that this hook returns a slightly different result than the query of a similar name.
 * This hook returns an array of all of the worklist items that the user is reporting on currently,
 * including the current case. Whereas the query will return an empty array, if the current case
 * is not grouped with any other worklist items.
 */
export const useCurrentWorklistItems = (): {
  loading: boolean;
  currentWorklistItems: GroupedWorklistItems;
} => {
  const currentWorklistItems = useContext(CurrentWorklistItems);

  if (!currentWorklistItems) {
    throw new Error('useCurrentWorklistItems must be used within a CurrentWorklistItemsProvider.');
  }

  return currentWorklistItems;
};

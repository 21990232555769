import { useGetUserHasPermissions } from '@sironamedical/common/hooks/useGetUserHasPermissions';
import { useGetUserRoles } from './useGetUserRoles';

/** Wraps around useGetUserHasPermissions and provides roles to avoid boilerplate  */
export const useUserHasPermissions = ({
  permissionNames,
  component,
  scopeId,
}: Omit<Parameters<typeof useGetUserHasPermissions>[0], 'roles'>): boolean => {
  const roles = useGetUserRoles();

  return useGetUserHasPermissions({
    roles,
    permissionNames,
    component,
    scopeId,
  });
};

// @flow
import { useEffect, useState } from 'react';
import { atom, useRecoilState } from 'recoil';
import type { RecoilState } from 'recoil';

import DialogConfirm from 'common/ui/DialogConfirm';
import Text from 'common/ui/Text';
import { Stack } from 'common/ui/Layout';
import { useWebHID } from 'modules/WebHID/useWebHID';
import { logger } from 'modules/logger';

export const webHIDConnectModalOpenState: RecoilState<string> = atom({
  key: 'webHIDConnectModalOpenState',
  default: '',
});

export const WebHIDConnectModal = (): React.ReactElement => {
  const { isWebHIDEnabled, pairDevice, onPairingCancel } = useWebHID();
  const [deviceToOpen, setDeviceToOpen] = useRecoilState(webHIDConnectModalOpenState);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(deviceToOpen !== '');
  }, [deviceToOpen]);

  const handleClose = (): void => {
    setDeviceToOpen('');
  };

  const handleConfirm = async (): Promise<undefined> => {
    logger.info('[WebHIDConnectModal] Confirming request to pair with device:', deviceToOpen);
    await pairDevice(deviceToOpen, isWebHIDEnabled);
    handleClose();
  };

  const handleCancel = (): void => {
    onPairingCancel();
    handleClose();
  };

  if (!isWebHIDEnabled) {
    return null;
  }

  return (
    <DialogConfirm
      open={isOpen}
      onClose={handleClose}
      slug="webhid-connect"
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      confirmText="Connect device"
      cancelText="Cancel"
      confirmButtonVariant="primary"
      data-testid="webhid-connect-modal"
    >
      <Stack vertical css="margin-top: 12px">
        <Text color="black">{`The device "${deviceToOpen}" was detected. Do you want to connect and use this device?`}</Text>
      </Stack>
    </DialogConfirm>
  );
};

import mitt from 'mitt';
import type { Emitter } from 'mitt';
import type { PickerMode } from './usePicker';

const emitters: {
  // @ts-expect-error [EN-7967] - TS2315 - Type 'Emitter' is not generic.
  [key: string]: Emitter<PickerMode>;
} = {};
const getEmitter = (viewportId: string) => {
  if (emitters[viewportId] != null) {
    return emitters[viewportId];
  } else {
    const emitter = mitt();
    emitters[viewportId] = emitter;
    return emitter;
  }
};

// @ts-expect-error [EN-7967] - TS2315 - Type 'Emitter' is not generic.
export const emitter: Emitter<PickerMode> = null;

/**
 * Used to trigger events in unit tests, the real implementation is defined
 * in `__mocks__/useEmitter.js` and becomes available only after the module
 * is mocked by calling `jest.mock('./useEmitter')`.
 * Note that the mock doesn't support multiple emitter instances.
 */
export const sendTestEvent = (type: PickerMode, event: unknown): void =>
  console.error(
    '`sendTestEvent` is meant to be used only in unit tests, did you forget to mock `useEmitter`?'
  );

/**
 * On DRE we use one emitter for each viewport rather than one emitter for all
 * like it happens on the EventsManager module, this is because we want the
 * events to be scoped by viewport, and we don't care about the cross-window
 * functionalities provided by EventsManager.
 * This approach helps with the overall performance since each viewport has
 * to handle only the events that interest it.
 */
// @ts-expect-error [EN-7967] - TS2315 - Type 'Emitter' is not generic.
export const useEmitter = (viewportId: string): Emitter<PickerMode> => {
  const emitter = getEmitter(viewportId);
  return emitter;
};

import { atom } from 'recoil';
import type { RecoilState } from 'recoil';

/** State for a desynchronized window; this drives if we should lock out the window for the user */
export const desyncDetectedState: RecoilState<boolean> = atom<boolean>({
  key: 'app.window.desyncDetected',
  default: false,
});

/** State for whether we should suppress the desync detection. This trumps the value in
 * `desyncDetected` when the user clicks on "Dismiss for N seconds" */
export const suppressDesyncDetectionState: RecoilState<boolean> = atom<boolean>({
  key: 'app.window.suppressDesyncDetection',
  default: false,
});

/** Local storage key for window synchronization validation */
export const WINDOW_SYNC_LS_KEY = 'window-sync';

/** Shape of the local storage entry for the origin of case sync request */
export type WindowSyncEntry = {
  screenName: string | null;
  caseId: string | null;
  timestamp: number | null;
};

/** Reads the latest case ID from local storage, if it exists. Returns null otherwise. */
export function getWindowSyncCase(): string | null | undefined {
  const storageValue = localStorage.getItem(WINDOW_SYNC_LS_KEY);
  const result = parseSyncEntry(storageValue)?.caseId ?? null;
  return result;
}

/** Parses the local storage object and returns the latest case ID, screen name, and timestamp */
export function parseSyncEntry(
  localStorageValue?: string | null
): WindowSyncEntry | null | undefined {
  if (localStorageValue == null || localStorageValue === '' || localStorageValue === '{}') {
    return null;
  }
  const storageValue = JSON.parse(localStorageValue);
  return storageValue;
}

/**
 * Saves information relevant to window synchronization checks to local storage
 * @param {string} caseId Case ID that the screen should be displaying
 */
export function saveWindowSyncInfo(caseId: string): void {
  const value = { caseId, timestamp: Date.now() } as const;
  localStorage.setItem(WINDOW_SYNC_LS_KEY, JSON.stringify(value));
}

import type { EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';
import type { ComponentType } from 'react';

export const CLEAR_FORMATTING_PLUGIN_ID: 'clearFormatting' = 'clearFormatting';
export type ClearFormattingPluginID = typeof CLEAR_FORMATTING_PLUGIN_ID;

export type ClearFormattingPluginOptions = Readonly<EditorPluginOptions>;

export type ClearFormattingPluginPropertyOptions = Readonly<
  EditorPluginPropertyOptions &
    ClearFormattingPluginOptions & {
      pluginID: ClearFormattingPluginID;
      hotkey: string;
      // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
      icon: ComponentType<any>;
      pluginName: string;
    }
>;

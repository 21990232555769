import { useCallback, useMemo } from 'react';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { logger } from '../modules/logger';
import { AppRoutePath } from '../config/constants';
import {
  dispatchExtensionEvent,
  transitionRouteEvent,
  closeWindowEvent,
} from '../domains/extension/extensionEventCreators';
import { ExtWindowName } from './useExtensionState';
import usePatientJacketView, {
  PATIENT_JACKET_VIEWS,
} from 'domains/worklist/Worklist/PatientJacketV2/hooks/usePatientJacketView';
import { useNavigate } from 'react-router-dom';
import { openPopup } from 'utils/openPopup';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';
import { useRecoilValue } from 'recoil';
import { shouldPatientJacketBeDocked as shouldPatientJacketBeDockedAtom } from '../domains/worklist/Worklist/state';

type UseManagePatientJacketReturn = {
  managePatientJacket: (params?: {
    nextCaseSmid?: string | null | undefined;
    forceOpen?: boolean;
  }) => void;
};

export const useManagePatientJacket = (): UseManagePatientJacketReturn => {
  const navigate = useNavigate();
  const [patientJacketV2Enabled] = useFeatureFlagEnabled(FF.PATIENT_JACKET_V2);
  const { data } = useCurrentUser();
  const me = data?.me;
  const shouldPatientJacketBeDocked = useRecoilValue(shouldPatientJacketBeDockedAtom);
  const openPatientJacketOnRead = me?.worklistSettings?.openPatientJacketOnRead ?? true;
  // 'docked' (same URL scheme despite the different UI) is the only available option for v1
  const shouldBeDocked = patientJacketV2Enabled ? shouldPatientJacketBeDocked : true;

  const { isDocked, isUndocked, view } = usePatientJacketView();
  const isPatientJacketOpen = view !== PATIENT_JACKET_VIEWS.NONE;
  const isCurrentWindowWorklist = window.location.pathname.includes('worklist');

  const openDockedPatientJacket = useCallback(
    (nextCaseSmid: string) => {
      if (isCurrentWindowWorklist) {
        navigate(AppRoutePath.PATIENT_JACKET.replace(':smid', nextCaseSmid));
      } else {
        dispatchExtensionEvent(
          transitionRouteEvent({
            target: ExtWindowName.Worklist,
            path: `${AppRoutePath.PATIENT_JACKET.replace(':smid', nextCaseSmid)}`,
          })
        );
      }
    },
    [isCurrentWindowWorklist, navigate]
  );

  const closePatientJacket = useCallback(() => {
    if (isCurrentWindowWorklist) {
      navigate(AppRoutePath.WORKLIST);
    } else if (isDocked) {
      dispatchExtensionEvent(
        transitionRouteEvent({
          target: ExtWindowName.Worklist,
          path: AppRoutePath.WORKLIST,
        })
      );
    } else {
      dispatchExtensionEvent(
        closeWindowEvent({
          target: ExtWindowName.Patient_Jacket,
        })
      );
    }
  }, [isCurrentWindowWorklist, isDocked, navigate]);

  const managePatientJacket = useCallback(
    ({ nextCaseSmid = null, forceOpen = false } = {}) => {
      if (nextCaseSmid == null) {
        logger.info('[useManagePatientJacket] sending closePatientJacket event', {
          nextCaseSmid,
          openPatientJacketOnRead,
          isPatientJacketOpen,
        });
        closePatientJacket();
      } else if (isPatientJacketOpen) {
        logger.info('[useManagePatientJacket] sending nextPatientJacket event', {
          nextCaseSmid,
          openPatientJacketOnRead,
          isPatientJacketOpen,
        });
        if (isUndocked) {
          dispatchExtensionEvent(
            transitionRouteEvent({
              target: ExtWindowName.Patient_Jacket,
              path: `${AppRoutePath.PATIENT_JACKET_UNDOCKED.replace(':smid', nextCaseSmid)}`,
            })
          );
        } else {
          openDockedPatientJacket(nextCaseSmid);
        }
      } else if (forceOpen || openPatientJacketOnRead) {
        logger.info('[useManagePatientJacket] sending openPatientJacket event', {
          nextCaseSmid,
          openPatientJacketOnRead,
          isPatientJacketOpen,
        });
        if (shouldBeDocked) {
          openDockedPatientJacket(nextCaseSmid);
        } else {
          const url = `${AppRoutePath.PATIENT_JACKET_UNDOCKED.replace(':smid', nextCaseSmid)}`;
          openPopup(url);
        }
      }
    },
    [
      isPatientJacketOpen,
      openPatientJacketOnRead,
      closePatientJacket,
      isUndocked,
      openDockedPatientJacket,
      shouldBeDocked,
    ]
  );

  return useMemo(() => ({ managePatientJacket }), [managePatientJacket]);
};

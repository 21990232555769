import { forwardRef } from 'react';
import { transparentize } from 'color2k';
import { Colors } from 'styles';

type FieldContentWrapperProps = {
  isDisabled?: boolean;
  isFocused?: boolean;
  children: React.ReactNode;
  css?: unknown;
};

export const FieldContentWrapper: React.ComponentType<FieldContentWrapperProps> = forwardRef<
  HTMLDivElement,
  FieldContentWrapperProps
>(({ isDisabled = false, isFocused = false, children, ...rest }, ref) => {
  return (
    <div
      {...rest}
      ref={ref}
      css={`
        color: ${Colors.gray10};
        background-color: ${isDisabled ? Colors.gray5 : transparentize(Colors.white, 0.97)};
        border-radius: 6px;
        border: 1px solid ${isFocused ? Colors.mainBlue : transparentize(Colors.white, 0.92)};
        padding: 12px 15px;
        position: relative;
      `}
    >
      {children}
    </div>
  );
});
FieldContentWrapper.displayName = 'FieldContentWrapper';

import type {
  EditorElement,
  EditorPluginOptions,
  EditorPluginPropertyOptions,
  Fragment,
} from '../../types';
import type { ComponentType } from 'react';

export const MACRO_PLACEHOLDER_PLUGIN_ID: 'macroPlaceholder' = 'macroPlaceholder';
export type MacroPlaceholderPluginID = typeof MACRO_PLACEHOLDER_PLUGIN_ID;

// A macro is technically just a fragment containing Nodes
export type Macro = Fragment;
export type MacroPlaceholderType = 'plainText' | 'macroText' | 'macroInline' | 'macroBlock';

export type MacroPlaceholderPluginElement = Readonly<
  EditorElement & {
    type: MacroPlaceholderPluginID;
    referenceID: string;
  }
>;

export type MacroPlaceholderPluginOptions = Readonly<
  EditorPluginOptions & {
    // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
    component?: ComponentType<any>;
  }
>;

export type MacroPlaceholderPluginPropertyOptions = Readonly<
  EditorPluginPropertyOptions &
    MacroPlaceholderPluginOptions & {
      pluginID: MacroPlaceholderPluginID;
      // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
      component: ComponentType<any>;
      pluginName: string;
    }
>;

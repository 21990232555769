import { Node } from 'domains/reporter/RichTextEditor/core';

import { useOpsLogger } from '../hooks/useOpsLogger';

type Props = {
  initialState: Node[];
};

export function ReporterOpsLogger({ initialState }: Props): React.ReactElement | null {
  useOpsLogger(initialState);
  return null;
}

import type { EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';

export const DICTAPHONE_CONTROLS_PLUGIN_ID: 'dictaphoneControls' = 'dictaphoneControls';
export type DictaphoneControlsPluginID = typeof DICTAPHONE_CONTROLS_PLUGIN_ID;

export type DictaphoneControlsPluginOptions = Readonly<
  EditorPluginOptions & {
    ignoreMergeFieldsInNavigation?: boolean;
    toggleFocusMode?: () => void;
  }
>;

export type DictaphoneControlsPluginPropertyOptions = Readonly<
  EditorPluginPropertyOptions &
    DictaphoneControlsPluginOptions & {
      pluginID: DictaphoneControlsPluginID;
      pluginName: string;
    }
>;

import { forwardRef } from 'react';
import { Colors } from 'styles';
import { NotEditable } from '../../../components';
import { transparentize } from 'color2k';

export type ReadonlyInlineTemplateElementProps = Readonly<{
  children: React.ReactNode;
  selected?: boolean;
  testID?: string;
  ['data-editor-element']?: string;
  ['data-slate-node']?: 'element';
  ['data-slate-inline']?: true;
  ['data-slate-void']?: true;
  ['data-selected']?: boolean;
  dir?: 'rtl';
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  element: any;
  leftDelimiter?: string;
  rightDelimiter?: string;
}>;

// For the Slate editor selection to work as expected, it helps to have a NotEditable boundary around the bookmark content.
// e.g. without this, double clicking a single word inside of a bookmark will place the focus outside of the bookmark.
// This will only be rendered if the inline bookmark is not empty.
const ZERO_WIDTH_SPACE = '\u200B';

const cssForBrackets = (
  selected: boolean,
  isEmptyInlineBookmark: boolean,
  leftDelimiter: string,
  rightDelimiter: string
) =>
  `
  &:focus {
    outline: 0;
  }
  &:before {
    content: ${"'" + leftDelimiter + "'"};
    font-family: 'Roboto Flex';
    color: ${selected ? Colors.blue5 : Colors.gray10};
    padding-right: ${isEmptyInlineBookmark ? '2px' : '0px'};
    position: relative;
    bottom: 1px;
  }
  &:after {
    content:  ${"'" + rightDelimiter + "'"};
    font-family: 'Roboto Flex';
    color: ${selected ? Colors.blue5 : Colors.gray10};
    padding-left: ${isEmptyInlineBookmark ? '2px' : '0px'};
    position: relative;
    bottom: 1px;
  }
  padding: 2px 0px;
  vertical-align: baseline;
  color: ${selected ? Colors.blue5 : Colors.yellow6};
  span[data-slate-string="true"]::selection {
    background-color: ${transparentize(Colors.blue5, 0.7)};
  }
  cursor: text;
  `;

export const ReadonlyInlineTemplateElement = forwardRef<
  HTMLSpanElement,
  ReadonlyInlineTemplateElementProps
>(
  (
    {
      children,
      selected = false,
      testID,
      element,
      leftDelimiter = '[',
      rightDelimiter = ']',
      ...props
    },
    ref
  ) => {
    const text = children?.[0]?.props?.text?.text ?? '';
    const isEmptyInlineBookmark = text === '';
    let css = '';
    if (element.shouldRenderBrackets !== false) {
      css = cssForBrackets(selected, isEmptyInlineBookmark, leftDelimiter, rightDelimiter);
    }

    return (
      <span ref={ref} data-testid={testID} css={css} {...props}>
        {isEmptyInlineBookmark ? <NotEditable /> : <NotEditable>{ZERO_WIDTH_SPACE}</NotEditable>}
        {children}
        {isEmptyInlineBookmark ? <NotEditable /> : <NotEditable>{ZERO_WIDTH_SPACE}</NotEditable>}
      </span>
    );
  }
);
ReadonlyInlineTemplateElement.displayName = 'ReadonlyInlineTemplateElement';

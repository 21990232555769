import { useCallback, useEffect } from 'react';
import useBroadcastChannel from 'hooks/useBroadcastChannel';

type Options = {
  callSelf: boolean;
};

export const useGlobalAction = (
  key: string,
  action: () => unknown,
  options?: Options
): (() => void) => {
  const bc = useBroadcastChannel(key);

  const runAction = useCallback(() => {
    bc.postMessage({ type: 'run' });
    if (options?.callSelf) {
      action();
    }
  }, [action, bc, options?.callSelf]);

  useEffect(() => {
    const cb = ({ type }: { type: string }) => {
      if (type === 'run') {
        if (typeof action === 'function') {
          try {
            return action();
          } catch (err: any) {}
        }
      }
    };
    bc.addEventListener('message', cb);

    return () => {
      bc.removeEventListener('message', cb);
    };
  }, [bc, action]);

  return runAction;
};

export const useAsyncGlobalAction = (
  key: string,
  action: () => Promise<void>,
  options?: Options
): (() => Promise<void>) => {
  const bc = useBroadcastChannel(key);

  const runAction = useCallback(async () => {
    bc.postMessage({ type: 'run' });
    if (options?.callSelf) {
      await action();
    }
  }, [action, bc, options?.callSelf]);

  useEffect(() => {
    const cb = async ({ type }: { type: string }) => {
      if (type === 'run') {
        if (typeof action === 'function') {
          try {
            return await action();
          } catch (err: any) {}
        }
      }
    };
    bc.addEventListener('message', cb);

    return () => {
      bc.removeEventListener('message', cb);
    };
  }, [bc, action]);

  return runAction;
};

import { Range, Node } from 'domains/reporter/RichTextEditor/core';

import { Flow } from 'flow-to-typescript-codemod';
import type { ToolInteractionPayload } from 'generated/graphql';

import { stringifyRange } from '../../../utils/stringify';

import { logger } from 'modules/logger';

export type StartTransactionRequest = {
  type: 'start';
  payload: {
    transaction_id: string;
    report_smid?: string;
    report_selection?: {
      anchor: {
        path: number[];
        offset: number;
      };
      focus: {
        path: number[];
        offset: number;
      };
    };
    report_content?: Node[];
    report_fields?: unknown[];
    template_fields?: unknown[];
    ai_mode?: boolean;
    focus_mode?: boolean;
    case_smid?: string;
    template_smid?: string;
    sdk_context?: ToolInteractionPayload;
  };
};

export type CreateStartTransactionRequestOptions = {
  transaction_id: string;
  case_smid?: string | null | undefined;
  template_smid?: string | null | undefined;
  report_smid?: string | null | undefined;
  report_selection?: Range;
  report_content?: Node[];
  report_fields?: unknown[];
  template_fields?: unknown[];
  ai_mode?: boolean;
  focus_mode?: boolean;
  sdk_context?: ToolInteractionPayload;
};

type StartTransactionProps = Flow.Diff<
  CreateStartTransactionRequestOptions,
  {
    transaction_id: string;
    report_selection?: Range;
  }
>;

export type StartTransactionOptions = StartTransactionProps & {
  report_selection?: Range;
  case_smid?: string;
  template_smid?: string;
  ai_mode?: boolean;
  focus_mode?: boolean;
  sdk_context?: ToolInteractionPayload;
};

export const createStartTransactionRequest = ({
  case_smid,
  template_smid,
  transaction_id,
  report_smid,
  report_selection,
  report_content,
  report_fields,
  template_fields,
  ai_mode,
  focus_mode,
  sdk_context,
}: CreateStartTransactionRequestOptions): StartTransactionRequest => {
  return {
    type: 'start',
    payload: {
      transaction_id,
      report_smid: report_smid ?? undefined,
      report_selection:
        report_selection != null
          ? {
              anchor: {
                path: report_selection.anchor.path,
                offset: report_selection.anchor.offset,
              },
              focus: {
                path: report_selection.focus.path,
                offset: report_selection.focus.offset,
              },
            }
          : undefined,
      report_content,
      report_fields,
      template_fields,
      ai_mode,
      focus_mode,
      case_smid: case_smid ?? undefined,
      template_smid: template_smid ?? undefined,
      sdk_context: sdk_context ?? undefined,
    },
  };
};

export type AudioMessage = ArrayBuffer;

export type HypothesisTextResponse = {
  type: 'hypothesis_text';
  payload: {
    transaction_id: string;
    text: string;
    done: boolean;
  };
};

export type StableTextResponse = {
  type: 'stable_text';
  payload: {
    transaction_id: string;
    text: string;
    markers: Array<{
      audioStart: number;
      audioLength: number;
      text: string;
    }>;
    done: boolean;
    substitutedText?: string;
  };
};

export type FocusMapAppendResponse = {
  type: 'focus_map_append';
  payload: {
    text: string;
    section: Array<string>;
  };
};

export type FocusMapDeleteResponse = {
  type: 'focus_map_delete';
  payload: {
    text: string;
    section: Array<string>;
  };
};

export type CommandResponse = {
  type: 'command';
  payload: {
    name: string;
    arguments: {
      string: string;
    };
  };
};

export type SDKCommandResponse = {
  type: 'sdk_command';
};

export type HangStudyCommandResponse = CommandResponse & {
  payload: CommandResponse['payload'] & {
    name: 'hang_study_by_id';
    arguments: {
      smid: string;
    };
  };
};

export type UpdateToolInteractionCommandResponse = CommandResponse & {
  payload: CommandResponse['payload'] & {
    name: 'update_tool_interaction';
    arguments: {
      llm_response_json: string;
    };
  };
};

export type StoppedResponse = {
  type: 'stopped';
  payload: {
    transaction_id: string;
    case_smid: string;
    ai_mode: boolean;
    focus_mode: boolean;
    text: string;
    done: boolean;
  };
};

export type ReportGeneratedResponse = {
  type: 'report_generated';
  payload: {
    transaction_id: string;
  };
};

export type StopTransactionRequest = {
  type: 'stop';
  payload: {
    transaction_id: string;
    ai_mode: boolean;
    focus_mode: boolean;
  };
};

export type CreateStopTransactionRequestOptions = {
  transaction_id: string;
  ai_mode: boolean;
  focus_mode: boolean;
};

export const createStopTransactionRequest = ({
  transaction_id,
  ai_mode,
  focus_mode,
}: CreateStopTransactionRequestOptions): StopTransactionRequest => {
  return {
    type: 'stop',
    payload: {
      transaction_id,
      ai_mode,
      focus_mode,
    },
  };
};

export type GenerateReportRequest = {
  type: 'generate';
  payload: {
    transaction_id: string;
    case_smid: string;
    ai_mode: boolean;
  };
};

export type CreateGenerateReportRequestOptions = {
  transaction_id: string;
  case_smid: string;
};

export const createGenerateReportRequest = ({
  transaction_id,
  case_smid,
}: CreateGenerateReportRequestOptions): GenerateReportRequest => {
  return {
    type: 'generate',
    payload: {
      transaction_id,
      case_smid,
      ai_mode: true,
    },
  };
};

export type ErrorResponse = {
  type: 'error';
  payload: {
    transaction_id: string;
    message: string;
  };
};

export type ASRPlexMessage =
  | HypothesisTextResponse
  | StableTextResponse
  | CommandResponse
  | SDKCommandResponse
  | HangStudyCommandResponse
  | UpdateToolInteractionCommandResponse
  | StoppedResponse
  | ReportGeneratedResponse
  | ErrorResponse;

export class ASRPlexTransaction {
  encoder: TextEncoder = new TextEncoder();
  transaction_id: string;
  encoded_transaction_id: Uint8Array;
  state: 'new' | 'started' | 'stopped' = 'new';
  socket: WebSocket;
  selection: Range | null = null;
  selectionKey: string | null = null;
  ai_mode: boolean = false;
  focus_mode: boolean = false;

  constructor(transaction_id: string, socket: WebSocket, ai_mode?: boolean, focus_mode?: boolean) {
    this.transaction_id = transaction_id;
    this.encoded_transaction_id = this.encoder.encode(`${transaction_id} `);
    this.ai_mode = ai_mode ?? false;
    this.focus_mode = focus_mode ?? false;
    this.socket = socket;
    this.socket.addEventListener('error', (ev: MessageEvent) => {
      logger.error('[asrPlexProtocol] ASRPlexTransaction socket error, stopping transaction.', {
        transaction_id: this.transaction_id,
        event: ev,
      });
      this.state = 'stopped';
    });
  }

  startTransaction({
    case_smid,
    template_smid,
    report_selection,
    report_content,
    report_fields,
    template_fields,
    ai_mode,
    focus_mode,
    sdk_context,
  }: StartTransactionOptions): void {
    if (this.state !== 'new') {
      logger.error(
        `[asrPlexProtocol] startTransaction called when transaction is in state: ${this.state}. Expected state: new`,
        { transaction_id: this.transaction_id }
      );
      return;
    }
    this.selection = report_selection ?? null;
    this.selectionKey = report_selection != null ? stringifyRange(report_selection) : null;
    const startReq = createStartTransactionRequest({
      transaction_id: this.transaction_id,
      case_smid,
      template_smid,
      report_content,
      report_fields,
      report_selection,
      template_fields,
      ai_mode,
      focus_mode,
      sdk_context,
    });
    this.socket.send(JSON.stringify(startReq));
    this.state = 'started';
  }

  stopTransaction(): void {
    if (this.state !== 'started') {
      logger.error(
        `[asrPlexProtocol] stopTransaction called when transaction is in state: ${this.state}. Expected state: started`,
        { transaction_id: this.transaction_id }
      );
      return;
    }
    const stopReq = createStopTransactionRequest({
      transaction_id: this.transaction_id,
      ai_mode: this.ai_mode,
      focus_mode: this.focus_mode,
    });
    this.socket.send(JSON.stringify(stopReq));
    this.state = 'stopped';
  }

  sendAudio(audio: Float32Array): void {
    if (this.state !== 'started') {
      logger.error(
        `[asrPlexProtocol] sendAudio called when transaction is in state: ${this.state}. Expected state: started`,
        { transaction_id: this.transaction_id }
      );
      return;
    }
    const message = new Uint8Array(this.encoded_transaction_id.length + audio.byteLength);
    message.set(this.encoded_transaction_id);
    message.set(new Uint8Array(audio.buffer), this.encoded_transaction_id.length);
    this.socket.send(message.buffer);
  }
}

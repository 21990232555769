/**
 * Audio mime types supported by nVoq.
 */
export const AudioMimeType = Object.freeze({
  pcm16khz: 'audio/pcm-16khz',
  pcm8khz: 'audio/pcm-8khz',
  ogg: 'audio/ogg',
  webm: 'audio/webm',
});

/**
 * Audio formats supported by nVoq.
 */
export const AudioFormat: {
  ogg: string;
  pcm16khz: string;
  pcm8khz: string;
  webm: string;
} = Object.keys(AudioMimeType).reduce(
  (acc, curr) => {
    const [, audioFormat] = AudioMimeType[curr].split('/');
    acc[curr] = audioFormat;
    return acc;
  },
  { ogg: '', pcm16khz: '', pcm8khz: '', webm: '' }
);

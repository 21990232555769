import { useCallback, useEffect, useState } from 'react';
import Text from 'common/ui/Text';
import { IconButton, Switch, withStyles } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { ApolloCache, useMutation } from '@apollo/client';
import {
  GET_TOOL_PREFERENCES,
  SET_WWWC_DRAG_DIRECTION,
  TOGGLE_MANUAL_LOCALIZER_LINES,
  TOGGLE_STACKED_SCROLLING,
  UPDATE_MOUSE_SCROLL_DIRECTION,
  UPDATE_STACKED_MODALITIES,
  TOGGLE_ABSOLUTE_SCROLL,
} from 'modules/Apollo/queries';
import { Stack, Spacer } from 'common/ui/Layout';
import { useCurrentUser } from 'hooks/useCurrentUser';
import type {
  GetToolPreferencesQuery,
  GetToolPreferencesQueryVariables,
  GetMeQuery,
  ToggleStackedScrollingMutation,
  ToggleManualLocalizerLinesMutation,
  UpdateMouseScrollDirectionMutation,
  UpdateMouseScrollDirectionMutationVariables,
  ToggleAbsoluteScrollMutation,
} from 'generated/graphql';
import { useIsWwwcInverted, useIsScrollInverted } from 'hooks/useIsScrollInverted';
import { useBaseViewerData } from 'domains/viewer/Viewer/StudyLoader/useStudies';
import {
  SettingsCard,
  SettingsCardContent,
  SettingsCardHeader,
  SettingsSeparator,
} from './SettingsLayoutComponents';
import type { DocumentNode } from 'graphql';
import SettingsDropdown from 'common/SettingsDropdown';
import { MODALITIES } from 'config/constants';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { Colors } from 'styles';
import { VirtualMonitorSplitSettings } from './VirtualMonitorSplitSettings';
import { EraseAllToolScopeSettings } from './EraseAllToolScopeSettings';
import { SkipSeriesSettings } from './SkipSeriesSettings';
import { purgePrecache } from 'modules/Apollo/precacheLink';
import { notifyUserSettingsUpdate } from 'hooks/useViewerSettingsSync';
import Tooltip from 'common/ui/Tooltip';
import Info from '@material-ui/icons/Info';
import { ThumbnailSizeSettings } from './ThumbnailSizeSettings';

type ViewerSettingsTabProps = {
  onClose: () => void;
  setError: (error?: string | null | undefined) => void;
};

const SettingsMenuItem = withStyles({
  root: {
    '&.Mui-selected': {
      backgroundColor: 'initial',
    },
  },
})(MenuItem);

export const ViewerSettingsTab = ({
  onClose,
  setError,
}: ViewerSettingsTabProps): React.ReactElement => {
  const {
    isScrollInverted,
    handleUpdateMouseScrollDirection,
    isWwwcInverted,
    handleInvertWwwcChange,
  } = useMouseSettingsToggles(setError);

  const [stackedScrollingEnabled, handleStackedScrollingChange] =
    useStackedScrollingToggle(setError);

  const [absoluteScrollingEnabled, handleAbsoluteScrollingChange] =
    useAbsoluteScrollingToggle(setError);

  const [manualLocalizerLinesEnabled, handleManualLocalizerLinesChange] =
    useManualLocalizerLinesToggle(setError);

  const [userStackedModalities, handleUserStackedModalitiesChange] =
    useStackedScrollingModalities(setError);

  // Using local state to keep track of selected items for the server request upon close
  const [stackedModalities, setStackedModalities] =
    useState<ReadonlyArray<string>>(userStackedModalities);

  useEffect(() => {
    setStackedModalities(userStackedModalities);
  }, [userStackedModalities]);

  return (
    <SettingsCard>
      <SettingsCardHeader>
        <Stack alignY="center">
          <Text variant="display2">Viewer Settings</Text>
          <Spacer />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </SettingsCardHeader>

      <SettingsCardContent>
        <Text variant="display1" display="block" gutterBottom="small">
          General
        </Text>
        <VirtualMonitorSplitSettings />
        <SkipSeriesSettings />
        <Stack alignY="center">
          <Text>Show localizer lines for manually-linked studies</Text>
          <Spacer />
          <Switch
            checked={manualLocalizerLinesEnabled}
            onChange={handleManualLocalizerLinesChange}
          />
        </Stack>
        <EraseAllToolScopeSettings />
        <ThumbnailSizeSettings />
        <SettingsSeparator />
        <Text variant="display1" display="block" gutterBottom="small">
          Mouse
        </Text>
        <Stack alignY="center">
          <Text>Invert mouse wheel scroll direction</Text>
          <Spacer />
          <Switch
            checked={isScrollInverted}
            // @ts-expect-error [EN-7967] - TS2322 - Type '(event: Event & { target: { checked: boolean; }; }) => void' is not assignable to type '(event: ChangeEvent<HTMLInputElement>, checked: boolean) => void'.
            onChange={handleUpdateMouseScrollDirection}
            inputProps={{ 'aria-label': 'Invert mouse wheel scroll direction' }}
          />
        </Stack>
        <Stack alignY="center">
          <Text>Invert Window Width/Level drag direction</Text>
          <Spacer />
          <Switch
            checked={isWwwcInverted}
            onChange={handleInvertWwwcChange}
            inputProps={{ 'aria-label': 'Invert Window Width/Level drag direction' }}
          />
        </Stack>
        <Stack alignY="center">
          <Text>Absolute scrolling</Text>
          <Tooltip
            content="A sync setting to correct for a common data error where series that share a frame of reference are given opposite directions"
            placement="right"
          >
            <Info style={{ cursor: 'pointer', marginLeft: '8px' }} />
          </Tooltip>
          <Spacer />
          <Switch
            checked={absoluteScrollingEnabled}
            onChange={handleAbsoluteScrollingChange}
            data-testid="absolute-scrolling"
            inputProps={{ 'aria-label': 'Absolute scrolling' }}
          />
        </Stack>
        <Stack alignY="center">
          <Text>Stacked scrolling</Text>
          <Spacer />
          <Switch
            checked={stackedScrollingEnabled}
            onChange={handleStackedScrollingChange}
            data-testid="stacked-scrolling"
            inputProps={{ 'aria-label': 'Stacked scrolling' }}
          />
        </Stack>
        <Stack alignY="center" vertical={true} alignX="start" css={{ paddingLeft: '16px' }}>
          <Text variant="body2">Modalities</Text>
          <SettingsDropdown
            multiple={true}
            value={stackedModalities}
            onSelectedChange={(value) => {
              // We should not ever not have an array with multiple true.
              // Since the value is a union there is a type warning that it might not be what we expect
              if (Array.isArray(value)) {
                setStackedModalities(value);
              }
            }}
            onClose={() => {
              handleUserStackedModalitiesChange(undefined, stackedModalities);
            }}
            data-testid="stacked-scrolling-modalities"
          >
            {MODALITIES.map((option) => (
              <SettingsMenuItem
                id={`select-menuitem-${option.value}`}
                value={option.value}
                key={option.value}
                data-testid={`${option.value}-menu-item`}
              >
                <Checkbox
                  color="primary"
                  css={`
                    color: ${Colors.blue4};
                  `}
                  checked={stackedModalities.includes(option.value)}
                />
                {option.name}
              </SettingsMenuItem>
            ))}
          </SettingsDropdown>
        </Stack>
      </SettingsCardContent>
    </SettingsCard>
  );
};

const useMouseSettingsToggles = (setError: (error?: string | null | undefined) => void) => {
  const [setWwwcDragDirection] = useMutation(SET_WWWC_DRAG_DIRECTION, {
    refetchQueries: [{ query: GET_TOOL_PREFERENCES }],
    update(cache: ApolloCache<unknown>, response, { variables }) {
      const { toolPreferences } =
        cache.readQuery<GetToolPreferencesQuery, GetToolPreferencesQueryVariables>({
          query: GET_TOOL_PREFERENCES,
        }) ?? {};

      if (toolPreferences == null) {
        return;
      }
      cache.writeQuery({
        query: GET_TOOL_PREFERENCES,
        data: {
          toolPreferences: {
            ...toolPreferences,
            wwwc: {
              ...toolPreferences.wwwc,
              invertDragDirection: variables?.value,
            },
          },
        },
      });
    },
  });

  const isWwwcInverted = useIsWwwcInverted();

  const handleInvertWwwcChange = () => {
    setWwwcDragDirection({
      variables: { value: !isWwwcInverted },
      optimisticResponse: {
        __typename: 'Mutation',
        setWwwcDragDirection: {
          invertDragDirection: !isWwwcInverted,
        },
      },
    });

    notifyUserSettingsUpdate();
  };

  const [updateMouseScrollDirection] = useMutation<
    UpdateMouseScrollDirectionMutation,
    UpdateMouseScrollDirectionMutationVariables
  >(UPDATE_MOUSE_SCROLL_DIRECTION, {
    update(cache: ApolloCache<unknown>, response, { variables = {} }) {
      const toolPreferences = cache.readQuery<
        GetToolPreferencesQuery,
        GetToolPreferencesQueryVariables
      >({
        query: GET_TOOL_PREFERENCES,
      })?.toolPreferences;

      cache.writeQuery({
        query: GET_TOOL_PREFERENCES,
        data: {
          toolPreferences: {
            ...toolPreferences,
            invertScroll: variables.invertScroll,
          },
        },
      });
    },
  });

  const isScrollInverted = useIsScrollInverted();
  const handleUpdateMouseScrollDirection = (
    event: Event & {
      target: {
        checked: boolean;
      };
    }
  ) => {
    updateMouseScrollDirection({
      variables: {
        invertScroll: event.target.checked,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateMouseScrollDirection: {
          __typename: 'ToolPreferences',
          invertScroll: event.target.checked,
        },
      },
    });

    notifyUserSettingsUpdate();
  };

  return {
    isScrollInverted,
    handleUpdateMouseScrollDirection,
    isWwwcInverted,
    handleInvertWwwcChange,
  };
};

const useStackedScrollingToggle = (setError: (error?: string | null | undefined) => void) => {
  const { refetch } = useBaseViewerData();
  const handleChange = useCallback(async () => {
    await purgePrecache();
    refetch();
  }, [refetch]);
  return useUserPreferenceValue<ToggleStackedScrollingMutation, boolean>({
    query: TOGGLE_STACKED_SCROLLING,
    preferenceKey: 'stackInstancesByType',
    onChange: handleChange,
    setError,
    initValue: false,
  });
};

const useAbsoluteScrollingToggle = (setError: (error?: string | null | undefined) => void) => {
  const getStateFromUser = useCallback((user) => user.viewerSettings.absoluteScroll ?? true, []);
  return useUserPreferenceValue<ToggleAbsoluteScrollMutation, boolean>({
    query: TOGGLE_ABSOLUTE_SCROLL,
    preferenceKey: 'absoluteScroll',
    getStateFromUser,
    setError,
    initValue: false,
  });
};

const useStackedScrollingModalities = (setError: (error?: string | null | undefined) => void) => {
  const { refetch } = useBaseViewerData();
  const handleChange = useCallback(async () => {
    await purgePrecache();
    refetch();
  }, [refetch]);
  return useUserPreferenceValue<ToggleStackedScrollingMutation, ReadonlyArray<string>>({
    query: UPDATE_STACKED_MODALITIES,
    preferenceKey: 'stackedModalities',
    onChange: handleChange,
    setError,
    initValue: [],
  });
};

const useManualLocalizerLinesToggle = (setError: (error?: string | null | undefined) => void) => {
  const getStateFromUser = useCallback((user) => user.viewerSettings.showManualLocalizerLines, []);
  return useUserPreferenceValue<ToggleManualLocalizerLinesMutation, boolean>({
    query: TOGGLE_MANUAL_LOCALIZER_LINES,
    preferenceKey: 'showManualLocalizerLines',
    getStateFromUser,
    setError,
    initValue: false,
  });
};

type UseUserPreferenceProps<T> = {
  query: DocumentNode;
  preferenceKey: string;
  getStateFromUser?: (user: NonNullable<GetMeQuery['me']>) => T;
  onChange?: () => unknown;
  setError: (error?: string | null | undefined) => void;
  initValue: T;
};

const useUserPreferenceValue = <Mutation extends unknown, T extends unknown>({
  query,
  preferenceKey,
  getStateFromUser,
  onChange,
  setError,
  initValue,
}: UseUserPreferenceProps<T>): [T, (_: unknown, value: T) => Promise<void>] => {
  const { data, refetch: refetchUser } = useCurrentUser();
  const me = data?.me;
  const [setPreference] = useMutation<
    Mutation,
    {
      [preferencesKey: string]: T;
    }
  >(query);

  const [enabled, setEnabled] = useState(initValue);

  useEffect(() => {
    if (me) {
      setEnabled(getStateFromUser?.(me) ?? me[preferenceKey]);
    }
  }, [me, getStateFromUser, preferenceKey]);

  const sendUpdate = async (payload: T) => {
    setError(null);

    try {
      await setPreference({
        variables: {
          [preferenceKey]: payload,
        },
      });
    } catch (e: any) {
      setError('An error occurred. Please try again.');
    }
    refetchUser();
    onChange?.();
  };

  const handleChange = async (_: unknown, checked: T) => {
    setEnabled(checked);
    await sendUpdate(checked);

    notifyUserSettingsUpdate();
  };

  return [enabled, handleChange];
};

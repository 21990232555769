import type { EditorElement, EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';
import type { ComponentType } from 'react';

export const BOLD_PLUGIN_ID: 'bold' = 'bold';
export type BoldPluginID = typeof BOLD_PLUGIN_ID;

export type BoldPluginElement = EditorElement;

export type BoldPluginOptions = Readonly<
  EditorPluginOptions & {
    // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
    component?: ComponentType<any>;
  }
>;

export type BoldPluginPropertyOptions = Readonly<
  EditorPluginPropertyOptions &
    BoldPluginOptions & {
      pluginID: BoldPluginID;
      hotkey: string;
      // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
      icon: ComponentType<any>;
      pluginName: string;
      // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
      component: ComponentType<any>;
    }
>;

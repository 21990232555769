import { useCallback, useState } from 'react';
import { css } from 'styled-components';
import { useMutation } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import AuthPage from './AuthPage';
import Text from 'common/ui/Text';
import Input from 'common/ui/Input';
import { Button } from 'common/ui/Button';
import { REQUEST_PASSWORD_RESET } from 'modules/Apollo/queries';
import { Colors, TextCss } from 'styles';
import { Stack } from '../ui/Layout';

const RequestPasswordReset = (): React.ReactElement => {
  const [requestPasswordReset] = useMutation(REQUEST_PASSWORD_RESET);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);

  const submit = useCallback(
    async (evt: React.SyntheticEvent<HTMLFormElement>) => {
      evt.preventDefault();
      // @ts-expect-error [EN-7967] - TS2339 - Property 'entries' does not exist on type 'FormData'.
      const data = Object.fromEntries(new FormData(evt.currentTarget).entries());
      setEmail(data.email);
      setError(null);
      setLoading(true);
      try {
        await requestPasswordReset({ variables: { email: data.email } });
        setSubmitted(true);
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    },
    [requestPasswordReset]
  );

  return (
    <AuthPage onSubmit={submit} title="Reset password">
      <Link to="/">
        <img
          src="/logos/logoSide.png"
          alt="Sirona Medical Logo"
          css={`
            width: 5rem;
            margin-bottom: 1.5rem;
          `}
        />
      </Link>

      {submitted === false ? (
        <>
          <Text variant="body2">
            Sorry you’re having trouble. Please enter your email address below. You will receive an
            email from [account-help-id]@[practice-url].[ext] with a link to reset your password.
          </Text>

          <div css="margin-top: 2.5em; margin-bottom: 3em; display: flex; flex-direction: column; align-self: stretch;">
            <label
              css={css`
                ${TextCss.caption}
                margin-left: 1.5rem;
                margin-top: 0.3rem;
                margin-bottom: 0.3em;
              `}
              htmlFor="email"
            >
              Email address
            </label>
            <Input
              id="email"
              type="email"
              name="email"
              fullWidth
              placeholder="eg:johndoe@practice.com"
            />
          </div>

          {error && (
            <Text
              css={`
                color: ${Colors.red5};
                margin-bottom: 0.3rem;
              `}
            >
              {error.message}
            </Text>
          )}

          {!loading ? (
            <Stack css="width: 100%" alignX="center" alignY="center">
              <Button css="width: 100%" type="submit">
                Reset password
              </Button>
            </Stack>
          ) : (
            <CircularProgress />
          )}
        </>
      ) : (
        <Stack vertical space="small">
          <Text>
            An email has been sent to {String(email)}. Please follow the instructions in the email
            to reset your password.
          </Text>
          <Text>
            Didn’t receive it? Check your SPAM folder, and add no-reply@sironamedical.com to your
            contacts. Contact your admin for further assistance.
          </Text>
        </Stack>
      )}
    </AuthPage>
  );
};

export default RequestPasswordReset;

import { atom, useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import type { RecoilState, SetterOrUpdater } from 'recoil';
import {
  broadcastChannelSynchronizerEffect,
  localStoragePersisterEffect,
} from 'utils/recoilEffects';
import type { ToolID } from 'hooks/usePreferences/allTools';

export const selectedToolState: RecoilState<ToolID> = atom({
  key: 'selectedTool',
  default: 'REGULAR_SCROLL',
  effects: [broadcastChannelSynchronizerEffect(), localStoragePersisterEffect()],
});

export const useSelectedTool = (): [ToolID, SetterOrUpdater<ToolID>] => {
  return useRecoilState(selectedToolState);
};

export const useSelectedToolValue = (): ToolID => {
  return useRecoilValue(selectedToolState);
};

export const useSetSelectedTool = (): SetterOrUpdater<ToolID> => {
  return useSetRecoilState(selectedToolState);
};

export const withSelectedTool = <P extends unknown>(
  Component: React.ComponentType<P>
): React.ComponentType<
  P & {
    selectedTool: ToolID;
    setSelectedTool: SetterOrUpdater<ToolID>;
  }
> =>
  function WithSelectedTool(props) {
    const [selectedTool, setSelectedTool] = useSelectedTool();
    return <Component {...props} selectedTool={selectedTool} setSelectedTool={setSelectedTool} />;
  };

import { atom, selector, useRecoilCallback, useRecoilValue, useResetRecoilState } from 'recoil';
import type { RecoilValueReadOnly, RecoilState } from 'recoil';
import { AnnotationTypes } from 'generated/graphql';
import type {
  ToolInteractionStartAnnotationResponse,
  GetCustomAnnotationLogicQuery,
  GetCustomAnnotationLogicQueryVariables,
} from 'generated/graphql';
import { CustomAnnotationTool } from '../../Custom';
import type { AnnotationData, CompleteCallback } from '../../types';
import { useMemo } from 'react';
import { GET_CUSTOM_ANNOTATION_LOGIC } from 'modules/Apollo/queries';
import { useQuery } from '@apollo/client';
import type { Vector3 as vec3 } from '@kitware/vtk.js/types';

export const startAnnotationState: RecoilState<{
  customAnnotationType: ToolInteractionStartAnnotationResponse['annotationType'] | null | undefined;
  startPosition: vec3 | null | undefined;
}> = atom({
  key: 'viewer.dre.sdk.startAnnotationState',
  default: { customAnnotationType: null, startPosition: null },
});

export const isDrawingAnnotationSelector: RecoilValueReadOnly<boolean> = selector<boolean>({
  key: 'viewer.dre.sdk.isDrawingAnnotation',
  get: ({ get }) => {
    const { customAnnotationType, startPosition } = get(startAnnotationState);
    return customAnnotationType != null && startPosition != null;
  },
});

type UseStartAnnotationResponseArgs = {
  mousePosition: vec3 | null | undefined;
};

export function useStartAnnotationResponse({
  mousePosition,
}: UseStartAnnotationResponseArgs): (
  response: Readonly<ToolInteractionStartAnnotationResponse>
) => void {
  return useRecoilCallback(
    ({ set }) =>
      (response: any) => {
        if (mousePosition == null) return;
        set(startAnnotationState, {
          customAnnotationType: response.annotationType,
          startPosition: mousePosition,
        });
      },
    [mousePosition]
  );
}

export function CustomAnnotationToolRenderer({
  onComplete,
  toolId,
}: {
  onComplete: CompleteCallback;
  toolId: string;
}): React.ReactElement | null {
  const { customAnnotationType, startPosition } = useRecoilValue(startAnnotationState);
  const resetStartAnnotationState = useResetRecoilState(startAnnotationState);

  const annotationType: AnnotationTypes = useMemo(() => {
    if (
      customAnnotationType != null &&
      // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'string' is not assignable to parameter of type 'AnnotationTypes'.
      Object.values(AnnotationTypes).includes(customAnnotationType)
    ) {
      return customAnnotationType as AnnotationTypes;
    } else {
      return AnnotationTypes.Custom;
    }
  }, [customAnnotationType]);

  const { data } = useQuery<GetCustomAnnotationLogicQuery, GetCustomAnnotationLogicQueryVariables>(
    GET_CUSTOM_ANNOTATION_LOGIC,
    {
      variables:
        customAnnotationType != null
          ? {
              toolId,
              type: customAnnotationType,
            }
          : undefined,
      skip: customAnnotationType == null,
    }
  );
  const logic = data?.customAnnotationLogic?.logic;
  const logicId = data?.customAnnotationLogic?.id;

  const createAnnotation = useRecoilCallback(
    ({ reset }) =>
      async (annotation: AnnotationData) => {
        await onComplete(annotation);
        reset(startAnnotationState);
      },
    [onComplete]
  );

  if (annotationType == null || logic == null || logicId == null) return null;

  if (annotationType !== AnnotationTypes.Custom) {
    throw new Error(
      'CustomAnnotationToolRenderer does not support non-custom annotation types yet.'
    );
  }

  return (
    <CustomAnnotationTool
      onComplete={createAnnotation}
      onCancel={resetStartAnnotationState}
      annotationType={annotationType}
      annotationLogic={logic}
      annotationLogicId={logicId}
      initialPoints={startPosition != null ? [startPosition] : undefined}
    />
  );
}

import { Colors, Sizes } from 'styles';

const styles = {
  /* Styles applied to menu button */
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  /* Styles applied to appbar */
  appbar: {
    zIndex: 8,
    background: Colors.toolbar,
    borderBottom: `1px solid ${Colors.gray2}`,
    height: Sizes.toolbarHeight,
  },
  toolbar: {
    height: Sizes.toolbarHeight,
  },
} as const;

export default styles;

import { Range } from 'domains/reporter/RichTextEditor/core';
import { AudioMimeType } from 'domains/reporter/audioTypes';
import analytics from 'modules/analytics';
import { reporter } from 'modules/analytics/constants';
import Deferred from 'utils/deferred';
import { performance } from 'utils/performance';
import type { AudioSamplingRate } from 'domains/reporter/Reporter/ExperimentControlTab';
import { nanoid } from 'nanoid';

// nVoq prefers 300ms audio chunks
export const NVOQ_PREFERRED_AUDIO_CHUNK_TIME_MS = 300;

export class DownsampleRawPCMMonoAudioRecorder {
  id: string;
  audioStream: MediaStream;
  audioContext: AudioContext;
  sampleRate: number;
  audioInput: MediaStreamAudioSourceNode;
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  recorder: any;
  config: {
    onDataAvailable: (arg1: {
      id: string;
      data: Blob;
      buffer: Float32Array;
      selection: Range | null | undefined;
      duration: number;
    }) => void;
    analytics?: {
      modality: string;
      bodyPart: string;
      studyDescription: string;
      worklistItemId: string;
    };
    sourceSampleRate: AudioSamplingRate;
    targetSampleRate: AudioSamplingRate;
    selection: Range | null | undefined;
  };
  recording: boolean = false;
  batchStartedAt: DOMHighResTimeStamp | null = null;
  onRecorderReady: Promise<undefined>;
  finalFrameProcessed: Promise<undefined>;
  waitingForFinalFrame: boolean;
  packetsSent: number;
  bufferedAudioFrames: Buffer[];
  selection: Range | null | undefined;
  startTimestamp: DOMHighResTimeStamp | null;
  endTimestamp: DOMHighResTimeStamp | null;
  emptyBuffer: Float32Array = new Float32Array(0);

  constructor(
    audioStream: MediaStream,
    audioContext: AudioContext,
    config: DownsampleRawPCMMonoAudioRecorder['config']
  ) {
    this.id = nanoid();
    this.startTimestamp = null;
    this.endTimestamp = null;
    const onRecorderReady = new Deferred();
    // @ts-expect-error [EN-7967] - TS2322 - Type 'Promise<unknown>' is not assignable to type 'Promise<undefined>'.
    this.onRecorderReady = onRecorderReady.promise;

    const finalFrameProcessed = new Deferred();
    // @ts-expect-error [EN-7967] - TS2322 - Type 'Promise<unknown>' is not assignable to type 'Promise<undefined>'.
    this.finalFrameProcessed = finalFrameProcessed.promise;
    this.waitingForFinalFrame = false;

    this.audioStream = audioStream;

    // creates an instance of audioContext
    this.audioContext = audioContext;

    // Set the worklet's targeted selection
    // Note that the raw audio worklet will have no (i.e. null) selection
    this.selection = config.selection;

    // retrieve the current sample rate of microphone the browser is using
    this.sampleRate = this.audioContext.sampleRate;

    // creates an audio node from the microphone incoming stream
    this.audioInput = this.audioContext.createMediaStreamSource(audioStream);

    this.recorder = new AudioWorkletNode(this.audioContext, 'downsample-raw-pcm-speech-processor', {
      processorOptions: {
        sourceSampleRate: config.sourceSampleRate,
        targetSampleRate: config.targetSampleRate,
      },
    });
    this.audioInput.connect(this.recorder);

    this.packetsSent = 0;
    this.bufferedAudioFrames = [];

    this.recorder.port.onmessage = (event: any) => {
      if (this.batchStartedAt == null) {
        this.batchStartedAt = performance.now();
      }
      if (event.data.type === 'data') {
        // The recorder will almost always be stopped in the middle of a frame.
        // When that happens, the partial frame is added to the bufferedAudioFrames,
        // and then the buffered frames are posted.
        if (this.waitingForFinalFrame) {
          this.bufferedAudioFrames.push(event.data.data.buffer);
          const blob = new Blob(this.bufferedAudioFrames, {
            type: AudioMimeType.pcm16khz,
          });
          this.config.onDataAvailable({
            id: this.id,
            data: blob,
            buffer: this.emptyBuffer,
            selection: this.selection,
            duration: this.getDurationMillis(),
          });
          this.packetsSent++;
          this.bufferedAudioFrames = [];

          // @ts-expect-error [EN-7967] - TS2554 - Expected 1 arguments, but got 0.
          finalFrameProcessed.resolve();
          this.waitingForFinalFrame = false;
          return;
        }

        // We use timestamps to check how much time has elapsed so that we send audio frames
        // as close to the preferred time value as possible. Previously, we assumed the sample
        // rate would produce a consistent number of audio frames, but in practice we can drop
        // frames and send audio much more slowly than we intended to.
        const startedAt = this.batchStartedAt;
        const timeElapsed = startedAt != null ? performance.now() - startedAt : -1;
        if (timeElapsed >= NVOQ_PREFERRED_AUDIO_CHUNK_TIME_MS) {
          // Are we more than 150% past the preferred nvoq audio chunk time
          if (
            timeElapsed >=
            NVOQ_PREFERRED_AUDIO_CHUNK_TIME_MS + NVOQ_PREFERRED_AUDIO_CHUNK_TIME_MS / 2
          ) {
            analytics.track(reporter.sys.slowAudioProcessing, {
              ...this.config.analytics,
              duration_ms: timeElapsed,
              type: 'measure',
            });
          }
          this.batchStartedAt = null;
          this.packetsSent++;

          this.bufferedAudioFrames.push(event.data.data.buffer);

          const blob = new Blob(this.bufferedAudioFrames, {
            type: AudioMimeType.pcm16khz,
          });
          this.config.onDataAvailable({
            id: this.id,
            data: blob,
            buffer: this.emptyBuffer,
            selection: this.selection,
            duration: this.getDurationMillis(),
          });

          this.bufferedAudioFrames = [];

          return;
        }

        this.bufferedAudioFrames.push(event.data.data.buffer);
      }
    };

    // @ts-expect-error [EN-7967] - TS2554 - Expected 1 arguments, but got 0.
    onRecorderReady.resolve();

    this.config = config;
  }

  startRecording() {
    if (this.recorder == null) {
      console.warn(
        "Can't start recorder since it's not ready yet. Use the `onRecorderReady` promise to wait for the recorder."
      );
      return;
    }
    if (this.recording) {
      console.warn("Can't start recorder since it's already started.");
      return;
    }

    this.startTimestamp = performance.now();
    this.recording = true;

    // start recording
    this.recorder.connect(this.audioContext.destination);
  }

  stopRecording(callback?: () => void) {
    if (this.recording === false) {
      console.warn("Can't stop recorder since it's already stopped.");
      return;
    }

    // Give command to close the processing of the worklet
    this.recorder.port.postMessage({ command: 'close' });

    this.waitingForFinalFrame = true;
    this.finalFrameProcessed.then(() => {
      this.recorder.disconnect();
      this.endTimestamp = performance.now();
      this.recording = false;

      // eslint-disable-next-line no-console
      console.log(`Recording complete with ${this.packetsSent} packets sent for audio processing.`);

      this.packetsSent = 0;
      callback != null && callback();
    });
  }

  getAudioContext(): AudioContext {
    return this.audioContext;
  }

  /**
   * Get the cumulative duration of the recording in milliseconds. This number will grow until
   * the recording stops. If the recording has not yet started, returns -1.
   */
  getDurationMillis(): number {
    if (this.startTimestamp == null) {
      return -1;
    }
    if (this.endTimestamp == null) {
      return performance.now() - this.startTimestamp;
    }
    return this.endTimestamp - this.startTimestamp;
  }
}

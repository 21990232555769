import type { EditorPlugin } from '../../types';
import { TextSourceStylingEditable } from './components';
import { getRenderLeaf } from '../../utils';
import { TEXT_SOURCE_STYLING_PLUGIN_ID } from './types';
import type {
  TextSourceStylingPluginOptions,
  TextSourceStylingPluginPropertyOptions,
} from './types';

export const TextSourceStylingPlugin = ({
  component = TextSourceStylingEditable,
  pluginName = 'TextSourceStyling',
  ...rest
}: TextSourceStylingPluginOptions = {}): EditorPlugin => {
  const options: TextSourceStylingPluginPropertyOptions = {
    pluginID: TEXT_SOURCE_STYLING_PLUGIN_ID,
    pluginName,
    component,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    renderLeaf: getRenderLeaf(options),
  };
};

import { Editor, Node } from '../core';
import { getNavigationNodeTypes } from '../constants';
import { Element } from 'slate';

export const getLowestBracket = (
  editor: Editor,
  {
    reverse,
    textEmpty,
    ignoreMergeFields,
  }: {
    reverse?: boolean;
    textEmpty?: boolean;
    ignoreMergeFields?: boolean;
  } = {
    reverse: false,
    textEmpty: false,
    ignoreMergeFields: false,
  }
) => {
  const navigationNodeTypes = getNavigationNodeTypes(ignoreMergeFields);

  const nodeEntries = Editor.nodes(editor, {
    at: [],
    match: (n) =>
      Element.isElement(n) &&
      navigationNodeTypes.includes(n.type) &&
      (textEmpty !== true || Node.string(n) === ''),
    mode: 'lowest',
    reverse,
  });

  return nodeEntries.next().value;
};

import { Switch, MenuItem } from '@material-ui/core';
import Text from 'common/ui/Text';
import { Stack, Spacer } from 'common/ui/Layout';
import { useCallback } from 'react';
import SettingsDropdown from 'common/SettingsDropdown';
import { useSeriesSkipSettings } from 'hooks/useSeriesSkipSettings';

const SERIES_SKIP_AMOUNT_TEXT = 'Series skip amount';

const SKIP_AMOUNT = [
  { value: '', name: 'Default' },
  { value: 1, name: '1' },
  { value: 2, name: '2' },
  { value: 3, name: '3' },
  { value: 4, name: '4' },
  { value: 5, name: '5' },
  { value: 6, name: '6' },
  { value: 7, name: '7' },
];

export const SkipSeriesSettings = (): React.ReactElement => {
  const [seriesSkipSettings, setSeriesSkipSettings] = useSeriesSkipSettings();

  const handleSeriesPreviousToggle = useCallback(
    async (e, eventValue: boolean) => {
      if (seriesSkipSettings != null) {
        await setSeriesSkipSettings({
          ...seriesSkipSettings,
          keepPreviousWhenAvailable: eventValue,
        });
      }
    },
    [setSeriesSkipSettings, seriesSkipSettings]
  );

  const handleAmountChange = useCallback(
    async (amount) => {
      if (seriesSkipSettings != null) {
        await setSeriesSkipSettings({ ...seriesSkipSettings, amount });
      }
    },
    [setSeriesSkipSettings, seriesSkipSettings]
  );

  return (
    <>
      <Stack alignY="center">
        <Text>{SERIES_SKIP_AMOUNT_TEXT}</Text>
        <Spacer />
      </Stack>
      <Stack alignY="center" vertical={true} alignX="start">
        <SettingsDropdown
          value={seriesSkipSettings?.amount ?? ''}
          displayEmpty
          onSelectedChange={(value) => {
            if (Array.isArray(value)) {
              return;
            }

            if (typeof value === 'string') {
              handleAmountChange(null);
            } else {
              handleAmountChange(value);
            }
          }}
        >
          {SKIP_AMOUNT.map((option) => (
            <MenuItem
              divider={option.value === ''}
              id={`select-menuitem-${option.value}`}
              value={option.value}
              key={option.value}
              data-testid={`${option.value}-menu-item`}
            >
              {option.name}
            </MenuItem>
          ))}
        </SettingsDropdown>
      </Stack>
      <Stack alignY="center" css={{ paddingLeft: '16px' }}>
        <Text>Keep displaying previous images when there's extra space after skipping</Text>
        <Spacer />
        <Switch
          checked={seriesSkipSettings?.keepPreviousWhenAvailable ?? false}
          onChange={handleSeriesPreviousToggle}
          inputProps={{ 'aria-label': SERIES_SKIP_AMOUNT_TEXT }}
        />
      </Stack>
    </>
  );
};

import { useQuery } from '@apollo/client';
import type { QueryHookOptions, QueryResult } from '@apollo/client';
import type { GetMeQuery, GetMeQueryVariables } from 'generated/graphql';
import { GET_ME } from 'modules/Apollo/queries';

export function useCurrentUser(
  options?: QueryHookOptions<GetMeQuery, GetMeQueryVariables>
): QueryResult<GetMeQuery, GetMeQueryVariables> {
  return useQuery<GetMeQuery, GetMeQueryVariables>(GET_ME, options);
}

import analytics from 'modules/analytics';
import { globalContext } from 'modules/analytics/constants';
import { Cache } from './precacheLink';
import type { Json } from 'generated/graphql';
import type { DocumentNode } from '@apollo/client';

export async function emitPrecacheStatus<T extends Json>(
  query: DocumentNode,
  variables: T
): Promise<void> {
  const cache = await Cache.open();
  // @ts-expect-error [EN-7967] - TS2339 - Property 'name' does not exist on type 'DefinitionNode'.
  const operationKey = query.definitions[0].name.value + JSON.stringify(variables);
  const operationUrl = new URL(operationKey, window.location.origin);
  // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'URL' is not assignable to parameter of type 'RequestInfo'.
  const cacheResponse = await cache.match(operationUrl);
  analytics.addContext(globalContext.viewer.precacheStatus, {
    source: cacheResponse ? 'precache' : 'network',
    // @ts-expect-error [EN-7967] - TS2339 - Property 'worklistIds' does not exist on type 'T'.
    worklistIds: variables.worklistIds,
  });
}

import { useCallback } from 'react';
import { useQuery, useMutation, ApolloCache } from '@apollo/client';
import { assocPath } from 'ramda';
import {
  GET_TOOL_PREFERENCES,
  UPDATE_BAR_PREFERENCES,
  UPDATE_RESET_TOOL_ON_SCROLL_PREFERENCES,
  UPDATE_MIRROR_VIEWER_LAYOUTS_PREFERENCES,
  UPDATE_SHOW_ICON_LABEL_PREFERENCES,
} from 'modules/Apollo/queries';
import { getToolById } from './allTools';
import type { ToolID, IconName, Tool } from './allTools';
import { getKeyboardShortcutById } from './keyboardShortcuts';
import type {
  GetToolPreferencesQuery,
  GetToolPreferencesQueryVariables,
  UpdateBarPreferencesMutation,
  UpdateBarPreferencesMutationVariables,
} from 'generated/graphql';

export { useEnabledTools } from './useEnabledTools';

export type Preference = {
  id: ToolID;
  description: string;
  iconName: IconName;
  source?: Tool['source'];
  category: Tool['category'];
  svgIcon?: string;
  lockedKey?: boolean;
};

export * from './allTools';
export * from './keyboardShortcuts';

export const getPreferenceById = async (id: string): Promise<Preference | null | undefined> =>
  (await getToolById(id)) || getKeyboardShortcutById(id);

export type ToolPreferences = GetToolPreferencesQuery['toolPreferences'];

export const useToolPreferences = (): [
  ToolPreferences | null | undefined,
  (type: keyof ToolPreferences, payload: Array<string>) => void,
] => {
  const { data } = useQuery<GetToolPreferencesQuery, GetToolPreferencesQueryVariables>(
    GET_TOOL_PREFERENCES,
    { fetchPolicy: 'cache-first' }
  );

  const [updateBarPreferences] = useMutation<
    UpdateBarPreferencesMutation,
    UpdateBarPreferencesMutationVariables
  >(UPDATE_BAR_PREFERENCES, {
    update(cache: ApolloCache<unknown>, { data }) {
      const updateBarPreferences = data?.updateBarPreferences;
      const toolPreferencesCache = cache.readQuery<
        GetToolPreferencesQuery,
        GetToolPreferencesQueryVariables
      >({ query: GET_TOOL_PREFERENCES });
      const toolPreferences = toolPreferencesCache?.toolPreferences;
      cache.writeQuery({
        query: GET_TOOL_PREFERENCES,
        data: {
          toolPreferences: {
            ...toolPreferences,
            bar: {
              ...toolPreferences?.bar,
              // @ts-ignore [exponential-spread]
              ...updateBarPreferences,
            },
          },
        },
      });
    },
  });

  const toolPreferences = data?.toolPreferences;

  const setBarPreferences = useCallback(
    (toolIds: Array<string>) => {
      updateBarPreferences({
        variables: {
          ids: toolIds,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateBarPreferences: {
            __typename: 'BarPreferences',
            toolIds,
          },
        },
      });
    },
    [updateBarPreferences]
  );

  const setToolPreferences = useCallback(
    (type: keyof ToolPreferences, payload: Array<string>) => {
      switch (type) {
        case 'bar':
          setBarPreferences(payload);
          break;
        default:
          break;
      }
    },
    [setBarPreferences]
  );

  return [toolPreferences, setToolPreferences];
};

export const useResetToolOnScrollPreferences = (): [boolean, (arg1: boolean) => void] => {
  const { data } = useQuery(GET_TOOL_PREFERENCES, { fetchPolicy: 'cache-first' });
  const enabled = data?.toolPreferences.resetToolOnScroll.enabled ?? true;
  const [updateValue] = useMutation(UPDATE_RESET_TOOL_ON_SCROLL_PREFERENCES);

  const set = useCallback(
    (enabled: boolean) => {
      updateValue({
        variables: { enabled },
        optimisticResponse: {
          __typename: 'Mutation',
          updateResetToolOnScrollPreferences: {
            __typename: 'ResetToolOnScrollPreferences',
            enabled,
          },
        },
        update: (proxy) => {
          proxy.writeQuery({
            query: GET_TOOL_PREFERENCES,
            data: assocPath(['toolPreferences', 'resetToolOnScroll', 'enabled'], enabled, data),
          });
        },
      });
    },
    [data, updateValue]
  );

  return [enabled, set];
};

export const useMirrorViewerLayoutsPreferences = (): [boolean, (arg1: boolean) => void] => {
  const { data } = useQuery(GET_TOOL_PREFERENCES, { fetchPolicy: 'cache-first' });
  const enabled = data?.toolPreferences.mirrorViewerLayouts.enabled ?? true;
  const [updateValue] = useMutation(UPDATE_MIRROR_VIEWER_LAYOUTS_PREFERENCES);

  const set = useCallback(
    (enabled: boolean) => {
      updateValue({
        variables: { enabled },
        optimisticResponse: {
          __typename: 'Mutation',
          updateMirrorViewerLayoutsPreferences: {
            __typename: 'MirrorViewerLayoutsPreferences',
            enabled,
          },
        },
        update: (proxy) => {
          proxy.writeQuery({
            query: GET_TOOL_PREFERENCES,
            data: assocPath(['toolPreferences', 'mirrorViewerLayouts', 'enabled'], enabled, data),
          });
        },
      });
    },
    [data, updateValue]
  );

  return [enabled, set];
};

export const useIconLabelPreferences = (): [boolean, (arg1: boolean) => void] => {
  const { data } = useQuery(GET_TOOL_PREFERENCES, { fetchPolicy: 'cache-first' });
  const enabled = data?.toolPreferences.showIconLabel.enabled ?? false;
  const [updateValue] = useMutation(UPDATE_SHOW_ICON_LABEL_PREFERENCES);

  const set = useCallback(
    (enabled: boolean) => {
      updateValue({
        variables: { enabled },
        optimisticResponse: {
          __typename: 'Mutation',
          updateShowIconLabelPreferences: {
            __typename: 'ShowIconLabelPreferences',
            enabled,
          },
        },
        update: (proxy) => {
          proxy.writeQuery({
            query: GET_TOOL_PREFERENCES,
            data: assocPath(['toolPreferences', 'showIconLabel', 'enabled'], enabled, data),
          });
        },
      });
    },
    [data, updateValue]
  );

  return [enabled, set];
};

// Fallback used when the `GET_TOOL_PREFERENCES` query hasn't returned my data yet
const PLACEHOLDER_TOOL_PREFERENCES = {
  mouse: {
    left: '',
    middle: '',
    right: '',
  },
  wheel: {
    toolIds: [],
  },
  keyboard: {
    shortcuts: [],
  },
  bar: {
    toolIds: [],
  },
} as const;

export const withToolPreferences = <T extends unknown>(
  Component: React.ComponentType<T>
): ((arg1: T) => React.ReactElement) =>
  function WithToolPreferences(props) {
    const [toolPreferences, setToolPreferences] = useToolPreferences();

    return (
      <Component
        toolPreferences={toolPreferences || PLACEHOLDER_TOOL_PREFERENCES}
        setToolPreferences={setToolPreferences}
        // @ts-expect-error [EN-7967] - TS2698 - Spread types may only be created from object types.
        {...props}
      />
    );
  };

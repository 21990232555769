import { Flow } from 'flow-to-typescript-codemod';
import Appbar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'clsx';
import styles from './styles';
import { css } from 'styled-components';
import { SIDENAV_WIDTH } from 'domains/reporter/RichTextEditor/plugins/inlineBookmark/constants';
import { Spacings } from 'styles';

const defaultContent = <Typography>SIRONA</Typography>;

type GenericAppBarProps = {
  classes: typeof styles;
  content?: React.ReactNode;
  children?: React.ReactNode;
  fixed?: boolean;
  className?: string;
};

/**
 * Reusable generic app bar component with consistent styling throughout app
 * A variant of this should appear at the top of every view
 *
 */
function GenericAppBar({
  classes,
  content = defaultContent,
  children,
  fixed = false,
  className,
}: GenericAppBarProps) {
  return (
    <Appbar
      className={classNames(classes.appbar, className)}
      position={fixed ? 'fixed' : 'relative'}
      elevation={0}
      css={css`
        app-region: drag;
        padding-left: calc(env(titlebar-area-x, 0) - ${SIDENAV_WIDTH}px + ${Spacings.medium}px);
      `}
    >
      {/* @ts-expect-error [EN-7967] - TS2769 - No overload matches this call. */}
      <Toolbar disableGutters={true} variant="dense" classes={{ root: classes.toolbar }}>
        {children ?? content}
      </Toolbar>
    </Appbar>
  );
}

const GenericAppBarWithStyles: Flow.AbstractComponent<
  Partial<
    Flow.Diff<
      GenericAppBarProps,
      {
        classes: typeof styles;
      }
    >
  >,
  unknown
  // @ts-expect-error [EN-7967] - TS2345 - Argument of type '({ classes, content, children, fixed, className, }: GenericAppBarProps) => Element' is not assignable to parameter of type 'ComponentType<ConsistentWith<GenericAppBarProps, { classes: ClassNameMap<"toolbar" | "menuButton" | "appbar">; }>>'.
> = withStyles(styles)(GenericAppBar);

export default GenericAppBarWithStyles;

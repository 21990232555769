import { Editor } from 'domains/reporter/RichTextEditor/core';
import { useFields } from './Fields.Context';
import type { FieldsProviderProps } from './Fields.Context';
import { FieldsEditor } from './Fields.Editor';
import { useRecoilValue } from 'recoil';
import { reportStatusState, SECTION_EDITOR_EDITABLE_STATUSES } from '../state';
import { Stack } from 'common/ui/Layout';
import { css } from 'styled-components';
import type { OnStableText } from '../../RichTextEditor/plugins/dictation/types';
import type { AutoloadTemplateState } from 'hooks/useAutoloadTemplate';

type Props = {
  isDisabled: boolean;
  onInit?: FieldsProviderProps['onInit'];
  onDestroy?: FieldsProviderProps['onDestroy'];
  autoloadTemplateState?: AutoloadTemplateState;
  aiMode?: boolean;
  onStableText?: OnStableText;
  editor?: Editor | null | undefined;
  isLoading?: boolean;
};

export const FieldsSectionEditor = ({
  isDisabled,
  onInit,
  onDestroy,
  autoloadTemplateState,
  aiMode = false,
  onStableText,
  editor,
  isLoading = false,
}: Props): React.ReactElement => {
  const [{ content, editorKey }, { onReportChange }] = useFields();
  const status = useRecoilValue(reportStatusState);

  return (
    <Stack
      data-stack-stretch
      css={css`
        max-height: 100%;
        overflow: hidden;
      `}
      space="small"
      stretchX
      stretchY
    >
      <FieldsEditor
        key={editorKey}
        editor={editor}
        content={content}
        onChange={onReportChange}
        isDisabled={isDisabled}
        isLoading={isLoading}
        onInit={onInit}
        onDestroy={onDestroy}
        autoloadTemplateState={autoloadTemplateState}
        aiMode={aiMode}
        onStableText={onStableText}
        // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'ReportStatuses' is not assignable to parameter of type '"idle" | "submitting"'.
        variant={SECTION_EDITOR_EDITABLE_STATUSES.includes(status) ? 'report' : 'view'}
      />
    </Stack>
  );
};

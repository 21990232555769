import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useOpenTabs } from './useOpenTabs';
import { useCurrentCaseId } from './useCurrentCase';
import { useCurrentCaseClaimedStatus } from './useCurrentCaseClaimedStatus';
import { PAGE_TYPES } from '../utils/pageTypes';
import { usePrevious } from 'react-use';
import { useMutation } from '@apollo/client';
import { CREATE_WORKLIST_ITEM_ACTIVITY } from '../modules/Apollo/queries';
import { logger } from '../modules/logger';

export const HEARTBEAT_FREQUENCY = 30000;

export const useReporterHeartbeat = ({
  customSendHeartbeat,
  heartbeatFrequency = HEARTBEAT_FREQUENCY,
}: {
  customSendHeartbeat?: () => void;
  heartbeatFrequency?: number;
}) => {
  const currentCaseId = useCurrentCaseId();

  const previousCaseId = usePrevious(currentCaseId);
  const { isClaimedByMe } = useCurrentCaseClaimedStatus();
  const openTabs = useOpenTabs();
  const intervalRef = useRef<number | null | undefined>(null);

  const isReporterOpen = useMemo(
    () => openTabs.some((tab) => tab.type === PAGE_TYPES.REPORTER),
    [openTabs]
  );

  const [createWorklistItemActivityMutation] = useMutation(CREATE_WORKLIST_ITEM_ACTIVITY);

  /**
   * Send worklist item activity to keep the case active
   */
  const sendHeartbeat = useCallback(async () => {
    if (customSendHeartbeat) {
      customSendHeartbeat();
    } else {
      if (currentCaseId != null) {
        await createWorklistItemActivityMutation({
          variables: { smid: currentCaseId },
          onError: (error) => {
            logger.error(
              `[useReporterHeartbeat] - request to create worklistItem activity for caseId ${currentCaseId} failed.`,
              error
            );
          },
        });
      }
    }
  }, [currentCaseId, customSendHeartbeat, createWorklistItemActivityMutation]);

  useEffect(() => {
    if (isReporterOpen && currentCaseId != null && isClaimedByMe === true) {
      if (!intervalRef.current || previousCaseId !== currentCaseId) {
        sendHeartbeat(); // Send heartbeat immediately if no interval is active or case ID changes
      }

      intervalRef.current = setInterval(sendHeartbeat, heartbeatFrequency) as unknown as number;

      return () => {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      };
    }
  }, [
    currentCaseId,
    isClaimedByMe,
    isReporterOpen,
    sendHeartbeat,
    previousCaseId,
    heartbeatFrequency,
  ]);

  return { sendHeartbeat };
};

import { forwardRef } from 'react';
import styled from 'styled-components';
import { Colors } from 'styles';

const Icon = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.gray7};
  margin-right: 5px;
`;

const BACKGROUND_COLOR = {
  light: Colors.gray4,
  dark: Colors.accountInputBackground,
} as const;

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode;
  customPrefix?: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  borderColor?: string;
  variant?: 'light' | 'dark';
  inputRef?: {
    current: null | HTMLInputElement;
  };
}

const WrappedInput = forwardRef(function ForwardedWrappedInput(
  {
    icon,
    className,
    customPrefix,
    variant,
    fullWidth,
    borderColor,
    inputRef,
    ...props
  }: InputProps,
  ref:
    | ((arg1: null | HTMLDivElement) => unknown)
    | {
        current: null | HTMLDivElement;
      }
) {
  return (
    <div ref={ref} className={className}>
      {icon != null && <Icon>{icon}</Icon>}
      {customPrefix}
      <input {...props} ref={inputRef} />
    </div>
  );
});

const Input = styled(WrappedInput as React.ComponentType<InputProps>)`
  display: inline-flex;
  align-items: center;
  border-radius: 50px;
  height: 3.5rem;
  background-color: ${(props: InputProps) => BACKGROUND_COLOR[props.variant || 'light']};
  color: ${Colors.gray9};
  border: ${(props: InputProps) =>
    props.borderColor != null ? `1px solid ${props.borderColor}` : `1px solid ${Colors.gray6}`};
  padding: 0 15px;
  width: ${(props: InputProps) => (props.fullWidth === true ? '100%' : '')};
  outline: 0;

  &:focus-within {
    border-color: ${Colors.blue3};
  }

  input {
    all: unset;
    align-self: stretch;
    flex: 1;
    display: inline-block;

    &::placeholder {
      color: ${Colors.gray8};
    }
  }
`;

export const SearchInput = styled(Input)`
  padding: 1px 6px;
  height: unset;
  background-color: ${Colors.gray2};

  svg {
    height: 15px;
    width: 15px;
  }
`;

export default Input;

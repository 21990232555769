/*eslint no-use-before-define: ["error", { "variables": false }]*/

import { atom } from 'recoil';
import type { RecoilState } from 'recoil';
import type { ViewportState } from './recoilTypes';
import { broadcastChannelSynchronizerEffect, copyToRefEffect } from 'utils/recoilEffects';
import type { CategoryLabel } from 'domains/viewer/AnnotationsManager';
import { generateViewportId } from '../domains/viewer/Viewer/viewerUtils';

export const viewerMessageState: RecoilState<
  | {
      title: string;
      description: string;
      buttonText: string;
      to: string;
      onClick?: () => unknown;
    }
  | null
  | undefined
> = atom({
  key: 'viewerMessageState',
  default: null,
});

export const viewportState: RecoilState<ViewportState> = atom({
  key: 'viewportState',
  default: {},
  dangerouslyAllowMutability: true,
});
const defaultViewport: string = generateViewportId(0, 0, 0); // flow is being weird about the return type of this function
export const activeViewportRef = { current: defaultViewport } as const;
export const activeViewportState: RecoilState<string> = atom({
  key: 'activeViewportState',
  default: defaultViewport,
  effects: [broadcastChannelSynchronizerEffect(), copyToRefEffect(activeViewportRef)],
});

export const ReporterSaveState: {
  Saved: 'saved';
  Saving: 'saving';
  Error: 'error';
} = Object.freeze({
  Saved: 'saved',
  Saving: 'saving',
  Error: 'error',
});

export type ReporterSaveStateType = (typeof ReporterSaveState)[keyof typeof ReporterSaveState];

export const reporterSavingState: RecoilState<ReporterSaveStateType> = atom({
  key: 'reporterSavingState',
  default: ReporterSaveState.Saved,
});

export const passwordResetSuccessfulState: RecoilState<boolean> = atom({
  key: 'passwordResetSuccessfulState',
  default: false,
});

export const annotationsConfigState: RecoilState<{
  descending: boolean;
  last: CategoryLabel | null | undefined;
  setDescending: (arg1: boolean) => void;
  setCategory: (arg1: CategoryLabel) => void;
}> = atom({
  key: 'annotationsConfigState',
  default: {
    descending: true,
    last: null,
    setDescending: () =>
      console.warn('Tried to set descending before the state was initialized. This is a noop.'),
    setCategory: () =>
      console.warn('Tried to set category before the state was initialized. This is a noop.'),
  },
});

export const isDraggingThumbnailAtom: RecoilState<boolean> = atom({
  key: 'viewer.thumbnail.isDragging',
  default: false,
  effects: [broadcastChannelSynchronizerEffect({ unidirectional: true })],
});

export const keyboardModifiers: RecoilState<{
  shiftKey: boolean;
  ctrlKey: boolean;
  altKey: boolean;
}> = atom({
  key: 'viewer.keyboardModifiers',
  default: {
    shiftKey: false,
    ctrlKey: false,
    altKey: false,
  },
  effects: [broadcastChannelSynchronizerEffect({ unidirectional: true })],
});

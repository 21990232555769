const sizes = {
  toolbarHeight: '6rem',
  toolbarButtonHeight: '3.2rem',
  buttonText: '0.875rem',
  toolbarItemSpacing: '1rem',
  textSmall: '1.1rem',
  textBody1: '1.4rem',
  textBody2: '1.3rem',
  textButton: '1.5rem',
  textCaption: '1.2rem',
  textDisplay1: '1.6rem',
  textDisplay2: '2.2rem',
  textDisplay3: '3.0rem',
  h1: '4.8rem',
  h2: '3.6rem',
  textOverline: '0.9rem',
  textSubhead: '1.1rem',
  textTitle: '1.2rem',
  reporterFontSize: {
    small: '1.2rem',
    medium: '1.4rem',
    large: '1.6rem',
  },
  display1: '1.6rem',
} as const;

export default sizes;

import { Range, Editor } from 'domains/reporter/RichTextEditor/core';
import { useContext, createContext, useState } from 'react';
import type { PluginsBag } from '../RichTextEditor/hooks';

type SlateSingletonContextContextProps = Readonly<{
  children: React.ReactNode;
}>;

type SlateSingletonState = {
  editor: Editor | null;
  pluginsBag: PluginsBag | null;
};

type SlateSingletonContextContextBag = [
  SlateSingletonState,
  (arg1: ((arg1: SlateSingletonState) => SlateSingletonState) | SlateSingletonState) => void,
];

const SlateSingletonContextContext = createContext<
  SlateSingletonContextContextBag | null | undefined
>(undefined);

export const SlateSingletonContextProvider = ({
  children,
}: SlateSingletonContextContextProps): React.ReactElement => {
  const bag = useState<SlateSingletonState>({ editor: null, pluginsBag: null });

  return (
    <SlateSingletonContextContext.Provider value={bag}>
      {children}
    </SlateSingletonContextContext.Provider>
  );
};

/**
 * Exposes Slate singleton access above the reporter's location in the component tree. Use if you
 * are above the reporter and need to run operations on the document. If you are inside of the reporter,
 * useSlate or useEditor from RichTextEditor/core
 */
export const useSlateSingletonContext = (): SlateSingletonContextContextBag => {
  const editor = useContext(SlateSingletonContextContext);

  if (!editor) {
    throw new Error(
      'useSlateSingletonContext must be used within a SlateSingletonContextProvider.'
    );
  }

  return editor;
};

type SlateSelectionSingletonState = {
  selection: Range | null;
};

type SlateSelectionSingletonContextContextBag = [
  SlateSelectionSingletonState,
  (
    arg1:
      | ((arg1: SlateSelectionSingletonState) => SlateSelectionSingletonState)
      | SlateSelectionSingletonState
  ) => void,
];

const SlateSelectionSingletonContext = createContext<
  SlateSelectionSingletonContextContextBag | null | undefined
>(undefined);

export const SlateSelectionSingletonContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const bag = useState<SlateSelectionSingletonState>({ selection: null });

  return (
    <SlateSelectionSingletonContext.Provider value={bag}>
      {children}
    </SlateSelectionSingletonContext.Provider>
  );
};

export const useSlateSelectionSingletonContext = (): SlateSelectionSingletonContextContextBag => {
  const selection = useContext(SlateSelectionSingletonContext);
  if (!selection) {
    throw new Error(
      'useSlateSelectionSingletonContext must be used within a SlateSelectionSingletonContextProvider.'
    );
  }
  return selection;
};

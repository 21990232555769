import { Colors } from 'styles';
import type { ColorsValueType } from 'styles/colors';
import { slateSource } from 'domains/reporter/RichTextEditor/core/slateSource';
import { clone } from 'ramda';
import { walkSlateContent } from 'domains/reporter/RichTextEditor/utils';
import { Text } from '../../core';
import type { SlateContent } from '../../../Reporter/types';

export const getSourceTextColor = (source?: string | null): ColorsValueType => {
  switch (source) {
    case slateSource.ai.focusMode:
    case slateSource.ai.impressionGenerator:
      return Colors.aiGreen;
    case slateSource.user.dictation:
      return Colors.orange5;
    case slateSource.user.keyboard:
      return Colors.yellow5;
    default:
      return Colors.white;
  }
};

export const annotateTextWithSource = (
  content: SlateContent,
  source?: string | null
): SlateContent => {
  if (content == null) {
    return content;
  }

  const clonedContent = clone(content);
  walkSlateContent(
    (el) => {},
    (textNode) => {
      if (Text.isText(textNode)) {
        // @ts-expect-error [EN-7967] - TS2339 - Property 'source' does not exist on type 'BaseText'.
        textNode.source = source;
      }
    }
  )(clonedContent);

  return clonedContent;
};

import { AppRoutePath, AppRedirectPath } from 'config/constants';
import { ADMIN_URL } from 'config';

import { Suspense, lazy } from 'react';

import RequestPasswordReset from '../../UserMgmt/RequestPasswordReset';
import PasswordReset from '../../UserMgmt/CompletePasswordReset';
import FourOFour from '../../404';
import {
  Route,
  Navigate,
  useLocation,
  Outlet,
  Routes,
  useParams,
  generatePath,
} from 'react-router-dom';
import type { Location } from 'react-router-dom';
import BackgroundRoute from './BackgroundRoute';
import RouteElement from './RouteElement';
import { getCaseStudyParamsSuffix, useCaseStudyParamsSuffix } from 'hooks/studyParams';

const LoginComponent = lazy(() => import('../../UserMgmt/LoginComponent'));

const MFALoginComponent = lazy(() => import('../../UserMgmt/MFALoginComponent'));

const Signup = lazy(() => import('../../UserMgmt/Signup'));

const Logout = lazy(() => import('../../UserMgmt/Logout'));
const Worklist = lazy(() => import('../../../domains/worklist/Worklist'));
const Viewer = lazy(() => import('../../../domains/viewer/Viewer'));
const OpenWorker = lazy(() => import('domains/viewer/Viewer/OpenPixelDataSharedWorker'));
const Debug = lazy(() => import('../../../domains/viewer/Debug'));
const SystemCheck = lazy(() => import('../../SystemCheck/SystemCheckRoute'));

const Reporter = lazy(() => import('../../../domains/reporter/Reporter'));
const TemplateEditor = lazy(() => import('../../../domains/reporter/Template/Template.Editor'));
const MacroEditor = lazy(() => import('../../../domains/reporter/Macro/Editor'));

const URT = lazy(() => import('../../../domains/urt/URT'));

const ProductPortal = lazy(() => import('../../ProductPortal'));
const ReadCase = lazy(() => import('../../External/ReadCase'));
const TemplatesBrowser = lazy(() => import('../../../domains/reporter/Browser/TemplatesBrowser'));
const MacrosBrowser = lazy(() => import('../../../domains/reporter/Browser/MacrosBrowser'));

const PatientJacket = lazy(() =>
  import('../../../domains/worklist/Worklist/PatientJacket').then((module) => ({
    default: module.PatientJacket,
  }))
);

const Admin = () => {
  window.location.href = ADMIN_URL;
  return null;
};

export function AppRoutes(): React.ReactElement {
  const routes = (
    <Route path="" element={<BackgroundRoute />}>
      <Route
        path={AppRoutePath.LOGIN}
        element={
          <RouteElement title="Login">
            <LoginComponent />
          </RouteElement>
        }
      />
      <Route
        path={AppRoutePath.VERIFY_MFA}
        element={
          <RouteElement title="Login">
            <MFALoginComponent />
          </RouteElement>
        }
      />
      {/**
       * Apollo can't set the useAuth Recoil state needed to logout
       * the user from the UI, we use this route to do it.
       */}
      <Route
        path={AppRoutePath.LOGOUT}
        element={
          <RouteElement title="Logout">
            <Logout />
          </RouteElement>
        }
      />
      <Route
        path={AppRoutePath.CONFIRM_ACCOUNT}
        element={
          <RouteElement title="Confirm Account">
            <Signup />
          </RouteElement>
        }
      />
      <Route
        path={AppRoutePath.RESET_PASSWORD}
        element={
          <RouteElement title="Password Reset">
            <RequestPasswordReset />
          </RouteElement>
        }
      />
      <Route
        path={AppRoutePath.COMPLETE_RESET_PASSWORD}
        element={
          <RouteElement title="Password Reset">
            <PasswordReset />
          </RouteElement>
        }
      />
      <Route
        path={AppRoutePath.WORKLIST}
        element={
          <RouteElement title="Worklist">
            <Worklist />
          </RouteElement>
        }
      >
        <Route
          path={AppRoutePath.PATIENT_JACKET}
          element={
            <RouteElement>
              <PatientJacket />
            </RouteElement>
          }
        />
      </Route>
      <Route
        path={AppRoutePath.PATIENT_JACKET_UNDOCKED}
        element={
          <RouteElement title="Patient Jacket">
            <PatientJacket />
          </RouteElement>
        }
      />
      <Route
        path={AppRoutePath.VIEWER}
        element={
          <RouteElement title="Viewer">
            <Viewer />
          </RouteElement>
        }
      />
      <Route path={AppRoutePath.ADMIN} element={<Admin />} />
      <Route
        path={AppRoutePath.REPORTER_TEMPLATE_EDIT}
        element={
          <RouteElement title="Reporter Template">
            <TemplateEditor />
          </RouteElement>
        }
      />
      <Route
        path={AppRoutePath.REPORTER_TEMPLATE_CLONE}
        element={
          <RouteElement title="Reporter Template">
            <TemplateEditor />
          </RouteElement>
        }
      />
      <Route
        path={AppRoutePath.REPORTER_TEMPLATE_NEW}
        element={
          <RouteElement title="Reporter Template">
            <TemplateEditor />
          </RouteElement>
        }
      />
      <Route
        path={AppRoutePath.REPORTER_MACRO_EDIT}
        element={
          <RouteElement>
            <MacroEditor />
          </RouteElement>
        }
      />
      <Route
        path={AppRoutePath.REPORTER_MACRO_CLONE}
        element={
          <RouteElement>
            <MacroEditor />
          </RouteElement>
        }
      />
      <Route
        path={AppRoutePath.REPORTER_MACRO_NEW}
        element={
          <RouteElement>
            <MacroEditor />
          </RouteElement>
        }
      />
      <Route
        path={AppRoutePath.REPORTER}
        element={
          <RouteElement title="Reporter">
            <ReporterElement />
          </RouteElement>
        }
      ></Route>
      <Route
        path={AppRoutePath.URT}
        element={
          <RouteElement title="URT">
            <URT />
          </RouteElement>
        }
      />
      <Route
        path={AppRoutePath.PRODUCTPORTAL}
        element={
          <RouteElement title="Product Portal">
            <ProductPortal />
          </RouteElement>
        }
      />
      <Route
        path={`${AppRoutePath.EXTERNAL}/read-case`}
        element={
          <RouteElement title="Opening from outside">
            <ReadCase />
          </RouteElement>
        }
      />
      <Route
        path={AppRedirectPath.VIEWER}
        element={<BackwardsCompatibilityRedirect path={AppRoutePath.VIEWER} />}
      />
      <Route
        path={AppRedirectPath.REPORTER}
        element={<BackwardsCompatibilityRedirect path={AppRoutePath.REPORTER} />}
      />
      <Route path={AppRoutePath.LOGIN} element={<Navigate to={AppRoutePath.WORKLIST} />} />
      <Route
        path={AppRoutePath.PIXEL_WORKER}
        element={
          <RouteElement title="Worker">
            <OpenWorker />
          </RouteElement>
        }
      />
      <Route path="/" element={<Navigate to={AppRoutePath.WORKLIST} />} />
      {/* This is needed to ensure old bookmarks keep working */}
      <Route path={AppRoutePath.HOME} element={<Navigate to={AppRoutePath.WORKLIST} />} />
      <Route
        path="*"
        element={
          <RouteElement>
            <FourOFour />
          </RouteElement>
        }
      />

      <Route
        path={AppRoutePath.SYSTEM_CHECK}
        element={
          <RouteElement title="System Check">
            <SystemCheck />
          </RouteElement>
        }
      />
      <Route
        path={AppRoutePath.DEBUG}
        element={
          <RouteElement title="Debug">
            <Debug />
          </RouteElement>
        }
      />
    </Route>
  );

  return <Routes>{routes}</Routes>;
}

const ReporterElement = () => {
  const location = useLocation();
  const closePath = `.${useCaseStudyParamsSuffix()}`;

  return (
    <>
      <Reporter />
      <Outlet />
      <Suspense>
        <BrowserPath
          browser={<TemplatesBrowser closePath={closePath} />}
          hash="#report-templates-browser"
          location={location}
        />
        <BrowserPath
          browser={<MacrosBrowser closePath={closePath} />}
          hash="#report-macros-browser"
          location={location}
        />
      </Suspense>
    </>
  );
};

type BackwardsCompatibilityRedirectProps = {
  path: string;
};

const BackwardsCompatibilityRedirect = ({ path }: BackwardsCompatibilityRedirectProps) => {
  const params = useParams();
  const studyIds = params.worklistId.split(',');
  const { worklistId, priorIds } = studyIds.reduce(
    ({ worklistId, priorIds }, studyId) => {
      if (studyId.startsWith('w_')) {
        worklistId = studyId.slice(2);
      } else if (studyId.startsWith('s_')) {
        priorIds.push(studyId.slice(2));
      }
      return { worklistId, priorIds };
    },
    { worklistId: undefined, priorIds: [] }
  );
  const priorsSuffix = getCaseStudyParamsSuffix(priorIds.join(','), '');
  const newPath = `${generatePath(path, { ...params, worklistId })}${priorsSuffix}`;
  return <Navigate replace to={newPath} />;
};

const BrowserPath = ({
  browser,
  hash,
  location,
}: {
  browser: React.ReactNode;
  hash: string;
  location?: Location;
}) => (location?.hash === hash ? browser : null);

import { Editor } from 'domains/reporter/RichTextEditor/core';

import type { EditorElement, EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';
import { PAGE_TYPES } from 'utils/pageTypes';
import type { Key } from 'react';
import {
  PointDeepLinkElement,
  SliceDeepLinkElement,
  StudyDeepLinkElement,
} from '../../slate-custom-types';

export const DEEP_LINK_PLUGIN_ID: 'deepLink' = 'deepLink';
export type DeepLinkPluginID = typeof DEEP_LINK_PLUGIN_ID;

type SliceDeepLinkProps = {
  target: typeof PAGE_TYPES.VIEWER;
} & SliceDeepLinkElement;

type PointDeepLinkProps = {
  target: typeof PAGE_TYPES.VIEWER;
} & PointDeepLinkElement;

type StudyDeepLinkProps = {
  target: typeof PAGE_TYPES.VIEWER;
} & StudyDeepLinkElement;

export type DeepLink = SliceDeepLinkProps | PointDeepLinkProps | StudyDeepLinkProps;

export type DeepLinkPluginElement = EditorElement &
  DeepLink & {
    type: DeepLinkPluginID;
  };

export type DeepLinkPluginOptions = Readonly<
  EditorPluginOptions & {
    // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
    component?: React.ComponentType<any>;
    ignoreMergeFieldsInNavigation?: boolean;
    isAutoFillComparisonEnabled?: boolean;
    onDeepLinkClick?: (element: DeepLinkPluginElement | null, editor: Editor | null) => void;
    onDeepLinkEditClick?: (element: DeepLinkPluginElement | null, editor: Editor | null) => void;
    getIsEditingStudyNode?: (key: Key) => boolean;
  }
>;

export type DeepLinkPluginPropertyOptions = Readonly<
  EditorPluginPropertyOptions &
    DeepLinkPluginOptions & {
      pluginID: DeepLinkPluginID;
      pluginName: string;
      // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
      component: React.ComponentType<any>;
    }
>;

export type DeepLinkContexts = DeepLink['context'];
export type DeepLinkTargets = DeepLink['target'];

import { PathRef, Node } from 'domains/reporter/RichTextEditor/core';
import { Transforms, Editor } from '../../core';
import { findNodeByIDs, insertSectionHeader } from './utils';
import { selectNextBracket } from '../../utils/bracketNavigation';
import { NAMESPACES, useEventsListener } from 'modules/EventsManager';

import type { CreateEnhanceProvider } from '../../types';
import { SECTION_HEADER_PLUGIN_ID } from './types';
import type { SectionHeaderPluginPropertyOptions } from './types';
import type { SlateProps } from 'slate-react';
import { Element } from 'slate';

type ProviderComponentProps = Readonly<{
  editor: Editor;
  children: React.ReactNode;
  ignoreMergeFieldsInNavigation?: boolean;
}>;

const getPathRefSafe = (pathRef: PathRef) => {
  if (pathRef?.current == null) {
    throw new Error('Cannot get path from pathRef.current of null.');
  }

  return pathRef.current;
};

/**
 * The provider component.
 *
 * Attaches an event handler when the editor is loaded, which listens for messages on the anatomic navigator broadcast channel.
 * @param {ProviderComponentProps} props - Provider component properties.
 */
const ProviderComponent = ({
  children,
  editor,
  ignoreMergeFieldsInNavigation,
  ...rest
}: ProviderComponentProps): React.ReactElement => {
  const editorHandler = handleAnatomicNavigatorCreateEvent(editor, ignoreMergeFieldsInNavigation);
  const editorUpdateHandler = handleAnatomicNavigatorUpdateEvent(editor);

  useEventsListener(NAMESPACES.INSERT_SECTION_HEADER, ({ payload }) => {
    if (payload.type === SECTION_HEADER_PLUGIN_ID && payload.conceptID != null) {
      editorHandler({
        conceptID: payload.conceptID,
        viewerRefID: payload.viewerRefID,
      });
    }
  });

  useEventsListener(NAMESPACES.UPDATE_SECTION_HEADERS, ({ payload }) => {
    if (payload.type !== 'updateSectionHeaders') {
      return;
    }
    editorUpdateHandler({
      type: payload.type,
      listOfUpdatedNodeIds: payload.listOfNodes,
    });
  });

  // @ts-expect-error [EN-7967] - TS2322 - Type 'ReactNode' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
  return children;
};

/**
 * Creates the provider component.
 *
 * @param {SectionHeaderPluginPropertyOptions} props - Plugin property options for section header
 */
export const enhanceProviderSectionHeader: CreateEnhanceProvider<
  SectionHeaderPluginPropertyOptions
> =
  ({ pluginID }) =>
  (Component: React.ComponentType<SlateProps>) =>
    function EnhanceProviderSectionHeader(props: SlateProps) {
      return (
        <ProviderComponent editor={props.editor}>
          <Component {...props} />
        </ProviderComponent>
      );
    };

/**
 * Event handler for any anatomic nav messages that come across the broadcast channel
 *
 * Curryable.
 *
 * @param {Editor} editor - Editor, which will be updated by these events
 * @param {Object} payload - Data payload from the event.
 * @param {string} conceptID
 * @param {string} sectionHeaderID
 */
const handleAnatomicNavigatorCreateEvent =
  (editor: Editor, ignoreMergeFieldsInNavigation?: boolean) =>
  ({ conceptID, viewerRefID }: { conceptID: string; viewerRefID: string }) => {
    if (conceptID == null) {
      return;
    }

    const existingNodeEntry = findNodeByIDs({ editor, conceptID, viewerRefID });

    if (!existingNodeEntry) {
      insertSectionHeader({ editor, conceptID, viewerRefID });

      const nextNodeEntry = Editor.next<Node>(editor, { mode: 'lowest' });
      if (nextNodeEntry) {
        const [, nextNodePath] = nextNodeEntry;
        Transforms.select(editor, Editor.start(editor, nextNodePath));
      } else {
        // We should always find a node since the structure demands it, but in case we don't we select the next bracket.
        selectNextBracket(editor, { ignoreMergeFieldsInNavigation });
      }
    } else {
      // If we do find this concept in the report, set focus there.
      const [, existingPath] = existingNodeEntry;

      // Select the one after the existing node.
      const nextNodeEntry = Editor.next<Node>(editor, { at: existingPath });

      if (nextNodeEntry) {
        const [, nextNodePath] = nextNodeEntry;
        Transforms.select(editor, nextNodePath);
      } else {
        // We should always find a node since the structure demands it, but in case we don't we select the end.
        Transforms.select(editor, Editor.end(editor, []));
      }
    }
  };

/**
 * find and modify updated section headers received from viewer event
 */
const handleAnatomicNavigatorUpdateEvent =
  (editor: Editor) =>
  ({
    type,
    listOfUpdatedNodeIds,
  }: {
    type: 'updateSectionHeaders';
    listOfUpdatedNodeIds: Array<{
      conceptID: string;
      viewerRefID: string;
    }>;
  }) => {
    const nodes = Array.from(
      Editor.nodes(editor, {
        at: [],
        match: (n) => Element.isElement(n) && n.type === SECTION_HEADER_PLUGIN_ID,
      })
    );

    Editor.withoutNormalizing(editor, () => {
      nodes
        .map(([node, nodePath]: [any, any]) => {
          // Create refs of every path because we are doing multiple transformations on the
          // tree at one time.
          return [node, Editor.pathRef(editor, nodePath)];
        })
        .forEach(([actualNode, actualNodePath]: [any, any]) => {
          // find if the node requires an update, because the label is modified on the viewer, post insertion of the same
          const nodeToModify = listOfUpdatedNodeIds.find(
            (node) =>
              actualNode.viewerRefID === node.viewerRefID && actualNode.conceptID !== node.conceptID
          );

          if (nodeToModify == null) return;

          /**
           * this location in segmentation after update has no particular label associated, thus having a null label
           * we delete such labels, and keep the location on the map {1:null}, so that in case of an update again and this
           * label has a valid value, we want to show, and allow user to insert it again.
           * possible questions: why do we keep null? we don't want to discard it in case the user did not mean to do such
           * update, and secondly when he corrects himself or now figures out he labelled them wrong, and wants to retrieve old or better state.
           *  */
          // if an incoming nodeId is not null post update, we replace the node otherwise we only delete the node which is invalid or null
          const isValidNode = nodeToModify.conceptID != null;

          // NOTE(mwood23): Insert has to be before delete for the path refs to work properly
          // test if it is a null label, if yes skip inserting new label in place of older one.
          if (isValidNode) {
            insertSectionHeader({
              editor,
              conceptID: nodeToModify.conceptID,
              viewerRefID: nodeToModify.viewerRefID,
              at: getPathRefSafe(actualNodePath),
            });
          }

          Transforms.removeNodes(editor, {
            at: getPathRefSafe(actualNodePath),
          });
        });
    });
  };

import { Node } from 'domains/reporter/RichTextEditor/core';
import isHotkey from 'is-hotkey';
import { Editor, Transforms } from '../../core';
import type { CreateOnKeyDown } from '../../types';
import { find } from '../../utils';
import type { LineBreakPluginPropertyOptions } from './types';

/**
 * The main behavior we need to handle is "skipping" the node when the user is
 * on either edge of the node and presses the left or right arrow key. This is to
 * make sure that the selection does not end up inside the LineBreak node, as we do not
 * want text inside the node. We also do not want the illusion of an "invisible" barrier in
 * the node. If we do not handle the case, then the following two slate documents would look
 * exactly the same in our editor, and so the user would need to press the arrow key twice to get
 * from line to the next.
 *
 * Document 1:                                            Document 2:
 * ------------------------------------------------------  ------------------------------------------------------
 * <editor>                                                 <editor>
 *   <hp>                                                     <hp>
 *     <hinlinebookmark>                                        <hinlinebookmark>
 *        <htext>hello<cursor/><htext>                            <htext/>
 *        <hlinebreak>                                            <hlinebreak>
 *          <htext/>                                                <htext><cursor/><htext>
 *        </hlinebreak>                                           </hlinebreak>
 *        <htext>goodbye</htext>                                <htext>goodbye</htext>
 *     </hinlinebookmark>                                       </hinlinebookmark>
 *   </hp>                                                     </hp>
 * </editor>                                                </editor>
 *
 * Both of these documents would render as
 *
 *       hello|
 *       goodbye
 *
 * in our reporter editor, where | is the cursor.
 *
 **/
export const onKeyDownLineBreak: CreateOnKeyDown<LineBreakPluginPropertyOptions> =
  ({ pluginID }) =>
  (e: KeyboardEvent, { editor }) => {
    /** If the user is at the right outside edge of a LineBreak node, move the selection to the
     *  end of the node to the left of the lineBreak node:
     *
     * Before Pressing Left Arrow Key:                        After Pressing Left Arrow Key:
     * ------------------------------------------------------  ------------------------------------------------------
     * <editor>                                                 <editor>
     *   <hp>                                                     <hp>
     *     <hinlinebookmark>                                        <hinlinebookmark>
     *        <htext>hello<htext>                                     <htext>hello<cursor/></htext>
     *        <hlinebreak>                                            <hlinebreak>
     *          <htext/>                                                <htext><htext>
     *        </hlinebreak>                                           </hlinebreak>
     *        <htext><cursor/>goodbye</htext>                         <htext>goodbye</htext>
     *     </hinlinebookmark>                                       </hinlinebookmark>
     *   </hp>                                                     </hp>
     * </editor>                                                </editor>
     *
     **/
    if (isHotkey('left', e) && editor.selection != null) {
      const { selection } = editor;

      const matchEntryBefore = find(editor, (n: Node) => n.type === pluginID, {
        at: Editor.before(editor, selection),
      });

      if (matchEntryBefore) {
        const targetPoint = Editor.before(editor, matchEntryBefore[1]);

        if (targetPoint != null) {
          e.preventDefault();
          Transforms.select(editor, targetPoint);

          return true;
        }
      }
    }

    /** If the user is at the left outside edge of a LineBreak node, move the selection to the
     * start of the node to the right of the lineBreak node.
     *
     * Before Pressing Right Arrow Key:                        After Pressing Right Arrow Key:
     * ------------------------------------------------------  ------------------------------------------------------
     * <editor>                                                 <editor>
     *   <hp>                                                     <hp>
     *     <hinlinebookmark>                                        <hinlinebookmark>
     *        <htext>hello<cursor/><htext>                            <htext/>
     *        <hlinebreak>                                            <hlinebreak>
     *          <htext/>                                                <htext><htext>
     *        </hlinebreak>                                           </hlinebreak>
     *        <htext>goodbye</htext>                                <htext><cursor/>goodbye</htext>
     *     </hinlinebookmark>                                       </hinlinebookmark>
     *   </hp>                                                     </hp>
     * </editor>                                                </editor>
     *
     **/
    if (isHotkey('right', e) && editor.selection != null) {
      const { selection } = editor;

      const matchEntryAfter = find(editor, (n: Node) => n.type === pluginID, {
        at: Editor.after(editor, selection),
      });

      if (matchEntryAfter) {
        const targetPoint = Editor.after(editor, matchEntryAfter[1]);

        if (targetPoint != null) {
          e.preventDefault();
          Transforms.select(editor, targetPoint);

          return true;
        }
      }
    }
  };

import { atom } from 'recoil';
import type { RecoilState } from 'recoil';
import type { StatusAuditLogs } from 'hooks/useWorklistItemAuditLogs';
import { broadcastChannelSynchronizerEffect } from 'utils/recoilEffects';
import type { WorklistSurfaceType } from './types';
import type { FilterStateType, FilterDefinitionType } from '../WorklistFilters/types';

export const claimedItemsCountState: RecoilState<{
  loading: boolean;
  count: number;
}> = atom({
  key: 'worklist.claimedItemsCountAtom',
  default: {
    loading: false,
    count: 0,
  },
});

export type ClaimCaseDialogState = {
  open: boolean;
  caseId: string;
  fullName: string;
  action: 'QUEUE' | 'READ' | '';
  callback?: () => void;
  isAddendum?: boolean;
};

export const claimCaseDialogStateDefault = {
  isAddendum: false,
  open: false,
  caseId: '',
  fullName: '',
  action: '',
  callback: () => {},
} as const;

export const claimCaseDialogState: RecoilState<ClaimCaseDialogState> = atom({
  key: 'worklist.claimCaseDialogState',
  default: claimCaseDialogStateDefault,
});

type CaseAuditLogModalState = {
  open: boolean;
  auditLogs: StatusAuditLogs;
};

export const caseAuditLogModalStateDefault: CaseAuditLogModalState = {
  open: false,
  auditLogs: {
    statusChanges: [],
    customStatusChanges: [],
  },
};

export const caseAuditLogModalState: RecoilState<CaseAuditLogModalState> = atom({
  key: 'worklist.caseAuditLogModalState',
  default: caseAuditLogModalStateDefault,
});

type WorklistAutoLoadState = {
  surface: WorklistSurfaceType | null | undefined;
  searchParamsString: string | null | undefined;
  filters: FilterStateType | null | undefined;
  availableFilters: FilterDefinitionType | null | undefined;
  searchForSurface: string;
};

export const worklistAutoLoadStateDefault: WorklistAutoLoadState = {
  surface: null,
  searchParamsString: null,
  filters: null,
  availableFilters: null,
  searchForSurface: '',
};

export const worklistAutoLoadState: RecoilState<WorklistAutoLoadState> = atom({
  key: 'worklistAutoLoadState',
  default: worklistAutoLoadStateDefault,
  effects: [broadcastChannelSynchronizerEffect()],
});

export const patientJacketShowRelevantPriorsOnlyState: RecoilState<boolean> = atom({
  key: 'patientJacketShowRelevantPriorsOnlyState',
  default: false,
  effects: [broadcastChannelSynchronizerEffect()],
});

export const shouldPatientJacketBeDocked: RecoilState<boolean> = atom({
  key: 'shouldPatientJacketBeDocked',
  default: true,
  effects: [broadcastChannelSynchronizerEffect()],
});

export const assignExamDialogState: RecoilState<boolean> = atom({
  key: 'assignExamDialogIsOpen',
  default: false,
});

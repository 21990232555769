/**
 * Generates an externalId to add to nvoq startdictation requests. The id contains
 * worklist item id. A nine character nonce is added to the end to ensure the ID
 * is unique. With a nine character nonce, the collision probablility would require
 * 1000 dictations per hour on the same worklist item id for 2 years to have a 1%
 * probability of collision. The max character size is 63 for an external ID, so
 * nine is enough randomness without using too much of the limit if we later wanted
 * to encode other information into the ID. The worklist item id is a uuid v4 which
 * takes up 36 characters. This means that 17 characters remain, one for another
 * delimiter (.), and 16 characters for the content.
 */
import { nanoid } from 'nanoid';

export function nvoqExternalId(worklistItemId: string): string {
  return `${worklistItemId}.${nanoid(9)}`;
}

import type { HIDDeviceFilter, HIDInputReportEvent } from 'w3c-web-hid';
import { BASE_DEVICE_FILTER_OPTIONS, DEFAULT_DEVICE_FILTERS } from '.';

const DEFAULT_UINT_LEN = 4;

// There's a pattern with WebHID devices where the last 4 elements of the typed array
// are the relevant common data. This function extracts those last elements.
export function getUint8Array(
  buffer: ArrayBuffer,
  uIntLength: number = DEFAULT_UINT_LEN
): Uint8Array {
  const uIntArr = new Uint8Array(buffer);
  return uIntArr.slice(uIntArr.length - uIntLength);
}

export function getIntFromBytes(bytes: Uint8Array): number {
  const bytesArr = Array.from(bytes);
  let val = 0;
  const lastIx = bytesArr.length - 1;
  for (let i = lastIx; i > 0; i--) {
    val = i > 0 ? val | (bytesArr[i] << ((lastIx - i) * 8)) : val | bytesArr[i];
  }
  return val;
}

export function strToNumber(str: string): number {
  return parseInt(str, 16);
}

export function getDeviceValue(e: HIDInputReportEvent): number {
  const bytes = getUint8Array(e.data.buffer);
  return getIntFromBytes(bytes);
}

/**
 * Parses the audio device label to extract the vendorId and productId.
 * Label format: "Microphone (vendorId:productId)"
 */
export function extractHIDDeviceInfo(audioDeviceLabel?: string): HIDDeviceFilter | null {
  if (audioDeviceLabel == null || audioDeviceLabel === '') {
    return null;
  }
  const regex = /([0-9a-fA-F]{4}):([0-9a-fA-F]{4})/g;
  const matches = audioDeviceLabel.match(regex);
  if (matches == null || matches?.length === 0) {
    return null;
  }
  const match = matches[matches.length - 1];
  const [vId, pId] = match.split(':');
  return { vendorId: strToNumber(vId), productId: strToNumber(pId) };
}

export const getCurrentSelectedAudioDevice = async (
  audioDeviceLabel?: string
): Promise<MediaDeviceInfo[] | null> => {
  if (navigator.mediaDevices == null) {
    return null;
  }
  const devices = await navigator.mediaDevices.enumerateDevices();
  const audioDevices = devices.filter((d) => d.kind === 'audioinput');
  if (audioDeviceLabel == null || audioDeviceLabel === '') {
    return audioDevices;
  }
  return audioDevices.filter((d) => d.label === audioDeviceLabel);
};

export const setHIDDeviceFilters = (filters?: HIDDeviceFilter[]): HIDDeviceFilter[] => {
  // return the default filters
  if (filters == null) {
    return DEFAULT_DEVICE_FILTERS.map((filter) => ({ ...filter, ...BASE_DEVICE_FILTER_OPTIONS }));
  }
  // explicitly set filters to an empty array to query for all physically connected HID devices
  if (filters.length === 0) {
    return [BASE_DEVICE_FILTER_OPTIONS];
  }
  return filters.map((filter) => ({ ...filter, ...BASE_DEVICE_FILTER_OPTIONS }));
};

import { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { useParams, useNavigate, Link, useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import AuthPage from './AuthPage';
import Text from 'common/ui/Text';
import Input from 'common/ui/Input';
import { Button } from 'common/ui/Button';
import { RESET_PASSWORD } from 'modules/Apollo/queries';
import { Colors, TextCss, Spacings } from 'styles';
import { useSetRecoilState } from 'recoil';
import { passwordResetSuccessfulState } from 'config/recoilState';
import { usePasswordValidation } from 'hooks/usePasswordValidation';
import { Stack } from '../ui/Layout';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const InputCSS = css`
  margin-top: 0.2rem;
  ::placeholder {
    color: ${Colors.gray10};
    font-size: 14px;
  }
  width: 100%;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: ${Colors.gray10};
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px ${Colors.gray4};
  }
`;

const PaddedTextCss = css`
  ${TextCss.caption}
  margin-left: ${Spacings.small}rem;
  margin-top: ${Spacings.xxsmall}rem;
  margin-bottom: ${Spacings.xsmall}rem;
`;

const StyledRequirement = styled.li`
  ${TextCss.caption};
  margin-bottom: ${Spacings.xsmall}rem;
`;

const getRequirementColor = (active: boolean) => `
  color: ${active ? Colors.green5 : 'white'};
`;

const RequestPasswordReset = (): React.ReactElement => {
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [
    password,
    setPassword,
    setConfirmPassword,
    passwordValidations,
    isPasswordMatching,
    isDisabled,
  ] = usePasswordValidation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  const { userId } = useParams();
  const setPasswordResetSuccessful = useSetRecoilState(passwordResetSuccessfulState);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onSubmit = useCallback(
    async (evt: React.SyntheticEvent<HTMLFormElement>) => {
      evt.preventDefault();

      // @ts-expect-error [EN-7967] - TS2339 - Property 'entries' does not exist on type 'FormData'.
      const data = Object.fromEntries(new FormData(evt.currentTarget).entries());
      setError(null);
      setLoading(true);
      try {
        await resetPassword({ variables: { token, userId, password: data.password } });
        setPasswordResetSuccessful(true);
        navigate('/login');
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    },
    [resetPassword, token, userId, navigate, setPasswordResetSuccessful]
  );

  return (
    <AuthPage onSubmit={onSubmit} title="Reset password">
      <Link to="/">
        <img
          src="/logos/logoSide.png"
          alt="Sirona Medical Logo"
          css={`
            width: 5rem;
            margin-bottom: 1.5rem;
          `}
        />
      </Link>
      <Text variant="body2">
        Thank you for confirming your email address. Please enter your new password.
      </Text>
      <Stack
        space="medium"
        vertical
        css={`
          margin-top: ${Spacings.small}rem;
          display: flex;
          flex-direction: column;
          width: 100%;
        `}
      >
        <Stack vertical>
          {/* @ts-expect-error [EN-7967] - TS2322 - Type '{ children: string; css: FlattenSimpleInterpolation; as: string; htmlFor: string; }' is not assignable to type 'DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>'. */}
          <label css={PaddedTextCss} as="label" htmlFor="password">
            Password
          </label>
          <Stack alignY="center" css="position: relative;">
            <Input
              css={InputCSS}
              id="password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              onChange={(evt) => setPassword(evt.target.value)}
              fullWidth
              placeholder="Enter your password"
              required
              variant="light"
            />
            <IconButton
              disableRipple
              aria-label="toggle password visibility"
              data-testid={`toggle-password-${showPassword ? 'hidden' : 'visible'}`}
              onClick={() => setShowPassword((show) => !show)}
              edge="end"
              css={`
                position: absolute;
                height: 16px;
                width: 16px;
                right: 12px;
              `}
            >
              {showPassword ? (
                <Visibility
                  css={`
                    font-size: 16px;
                  `}
                />
              ) : (
                <VisibilityOff
                  css={`
                    font-size: 16px;
                  `}
                />
              )}
            </IconButton>
          </Stack>
        </Stack>
        <Stack vertical>
          <ul
            data-testid="password-checks"
            css={`
              padding-inline-start: 3rem;
            `}
          >
            <StyledRequirement css={getRequirementColor(passwordValidations.hasLength)}>
              Minimum of 8 characters long.
            </StyledRequirement>
            <StyledRequirement css={getRequirementColor(passwordValidations.hasUpperCase)}>
              Contains at least one uppercase letter.
            </StyledRequirement>
            <StyledRequirement css={getRequirementColor(passwordValidations.hasLowerCase)}>
              Contains at least one lowercase letter.
            </StyledRequirement>
            <StyledRequirement css={getRequirementColor(passwordValidations.hasNumber)}>
              Contains at least one number.
            </StyledRequirement>
            <StyledRequirement css={getRequirementColor(passwordValidations.hasSpecialChar)}>
              Contains at least one special character.
            </StyledRequirement>
          </ul>
        </Stack>
        <Stack vertical>
          {/* @ts-expect-error [EN-7967] - TS2322 - Type '{ children: string; css: FlattenSimpleInterpolation; as: string; htmlFor: string; }' is not assignable to type 'DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>'. */}
          <label css={PaddedTextCss} as="label" htmlFor="confirmPassword">
            Re-enter password
          </label>
          <Stack alignY="center" css="position: relative;">
            <Input
              css={InputCSS}
              id="confirmPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              name="confirmPassword"
              onChange={(evt) => setConfirmPassword(evt.target.value)}
              pattern={password?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')} // must match other password field
              title="Passwords should match."
              fullWidth
              placeholder="Enter your password"
              required
              variant="light"
            />
            <IconButton
              disableRipple
              data-testid={`toggle-confirm-password-${showConfirmPassword ? 'hidden' : 'visible'}`}
              aria-label="toggle confirm password visibility"
              onClick={() => setShowConfirmPassword((show) => !show)}
              edge="end"
              css={`
                position: absolute;
                height: 16px;
                width: 16px;
                right: 12px;
              `}
            >
              {showConfirmPassword ? (
                <Visibility
                  css={`
                    font-size: 16px;
                  `}
                />
              ) : (
                <VisibilityOff
                  css={`
                    font-size: 16px;
                  `}
                />
              )}
            </IconButton>
          </Stack>
          <div css="height: 2rem;  width: 100%;">
            {!isPasswordMatching && (
              <Text
                variant="caption"
                css={`
                  color: ${Colors.yellow3};
                  margin-left: ${Spacings.small}rem;
                `}
              >
                Passwords must match
              </Text>
            )}
          </div>
        </Stack>
      </Stack>
      <Stack
        vertical
        stretchX
        alignX="center"
        space="small"
        css={`
          margin-top: ${Spacings.small}rem;
        `}
      >
        {!loading ? (
          <Stack css="width: 100%" alignX="center" alignY="center">
            <Button
              css={`
                width: 100%;
              `}
              type="submit"
              disabled={isDisabled}
            >
              Reset password
            </Button>
          </Stack>
        ) : (
          <CircularProgress />
        )}

        <Text
          variant="caption"
          css={`
            margin-top: ${Spacings.small};
            color: ${Colors.red5};
            margin-bottom: ${Spacings.xxsmall};
          `}
        >
          {error?.message}
        </Text>
      </Stack>
    </AuthPage>
  );
};

export default RequestPasswordReset;

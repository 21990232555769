import type { EditorPlugin } from '../../types';
import { DICTATION_PLUGIN_ID } from './types';
import type { DictationPluginOptions, DictationPluginPropertyOptions } from './types';
import { enhanceProviderDictation } from './enhanceProviderDictation';
import { renderLeafDictation } from './renderLeafDictation';
import { DecorateDictationEditable } from './components';

export const DictationPlugin = ({
  pluginName = 'Dictation',
  component = DecorateDictationEditable,
  enablePicklistDictation = false,
  aiMode = false,
  ...rest
}: DictationPluginOptions): EditorPlugin => {
  const options: DictationPluginPropertyOptions = {
    pluginID: DICTATION_PLUGIN_ID,
    pluginName,
    component,
    enablePicklistDictation,
    aiMode,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    enhanceProvider: enhanceProviderDictation(options),
    renderLeaf: renderLeafDictation(options),
  };
};

import { Editor } from 'domains/reporter/RichTextEditor/core';
import type { EditorElement, EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';
import { PLACEHOLDER_FIELDS_DICT } from './constants';
import type { WorklistItem, GetWorklistItemQuery } from 'generated/graphql';
import type { ComponentType } from 'react';
import type { GroupedWorklistItem } from 'hooks/useCurrentWorklistItems';

export const PLACEHOLDER_PLUGIN_ID: 'placeholder' = 'placeholder';
export type PlaceholderPluginID = typeof PLACEHOLDER_PLUGIN_ID;

export type PlaceholderFieldID = keyof typeof PLACEHOLDER_FIELDS_DICT;
export type PlaceholderFieldItem = Readonly<{
  key: PlaceholderFieldID;
  name: string;
  resolve: (
    worklistItem:
      | WorklistItem
      | GroupedWorklistItem
      | null
      | undefined
      | GetWorklistItemQuery['worklistItem']
      | null
      | undefined
  ) => string | null | undefined;
}>;

export type PlaceholderPluginElement = Readonly<
  EditorElement & {
    type: PlaceholderPluginID;
    placeholderID: PlaceholderFieldID;
    workListItemSmid: string;
  }
>;

export type PlaceholderPluginMenuClick = Readonly<{
  editor: Editor;
  placeholderField: PlaceholderFieldItem;
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  instance: any;
  pluginID: PlaceholderPluginID;
  currentWorklistItems: ReadonlyArray<GroupedWorklistItem>;
}>;

export type PlaceholderPluginOptions = Readonly<
  EditorPluginOptions & {
    onPlaceholderMenuClick: (props: PlaceholderPluginMenuClick) => void;
    // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
    component?: ComponentType<any>;
    /**
     * Array of plugin fields that the user will be able to trigger.
     */
    placeholderFields?: PlaceholderFieldItem[];
    onPlaceholderRemove?: (props: { placeholderID: PlaceholderFieldID }) => void;
    currentWorklistItems?: ReadonlyArray<GroupedWorklistItem>;
  }
>;

export type PlaceholderPluginPropertyOptions = Readonly<
  EditorPluginPropertyOptions &
    PlaceholderPluginOptions & {
      pluginID: PlaceholderPluginID;
      placeholderFields: PlaceholderFieldItem[];
      // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
      component: ComponentType<any>;
      pluginName: string;
    }
>;

import { useRecoilCallback } from 'recoil';
import type { ToolInteractionUpdateViewportPresentationStateResponse } from 'generated/graphql';
import { useStudies } from '../../../../Viewer/StudyLoader/useStudies';
import {
  getViewportConfigurationSelector,
  viewportDisplayConfigurationSelector,
} from '../../../../ViewportsConfigurations/state';
import { useViewportId } from '../../../modules/state';
import { useCurrentCaseId } from 'hooks/useCurrentCase';
import { useInitCamera } from '../../../modules/useInitCamera';

export function useUpdateViewportPresentationStateResponse(): (
  response: Readonly<ToolInteractionUpdateViewportPresentationStateResponse>
) => void {
  const activeViewportId = useViewportId();
  const { groupedStudies } = useStudies();
  const currentCaseId = useCurrentCaseId();
  const initCamera = useInitCamera(activeViewportId);

  const updateViewportConfigurationParams2d = useRecoilCallback(
    ({ snapshot, set }) =>
      (response: any) => {
        const viewportId = response.viewportId ?? activeViewportId;
        const viewportConfiguration = snapshot
          .getLoadable(
            getViewportConfigurationSelector({
              viewportId,
              currentCaseId,
              groupedStudies,
            })
          )
          .valueMaybe();

        if (viewportConfiguration == null) {
          return;
        }

        const selector = viewportDisplayConfigurationSelector({
          stackSmid: viewportConfiguration?.stack?.smid,
          viewType: viewportConfiguration?.viewType,
          viewportId: response.viewportId,
        });

        set(selector, (config) => {
          return {
            ...config,
            params2d: {
              ...config?.params2d,
              window: response.window ?? config?.params2d?.window,
              level: response.level ?? config?.params2d?.level,
              rotate: response.rotate != null ? response.rotate : 0,
              zoom: response.zoom ?? config?.params2d?.zoom,
            },
          };
        });
        setTimeout(() => {
          initCamera();
        }, 500);
      }
  );

  return updateViewportConfigurationParams2d;
}

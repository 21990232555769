import * as React from 'react';
import type { ComponentType } from 'react';

export type GetRenderToolbarOptions = Record<any, any>;

// TODO(mwood23) Types for Tippy
export type RenderTippyProps = (tippyProps?: any) => React.ReactElement;

export const getRenderToolbar = <
  T extends unknown,
  PluginOptions extends {
    component: ComponentType<T>;
  },
>({
  component: Component,
  ...pluginOptions
}: PluginOptions): RenderTippyProps =>
  function GetRenderToolbar(tippyProps) {
    return <Component {...tippyProps} {...pluginOptions} />;
  };

import { Colors } from 'styles';

const styles: () => {
  [key: string]:
    | string
    | number
    | {
        [key: string]: string | number;
      };
} = () => ({
  root: {
    borderRadius: '16px',
    marginTop: '0.5rem',
    backgroundColor: Colors.gray5,
    fontSize: '1.4rem',
    lineHeight: '1.57',
    color: Colors.gray10,
  },
  settingDropDownInput: {
    borderRadius: '16px',
    padding: '0.5rem 1rem 0.5rem 1rem',
  },
  settingDropDownMenuPaper: {
    borderRadius: 6,
    marginTop: '0.5rem',
    backgroundColor: Colors.gray5,
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: Colors.gray10,
  },
});

export default styles;

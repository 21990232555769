import { useEffect, useCallback, useRef } from 'react';

export const useOnUnload = (fn: () => Promise<undefined> | undefined) => {
  const fnRef = useRef(fn);
  useEffect(() => {
    fnRef.current = fn;
  }, [fn]);

  const cb = useCallback(() => {
    fnRef.current();
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', cb);
    return () => {
      window.removeEventListener('beforeunload', cb);
    };
  }, [cb]);
};

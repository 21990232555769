import styled from 'styled-components';
import { Colors } from 'styles';
import { motion } from 'framer-motion';
import { Background } from './Background';

const Wrapper = styled(Background)`
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;
`;

const Footer = styled.div`
  flex-shrink: 0;
`;

const Main = styled(motion.div as any)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.gray3};
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  width: 320px;
  border-radius: 6px;
  overflow: hidden;
`;

const MainContentWrapperTitle = styled.div`
  background-color: ${Colors.gray5};
  width: 100%;
  padding: 15px 40px;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
`;

export { Wrapper, Footer, Main, MainContent, MainContentWrapperTitle, MainContentWrapper };

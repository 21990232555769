import { Editor, Range } from '../core';
import analytics from 'modules/analytics';
import { reporter } from 'modules/analytics/constants';
import type { THeadingLevel } from '../plugins';
import { HIGHLIGHTED_PLUGIN_ID } from '../plugins/highlighted/types';
import { UNDERLINE_PLUGIN_ID } from '../plugins/underline/types';
import { ITALIC_PLUGIN_ID } from '../plugins/italic/types';
import { BOLD_PLUGIN_ID } from '../plugins/bold/types';
import { TEXT_SOURCE_STYLING_PLUGIN_ID } from '../plugins/textSourceStyling/types';
import { getEditorSelectionSafe, areMultipleNodesSelected } from '.';
import {
  isHeadingActive,
  isOnlyHeadingSelected,
  selectionIncludesHeading,
  isOnlyHeadingTextSelected,
  areMultipleHeadingsSelected,
} from '../plugins/heading/utils/normalization';

export const MARKS = [
  BOLD_PLUGIN_ID,
  ITALIC_PLUGIN_ID,
  UNDERLINE_PLUGIN_ID,
  HIGHLIGHTED_PLUGIN_ID,
  TEXT_SOURCE_STYLING_PLUGIN_ID,
] as const;

export const isMarkActive = (editor: Editor, type: string): boolean => {
  const marks = Editor.marks(editor);
  return marks ? marks[type] === true : false;
};

export const isHeadingButtonActive = (
  editor: Editor,
  type: string,
  level?: THeadingLevel | null
): boolean => {
  const selection = getEditorSelectionSafe(editor);

  if (Range.isCollapsed(selection)) {
    return false;
  }

  if (isHeadingActive(editor, type, level)) {
    if (isOnlyHeadingSelected(editor)) {
      return true;
    } else if (areMultipleNodesSelected(editor)) {
      return isOnlyHeadingTextSelected(editor) && !areMultipleHeadingsSelected(editor);
    }
  }

  return false;
};

export const isMarkPluginID = (type: string): boolean => {
  // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'string' is not assignable to parameter of type '"bold" | "source" | "italic" | "underline" | "highlighted"'.
  return MARKS.includes(type);
};

export const isButtonDisabled = (
  editor: Editor,
  type: string
): [boolean, string | null | undefined] => {
  if (isMarkPluginID(type) && selectionIncludesHeading(editor)) {
    return [true, 'Headings can only be styled using your text settings.'];
  }

  return [false, null];
};

export const toggleMark = (editor: Editor, key: string) => {
  analytics.track(reporter.usr.markApplied, {
    pluginId: key,
    selectedText:
      editor.selection != null ? Editor.string(editor, editor.selection) : 'No text selected',
  });

  const isActive = isMarkActive(editor, key);

  if (isActive) {
    editor.removeMark(key);
    return;
  }

  editor.addMark(key, true);
};

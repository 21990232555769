import { useRecoilCallback, useRecoilValue } from 'recoil';
import type { Vector3 as vec3 } from '@kitware/vtk.js/types';
import type { ToolInteractionPayload } from 'generated/graphql';
import { DRE_ALL_VIEWPORT_TYPES } from 'config/constants';
import type { DreAllViewportTypes } from 'config/constants';
import { useViewportsConfigurationsSelector } from '../../../ViewportsConfigurations/state';
import type { VtkAnnotation } from '../../Annotations';
import type { Config } from './ConfigBasedTool';
import { ToolPayloadCapability } from 'generated/graphql';
import { makePayloadGetterSelector, useMockableContext } from './preparePayloadUtils';
import type { Stack, ViewportsConfigurations } from '../../../ViewportsConfigurations/types';
import type { ViewportsDisplayConfigurations } from './preparePayloadUtils';
import { useBaseViewerData } from '../../../Viewer/StudyLoader/useStudies';
import { frameToSlimFrame } from '../../../ViewportsConfigurations/manipulators';
import type { TStackProviders } from '../../../Viewer/ViewerContext';
import type { BaseImagingProvider } from '../../modules/imaging/BaseImagingProvider';
import { BaseSingleStackImagingProvider } from '../../modules/imaging/BaseSingleStackImagingProvider';

export function getViewportsPayload({
  viewportsConfigurations,
  viewportsDisplayConfigurations,
  stackProviders,
  activeViewportId,
  payloadCapabilities,
}: {
  viewportsConfigurations: ViewportsConfigurations;
  viewportsDisplayConfigurations: ViewportsDisplayConfigurations;
  stackProviders: TStackProviders | null | undefined;
  activeViewportId: string;
  payloadCapabilities: Config['payloadCapabilities'];
}): NonNullable<ToolInteractionPayload['viewports']> {
  const viewports = Object.entries(viewportsConfigurations)
    .filter(([id, config]: [any, any]) => config != null)
    .map(([id, config]: [any, any]) => {
      const studyId = config.study?.smid;
      const seriesId = config.series?.smid;
      const stackId = config.stack?.smid;
      const viewType = config.viewType;
      const imagingProvider =
        seriesId != null && stackProviders != null ? stackProviders.get(stackId ?? '') : null;

      // SDK doesn't support non-DRE viewports
      let activeSlice = -1;

      if (DRE_ALL_VIEWPORT_TYPES.includes(viewType) && imagingProvider != null) {
        activeSlice = imagingProvider.getActiveSlice(viewType, id);
      }
      // viewportConfig.stack is a slim stack with a single frame
      // we need to get all of the frames, so reach into the stack provider for them
      const provider = stackProviders?.get(config.stack?.smid ?? '');

      const slices =
        payloadCapabilities.includes(ToolPayloadCapability.ViewerViewportsSlices) &&
        provider instanceof BaseSingleStackImagingProvider &&
        provider?.stack?.frames
          ? provider.stack?.frames.map(({ smid, series }, index: any) => ({
              active: activeSlice === index,
              index,
              studyId,
              seriesId: series.smid,
              instanceId: smid,
            }))
          : undefined;

      const viewportDisplayConfiguration = viewportsDisplayConfigurations[id];
      const params2d = viewportDisplayConfiguration?.params2d;
      const displayConfiguration = {
        params2d: params2d
          ? {
              focalPoint: params2d.focalPoint,
              imageNumber: activeSlice,
              level: params2d.level,
              offsetX: params2d.offsetX,
              offsetY: params2d.offsetY,
              position: params2d.position,
              rotate: params2d.rotate,
              window: params2d.window,
              zoom: params2d.zoom,
            }
          : undefined,
        isVerticallyFlipped: viewportDisplayConfiguration?.isVerticallyFlipped ?? false,
        isHorizontallyFlipped: viewportDisplayConfiguration?.isHorizontallyFlipped ?? false,
        isInverted: viewportDisplayConfiguration?.isInverted ?? false,
      } as const;

      return {
        id,
        active: id === activeViewportId,
        description: config.seriesDescriptions?.join(',') ?? '',
        slices,
        displayConfiguration: payloadCapabilities.includes(
          ToolPayloadCapability.ViewerViewportsDisplayConfig
        )
          ? displayConfiguration
          : undefined,
      };
    });

  return viewports;
}

export function getAnnotationsPayload({
  allToolAnnotations,
  imagingProvider,
}: {
  allToolAnnotations: ReadonlyArray<VtkAnnotation>;
  imagingProvider: BaseImagingProvider<Stack> | null | undefined;
}): NonNullable<ToolInteractionPayload['annotations']> {
  const annotations = allToolAnnotations.map((annotation) => ({
    id: annotation.id,
    type: annotation.type,
    segments: annotation.segments.map((s) => ({
      start: {
        worldSpace: s[0],
        indexSpace: imagingProvider?.worldToIndex(s[0]),
      },
      end: {
        worldSpace: s[1],
        indexSpace: imagingProvider?.worldToIndex(s[1]),
      },
    })),
    groupId: annotation.groupId,
    label: annotation.label,
  }));

  return annotations;
}

export function getPayload({
  mousePosition,
  indexSpace,
  activeViewportId,
  viewportsConfigurations,
  viewportsDisplayConfigurations,
  stackProviders,
  imagingProvider,
  allToolAnnotations,
  payloadCapabilities,
}: {
  mousePosition: vec3 | null | undefined;
  indexSpace: vec3 | null | undefined;
  activeViewportId: string;
  viewportsConfigurations: ViewportsConfigurations;
  viewportsDisplayConfigurations: ViewportsDisplayConfigurations;
  stackProviders: TStackProviders | null | undefined;
  imagingProvider: BaseImagingProvider<Stack> | null | undefined;
  allToolAnnotations: ReadonlyArray<VtkAnnotation>;
  payloadCapabilities: Config['payloadCapabilities'];
}): ToolInteractionPayload {
  // We only send to the backend the data that the tool is interested in, as defined
  // by the payload capabilities
  return {
    viewports: payloadCapabilities.includes(ToolPayloadCapability.ViewerViewports)
      ? getViewportsPayload({
          viewportsConfigurations,
          viewportsDisplayConfigurations,
          stackProviders,
          activeViewportId,
          payloadCapabilities,
        })
      : undefined,
    widgets: payloadCapabilities.includes(ToolPayloadCapability.Widgets) ? [] : undefined,
    annotations: payloadCapabilities.includes(ToolPayloadCapability.ViewerAnnotations)
      ? getAnnotationsPayload({
          allToolAnnotations,
          imagingProvider,
        })
      : undefined,
    mergeFields: payloadCapabilities.includes(ToolPayloadCapability.ReporterMergeFields)
      ? []
      : undefined,
    coordinates: payloadCapabilities.includes(ToolPayloadCapability.ViewerCoordinates)
      ? [
          {
            worldSpace: mousePosition,
            indexSpace,
          },
        ]
      : undefined,
  };
}

export function usePayload(
  payloadCapabilities: ReadonlyArray<ToolPayloadCapability>
): ToolInteractionPayload {
  const selector = makePayloadGetterSelector({
    viewportsConfigurationsSelector: useViewportsConfigurationsSelector(),
  });

  const {
    activeViewportId,
    viewportsConfigurations: slimViewportsConfigurations,
    viewportsDisplayConfigurations,
  } = useRecoilValue(selector);
  const { studies } = useBaseViewerData();
  const stacks = studies.flatMap((study) => study.stackedFrames);

  const viewportsConfigurations =
    slimViewportsConfigurations != null
      ? Object.fromEntries(
          Object.entries(slimViewportsConfigurations).map(
            ([viewportId, viewportConfiguration]: [any, any]) => {
              const slimStack = viewportConfiguration?.stack;
              const stack =
                slimStack != null ? stacks.find((stack) => stack.smid === slimStack.smid) : null;

              if (viewportConfiguration != null && slimStack != null && stack != null) {
                return [
                  viewportId,
                  {
                    ...viewportConfiguration,
                    stack: {
                      ...slimStack,
                      // @ts-expect-error [EN-7967] - TS2339 - Property 'frames' does not exist on type '{ readonly __typename?: "LayeredStack"; readonly smid: string; readonly type: string; readonly study: { readonly __typename?: "Study"; readonly smid: string; }; readonly stackLayers: readonly { readonly __typename?: "Layer"; readonly stackSmid: string; readonly index: number; }[]; } | { ...; }'.
                      frames: stack.frames?.map((frame: any) => frameToSlimFrame(frame)) ?? [],
                    },
                  },
                ];
              }

              return [viewportId, viewportConfiguration];
            }
          )
        )
      : null;

  if (viewportsConfigurations == null) {
    throw new Error('Viewports configurations are not available');
  }

  return getPayload({
    mousePosition: null, // TODO(fzivolo): support this on both Viewer and Reporter side
    indexSpace: null, // TODO(fzivolo): support this on both Viewer and Reporter side
    activeViewportId,
    viewportsConfigurations,
    viewportsDisplayConfigurations,
    stackProviders: null, // TODO(fzivolo): support this on both Viewer and Reporter side
    imagingProvider: null, // TODO(fzivolo): support this on both Viewer and Reporter side
    allToolAnnotations: [], // TODO(fzivolo): support this on both Viewer and Reporter side
    payloadCapabilities,
  });
}
export function usePayloadGetter(): (arg1: {
  viewType: DreAllViewportTypes;
  mousePosition: vec3 | null | undefined;
  annotations: VtkAnnotation[];
  payloadCapabilities: Config['payloadCapabilities'];
}) => Promise<ToolInteractionPayload> {
  const selector = makePayloadGetterSelector({
    viewportsConfigurationsSelector: useViewportsConfigurationsSelector(),
  });
  const { stackProviders, imagingProvider, activeSlice } = useMockableContext();

  return useRecoilCallback(
    ({ snapshot }) =>
      async ({ viewType, mousePosition, annotations: allToolAnnotations, payloadCapabilities }) => {
        if (imagingProvider == null) {
          throw new Error('Imaging provider is not available');
        }

        const { activeViewportId, viewportsConfigurations, viewportsDisplayConfigurations } =
          snapshot.getLoadable(selector).valueOrThrow();

        if (viewportsConfigurations == null) {
          throw new Error('Viewports configurations are not available');
        }

        const activeSliceTags = imagingProvider.getFrameTagsForViewIndex(viewType, activeSlice);
        const indexSpace = mousePosition
          ? imagingProvider.worldToIndex(mousePosition, activeSliceTags)
          : null;

        return getPayload({
          mousePosition,
          indexSpace,
          activeViewportId,
          viewportsConfigurations,
          viewportsDisplayConfigurations,
          stackProviders,
          imagingProvider,
          allToolAnnotations,
          payloadCapabilities,
        });
      },
    [activeSlice, imagingProvider, stackProviders, selector]
  );
}

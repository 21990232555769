import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { logger } from 'modules/logger';
import { getWorkspaceManagerVersion, isWorkspaceManagerAvailable } from 'modules/workspace-manager';
import {
  addExtensionListener,
  removeExtensionListener,
} from 'domains/extension/extensionEventCreators';
import { RESPONSE_EVENTS as EXTENSION_RESPONSE_EVENTS } from 'domains/extension/constants';
import { getPageType } from 'utils/pageTypes';
import type { PageTypes } from 'utils/pageTypes';

export const EXT_VERSION_REQUIRED = 4;

export type Transition = {
  /** Page type */
  target: PageTypes;
  /** New path */
  path: string;
};

export function useRouteTransitions(): void {
  const isExtensionActive = isWorkspaceManagerAvailable();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isExtensionActive || getWorkspaceManagerVersion() < EXT_VERSION_REQUIRED) {
      logger.warn(
        `[useRouteTransitions] Extension is not active or version is less than ${EXT_VERSION_REQUIRED}`
      );
      return;
    }

    const transitionRouteHandler = (event: CustomEvent<Transition>) => {
      const { detail } = event;
      logger.info('[useRouteTransitions] Transition Route', detail);
      const currentWindow = getPageType(window.location.href);
      const loggerInfo = {
        ...detail,
        currentUrl: window.location.href,
      } as const;
      // TODO: Ideally this would validate against a list of defined paths
      if (detail.path == null || detail.path === '') {
        logger.error('[useRouteTransitions] Transition Route: Invalid path', loggerInfo);
        return;
      }

      if (currentWindow === detail.target) {
        navigate(detail.path);
      } else {
        // Message should only be sent to the appropriate tab, so if target
        // doesn't match it is an error
        logger.error(
          '[useRouteTransitions] Transition Route: Current window does not match target window',
          loggerInfo
        );
      }
    };

    addExtensionListener(
      EXTENSION_RESPONSE_EVENTS.TRANSITION_ROUTE_RESPONSE,
      transitionRouteHandler
    );

    return () => {
      removeExtensionListener(
        EXTENSION_RESPONSE_EVENTS.TRANSITION_ROUTE_RESPONSE,
        transitionRouteHandler
      );
    };
  }, [isExtensionActive, navigate]);
}

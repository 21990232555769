import type { EditorPluginOptions, EditorPluginPropertyOptions, OnKeyDown } from '../../types';

export const GENERAL_KEYBOARD_CONTROLS_PLUGIN_ID: 'generalKeyboardControls' =
  'generalKeyboardControls';
export type GeneralKeyboardControlsPluginID = typeof GENERAL_KEYBOARD_CONTROLS_PLUGIN_ID;

export type GeneralKeyboardControlsPluginOptions = Readonly<
  EditorPluginOptions & {
    onKeyDownOverride?: OnKeyDown;
    ignoreMergeFieldsInNavigation?: boolean;
  }
>;

export type GeneralKeyboardControlsPluginPropertyOptions = Readonly<
  EditorPluginPropertyOptions &
    GeneralKeyboardControlsPluginOptions & {
      pluginID: GeneralKeyboardControlsPluginID;
      pluginName: string;
      onKeyDownOverride: OnKeyDown;
    }
>;

import variant from '@jitl/quickjs-browser-release-sync-wasm';
import { newQuickJSWASMModuleFromVariant } from 'quickjs-emscripten-core';
import type { QuickJSWASMModule } from 'quickjs-emscripten-core';

type Status = 'pending' | 'error' | 'success';

export const loadQuickJs = (): {
  read: () => QuickJSWASMModule;
} => {
  let status: Status = 'pending';
  let result;
  const suspender = newQuickJSWASMModuleFromVariant(variant)
    .then((quickJs) => {
      status = 'success';
      result = quickJs;
    })
    .catch((error) => {
      status = 'error';
      result = error;
    });

  return {
    read() {
      switch (status) {
        case 'pending':
          throw suspender;
        case 'error':
          throw result;
        case 'success':
          return result;
        default:
          throw new Error(`Unknown status: ${status}`);
      }
    },
  };
};

const quickJsResource = loadQuickJs();

export function useQuickJS(): QuickJSWASMModule {
  return quickJsResource.read();
}

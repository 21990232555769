import { useCallback, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { useMicrophone } from 'domains/reporter/useMicrophone';
import { GamepadActionId } from 'generated/graphql';
import { NAMESPACES, useEventsListener } from 'modules/EventsManager';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';
import hidDeviceManager, { HID_MANAGER_STATES } from 'modules/WebHID';
import { webHIDConnectModalOpenState } from 'domains/reporter/WebHID/WebHIDConnectModal';
import { useToasterDispatch } from 'common/ui/Toaster';
import { useMediaDevices } from 'domains/reporter/useMediaDevices';

export const DEFAULT_WEBHID_KEYCODE_MAPPINGS = {
  '16': 'back',
  '1': 'record',
  '8': 'forward',
  '32': 'submitReport',
  // '9999999': 'toggleFocusMode', // [RP-3708] there is no inputreport event code for this
};

type UseWebHIDReturn = {
  isWebHIDEnabled: boolean;
  manageWebHIDPairing: (label: string) => Promise<void>;
  pairDevice: (label: string, isWebHIDEnabled?: boolean) => Promise<void>;
  onPairingCancel: () => void;
};

export const useWebHID = (): UseWebHIDReturn => {
  const [isWebHIDEnabled] = useFeatureFlagEnabled(FF.REPORTER_WEBHID);
  const { audioInputDeviceLabel } = useMicrophone();
  const { loaded } = useMediaDevices();
  const setDeviceToOpen = useSetRecoilState(webHIDConnectModalOpenState);
  const { enqueueToast } = useToasterDispatch();
  const { checkForValidHIDDevice, pairDevice, setManagerState, onPairingCancel } = hidDeviceManager;

  useEventsListener(NAMESPACES.OPEN_WEBHID_CONNECT_MODAL, ({ payload }) =>
    setDeviceToOpen(payload)
  );

  useEventsListener(NAMESPACES.WEBHID_ERROR, ({ payload }) =>
    enqueueToast(payload, { severity: 'error' })
  );

  const manageWebHIDPairing = useCallback(async () => {
    setManagerState(HID_MANAGER_STATES.LOADING);
    await checkForValidHIDDevice({ audioInputLabel: audioInputDeviceLabel, isWebHIDEnabled });
    setManagerState(HID_MANAGER_STATES.DONE);
  }, [isWebHIDEnabled, audioInputDeviceLabel, setManagerState, checkForValidHIDDevice]);

  useEffect(() => {
    if (loaded && hidDeviceManager.state !== HID_MANAGER_STATES.LOADING) {
      manageWebHIDPairing();
    }
  }, [loaded, isWebHIDEnabled, audioInputDeviceLabel, manageWebHIDPairing]);

  return { isWebHIDEnabled, pairDevice, manageWebHIDPairing, onPairingCancel };
};

export type WebHIDBindingsCallback = (actionID: GamepadActionId, pressed: boolean) => void;

export const useWebHIDBindings = ({ callback }: { callback: WebHIDBindingsCallback }): void => {
  useEventsListener(NAMESPACES.INPUTREPORT_EVENT, ({ payload }) => {
    const { value, pressed } = payload;
    const actionId = DEFAULT_WEBHID_KEYCODE_MAPPINGS[value];
    if (actionId == null) {
      return;
    }
    callback(actionId, pressed);
  });
};

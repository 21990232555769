import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const PRIOR_STUDIES_PARAM = 'prior_studies';
export const EXCLUDED_AUTOLOADED_PRIOR_STUDIES_PARAM = 'skip_studies';

export const usePriorStudiesParams = (): string | null | undefined => {
  const [searchParams] = useSearchParams();
  return searchParams.get(PRIOR_STUDIES_PARAM);
};

export const useExcludedAutoloadedPriorParams = (): string | null | undefined => {
  const [searchParams] = useSearchParams();
  return searchParams.get(EXCLUDED_AUTOLOADED_PRIOR_STUDIES_PARAM);
};

export const useExcludedAutoloadedPriorSmids = (): string[] => {
  const excludedAutoloadedPriorSmids = useExcludedAutoloadedPriorParams();
  return useMemo(
    () => excludedAutoloadedPriorSmids?.split(',') ?? [],
    [excludedAutoloadedPriorSmids]
  );
};

const getPriorsQueryParametersSuffix = (priorStudiesList?: string | null): string => {
  return priorStudiesList != null && priorStudiesList.length > 0
    ? `${PRIOR_STUDIES_PARAM}=${priorStudiesList}`
    : '';
};

const getExcludedAutoloadedPriorsQueryParametersSuffix = (
  excludedAutoloadedPriorsList?: string | null
): string => {
  return excludedAutoloadedPriorsList != null && excludedAutoloadedPriorsList.length > 0
    ? `${EXCLUDED_AUTOLOADED_PRIOR_STUDIES_PARAM}=${excludedAutoloadedPriorsList}`
    : '';
};

export const getCaseStudyParamsSuffix = (
  priorStudiesList?: string | null,
  excludedAutoloadedPriorsList?: string | null
): string => {
  const params = [
    getPriorsQueryParametersSuffix(priorStudiesList),
    getExcludedAutoloadedPriorsQueryParametersSuffix(excludedAutoloadedPriorsList),
  ]
    .filter((suffix) => suffix.length > 0)
    .join('&');

  return params.length > 0 ? `?${params}` : '';
};

export const useCaseStudyParamsSuffix = (): string => {
  const priorSmids = usePriorStudiesParams();
  const excludedAutoloadedPriorsList = useExcludedAutoloadedPriorParams();

  return getCaseStudyParamsSuffix(priorSmids, excludedAutoloadedPriorsList);
};

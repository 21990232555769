import { Flow } from 'flow-to-typescript-codemod';
import { withStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/InputBase';

import styles from './styles';
import type { Style } from 'styles';

import { NOOP } from 'config/constants';

type SettingsDropdownProps = {
  multiple?: boolean;
  classes: Style;
  children?: React.ReactNode;
  value?: string | number | string[] | ReadonlyArray<string>;
  onSelectedChange?: (v: string | number | string[] | ReadonlyArray<string>) => unknown;
  onClose?: (v: string | number | string[]) => unknown;
  menuId?: string;
  disabled?: boolean;
  displayEmpty?: boolean;
  ['data-testid']?: string;
};

function SettingsDropdownComponent({
  classes,
  children = [],
  value,
  onSelectedChange = NOOP,
  onClose = NOOP,
  menuId = '',
  disabled = false,
  multiple = false,
  'data-testid': dataTestId,
  displayEmpty = false,
}: SettingsDropdownProps): React.ReactElement {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSelectedChange(event.target.value);
  };

  const defaultValue = multiple ? [] : undefined;

  return (
    <Select
      disabled={disabled}
      // @ts-expect-error [EN-7967] - TS2322 - Type 'unknown' is not assignable to type 'string'.
      className={classes.root}
      multiple={multiple}
      MenuProps={{
        id: menuId,
        'data-testid': menuId,
        // Clearing `getContentAnchorEl` is required to anchor the container of generated MenuItems
        // to the parent element; otherwise the container will anchor itself arbitrarily on every
        // render. More info: https://github.com/mui/material-ui/issues/7961#issuecomment-326116559
        getContentAnchorEl: null,
        BackdropProps: {
          id: `${menuId}-backdrop`,
        },
        classes: {
          // @ts-expect-error [EN-7967] - TS2322 - Type 'unknown' is not assignable to type 'string'.
          paper: classes.settingDropDownMenuPaper,
        },
      }}
      renderValue={
        // Allow settings dropdown to compute the value based on children when not in multiple
        multiple
          ? (value: any) => {
              if (Array.isArray(value)) {
                return value.join(', ');
              }
            }
          : undefined
      }
      // @ts-expect-error [EN-7967] - TS2322 - Type 'unknown' is not assignable to type 'string'.
      input={<Input classes={{ input: classes.settingDropDownInput }} />}
      value={value ?? defaultValue}
      displayEmpty={displayEmpty}
      onChange={handleOnChange}
      // @ts-expect-error [EN-7967] - TS2322 - Type '(v: string | number | string[]) => unknown' is not assignable to type '(event: ChangeEvent<{}>) => void'.
      onClose={onClose}
      data-testid={dataTestId}
    >
      {children}
    </Select>
  );
}

const SettingsDropdown: Flow.AbstractComponent<
  Partial<
    Flow.Diff<
      SettingsDropdownProps,
      {
        classes: Style;
      }
    >
  >,
  unknown
  // @ts-expect-error [EN-7967] - TS2345 - Argument of type '() => { [key: string]: string | number | { [key: string]: string | number; }; }' is not assignable to parameter of type 'Styles<Theme, {}, string>'.
> = withStyles(styles)(SettingsDropdownComponent);

export default SettingsDropdown;

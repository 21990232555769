import { atom } from 'recoil';
import type { RecoilState } from 'recoil';
import type { StatefulSplitClient } from './StatefulSplitClient';

export const splitIoState: RecoilState<{
  client: StatefulSplitClient | null;
  ready: boolean;
  timeout: boolean;
  email: string;
}> = atom({
  key: 'splitio',
  default: { client: null, ready: false, timeout: false, email: '' },
  dangerouslyAllowMutability: true,
});

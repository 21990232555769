import styled from 'styled-components';

/**
 * Wrapping components with this component makes Slate ignore them inside of its
 * state and helps to avoid the cannot resolve X... errors. Use it when you are placing React components
 * inside of the <Editable /> component, but don't want to navigate to them with your cursor.
 */
export const NotEditable = styled.span.attrs({
  contentEditable: false,
})`
  user-select: none;
  /* This isn't strictly necessary, but there's something in
     this editor's implementation causing Slate to glitch on a fresh
    render before the field is focusing. */
  pointer-events: none;
`;

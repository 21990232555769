/* global FinalizationRegistry */

import { useMemo, useRef } from 'react';

const registry = new FinalizationRegistry((cleanupRef: any) => {
  cleanupRef.current?.(); // cleanup on GC
});

/**
 * A version of useMemo that allows cleanup when the previous value is garbage collected.
 *
 * Usage:
 * ```
 * const value = useMemoCleanup(() => {
 *   const cleanup = () => {
 *     // cleanup logic
 *   };
 *   return [value, cleanup];
 * }, [deps]);
 * ```
 */
export function useMemoCleanup<T, C extends () => unknown = () => unknown>(
  callback: () => [T, C],
  deps: ReadonlyArray<unknown>
): T {
  const cleanupRef = useRef<C | null | undefined>(null); // holds a cleanup value
  const unmountRef = useRef<boolean>(false); // the GC-triggering candidate

  if (!unmountRef.current) {
    unmountRef.current = true;
    // this works since refs are preserved for the component's lifetime
    registry.register(unmountRef, cleanupRef);
  }

  const returned = useMemo(() => {
    cleanupRef.current?.();
    cleanupRef.current = null;

    const [returned, cleanup] = callback();
    cleanupRef.current = typeof cleanup === 'function' ? cleanup : null;

    return returned;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return returned;
}

import { Operation, Range, Node, Editor } from 'domains/reporter/RichTextEditor/core';
import { pick } from 'ramda';
import type { HistoryType } from '../core';

export type PartialEditor = {
  children: Node[];
  selection: Range | null;
  operations: Operation[];
  log?: {
    ops: Operation[];
  };
  history?: HistoryType;
};

/*
 * This function return an editor-like object which has been stripped of
 * editor methods.
 * Useful only for logging purposes.
 */
export const partialEditor = (
  editor: Editor
): PartialEditor => // $FlowFixMe[prop-missing] Ramda internals will ignore any keys that are not present in the editor
  // $FlowFixMe[incompatible-return] Ramda internals will ignore any keys that are not present in the editor
  // @ts-expect-error [EN-7967] - TS2739 - Type '{}' is missing the following properties from type 'PartialEditor': children, selection, operations
  pick(['children', 'operations', 'selection'], editor);

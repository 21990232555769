import styled from 'styled-components';
import type { ThemedStyledProps } from 'styled-components';
import { TextCss, Colors } from 'styles';
import type { TextVariant } from 'styles/text';

// TODO(jnelson): import from Spacing module once merged
// https://github.com/SironaMedical/services-frontend/pull/1359
const spacings = {
  none: 0,
  xxsmall: 0.2,
  xsmall: 0.5,
  small: 1,
  medium: 1.5,
  large: 2.2,
  xlarge: 3.5,
  xxlarge: 5,
} as const;

const COLOR_MAP = {
  primary: 'gray10',
  secondary: 'gray9',
  'secondary-alt': 'gray8',
  success: 'green5',
  error: 'red5',
  black: 'gray1',
  link: 'blue5',
} as const;

type TextOverflow = 'clip' | 'ellipsis' | 'show';

export type TextProps = Readonly<{
  variant?: TextVariant;
  display?: 'none' | 'inline' | 'block' | 'inline-block' | 'inline-flex';
  color?: string;
  italic?: boolean;
  gutterBottom?: keyof typeof spacings;
  gutterTop?: keyof typeof spacings;
  children?: React.ReactNode;
  overflow?: TextOverflow;
}>;

// @ts-expect-error [EN-7967] - TS2339 - Property 'align' does not exist on type 'Readonly<{ variant?: TextVariant; display?: "none" | "inline" | "block" | "inline-block" | "inline-flex"; color?: string; italic?: boolean; gutterBottom?: "small" | "none" | "large" | ... 4 more ... | "xxlarge"; gutterTop?: "small" | ... 6 more ... | "xxlarge"; children?: ReactNode; overflow?: TextOverflow; }> & The...'.
const TEXT_ALIGN = ({ align = 'inherit' }: ThemedStyledProps<TextProps, never>) => align;
const DISPLAY = ({ display = 'inline' }: ThemedStyledProps<TextProps, never>) => display;
const GUTTER_BOTTOM = ({ gutterBottom = 'none' }: ThemedStyledProps<TextProps, never>) =>
  `${spacings[gutterBottom]}rem`;
const GUTTER_TOP = ({ gutterTop = 'none' }: ThemedStyledProps<TextProps, never>) =>
  `${spacings[gutterTop]}rem`;
const COLOR = ({ color = 'primary' }: ThemedStyledProps<TextProps, never>) =>
  Colors[COLOR_MAP[color as any] ?? color];
const FONT_STYLE = ({ italic = false }: ThemedStyledProps<TextProps, never>) =>
  italic ? 'font-style: italic;' : '';

const Text = styled.span.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    defaultValidatorFn(prop) || ['color', 'stretchY'].includes(prop),
})<TextProps>`
  display: ${DISPLAY};
  text-align: ${TEXT_ALIGN};
  margin-bottom: ${GUTTER_BOTTOM};
  margin-top: ${GUTTER_TOP};
  color: ${COLOR};
  ${FONT_STYLE}
  ${(props: ThemedStyledProps<TextProps, never>) => TextCss[props.variant ?? 'body1']};
  ${(props: ThemedStyledProps<TextProps, never>) =>
    props.overflow === 'ellipsis'
      ? 'text-overflow: ellipsis; white-space: nowrap; overflow: hidden;'
      : ''};
  ${(props: ThemedStyledProps<TextProps, never>) =>
    props.overflow === 'clip' ? 'overflow: hidden;' : ''};
  ${(props: ThemedStyledProps<TextProps, never>) =>
    props.overflow === 'show' ? 'overflow: visible;' : ''};
`;

export default Text;

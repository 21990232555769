const styles = (theme: {
  spacing: (v: number) => number;
}): {
  close: {
    padding: number;
  };
} => ({
  close: {
    padding: theme.spacing(2),
  },
});

export default styles;

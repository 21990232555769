import type { EditorPlugin } from '../../types';
import { onDictaphoneButtonPressDictaphoneControls } from './onDictaphoneButtonPressDictaphoneControls';
import { DICTAPHONE_CONTROLS_PLUGIN_ID } from './types';
import type {
  DictaphoneControlsPluginOptions,
  DictaphoneControlsPluginPropertyOptions,
} from './types';

export const DictaphoneControlsPlugin = ({
  pluginName = 'Dictaphone Controls',
  ...rest
}: DictaphoneControlsPluginOptions = {}): EditorPlugin => {
  const options: DictaphoneControlsPluginPropertyOptions = {
    pluginID: DICTAPHONE_CONTROLS_PLUGIN_ID,
    pluginName,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    onDictaphoneButtonPress: onDictaphoneButtonPressDictaphoneControls(options),
  };
};

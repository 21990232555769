import type { DreMouseEvent } from './types';
import { useState, useCallback, useContext } from 'react';
import { useSetRecoilState } from 'recoil';
import { Contexts } from 'react-vtk-js';
import type { MouseHandlers } from '../types';
import { useMouseToWorldPosition } from './useMouseWorldPosition';
import { useEventAction } from './usePicker';
import { useViewportId, mouseAdaptorsState } from './state';

type Props = {
  onDragStart?: MouseHandlers['onDragStart'] | null | undefined;
  onDrag?: MouseHandlers['onDrag'] | null | undefined;
  onDragEnd?: MouseHandlers['onDragEnd'] | null | undefined;
};

type EventHandlers = {
  onMouseDown: NonNullable<MouseHandlers['onMouseDown']>;
  onMouseUp: NonNullable<MouseHandlers['onMouseUp']>;
};

export const useDragEvents = ({ onDrag, onDragStart, onDragEnd }: Props): EventHandlers => {
  const viewportId = useViewportId();
  const setMouseAdaptorsState = useSetRecoilState(mouseAdaptorsState(viewportId));
  const [primitiveInfo, setPrimitiveInfo] = useState(null);
  const mouseToWorldPosition = useMouseToWorldPosition();
  const view = useContext(Contexts.ViewContext);

  const [dragging, setDragging] = useState(false);

  const getMouseWorldPosition = useCallback(
    async (
      displayPosition?: {
        x: number;
        y: number;
      } | null
    ) => {
      // @ts-expect-error [EN-7967] - TS2339 - Property 'getOpenGLRenderWindow' does not exist on type 'unknown'.
      const canvas = view?.getOpenGLRenderWindow()?.get().getCanvas();
      if (displayPosition == null || canvas == null) return null;

      const { left, top } = canvas.getBoundingClientRect();
      const posX = left + window.pageXOffset;
      const posY = top + window.pageYOffset;
      const elX = displayPosition.x - posX;
      const elY = displayPosition.y - posY;

      return mouseToWorldPosition({ elX, elY });
    },
    [mouseToWorldPosition, view]
  );

  useEventAction('mouseMove', async ({ displayPosition }) => {
    if (dragging == null || primitiveInfo == null || displayPosition == null) {
      return;
    }

    onDrag?.({ ...primitiveInfo, worldPosition: await getMouseWorldPosition(displayPosition) });
  });

  const onMouseDown = useCallback(
    async (info: DreMouseEvent) => {
      setMouseAdaptorsState((state) => ({ ...state, disabled: true }));

      setDragging(true);
      setPrimitiveInfo(info);

      onDragStart?.({ ...info, worldPosition: await getMouseWorldPosition(info.displayPosition) });
    },
    [onDragStart, setMouseAdaptorsState, getMouseWorldPosition]
  );

  const onMouseUp = useCallback(
    async (info: DreMouseEvent) => {
      setMouseAdaptorsState((state) => ({ ...state, disabled: false }));
      if (dragging === false) return;

      setDragging(false);
      setPrimitiveInfo(null);
      onDragEnd?.({ ...info, worldPosition: await getMouseWorldPosition(info.displayPosition) });
    },
    [dragging, onDragEnd, setMouseAdaptorsState, getMouseWorldPosition]
  );

  return {
    onMouseDown,
    onMouseUp,
  };
};

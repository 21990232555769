import type { EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';

export const HISTORY_PLUGIN_ID: 'history' = 'history';
export type HistoryPluginID = typeof HISTORY_PLUGIN_ID;
/**
 * For now, we are not creating undo and redo plugins. Add them as separate plugins if we need to control the hotkey
 * or if there would be a circumstance where you would want an editor to only have undo
 * or redo powers.
 */

export type HistoryPluginOptions = Readonly<EditorPluginOptions>;

export type HistoryPluginPropertyOptions = Readonly<
  EditorPluginPropertyOptions &
    HistoryPluginOptions & {
      pluginID: HistoryPluginID;
      pluginName: string;
    }
>;

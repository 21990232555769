import type { DreAllViewportTypes } from 'config/constants';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';
import { useImagingContext, useActiveSliceForViewport } from './imaging/ImagingContext';
import { useViewportId, useViewType } from './state';
import { useEffect, useState } from 'react';

/*
 * this hook will always return a vtkImageData object
 * that can be used to get information about the currently
 * active slice. for 3dable volumes, this is provider.vtkImage,
 * which holds the 3dable volume used to nicely lay out slices for imaging
 * in all providers. For a non3dable provider, this hook will return
 * the imageData specific to the current slice, containing only the data
 * for and about that slice. This has many sneaky implications, including that
 * you cannot use the result of this hook to reason about a random position
 * in a frame of reference, because the point may be closer to another slice
 * and it may be more accurate to use that slice's imageData.  The goal is to
 * move as much of this logic as possible into the imaging providers themselves.
 * The stated hard case is solved by imageProvider.getSliceForWorldPosition().
 * There are many cases in places like annotations and mouse position where it
 * is important for us to have access to both pieces of information --
 * where the slice is in world space, and where the slice is drawn in the 3dable volume.
 * it's important to think carefully about which imageData you need for your use case
 */

export type ImageWrapper = {
  data: vtkImageData;
};

export const useOptionalActiveSliceImageForViewport = (
  viewportId: string,
  viewType: DreAllViewportTypes
): vtkImageData | null | undefined => {
  const { imagingProvider } = useImagingContext();
  const activeSlice = useActiveSliceForViewport(viewportId, viewType);
  const frameSmid = imagingProvider?.frameSmidsMap[activeSlice];

  const [, rerender] = useState({});

  useEffect(() => {
    if (imagingProvider == null) return;

    const onImageChange = () => rerender({});
    const eventListenerId = imagingProvider.onImageChange(viewportId, viewType, onImageChange);

    return () => {
      imagingProvider.offImageChange(eventListenerId);
    };
  }, [imagingProvider, viewType, viewportId]);

  if (imagingProvider == null) return null;
  const image = imagingProvider.getImage(frameSmid);

  return image;
};

export const useActiveSliceImage = (): vtkImageData | null | undefined => {
  const viewportId = useViewportId();
  const viewType = useViewType();
  return useOptionalActiveSliceImageForViewport(viewportId, viewType);
};

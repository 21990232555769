import { FF } from 'modules/feature-flags';
import { useSplitFlag } from 'modules/feature-flags/useSplitFlag';
import { useLayouts } from '../Viewer/StudyLoader/useLayouts';
import { generateViewportId, getViewportGrid } from '../Viewer/viewerUtils';

import { useVirtualMonitorSplitToggle } from 'hooks/useVirtualMonitorSplitToggle';

import { useRecoilState, useRecoilValue } from 'recoil';
import { viewportUpIdSelector, viewportUpIds } from '../Viewer/viewerState';

export const useRenderedViewportIds = (): Array<string> => {
  const viewportIds = useRecoilValue(viewportUpIds);
  const [virtualMonitorSplit] = useVirtualMonitorSplitToggle();
  const { layoutsForCase: layouts } = useLayouts();
  const [minColumnsToEnableMultipleExpansionAreas] = useSplitFlag(
    FF.MIN_COLUMNS_TO_ENABLE_MULTIPLE_EXPANSION_AREAS,
    '2',
    (value) => Number(value ?? 2)
  );

  return Object.entries(layouts).flatMap(([windowId, layout]: [any, any]) => {
    return Array.from(
      new Set(
        getViewportGrid(
          viewportIds[windowId] ?? [],
          (row, col) => generateViewportId(windowId, row, col),
          layout,
          virtualMonitorSplit,
          minColumnsToEnableMultipleExpansionAreas
        ).flat()
      )
    );
  });
};

export const useRenderedViewportIdsForWindow = (windowId: string): Array<string> => {
  const [viewportIds] = useRecoilState(viewportUpIdSelector(windowId));
  const [virtualMonitorSplit] = useVirtualMonitorSplitToggle();
  const { layoutForWindow: layout } = useLayouts();
  const [minColumnsToEnableMultipleExpansionAreas] = useSplitFlag(
    FF.MIN_COLUMNS_TO_ENABLE_MULTIPLE_EXPANSION_AREAS,
    '2',
    (value) => Number(value ?? 2)
  );

  return Array.from(
    new Set(
      getViewportGrid(
        viewportIds,
        (row, col) => generateViewportId(windowId, row, col),
        layout ?? [0, 0],
        virtualMonitorSplit,
        minColumnsToEnableMultipleExpansionAreas
      ).flat()
    )
  );
};

import { Range } from 'domains/reporter/RichTextEditor/core';
import { createContext, useContext, useState, useEffect } from 'react';

export type RangeMasksState = Array<Range>;
export type RangeMasksDispatch = (
  arg1: ((arg1: RangeMasksState) => RangeMasksState) | RangeMasksState
) => void;

const RangeMasksStateContext = createContext<RangeMasksState | null | undefined>(undefined);
const RangeMasksDispatchContext = createContext<RangeMasksDispatch | null | undefined>(undefined);

export type RangeMask = Range;

export type RangeMaskProps = {
  children: React.ReactNode;
  onChange?: (rangeMasks: RangeMask[]) => void;
};

/**
 * A range mask allows us to create a decoration that will hide other decorations and still be applied
 * inside of Slate. For example, if there are three decorations applied to the same range and one of them
 * is a range mask that will be the only one applied to the range.
 */
export const RangeMasksProvider = ({ children, onChange }: RangeMaskProps): React.ReactElement => {
  const [rangeMasksState, setRangeMasksState] = useState<RangeMask[]>([]);

  useEffect(() => {
    onChange?.(rangeMasksState);
  }, [rangeMasksState, onChange]);

  return (
    <RangeMasksStateContext.Provider value={rangeMasksState}>
      <RangeMasksDispatchContext.Provider value={setRangeMasksState}>
        {children}
      </RangeMasksDispatchContext.Provider>
    </RangeMasksStateContext.Provider>
  );
};

export const useRangeMasksState = (): RangeMasksState => {
  const rangeMasksState = useContext(RangeMasksStateContext);

  if (!rangeMasksState) {
    throw new Error('useRangeMasksState must be used within a RangeMasksProvider.');
  }

  return rangeMasksState;
};

export const useRangeMasksDispatch = (): RangeMasksDispatch => {
  const rangeMasksDispatch = useContext(RangeMasksDispatchContext);

  if (!rangeMasksDispatch) {
    throw new Error('useRangeMasksDispatch must be used within a RangeMasksProvider.');
  }

  return rangeMasksDispatch;
};

export const useRangeMasks = (): [RangeMasksState, RangeMasksDispatch] => {
  return [useRangeMasksState(), useRangeMasksDispatch()];
};

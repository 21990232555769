import { forwardRef } from 'react';
import { Geometry2DRepresentation } from 'react-vtk-js';
import type { Geometry2DRepresentationProps } from 'react-vtk-js';

const BatchedGeometry2DRepresentationWrapper: React.ComponentType<Geometry2DRepresentationProps> =
  forwardRef((props: Geometry2DRepresentationProps, ref) => {
    return <Geometry2DRepresentation {...props} ref={ref} />;
  });

BatchedGeometry2DRepresentationWrapper.displayName = 'BatchedGeometry2DRepresentationWrapper';

export default BatchedGeometry2DRepresentationWrapper;

import type { EditorPlugin } from '../../types';
import { HighlightedEditable } from './components';
import { getRenderLeaf } from '../../utils';
import { HIGHLIGHTED_PLUGIN_ID } from './types';
import type { HighlightedPluginOptions, HighlightedPluginPropertyOptions } from './types';
import { getDecorate } from './decorate';

export const HighlightedPlugin = ({
  component = HighlightedEditable,
  pluginName = 'Highlighted',
  words = [],
  ...rest
}: HighlightedPluginOptions): EditorPlugin => {
  const options: HighlightedPluginPropertyOptions = {
    pluginID: HIGHLIGHTED_PLUGIN_ID,
    pluginName,
    component,
    words,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    renderLeaf: getRenderLeaf(options),
    decorate: getDecorate(options),
  };
};

import MuiDivider from '@material-ui/core/Divider';
import { Colors } from 'styles';

export const Divider = (props: Record<any, any>): React.ReactElement => (
  <MuiDivider
    {...props}
    css={`
      background-color: ${Colors.gray4};
    `}
  />
);

import { useEffect, useMemo, useRef, useState } from 'react';
import {
  ButtonGroup as StyledButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import { AccessAlarm, ArrowDropDownOutlined } from '@material-ui/icons';
import styled, { css } from 'styled-components';

import { Button } from 'common/ui/Button';
import {
  REPORT_EDITABLE_STATUSES,
  reporterIsBypassAndSign,
  ReportStatus,
  IN_FLIGHT_STATUSES,
} from '../state';
import getTimeRemaining from 'utils/getTimeRemaining';
import { useRecorder } from 'common/Recorder/useRecorder';
import { unreachableCaseError } from 'types';
import Lock from '@material-ui/icons/Lock';
import { Stack } from 'common/ui/Layout';
import { Colors } from 'styles';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';
import { useRecoilState, useRecoilValue } from 'recoil';
import type { ReportStatuses } from 'domains/reporter/Reporter/state';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReporterSaveState, reporterSavingState } from 'config/recoilState';
import Tooltip from 'common/ui/Tooltip';

const FINAL_COUNTDOWN_TIME_SECONDS = 60;

const getButtonContentForIdleStatus = ({
  isRecording,
  isOtherCaseInGroupSubmitting,
  isBypassAndSign,
}: {
  isRecording: boolean;
  isOtherCaseInGroupSubmitting: boolean;
  isBypassAndSign: boolean;
}) => {
  if (isRecording) {
    return 'Recording...';
  }
  if (isOtherCaseInGroupSubmitting) {
    return (
      <>
        <Lock
          css={`
            margin-right: 0.5rem;
            margin-left: -1rem;
          `}
        />
        Signing With Group
      </>
    );
  }
  return isBypassAndSign ? 'Bypass and Sign' : 'Sign';
};

const getButtonContentByReportStatus = ({
  status,
  isClaimedByMe = false,
  isRecording,
  isOtherCaseInGroupSubmitting,
  isBypassAndSign,
}: {
  status: ReportStatuses;
  isClaimedByMe: boolean;
  isRecording: boolean;
  isOtherCaseInGroupSubmitting: boolean;
  isBypassAndSign: boolean;
}): React.ReactElement => {
  switch (status) {
    case ReportStatus.Init:
    case ReportStatus.Idle:
      // @ts-expect-error [EN-7967] - TS2322 - Type 'Element | "Sign" | "Recording..." | "Bypass and Sign"' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
      return getButtonContentForIdleStatus({
        isRecording,
        isOtherCaseInGroupSubmitting,
        isBypassAndSign,
      });
    case ReportStatus.Submitting:
      // @ts-expect-error [EN-7967] - TS2322 - Type 'string' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
      return 'Signing...';
    case ReportStatus.ProvisionalSubmit:
    case ReportStatus.Cancelling:
      // Submit is the default state so this makes it show consistent regardless of the underlying state
      // @ts-expect-error [EN-7967] - TS2322 - Type 'string' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
      return isClaimedByMe ? 'Cancel Signing' : 'Sign';
    case ReportStatus.Submitted:
      // @ts-expect-error [EN-7967] - TS2322 - Type 'string' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
      return 'Report Signed';
    case ReportStatus.AddendumAdd:
    case ReportStatus.AddendumSubmitting:
      // @ts-expect-error [EN-7967] - TS2322 - Type 'string' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
      return isRecording
        ? 'Recording...'
        : isBypassAndSign
          ? 'Bypass and Sign Addendum'
          : 'Sign Addendum';
    case ReportStatus.ProvisionalAddendumSubmit:
    case ReportStatus.AddendumCancelling:
      // @ts-expect-error [EN-7967] - TS2322 - Type 'string' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
      return 'Cancel Addendum';
    default:
      return unreachableCaseError(status);
  }
};

const options = ['Sign', 'Bypass and sign'];

const ButtonGroup = styled(StyledButtonGroup)`
  .dropdown-button {
    padding: 0;
  }
`;

export default function FieldsSubmitButton({
  countdownSeconds,
  status,
  onSubmit,
  onCancelSubmit,
  disabled,
  isClaimedByMe,
  isOtherCaseInGroupSubmitting,
  isCountdownMode,
}: {
  countdownSeconds: number | null;
  status: ReportStatuses;
  onSubmit: (() => void) | (() => Promise<void>);
  onCancelSubmit: (() => void) | (() => Promise<void>);
  disabled: boolean;
  isClaimedByMe: boolean;
  isOtherCaseInGroupSubmitting: boolean;
  isCountdownMode: boolean;
}): React.ReactElement {
  const [splitButtonOpen, setSplitButtonOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [selectedIndex, setSelectedIndex] = useState(0); // 0 is Sign, 1 is Bypass and Sign
  const time = getTimeRemaining(countdownSeconds);
  const isInFinalCountdownState = (countdownSeconds ?? -1) <= FINAL_COUNTDOWN_TIME_SECONDS;
  const { isRecording } = useRecorder();
  const [isBypassSignEnabled] = useFeatureFlagEnabled(FF.REPORTER_BYPASS_SIGNING);
  const [isBypassAndSign, setIsBypassAndSign] = useRecoilState(reporterIsBypassAndSign);
  const reportSaveState = useRecoilValue(reporterSavingState);

  const isReportSaving = useMemo(() => {
    return reportSaveState === ReporterSaveState.Saving;
  }, [reportSaveState]);

  useEffect(() => {
    setIsBypassAndSign(isBypassSignEnabled && selectedIndex === 1);
  }, [isBypassSignEnabled, selectedIndex, setIsBypassAndSign]);

  let variant = 'primary';
  switch (status) {
    case ReportStatus.Idle:
    case ReportStatus.AddendumAdd:
    case ReportStatus.Submitting:
    case ReportStatus.Cancelling:
    case ReportStatus.AddendumSubmitting:
    case ReportStatus.AddendumCancelling:
      break;
    case ReportStatus.ProvisionalSubmit:
    case ReportStatus.ProvisionalAddendumSubmit:
      variant = isInFinalCountdownState ? 'error' : 'yellow';
      break;
    case ReportStatus.Submitted:
    case ReportStatus.Init:
      variant = 'secondary';
      break;

    default:
      return unreachableCaseError(status);
  }

  // Will disable the button if receives disabled prop, in-flight statuses matches the current status,
  // the nvoq queue of pending stable text is not empty or if actively recording
  // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'ReportStatuses' is not assignable to parameter of type '"idle" | "init" | "addendumAdd"'.
  const isRecordingProcessing = REPORT_EDITABLE_STATUSES.includes(status) && isRecording;
  const isButtonDisabled =
    disabled || isRecordingProcessing || isOtherCaseInGroupSubmitting || isReportSaving;

  const handleMenuItemClick = (event: Event, index: number) => {
    setSelectedIndex(index);
    setSplitButtonOpen(false);
  };

  const handleToggle = () => {
    setSplitButtonOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'EventTarget' is not assignable to parameter of type 'Node'.
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setSplitButtonOpen(false);
  };

  return (
    <Stack
      stretchX
      stretchY
      alignY="center"
      css={css`
        padding: 4px 0px;
        width: 100%;
      `}
    >
      {isCountdownMode && (
        <Stack
          stretchY
          css={`
            position: relative;
            background-color: ${isInFinalCountdownState ? Colors.gray10 : Colors.yellow6};
            color: ${Colors.gray1};
            display: flex;
            align-items: center;
            border-radius: 2.5rem;
            right: -5rem;
            width: 13rem;
            padding-left: 1rem;
            height: 100%;
            padding: 0.36rem;
          `}
        >
          <AccessAlarm
            css={`
              color: ${isInFinalCountdownState ? Colors.red2 : 'inherit'};
              margin-right: 0.5rem;
            `}
          />
          {time}
        </Stack>
      )}
      <Tooltip
        backgroundColor={Colors.gray7}
        color={Colors.gray1}
        content={isReportSaving ? 'Cannot sign report until changes are saved.' : null}
      >
        <ButtonGroup variant="contained" ref={anchorRef} aria-label="Button group for signing">
          <Button
            // @ts-expect-error [EN-7967] - TS2322 - Type 'string' is not assignable to type '"error" | "primary" | "yellow" | "light" | "secondary" | "secondary-alt" | "ghost" | "ghost-blue" | "ghost-error"'.
            variant={variant}
            disabled={isButtonDisabled}
            disableRipple
            icon={status === ReportStatus.Submitted ? <Lock /> : null}
            onClick={() => {
              if (
                [ReportStatus.ProvisionalSubmit, ReportStatus.ProvisionalAddendumSubmit].includes(
                  // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'ReportStatuses' is not assignable to parameter of type '"provisionalReportSubmit" | "provisionalAddendumSubmit"'.
                  status
                )
              ) {
                onCancelSubmit();
              } else {
                onSubmit();
              }
            }}
            data-status={status}
            data-testid="sign-button"
            css={`
              white-space: nowrap;
              padding: 4px ${isCountdownMode ? '2.5rem' : '24px'};
            `}
          >
            {getButtonContentByReportStatus({
              status,
              isClaimedByMe,
              isRecording,
              isOtherCaseInGroupSubmitting,
              isBypassAndSign,
            })}
          </Button>
          {/* @ts-expect-error [EN-7967] - TS2345 - Argument of type 'ReportStatuses' is not assignable to parameter of type '"idle" | "init" | "addendumAdd"'. */}
          {isBypassSignEnabled && REPORT_EDITABLE_STATUSES.includes(status) && (
            <Button
              data-testid="submit-split-button"
              className="dropdown-button"
              // @ts-expect-error [EN-7967] - TS2322 - Type 'string' is not assignable to type '"error" | "primary" | "yellow" | "light" | "secondary" | "secondary-alt" | "ghost" | "ghost-blue" | "ghost-error"'.
              variant={variant}
              size="small"
              disabled={isButtonDisabled}
              disableRipple
              aria-controls={splitButtonOpen ? 'split-button-menu' : undefined}
              aria-expanded={splitButtonOpen ? 'true' : undefined}
              aria-haspopup="menu"
              onClick={() => {
                !isButtonDisabled && handleToggle();
              }}
            >
              <ArrowDropDownOutlined />
            </Button>
          )}
        </ButtonGroup>
      </Tooltip>
      {/* @ts-expect-error [EN-7967] - TS2345 - Argument of type 'ReportStatuses' is not assignable to parameter of type '"submitting" | "cancelling" | "addendumSubmitting" | "addendumCancelling"'. */}
      {IN_FLIGHT_STATUSES.includes(status) && (
        <CircularProgress style={{ marginLeft: '8px' }} size={16} />
      )}
      {isBypassSignEnabled && (
        <Popper open={splitButtonOpen} anchorEl={anchorRef.current} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                {/* @ts-expect-error [EN-7967] - TS2322 - Type '(event: Event) => void' is not assignable to type '(event: MouseEvent<Document, MouseEvent>) => void'. */}
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'MouseEvent<HTMLLIElement, MouseEvent>' is not assignable to parameter of type 'Event'.
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </Stack>
  );
}

FieldsSubmitButton.displayName = 'FieldsSubmitButton';

/*
 * Creates a promise, which will resolve either when the returned trigger function is called,
 * or after a timeout, whichever comes first.
 */
export const createLockingFunction = (
  timeout: number
): {
  promise: Promise<undefined>;
  trigger: () => void;
} => {
  let resolvePromise: () => void | null | undefined;
  let timer: number | null | undefined;

  // Create a promise with an externally accessible resolver
  const promise = new Promise((resolve: (result: Promise<undefined> | undefined) => void) => {
    // @ts-expect-error [EN-7967] - TS2322 - Type '(result: Promise<undefined>) => void' is not assignable to type '() => void'.
    resolvePromise = resolve;
  });

  // Function to clean up and resolve the promise
  const resolveAndCleanup = () => {
    clearTimeout(timer);
    timer = null;
    resolvePromise && resolvePromise();
    resolvePromise = null;
  };

  // Function that can be called to resolve the promise
  const trigger = () => {
    resolveAndCleanup();
  };

  // Set a timeout to resolve the promise if trigger isn't called
  // @ts-expect-error [EN-7967] - TS2322 - Type 'Timeout' is not assignable to type 'number'.
  timer = setTimeout(() => {
    resolveAndCleanup();
  }, timeout);

  return { promise, trigger };
};

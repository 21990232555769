import { useViewerContext } from 'domains/viewer/Viewer/ViewerContext';
import type { ViewportsConfigurations } from 'domains/viewer/ViewportsConfigurations';
import { selectorFamily } from 'recoil';
import { useImagingContext, useActiveSlice } from '../../modules/imaging/ImagingContext';
import { activeViewportState } from 'config/recoilState';
import { viewportDisplayConfigurationSelector } from '../../../ViewportsConfigurations/state';
import type { Stack, ViewportDisplayConfiguration } from '../../../ViewportsConfigurations/types';
import type { RecoilState, RecoilValueReadOnly } from 'recoil';
import type { TStackProviders } from '../../../Viewer/ViewerContext';
import type { BaseImagingProvider } from '../../modules/imaging/BaseImagingProvider';

export type ViewportsDisplayConfigurations = {
  [key: string]: ViewportDisplayConfiguration | null | undefined;
};
export const makePayloadGetterSelector: (arg1: {
  viewportsConfigurationsSelector: RecoilState<ViewportsConfigurations | null | undefined>;
}) => RecoilValueReadOnly<{
  activeViewportId: string;
  viewportsConfigurations: ViewportsConfigurations | null | undefined;
  viewportsDisplayConfigurations: ViewportsDisplayConfigurations;
}> = selectorFamily({
  key: 'sdk.payloadGetter',
  get:
    ({ viewportsConfigurationsSelector }) =>
    ({ get }) => {
      const viewportsConfigurations: ViewportsConfigurations | null | undefined = get(
        viewportsConfigurationsSelector
      );
      const viewportsDisplayConfigurations: ViewportsDisplayConfigurations =
        viewportsConfigurations != null
          ? Object.fromEntries(
              Object.entries(viewportsConfigurations).map(([id, config]: [any, any]) => [
                id,
                get(
                  viewportDisplayConfigurationSelector({
                    stackSmid: config?.stack?.smid,
                    viewType: config?.viewType,
                    viewportId: id,
                  })
                ),
              ])
            )
          : {};

      return {
        activeViewportId: get(activeViewportState),
        viewportsConfigurations: viewportsConfigurations,
        viewportsDisplayConfigurations: viewportsDisplayConfigurations,
      };
    },
});

export function useMockableContext(): {
  stackProviders: TStackProviders;
  imagingProvider: BaseImagingProvider<Stack> | null | undefined;
  activeSlice: number;
} {
  const { stackProviders } = useViewerContext();
  const { imagingProvider } = useImagingContext();
  const activeSlice = useActiveSlice();

  return {
    stackProviders,
    imagingProvider,
    activeSlice,
  };
}

import type { InlineBookmarkPluginElement } from '../types';
import { ReadonlyInlineTemplateElement } from '../../common/inlineTemplate';
import { forwardRef } from 'react';

type ReadonlyInlineBookmarkElementProps = {
  selected?: boolean;
  element: InlineBookmarkPluginElement;
  children: React.ReactNode;
  leftDelimiter?: string;
  rightDelimiter?: string;
  ['data-editor-element']?: string;
  ['data-slate-node']?: 'element';
  ['data-slate-inline']?: true;
  ['data-slate-void']?: true;
  dir?: 'rtl';
};

export const ReadonlyInlineBookmarkElement = forwardRef<
  HTMLSpanElement,
  ReadonlyInlineBookmarkElementProps
>(({ selected, element, leftDelimiter, rightDelimiter, ...rest }, ref) => {
  return (
    <ReadonlyInlineTemplateElement
      ref={ref}
      {...rest}
      selected={selected}
      testID="inlineBookmark"
      data-selected={selected}
      element={element}
      leftDelimiter={leftDelimiter}
      rightDelimiter={rightDelimiter}
    />
  );
});
ReadonlyInlineBookmarkElement.displayName = 'ReadonlyInlineBookmarkElement';

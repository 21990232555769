import type { HeadingErrorPluginElement, HeadingErrorPluginPropertyOptions } from '../types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core';

import type { GetRenderElementComponentType } from '../../../utils/getRenderElement';
import { Colors } from 'styles';
import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';
import Text from 'common/ui/Text';
import { Stack } from 'common/ui/Layout';

const HeadingErrorWrapper = styled.span`
  margin: 0;
  color: ${Colors.red6};
  display: inline;
`;

const TooltipError = withStyles({
  tooltip: {
    background: Colors.gray8,
    fontSize: 14,
    color: Colors.gray1,
    fontWeight: 400,
    padding: '4px 8px',
    maxWidth: 'none',
  },
})(Tooltip);

export const HeadingErrorEditable: GetRenderElementComponentType<
  HeadingErrorPluginPropertyOptions,
  HeadingErrorPluginElement
> = ({ children, attributes, element, ...rest }) => {
  return (
    <TooltipError
      placement="bottom"
      arrow
      title={
        <Stack stretchX alignY="center" space="xsmall">
          <WarningIcon
            css={`
              margin-right: 6px;
              font-size: 1.5rem;
              color: ${Colors.red3};
            `}
          />
          <Text color="black">Duplicate header name. Please use another name.</Text>
        </Stack>
      }
    >
      <HeadingErrorWrapper>{children}</HeadingErrorWrapper>
    </TooltipError>
  );
};

export const HeadingErrorRenderer = styled(HeadingErrorEditable)`
  white-space: pre-wrap;
`;

import { PathRef, Node } from 'domains/reporter/RichTextEditor/core';
import { Stack } from 'common/ui/Layout';
import { css } from 'styled-components';
import { useSlateSingletonContext } from '../SlateSingletonContext';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';
import { Range, Transforms, Editor } from '../../RichTextEditor/core';
import { isHeadingNode } from '../../RichTextEditor/plugins/heading/utils/normalization';
import Text from 'common/ui/Text';
import { Divider } from 'common/ui/Divider';
import { PICKLIST_PLUGIN_ID } from '../../RichTextEditor/plugins/picklist/types';
import List from '@material-ui/icons/List';
import { Colors } from 'styles';
import { selectNavigationItem } from './utils';
import { transparentize } from 'color2k';
import { HEADING_PLUGIN_ID } from '../../RichTextEditor/plugins/heading/types';
import { PicklistOptionsOutline } from './PicklistOptionsOutline';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import { createInlineBookmark } from '../../RichTextEditor/plugins/inlineBookmark/utils';
import TextWithTooltip from 'common/TextWithTooltip';

export const REPORT_OUTLINE_WIDTH = 176;

export type NavigationItem = {
  name: string;
  node: Node;
  path: PathRef | null | undefined;
};

type BaseOutlineProps = {
  navigationItems: Array<NavigationItem>;
  isDisabled: boolean;
  enableCreation?: boolean;
};

export const BaseOutline = ({
  navigationItems = [],
  isDisabled = false,
  enableCreation = false,
}: BaseOutlineProps): React.ReactElement | null => {
  const [isReportOutlineEnabled] = useFeatureFlagEnabled(FF.REPORT_OUTLINE);
  const [{ editor }] = useSlateSingletonContext();

  if (!isReportOutlineEnabled) {
    return null;
  }

  return (
    <Stack
      data-testid="outline"
      vertical
      css={css`
        width: ${REPORT_OUTLINE_WIDTH}px;
        min-width: ${REPORT_OUTLINE_WIDTH}px;
        overflow: auto;
        gap: 4px;
      `}
    >
      <Stack
        vertical
        css={css`
          position: relative;
        `}
      >
        <div
          css={`
            display: flex;
            flex-direction: column;
            gap: 4px;
          `}
        >
          <Stack stretchX alignX="between" alignY="center">
            <Text
              variant="body1"
              css={css`
                font-size: 13px;
              `}
            >
              Field Outline
            </Text>
            {enableCreation && editor != null && (
              <IconButton
                data-testid="create-field"
                css={`
                  padding: 0;
                `}
                onClick={(e) => {
                  e.preventDefault();
                  const insertPoint = editor.selection ?? Editor.end(editor, []);
                  Transforms.insertNodes(editor, [createInlineBookmark()], {
                    at: insertPoint,
                  });
                  Transforms.select(editor, Editor.after(editor, insertPoint) ?? insertPoint);
                }}
              >
                <AddCircleIcon
                  css={`
                    width: 16px;
                    height: 16px;
                    color: ${Colors.gray8};
                  `}
                />
              </IconButton>
            )}
          </Stack>
          <Divider
            variant="fullWidth"
            css={`
              background-color: ${transparentize(Colors.gray10, 0.88)};
              height: 1px;
            `}
          />
        </div>
        <Stack vertical css="margin-top: 4px">
          {navigationItems.map((item, idx) => {
            const selection = editor?.selection;
            const itemPath =
              item.path != null && item.path.current != null ? item.path.current : null;
            const isSelected =
              selection != null && itemPath != null && Range.includes(selection, itemPath);
            const isPicklist = item.node.type === PICKLIST_PLUGIN_ID;

            return (
              <Stack vertical key={idx}>
                <Stack
                  data-testid={`report-outline-${
                    String(item.node.type) === HEADING_PLUGIN_ID ? 'heading' : 'field'
                  }`}
                  key={idx}
                  alignX="between"
                  alignY="center"
                  onClick={() => {
                    if (!isDisabled && editor != null && itemPath != null) {
                      selectNavigationItem(editor, item);
                    }
                  }}
                  css={css`
                    color: ${isSelected ? Colors.blue4 : Colors.gray8};
                    cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
                    margin-left: ${!isHeadingNode(item.node) ? '24px' : '0px'};
                    gap: 4px;

                    &:hover {
                      color: ${!isDisabled ? Colors.gray10 : Colors.gray8};
                    }
                  `}
                >
                  {isHeadingNode(item.node) ? (
                    <TextWithTooltip
                      customEllipsisCss={{
                        maxWidth: REPORT_OUTLINE_WIDTH,
                        textOverflow: 'ellipsis',
                        fontSize: '1.3rem',
                        lineHeight: '20px',
                      }}
                      conditionalTooltip={true}
                      name={item.name}
                    />
                  ) : (
                    <div
                      css={css`
                        font-size: 1.3rem;
                        line-height: 20px;
                      `}
                    >
                      {item.name}
                    </div>
                  )}
                  {isPicklist && (
                    <List
                      css={css`
                        font-size: 14px;
                      `}
                    />
                  )}
                </Stack>
                {isPicklist && isSelected && <PicklistOptionsOutline />}
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};

import { useMutation } from '@apollo/client';
import type { SeriesSkipSettings } from 'generated/graphql';
import { UPDATE_SERIES_SKIP_AMOUNT_SETTINGS } from 'modules/Apollo/queries';
import { useCurrentUser } from './useCurrentUser';
import { GET_ME } from '../modules/Apollo/queries';
import { assocPath } from 'ramda';
import { notifyUserSettingsUpdate } from './useViewerSettingsSync';
import type { ApolloCache } from '@apollo/client';

export const useSeriesSkipSettings = (): [
  SeriesSkipSettings | null,
  (arg1: SeriesSkipSettings | null) => void,
] => {
  const { data, refetch: refetchUser } = useCurrentUser();
  const me = data?.me;
  const seriesSkipSettings = me?.viewerSettings?.seriesSkipSettings ?? null;

  const [saveSeriesSkipAmountSettings] = useMutation(UPDATE_SERIES_SKIP_AMOUNT_SETTINGS, {
    onCompleted: () => {
      // refetchUser();
      notifyUserSettingsUpdate();
    },
    onError: () => {
      refetchUser();
    },
    update(cache: ApolloCache<unknown>, res, { variables }) {
      if (me == null) {
        return;
      }

      const { seriesSkipSettings } = variables ?? {
        seriesSkipSettings: {
          amount: null,
          keepPreviousWhenAvailable: true,
        },
      };

      cache.writeQuery({
        query: GET_ME,
        data: assocPath(['me', 'viewerSettings', 'seriesSkipSettings'], seriesSkipSettings, data),
      });
    },
  });

  const updateSeriesSkipAmountSettings = (seriesSkipSettings: SeriesSkipSettings | null) => {
    saveSeriesSkipAmountSettings({
      variables: {
        seriesSkipSettings,
      },
      optimisticResponse: {
        updateSeriesSkipSettings: {
          seriesSkipSettings: {
            ...seriesSkipSettings,
            __typename: 'SeriesSkipSettings',
          },
          __typename: 'SeriesSkipSettingsResponse',
        },
      },
    });
  };

  return [seriesSkipSettings, updateSeriesSkipAmountSettings];
};

import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles';
import { SnackbarContent } from '@material-ui/core';
import { Colors } from 'styles';
import { EMPTY } from 'config/constants';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteForever from '@material-ui/icons/DeleteForever';

const RedWarning: React.ReactNode = <WarningIcon style={{ color: `${Colors.red6}` }} />;
const RedDeleteForever: React.ReactNode = <DeleteForever style={{ color: `${Colors.red6}` }} />;
const YellowWarning: React.ReactNode = <WarningIcon style={{ color: `${Colors.yellow5}` }} />;
const GreenCheck: React.ReactNode = <CheckCircleIcon style={{ color: `${Colors.green6}` }} />;

const severityColorsMap = {
  success: Colors.green4,
  error: Colors.red4,
  info: Colors.blue4,
  default: Colors.gray5,
} as const;

const iconsMap = {
  redWarning: RedWarning,
  yellowWarning: YellowWarning,
  greenCheck: GreenCheck,
  redDeleteForever: RedDeleteForever,
} as const;

export type SnackBarIcon = keyof typeof iconsMap;

export type SnackbarContentProps = {
  action: React.ReactNode;
  classes: {
    [key: string]: unknown;
  };
  message: React.ReactNode;
  role: string;
  reduceCloseButtonHeight?: boolean;
};

export type SnackbarContentClassKey = 'root' | 'message' | 'action';

export type SnackbarCloseReason = 'timeout' | 'clickaway';

export type ClickawayListenerProps = {
  /**
   * The wrapped element.
   */
  children: React.ReactNode;
  /**
   * If `true`, the React tree is ignored and only the DOM tree is considered.
   * This prop changes how portaled elements are handled.
   */
  disableReactTree?: boolean;
  /**
   * The mouse event to listen to. You can disable the listener by providing `false`.
   */
  mouseEvent?: 'onClick' | 'onMouseDown' | 'onMouseUp' | false;
  /**
   * Callback fired when a "click away" event is detected.
   */
  onClickAway: (event: Event) => void;
  /**
   * The touch event to listen to. You can disable the listener by providing `false`.
   */
  touchEvent?: 'onTouchStart' | 'onTouchEnd' | false;
};

export type GenericSnackBarProps = {
  /**
   * Styles for this class
   */
  classes?: {
    [key: string]: unknown;
  };
  /**
   * Message to show in snack bar
   */
  msg: SnackbarContentProps['message'];
  /**
   * Name of snack bar action
   */
  actionName?: React.ReactNode;
  /**
   * Function to invoke on action click
   */
  actionHandler?: (e: Event) => undefined | Promise<undefined>;
  /**
   * Color of the action button
   */
  actionColor?: string;
  /**
   * Snackbar severity
   */
  severity?: keyof typeof severityColorsMap;
  /**
   * Icon to display at the left of the SnackBar, before the message
   */
  icon?: SnackBarIcon;
  /**
   * Optional string to use for a data-testid property on the snackbar
   */
  ['data-testid']?: string;
  /**
   * The action to display. It renders after the message, at the end of the snackbar.
   */
  action?: SnackbarContentProps['action'];
  /**
   * The vertical anchor of the `Snackbar`.
   */
  vertical?: 'top' | 'bottom';
  /**
   * The horizontal anchor of the `Snackbar`.
   */
  horizontal?: 'left' | 'center' | 'right';
  /**
   * The number of milliseconds to wait before automatically calling the
   * `onClose` function. `onClose` should then set the state of the `open`
   * prop to hide the Snackbar. This behavior is disabled by default with
   * the `null` value.
   */
  duration?: number | null;
  autoHideDuration?: number | null;
  /**
   * Replace the `SnackbarContent` component.
   */
  // @ts-expect-error [EN-7967] - TS2344 - Type 'unknown' does not satisfy the constraint 'JSXElementConstructor<any> | keyof IntrinsicElements'.
  children?: React.ReactElement<React.ComponentProps<unknown>>;
  /**
   * Props applied to the `ClickAwayListener` element.
   */
  ClickAwayListenerProps?: Partial<ClickawayListenerProps>;
  /**
   * Props applied to the [`SnackbarContent`](/api/snackbar-content/) element.
   */
  ContentProps?: Partial<SnackbarContentProps>;
  /**
   * If `true`, the `autoHideDuration` timer will expire even if the window is not focused.
   */
  disableWindowBlurListener?: boolean;
  /**
   * When displaying multiple consecutive Snackbars from a parent rendering a single
   * <Snackbar/>, add the key prop to ensure independent treatment of each message.
   * e.g. <Snackbar key={message} />, otherwise, the message may update-in-place and
   * features such as autoHideDuration may be canceled.
   * @document
   */
  key?: string;
  /**
   * The message to display.
   */
  message?: SnackbarContentProps['message'];
  /**
   * Callback fired when the component requests to be closed.
   * Typically `onClose` is used to set state in the parent component,
   * which is used to control the `Snackbar` `open` prop.
   * The `reason` parameter can optionally be used to control the response to `onClose`,
   * for example ignoring `clickaway`.
   *
   * @param {object} event The event source of the callback.
   * @param {string} reason Can be: `"timeout"` (`autoHideDuration` expired), `"clickaway"`.
   */
  handleClose?: (event: Event, reason: SnackbarCloseReason) => void;
  /**
   * Callback fired before the transition is entering.
   */
  onEnter?: () => void;
  /**
   * Callback fired when the transition has entered.
   */
  onEntered?: () => void;
  /**
   * Callback fired when the transition is entering.
   */
  onEntering?: () => void;
  /**
   * Callback fired before the transition is exiting.
   */
  onExit?: () => void;
  /**
   * Callback fired when the transition has exited.
   */
  onExited?: () => void;
  /**
   * Callback fired when the transition is exiting.
   */
  onExiting?: () => void;
  onMouseEnter?: (e: Event) => void;
  onMouseLeave?: (e: Event) => void;
  /**
   * If `true`, `Snackbar` is open.
   */
  open?: boolean;
  /**
   * The number of milliseconds to wait before dismissing after user interaction.
   * If `autoHideDuration` prop isn't specified, it does nothing.
   * If `autoHideDuration` prop is specified but `resumeHideDuration` isn't,
   * we default to `autoHideDuration / 2` ms.
   */
  resumeHideDuration?: number;
  /**
   * The component used for the transition.
   * [Follow this guide](/components/transitions/#transitioncomponent-prop) to learn more about the requirements for this component.
   */
  TransitionComponent?: React.ComponentType<{
    children?: React.ReactNode;
  }>;
  /**
   * The duration for the transition, in milliseconds.
   * You may specify a single timeout for all transitions, or individually with an object.
   */
  transitionDuration?: number;
  /**
   * Props applied to the [`Transition`](http://reactcommunity.org/react-transition-group/transition#Transition-props) element.
   */
  TransitionProps?: Record<any, any>;
};

export type GenericSnackBarContentProps = Readonly<{
  /**
   * Message to show in snack bar
   */
  message: SnackbarContentProps['message'];
  classes?: {
    [key: string]: unknown;
  };
  /**
   * Snackbar severity
   */
  severity?: keyof typeof severityColorsMap;
  /**
   * Name of snack bar action
   */
  actionName?: React.ReactNode;
  /**
   * Function to invoke on action click
   */
  actionHandler?: (e: Event) => undefined | Promise<undefined>;
  /**
   * Color of the action button
   */
  actionColor?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | string;
  /**
   * Icon to display at the left of the SnackBar, before the message
   */
  icon?: SnackBarIcon;
  /**
   * Callback fired when the component requests to be closed.
   * Typically `onClose` is used to set state in the parent component,
   * which is used to control the `Snackbar` `open` prop.
   * The `reason` parameter can optionally be used to control the response to `onClose`,
   * for example ignoring `clickaway`.
   *
   * @param {object} event The event source of the callback.
   * @param {string} reason Can be: `"timeout"` (`autoHideDuration` expired), `"clickaway"`.
   */
  onClose?: (event: Event, reason: SnackbarCloseReason) => void;
  action?: React.ReactNode;
  role?: string;
  reduceCloseButtonHeight?: boolean;
}>;

export const GenericSnackbarContent = withStyles(styles)(
  ({
    classes = EMPTY.OBJECT,
    severity = 'default',
    message,
    icon,
    actionName = null,
    actionHandler,
    onClose,
    actionColor,
    reduceCloseButtonHeight = false,
    ...rest
  }: GenericSnackBarContentProps): React.ReactElement => {
    return (
      <SnackbarContent
        css={`
          background-color: ${severityColorsMap[severity]};
        `}
        message={
          icon != null ? (
            <span
              css={`
                color: white;
                max-width: 550px;
                display: flex;
                align-items: center;
                * {
                  margin-right: 16px;
                }
              `}
              id="message-id"
            >
              {iconsMap[icon]}
              {message}
            </span>
          ) : (
            <span
              css={`
                color: white;
                max-width: 550px;
                display: inline-block;
              `}
              id="message-id"
            >
              {message}
            </span>
          )
        }
        action={[
          actionName != null ? (
            <Button
              key="actionButton"
              // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
              color={actionColor ?? 'secondary'}
              size="small"
              onClick={actionHandler}
            >
              {actionName}
            </Button>
          ) : null,
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            data-testid="snack-bar--close-button"
            // @ts-expect-error [EN-7967] - TS2769 - No overload matches this call.
            className={classes.close}
            onClick={onClose}
            css={`
              ${reduceCloseButtonHeight ? 'padding-top: 10px; padding-bottom: 10px;' : ''}
            `}
          >
            <CloseIcon
              css={`
                color: white;
              `}
            />
          </IconButton>,
        ]}
        {...rest}
      />
    );
  }
) as React.ComponentType<GenericSnackBarContentProps>;

function GenericSnackBar({
  classes = EMPTY.OBJECT,
  msg,
  icon,
  actionName = null,
  actionHandler,
  duration = 4000,
  vertical = 'top',
  horizontal = 'right',
  handleClose,
  open,
  severity = 'success',
  'data-testid': testId,
  actionColor,
  ContentProps,
  ...rest
}: GenericSnackBarProps): React.ReactElement {
  return (
    <Snackbar
      anchorOrigin={{
        vertical,
        horizontal,
      }}
      open={open}
      autoHideDuration={duration}
      // @ts-expect-error [EN-7967] - TS2322 - Type '(event: Event, reason: SnackbarCloseReason) => void' is not assignable to type '(event: SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => void'.
      onClose={handleClose}
      data-testid={testId}
      css={`
        top: env(titlebar-area-height, 15px);
      `}
      {...rest}
    >
      <GenericSnackbarContent
        severity={severity}
        message={msg}
        icon={icon}
        actionName={actionName}
        actionHandler={actionHandler}
        actionColor={actionColor}
        onClose={handleClose}
        {...ContentProps}
      />
    </Snackbar>
  );
}

export default GenericSnackBar;

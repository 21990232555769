/* eslint-disable import/extensions */

import downsampleRawPCMSpeechProcessorUrl from './DownsampleRawPCMSpeech.processor?worker&url';
import downsampleRawPCMSpeechProcessorUrlDev from './DownsampleRawPCMSpeech.processor?url';

export const getDownsampleRawPCMSpeechProcessorUrl = (): URL => {
  // @ts-expect-error [EN-7967] - TS2322 - Type 'string' is not assignable to type 'URL'.
  return import.meta.env.PROD
    ? downsampleRawPCMSpeechProcessorUrl
    : downsampleRawPCMSpeechProcessorUrlDev;
};

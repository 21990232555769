/* eslint-disable no-redeclare */
/**
 * Use this if you do not want to throw an error but still capture it.
 */
export const unreachableCaseLog = (x: string): void => {
  console.error(`Unreachable case: ${x}`);
};

export const unreachableCaseError = <T>(x: string): T => {
  throw new Error(`Unreachable case: ${x}`);
};

/**
 * Get the value of a key in an object.
 */
export type ValueOf<T> = T[keyof T];

/**
 * Get the key of an object.
 */
export type KeyOf<T> = keyof T;

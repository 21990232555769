import { Editor } from 'domains/reporter/RichTextEditor/core';
import { useMemo, useState } from 'react';
import { useExpandSelection } from './useExpandSelection';
import type { CreateEnhanceProvider } from '../../types/plugin';
import type { ExpandSelectionPluginPropertyOptions } from './expandSelectionPlugin';
import type { SlateProps } from 'slate-react';

type ProviderComponentProps = Readonly<{
  editor: Editor;
  forceExpandPicklist: boolean;
  children: React.ReactNode;
}>;

export const ProviderComponentWithExpandSelection = ({
  editor,
  forceExpandPicklist,
  children,
}: ProviderComponentProps): React.ReactElement => {
  const [selection, setSelection] = useState();
  useExpandSelection({ selection, editor, forceExpandPicklist });

  useMemo(() => {
    const initialChange = editor.onChange;
    editor.onChange = (...change) => {
      setSelection((selection) => (editor.selection !== selection ? editor.selection : selection));
      initialChange(...change);
    };
  }, [editor]);

  // @ts-expect-error [EN-7967] - TS2322 - Type 'ReactNode' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
  return children;
};

export const enhanceProviderExpandSelection: CreateEnhanceProvider<
  ExpandSelectionPluginPropertyOptions
> =
  ({ pluginID, forceExpandPicklist = false }) =>
  (Component: React.ComponentType<SlateProps>) =>
    function EnhanceProviderExpandSelection(props: SlateProps) {
      return (
        <ProviderComponentWithExpandSelection
          editor={props.editor}
          forceExpandPicklist={forceExpandPicklist}
        >
          <Component {...props} />
        </ProviderComponentWithExpandSelection>
      );
    };

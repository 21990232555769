import { AppRoutePath } from 'config/constants';
import { useViewerMetadata } from 'domains/viewer/Viewer/StudyLoader/useViewerMetadata';
import analytics from 'modules/analytics';
import { globalContext } from 'modules/analytics/constants';
import { useEffect } from 'react';
import { generatePath, matchPath } from 'react-router-dom';
import type { RecoilState } from 'recoil';
import { atom, useSetRecoilState } from 'recoil';

export const openedViewersState: RecoilState<ReadonlyArray<string>> = atom({
  key: 'openedViewersState',
  default: [],
});

export function replaceViewerId(location: Location, windowId: string): string {
  const match = matchPath(AppRoutePath.VIEWER, location.pathname);
  const pathname = generatePath(AppRoutePath.VIEWER, { ...match?.params, windowId });
  return `${pathname}${location.search}`;
}

export const TrackOpenedViewers = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const setOpenedViewers = useSetRecoilState(openedViewersState);
  const { openWindowsIds } = useViewerMetadata();

  useEffect(() => {
    setOpenedViewers(openWindowsIds);
    analytics.addContext(globalContext.viewer.windowsOpen, openWindowsIds?.length);
    analytics.addContext(globalContext.viewer.openWindowsIds, openWindowsIds);
  }, [setOpenedViewers, openWindowsIds]);

  // @ts-expect-error [EN-7967] - TS2322 - Type 'ReactNode' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
  return children;
};

import { HEADING_ERROR_PLUGIN_ID } from './types';
import type { HeadingErrorPluginOptions, HeadingErrorPluginPropertyOptions } from './types';

import type { EditorPlugin } from '../../types';
import { getRenderLeaf } from '../../utils';
import { HeadingErrorEditable } from './components/headingError';
import { enhanceEditorStateHeadingError } from './enhanceEditorStateHeadingError';

export const HeadingErrorPlugin = ({
  component = HeadingErrorEditable,
  pluginName = 'HeadingError',
  ...rest
}: HeadingErrorPluginOptions = {}): EditorPlugin => {
  const options: HeadingErrorPluginPropertyOptions = {
    pluginID: HEADING_ERROR_PLUGIN_ID,
    pluginName,
    component,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    renderLeaf: getRenderLeaf(options),
    enhanceEditorState: enhanceEditorStateHeadingError(options),
  };
};

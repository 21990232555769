import { MessageDisplay } from 'generated/graphql';
import type { ToolInteractionMessageResponse } from 'generated/graphql';
import { showToastMessage } from '../feedback';

export function handleMessageResponse(
  response: Readonly<ToolInteractionMessageResponse>
): undefined | null {
  const { message, severity, display } = response;

  if (message == null) return null;

  if (display === MessageDisplay.Toast) {
    // @ts-expect-error [EN-7967] - TS2322 - Type 'void' is not assignable to type 'null'.
    return showToastMessage(message, severity);
  }

  // @ts-expect-error [EN-7967] - TS2322 - Type 'void' is not assignable to type 'null'.
  return alert(message);
}

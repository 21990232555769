import { Text } from 'domains/reporter/RichTextEditor/core';
import type { RenderLeafProps } from '../../../core';

import { getSourceTextColor } from '../utils';

const TextSourceStyling = ({
  attributes,
  children,
  leaf,
}: RenderLeafProps<Text>): React.ReactElement => {
  // @ts-expect-error [EN-7967] - TS2339 - Property 'source' does not exist on type 'BaseText'.
  if (leaf?.source != null) {
    // @ts-expect-error [EN-7967] - TS2339 - Property 'source' does not exist on type 'BaseText'.
    children = <span style={{ color: getSourceTextColor(String(leaf.source)) }}>{children}</span>;
  }

  return <span {...attributes}>{children}</span>;
};

TextSourceStyling.displayName = 'TextSourceStyling';

export const TextSourceStylingEditable = TextSourceStyling;
export const TextSourceStylingRenderer = TextSourceStyling;

import { Dialog as MuiDialog, DialogProps as MUIDialogProps } from '@material-ui/core';
import { PropsWithChildren } from 'react';
import { Colors } from 'styles';

export type DialogProps = PropsWithChildren<{
  variant?: 'dark' | 'light';
}> &
  MUIDialogProps;

const Dialog: React.FC<DialogProps> = ({ variant = 'dark', ...rest }): React.ReactElement => {
  return (
    <MuiDialog
      {...rest}
      css={`
        .MuiDialog-paper {
          width: 450px;
          padding: 3rem;
          background-color: ${variant === 'light' ? Colors.gray10 : Colors.gray2};
          color: ${variant === 'light' ? Colors.gray1 : Colors.gray10};
        }
        .MuiDialogContent-root {
          padding: 0 0 3rem 0;
        }
      `}
    />
  );
};
export default Dialog;

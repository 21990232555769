import { useImagingContext } from 'domains/viewer/ViewportDre/modules/imaging/ImagingContext';
import { BREAST_FOCI } from 'domains/viewer/ViewportDre/constants';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags/suspense';

export const useClassificationBasedMammoFeatures = () => {
  const { imagingProvider } = useImagingContext();
  const allowMammoSSCFeatures = useFeatureFlagEnabled(FF.ALLOW_MAMMO_SSC_FEATURES);
  const hasMammoFocus = imagingProvider && imagingProvider.classificationsIncludeFoci(BREAST_FOCI);
  return allowMammoSSCFeatures && hasMammoFocus;
};

// this is necessary for situations where we are outside of the imaging context but can
// find the right provider
export const useCheckClassificationBasedMammoFeatures = (provider) => {
  const allowMammoSSCFeatures = useFeatureFlagEnabled(FF.ALLOW_MAMMO_SSC_FEATURES);
  if (provider == null) return false;
  const hasMammoFocus = provider.classificationsIncludeFoci(BREAST_FOCI);
  return allowMammoSSCFeatures && hasMammoFocus;
};

import type { RenderElementProps } from '../core';
import type { ElementPluginID } from '../plugins';
import type { ComponentType } from 'react';

export type GetRenderElementOptions = Readonly<{
  /**
   * By passing true, helper automatically spreads any required attributes down to the component. If this is not used,
   * you must spread the attributes onto the component.
   *
   * @default true
   */
  spreadAttributes?: boolean;
}>;

export type GetRenderElementComponentType<PluginOptions, El> = (
  arg1: RenderElementProps<El> & {
    ['data-editor-element']: string;
    pluginOptions: PluginOptions;
  }
) => React.ReactElement;

export const getRenderElement = <
  PluginOptions extends Readonly<{
    pluginID: ElementPluginID;
    component: ComponentType<any>;
  }>,
>(
  { component: Component, ...pluginOptions }: PluginOptions,
  { spreadAttributes = true }: GetRenderElementOptions = { spreadAttributes: true }
): ((props: RenderElementProps) => React.ReactElement) =>
  function GetRenderElement({ element, attributes, ...rest }: RenderElementProps) {
    const { pluginID } = pluginOptions;
    const bagToSpread = spreadAttributes ? attributes : { attributes };

    if (element.type === pluginID) {
      return (
        <Component
          data-editor-element={pluginID}
          {...bagToSpread}
          pluginOptions={pluginOptions}
          element={element}
          {...rest}
        />
      );
    }

    return null;
  };

import { Location, Span, Range } from 'domains/reporter/RichTextEditor/core';
import type { NodeEntry } from '../core';

import { useRef, useEffect } from 'react';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import type { ToastKey } from 'common/ui/Toaster/Toast';
import type { Toast } from 'common/ui/Toaster/Toaster';
import type { SlateContent } from '../../Reporter/types';
import { Node, ReactEditor, Transforms, Editor } from '../core';
import { walkSlateContent } from './walkSlateContent';
import { selectAndFocusEditor } from './focusEditor';
import { NOOP } from 'config/constants';
import { logger } from 'modules/logger';
import type { AllReporterVariant } from '../hooks/useReporter';
import { PicklistPluginElement, RequiredFieldsPluginElement } from '../slate-custom-types';
import { InlineBookmarkPluginElement } from '../plugins';
import { Element, Text } from 'slate';

export const warnOnRequiredFieldDeletion = (
  requiredFields: Array<Node>,
  enqueueToast?: (msg: React.ReactNode, options?: Readonly<Partial<Toast>>) => ToastKey,
  variant?: AllReporterVariant
): void => {
  if (requiredFields.length === 0 || enqueueToast == null) {
    return;
  }

  const requiredFieldNames = requiredFields.map(
    (node) => (node as PicklistPluginElement | InlineBookmarkPluginElement).name
  );

  let toastMessage = '';

  if (requiredFields.length === 1) {
    toastMessage = `"${requiredFieldNames[0]}" field is required and cannot be deleted.`;
  } else {
    const allButLastFieldNames = requiredFieldNames
      .map((name) => `"${name}"`)
      .slice(0, -1)
      .join(', ');
    const lastFieldName = requiredFieldNames[requiredFieldNames.length - 1];
    toastMessage = `${allButLastFieldNames}${requiredFieldNames.length > 2 ? ',' : ''} and "${lastFieldName}" fields are required and cannot be deleted.`;
  }

  enqueueToast(toastMessage);
};

export const insertContentButPreserveRequiredFields = ({
  editor,
  entirelySelectedRequiredEntries,
  selection,
  onComplete = NOOP,
  enqueueToast,
  select = true,
  variant,
}: {
  editor: Editor;
  entirelySelectedRequiredEntries: NodeEntry<never>[];
  selection: Range;
  onComplete?: () => void;
  enqueueToast?: (msg: React.ReactNode, options?: Readonly<Partial<Toast>>) => ToastKey;
  select?: boolean;
  variant?: AllReporterVariant;
}): void => {
  const requiredNodes: Array<Node> = entirelySelectedRequiredEntries.map(([node]) => node);

  Transforms.delete(editor, { at: selection });

  Transforms.insertFragment(editor, requiredNodes);

  warnOnRequiredFieldDeletion(requiredNodes, enqueueToast, variant);

  logger.info(
    '[insertContentButPreserveRequiredFields] Required field deletion prevented in insertText',
    {
      fieldNames: requiredNodes.map(
        (node) => (node as PicklistPluginElement | InlineBookmarkPluginElement).name
      ),
      variant,
    }
  );

  try {
    const nextNodeEntry = Editor.next(editor);
    Transforms.select(
      editor,
      nextNodeEntry != null ? Editor.start(editor, nextNodeEntry[1]) : Editor.end(editor, [])
    );
  } catch (e: any) {
    logger.warn('[requiredFields] Error setting selection after preserving required fields', e);
  }

  onComplete();
};

export const isRequiredFieldNode = (n: Node): boolean =>
  Element.isElement(n) && (n as RequiredFieldsPluginElement).required === true;

export const isEmptyRequiredFieldNode = (node: Node): boolean =>
  isRequiredFieldNode(node) &&
  (Node.string(node) === '' ||
    ((node as PicklistPluginElement).type === 'picklist' && Node.string(node) === ' '));

export const getEmptyRequiredFields = (content: SlateContent): Node[] => {
  const emptyRequiredFields: Node[] = [];

  const onElement = (elementNode: Node) => {
    if (isEmptyRequiredFieldNode(elementNode)) {
      emptyRequiredFields.push(elementNode);
    }
  };

  walkSlateContent(onElement)(content);

  return emptyRequiredFields;
};

export const moveSelectionToFirstEmptyRequiredField = (
  content: SlateContent,
  editor: Editor
): void => {
  const firstRequiredNodeEntry = getFirstEmptyRequiredField(editor);
  if (!firstRequiredNodeEntry.done && firstRequiredNodeEntry.value != null) {
    const [node, path] = firstRequiredNodeEntry.value as NodeEntry;

    try {
      const target = ReactEditor.toDOMNode(editor, node);
      scrollIntoViewIfNeeded(target, {
        behavior: 'smooth',
        scrollMode: 'if-needed',
        block: 'end',
      });
      selectAndFocusEditor(editor, path);
    } catch (e: any) {
      // no-op, this can happen if we run Slate headlessly since there will be no
      // associated DOM
    }
  }
};

// I can't use MutableRefObject to type with flow apparently? But its ok, even
// though this needs to be added to a dep array, the object is stable so it
// won't trigger a re-render
export const useDialogOpenStateRef = (
  dialogOpenState: boolean
): {
  current: boolean;
} => {
  const isDialogOpenRef = useRef<boolean>(false);

  useEffect(() => {
    if (dialogOpenState !== isDialogOpenRef.current) {
      isDialogOpenRef.current = dialogOpenState;
    }
  }, [dialogOpenState]);

  return isDialogOpenRef;
};

export const getLowestRequiredFields = (editor: Editor): Array<NodeEntry<never>> => {
  return Array.from(
    Editor.nodes(editor, {
      match: (n) => (n as RequiredFieldsPluginElement).required === true,
      mode: 'lowest',
    })
  );
};

export const getSelectedRequiredFields = (editor: Editor): Array<NodeEntry<never>> => {
  return Array.from(
    Editor.nodes(editor, {
      match: (n) => (n as RequiredFieldsPluginElement).required === true,
    })
  );
};

export const getRequiredFieldsAtLocation = (
  editor: Editor,
  at: Location | Span
): Array<NodeEntry<never>> => {
  return Array.from(
    Editor.nodes(editor, {
      at,
      match: (n) => (n as RequiredFieldsPluginElement).required === true,
      mode: 'lowest',
    })
  );
};

const getFirstEmptyRequiredField = (editor: Editor) => {
  return Editor.nodes(editor, {
    at: [],
    match: (n) => isEmptyRequiredFieldNode(n),
  }).next();
};

// Generator of nodes within a given selection or the entire editor that are
// empty required fields
export const getEmptyTouchedRequiredFields = (editor: Editor, selection: Range | null) =>
  Editor.nodes(editor, {
    at: selection ?? [],
    match: (n) =>
      Element.isElement(n) &&
      isRequiredFieldNode(n) &&
      n.children.length === 1 &&
      (n.children[0] as Text).text.trim() === '',
    mode: 'all',
  });

export const getRequiredFieldsInRange = (editor: Editor, selection: Range | null) =>
  Editor.nodes(editor, {
    at: selection ?? [],
    match: (n) => isRequiredFieldNode(n),
    mode: 'all',
  });

export const isRequiredFieldInRange = (editor: Editor, range: Range): boolean => {
  const maybeRequiredField = Editor.nodes(editor, {
    at: range,
    match: (n) => isRequiredFieldNode(n),
  }).next();

  if (!maybeRequiredField.done) {
    return true;
  }

  return false;
};

//https://upscope.com/co-browsing-api/docs/javascript-sdk/sdk-functions
import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { GET_ME } from 'modules/Apollo/queries';
import { isRefreshTokenProvided } from 'utils/token';
import type { GetMeQuery, GetMeQueryVariables } from 'generated/graphql';
import { env } from 'config/env';

let globalInitialized = false;

// @ts-expect-error [EN-7967] - TS2339 - Property 'Upscope' does not exist on type 'Window & typeof globalThis'.
const getUpscope = () => window.Upscope ?? (() => {});

export function useUpscope() {
  const [getMe, { data = {} }] = useLazyQuery<GetMeQuery, GetMeQueryVariables>(GET_ME, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    // Don't call this query if the user is not logged in
    if (isRefreshTokenProvided()) {
      getMe();
    }
  }, [getMe]);

  const initialize = () => {
    if (globalInitialized) {
      return;
    }
    // prettier-ignore
    // eslint-disable-next-line
    // @ts-expect-error [EN-7967] - TS2339 - Property 'c' does not exist on type '() => void'. | TS2339 - Property 'q' does not exist on type '() => void'. | TS2339 - Property 'c' does not exist on type '() => void'. | TS2339 - Property 'q' does not exist on type '() => void'. | TS2339 - Property 'Upscope' does not exist on type 'Window & typeof globalThis'. | TS2339 - Property 'Upscope' does not exist on type 'Window & typeof globalThis'.
    (function(w, u, d){var i=function(){i.c(arguments)};i.q=[];i.c=function(args: any){i.q.push(args)};var l = function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://code.upscope.io/x7MxKSPaaL.js';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(typeof u!=="function"){w.Upscope=i;l();}})(window, window.Upscope, document);
    getUpscope()('init');
    globalInitialized = true;
  };

  useEffect(() => {
    // @ts-expect-error [EN-7967] - TS2339 - Property 'me' does not exist on type '{}'.
    const user = data?.me;

    if (
      user == null ||
      env.DEPLOY_ENV === 'development' ||
      env.DEPLOY_ENV === 'local' ||
      env.DEPLOY_ENV == null
    ) {
      return;
    }

    if (user.screenShareEnabled) {
      initialize();
      getUpscope()('updateConnection', {
        uniqueId: user.id,
        // Set the user name or email below (e.g. ["John Smith", "john.smith@acme.com"]).
        identities: [`${user.firstName} ${user.lastName}`, user.email],
      });
    } else {
      getUpscope()('stopSession');
    }
    // @ts-expect-error [EN-7967] - TS2339 - Property 'me' does not exist on type '{}'.
  }, [data?.me]);
}

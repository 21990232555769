import type { InlineBookmarkPluginElement } from '../types';

import { InlineBookmarkElement } from './InlineBookmarkElement';

type InlineTemplateElementProps = Readonly<{
  children: React.ReactNode;
  selected?: boolean;
  leftDelimiter: React.ReactNode;
  rightDelimiter: React.ReactNode;
  testID?: string;
  attributes: {
    ['data-slate-node']: 'element';
    ['data-slate-inline']?: true;
    ['data-slate-void']?: true;
    dir?: 'rtl';
    ref: React.Ref<HTMLSpanElement>;
  };
  element: InlineBookmarkPluginElement;
  ['data-editor-element']?: string;
  variant?: string;
}>;

export const InlineBookmarkTemplate = ({
  attributes,
  // @ts-expect-error [EN-7967] - TS2339 - Property 'options' does not exist on type 'Readonly<{ children: ReactNode; selected?: boolean; leftDelimiter: ReactNode; rightDelimiter: ReactNode; testID?: string; attributes: { "data-slate-node": "element"; "data-slate-inline"?: true; "data-slate-void"?: true; dir?: "rtl"; ref: Ref<...>; }; element: Readonly<...>; "data-editor-element"?: string; variant?: ...'.
  options = [],
  children,
  element,
  leftDelimiter,
  selected,
  rightDelimiter,
  variant,
  ...rest
}: InlineTemplateElementProps): React.ReactElement => {
  return (
    // @ts-expect-error [EN-7967] - TS2322 - Type '{ children: Element; readOnly: true; }' is not assignable to type 'DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>'.
    <span readOnly>
      <InlineBookmarkElement
        {...attributes}
        element={element}
        variant={variant}
        selected={selected}
      >
        {children}
      </InlineBookmarkElement>
    </span>
  );
};

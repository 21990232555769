/**
 * Takes an autoCorrect dictionary and adds capitalized entry keys to the dictionary.
 * Values for the copied entries stay the same.
 */
export const addCapitalizedAutoCorrectEntries = (dictionary: {
  [key: string]: string;
}): {
  [key: string]: string;
} => {
  return Object.keys(dictionary).reduce(
    (updatedDictionary, key) => {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      // Check if the key can be capitalized and the capitalized version doesn't already exist
      if (!(capitalizedKey in dictionary)) {
        updatedDictionary[capitalizedKey] = dictionary[key];
      }

      return updatedDictionary;
    },
    { ...dictionary }
  );
};

import type { EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';

export const ANATOMIC_NAVIGATOR_PLUGIN_ID: 'anatomicNavigator' = 'anatomicNavigator';
export type AnatomicNavigatorPluginID = typeof ANATOMIC_NAVIGATOR_PLUGIN_ID;

export type AnatomicNavigatorPluginOptions = Readonly<EditorPluginOptions>;

export type AnatomicNavigatorPluginPropertyOptions = Readonly<
  EditorPluginPropertyOptions &
    AnatomicNavigatorPluginOptions & {
      pluginID: AnatomicNavigatorPluginID;
      pluginName: string;
    }
>;

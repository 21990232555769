import { useEffect } from 'react';
import type { MediaDeviceInfo } from '../useMicrophone/useMicrophone';
import type { RecoilState } from 'recoil';

import { NOOP } from 'config/constants';
import { atom, useRecoilState } from 'recoil';

type UseMediaDevicesState = {
  devices: MediaDeviceInfo[];
  loaded: boolean;
};

export const mediaDevicesState: RecoilState<UseMediaDevicesState> = atom({
  key: 'mediaDevicesState',
  default: {
    devices: [],
    loaded: false,
  },
});

export const useMediaDevices = (): UseMediaDevicesState => {
  const [state, setState] = useRecoilState(mediaDevicesState);

  const onChange = () => {
    const { mediaDevices } = navigator;
    if (mediaDevices == null) return;
    mediaDevices
      .enumerateDevices()
      .then((devices) => {
        if (state.devices.length !== devices.length) {
          setState({
            devices: devices.map(({ deviceId, groupId, kind, label }) => ({
              deviceId,
              groupId,
              kind,
              label,
            })),
            loaded: true,
          });
        }
      })
      .catch(NOOP);
  };

  useEffect(() => {
    const mediaDevices = navigator.mediaDevices;
    if (mediaDevices == null || state.devices.length !== 0 || state.loaded) {
      return;
    }
    mediaDevices.addEventListener('devicechange', onChange);
    onChange();

    return () => {
      if (mediaDevices == null) {
        return;
      }
      mediaDevices.removeEventListener('devicechange', onChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state;
};

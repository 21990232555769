import { useState } from 'react';
import { useDebounce } from 'use-debounce';

export const useDebouncedState = <T>(
  initialValue: T,
  ms: number
): [T, T, (arg1: ((arg1: T) => T) | T) => void] => {
  const [state, setState] = useState(initialValue);

  // In the case of an empty value, we don't want to debounce it since it's
  // been cleared
  const isEmpty = state === '';
  const [debouncedValue] = useDebounce(state, isEmpty ? 0 : ms);

  return [state, debouncedValue, setState];
};

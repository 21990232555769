import type { EditorElement, EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';
import type { ComponentType } from 'react';
export const AUTOCORRECT_PLUGIN_ID: 'autoCorrect' = 'autoCorrect';
export type AutoCorrectPluginID = typeof AUTOCORRECT_PLUGIN_ID;

export type AutoCorrectPluginElement = Readonly<
  EditorElement & {
    type: AutoCorrectPluginID;
  }
>;

export type AutoCorrectPluginOptions = Readonly<
  EditorPluginOptions & {
    // $FlowFixMe[unclear-type]
    component?: ComponentType<any>;
  }
>;

export type AutoCorrectPluginPropertyOptions = Readonly<
  EditorPluginPropertyOptions &
    AutoCorrectPluginOptions & {
      pluginID: AutoCorrectPluginID;
      pluginName: string;
      // $FlowFixMe[unclear-type]
      icon: ComponentType<any>;
    }
>;

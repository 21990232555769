const validatePassword = (password: string): Array<string> => {
  let validations: Array<string> = [];
  if (password.length >= 8) {
    validations = [...validations, 'hasLength'];
  }
  if ([...password].some((x) => /^[0-9]*$/.test(x))) {
    validations = [...validations, 'hasNumber'];
  }
  if ([...password].some((x) => /^[A-Z]*$/.test(x))) {
    validations = [...validations, 'hasUpperCase'];
  }
  if ([...password].some((x) => /^[a-z]*$/.test(x))) {
    validations = [...validations, 'hasLowerCase'];
  }
  // List from https://owasp.org/www-community/password-special-characters
  if ([...password].some((x) => /^[\s[\]!"#$%&'()*+,-./\\:;<=>?@^_`{|}~]*$/.test(x))) {
    validations = [...validations, 'hasSpecialChar'];
  }
  return validations;
};
export default validatePassword;

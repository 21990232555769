import {
  PointRef,
  Point,
  RangeRef,
  Range,
  PathRef,
  Path,
} from 'domains/reporter/RichTextEditor/core';

export const getPathRefSafe = (pathRef: PathRef): Path => {
  if (pathRef.current == null) {
    throw new Error('Cannot get pathRef of undefined!');
  }

  return pathRef.current;
};

export const getPointRefSafe = (pointRef: PointRef): Point => {
  if (pointRef.current == null) {
    throw new Error('Cannot get pointRef of undefined!');
  }

  return pointRef.current;
};

export const getRangeRefSafe = (rangeRef?: RangeRef | null): Range => {
  if (rangeRef == null || rangeRef.current == null) {
    throw new Error('Cannot get rangeRef of undefined!');
  }

  return rangeRef.current;
};

export const slateSource = Object.freeze({
  user: {
    dictation: 'user/dictation',
    keyboard: 'user/keyboard',
  },
  ugc: {
    template: 'ugc/template',
    macro: 'ugc/macro',
    picklist: 'ugc/picklist',
  },
  ai: {
    imageBookmark: 'ai/image-bookmark',
    // TODO: We need to update this to more granular ai options some day, but for purposes of focus mode for
    // RSNA, we'll be using the pre-existing ai/template-mapper
    impressionGenerator: 'ai/template-mapper',
    focusMode: 'ai/template-mapper',
  },
});

import { Editor, Range, Transforms, Node, Element } from '../../core';
import type { CreateEnhanceEditorState } from '../../types';
import { find } from '../../utils';
import type { DeepLinkPluginPropertyOptions } from './types';
import { slateContentToString } from '../../utils/slateContentToString';
import type { NodeEntry } from 'slate';
import { ReactEditor } from 'slate-react';

export const enhanceEditorState: CreateEnhanceEditorState<DeepLinkPluginPropertyOptions> =
  ({ pluginID, component, getIsEditingStudyNode }) =>
  (editor: Editor) => {
    const { isInline, deleteBackward, deleteForward, normalizeNode } = editor;

    editor.isInline = (element: Element) => {
      return element.type === pluginID ? true : isInline(element);
    };

    editor.deleteBackward = (...args) => {
      const { selection } = editor;

      if (selection && Range.isCollapsed(selection)) {
        // If outside of the node see if the next left point (where the cursor
        // would be on delete) is the same node. If so, remove the entire node.
        const matchEntryBefore = find(editor, (n: Node) => n.type === pluginID, {
          at: Editor.before(editor, selection),
        });

        if (matchEntryBefore != null) {
          if (matchEntryBefore[0].variant === 'study' && getIsEditingStudyNode) {
            try {
              const key = ReactEditor.findKey(editor, matchEntryBefore[0]);
              // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'import("slate-react").Key' is not assignable to parameter of type 'React.Key'.
              const isEditing = getIsEditingStudyNode(key);
              // don't delete the entire node if in editing mode
              if (isEditing) {
                return deleteBackward(...args);
              }
            } catch (e: any) {
              return deleteBackward(...args);
            }
          }

          const targetPoint = Editor.before(editor, matchEntryBefore[1]);

          Transforms.removeNodes(editor, { at: matchEntryBefore[1] });
          if (targetPoint != null) Transforms.select(editor, targetPoint);
          return;
        }
      }

      return deleteBackward(...args);
    };

    editor.deleteForward = (...args) => {
      const { selection } = editor;

      if (selection && Range.isCollapsed(selection)) {
        // If outside of the node see if the next left point (where the cursor
        // would be on delete) is the same node. If so, remove the entire node.
        const matchEntryAfter = find(editor, (n: Node) => n.type === pluginID, {
          at: Editor.after(editor, selection),
        });

        if (matchEntryAfter != null) {
          if (matchEntryAfter[0].variant === 'study' && getIsEditingStudyNode) {
            try {
              const key = ReactEditor.findKey(editor, matchEntryAfter[0]);
              // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'import("slate-react").Key' is not assignable to parameter of type 'React.Key'.
              const isEditing = getIsEditingStudyNode(key);
              // don't delete the entire node if in editing mode
              if (isEditing) {
                return deleteForward(...args);
              }
            } catch (e: any) {
              return deleteForward(...args);
            }
          }

          const targetPoint = Editor.before(editor, matchEntryAfter[1]);

          Transforms.removeNodes(editor, { at: matchEntryAfter[1] });
          if (targetPoint != null) Transforms.select(editor, targetPoint);
          return;
        }
      }

      return deleteForward(...args);
    };

    editor.normalizeNode = (entry: NodeEntry) => {
      const [node, path] = entry;

      if (Element.isElement(node) && node.type === pluginID) {
        if (
          Array.isArray(node.children) &&
          (node.children.length === 0 || slateContentToString([node]).length === 0)
        ) {
          Transforms.removeNodes(editor, { at: path });
        } else {
          for (const [child, childPath] of Array.from(Node.children(editor, path))) {
            // Can not wrap a deep link within a deep link
            if (Element.isElement(child) && child.type === pluginID) {
              Transforms.unwrapNodes(editor, { at: childPath });
              return;
            }
          }
        }
      }

      return normalizeNode(entry);
    };

    return editor;
  };

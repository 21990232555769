import { saveWindowSyncInfo } from 'modules/windowDesyncDetector';
import { useCallback, useEffect } from 'react';
import {
  useCurrentCaseId,
  useCurrentComparativeStudies,
  useSetCurrentCase,
} from './useCurrentCase';
import {
  addExtensionListener,
  focusEvent,
  removeExtensionListener,
  syncEvent,
} from 'domains/extension/extensionEventCreators';
import { RESPONSE_EVENTS as EXTENSION_RESPONSE_EVENTS } from 'domains/extension/constants';
import { dispatchExtensionEvent } from '../domains/extension/extensionEventCreators';
import { useMatch } from 'react-router-dom';
import { AppRoutePath } from '../config/constants';
import { useOpenTabs } from './useOpenTabs';
import { PAGE_TYPES } from '../utils/pageTypes';

/**
 * When mounted, registers a listener that will set the case context when a message is received from
 * another window. This hook will return a function `syncCase` that can send a message to other
 * windows to update their case context.
 */
export const useCaseSync = (): ((id: string, studies?: ReadonlyArray<string>) => void) => {
  const [, setCurrentComparativeStudies] = useCurrentComparativeStudies();
  const currentCaseId = useCurrentCaseId();
  const setCurrentCase = useSetCurrentCase();

  const openTabs = useOpenTabs();
  const viewerTab = openTabs.find((tab) => tab.type === PAGE_TYPES.VIEWER);
  const currentViewerCaseSmid = viewerTab?.worklistIds[0];

  const shouldPatientJacketStayInSync = currentViewerCaseSmid === currentCaseId;
  const isDockedJacket = useMatch(AppRoutePath.PATIENT_JACKET) != null;
  const isUndockedJacket = useMatch(AppRoutePath.PATIENT_JACKET_UNDOCKED) != null;
  const shouldSync = !(isDockedJacket || isUndockedJacket) || shouldPatientJacketStayInSync;

  useEffect(() => {
    if (!shouldSync) {
      return;
    }
    const handler = (
      event: CustomEvent<{
        from: string | null | undefined;
        to: string;
        studies: string[] | null | undefined;
      }>
    ) => {
      const { from, to, studies } = event.detail;
      if (from != null && currentCaseId != null && from !== currentCaseId) {
        return;
      }
      if (studies != null) {
        setCurrentComparativeStudies({ caseSmid: to, studySmids: studies });
      } else {
        setCurrentCase(to);
        // focus viewer/0, instead of viewer/1, viewer/2, etc.
        const paths = ['viewer/0', 'reporter'];
        if (paths.some((path) => window.location.pathname.includes(path))) {
          dispatchExtensionEvent(focusEvent());
        }
      }
    };

    addExtensionListener(EXTENSION_RESPONSE_EVENTS.PRIORS, handler);

    return () => {
      removeExtensionListener(EXTENSION_RESPONSE_EVENTS.PRIORS, handler);
    };
  }, [currentCaseId, setCurrentCase, setCurrentComparativeStudies, shouldSync]);

  const syncCase = useCallback(
    (id: string, studies?: ReadonlyArray<string>) => {
      if (studies != null) {
        setCurrentComparativeStudies({ caseSmid: id, studySmids: studies });
      } else {
        setCurrentCase(id);
      }
      saveWindowSyncInfo(id);
      try {
        dispatchExtensionEvent(syncEvent({ from: null, to: id, studies }));
      } catch (err: any) {
        console.error(err);
      }
    },
    [setCurrentCase, setCurrentComparativeStudies]
  );

  return syncCase;
};

import {
  PLACEHOLDER_DELIMITER_LEFT,
  PLACEHOLDER_DELIMITER_RIGHT,
  PLACEHOLDER_FIELDS_DICT,
} from '../constants';
import Text from 'common/ui/Text';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Stack } from 'common/ui/Layout';
import { useRecoilValue } from 'recoil';
import { emptyPlaceholderDialogState } from '../../../../Reporter/state';
import { useCurrentWorklistItems } from 'hooks/useCurrentWorklistItems';
import type { PlaceholderPluginElement } from '../types';
import { formatStudyInformationWithoutDate } from 'common/StudyInformationCard';
import { Colors } from 'styles';

const MAX_PLACEHOLDERS_LIST_LENGTH = 3;
const MAX_CASES_LIST_LENGTH = 3;

export const EmptyPlaceholderDialogContent = (): React.ReactElement | null => {
  const placeholderFieldDialogState = useRecoilValue(emptyPlaceholderDialogState);

  const placeholders = placeholderFieldDialogState.emptyPlaceholders;

  const { currentWorklistItems } = useCurrentWorklistItems();

  // Create a new object of {workListItemSmid: [placeholders]}
  // Each placeholder object is guaranteed to have a workListItemSmid, which is a string.

  const missingPlaceholdersPerItem = placeholders.reduce(
    (acc, placeholder) => {
      if (placeholder.workListItemSmid in acc) {
        acc[placeholder.workListItemSmid].push(placeholder);
      } else {
        acc[placeholder.workListItemSmid] = [placeholder];
      }
      return acc;
    },
    {} as {
      [key: string]: PlaceholderPluginElement[];
    }
  );

  const cutoffLinkCases = currentWorklistItems.length - MAX_CASES_LIST_LENGTH;
  const cutoffPlaceholders = Object.entries(missingPlaceholdersPerItem)
    .slice(MAX_CASES_LIST_LENGTH)
    .reduce((acc, [key, value]: [any, any]) => {
      acc = acc + value.length;
      return acc;
    }, 0);

  return (
    <Stack vertical space="small">
      {placeholders.length === 1 && (
        <Text color="black">There is [1] missing merge field in your report:</Text>
      )}
      {placeholders.length > 1 && (
        <Text color="black">
          There are [{placeholders.length}] missing merge fields in your report:
        </Text>
      )}
      {Object.entries(missingPlaceholdersPerItem)
        .map(([worklistItemSmid, placeholders]: [any, any]) => {
          const worklistItem = currentWorklistItems.find((item) => item.smid === worklistItemSmid);
          if (worklistItem == null) return null;
          const { studyDescription, accessionNumber } = worklistItem;

          return (
            <Stack vertical space="none" key={`placeholders-${worklistItemSmid}`}>
              {currentWorklistItems.length > 1 && (
                <Stack>
                  <Text color="black">In </Text>
                  <Text
                    italic
                    css={`
                      word-break: break-all;
                      color: ${Colors.gray1};
                    `}
                  >
                    {formatStudyInformationWithoutDate(studyDescription, accessionNumber)}
                  </Text>
                </Stack>
              )}
              <List css="padding-top: 0;">
                {placeholders
                  .map((placeholder, index) => {
                    return (
                      <ListItem
                        key={`${placeholder.placeholderID}-${index}`}
                        css={`
                          padding: 0;
                          justify-content: center;
                        `}
                      >
                        {`${PLACEHOLDER_DELIMITER_LEFT} `}
                        <Text color="black">
                          {PLACEHOLDER_FIELDS_DICT[placeholder.placeholderID]?.name ??
                            placeholder.placeholderID}
                        </Text>
                        {` ${PLACEHOLDER_DELIMITER_RIGHT}`}
                      </ListItem>
                    );
                  })
                  .slice(0, MAX_PLACEHOLDERS_LIST_LENGTH)}
                {placeholders.length > MAX_PLACEHOLDERS_LIST_LENGTH && (
                  <Text color="black">
                    and {placeholders.length - MAX_PLACEHOLDERS_LIST_LENGTH}{' '}
                    {placeholders.length - MAX_PLACEHOLDERS_LIST_LENGTH === 1 ? 'other' : 'others'}
                  </Text>
                )}
              </List>
            </Stack>
          );
        })
        .slice(0, MAX_CASES_LIST_LENGTH)}
      {cutoffLinkCases > 0 && cutoffPlaceholders > 0 && (
        <Text color="black">
          Plus {cutoffPlaceholders} other merge {cutoffPlaceholders === 1 ? 'field' : 'fields'} from{' '}
          {cutoffLinkCases} linked {cutoffLinkCases === 1 ? 'case' : 'cases'}.
        </Text>
      )}
      <Text color="black">Sign report anyway?</Text>
    </Stack>
  );
};

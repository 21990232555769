import { getScreenName } from 'utils/screenNames';
import { checkTabPresence } from '../hooks/useOpenTabs';

export type PopupOptions = {
  top: number;
  left: number;
  width: number;
  height: number;
};

export const openPopup = async (
  openPathNewTab: string,
  config: PopupOptions = {
    width: 1000,
    height: 900,
    top: 10,
    left: 10,
  }
): Promise<boolean> => {
  let id;
  try {
    id = getScreenName(new URL(openPathNewTab, window.location.origin).toString());
  } catch (e: any) {
    // We couldn't find a screen name, in this case we are okay with a undefined id
  }

  window.open(
    openPathNewTab,
    // We use the screen name as the window name to allow for the same tab to be reused
    id,
    Object.entries({
      // enforce the popup to use a different JS thread to allot more memory
      noopener: true,
      resizable: 'yes',
      scrollbar: 'yes',
      titlebar: 'yes',
      ...config,
    })
      .map(([key, value]: [any, any]) => `${key}=${String(value)}`)
      .join(',')
  );

  const tabId = await checkTabPresence(openPathNewTab);

  return tabId != null;
};

import { forwardRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import type { CSSObject } from 'styled-components';
import { Colors } from 'styles';
import { NotEditable } from '../../../components/NotEditable';
import { DEEP_LINK_PLUGIN_ID, type DeepLinkPluginElement } from '../types';
import { usePopper } from 'react-popper';
import getOppositePlacement from '@popperjs/core/lib/utils/getOppositePlacement';
import { ReactEditor } from 'slate-react';
import { Editor } from 'slate';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { useSlateSingletonContext } from '../../../../Reporter/SlateSingletonContext';
import { DeepLinkStyles } from './DeepLink';
import type { DeepLinkElementProps } from './DeepLink';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';
import { DEFAULT_DEEP_LINK_BACKGROUND_COLOR } from 'hooks/useDeepLinkColors';
import { DeepLinkVariants } from '../constants';

type ReadonlyDeepLinkElementProps = Readonly<
  DeepLinkElementProps & {
    onDeepLinkClick?: (element: DeepLinkPluginElement | null, editor: Editor | null) => void;
    onDeepLinkEditClick?: (element: DeepLinkPluginElement | null, editor: Editor | null) => void;
    showEditMenu?: boolean;
    ['data-testid']?: string;
  }
>;

export const RenderableComponent = styled(NotEditable)`
  ${DeepLinkStyles}
`;

export const ReadonlyDeepLinkElement = forwardRef<HTMLSpanElement, ReadonlyDeepLinkElementProps>(
  (
    {
      selected,
      element,
      children,
      pluginOptions,
      onDeepLinkClick = (element: DeepLinkPluginElement, editor: null | Editor) => {},
      onDeepLinkEditClick = (element: DeepLinkPluginElement, editor: null | Editor) => {},
      showEditMenu = false,
      ...rest
    },
    ref
  ) => {
    const [{ editor }] = useSlateSingletonContext();
    const [referenceElement, setReferenceElement] = useState<Range | null>(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const { styles, state } = usePopper(referenceElement, popperElement, {
      placement: 'top',
      modifiers: [
        { name: 'offset', options: { offset: [0, 10] } },
        { name: 'arrow', options: { element: arrowElement } },
      ],
    });
    const [imageBookmarkFlag] = useFeatureFlagEnabled(FF.IMAGE_BOOKMARK);
    const isImageBookmark =
      (element as DeepLinkPluginElement).type === DEEP_LINK_PLUGIN_ID &&
      (imageBookmarkFlag ||
        (element as DeepLinkPluginElement).variant === DeepLinkVariants.POINT) &&
      (element as DeepLinkPluginElement).variant !== DeepLinkVariants.STUDY;

    useEffect(() => {
      if (element == null || editor == null) return;
      try {
        const path = ReactEditor.findPath(editor, element);
        const range = Editor.range(editor, path);
        const domRange = ReactEditor.toDOMRange(editor, range);
        setReferenceElement(domRange);
      } catch (e: any) {}
    }, [editor, element]);

    return (
      <>
        {showEditMenu && referenceElement !== null && (
          <div
            {...(state?.attributes?.popper != null ? state.attributes.popper : {})}
            ref={setPopperElement}
            css={css`
              ${css((styles.popper as CSSObject) || {})};
              background-color: ${Colors.gray3};
              padding: 4px;
              border-radius: 6px;
              display: flex;
              align-content: center;
              &[data-popper-reference-hidden='true'] {
                visibility: hidden;
                pointer-events: none;
              }
            `}
          >
            <span>
              <IconButton
                size="small"
                aria-label="edit"
                onMouseDown={() => {
                  onDeepLinkEditClick(element, editor);
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </span>
            <div
              ref={setArrowElement}
              css={css`
                ${css((styles.arrow as CSSObject) || {})};
                transform: ${styles.arrow?.transform} rotate(45deg);
                width: 10px;
                height: 10px;
                background-color: ${Colors.gray3};
                ${state?.placement
                  ? css`
                      ${getOppositePlacement(state?.placement)}: -5px;
                    `
                  : undefined}
                right: 29px;
                left: auto;
              `}
            />
          </div>
        )}
        <RenderableComponent
          style={{
            backgroundColor: rest.colors?.backgroundColor ?? DEFAULT_DEEP_LINK_BACKGROUND_COLOR,
          }}
          data-testid={rest['data-testid']}
          ref={ref}
          element={element}
          selected={selected}
          isClickable={isImageBookmark || element.variant === DeepLinkVariants.STUDY}
          onMouseDown={(event) => event.preventDefault()}
          onClick={() => {
            onDeepLinkClick(element, editor);
          }}
          {...rest}
        >
          {children}
        </RenderableComponent>
      </>
    );
  }
);

ReadonlyDeepLinkElement.displayName = 'ReadonlyDeepLinkElement';

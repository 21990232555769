import { createContext, useContext, useEffect } from 'react';
import type { Context } from 'react';
import {
  atom,
  atomFamily,
  useSetRecoilState,
  useRecoilCallback,
  selectorFamily,
  useRecoilValue,
} from 'recoil';
import type { RecoilState, RecoilValueReadOnly } from 'recoil';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';
import type { VtkDirection } from 'types/vtk';
import { useScaleListener } from './useScale';
import {
  broadcastChannelSynchronizerEffect,
  localStoragePersisterEffect,
} from 'utils/recoilEffects';
import { activeViewportState } from 'config/recoilState';
import type { DreAllViewportTypes } from 'config/constants';
import type { Study, Series } from 'domains/viewer/ViewportsConfigurations';
import {
  currentSeriesAtomFamily,
  currentStudyAtomFamily,
  currentViewTypeAtomFamily,
  currentStackAtomFamily,
} from '../../Viewer/viewerRecoilContext';
import { generateViewportId } from '../../Viewer/viewerUtils';
import type { Stack } from '../../ViewportsConfigurations/types';
import { useViewportsConfigurationsSelector } from '../../ViewportsConfigurations/state';
import { isCameraInitializedState } from './useInitCamera';
import type { WLAdjustLayerType } from '../../hooks/usePETCTControls';

type AtomFamily<T, P> = (arg1: P) => RecoilState<T>;

export const ViewportIdContext: Context<string | null | undefined> = createContext<
  string | null | undefined
>(null);
const SizesContext = createContext<
  | {
      width: number;
      height: number;
    }
  | null
  | undefined
>(null);

const NOT_WRAPPED_ERROR =
  'DRE Recoil state should only be accessed from within a DRE Recoil state provider.';

export const useViewportId = (): string => {
  const id = useContext(ViewportIdContext);
  if (id == null) {
    throw new Error(`${NOT_WRAPPED_ERROR} Missing ViewportIdContext.`);
  }
  return id;
};

export const useStudy = (): Study => {
  const study = useRecoilValue(currentStudyAtomFamily(useViewportId()));
  if (study == null) {
    throw new Error(NOT_WRAPPED_ERROR);
  }
  return study;
};

export const useSeries = (): Series | null | undefined => {
  const series = useRecoilValue(currentSeriesAtomFamily(useViewportId()));

  return series;
};

export const useSeriesSmid = (): string | null | undefined => {
  const series = useSeries();
  return series?.smid;
};

export const useStack = (): Stack => {
  const stack = useRecoilValue(currentStackAtomFamily(useViewportId()));
  if (stack == null) {
    throw new Error(NOT_WRAPPED_ERROR);
  }
  return stack;
};

export const useStackSmid = (): string => {
  const stack = useStack();
  return stack.smid;
};

export const useViewportSize = (): {
  width: number;
  height: number;
} => {
  const sizes = useContext(SizesContext);
  if (sizes == null) {
    throw new Error(NOT_WRAPPED_ERROR);
  }
  return sizes;
};

export const useViewType = (): DreAllViewportTypes => {
  const viewportId = useViewportId();
  const viewType = useRecoilValue(currentViewTypeAtomFamily<DreAllViewportTypes>(viewportId));
  return viewType;
};

export const useParentStudy = (): Study => {
  const study = useRecoilValue(currentStudyAtomFamily(useViewportId()));
  if (study == null) {
    throw new Error(NOT_WRAPPED_ERROR);
  }
  return study;
};
/**
 * The following atoms are not grouped as a single atom with selectors
 * because when the approach has been tested the performance was suboptimal.
 * Having dedicated atom families for each datum is more efficient.
 */

export const sizesState: AtomFamily<
  | {
      width: number;
      height: number;
    }
  | null
  | undefined,
  string
> = atomFamily({
  key: 'viewportSize',
  default: null,
});

export const nrrdImageState: AtomFamily<vtkImageData | null | undefined, string> = atomFamily({
  key: 'dreNrrdViewportImage',
  default: null,
});

export const frameSmidsMapState: AtomFamily<
  {
    [key: number]: string;
  },
  string
> = atomFamily({
  key: 'dreFrameSmidsMap',
  default: {},
  effects: [broadcastChannelSynchronizerEffect()],
});

export const isActiveViewportScrollingAtom: RecoilState<boolean> = atom({
  key: 'viewer.dre.isActiveViewportScrollingAtom',
  default: false,
  effects: [broadcastChannelSynchronizerEffect({ unidirectional: true })],
});

export const isActiveViewportPanningAtom: RecoilState<boolean> = atom({
  key: 'viewer.dre.isActiveViewportPanningAtom',
  default: false,
  effects: [broadcastChannelSynchronizerEffect({ unidirectional: true })],
});

// This is the primitive the user is interacting with
export const selectedPrimitiveState: AtomFamily<string | null | undefined, string> = atomFamily({
  key: 'dreViewportSelectedPrimitive',
  default: null,
});

// This is the last primitive the user activated (clicked) and it's global
// for the whole app
export const activePrimitiveState: RecoilState<string | null | undefined> = atom({
  key: 'dreViewportActivePrimitive',
  default: null,
  effects: [broadcastChannelSynchronizerEffect({ unidirectional: true })],
});

export const scaleState: AtomFamily<number | null | undefined, string> = atomFamily({
  key: 'dreViewportScale',
  default: null,
});

export const slicingModeState: AtomFamily<0 | 1 | 2, string> = atomFamily({
  key: 'dreSlicingModeState',
  default: 2,
});

export const slicerBoundState: AtomFamily<Array<number> | null | undefined, string> = atomFamily({
  key: 'viewer.dre.slicerBound',
  default: null,
});

export const mouseAdaptorsState: AtomFamily<
  {
    disabled: boolean;
  },
  string
> = atomFamily({
  key: 'dreMouseadaptors',
  default: {
    disabled: false,
  },
});

export const StateProvider = ({
  viewportId,
  children,
  sizes,
}: {
  viewportId: string;
  children: React.ReactNode;
  sizes?: {
    width: number;
    height: number;
  };
}): React.ReactElement => {
  const setScale = useSetRecoilState(scaleState(viewportId));
  const setSizes = useSetRecoilState(sizesState(viewportId));

  useScaleListener(setScale);

  useEffect(() => {
    setSizes(sizes);
  }, [setSizes, sizes]);

  return <SizesContext.Provider value={sizes}>{children}</SizesContext.Provider>;
};

/**
 * guideViewportAtom is local to DRE and holds data only useful to it.
 * We also have a selector that combines the above state with the global activeViewportState
 * to create a single state object that can be used to access a complete guide viewport state.
 *
 * `activeViewportState` is set by both DRE and Fovia and is used to determine the current viewport.
 */
export type GuideViewportAtom = Readonly<{
  direction: VtkDirection;
  id: string;
  sopInstanceUID: string | null | undefined;
  studySmid: string | null | undefined;
  stackSmid: string | null | undefined;
  viewType: DreAllViewportTypes;
  slicingModeIndex: 0 | 1 | 2;
}>;

export const guideViewportAtom: RecoilState<GuideViewportAtom> = atom<GuideViewportAtom>({
  key: 'dreGuideViewportWithoutId',
  default: {
    id: generateViewportId(0, 0, 0),
    direction: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    sopInstanceUID: null,
    viewType: 'TWO_D_DRE',
    slicingModeIndex: 2,
    studySmid: null,
    stackSmid: null,
  },
  effects: [broadcastChannelSynchronizerEffect({ unidirectional: true })],
});

export const ScrollLinkMode: {
  Disabled: 'disabled';
  Automatic: 'automatic';
  Manual: 'manual';
} = {
  Disabled: 'disabled',
  Automatic: 'automatic',
  Manual: 'manual',
};
export type TScrollLinkMode = (typeof ScrollLinkMode)[keyof typeof ScrollLinkMode];
const DEFAULT_LINKING_MODE: TScrollLinkMode = ScrollLinkMode.Automatic;

export const scrollLinkModeAtom: AtomFamily<TScrollLinkMode, string | null | undefined> =
  atomFamily({
    key: 'scroll-link-mode',
    default: DEFAULT_LINKING_MODE,
    effects: [
      broadcastChannelSynchronizerEffect({ unidirectional: true }),
      localStoragePersisterEffect(),
    ],
  });

export const isLinkedScrollingEnabledSelector: (
  caseSmid?: string | null | undefined
) => RecoilValueReadOnly<boolean> = selectorFamily({
  key: 'viewer.dre.linked-scrolling-selector',
  get:
    (caseSmid) =>
    ({ get }) => {
      const scrollLinkMode = get(scrollLinkModeAtom(caseSmid));
      return scrollLinkMode !== ScrollLinkMode.Disabled;
    },
});

/**
 * HACK(fzivolo): This hook is required to set the WWWC of the current viewport
 * from the WWWC presets dropdown. This is not ideal because we are tying the
 * internal DRE state with the outer application logic. We need to architect a
 * cleaner solution to this problem so that the DRE state doesn't leak outside.
 */

export const primitiveCursorsState: AtomFamily<
  {
    [key: string]: string | null | undefined;
  },
  string
> = atomFamily({
  key: 'drePrimitiveCursors',
  default: {},
});

export const useResetViewportState: (viewportId: string) => () => void = (viewportId) => {
  return useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(isCameraInitializedState(viewportId));
        reset(mouseAdaptorsState(viewportId));
        reset(scaleState(viewportId));
      },
    [viewportId]
  );
};

export const useResetAllViewportState = (): (() => void) => {
  const viewportsConfigurationsSelector = useViewportsConfigurationsSelector();

  return useRecoilCallback(
    ({ reset, snapshot, set }) =>
      () => {
        const viewportsConfigurations = snapshot
          .getLoadable(viewportsConfigurationsSelector)
          .getValue();

        Object.keys(viewportsConfigurations ?? {}).forEach((viewportId) => {
          reset(isCameraInitializedState(viewportId));
          reset(mouseAdaptorsState(viewportId));
          reset(scaleState(viewportId));
        });
      },
    [viewportsConfigurationsSelector]
  );
};

export const useResetState: (viewportId: string) => () => void = (viewportId) => {
  const resetState = useResetViewportState(viewportId);

  return useRecoilCallback(
    ({ reset }) =>
      () => {
        resetState();
      },
    [resetState]
  );
};

export const isActiveViewportSelector: (arg1: string) => RecoilValueReadOnly<boolean> =
  selectorFamily({
    key: 'viewer.dre.isActiveViewportSelector',
    get:
      (viewportId) =>
      ({ get }) =>
        get(activeViewportState) === viewportId,
  });

export const wlAdjustLayer: (
  viewportId: string
) => RecoilState<WLAdjustLayerType | null | undefined> = atomFamily({
  key: 'viewer.dre.wlAdjustLayer',
  default: null,
});

export const disableToolbarKeypressListenersState: RecoilState<boolean> = atom({
  key: 'disableToolbarKeypressListenersState',
  default: false,
});

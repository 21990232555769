import { Range, PathRef } from 'domains/reporter/RichTextEditor/core';
import type { Key } from 'slate-react';
import { useCallback } from 'react';
import { useUnmount } from 'react-use';
import { Editor } from 'slate';
import { useRecoilState } from 'recoil';
import { ReactEditor } from '../domains/reporter/RichTextEditor/core';
import { touchedRequiredFieldsState } from '../domains/reporter/Reporter/state';
import { logger } from '../modules/logger';
import {
  getEmptyTouchedRequiredFields,
  getRequiredFieldsInRange,
  isEmptyRequiredFieldNode,
} from '../domains/reporter/RichTextEditor/utils/requiredFields';
import { useSlateSingletonContext } from '../domains/reporter/Reporter/SlateSingletonContext';

// Get the empty required fields that have been touched by the user in the selection or entire editor if selection is null
const getEmptyRequiredFieldsMap = (editor: Editor, selection: Range | null = null) => {
  const emptyTouchedRequiredFields = getEmptyTouchedRequiredFields(editor, selection);
  const emptyTouchedRequiredFieldsMap = new Map<Key, PathRef>();

  for (const [node, nodePath] of emptyTouchedRequiredFields) {
    const pathRef = Editor.pathRef(editor, nodePath);
    const reactKey = ReactEditor.findKey(editor, node);
    emptyTouchedRequiredFieldsMap.set(reactKey, pathRef);
  }

  return emptyTouchedRequiredFieldsMap;
};

/**
   * When a required field has been touched and is empty, and the cursor has moved out of the field:
    A warning icon should appear
    On the same Y-index to the left of the report border
    Hovering tooltip explaining error
    The brackets of the required field should turn red
   */
export const useRequiredFieldIndicator = (): {
  syncRequiredFields: (selection: Range, editor: Editor) => void;
  resetRequiredFields: () => void;
  onInsertTextRequiredField: (key: Key) => void;
  setAllRequiredFieldsTouched: () => void;
} => {
  // touchedRequiredFields is a map of the empty touched required fields minus the most recent match
  const [, setTouchedRequiredFields] = useRecoilState(touchedRequiredFieldsState);
  const [{ editor }] = useSlateSingletonContext();

  /**
   * Syncs the required fields on every new selection, setTimeout is used to because of selection delay
   * Updates the touchedRequiredFieldsState such that it contains
   * the paths of all empty+touched+required fields in the previous selection
   * since the indicator is only shown after the user clicks out of the field
   */
  const syncRequiredFields = useCallback(
    (selection: Range, editor: Editor) => {
      if (selection == null) return;

      setTimeout(() => {
        try {
          setTouchedRequiredFields((touchedRequiredFields) => {
            const updatedTouchedRequiredFields = new Map<Key, PathRef>(touchedRequiredFields);
            const emptyTouchedRequiredFields = getRequiredFieldsInRange(editor, selection);

            for (const [node, nodePath] of emptyTouchedRequiredFields) {
              const pathRef = Editor.pathRef(editor, nodePath);
              const reactKey = ReactEditor.findKey(editor, node);

              if (isEmptyRequiredFieldNode(node) && !touchedRequiredFields.has(reactKey)) {
                logger.info(
                  `[useRequiredFieldIndicator] Required field was touched and is empty, marking with indicator`,
                  { field: node.name }
                );
                updatedTouchedRequiredFields.set(reactKey, pathRef);
              } else if (!isEmptyRequiredFieldNode(node) && touchedRequiredFields.has(reactKey)) {
                updatedTouchedRequiredFields.delete(reactKey);
              }
            }
            return updatedTouchedRequiredFields;
          });
        } catch (e: any) {
          logger.warn(`useRequiredFieldIndicator] Error syncing required fields`, {
            e,
          });
        }
      }, 10);
    },
    [setTouchedRequiredFields]
  );

  const onInsertTextRequiredField = useCallback(
    (key: Key) => {
      // setTimeout to trigger update after selection is updated after inserting text
      setTimeout(() => {
        setTouchedRequiredFields((touchedRequiredFields) => {
          const updatedTouchedRequiredFields = new Map(touchedRequiredFields);
          if (updatedTouchedRequiredFields.has(key)) {
            updatedTouchedRequiredFields.delete(key);
          }

          return updatedTouchedRequiredFields;
        });
      }, 300);
    },
    [setTouchedRequiredFields]
  );

  const resetRequiredFields = useCallback(() => {
    setTimeout(() => {
      setTouchedRequiredFields(new Map<Key, PathRef>());
    }, 50);
  }, [setTouchedRequiredFields]);

  const setAllRequiredFieldsTouched = () => {
    if (editor == null) return;
    const emptyTouchedRequiredFieldsMap = getEmptyRequiredFieldsMap(editor);
    if (emptyTouchedRequiredFieldsMap == null) return;

    setTouchedRequiredFields(() => emptyTouchedRequiredFieldsMap);
  };

  useUnmount(() => {
    resetRequiredFields();
  });

  return {
    syncRequiredFields,
    resetRequiredFields,
    onInsertTextRequiredField,
    setAllRequiredFieldsTouched,
  };
};

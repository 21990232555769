import { useToolPreferences } from 'hooks/usePreferences';
import { isWindows } from 'utils/browserDetect';

export const useIsScrollInverted = (): boolean => {
  const [prefs] = useToolPreferences();
  const invertScroll = !!prefs?.invertScroll;
  return invertScroll;
};

export const useGetScrollScale = (scale: number): number => {
  const invertScroll = useIsScrollInverted();
  const windowsScale = isWindows() ? -scale : scale;
  return invertScroll ? windowsScale * -1 : windowsScale;
};

export const useIsWwwcInverted = (): boolean => {
  const [prefs] = useToolPreferences();
  return Boolean(prefs?.wwwc.invertDragDirection);
};

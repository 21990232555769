import { Point } from 'domains/reporter/RichTextEditor/core';
import { useEffect } from 'react';
import { Editor, Transforms, Node, Range } from '../../core';
import { clone, equals } from 'ramda';
import { PUNCTUATIONS, endsWithWhitespace } from '../../stitching/normalizationHelpers';
import { useMostRecentInput, RICH_TEXT_EDITOR } from 'hooks/useMostRecentInput';
import { PICKLIST_PLUGIN_ID } from '../picklist/types';
import { isSelectionContainedInNode } from '../../utils/isSelectionContainedInNode';
import { find } from '../../utils/find';
import { Text, Element } from 'slate';

type IndexCheckProps = {
  text: string;
  characters: string[];
  last?: boolean;
};

// helper function that returns the nearest offset of a break character so we can expand a selection
const indexOfs = ({ text, characters, last }: IndexCheckProps) => {
  const indices = characters
    .map((character) => (last === true ? text.lastIndexOf(character) : text.indexOf(character)))
    //remove -1's for characters that arent found
    .filter((index) => index !== -1);

  if (!indices.length) return undefined;
  return last === true ? Math.max(...indices) : Math.min(...indices);
};

const breakCharacters = [' ', '\n', ...PUNCTUATIONS.split('')];

const getStartAndEndText = (
  editor: Editor,
  start: Point,
  end: Point
):
  | {
      startText: string;
      endText: string;
    }
  | undefined => {
  const selection = editor.selection;
  if (selection == null) return;

  const startType = Node.get(editor, start.path);
  // TODO: TS migration - should be Text.isText(startType)
  if (typeof (startType as Text).text !== 'string') return;
  const { text: startText } = startType as Text;

  const endType = Node.get(editor, end.path);
  const endText = (endType as Text)?.text;
  if (typeof endText !== 'string') return;

  if (startText === null || endText === null) return;

  return { startText, endText };
};

const getOffsetChanges = ({
  start,
  end,
  endText,
  beforeToStartOffset,
  endOffsetToAfter,
}: {
  start: Point;
  end: Point;
  endText: string;
  beforeToStartOffset: string;
  endOffsetToAfter: string;
}): {
  startOffsetChange: number;
  endOffsetChange: number;
} => {
  // grabs where the closest breakCharacter is, and expands the selection
  const expandedStartOffset = indexOfs({
    last: true,
    text: beforeToStartOffset,
    characters: breakCharacters,
  });

  let startOffsetChange = 0;
  // when there is no space at the front, we are selecting part of the first word in the node, and need to expand all the way
  // this occurs when the user selects a portion of the very first node (theres no spaces or punctuation, but we have to expand all the way)
  if (expandedStartOffset === undefined) {
    startOffsetChange = start.offset;
  } else {
    startOffsetChange = start.offset - (expandedStartOffset + 1);
  }

  // increase selection to the nearest break character
  const endOffsetChange =
    indexOfs({
      text: endOffsetToAfter,
      characters: breakCharacters,
    }) ??
    // when there is no space at the end, we are selecting part of the last word in the node, and need to expand all the way

    endText.length - end.offset;

  return { startOffsetChange, endOffsetChange };
};

/**
 * Given an editor, return a selection to be used for dictation purposes
 *
 * Currently, if a user's selection is collapsed, inside a word, and AFTER the first letter or BEFORE the last letter,
 * return a dictation selection that is at the end of the word
 */
export const getSelectionForDictation = (editor: Editor): Range | null => {
  if (editor.selection == null) {
    return null;
  }

  const selection = editor.selection;

  // return the original selection if not collapsed
  if (!Range.isCollapsed(selection)) {
    return editor.selection;
  }

  const [anchor] = Range.edges(selection);

  // using anchor for both start and end, since selection is collapsed.
  const texts = getStartAndEndText(editor, anchor, anchor);
  if (texts == null) return null;

  const { startText, endText } = texts;

  // gets the content before the selection, up to the start
  const beforeToStartOffset = startText.slice(0, anchor.offset);

  // from the end of the selection, to the end of the entire node
  const endOffsetToAfter = endText.slice(anchor.offset, endText.length);

  if (beforeToStartOffset.trim().endsWith('(') && endOffsetToAfter.trim().startsWith(')')) {
    return null;
  }

  const { startOffsetChange, endOffsetChange } = getOffsetChanges({
    start: anchor,
    end: anchor,
    endText,
    beforeToStartOffset,
    endOffsetToAfter,
  });

  // don't proceed if the cursor is at the start or at the end of the word
  if (startOffsetChange === 0 || endOffsetChange === 0) {
    return null;
  }

  // This is the point at the very end of the word in question
  const newPoint: Point = {
    path: selection.anchor.path,
    offset: selection.anchor.offset + endOffsetChange,
  };

  // If there is a space after the calculated point, return that selection instead.
  const [node] = Editor.node(editor, newPoint.path);
  if (Node.string(node)) {
    const character = Text.isText(node) && node.text[newPoint.offset];

    if (character === ' ') {
      return Editor.range(editor, { ...newPoint, offset: newPoint.offset + 1 });
    }
  }

  return Editor.range(editor, newPoint);
};

export const expandSelection = (
  editor: Editor,
  forceExpandPicklist?: boolean
): Range | undefined => {
  const { selection: editorSelection } = editor;

  if (editorSelection == null) {
    return;
  }

  const selection = Editor.unhangRange(editor, editorSelection);

  if (forceExpandPicklist === true) {
    const picklistEntry = find(editor, (n) => n.type === PICKLIST_PLUGIN_ID);

    // If you are using the field pane in either the macro and template editors, we do not allow you to directly interact with
    // the picklist element in the editor. Rather, you need to edit the options in the pane. As a result, we don't allow you to partially
    // select a picklist. If your selection is expanded and includes only a portion of the picklist, we move your selection to entirely select the picklist (including brackets).
    if (selection != null && picklistEntry != null) {
      const isRangeBackward = Range.isBackward(selection);

      if (
        Range.isCollapsed(selection) ||
        isSelectionContainedInNode(editor, picklistEntry[1], editor.selection)
      ) {
        const startPicklistPoint = Editor.start(editor, picklistEntry[1]);
        const endPicklistPoint = Editor.end(editor, picklistEntry[1]);

        if (startPicklistPoint && endPicklistPoint != null) {
          return {
            anchor: isRangeBackward ? endPicklistPoint : startPicklistPoint,
            focus: isRangeBackward ? startPicklistPoint : endPicklistPoint,
          };
        }
      } else {
        const expandedSelection = clone(selection);

        const isAnchorInPicklist =
          expandedSelection.anchor != null &&
          Range.intersection(Editor.range(editor, picklistEntry[1]), {
            anchor: expandedSelection.anchor,
            focus: expandedSelection.anchor,
          }) != null;

        if (isAnchorInPicklist) {
          const anchorPoint = isRangeBackward
            ? Editor.after(editor, picklistEntry[1])
            : Editor.before(editor, picklistEntry[1]);
          if (anchorPoint != null) {
            expandedSelection.anchor = anchorPoint;
          }
        }

        const maybeFocusPicklist = Array.from(
          Editor.nodes(editor, {
            at: expandedSelection.focus,
            match: (n) => Element.isElement(n) && n.type === PICKLIST_PLUGIN_ID,
          })
        );

        const isFocusInPicklist =
          expandedSelection.focus != null &&
          maybeFocusPicklist.length > 0 &&
          Range.intersection(Editor.range(editor, maybeFocusPicklist[0][1]), {
            anchor: expandedSelection.focus,
            focus: expandedSelection.focus,
          }) != null;

        if (isFocusInPicklist && maybeFocusPicklist[0][1] != null) {
          const anchorPoint = isRangeBackward
            ? Editor.before(editor, picklistEntry[1])
            : Editor.after(editor, picklistEntry[1]);
          if (anchorPoint != null) {
            expandedSelection.focus = anchorPoint;
          }
        }

        return expandedSelection;
      }
    }
  }

  // If the selection is collapsed and we are forcing to expand picklists, the cursor should automatically
  // expand to select the entire picklist
  if (Range.isCollapsed(selection)) {
    return;
  }

  // grabs the nodes at the start and end of a selection
  const [start, end] = Range.edges(selection);

  const texts = getStartAndEndText(editor, start, end);

  if (texts == null) return selection;

  const { startText, endText } = texts;

  // determines whitespace-only selections within the same selection path
  const isSelectionWhitespaceOnly =
    startText.slice(start.offset, end.offset).replace(/\s/g, '').length === 0 &&
    equals(start.path, end.path);

  // In Windows, double clicking on a word in the browser will select the word and any proceeding whitespace. Combined with the expandSelection function, this will cause the selection to expand to the next word. This check prevents that from happening.
  const selectedText = startText.slice(start.offset, end.offset);

  // gets the content before the selection, up to the start
  const beforeToStartOffset = startText.slice(
    0,
    isSelectionWhitespaceOnly ? start.offset + 1 : start.offset
  );

  // from the end of the selection, to the end of the entire node
  const endOffsetToAfter = endText.slice(
    isSelectionWhitespaceOnly || endsWithWhitespace(selectedText) ? end.offset - 1 : end.offset,
    endText.length
  );

  const { startOffsetChange, endOffsetChange } = getOffsetChanges({
    start,
    end,
    endText,
    beforeToStartOffset,
    endOffsetToAfter,
  });

  if (startOffsetChange === 0 && endOffsetChange === 0) {
    return selection;
  }

  const { anchor, focus } = selection;

  // user can drag a selection starting left to right, or from right to left, we must account for that
  const isBackward = Range.isBackward(selection);

  // create the new anchors / focus
  // its a bit tricky because we have to make it correctly if isBackward is true, or if only one side of the selection changes
  const newAnchor =
    startOffsetChange > 0
      ? {
          ...anchor,
          offset: start.offset - startOffsetChange,
        }
      : anchor;

  const newFocus =
    endOffsetChange > 0
      ? {
          ...focus,
          offset: end.offset + endOffsetChange,
        }
      : focus;

  const newBackwardAnchor =
    endOffsetChange > 0
      ? {
          ...anchor,
          offset: end.offset + endOffsetChange,
        }
      : anchor;

  const newBackwardFocus =
    startOffsetChange > 0
      ? {
          ...focus,
          offset: start.offset - startOffsetChange,
        }
      : focus;

  return {
    anchor: isBackward ? newBackwardAnchor : newAnchor,
    focus: isBackward ? newBackwardFocus : newFocus,
  };
};

type UseExpandSelectionProps = {
  editor: Editor;
  selection: Range | null | undefined;
  forceExpandPicklist?: boolean;
};
export const useExpandSelection = ({
  editor,
  selection,
  forceExpandPicklist = false,
}: UseExpandSelectionProps) => {
  const { isMousePressed } = useMostRecentInput(RICH_TEXT_EDITOR);

  useEffect(() => {
    if (isMousePressed) return;

    const newSelection = expandSelection(editor, forceExpandPicklist);
    if (newSelection === undefined) return;

    Transforms.select(editor, newSelection);
  }, [editor, forceExpandPicklist, isMousePressed, selection]);
};

import { Colors } from 'styles';
import styled from 'styled-components';

type DropdownIndicatorProps = {
  color?: string;
  height?: string;
  width?: string;
  css?: string;
};

export const DropdownIndicator: React.ComponentType<DropdownIndicatorProps> = styled.div`
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-color: ${(props: DropdownIndicatorProps) => props.color ?? Colors.white};
  height: ${(props: DropdownIndicatorProps) => props.height ?? '0.5em'};
  width: ${(props: DropdownIndicatorProps) => props.width ?? '0.5em'};

  position: relative;
  top: 2rem;
  right: 0.5rem;
  opacity: 0.6;
  pointer-events: none;
`;

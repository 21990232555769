import vtkCamera from '@kitware/vtk.js/Rendering/Core/Camera';
import { vec3 as vector3, mat4 } from 'gl-matrix';
import { radiansFromDegrees } from '@kitware/vtk.js/Common/Core/Math';
import type { Vector3 as vec3 } from '@kitware/vtk.js/types';

export const rotateFromImageCenter = (
  camera: vtkCamera,
  /* absolute roll angle in degrees */
  roll: number,
  center: vec3
): void => {
  const centerNeg = vector3.create();
  const axis = vector3.create();
  const transform = mat4.create();
  const newCamPos = vector3.create();
  const newFp = vector3.create();
  const newViewUp = vector3.create();
  const direction = vector3.create();
  const cameraPos = camera.getPosition();
  const cameraFp = camera.getFocalPoint();
  const viewUp = camera.getViewUp();
  axis[0] = cameraFp[0] - cameraPos[0];
  axis[1] = cameraFp[1] - cameraPos[1];
  axis[2] = cameraFp[2] - cameraPos[2];
  mat4.identity(transform);
  centerNeg[0] = -center[0];
  centerNeg[1] = -center[1];
  centerNeg[2] = -center[2];

  // Translate to center
  mat4.translate(transform, transform, center);
  // roll
  mat4.rotate(transform, transform, radiansFromDegrees(roll), axis);
  // Translate back
  mat4.translate(transform, transform, centerNeg);

  // Apply transformation to camera position, focal point, and view up
  vector3.transformMat4(newCamPos, cameraPos, transform);
  vector3.transformMat4(newFp, cameraFp, transform);

  direction[0] = viewUp[0] + cameraPos[0];
  direction[1] = viewUp[1] + cameraPos[1];
  direction[2] = viewUp[2] + cameraPos[2];
  vector3.transformMat4(newViewUp, direction, transform);

  camera.setPosition(newCamPos[0], newCamPos[1], newCamPos[2]);
  camera.setFocalPoint(newFp[0], newFp[1], newFp[2]);
  camera.setViewUp(
    newViewUp[0] - newCamPos[0],
    newViewUp[1] - newCamPos[1],
    newViewUp[2] - newCamPos[2]
  );
  camera.orthogonalizeViewUp();
};

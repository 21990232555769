import { useRef, useState, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { BroadcastChannel } from 'broadcast-channel';
import { WORKLIST_UPDATE } from 'config/constants';
import { ReportStatus, reportStatusState } from '../state';
import { getCountdownSeconds } from 'utils/report';

export const worklistUpdateBc: BroadcastChannel<string> = new BroadcastChannel<string>(
  WORKLIST_UPDATE
);

type UseCountdownOpts = {
  worklistItem: Readonly<{
    smid: string;
  }>;
  onStart?: () => void;
  onCancel?: () => void;
};

type UseCountdownReturn = {
  cancelCountdown: (v?: { request: boolean | null | undefined }) => Promise<void>;
  clearTimer: () => void;
  countdownSeconds: null | number;
  setCountdownSeconds: (arg1: ((arg1: null | number) => null | number) | null | number) => void;
  startCountdown: (v?: {
    submittedAt: string | null | undefined | Date | null | undefined;
  }) => Promise<void>;
};

export const useCountdown = ({
  worklistItem,
  onStart = () => {},
  onCancel = () => {},
}: UseCountdownOpts): UseCountdownReturn => {
  const setStatus = useSetRecoilState(reportStatusState);
  const [countdownSeconds, setCountdownSeconds] = useState(null);
  const countdownIntervalRef = useRef();

  const clearTimer = useCallback(() => {
    setCountdownSeconds(null);
    clearInterval(countdownIntervalRef.current);
  }, []);

  const changeTime = useCallback((submittedAt: string | Date) => {
    setCountdownSeconds(getCountdownSeconds(submittedAt));
  }, []);

  const startCountdown = useCallback(
    async ({
      submittedAt,
    }: {
      submittedAt?: string | Date | null | undefined;
    } = {}) => {
      clearTimer();
      onStart();

      setStatus((status) =>
        status === ReportStatus.AddendumAdd
          ? ReportStatus.ProvisionalAddendumSubmit
          : ReportStatus.ProvisionalSubmit
      );

      const submittedAtWithFallback = submittedAt != null ? submittedAt : new Date().toString();

      changeTime(submittedAtWithFallback);

      // interval every 500ms to prevent edge cases to do with rounding errors with timestamp-differences
      // @ts-expect-error [EN-7967] - TS2322 - Type 'Timeout' is not assignable to type 'undefined'.
      countdownIntervalRef.current = setInterval(() => changeTime(submittedAtWithFallback), 500);

      worklistUpdateBc.postMessage(worklistItem.smid);
    },
    [worklistItem, setStatus, onStart, clearTimer, changeTime]
  );

  const cancelCountdown = useCallback(
    async ({
      request = true,
    }: {
      request?: boolean | null | undefined;
    } = {}) => {
      clearTimer();
      onCancel();

      worklistUpdateBc.postMessage(worklistItem.smid);
    },
    [worklistItem, onCancel, clearTimer]
  );

  return {
    countdownSeconds,
    setCountdownSeconds,
    startCountdown,
    cancelCountdown,
    clearTimer,
  };
};

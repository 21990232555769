import vtkOpenGLRenderWindow from '@kitware/vtk.js/Rendering/OpenGL/RenderWindow';
import { sleep } from 'utils/sleep';
import { useContext } from 'react';
import { Contexts } from 'react-vtk-js';

type Status = 'pending' | 'error' | 'success';

const MAX_ATTEMPTS = 20;
const SLEEP_TIME = 100;

/**
 * This waits for the renderer to be available and suspends the component
 * until it is. It will throw an error if the renderer is not available
 * after the specified number of attempts.
 * The error can be caught and handled by the parent component with an
 * ErrorBoundary.
 */
export function useOpenGLRenderWindow(): vtkOpenGLRenderWindow {
  const view = useContext(Contexts.ViewContext);
  let result;

  let status: Status = 'pending';

  const promise = new Promise(async (resolve, reject: (error?: any) => void) => {
    // @ts-expect-error [EN-7967] - TS2339 - Property 'getOpenGLRenderWindow' does not exist on type 'unknown'.
    let openGLRenderWindow = view?.getOpenGLRenderWindow()?.get();
    let attempts = 0;
    while ((view == null || openGLRenderWindow == null) && attempts < MAX_ATTEMPTS) {
      attempts += 1;
      await sleep(SLEEP_TIME);
      // @ts-expect-error [EN-7967] - TS2339 - Property 'getOpenGLRenderWindow' does not exist on type 'unknown'.
      openGLRenderWindow = view?.getOpenGLRenderWindow()?.get();
    }

    if (attempts >= MAX_ATTEMPTS || view == null || openGLRenderWindow == null) {
      status = 'error';
      return reject(new Error('No openGLRenderWindow found'));
    }

    result = openGLRenderWindow;
    status = 'success';
    resolve(result);
  });

  switch (status) {
    case 'pending':
      throw promise;
    // @ts-expect-error [EN-7967] - TS2678 - Type '"error"' is not comparable to type '"pending"'.
    case 'error':
      throw new Error('No openGLRenderWindow found');
    // @ts-expect-error [EN-7967] - TS2678 - Type '"success"' is not comparable to type '"pending"'.
    case 'success':
      if (result == null) {
        throw new Error(
          'Unrechable error: openGLRenderWindow should always be defined at this point'
        );
      }
      return result;
    default:
      throw new Error(`Unknown status: ${status}`);
  }
}

import { Editor } from 'domains/reporter/RichTextEditor/core';
import { withInlineTemplate } from '../common/inlineTemplate';
import type { CreateEnhanceEditorState } from '../../types';
import type { MacroPlaceholderPluginPropertyOptions, MacroPlaceholderPluginElement } from './types';

export const enhanceEditorStateMacroPlaceholder: CreateEnhanceEditorState<
  MacroPlaceholderPluginPropertyOptions
> = (options) => (editor: Editor) => {
  return withInlineTemplate<MacroPlaceholderPluginElement>({
    pluginID: options.pluginID,
  })(editor);
};

import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { transparentize } from 'color2k';
import colors from 'styles/colors';
import { useFeatureFlagEnabled, FF } from 'modules/feature-flags';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${transparentize(colors.gray1, 0.2)};
  z-index: 1;
  padding-top: 50px;
  text-align: center;
`;

type Props = {
  loading: boolean;
  hasData: boolean;
  overrideLinearProgress?: boolean;
};

const TableLoading = ({
  loading,
  hasData,
  overrideLinearProgress = false,
}: Props): React.ReactElement | null => {
  const [isWorklistColumnFiltersEnabled, isWorklistColumnFiltersLoading] = useFeatureFlagEnabled(
    FF.WORKLIST_COLUMN_FILTERS
  );
  if (!loading) {
    return null;
  }

  if (
    (hasData || isWorklistColumnFiltersEnabled || isWorklistColumnFiltersLoading) &&
    !overrideLinearProgress
  ) {
    return <LinearProgress css="position: relative; margin-bottom: -1px; height: 1px;" />;
  }

  return (
    <Overlay data-testid="table-loading">
      <CircularProgress />
    </Overlay>
  );
};

export default TableLoading;

import type { EditorPlugin } from '../../types';
import { ItalicEditable } from './components';
import { onKeyDownItalic } from './onKeyDownItalic';
import { getRenderLeaf } from '../../utils';
import { ITALIC_PLUGIN_ID } from './types';
import type { ItalicPluginOptions, ItalicPluginPropertyOptions } from './types';
import { MarkButton } from '../../components';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';

export const DEFAULT_ITALIC_HOTKEY = 'mod+i';

export const ItalicPlugin = ({
  component = ItalicEditable,
  pluginName = 'Italic',
  hotkey = DEFAULT_ITALIC_HOTKEY,
  icon = FormatItalicIcon,
  ...rest
}: ItalicPluginOptions = {}): EditorPlugin => {
  const options: ItalicPluginPropertyOptions = {
    pluginID: ITALIC_PLUGIN_ID,
    pluginName,
    hotkey,
    icon,
    component,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    renderLeaf: getRenderLeaf(options),
    onKeyDown: onKeyDownItalic(options),
    hoveringToolbarConfig: () => [
      {
        pluginID: options.pluginID,
        toolbarButton: (
          <MarkButton
            icon={options.icon}
            pluginID={options.pluginID}
            pluginName={options.pluginName}
          />
        ),
      },
    ],
  };
};

import { createContext, useContext, useEffect } from 'react';
import { useRecoilState as useState } from 'recoil';
// @ts-expect-error [EN-7967] - TS2305 - Module '"@splitsoftware/splitio"' has no exported member 'SplitClient'.
import type { SplitClient } from '@splitsoftware/splitio';

import { useCurrentUser } from 'hooks/useCurrentUser';
import { splitIoState } from './state';
import { getClientWithState } from './StatefulSplitClient';
import type { StatefulSplitClient, StatefulSplitClientState } from './StatefulSplitClient';
import { logger } from 'modules/logger';

export const MockSplitClientContext: React.Context<StatefulSplitClient | null> =
  createContext(null);

export const useSplitClient = (): [SplitClient | null | undefined, boolean, boolean] => {
  // To get around exhaustive-deps we can use the 'useState' alias but using
  // recoil state (as they are equivalent). This way we do not have to ignore the line
  const [state, setState] = useState(splitIoState);
  const { data } = useCurrentUser();
  const email = data?.me.email;

  const mockedSplitClient = useContext(MockSplitClientContext);

  useEffect(() => {
    if (email == null) {
      return;
    }

    const statefulClient = getClientWithState(email);

    setState((state) => {
      if (
        state.email === email &&
        state.client === statefulClient &&
        state.ready === (statefulClient.state === 'ready') &&
        state.timeout === (statefulClient.state === 'timeout')
      ) {
        return state;
      }

      return {
        ...state,
        client: statefulClient,
        email,
        ready: statefulClient.state === 'ready',
        timeout: statefulClient.state === 'timeout',
      };
    });

    if (statefulClient.state === 'pending') {
      const onStateChange = (state: StatefulSplitClientState) => {
        if (state === 'ready') {
          logger.info('Legacy Split Client: ready');

          setState((state) => ({ ...state, ready: true }));
        } else if (state === 'timeout') {
          logger.info('Legacy Split Client: timed out');
          setState((state) => ({ ...state, timeout: true }));
        }
      };

      statefulClient.onStateChange(onStateChange);
      return () => statefulClient.offStateChange(onStateChange);
    } else {
      // this message is spammy so it will hide in debug
      logger.debug(`Legacy Split Client: already ${statefulClient.state}`);
    }
  }, [email]);

  return mockedSplitClient
    ? [
        mockedSplitClient.splitClient,
        mockedSplitClient.state === 'ready',
        mockedSplitClient.state === 'timeout',
      ]
    : [state.client?.splitClient, state.ready, state.timeout];
};

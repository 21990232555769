import { nanoid } from 'nanoid';
import type { Vector3 as vec3 } from '@kitware/vtk.js/types';

import type { DreAllViewportTypes } from 'config/constants';

import type { BaseImagingProvider } from './BaseImagingProvider';

import { ScrollLinkMode } from '../state';
import type { TScrollLinkMode } from '../state';
import type { TStackProviders } from '../../../Viewer/ViewerContext';
import { DRE_ALL_VIEWPORT_TYPES } from 'config/constants';
import type { Stack } from '../../../ViewportsConfigurations/types';

type LinkType = (typeof ScrollLinkMode)['Automatic'] | (typeof ScrollLinkMode)['Manual'];
type LinkInfo = {
  viewType: DreAllViewportTypes;
  stackProvider: BaseImagingProvider<Stack>;
  stackSmid: string;
};
export type PositionToSliceTransform = (
  source?: vec3 | null | undefined,
  manualScrollDistance?: vec3 | null | undefined
) => number | null | undefined;
type LinkedSet = {
  a: LinkInfo;
  b: LinkInfo;
  type: LinkType;
  aPositionToBSlice: PositionToSliceTransform;
  bPositionToASlice: PositionToSliceTransform;
};

function computeStackLinkKey(viewType: DreAllViewportTypes, stackSmid: string): string {
  return `${viewType}:${stackSmid}`;
}

/**
 * Manages connections (links) of specific views between stacks.
 */
export default class LinkManager {
  /**
   * Current mode for scroll linking.
   *
   * If "automatic", only links with a type of "automatic" will be used.
   * If "manual", links with a type of "manual" OR "automatic" will be used.
   */
  #scrollLinkMode: TScrollLinkMode;

  /**
   * A map keyed by a string in the format `{viewId}:{stackSmid}`,
   * with each value being a list of unique string identifiers (nanoid)
   * matching an element in {linkedStacksMap}.
   */
  #stackToLinksMap: Map<string, Array<string>>;

  /**
   * A map keyed by a unique string identifier, with each value being
   * a single set of stack + view types that are linked together along with
   * a transform function to map the slice position of one stack onto the other.
   */
  #linkedStacksMap: Map<string, LinkedSet>;

  constructor() {
    this.#stackToLinksMap = new Map<string, Array<string>>();
    this.#linkedStacksMap = new Map<string, LinkedSet>();
    this.#scrollLinkMode = ScrollLinkMode.Automatic;
  }

  /**
   * Disable all linked scrolling between stacks.
   */
  disableLinkedScrolling() {
    this.#scrollLinkMode = ScrollLinkMode.Disabled;
  }

  /**
   * Only stacks that we have automatically linked together via the `FrameOfReferenceUID`
   * and parallel images method will scroll in sync.
   */
  enableAutomaticLinkedScrolling() {
    this.#scrollLinkMode = ScrollLinkMode.Automatic;
  }

  /**
   * All stacks that have been linked due to having parallel images will scroll together.
   * (Includes all 'automatic' and 'manual' type links)
   */
  enableManualLinkedScrolling() {
    this.#scrollLinkMode = ScrollLinkMode.Manual;
  }

  isScrollLinkingEnabled(): boolean {
    return this.#scrollLinkMode !== ScrollLinkMode.Disabled;
  }

  getScrollLinkMode(): TScrollLinkMode {
    return this.#scrollLinkMode;
  }

  /**
   * Determines if a given view of a stack is linked to any other stacks' views.
   */
  hasLinks(smid: string, viewType: DreAllViewportTypes): boolean {
    const linkKey = computeStackLinkKey(viewType, smid);
    const links = this.#stackToLinksMap.get(linkKey) ?? [];
    return links.length > 0;
  }

  /**
   * For the given {viewType} for the stack identified by {stackSmid},
   * return a list of all other stacks that are linked to it along with
   * a transform function to map the slice position of the given stack
   * to a slice position in the linked stack.
   */
  getLinksForView(
    smid: string,
    viewType: DreAllViewportTypes
  ): Array<{
    targetProvider: BaseImagingProvider<Stack>;
    targetViewType: DreAllViewportTypes;
    positionToSliceTransform: PositionToSliceTransform;
  }> {
    const linkKey = computeStackLinkKey(viewType, smid);
    const links = this.#stackToLinksMap.get(linkKey) ?? [];
    return links
      .map((linkId) => {
        const linkedSet = this.#linkedStacksMap.get(linkId);

        if (linkedSet == null) return null;

        const { a, b, type } = linkedSet;

        // Manual scroll links can only be used in manual scroll mode,
        // while automatic scroll links can be used in either mode.
        if (
          this.#scrollLinkMode === ScrollLinkMode.Disabled ||
          (this.#scrollLinkMode === ScrollLinkMode.Automatic && type === ScrollLinkMode.Manual)
        )
          return null;

        const target = a.stackProvider.linkSmid === smid ? b : a;
        const positionToSliceTransform =
          a.stackProvider.linkSmid === smid
            ? linkedSet.aPositionToBSlice
            : linkedSet.bPositionToASlice;

        return {
          targetProvider: target.stackProvider,
          targetViewType: target.viewType,
          positionToSliceTransform,
        };
      })
      .filter(Boolean);
  }

  /**
   * Identifies views of two distinct stacks that are linked together.
   */
  linkStackViews({
    viewTypeA,
    stackProviderA,
    viewTypeB,
    stackProviderB,
    type,
    aPositionToBSlice,
    bPositionToASlice,
  }: {
    viewTypeA: DreAllViewportTypes;
    stackProviderA: BaseImagingProvider<Stack>;
    viewTypeB: DreAllViewportTypes;
    stackProviderB: BaseImagingProvider<Stack>;
    type: LinkType;
    aPositionToBSlice: PositionToSliceTransform;
    bPositionToASlice: PositionToSliceTransform;
  }): LinkedSet | null | undefined {
    // check that both stacks have a frame of reference. If either does not, there should not be
    // any linking or localization lines
    if (
      stackProviderA.getFrameOfReference() == null ||
      stackProviderB.getFrameOfReference() == null
    ) {
      return null;
    }

    const linkId = nanoid();
    const linkKeyA = computeStackLinkKey(viewTypeA, stackProviderA.linkSmid);
    const linkKeyB = computeStackLinkKey(viewTypeB, stackProviderB.linkSmid);
    const aLinks: Array<string> = this.#stackToLinksMap.get(linkKeyA) ?? [];
    const bLinks: Array<string> = this.#stackToLinksMap.get(linkKeyB) ?? [];
    const existingLinkId = aLinks.find((linkId) => bLinks.includes(linkId));

    if (existingLinkId != null) {
      const existingLink = this.#linkedStacksMap.get(existingLinkId);

      if (existingLink != null) {
        return existingLink;
      }
    } else if (aLinks.length > 0 && bLinks.length === 0) {
      // Stack + View A is already linked, but Stack + View B is not.
      // Add Stack + View B to the existing list of links for Stack + View A.
      aLinks.push(linkId);
      this.#stackToLinksMap.set(linkKeyB, [linkId]);
    } else if (aLinks.length === 0 && bLinks.length > 0) {
      // Stack + View B is already linked, but Stack + View A is not.
      // Add Stack + View A to the existing list of links for Stack + View B.
      bLinks.push(linkId);
      this.#stackToLinksMap.set(linkKeyA, [linkId]);
    } else if (aLinks.length === 0 && bLinks.length === 0) {
      // Neither stack + view is linked, so create new links for both.
      this.#stackToLinksMap.set(linkKeyA, [linkId]);
      this.#stackToLinksMap.set(linkKeyB, [linkId]);
    } else {
      // Both stacks A and B are already linked to other stacks, but not each other.
      aLinks.push(linkId);
      bLinks.push(linkId);
    }

    const link = {
      type,
      aPositionToBSlice,
      bPositionToASlice,
      a: {
        viewType: viewTypeA,
        stackProvider: stackProviderA,
        stackSmid: stackProviderA.stack.smid,
      },
      b: {
        viewType: viewTypeB,
        stackProvider: stackProviderB,
        stackSmid: stackProviderB.stack.smid,
      },
    } as const;

    this.#linkedStacksMap.set(linkId, link);

    return link;
  }

  purgeOldProviderLinks(currentProviders: TStackProviders) {
    this.#linkedStacksMap.forEach((link, linkKey) => {
      const mapProviderA = currentProviders.get(link.a.stackSmid);
      const mapProviderB = currentProviders.get(link.b.stackSmid);
      if (mapProviderA !== link.a.stackProvider || mapProviderB !== link.b.stackProvider) {
        this.#linkedStacksMap.delete(linkKey);
        DRE_ALL_VIEWPORT_TYPES.forEach((viewType) => {
          const stackALinkKey = computeStackLinkKey(viewType, link.a.stackSmid);
          const stackBLinkKey = computeStackLinkKey(viewType, link.b.stackSmid);

          const stackViewATypeLinks = this.#stackToLinksMap.get(stackALinkKey);
          if (stackViewATypeLinks != null) {
            this.#stackToLinksMap.set(
              stackALinkKey,
              stackViewATypeLinks.filter((stackLinkKey) => stackLinkKey !== linkKey)
            );
          }

          const stackViewBTypeLinks = this.#stackToLinksMap.get(stackBLinkKey);
          if (stackViewBTypeLinks != null) {
            this.#stackToLinksMap.set(
              stackBLinkKey,
              stackViewBTypeLinks.filter((stackLinkKey) => stackLinkKey !== linkKey)
            );
          }
        });
      }
    });
  }
}

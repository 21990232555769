import { Switch, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { useVirtualMonitorSplitToggle } from 'hooks/useVirtualMonitorSplitToggle';
import { VirtualMonitorSplit } from 'generated/graphql';
import { Icon } from '../ui/Icon';
import spacings from 'styles/spacings';
import Text from 'common/ui/Text';
import { Stack, Spacer } from 'common/ui/Layout';
import { useCallback } from 'react';

const VIRTUAL_MONITOR_SPLIT_LABEL_TEXT = 'Virtual monitor split';

export const VirtualMonitorSplitSettings = (): React.ReactElement => {
  const [virtualMonitorSplit, setVirtualMonitorSplitChange] = useVirtualMonitorSplitToggle();
  const isEnabled = virtualMonitorSplit != null;

  const handleVirtualMonitorSplitChange = useCallback(
    async (e, eventValue: boolean | VirtualMonitorSplit) => {
      // Handle radio group change
      if (typeof eventValue === 'string') {
        await setVirtualMonitorSplitChange(eventValue);
        return;
      }
      // Handle switch change
      await setVirtualMonitorSplitChange(eventValue === true ? VirtualMonitorSplit.Vertical : null);
    },
    [setVirtualMonitorSplitChange]
  );

  return (
    <>
      <Stack alignY="center">
        <Text>{VIRTUAL_MONITOR_SPLIT_LABEL_TEXT}</Text>
        <Spacer />
        <Switch
          checked={isEnabled}
          onChange={handleVirtualMonitorSplitChange}
          inputProps={{ 'aria-label': VIRTUAL_MONITOR_SPLIT_LABEL_TEXT }}
        />
      </Stack>
      {isEnabled && (
        <Stack
          alignY="center"
          style={{
            paddingLeft: `${spacings.medium}rem`,
          }}
        >
          <RadioGroup
            aria-label="Virtual monitor split orientation"
            value={virtualMonitorSplit}
            // @ts-expect-error [EN-7967] - TS2322 - Type '(e: any, eventValue: boolean | VirtualMonitorSplit) => Promise<void>' is not assignable to type '(event: ChangeEvent<HTMLInputElement>, value: string) => void'.
            onChange={handleVirtualMonitorSplitChange}
          >
            <VirtualMonitorSplitRadio
              value={VirtualMonitorSplit.Vertical}
              savedPreference={virtualMonitorSplit}
            />
            <VirtualMonitorSplitRadio
              value={VirtualMonitorSplit.Horizontal}
              savedPreference={virtualMonitorSplit}
            />
          </RadioGroup>
        </Stack>
      )}
    </>
  );
};

const VirtualMonitorSplitRadio = ({
  value,
  savedPreference,
}: {
  value: VirtualMonitorSplit;
  savedPreference: VirtualMonitorSplit | null;
}) => (
  <FormControlLabel
    value={value}
    name="virtualMonitorSplit"
    checked={savedPreference === value}
    control={<Radio color="primary" />}
    label={
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <Text style={{ textTransform: 'capitalize', marginRight: `${spacings.xsmall}rem` }}>
          {value}
        </Text>
        <Icon name={`border_${value}`} />
      </span>
    }
    labelPlacement="end"
  />
);

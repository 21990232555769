import { CHROME_EXTENSION_INSTALL_PAGE } from 'config/constants';
import { PAGE_TYPES } from 'utils/pageTypes';
import Text from 'common/ui/Text';
import type { PageTypes } from 'utils/pageTypes';
import type { GenericSnackBarProps } from 'common/GenericSnackBar/GenericSnackBar';
import { isWorkspaceManagerAvailable } from 'modules/workspace-manager';

const BrowserExtensionInstallLink = () => (
  <>
    We recommend you{' '}
    <Text as="a" href={CHROME_EXTENSION_INSTALL_PAGE} target="_blank" rel="noopener noreferrer">
      install our browser extension,
    </Text>{' '}
    which allows the Sirona Workspace to automatically take you to the appropriate window.
  </>
);

type DisplayMessageArguments = {
  openedViewerIds: Set<string>;
  isReporterOpen: boolean;
  setSnackbarMessage: (arg1: {
    message: React.ReactNode;
    severity: GenericSnackBarProps['severity'];
  }) => void;
  viewerIndex: string;
  pageType: PageTypes;
};

export const displayMessage = ({
  openedViewerIds,
  isReporterOpen,
  setSnackbarMessage,
  viewerIndex,
  pageType,
}: DisplayMessageArguments): void => {
  switch (pageType) {
    case PAGE_TYPES.VIEWER: {
      // If a new viewer was just opened, display a message to the user
      if (openedViewerIds.has(viewerIndex) === false) {
        // If the user has not the workspace manager available we want to warn the user
        // that the case has been loaded but that we could not automatically focus the
        // viewer window
        if (!isWorkspaceManagerAvailable()) {
          setSnackbarMessage({
            message: (
              <>
                Viewer {viewerIndex} is already open. <BrowserExtensionInstallLink />
              </>
            ),
            severity: 'info',
          });
        } else {
          setSnackbarMessage({
            message: 'Viewer loaded in new window.',
            severity: 'success',
          });
        }
      }
      break;
    }
    case PAGE_TYPES.REPORTER: {
      if (!isReporterOpen) {
        setSnackbarMessage({
          message: 'Reporter loaded in new window.',
          severity: 'success',
        });
      } else if (!isWorkspaceManagerAvailable()) {
        setSnackbarMessage({
          message: (
            <>
              A Reporter is already open. <BrowserExtensionInstallLink />
            </>
          ),
          severity: 'info',
        });
      }
      break;
    }
    default:
  }
};

import { Range, Editor } from 'domains/reporter/RichTextEditor/core';
import { getSelectionForDictation } from '../plugins/expandSelection/useExpandSelection';

export const getEditorDictationSelectionSafe = (editor: Editor): Range => {
  if (editor.selection == null) {
    throw new Error('Cannot get editor selection of null!');
  }

  const selection = getSelectionForDictation(editor) ?? editor.selection;

  if (selection == null) {
    throw new Error('Cannot get editor dictation selection of null!');
  }

  return selection;
};

export const getEditorSelectionSafe = (editor: Editor): Range => {
  if (editor.selection == null) {
    throw new Error('Cannot get editor selection of null!');
  }

  return editor.selection;
};

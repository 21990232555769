import { useRecoilState } from 'recoil';
import { emptyRequiredFieldsDialogRecoilState } from 'domains/reporter/Reporter/state';
import DialogConfirm from 'common/ui/DialogConfirm';
import { useSlateSingletonContext } from 'domains/reporter/Reporter/SlateSingletonContext';
import { useFields } from 'domains/reporter/Reporter/Fields/Fields.Context';
import { moveSelectionToFirstEmptyRequiredField } from 'domains/reporter/RichTextEditor/utils';
import { EmptyRequiredFieldsDialogContent } from 'domains/reporter/RichTextEditor/plugins/requiredFields/components/EmptyRequiredFieldsDialogContent';
import { DEFAULT_FIELDS_WARNING_MODAL_PROPS } from './constants';

export const RequiredFieldsEnforcement = (): React.ReactElement => {
  const [{ editor }] = useSlateSingletonContext();
  const [{ content }] = useFields();

  const [emptyRequiredFieldsDialogState, setEmptyRequiredFieldsDialogState] = useRecoilState(
    emptyRequiredFieldsDialogRecoilState
  );

  const handleCloseEmptyRequiredFieldsDialog = () => {
    setEmptyRequiredFieldsDialogState({ open: false, emptyRequiredFields: [] });

    if (editor != null) {
      moveSelectionToFirstEmptyRequiredField(content, editor);
    }
  };

  return (
    <DialogConfirm
      {...DEFAULT_FIELDS_WARNING_MODAL_PROPS}
      open={emptyRequiredFieldsDialogState.open}
      slug="required-fields-enforcement-dialog"
      onConfirm={handleCloseEmptyRequiredFieldsDialog}
      confirmText={
        emptyRequiredFieldsDialogState.emptyRequiredFields.length === 1
          ? 'Go to empty required field'
          : 'Go to first empty required field'
      }
      confirmButtonVariant="secondary"
    >
      <EmptyRequiredFieldsDialogContent />
    </DialogConfirm>
  );
};

import { Path } from 'domains/reporter/RichTextEditor/core';
import { PLACEHOLDER_PLUGIN_ID } from './types';
import type {
  PlaceholderPluginElement,
  PlaceholderFieldItem,
  PlaceholderPluginMenuClick,
} from './types';
import {
  PLACEHOLDER_DELIMITER_RIGHT,
  PLACEHOLDER_FIELDS_DICT,
  PLACEHOLDER_FIELDS_TO_APPLY_TO_LINK_CASES,
} from './constants';
import type { WorklistItem, GetWorklistItemQuery } from 'generated/graphql';
import type { SlateContent } from '../../../Reporter/types';
import { Editor, Node, Transforms, ReactEditor, Range, Point } from '../../core';
import { walkSlateContent } from '../../utils';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import type { GroupedWorklistItem, GroupedWorklistItems } from 'hooks/useCurrentWorklistItems';
import type { PlaceholderFieldToApplyToLinkCases } from './constants';
import { uniqBy } from 'ramda';
import { logger } from 'modules/logger';
import { selectAndFocusEditor } from '../../utils/focusEditor';
import { PlaceholderElement } from '../../slate-custom-types';

export const isPlaceholderNode = (n: Node): boolean => n.type === PLACEHOLDER_PLUGIN_ID;

export const resolvePlaceholderID = (
  placeholderID: string,
  worklistItem:
    | WorklistItem
    | NonNullable<GetWorklistItemQuery['worklistItem']>
    | GroupedWorklistItem
): string => {
  /**
   * Given a placeholderID and a worklistItem return the placeholder's value or an appropriate missing message.
   * First check to see if its a known internal placeholder and if its not, check the worklist item's customMergeFields.
   */
  if (placeholderID in PLACEHOLDER_FIELDS_DICT) {
    return resolvePlaceholderField(PLACEHOLDER_FIELDS_DICT[placeholderID], worklistItem);
  } else {
    const mergeField =
      worklistItem?.customMergeFields &&
      worklistItem.customMergeFields.find(({ key }) => key === placeholderID);
    if (mergeField?.value) {
      return mergeField.value;
    } else {
      return '';
    }
  }
};

export const getPlaceholderName = (placeholderID: string): string => {
  return placeholderID in PLACEHOLDER_FIELDS_DICT
    ? PLACEHOLDER_FIELDS_DICT[placeholderID].name
    : placeholderID;
};

const isEmptyPlaceholderNode = (node: Node): boolean =>
  isPlaceholderNode(node) && Node.string(node) === '';

export const resolvePlaceholderField = (
  placeHolderField: PlaceholderFieldItem,
  worklistItem:
    | WorklistItem
    | null
    | undefined
    | NonNullable<GetWorklistItemQuery['worklistItem']>
    | null
    | undefined
    | GroupedWorklistItem
): string => {
  const value = placeHolderField.resolve(worklistItem);
  return value != null && value !== '' ? value : '';
};

export const getEmptyPlaceholderFields = (content: SlateContent): PlaceholderPluginElement[] => {
  const emptyPlaceholders: PlaceholderPluginElement[] = [];

  const onElement = (elementNode: Node) => {
    if (isEmptyPlaceholderNode(elementNode)) {
      emptyPlaceholders.push(elementNode);
    }
  };

  walkSlateContent(onElement)(content);

  return emptyPlaceholders;
};

export const moveSelectionToFirstEmptyPlaceholder = (
  content: SlateContent,
  editor: Editor
): void => {
  for (const [node, path] of Array.from(Node.elements(editor, { reverse: false }))) {
    if (isEmptyPlaceholderNode(node)) {
      const targetPath = [...path, 0];

      try {
        const target = ReactEditor.toDOMNode(editor, node);
        scrollIntoViewIfNeeded(target, {
          behavior: 'smooth',
          scrollMode: 'if-needed',
          block: 'end',
        });
        selectAndFocusEditor(editor, targetPath);
      } catch (e: any) {
        // no-op, this can happen if we run Slate headlessly since there will be no
        // associated DOM
      }

      return;
    }
  }
};

/**
 * Returns the unique worklist items for the given placeholder field.
 * NOTE: It will ignore "" and null when parsing unique values, so any
 * worklist items which have "" or null for the placeholder field will be
 * included in the result.
 */
export const getUniqueWorklistItems = (
  currentWorklistItems: GroupedWorklistItems,
  placeholderField: PlaceholderFieldToApplyToLinkCases
): GroupedWorklistItems => {
  let uniqueWorklistItems: GroupedWorklistItems = [];

  // NOTE: We use the Symbol() method to generate a unique value for the items that have a value of "" for the given placeholderField.
  if (placeholderField === 'laterality') {
    uniqueWorklistItems =
      uniqBy((item) => {
        const laterality = item['studies'][0]['laterality'];
        return laterality === '' || laterality == null ? Symbol() : laterality;
      }, currentWorklistItems) ?? [];
  } else {
    uniqueWorklistItems =
      uniqBy((item) => {
        const value = item[placeholderField];
        return value === '' || value == null ? Symbol() : value;
      }, currentWorklistItems) ?? [];
  }

  return uniqueWorklistItems;
};

/**
 * Returns the first duplicate worklist item in currentWorklistItems of
 * the removedWorklistItem and the given placeholder field.
 * NOTE: It will ignore "" and null when parsing duplicate values, so any
 * worklist items which have "" or null for the placeholder field will be
 * excluded in the result.
 */
export const getDuplicateWorklistItem = (
  removedWorklistItem: GroupedWorklistItem,
  currentWorklistItems: GroupedWorklistItems,
  placeholderField: PlaceholderFieldToApplyToLinkCases
): GroupedWorklistItem | null | undefined =>
  currentWorklistItems.find((item) => {
    if (item.smid === removedWorklistItem.smid) return false;
    let isDuplicate;
    if (placeholderField === 'laterality' && item.studies[0]?.laterality != null) {
      isDuplicate =
        item.studies[0].laterality === removedWorklistItem.studies[0].laterality &&
        item.studies[0].laterality != null &&
        item.studies[0].laterality !== '';
    }
    if (placeholderField === 'studyDescription' || placeholderField === 'studyReason') {
      isDuplicate =
        item[placeholderField] === removedWorklistItem[placeholderField] &&
        item[placeholderField] != null &&
        item[placeholderField] !== '';
    }
    return isDuplicate;
  });

/**
 * This function is used to insert placeholders nodes into the editor, and place the selection behind
 * the last inserted node. Important to note that it will remove nodes at the provided path, so you
 * will need to include the current node in that position in nodesToInsert, if you do not wish
 * to remove it
 */
export const handleInsertPlaceholders = (
  editor: Editor,
  path: Path,
  nodesToInsert: Array<Node>
) => {
  // Insert the hydrated placeholder nodes at the same location as unhydrated placeholder node.
  Transforms.removeNodes(editor, { at: path });
  Transforms.insertNodes(editor, nodesToInsert, { at: path });
  // move selection to end of inserted placeholder
  Transforms.select(editor, path);
  Transforms.collapse(editor, { edge: 'end' });
  Transforms.move(editor, { unit: 'offset', distance: PLACEHOLDER_DELIMITER_RIGHT.length });
};

/**
 * Finds placeholders which do not have an associated worklist item, and provides them with the value
 * from that worklist item. e.g. "<Age>" is replaced with "<56>".
 *
 * Some placeholders will be replaced with additional nodes, if there are multiple current worklist items.
 * e.g. "<StudyDescription>" is replaced with "<XR KNEE>, <MRI Left Knee>".
 */
export const hydratePlaceholders = (editor: Editor, currentWorklistItems: GroupedWorklistItems) => {
  if (currentWorklistItems.length === 0) {
    logger.warn('[hydratePlaceholders]: No current worklist items found. This should not happen.');
  }

  Editor.withoutNormalizing(editor, () => {
    // Find the placeholders, which currently have no workListItemSmid and a default value
    const placeholders = Editor.nodes(editor, {
      at: [],
      match: (n: Node) => isPlaceholderNode(n) && n.workListItemSmid == null,
    });

    // For each placeholder node, hydrate it with two considerations: what the placeholderID is, and
    // whether there are multiple current worklist items.
    for (const [node, path] of Array.from(placeholders)) {
      const { placeholderID } = node as PlaceholderElement;
      const nodesToInsert: Array<Node> = [];

      // Some placeholders should have a node for each unique value in the current worklist items.
      // And any placeholders should be separated by commas.
      if (
        Object.keys(PLACEHOLDER_FIELDS_TO_APPLY_TO_LINK_CASES).includes(placeholderID) &&
        currentWorklistItems.length > 1
      ) {
        const uniqueWorklistItems = getUniqueWorklistItems(
          currentWorklistItems,
          PLACEHOLDER_FIELDS_TO_APPLY_TO_LINK_CASES[placeholderID]
        );

        uniqueWorklistItems.forEach((worklistItem, idx) => {
          nodesToInsert.push({
            type: PLACEHOLDER_PLUGIN_ID,
            placeholderID,
            workListItemSmid: worklistItem.smid,
            children: [{ text: resolvePlaceholderID(placeholderID, worklistItem) }],
          });
          if (idx !== uniqueWorklistItems.length - 1) {
            nodesToInsert.push({ text: ', ' });
          }
        });
      } else {
        // Otherwise, we simply hydrate the placeholder with the first worklist item's value.
        nodesToInsert.push({
          type: PLACEHOLDER_PLUGIN_ID,
          placeholderID,
          workListItemSmid: currentWorklistItems[0]?.smid,
          children: [{ text: resolvePlaceholderID(placeholderID, currentWorklistItems[0]) }],
        });
      }

      handleInsertPlaceholders(editor, path, nodesToInsert);
    }
  });
};

export const handlePlaceholderItemClick = ({
  editor,
  pluginID,
  placeholderField,
  instance,
  currentWorklistItems,
}: PlaceholderPluginMenuClick) => {
  if (editor.selection == null) {
    // noop if we don't have a selection object (i.e. editor is not focused)
    return;
  }
  const { anchor, focus } = editor.selection;
  const currentAnchor = Editor.node(editor, anchor);
  const currentFocus = Editor.node(editor, focus);
  const isSelectionReversed = Range.isBackward(editor.selection);
  const nodeToInsert = {
    type: pluginID,
    placeholderID: placeholderField.key,
    children: [{ text: placeholderField.name }],
  } as const;

  if (currentAnchor) {
    const parentNode = Editor.parent(editor, currentAnchor[1]);
    if (parentNode && isPlaceholderNode(parentNode[0])) {
      const newPoint = isSelectionReversed
        ? Editor.after(editor, parentNode[1])
        : Editor.before(editor, parentNode[1]);
      if (Point.isPoint(newPoint)) {
        Transforms.setPoint(editor, { ...newPoint }, { edge: 'anchor' });
      }
    }
  }

  if (currentFocus) {
    const parentNode = Editor.parent(editor, currentFocus[1]);
    if (parentNode && isPlaceholderNode(parentNode[0])) {
      const newPoint = isSelectionReversed
        ? Editor.before(editor, parentNode[1])
        : Editor.after(editor, parentNode[1]);
      if (Point.isPoint(newPoint)) {
        Transforms.setPoint(editor, { ...newPoint }, { edge: 'focus' });
      }
    }
  }

  Editor.deleteFragment(editor);
  Transforms.insertNodes(editor, nodeToInsert);

  hydratePlaceholders(editor, currentWorklistItems);

  instance.hide();
};

import { logger } from 'modules/logger';

import { ASR_PLEX_WS_URL, ASR_PLEX_VAD_WS_URL } from 'config';

class ASRPlexWebSocketService {
  // eslint-disable-next-line no-use-before-define
  static instance: ASRPlexWebSocketService | null = null;
  static connection: WebSocket | null = null;

  // eslint-disable-next-line no-use-before-define
  static getInstance(): ASRPlexWebSocketService {
    if (!ASRPlexWebSocketService.instance) {
      ASRPlexWebSocketService.instance = new ASRPlexWebSocketService();
    }
    return ASRPlexWebSocketService.instance;
  }

  connect(): WebSocket {
    if (ASRPlexWebSocketService.connection == null) {
      ASRPlexWebSocketService.connection = new WebSocket(ASR_PLEX_WS_URL);
      ASRPlexWebSocketService.connection.onopen = () => {
        logger.info('[ASRPlexWebSocketService] connection opened');
      };
      ASRPlexWebSocketService.connection.onerror = (err: any) => {
        logger.error('[ASRPlexWebSocketService] connection error', err);
      };
      ASRPlexWebSocketService.connection.onclose = () => {
        logger.info('[ASRPlexWebSocketService] connection closed');
        ASRPlexWebSocketService.connection = null;
      };
    }
    return ASRPlexWebSocketService.connection;
  }
}

export const asrPlexWebSocketService: ASRPlexWebSocketService =
  ASRPlexWebSocketService.getInstance();

class ASRPlexVADWebSocketService {
  // eslint-disable-next-line no-use-before-define
  static instance: ASRPlexVADWebSocketService | null = null;
  static connection: WebSocket | null = null;

  // eslint-disable-next-line no-use-before-define
  static getInstance(): ASRPlexVADWebSocketService {
    if (!ASRPlexVADWebSocketService.instance) {
      ASRPlexVADWebSocketService.instance = new ASRPlexVADWebSocketService();
    }
    return ASRPlexVADWebSocketService.instance;
  }

  connect(): WebSocket {
    if (ASRPlexVADWebSocketService.connection == null) {
      ASRPlexVADWebSocketService.connection = new WebSocket(ASR_PLEX_VAD_WS_URL);
      ASRPlexVADWebSocketService.connection.onopen = () => {
        logger.info('[ASRPlexVADWebSocketService] connection opened');
      };
      ASRPlexVADWebSocketService.connection.onerror = (err: any) => {
        logger.error('[ASRPlexVADWebSocketService] connection error', err);
      };
      ASRPlexVADWebSocketService.connection.onclose = () => {
        logger.info('[ASRPlexVADWebSocketService] connection closed');
        ASRPlexVADWebSocketService.connection = null;
      };
    }
    return ASRPlexVADWebSocketService.connection;
  }
}

export const asrPlexVADWebSocketService: ASRPlexVADWebSocketService =
  ASRPlexVADWebSocketService.getInstance();

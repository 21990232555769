import { NotEditable } from '../../../components';
import styled from 'styled-components';
import { useSlate, Editor, ReactEditor, Range, Node, Text } from '../../../core';
import type { RenderLeafProps } from '../../../core';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import type { DictationPluginElementOrDecorate } from '../types';
import { useEffect } from 'react';
import { equals } from 'ramda';
import { logger } from 'modules/logger';
import { partialEditor } from '../../../utils';
import { Element } from 'slate';

// There is some minor trickery going on in this file. The editor's selection (and the object
// reference of that selection) is sacred to us because it's our one way of inferring the intent
// from the user at the moment. That means we protect it at all costs and only apply operations
// to the editor that alter it when asked by the user or they have given us confirmation of their
// intent. In the same realm, we also guard the editor state at all costs because it's what is
// saved to the server and symbolizes the end of their journey for a given case. It's the only
// state of record of what their intent and findings are. WE DO NOT WILLY NILLY AUGMENT IT!
//
// That raises the case of progressive rendering where we want to show them the hypothesis text
// as they dictate, but we know it's not right nor final. We're faced with a quandary: show them the
// hypothesis text by altering the editor's state or providing a subpar UX. By writing hypothesis text
// to the editor state we ruin sanctity of editor state and alter the selection in the process. Big no no!
//
// With this approach, we get both! We create a decorator at the selection where they dictate and
// insert the hypothesis text there. Decorators act like marks except they do not touch the editor's state.
// Since we're not updating the editor's state, we have to sprinkle in a bit of trickery to make things
// happy happy within a content editable. We render the actual editor state (most likely the contents of a
// bracket) within a span and give it a display of none. This still keeps the editor state in the DOM, but
// does not change the user's selection while it's visibly hidden. Next, we visibly show hypothesis text
// and make it not editable so that it's not on Slate's radar as something that can be selected and avoids
// nasty cannot resolve Slate errors.

const HypothesisText = styled.span`
  opacity: 0.5;
  pointer-events: auto;
`;

const DecorateEditableContent = styled.span`
  display: none;
`;

export const DecorateDictationEditable = ({
  children,
  leaf,
  attributes,
}: RenderLeafProps<DictationPluginElementOrDecorate>): React.ReactElement | null => {
  const editor = useSlate();

  useEffect(() => {
    if (editor == null || editor.selection == null) {
      return;
    }

    try {
      const [targetNode] = Editor.node(editor, editor.selection);
      const target = ReactEditor.toDOMNode(editor, targetNode);

      scrollIntoViewIfNeeded(target, {
        behavior: 'auto',
        scrollMode: 'if-needed',
        block: 'end',
      });
    } catch (e: any) {
      // no-op, this can happen if we run Slate headlessly since there will be no
      // associated DOM
    }
  }, [editor.selection, editor, leaf.hypothesisText]);

  // TODO: The logic below governs whether this component should render hypothesis text as a child.
  // It's quite complex. It could be entirely removed if the user's expanded selection was deleted at the start of dictation.
  // The reason we can't do that currently has to do with how useNvoqQueue is setup. Once that logic has been migrated to the backend
  // this can be cleaned up.
  let shouldDisplayHypothesisText = leaf?.hypothesisText !== '';

  const isCollapsed = Range.isCollapsed(editor.selection);

  if (!isCollapsed && editor.selection) {
    // If selection is expanded, we need to do a bit more work to figure out which node to display the hypothesis text in.
    // That'll be either the first non-empty text node in the selection, or the child of the first inline bookmark node.
    const selectedNodes = Array.from(
      Editor.nodes(editor, { at: editor.selection, match: (n: Node) => !Editor.isEditor(n) })
    );
    let leafToDisplayHypothesisText;
    for (const [node, path] of selectedNodes) {
      // @ts-expect-error [EN-7967] - TS2339 - Property 'text' does not exist on type 'never'.
      if (Text.isText(node) && node.text !== '') {
        leafToDisplayHypothesisText = [node, path];
        break;
        // @ts-expect-error [EN-7967] - TS2339 - Property 'type' does not exist on type 'never'.
      } else if (Element.isElement(node) && node.type === 'inlineBookmark') {
        // @ts-expect-error [EN-7967] - TS2339 - Property 'children' does not exist on type 'never'.
        leafToDisplayHypothesisText = [node.children[0], [...path, 0]];
        break;
      }
    }
    if (leafToDisplayHypothesisText == null) {
      // This should never happen, but if it does, we don't want to display hypothesis text.
      const hypothesisErrorMessage =
        '[dictation] Could not find a valid node to display hypothesis text in.';
      logger.error(hypothesisErrorMessage, {
        selectedNodes: JSON.stringify(selectedNodes),
        editor: partialEditor(editor),
        shouldDisplayHypothesisText,
      });
      return null;
    }

    // @ts-expect-error [EN-7967] - TS2339 - Property 'props' does not exist on type 'ReactNode'.
    const currentParentNode = children.props?.parent;
    const leafToDisplayHypothesisTextParent = Node.parent(editor, leafToDisplayHypothesisText[1]);

    // If the leaf which should display hypothesis text is a text node under a paragraph, then we want to display
    // the hypothesis text only if the current leaf text's matches the target leaf's text.
    // Otherwise, the leaf which should display the hypothesis text is inside of an inline bookmark, and we want to display
    // the hypothesis text only if the current leaf's parent matches the target leaf's parent.
    // Otherwise, display whatever was specified in the other if/else if blocks.
    // You'll notice that this isn't foolproof. If the leaf to display is a text node, and there is some other leaf in the selection with identical text
    // then they will both display hypothesis text. We can accept this edge case for now.
    if (
      (currentParentNode.type === 'paragraph' &&
        !leafToDisplayHypothesisText[0].text.includes(leaf.text)) ||
      (currentParentNode != null && !equals(leafToDisplayHypothesisTextParent, currentParentNode))
    ) {
      shouldDisplayHypothesisText = false;
    }
  }

  return (
    <span {...attributes}>
      {leaf?.hypothesisText === '' ? (
        children
      ) : (
        <DecorateEditableContent>{children}</DecorateEditableContent>
      )}
      {shouldDisplayHypothesisText ? (
        <NotEditable>
          <HypothesisText data-testid="hypothesis-text">{' ... '}</HypothesisText>
        </NotEditable>
      ) : null}
    </span>
  );
};

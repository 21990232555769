import type { EditorElement, EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';
import type { ComponentType } from 'react';

export const ITALIC_PLUGIN_ID: 'italic' = 'italic';
export type ItalicPluginID = typeof ITALIC_PLUGIN_ID;

export type ItalicPluginElement = EditorElement;

export type ItalicPluginOptions = Readonly<
  EditorPluginOptions & {
    // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
    component?: ComponentType<any>;
  }
>;

export type ItalicPluginPropertyOptions = Readonly<
  EditorPluginPropertyOptions &
    ItalicPluginOptions & {
      pluginID: ItalicPluginID;
      hotkey: string;
      // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
      component: ComponentType<any>;
      pluginName: string;
      // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
      icon: ComponentType<any>;
    }
>;

import { atom } from 'recoil';
import type { RecoilState } from 'recoil';

export type AIModeReportState = 'mapping' | 'generating' | 'editing';

// @ts-expect-error [EN-7967] - TS2322 - Type 'RecoilState<{ report_state: "mapping"; resolve: () => void; }>' is not assignable to type 'RecoilState<{ report_state: AIModeReportState; resolve: () => Promise<undefined>; }>'.
export const findingsMapperState: RecoilState<{
  report_state: AIModeReportState;
  resolve: () => undefined | Promise<undefined>;
}> = atom({
  key: 'findingsMapperState',
  default: {
    report_state: 'mapping',
    resolve: () => {},
  },
});

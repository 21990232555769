import { PICKLIST_PLUGIN_ID } from './plugins/picklist/types';
import { INLINE_BOOKMARK_PLUGIN_ID } from './plugins/inlineBookmark/types';
import { PLACEHOLDER_PLUGIN_ID } from './plugins/placeholder/types';
import {
  CustomElement,
  InlineBookmarkElement,
  PicklistPluginElement,
  RequiredFieldsPluginElement,
} from './slate-custom-types';
import { REQUIRED_FIELDS_PLUGIN_ID } from './plugins';

export const NEWLINE = '\n';
export const BRACKET_TYPES: Array<Partial<CustomElement['type']>> = [
  PICKLIST_PLUGIN_ID,
  INLINE_BOOKMARK_PLUGIN_ID,
  PLACEHOLDER_PLUGIN_ID,
];
export const SQUARE_BRACKET_TYPES: Array<CustomElement['type']> = [
  PICKLIST_PLUGIN_ID,
  INLINE_BOOKMARK_PLUGIN_ID,
  REQUIRED_FIELDS_PLUGIN_ID,
];

export const getNavigationNodeTypes = (
  ignoreMergeFieldsInNavigation?: boolean
): Array<Partial<CustomElement['type']>> => {
  return ignoreMergeFieldsInNavigation === true ? SQUARE_BRACKET_TYPES : BRACKET_TYPES;
};

export const isBracketType = (type: CustomElement['type']): boolean => BRACKET_TYPES.includes(type);

export type SquareBracketType = PicklistPluginElement | InlineBookmarkElement;

export const isSquareBracketType = (
  type?: CustomElement['type'] | SquareBracketType['type']
): type is SquareBracketType['type'] => type != null && SQUARE_BRACKET_TYPES.includes(type);

export const isSquareBracket = (
  node: CustomElement | SquareBracketType
): node is SquareBracketType => isSquareBracketType(node.type);

export const isRequiredFieldNode = (
  node: CustomElement | RequiredFieldsPluginElement
): node is RequiredFieldsPluginElement => {
  return node.type === REQUIRED_FIELDS_PLUGIN_ID;
};

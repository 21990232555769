import { useCallback } from 'react';
import { useMatch, useParams, generatePath } from 'react-router-dom';
import { useViewerId } from 'hooks/useViewerId';

import { AppRoutePath } from 'config/constants';
import { useReporterSubPath } from './useReporterSubPath';
import { pick } from 'ramda';
import { getCaseStudyParamsSuffix } from './studyParams';

type UrlBuilderArgs = {
  isViewer?: boolean | null | undefined;
  isReporter?: boolean | null | undefined;
  isDockedJacket?: boolean | null | undefined;
  isUndockedJacket?: boolean | null | undefined;
  isURT?: boolean | null | undefined;
};

export const useUrlBuilder = ({
  isViewer: forceViewer = null,
  isReporter: forceReporter = null,
  isDockedJacket: forceDockedJacket = null,
  isUndockedJacket: forceUndockedJacket = null,
  isURT: forceURT = null,
}: UrlBuilderArgs = {}): ((urlProps: {
  caseSmid?: string | null | undefined;
  studySmids?: ReadonlyArray<string>;
  autoloadedStudySmids?: ReadonlyArray<string>;
  viewerId?: string;
}) => string | null | undefined) => {
  const params = useParams();
  const matchViewer = useMatch(AppRoutePath.VIEWER) != null;
  const matchReporter = useMatch(AppRoutePath.REPORTER) != null;
  const matchDockedJacket = useMatch(AppRoutePath.PATIENT_JACKET) != null;
  const matchUndockedJacket = useMatch(AppRoutePath.PATIENT_JACKET_UNDOCKED) != null;
  const matchURT = useMatch(AppRoutePath.URT) != null;

  const forceUrl =
    forceViewer || forceReporter || forceDockedJacket || forceUndockedJacket || forceURT;
  const isViewer = forceUrl != null ? forceViewer : matchViewer;
  const isReporter = forceUrl != null ? forceReporter : matchReporter;
  const isDockedJacket = forceUrl != null ? forceDockedJacket : matchDockedJacket;
  const isUndockedJacket = forceUrl != null ? forceUndockedJacket : matchUndockedJacket;
  const isURT = forceUrl != null ? forceURT : matchURT;

  const reporterSubPath = useReporterSubPath();

  const viewerIdFromUrl = useViewerId();

  const buildUrl = useCallback(
    ({ caseSmid, studySmids = [], autoloadedStudySmids = [], viewerId = viewerIdFromUrl }) => {
      if (caseSmid == null) {
        return null;
      }

      // add query params for any requested study smids that weren't autoloaded
      const priorStudies = studySmids
        .filter((priorSmid) => !autoloadedStudySmids.includes(priorSmid))
        .join(',');

      // add query params for autoload prior smids to skip (not included in the new list of studies)
      const skippedAutoloadedPriorStudies = autoloadedStudySmids
        .filter((autoloadedSmid) => !studySmids.includes(autoloadedSmid))
        .join(',');

      const priorsSuffix = getCaseStudyParamsSuffix(priorStudies, skippedAutoloadedPriorStudies);

      if (isViewer === true) {
        return `${generatePath(AppRoutePath.VIEWER, {
          worklistId: caseSmid,
          windowId: viewerId ?? '0',
        })}${priorsSuffix}`;
      } else if (reporterSubPath != null) {
        return `${generatePath(reporterSubPath, {
          ...pick(['templateId', 'macroId'], params),
          worklistId: caseSmid,
        })}${priorsSuffix}`;
      } else if (isReporter === true) {
        return `${generatePath(AppRoutePath.REPORTER, { worklistId: caseSmid })}${priorsSuffix}`;
      } else if (isDockedJacket === true) {
        /**
         * Note that Patient Jacket only syncs if it is already on the same case,
         * (this function will not be called when the jacket is on a different case)
         * So it is safe to build prior parameters for the case
         * because we have the full study info already.
         */
        return `${generatePath(AppRoutePath.PATIENT_JACKET, { smid: caseSmid })}${priorsSuffix}`;
      } else if (isUndockedJacket === true) {
        return `${generatePath(AppRoutePath.PATIENT_JACKET_UNDOCKED, { smid: caseSmid })}${priorsSuffix}`;
      } else if (isURT === true) {
        return `${generatePath(AppRoutePath.URT, { worklistId: caseSmid })}${priorsSuffix}`;
      }
    },
    [
      viewerIdFromUrl,
      isViewer,
      reporterSubPath,
      isReporter,
      isDockedJacket,
      isUndockedJacket,
      isURT,
      params,
    ]
  );

  return buildUrl;
};

import { useEffect, useMemo } from 'react';
import type {
  PicklistPluginElement,
  PicklistPluginPropertyOptions,
  ReportPicklistBeforeSubmissionOptions,
} from '../types';

import { PicklistBuilder } from './PicklistBuilder';
import type { GetRenderElementComponentType } from '../../../utils/getRenderElement';
import { unreachableCaseError } from 'types';
import { useSelected, useEditor } from '../../../core';

import { useReporterState } from '../../../hooks';

import { PicklistSelector } from './PicklistSelector';
import { PicklistElement } from './PicklistElement';
import { usePicklistState } from '../../../../Template/usePicklist';
import styled from 'styled-components';
import { isNodeTypeIn } from '../../../utils';
import { equals } from 'ramda';
import { PICKLIST_PLUGIN_ID } from '../types';
import { Element, Editor } from 'slate';

const EMPTY_ARRAY: Array<ReportPicklistBeforeSubmissionOptions> = [];

export const PicklistEditable: GetRenderElementComponentType<
  PicklistPluginPropertyOptions,
  PicklistPluginElement
> = ({
  pluginOptions: { pluginID, showPicklistOptionsInEditor },
  element,
  ...rest
}): React.ReactElement | null => {
  const { variant } = useReporterState();
  const { picklists, setActivePicklist, activePicklist } = usePicklistState();
  const selected = useSelected();

  const editor = useEditor();

  const isSelected = useMemo(() => {
    if (Editor.isEditor(editor)) {
      const selectionNode = Editor.above(editor, {
        at: editor.selection,
        match: (n) => Element.isElement(n) && n.type === PICKLIST_PLUGIN_ID,
        mode: 'lowest',
      });

      if (selectionNode != null && !Editor.isEditor(selectionNode[0])) {
        // @ts-expect-error [EN-7967] - TS2339 - Property 'children' does not exist on type 'never'.
        return selected && equals(element.children, selectionNode[0].children);
      }
    }
    return selected;
  }, [editor, element.children, selected]);

  useEffect(() => {
    if (isSelected) {
      const activePicklist = picklists.find((p) => p.id === element.picklistID);
      setActivePicklist(activePicklist);
    } else if (!isNodeTypeIn(editor, pluginID)) {
      setActivePicklist(null);
    }

    // When a picklist is 'unmounted', either because it was deleted or a new template was applied, reset active picklist.
    return () => {
      setActivePicklist(null);
    };
  }, [
    isSelected,
    editor,
    pluginID,
    element.picklistID,
    setActivePicklist,
    picklists,
    activePicklist,
  ]);

  switch (variant) {
    case 'report':
      return (
        <PicklistSelector
          {...rest}
          isSelected={isSelected}
          pluginID={pluginID}
          showPicklistOptionsInEditor={showPicklistOptionsInEditor}
          options={activePicklist?.options ?? EMPTY_ARRAY}
          element={element}
        />
      );
    case 'fragment':
    case 'template':
      return (
        <PicklistBuilder
          {...rest}
          variant={variant}
          options={activePicklist?.options ?? []}
          element={element}
        />
      );
    case 'view':
      return null;
    default:
      unreachableCaseError(variant);
  }
};

export const PicklistRenderer = styled(PicklistElement)`
  white-space: pre-wrap;
`;

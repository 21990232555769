import similarity from 'string-similarity';
import { metaphone } from 'metaphone';
import leven from 'fast-levenshtein';

export type FindBestMatchReturn = {
  bestMatch: string;
  rating: number;
  method: 'metaphone' | 'levenshtein';
};

export function findBestMatch(s: string, candidates: string[]): FindBestMatchReturn {
  const metaphoneMap = candidates.reduce<{
    [key: string]: string;
  }>((acc, curr) => {
    acc[metaphone(curr)] = curr;
    return acc;
  }, {});
  const { bestMatch } = similarity.findBestMatch(metaphone(s), Object.keys(metaphoneMap));
  if (bestMatch.rating === 0) {
    // if the similarity algorithm fails, we fall back on edit distance
    // and look for something within edit distance of 2
    const editDistances = candidates.map((c) => leven.get(s, c));
    let minDistance = Infinity;
    let match = candidates[0];
    for (let i = 0; i < editDistances.length; i++) {
      if (editDistances[i] < minDistance) {
        minDistance = editDistances[i];
        match = candidates[i];
      }
    }
    if (minDistance <= 2) {
      // this maps edit distance to a value between 0..1. Edit distance of 1 is 0.7, Edit distance of 2 is 0.4
      const rating = Math.max(0, 1 - (minDistance / 10) * 3);
      return { bestMatch: match, rating, method: 'levenshtein' };
    }
  }
  return {
    bestMatch: metaphoneMap[bestMatch.target],
    rating: bestMatch.rating,
    method: 'metaphone',
  };
}

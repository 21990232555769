import { ChangeEvent, useEffect, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { motion, AnimatePresence } from 'framer-motion';
import Text from 'common/ui/Text';
import Input from 'common/ui/Input';
import { Button } from 'common/ui/Button';
import { IconButton, Switch } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Close from '@material-ui/icons/Close';
import { useMutation } from '@apollo/client';
import { UPDATE_ME, UPDATE_PASSWORD, TOGGLE_SCREEN_SHARE } from 'modules/Apollo/queries';
import { Stack, Spacer } from 'common/ui/Layout';
import { useCurrentUser } from 'hooks/useCurrentUser';
import type {
  UpdateMeMutation,
  UpdateMeMutationVariables,
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables,
  PreferredWorkLocationPayload,
  ToggleScreenShareMutation,
  ToggleScreenShareMutationVariables,
} from 'generated/graphql';

import { Colors } from 'styles';
import { PASSWORD_REQUIREMENT_TEXT } from 'config/constants';
import {
  SettingsCard,
  SettingsCardContent,
  SettingsCardHeader,
  SettingsAvatar,
  SettingsErrorIcon,
  SettingsLabel,
  SettingsProgressSpinner,
  SettingsSeparator,
  SettingsSuccessIcon,
} from './SettingsLayoutComponents';

enum Status {
  IDLE = 'idle',
  SUCCESS = 'success',
  SUBMITTING = 'submitting',
  ERROR = 'error',
}

type PersonalInformationTabProps = {
  error: string | null | undefined;
  onClose: () => void;
  setError: (error?: string | null | undefined) => void;
};

export const PersonalInformationTab = ({
  error,
  onClose,
  setError,
}: PersonalInformationTabProps): React.ReactElement => {
  const { data } = useCurrentUser();
  const [updateMe] = useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UPDATE_ME);
  const [toggleScreenShare] = useMutation<
    ToggleScreenShareMutation,
    ToggleScreenShareMutationVariables
  >(TOGGLE_SCREEN_SHARE);

  const [hospitalSwitch, setHospitalSwitch] = useState(false);
  const [homeSwitch, setHomeSwitch] = useState(false);
  const me = data?.me;
  const [screenShareEnabled, setScreenShare] = useState(me?.screenShareEnabled);
  const [systemSettingsError, setSystemSettingsError] = useState<string>(undefined);

  useEffect(() => {
    if (me) {
      setHospitalSwitch(me.preferredWorkLocation.hospital);
      setHomeSwitch(me.preferredWorkLocation.home);
      setScreenShare(me.screenShareEnabled);
    }
  }, [me]);

  const sendSwitchUpdate = async (payload: Partial<PreferredWorkLocationPayload>) => {
    setError(null);

    try {
      await updateMe({
        variables: {
          preferredWorkLocation: {
            home: homeSwitch,
            hospital: hospitalSwitch,
            ...payload,
          },
        },
      });
    } catch (e: any) {
      setError('An error occurred. Please try again.');
    }
  };

  const handleHospitalSwitchChange = (_: unknown, checked: boolean) => {
    setHospitalSwitch(checked);
    sendSwitchUpdate({ hospital: checked });
  };

  const handleHomeSwitchChange = (_: unknown, checked: boolean) => {
    setHomeSwitch(checked);
    sendSwitchUpdate({ home: checked });
  };

  const handleScreenShareChange = async (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    try {
      setScreenShare(checked);
      await toggleScreenShare({
        variables: {
          screenShareEnabled: checked,
        },
      });
    } catch (e: any) {
      setSystemSettingsError('An error occurred. Please try again.');
      setScreenShare(!checked);
    }
  };

  return (
    <SettingsCard>
      <SettingsCardHeader>
        <Stack alignY="center">
          <Text variant="display2">Personal Information</Text>
          <Spacer />
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </SettingsCardHeader>

      <SettingsCardContent>
        <Stack alignY="center" space="medium">
          <div>
            <SettingsAvatar name={me ? `${me.firstName} ${me.lastName}` : undefined} />
          </div>
          <div>
            <LiveField id="firstName" label="First name" />
          </div>
          <div>
            <LiveField id="lastName" label="Last name" />
          </div>
          <div>
            <LiveField id="physicianId" label="Physician ID" />
          </div>
        </Stack>

        <SettingsSeparator />

        <Text variant="display1" display="block" gutterBottom="small">
          Preferred work location
        </Text>

        <Stack alignY="center">
          <Text>Hospital / Clinic</Text>
          <Spacer />
          <Switch checked={hospitalSwitch} onChange={handleHospitalSwitchChange} />
        </Stack>
        <Stack alignY="center">
          <Text>Home</Text>
          <Spacer />
          <Switch checked={homeSwitch} onChange={handleHomeSwitchChange} />
        </Stack>
        {error != null && <Text color="error">{error}</Text>}

        <SettingsSeparator />

        <PasswordContainer requireTotp={me?.securitySettings?.multiFactorAuth?.linked ?? false} />

        <SettingsSeparator />

        <Text variant="display1" display="block" gutterBottom="small">
          System Settings
        </Text>
        <Stack alignY="center">
          <Text>Enable Screen Sharing</Text>
          <Spacer />
          <Switch checked={screenShareEnabled} onChange={handleScreenShareChange} />
        </Stack>
        {systemSettingsError && <Text color="error">{systemSettingsError}</Text>}

        <SettingsSeparator />

        <Stack vertical space="medium">
          <Stack alignY="center">
            <Button
              id="reset-default-config"
              variant="primary"
              onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}
              type="submit"
            >
              Reset default configuration and refresh window
            </Button>
          </Stack>
        </Stack>
      </SettingsCardContent>
    </SettingsCard>
  );
};

function LiveField({ id, label }: { id: string; label: string }) {
  const { data } = useCurrentUser();
  const me = data?.me;
  const [status, setStatus] = useState<Status>(Status.IDLE);
  const [value, setValue] = useState('');
  const [update] = useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UPDATE_ME);

  useEffect(() => {
    if (me) {
      setValue(me[id] || '');
    }
  }, [me, id]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleSubmit = async () => {
    if (status !== Status.IDLE) {
      return;
    }

    setStatus(Status.SUBMITTING);

    try {
      await update({
        variables: {
          [String(id)]: value,
        },
      });

      setStatus((status) => {
        if (status === Status.SUBMITTING) {
          return Status.SUCCESS;
        }
        return status;
      });
    } catch (e: any) {
      setStatus(Status.ERROR);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <>
      <SettingsLabel htmlFor={id}>{label}</SettingsLabel>
      <div
        css={`
          position: relative;
        `}
      >
        <Input
          id={id}
          value={value}
          onChange={handleChange}
          onFocus={() => setStatus(Status.IDLE)}
          onBlur={handleSubmit}
          onKeyDown={handleKeyDown}
        />
        <FieldStatus status={status} />
      </div>
    </>
  );
}

type FormValues = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  totp: string;
  requireTotp: boolean;
};

function PasswordContainer({ requireTotp }: { requireTotp: boolean }) {
  const [status, setStatus] = useState(Status.IDLE);
  const [message, setMessage] = useState(null);
  const [updatePassword] = useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(
    UPDATE_PASSWORD
  );

  const handleSubmit = async ({
    newPassword,
    currentPassword,
    totp,
  }: {
    newPassword: string;
    currentPassword: string;
    totp?: string;
  }) => {
    setMessage(null);
    setStatus(Status.SUBMITTING);

    try {
      const response = await updatePassword({
        variables: {
          newPassword: newPassword,
          oldPassword: currentPassword,
          totp,
        },
      });
      const confirmed = response?.data?.updatePassword?.confirmed ?? false;

      if (confirmed) {
        setMessage('Successfully updated password.');
        setStatus(Status.IDLE);
      }
    } catch (error: any) {
      // TODO(jnelson): once the back-end returns clean messages we should use them.
      const status = error?.graphQLErrors?.[0]?.extensions.code;

      if (status === 400) {
        setMessage(
          'Error: either the current password is incorrect or the new password does not meet the specified requirements.'
        );
      } else {
        setMessage('An error occurred. Please try again.');
      }

      setStatus(Status.ERROR);
    }
  };
  const initialValues: FormValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    totp: '',
    requireTotp,
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validate={validateValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
    >
      {({ submitForm, touched, errors, dirty }) => {
        const isDisabled = Object.keys(errors).length !== 0 || !dirty;

        return (
          // @ts-expect-error [EN-7967] - TS2739 - Type '{ children: Element[]; css: string; }' is missing the following properties from type 'Pick<DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>, "name" | "action" | ... 260 more ... | "onPointerLeaveCapture">': placeholder, onPointerEnterCapture, onPointerLeaveCapture
          <Form
            css={`
              margin-bottom: 2.5rem;
              display: flex;
              flex-direction: column;
            `}
          >
            <Text variant="display1" display="block" gutterBottom="large">
              Change password
            </Text>

            <Stack vertical space="medium" style={{ width: '31.6rem' }}>
              <div>
                <SettingsLabel htmlFor="currentPassword">Current password</SettingsLabel>
                {/* @ts-expect-error [EN-7967] - TS2769 - No overload matches this call. */}
                <Input
                  as={PasswordField}
                  type="password"
                  id="currentPassword"
                  name="currentPassword"
                  helperText={touched.currentPassword ? errors.currentPassword : null}
                />
              </div>
              <div>
                <SettingsLabel htmlFor="newPassword">New password</SettingsLabel>
                {/* @ts-expect-error [EN-7967] - TS2769 - No overload matches this call. */}
                <Input
                  as={PasswordField}
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  helperText={touched.newPassword ? errors.newPassword : null}
                />
              </div>
              <div>
                <SettingsLabel htmlFor="confirmNewPassword">Confirm new password</SettingsLabel>
                {/* @ts-expect-error [EN-7967] - TS2769 - No overload matches this call. */}
                <Input
                  as={PasswordField}
                  type="password"
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  helperText={touched.confirmNewPassword ? errors.confirmNewPassword : null}
                />
              </div>
              {requireTotp && (
                <div>
                  <SettingsLabel htmlFor="totp">Authentication Code</SettingsLabel>
                  {/* @ts-expect-error [EN-7967] - TS2769 - No overload matches this call. */}
                  <Input
                    as={PasswordField}
                    autoComplete="one-time-code"
                    id="totp"
                    type="text"
                    name="totp"
                    helperText={touched.totp ? errors.totp : null}
                  />
                </div>
              )}
            </Stack>

            <Text style={{ marginTop: '16px', marginBottom: '16px' }}>
              {PASSWORD_REQUIREMENT_TEXT}
            </Text>

            <Stack vertical space="medium" style={{ width: '31.6rem' }}>
              {status === Status.SUBMITTING ? (
                <CircularProgress
                  size="3rem"
                  css={`
                    margin: 0 auto 0;
                  `}
                />
              ) : (
                <Button id="change-password" type="submit" disabled={isDisabled}>
                  Change password
                </Button>
              )}
            </Stack>
            {message != null && (
              <Text
                color={status === Status.ERROR ? 'error' : 'success'}
                variant="body1"
                css={`
                  margin-left: 15px;
                `}
              >
                {message}
              </Text>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

function PasswordField({
  helperText,
  ...props
}: {
  helperText?: string;
  name: string;
  type?: string;
}) {
  return (
    <>
      <Field
        {...props}
        css={`
          border-color: ${helperText != null ? Colors.red5 : ''} !important;
          width: 100%;
        `}
      />
      {helperText != null ? (
        <Text
          variant="caption"
          color="error"
          css={`
            margin-left: 15px;
          `}
        >
          {helperText}
        </Text>
      ) : null}
    </>
  );
}

export const validateValues = (values: {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  totp: string;
  requireTotp: boolean;
}): Partial<{
  newPassword: string;
  confirmNewPassword: string;
  currentPassword: string;
  totp: string;
}> => {
  const errors: Partial<{
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    totp: string;
  }> = {};

  if (!values.currentPassword) {
    errors.currentPassword = 'Required';
  }

  if (!values.newPassword) {
    errors.newPassword = 'Required';
  }

  if (!values.confirmNewPassword) {
    errors.confirmNewPassword = 'Required';
  }

  if (values.requireTotp) {
    if (!values.totp) {
      errors.totp = 'Required';
    }
  }

  if (values.newPassword && values.newPassword.length < 8) {
    errors.newPassword = 'Password must be at least 8 characters long.';
  }

  if (values.confirmNewPassword && values.confirmNewPassword !== values.newPassword) {
    errors.newPassword = 'Password and confirm password does not match';
    errors.confirmNewPassword = 'Password and confirm password does not match';
  }

  return errors;
};

function FieldStatus({ status }: { status: string }) {
  if (status === Status.IDLE) {
    return null;
  }

  return (
    <div
      css={`
        display: flex;
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
      `}
    >
      {status === Status.SUBMITTING && <SettingsProgressSpinner size="2rem" />}
      {/* @ts-expect-error [EN-7967] - TS2322 - Type '{ children: Element[]; exitBeforeEnter: true; }' is not assignable to type 'IntrinsicAttributes & AnimatePresenceProps'. */}
      <AnimatePresence exitBeforeEnter>
        {status === Status.SUCCESS && (
          <motion.div
            key="success"
            initial={{ opacity: 0.5, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            css={`
              display: flex;
            `}
          >
            <SettingsSuccessIcon />
          </motion.div>
        )}
        {status === Status.ERROR && (
          <motion.div
            key="error"
            initial={{ opacity: 0.5, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            css={`
              display: flex;
            `}
          >
            <SettingsErrorIcon />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

import { Colors, TextCss } from 'styles';

export const ReporterSettingsSectionTitle = ({ title }: { title: string }): React.ReactElement => (
  <div
    css={`
      display: flex;
      width: fit-content;
      height: 2.2rem;
      margin-bottom: 16px;
      color: ${Colors.gray10};
      ${TextCss.display1}
    `}
  >
    {title}
  </div>
);

import type { EditorElement, EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';
import type { ComponentType } from 'react';

export const TEXT_SOURCE_STYLING_PLUGIN_ID: 'source' = 'source';

export type TextSourceStylingPluginID = typeof TEXT_SOURCE_STYLING_PLUGIN_ID;

export type TextSourceStylingPluginElement = EditorElement;

export type TextSourceStylingPluginOptions = Readonly<
  EditorPluginOptions & {
    // $FlowFixMe[unclear-type]
    component?: ComponentType<any>;
  }
>;

export type TextSourceStylingPluginPropertyOptions = Readonly<
  EditorPluginPropertyOptions &
    TextSourceStylingPluginOptions & {
      pluginID: TextSourceStylingPluginID;
      // $FlowFixMe[unclear-type]
      component: ComponentType<any>;
      pluginName: string;
    }
>;

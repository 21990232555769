import { Node } from 'domains/reporter/RichTextEditor/core';
import { LINE_BREAK_PLUGIN_ID } from './types';
import type { LineBreakPluginElementMutable } from './types';
import { Editor, Range, Text } from '../../core';
import { normalizeMacro } from '../macroPlaceholder/utils';
import { PARAGRAPH_PLUGIN_ID } from '../paragraph/types';
import { HEADING_PLUGIN_ID } from '../heading/types';
import { Element } from 'slate';

export const createLineBreak = (): LineBreakPluginElementMutable => ({
  type: LINE_BREAK_PLUGIN_ID,
  children: [{ text: '' }],
});

export const isSelectionInLineBreak = (editor: Editor, selection: Range): boolean => {
  // If selection is not collapsed, then the cursor is not contained within a line break
  if (!Range.isCollapsed(selection)) {
    return false;
  }

  const nodes = Array.from(
    Editor.nodes(editor, {
      at: selection,
      match: (n) => Element.isElement(n) && n.type === LINE_BREAK_PLUGIN_ID,
    })
  );

  return nodes.length > 0;
};

export const convertParagraphsIntoLineBreaks = (fragment: Array<Node>): Array<Node> => {
  const normalizedFragment = normalizeMacro(fragment);

  let fragmentToReturn: Array<Node> = [];
  normalizedFragment.forEach((node, idx) => {
    const shouldPrependLineBreak =
      fragmentToReturn.length !== 0 &&
      !(
        fragmentToReturn.length === 1 &&
        Text.isText(fragmentToReturn[0]) &&
        fragmentToReturn[0].text === ''
      ) &&
      node.shouldForceInline !== true;

    if (node.type === HEADING_PLUGIN_ID || node.type === PARAGRAPH_PLUGIN_ID) {
      fragmentToReturn = fragmentToReturn.concat(
        shouldPrependLineBreak ? [createLineBreak(), ...node.children] : node.children
      );
      return;
    }

    fragmentToReturn.push(node);
  });

  return fragmentToReturn;
};

import { useCallback, useEffect, useRef, useState } from 'react';
import { stringifyRange } from 'domains/reporter/RichTextEditor/utils/stringify';
import { partialEditor } from 'domains/reporter/RichTextEditor/utils';
import { logger } from 'modules/logger';
import { useReportLockedState } from 'domains/reporter/Reporter/hooks/useReportLockedState/useReportLockedState';
import { useCurrentCaseReport } from './useCurrentCaseReport';
import { useSlateSingletonContext } from 'domains/reporter/Reporter/SlateSingletonContext';
import type { ReportTemplateFieldsFragment } from 'generated/graphql';

export type AutoloadTemplateState = 'pending' | 'success' | 'skipped';

type UseAutoloadTemplateProps = {
  unfurlTemplate: (template: ReportTemplateFieldsFragment) => void;
  loading?: boolean;
  setTemplate: (template?: ReportTemplateFieldsFragment | null | undefined) => void;
};

export const useAutoloadTemplate = ({
  unfurlTemplate,
  loading = false,
  setTemplate,
}: UseAutoloadTemplateProps): {
  autoloadTemplate: () => void;
  autoloadTemplateState: AutoloadTemplateState;
} => {
  const { isClaimedByMe } = useReportLockedState();
  const previousIsClaimedByMeRef = useRef(isClaimedByMe);
  const { currentCaseReport: worklistItem, loadingCaseReport } = useCurrentCaseReport();
  const [{ editor }] = useSlateSingletonContext();

  const [autoloadTemplateState, setAutoloadTemplateState] =
    useState<AutoloadTemplateState>('pending');

  const currentWorklistItemSmid = useRef(null);

  useEffect(() => {
    if (worklistItem == null) return;

    // if window is reporter is still open, and the worklist item or claiming user has changed, reset autoloadTemplateState
    const worklistItemChanged = currentWorklistItemSmid.current !== worklistItem.smid;
    const claimantChanged = previousIsClaimedByMeRef.current !== isClaimedByMe;
    if ((worklistItemChanged || claimantChanged) && autoloadTemplateState !== 'pending') {
      const changeReason = worklistItemChanged ? 'worklist item' : 'claimant';
      logger.info(
        `[useAutoloadTemplate] resetting autoloadTemplateState to pending due to ${changeReason} change`
      );
      setAutoloadTemplateState('pending');
      setTemplate(null);
    }

    currentWorklistItemSmid.current = worklistItem.smid;
    previousIsClaimedByMeRef.current = isClaimedByMe;
  }, [autoloadTemplateState, worklistItem, isClaimedByMe, setTemplate]);

  const autoloadTemplate = useCallback(() => {
    logger.info('[useAutoloadTemplate] autoloadTemplate called');
    if (loading || loadingCaseReport) {
      return;
    }

    if (isClaimedByMe === false) {
      setAutoloadTemplateState('skipped');
      logger.info(
        '[useAutoloadTemplate] resetting autoloadTemplateState to skipped due to not being claimed by user'
      );
      return;
    }

    if (worklistItem == null) {
      return;
    }

    const { report } = worklistItem;
    if (report == null) {
      return;
    }

    const { created, sectionsLastUpdated, template } = report;

    if (editor == null || editor.children.length === 0) {
      return;
    }

    if (template == null || (sectionsLastUpdated != null && sectionsLastUpdated !== created)) {
      logger.info(
        '[useAutoloadTemplate] resetting autoloadTemplateState to skipped as there is no template to autoload'
      );
      setAutoloadTemplateState('skipped');
      return;
    }

    const autoloadInfoMessage = `[ReporterComponent] Attempting to autoload template "${
      template.id
    }" for worklist item "${worklistItem.smid}" with procedure description "${
      worklistItem.procedure?.description ?? 'N/A'
    }"`;

    logger.info(autoloadInfoMessage, {
      logId: editor.selection ? stringifyRange(editor.selection) : 'null',
      editor: partialEditor(editor),
      template: {
        name: template.name,
        smid: template.id,
      },
      updatedTime: sectionsLastUpdated,
    });

    unfurlTemplate(template);

    setAutoloadTemplateState('success');
    /**
     * RP-3188: Similar to the invocation of `handleInsertTemplate` within the useEffect in Fields.Context
     * (see note), we don't want to re-run this effect when the editor or worklistItem changes.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClaimedByMe, loading, loadingCaseReport, unfurlTemplate]);

  return {
    autoloadTemplate,
    autoloadTemplateState,
  };
};

export default useAutoloadTemplate;

import { useCurrentUser } from 'hooks/useCurrentUser';
import { useFeatureFlagEnabled, FF } from 'modules/feature-flags';
import { useSlateSelection, useSlateStatic } from 'slate-react';
import { useImpressionGenerator } from 'hooks/useImpressionGenerator';
import { useEffect } from 'react';
import { logger } from 'modules/logger';

export const AutoGenerateImpressions = (): React.ReactElement | null => {
  const [isAutoImpressionsFFEnabled] = useFeatureFlagEnabled(FF.REPORTER_AUTO_IMPRESSIONS);
  const { data } = useCurrentUser();
  const reporterSettings = data?.me?.reporterSettings;
  const isAutoImpressionEnabled = reporterSettings?.impressionGenerator?.enabled ?? false;

  if (!isAutoImpressionsFFEnabled || !isAutoImpressionEnabled) {
    return null;
  }

  return <WatchImpressions />;
};

export const WatchImpressions = (): React.ReactElement | null => {
  const selection = useSlateSelection();
  const editor = useSlateStatic();
  const {
    isSelectionInImpressionSection,
    isImpressionContentEmpty,
    getImpressionContent,
    generateAndStitchImpression,
    isFirstNavigationToImpressionSection,
    setIsFirstNavigationToImpressionSection,
    isCurrentImpressionGenerated,
  } = useImpressionGenerator();

  useEffect(() => {
    if (isSelectionInImpressionSection(editor, selection) && isFirstNavigationToImpressionSection) {
      setIsFirstNavigationToImpressionSection(false);
      const content = getImpressionContent(editor);
      if (isImpressionContentEmpty(content) || !isCurrentImpressionGenerated(editor)) {
        logger.info(
          `[AutoGenerateImpression] Impression generation triggered by navigation: ${isImpressionContentEmpty(content) ? 'content is empty' : ''}${!isCurrentImpressionGenerated(editor) ? 'content is not auto generated (ugc or default text)' : ''}`
        );
        generateAndStitchImpression(editor, true);
      }
    }
  }, [
    selection,
    editor,
    isSelectionInImpressionSection,
    getImpressionContent,
    isImpressionContentEmpty,
    generateAndStitchImpression,
    isFirstNavigationToImpressionSection,
    setIsFirstNavigationToImpressionSection,
    isCurrentImpressionGenerated,
  ]);

  return null;
};
